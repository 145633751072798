import { DeviceState, PositionState } from './DeviceState';
import { FutureInterpreter } from './FutureInterpreter';
import { CurrentFrameInterpreter } from './CurrentFrameInterpreter';

// If the network delay is more than this threshold, we will use the future prediction
// If process.env.REACT_APP_FUTURE_PREDICTION_USE_THRESHOLD_MSEC is not defined, we will use 500
const FUTURE_PREDICTION_USE_THRESHOLD_MSEC = parseInt(
  process.env.REACT_APP_FUTURE_PREDICTION_USE_THRESHOLD_MSEC ?? '500',
  10,
);

/**
 * This class is responsible for calculating the position of a stroker
 * based on the predictions we receive from ML server
 * It also implements the rule that if device has started moving, it keeps moving
 * until it reaches the destination (upper or lower position)
 */
export class DeviceStateMachine {
  private futureInterpreter: FutureInterpreter;
  private currentFrameInterpreter: CurrentFrameInterpreter;
  private deviceState: DeviceState;
  // The timestamp of the frame that has been used to generate the last server prediction we received
  private frameTimestamp: number;
  private maxStrokeLength: number;
  private strokeDurationCompensator: number;

  constructor() {
    this.deviceState = new DeviceState();
    this.futureInterpreter = new FutureInterpreter();
    this.currentFrameInterpreter = new CurrentFrameInterpreter();
    this.frameTimestamp = 0;
    this.maxStrokeLength = 100;
    this.strokeDurationCompensator = 0.75;
  }

  getDeviceState(): DeviceState {
    return this.deviceState;
  }

  /** This function calculates stroke duration based on the speed
   * The formula used here is derived from the Keon Speed Investigation.
   * https://feelrobotics.atlassian.net/wiki/spaces/FRT/pages/2554069019/Keon+Speed+Investigation
   * @param deviceState the current device state
   * @return the duration of the stroke
   */
  calculateStrokeDuration(deviceState: DeviceState): number {
    const speed = deviceState.speed;
    // The empirical formula used here is derived from the Keon Speed Investigation
    const speedInAmpPerSec = 3 * speed + 81.49;
    // Below, the multiplication by 1000 is to convert it into milliseconds
    return (this.maxStrokeLength * this.strokeDurationCompensator * 1000) / speedInAmpPerSec;
  }

  /**
   * Call this function on each frame to calculate the new position of the device
   */
  onFrame() {
    // If we started moving in a certain direction, we need to finish it
    // before starting reacting to any new instructions
    const now = Date.now();

    if (this.deviceState.movementStopTimestamp > now) {
      // Still moving
      return;
    }

    // If there was a movement, stop it
    if (this.deviceState.positionState === PositionState.MoveUp) {
      this.deviceState.positionState = PositionState.Up;
    } else if (this.deviceState.positionState === PositionState.MoveDown) {
      this.deviceState.positionState = PositionState.Down;
    }

    const timePassed = now - this.frameTimestamp;
    if (timePassed > FUTURE_PREDICTION_USE_THRESHOLD_MSEC) {
      this.deviceState = this.futureInterpreter.updateDeviceStateOnFrame(this.deviceState);
    } else {
      this.deviceState = this.currentFrameInterpreter.updateDeviceStateOnFrame(this.deviceState);
    }

    if (
      this.deviceState.positionState === PositionState.Up ||
      this.deviceState.positionState === PositionState.Down
    ) {
      // Do not need to move, exit
      return;
    }

    // Need to move
    const timeNow = Date.now();
    if (this.deviceState.positionState === PositionState.MoveUp) {
      this.deviceState.destination = 1;
    } else if (this.deviceState.positionState === PositionState.MoveDown) {
      this.deviceState.destination = 0;
    }
    this.deviceState.movementStopTimestamp = timeNow + this.deviceState.strokeDuration;
  }

  /**
   * Processes a new prediction from the ML server, updating various components with the received data.
   *
   * @param position - The current position.
   * @param future - An array of future positions predicted by the ML server.
   * @param timestamp - The timestamp of the frame that was used to generate the prediction.
   * @param isBlowJob - Indicator if the current command relates to a specific condition (1 for true, 0 for false).
   */
  onMlCommand(position: number, future: Array<number>, timestamp: number, isBlowJob: number): void {
    this.frameTimestamp = timestamp;
    this.futureInterpreter.onMlCommand(future, timestamp);
    this.currentFrameInterpreter.onMlCommand(position, isBlowJob, timestamp);
  }
}
