import React, { createContext, useEffect, useState } from 'react';

import { createHook } from 'utils/utils';
import { usePrediction } from './PredictionHook';
import { useMLPerformance } from './MLPerformanceHook';
import { useConnectedDevices } from './ConnectedDevicesHook';
import { useDevicesOperation } from './DevicesOperationHook';
import { useBandwidth } from 'hooks/BandwidthHooks';
import { useChromeExtension } from './ChromeExtensionHook';
import { useWebsiteCompatibility } from './WebsiteCompatibilityHook';
import { useAuth } from './AuthHook';
import { useDeviceBattery } from './DeviceBatteryHook';
import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';

const ChromeExtensionNotificationsContext = createContext();

const PORTAL_POPUP_URL =
  window.location.protocol + '//' + window.location.host + '/extension/popup';

// TODO remove to popup notifications hook
const useChromeExtensionNotifications = () =>
  createHook('useChromeExtensionNotifications', ChromeExtensionNotificationsContext);

const ChromeExtensionNotificationsContextProvider = ({ children }) => {
  const { serverUrl, autopilot, setAutopilot, testSignal, setTestSignal, connection } =
    usePrediction();
  const { averageServerTurnaroundMsec, serverDroppedFramePercentage, fps } = useMLPerformance();
  const { bytesPerSecond, fasterImageCompression, lowImageSize } = useBandwidth();
  const { batteryPerDevice } = useDeviceBattery();
  const { extensionVersion, contentUrl } = useChromeExtension();
  const { getConnectedDevices } = useConnectedDevices();
  const {
    isPaused,
    setPaused,
    intensity,
    setIntensity,
    ambientMovement,
    setAmbientMovement,
    suctionMode,
    setSuctionMode,
  } = useDevicesOperation();
  const devices = getConnectedDevices();
  const { checkCompatibility, compatibleWebsites } = useWebsiteCompatibility();
  const { authenticatedUser } = useAuth();
  const devicesStr = JSON.stringify(devices);
  const [broadcastChannel, setBroadcastChannel] = useState(null);
  // Flag indicating whether the current content page is whitelisted or not
  const [whitelisted, setWhitelisted] = useState(false);

  const sentToGA = (data) => {
    let action;
    const params = { video_url: contentUrl };

    if (isPaused !== data.isPaused) {
      action = 'toggle_device';
      params.value = data.isPaused ? 'pause_device' : 'play_device';
    } else if (autopilot !== data.autopilot) {
      action = 'toggle_autopilot';
      params.value = data.autopilot ? 'enable' : 'disable';
    } else if (intensity !== data.intensity) {
      action = 'set_power_control_intensity';
      params.value = data.intensity;
    } else if (ambientMovement !== data.ambientMovement && !data.autopilot) {
      action = 'set_ambient_movement';
      params.value = data.ambientMovement;
    } else if (suctionMode !== data.suctionMode) {
      action = 'set_suction_mode';
      params.value = data.suctionMode;
    }

    sendToAnalyticsCustomEventObj(action, params);
  };

  useEffect(() => {
    const bc = new BroadcastChannel('extension-popup');
    setBroadcastChannel(bc);

    // The delay is needed to wait until the content script is started
    setTimeout(() => {
      // Notify extension about popup URL
      const request = {
        what: 'PORTAL_URL',
        url: PORTAL_POPUP_URL,
        width: '339px',
        height: '600px', // Please note that Chrome might limit height to 600 px
      };
      window.postMessage(request, '*');
    }, 300);

    return () => {
      bc.close();
    };
  }, []);

  useEffect(() => {
    setWhitelisted(contentUrl && checkCompatibility(contentUrl));
  }, [contentUrl, compatibleWebsites.length]);

  useEffect(() => {
    if (!broadcastChannel || !connection) {
      return;
    }

    const unsubscribeFromPredictions = connection.subscribeServerPredictions(
      ({ frameIndex, engine_position: percent, is_blowjob }) => {
        // Send data to the portal content script
        const msg = {
          what: 'FRAME',
          predict: percent,
          is_blowjob,
          averageServerTurnaroundMsec,
          serverDroppedFramePercentage,
          fps,
          frameNo: frameIndex,
        };
        broadcastChannel.postMessage(msg);
      },
    );
    return () => {
      unsubscribeFromPredictions();
    };
  }, [averageServerTurnaroundMsec, serverDroppedFramePercentage, fps, connection]);

  useEffect(() => {
    if (!broadcastChannel) {
      return;
    }

    const sendStateUpdateToPopup = () => {
      const msg = {
        what: 'SERVER2POPUP_UPDATE',
        isPaused,
        intensity,
        ambientMovement,
        extensionVersion,
        devices: devices.map(({ name, image, id }) => ({ name, image, id })),
        batteryPerDevice,
        serverUrl,
        autopilot,
        fasterImageCompression,
        lowImageSize,
        contentUrl,
        isLoggedIn: authenticatedUser?.attributes?.email,
        whitelisted,
        suctionMode,
        testSignal,
      };
      broadcastChannel.postMessage(msg);
    };

    broadcastChannel.onmessage = (event) => {
      const { data } = event;
      if (data.what === 'UPDATE_REQ') {
        sendStateUpdateToPopup();
      } else if (data.what === 'POPUP2SERVER_UPDATE') {
        sentToGA(data);
        setPaused(data.isPaused);
        setIntensity(data.intensity);
        setAmbientMovement(data.ambientMovement);
        setAutopilot(data.autopilot);
        setSuctionMode(data.suctionMode || 0);
        setTestSignal(data.testSignal);
      }
    };

    sendStateUpdateToPopup();
  }, [
    isPaused,
    intensity,
    ambientMovement,
    suctionMode,
    testSignal,
    broadcastChannel,
    extensionVersion,
    devicesStr,
    batteryPerDevice,
    serverUrl,
    autopilot,
    fasterImageCompression,
    lowImageSize,
    contentUrl,
    authenticatedUser?.attributes?.email,
    whitelisted,
  ]);

  // Send outgoing bandwidth updates
  useEffect(() => {
    if (!broadcastChannel) {
      return;
    }

    const msg = {
      what: 'SERVER2POPUP_BYTES_PER_SECOND',
      bytesPerSecond,
    };
    broadcastChannel.postMessage(msg);
  }, [bytesPerSecond, broadcastChannel]);

  return (
    <ChromeExtensionNotificationsContext.Provider value={{}}>
      {children}
    </ChromeExtensionNotificationsContext.Provider>
  );
};

export { ChromeExtensionNotificationsContextProvider, useChromeExtensionNotifications };
