import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import { toast } from 'react-toastify';

import image from 'images/devices/handy-promotion.png';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import { sendToAnalytics } from 'lib/googleAnalytics';
import { sendAnalytics } from '@feelrobotics/ftap-connector';
import { shouldSendAnalytics } from 'utils/utils';
import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import { wait } from '../../../utils/wait';
import { updateDocumentTitle, displaySystemNotification } from 'utils/utils';
import { AsyncQueue } from '../../AsyncQueue';
import { espressif, handyplug } from '../handyUtils/bundel.js';
import { throttle } from 'lodash';
import DeviceStateNotification from 'components/DeviceStateNotification';
import {
  CONNECTED_NOTIFICATIONS_OPTIONS,
  DISCONNECTED_NOTIFICATIONS_OPTIONS,
  CONNECTION_WAY,
} from 'data/constants';
import { HandyApi, Mode } from 'lib/devices/handy-api';

const serviceUuid = '1775244d-6b43-439b-877c-060f2d9bed07';
const commandCharacteristicUuid = '1775ff55-6b43-439b-877c-060f2d9bed07';

const CHARACTERISTICS_PROV_SESSION = '1775ff51-6b43-439b-877c-060f2d9bed07';
const CHARACTERISTICS_HANDY_PLUG = '1775ff55-6b43-439b-877c-060f2d9bed07';

const names = ['The Handy'];

export default class HandyWrapper extends BaseDeviceWrapper {
  constructor(device) {
    super(device, serviceUuid, commandCharacteristicUuid, image);
    this.ambientMovementMixin = new StrokerAmbientMovementMixin();
    this.currentPosition = 0;
    this.commandQueue = new AsyncQueue();
    this.handyApi = new HandyApi();
    this.connectionKey = device?.connectionKey;
    this.connectionWay = '';
    this.supportWifi = true;
  }
  static get deviceNames() {
    return names;
  }
  // For compatibility with bluetooth devices
  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return 'Sweet Tech';
  }

  async connect(isConnectionByWifi) {
    if (isConnectionByWifi) {
      await this.connectWifi();
    } else {
      await this.connectBT();
    }
  }

  async disconnect() {
    if (this.connectionWay === CONNECTION_WAY.WIFI) {
      await this.disconnectWifi();
    } else if (this.connectionWay === CONNECTION_WAY.BT) {
      await this.disconnectBT();
    }
  }

  async moveTo(position, speed) {
    if (this.connectionWay === CONNECTION_WAY.WIFI) {
      await this.moveToWifi(position, speed);
    } else if (this.connectionWay === CONNECTION_WAY.BT) {
      await this.sendLinearCmd(position, speed);
    }
  }

  async writePaused() {
    if (this.connectionWay === CONNECTION_WAY.BT) {
      await this.writePausedBT();
    }
    // NOTE: there is no any endpoint to stop immediately Handy device via WIFI on Handy server,
    // we are just waiting for all remaining requests to be processed
  }

  /**
   * Connect to the device via Bluetooth
   */
  async connectBT() {
    await super.connect();
    // Start session
    this.startSession();
    this.startHandyplugSession();
    this.connectionWay = CONNECTION_WAY.BT;
  }

  async disconnectBT() {
    // keep device paired to avoid reconnection error
    const isWifiSupported = true;
    await super.disconnect(isWifiSupported);
    this.connectionWay = '';
  }

  async disconnectWifi() {
    this.setConnectionState(ConnectionState.WIFI_MANUALLY_DISCONNECTED);
    this.connectionWay = '';
    shouldSendAnalytics() && sendToAnalytics('disconnect', this.device.name, this.device.id);
    sendAnalytics('disconnect', this.device.name, this.device.id);
  }

  /**
   * Connect to the device via WiFi
   */
  async connectWifi() {
    const startTime = performance.now();

    this.setConnectionState(ConnectionState.CONNECTING);
    const connectResult = await this.handyApi.base.setMode(this.connectionKey, { mode: Mode.HDSP });
    if (connectResult.error) {
      this.setConnectionState(ConnectionState.DISCONNECTED);
      throw new Error('Failed to connect to Handy');
    }
    this.setConnectionState(ConnectionState.CONNECTED);
    this.connectionWay = CONNECTION_WAY.WIFI;
    const milliseconds = Math.round(performance.now() - startTime);

    // force limiting stroke length to 40%, to make the handy able to keep up with the video using current API v2.
    // TODO retest new API v3 when it will be availble
    await this.handyApi.slide.setSlide(this.connectionKey, {
      min: 0,
      max: 40,
    });

    sendToAnalytics('device_connect', 'device', 'The Handy WIFI', milliseconds);
    shouldSendAnalytics() && sendToAnalytics('connect', this.device.name, this.device.id);
    sendAnalytics('connect', this.device.name, this.device.id);

    setInterval(() => {
      this.checkDeviceIsOnline();
    }, 5000);
  }

  async checkDeviceIsOnline() {
    if (
      this.connectionState === ConnectionState.WIFI_MANUALLY_DISCONNECTED ||
      this.connectionState === ConnectionState.CONNECTING ||
      this.connectionWay === CONNECTION_WAY.BT
    ) {
      // do not send "check wifi status" request, if device is manually disconnected or trying to connect by bluetooth
      return;
    }

    try {
      const result = await this.handyApi.base.isConnected(this.connectionKey);
      if (
        (result.connected && this.connectionState === ConnectionState.CONNECTED) ||
        (!result.connected && this.connectionState === ConnectionState.DISCONNECTED)
      ) {
        // device state didn't change
        return;
      }
      if (result.connected) {
        const connectResult = await this.handyApi.base.setMode(this.connectionKey, {
          mode: Mode.HDSP,
        });
        if (!connectResult.error) {
          this.setConnectionState(ConnectionState.CONNECTED);

          toast.dismiss();
          toast(
            <DeviceStateNotification device={this.device} state={ConnectionState.CONNECTED} />,
            CONNECTED_NOTIFICATIONS_OPTIONS,
          );

          if (document.visibilityState === 'hidden') {
            updateDocumentTitle(this, true);
            displaySystemNotification(this, true);
          }
        }
      } else {
        this.setConnectionState(ConnectionState.DISCONNECTED);
        toast.dismiss();
        toast(
          <DeviceStateNotification device={this.device} state={ConnectionState.DISCONNECTED} />,
          DISCONNECTED_NOTIFICATIONS_OPTIONS,
        );
        if (document.visibilityState === 'hidden') {
          updateDocumentTitle(this, false);
          displaySystemNotification(this, false);
        }
      }
    } catch (e) {
      console.warn('e', e.message);
    }
  }

  moveToWifi = async (pos, speed) => {
    const spd = Math.max(0, Math.min(99, Math.round(speed)));
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: pos,
      velocity: spd,
      stopOnTarget: true,
      immediateResponse: true,
    });
  };

  async writePausedBT() {
    await this.sendLinearCmd(0, 0);
  }

  // entry command from ConnectedDevicesHook
  async write(percent, speed, isBlowJob) {
    if (this.currentPosition === percent) {
      // Don't write the same value to the device twice
      return;
    }
    this.currentPosition = percent;

    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    let pos = Math.min(100, Math.round((percent * maxIntensity) / 100));
    const spd = Math.round((speed * maxIntensity) / 100);

    await this.moveTo(pos, spd);
    await this.ambientMovementMixin.onWrite();
  }

  /**
   * Abstract method to do ambient movement/vibration
   * @param {int} percent - vibration/movement intesity, 0..100
   */
  async setAmbientMovement(percent) {
    await this.ambientMovementMixin.setAmbientMovement(percent);
  }

  async setMaxIntensity(percent) {
    await this.ambientMovementMixin.setMaxIntensity(percent);
  }

  // throttle api request during 600 ms in Auto control mode for Wifi
  moveToWifiAmbient = throttle(async (pos, speed) => {
    const spd = Math.max(0, Math.min(100, Math.round(speed)));
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: pos,
      velocity: spd,
      stopOnTarget: true,
      immediateResponse: true,
    });
  }, 600);

  async moveAmbient(pos, speed) {
    if (this.connectionWay === CONNECTION_WAY.WIFI) {
      await this.moveToWifiAmbient(pos, speed);
      return;
    }
    if (this.connectionWay === CONNECTION_WAY.BT) {
      await this.sendLinearCmd(pos, speed);
      return;
    }
  }

  async onTimer() {
    await this.ambientMovementMixin.onTimer(async (pos, speed) => this.moveAmbient(pos, speed));
  }

  setDeviceMovementCallback(callback) {
    this.deviceMovementCallback = callback;
  }

  async testDevice() {
    if (this.connectionKey) {
      await this.testDeviceWifi();
      return;
    }
    const response = await this.testDeviceBT();
    if (!!response?.error?.connected) {
      this.setConnectionState(ConnectionState.DISCONNECTED);
    }
  }

  async testDeviceBT() {
    await this.sendLinearCmd(0);
    for (let i = 0; i < 2; i++) {
      await this.sendLinearCmd(100, 100);
      await wait(200);
      await this.sendLinearCmd(0);
      await wait(200);
    }
  }

  async testDeviceWifi() {
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: 0,
      velocity: 100,
      stopOnTarget: true,
    });
    await wait(500);
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: 50,
      velocity: 100,
      stopOnTarget: true,
    });
    await wait(200);
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: 0,
      velocity: 100,
      stopOnTarget: true,
    });
    await wait(200);
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: 100,
      velocity: 100,
      stopOnTarget: true,
    });
    await wait(200);
    await this.handyApi.hdsp.nextPositionPercentVelocityPercent(this.connectionKey, {
      position: 0,
      velocity: 100,
      stopOnTarget: true,
    });
  }

  /**
   * The following functions have been taken from the handy-ble-simple-example provided by SweetTech
   * https://github.com/poengAlex/handy-ble-simple-example
   */
  async startSession() {
    let provSession = await this.sensorService.getCharacteristic(CHARACTERISTICS_PROV_SESSION);
    await wait(50);
    let s0SessionCmd = espressif.S0SessionCmd.create();
    let sec0Payload = espressif.Sec0Payload.create({
      sc: s0SessionCmd,
    });
    let newSessionData = espressif.SessionData.create({
      secVer: 0,
      sec0: sec0Payload,
    });

    let newSessionDataBytes = espressif.SessionData.encode(newSessionData).finish();
    await provSession.writeValue(newSessionDataBytes);
  }

  async startHandyplugSession() {
    //Both RX and TX is on this char
    this.handyplugCharacteristic = await this.sensorService.getCharacteristic(
      CHARACTERISTICS_HANDY_PLUG,
    );
  }
  async sendLinearCmd(position, speed) {
    let duration = 100 + (100 - speed); // ms
    if (speed <= 25) {
      // Auto control mode
      duration = 250;
    }
    try {
      let Id = Math.round(Math.random() * 10000); //2147483647 max int value
      const vector = handyplug.LinearCmd.Vector.create({
        Index: 0,
        // the farther the Handy moves, the faster his speed
        Duration: duration,
        Position: (position / 100).toFixed(2),
      });
      const linearCmd = handyplug.LinearCmd.create({
        Id: Id,
        DeviceIndex: 0,
        Vectors: [vector],
      });
      const msgProto = handyplug.Message.create({
        LinearCmd: linearCmd,
      });
      let payload = handyplug.Payload.create({
        Messages: [msgProto],
      });
      let payloadBytes = handyplug.Payload.encode(payload).finish();
      await this.handyplugCharacteristic.writeValue(payloadBytes);
    } catch (e) {
      console.error('write BLE command to Handy', e.message);
    }
  }
}
