import React from 'react';
import cn from 'classnames';

import styles from './style.module.scss';

const LegacyButton = ({
  secondary,
  disabled,
  onClick,
  text,
  icon,
  styles: extraStyles,
  ...props
}) => {
  const disabledStyles = secondary
    ? disabled && styles.disabledSecondary
    : disabled && styles.disabledMain;

  return (
    <button
      {...props}
      className={cn(secondary ? styles.secondary : styles.main, disabledStyles, extraStyles)}
      disabled={disabled}
      onClick={onClick}
    >
      {icon ? <img className={styles.image} src={icon} alt={text} /> : null}
      {text}
    </button>
  );
};

export default LegacyButton;
