// If ambient movement is below this, device doesn't move
const AMBIENT_MOVEMENT_MIN_THRESHOLD = 3;
// Max ambient movement intensity
const MAX_AMBIENT_MOVEMENT = 0.25;

// If no movement for this amount of time, ambient movement starts
const AMBIENT_MOVEMENT_KICK_IN_TIMEOUT = 2000;

export default class StrokerAmbientMovementMixin {
  constructor() {
    this.ambientNextMovementTimestamp = performance.now() + AMBIENT_MOVEMENT_KICK_IN_TIMEOUT;
    this.ambientIntensity = 0;
    this.maxIntensity = 100;
    this.currentPosition = 0;
  }

  async onWrite() {
    this.ambientNextMovementTimestamp = performance.now() + AMBIENT_MOVEMENT_KICK_IN_TIMEOUT;
  }

  async setAmbientMovement(percent) {
    if (this.ambientIntensity === percent) {
      return;
    }
    this.ambientIntensity = percent;
  }

  async setMaxIntensity(percent) {
    this.maxIntensity = percent;
  }

  /**
   * Call this function ~30 times per second in order to check
   * in order to do ambient movement
   * @param moveTo - the function to call in order to move the device
   */
  async onTimer(moveTo) {
    const speed = +this.ambientIntensity * MAX_AMBIENT_MOVEMENT;
    if (speed < AMBIENT_MOVEMENT_MIN_THRESHOLD) {
      // If no ambient movement, do nothing
      return;
    }

    if (performance.now() < this.ambientNextMovementTimestamp) {
      // If didn't pass enough time since we moved for the last time, do nothing
      return;
    }
    const middle = this.maxIntensity / 2;
    const newPos = this.currentPosition > middle ? 0 : Math.ceil(+this.maxIntensity);
    await moveTo(newPos, speed);
    this.currentPosition = newPos;
    const ambientMovementInterval = 10000 / speed;
    this.ambientNextMovementTimestamp = performance.now() + ambientMovementInterval;
  }
}
