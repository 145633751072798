import React from 'react';
import cn from 'classnames';

import styles from './style.module.scss';

const StepItem = ({ image, text, styles: containerStyles }) => {
  return (
    <div className={cn(styles.container, containerStyles)}>
      <img className={styles.image} src={image} alt="Logo" />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default StepItem;
