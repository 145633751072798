/**
 * DeviceState is a class that represents the state of a device.
 * This state is generated by the DeviceStateMachine as a result of
 * the predictions received from the ML server.
 * Based on this state, we send the commands to the device.
 */
export enum PositionState {
  MoveUp = 1,
  MoveDown,
  Up,
  Down,
}

export class DeviceState {
  // Whether the state was updated with a future prediction or with the current frame prediction
  public updatedWithFuturePrediction: boolean;

  public movementStopTimestamp: number;

  public positionState: PositionState;
  public isBlowJob: boolean;
  public destination: number;
  public speed: number;
  public strokeDuration: number;

  constructor() {
    this.updatedWithFuturePrediction = false;
    this.movementStopTimestamp = 0;
    this.destination = 0;
    this.speed = 0;
    this.positionState = PositionState.Down;
    this.isBlowJob = false;
    this.strokeDuration = 1000;
  }
}
