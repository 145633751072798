import React, { createContext, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { createHook } from 'utils/utils';
import billingApi from '../api/billing-api';
import { useAuth } from './AuthHook';

const KlaviyoContext = createContext();
const useKlaviyo = () => createHook('useKlaviyo', KlaviyoContext);

const KlaviyoContextProvider = ({ children }) => {
  const { authenticatedUser } = useAuth();
  const [isMarked, setIsMarked] = useLocalStorage('newsletter', null);

  useEffect(() => {
    // Try subscribing the user that just logged in.
    if (authenticatedUser) {
      subscribeIfMarked();
    }
  }, [authenticatedUser?.attributes?.email]);

  /**
   * Saves the given e-mail address to localstorage so we can
   * subscribe the user once he/she is logged in.
   * (This is necessary because the server endpoint needs authentication)
   * @param {string} email Email address to be subscribed.
   */
  const markForSubscription = (email) => {
    setIsMarked(email);
  };

  /**
   * Checks the localstorage for a saved e-mail address. If it finds one,
   * it will be subscribed and removed from local storage.
   */
  const subscribeIfMarked = async () => {
    if (isMarked) {
      const error = await subscribe(isMarked);
      if (error) {
        console.error(error);
      } else {
        setIsMarked(null);
      }
    }
  };

  /**
   * Subscribe an email address to the FeelMe newsletter.
   * @param {string} email Email address to be subscribed
   */
  const subscribe = async (email) => {
    return await billingApi('/newsletter', true, {}, { method: 'POST' }, { email: email });
  };

  /**
   * Unsubscribe an email address to the FeelMe newsletter.
   * @param {string} email Email address to be unsubscribed
   */
  const unsubscribe = async (email) => {
    return await billingApi('/newsletter', true, {}, { method: 'DELETE' }, { email: email });
  };

  return (
    <KlaviyoContext.Provider value={{ markForSubscription, subscribeIfMarked, unsubscribe }}>
      {children}
    </KlaviyoContext.Provider>
  );
};

export { KlaviyoContextProvider, useKlaviyo };
