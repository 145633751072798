import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConnectedDevicesContextProvider } from './hooks/ConnectedDevicesHook';
import { DevicesOperationContextProvider } from './hooks/DevicesOperationHook';
import { PredictionContextProvider } from './hooks/PredictionHook';
import { MLPerformanceContextProvider } from './hooks/MLPerformanceHook';
import { AuthContextProvider } from 'hooks/AuthHook';
import { ChromeExtensionContextProvider } from 'hooks/ChromeExtensionHook';
import { UserSettingsContextProvider } from 'hooks/UserSettingsHook';
import { SubscriptionContextProvider } from 'hooks/SubscriptionHook';
import { ChromeExtensionNotificationsContextProvider } from 'hooks/ChromeExtensionNotificationsHook';
import { BandwidthContextProvider } from 'hooks/BandwidthHooks';
import { BandwidthAutoAdjustContextProvider } from 'hooks/BandwidthAutoAdjustHook';
import { VideoPlayerContextProvider } from 'hooks/VideoPlayerHook';
import { GoogleAnalyticsContextProvider } from 'hooks/GoogleAnalyticsHooks';
import { WebsiteCompatibilityContextProvider } from 'hooks/WebsiteCompatibilityHook';
import { AutoSelectServerContextProvider } from 'hooks/AutoSelectServerHook';
import { FeelmeAnalyticsServerContextProvider } from 'hooks/FeelmeAnalyticsServerHook';
import { DeviceVisualizationContextProvider } from 'hooks/DeviceVisualizationHook';
import { DeviceBatteryContextProvider } from 'hooks/DeviceBatteryHook';
import { WizardContextProvider } from 'hooks/WizardHook';
import { KlaviyoContextProvider } from './hooks/KlaviyoHook';
import { BluetoothAlertProvider } from './hooks/BluetoothAlertHook';
import { ContentChannelsProvider } from './hooks/ContentChannelsHook';
import { NotificationsProvider } from './hooks/NotificationsHook';
import { PowerBlowContextProvider } from './hooks/PowerBlowHook';
import { AffiliateContextProvider } from 'hooks/AffiliateHook';
import { ImportCartContextProvider } from 'hooks/ImportCartHook';

import CloseIcon from 'components/CloseNotificationIcon';

import PortalAppRouting from 'routes/PortalAppRouting';

import './PortalApp.scss';

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const PortalApp = () => {
  return (
    <div id="main">
      <BluetoothAlertProvider>
        <MLPerformanceContextProvider>
          <ChromeExtensionContextProvider>
            <WizardContextProvider>
              <AuthContextProvider>
                <UserSettingsContextProvider>
                  <SubscriptionContextProvider>
                    <BandwidthContextProvider>
                      <BandwidthAutoAdjustContextProvider>
                        <WebsiteCompatibilityContextProvider>
                          <ConnectedDevicesContextProvider>
                            <PredictionContextProvider>
                              <GoogleAnalyticsContextProvider>
                                <DeviceVisualizationContextProvider>
                                  <ContentChannelsProvider>
                                    <PowerBlowContextProvider>
                                      <DevicesOperationContextProvider>
                                        <DeviceBatteryContextProvider>
                                          <FeelmeAnalyticsServerContextProvider>
                                            <ChromeExtensionNotificationsContextProvider>
                                              <VideoPlayerContextProvider>
                                                <AutoSelectServerContextProvider>
                                                  <NotificationsProvider>
                                                    <AffiliateContextProvider>
                                                      <ImportCartContextProvider>
                                                        <KlaviyoContextProvider>
                                                          <PortalAppRouting />
                                                          <ToastContainer
                                                            closeButton={<CloseIcon />}
                                                          />
                                                        </KlaviyoContextProvider>
                                                      </ImportCartContextProvider>
                                                    </AffiliateContextProvider>
                                                  </NotificationsProvider>
                                                </AutoSelectServerContextProvider>
                                              </VideoPlayerContextProvider>
                                            </ChromeExtensionNotificationsContextProvider>
                                          </FeelmeAnalyticsServerContextProvider>
                                        </DeviceBatteryContextProvider>
                                      </DevicesOperationContextProvider>
                                    </PowerBlowContextProvider>
                                  </ContentChannelsProvider>
                                </DeviceVisualizationContextProvider>
                              </GoogleAnalyticsContextProvider>
                            </PredictionContextProvider>
                          </ConnectedDevicesContextProvider>
                        </WebsiteCompatibilityContextProvider>
                      </BandwidthAutoAdjustContextProvider>
                    </BandwidthContextProvider>
                  </SubscriptionContextProvider>
                </UserSettingsContextProvider>
              </AuthContextProvider>
            </WizardContextProvider>
          </ChromeExtensionContextProvider>
        </MLPerformanceContextProvider>
      </BluetoothAlertProvider>
    </div>
  );
};

export default PortalApp;
