/**
 * A hook to adjust amount of data sent over the internet to the ML server
 */
import React, { createContext, useState, useEffect, useReducer, useCallback } from 'react';
import { createHook } from 'utils/utils';
import { sendToAnalytics } from 'lib/googleAnalytics.js';

const IMAGE_FORMAT_SLOW = 'image/webp';
const IMAGE_FORMAT_FAST = 'image/jpeg';

const IMAGE_COMPRESSION_LOW_SIZE = 0.1;
const IMAGE_COMPRESSION_NORMAL = undefined;

const BandwidthContext = createContext();

export const useBandwidth = () => createHook('useBandwidth', BandwidthContext);

const reduce = (prevState, action) => {
  const { type } = action;
  switch (type) {
    case 'LOG_SIZE':
      if (prevState.frameIndex === action.frameIndex) {
        // We have already handled this frame
        return prevState;
      }

      return {
        ...prevState,
        totalBytes: prevState.totalBytes + action.bytes,
      };
    case 'RECALCULATE':
      return {
        ...prevState,
        totalBytes: 0,
        bytesPerSecond: prevState.totalBytes,
      };
    default:
      return prevState;
  }
};

export const BandwidthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, {});
  const [fasterImageCompression, setFasterImageCompression] = useState(false);
  const [lowImageSize, setLowImageSize] = useState(false);

  useEffect(() => {
    const frameType = fasterImageCompression ? IMAGE_FORMAT_FAST : IMAGE_FORMAT_SLOW;
    const frameCompression = lowImageSize ? IMAGE_COMPRESSION_LOW_SIZE : IMAGE_COMPRESSION_NORMAL;
    const request = {
      what: 'FRAME_QUALITY',
      frameType,
      frameCompression,
    };
    window.postMessage(request, '*');
    if (fasterImageCompression) {
      sendToAnalytics('bandwidth_adjust', 'image_format', frameType);
    }
    if (lowImageSize) {
      sendToAnalytics('bandwidth_adjust', 'image_compression_level', frameCompression.toString());
    }
  }, [fasterImageCompression, lowImageSize]);

  const logSentSize = useCallback((frameIndex, bytes) => {
    dispatch({
      type: 'LOG_SIZE',
      frameIndex,
      bytes,
    });
  }, []);

  useEffect(() => {
    // Recalculate speed every second
    const interval = setInterval(() => {
      // Recalculate bytes per second every second
      dispatch({ type: 'RECALCULATE' });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <BandwidthContext.Provider
      value={{
        setFasterImageCompression,
        setLowImageSize,
        logSentSize,
        bytesPerSecond: state.bytesPerSecond,
        fasterImageCompression,
        lowImageSize,
      }}
    >
      {children}
    </BandwidthContext.Provider>
  );
};
