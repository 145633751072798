import React, { createContext, ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAuth } from 'hooks/AuthHook';

import { createHook } from 'utils/utils';
import {
  CART_PLAN_ID_KEY,
  CART_PLAN_ID_EXPIRATION_PERIOD_IN_HOURS,
  ROUTE_PATHS,
} from 'data/constants';

interface Plan {
  id: string;
}

interface PlanData {
  date: number;
  id: string;
}

interface ImportCartContextProps {
  processPlanIdFromUrl: (cartData: string) => void;
  getSavedPlanId: () => string | null;
  handleNavigationAfterEmailConfirm: (
    navigate: NavigateFunction,
    routeState: { email: string },
    actionWithPlanId: (planId: string, email: string, callback: () => void) => void,
  ) => void;
}

const ImportCartContext = createContext<ImportCartContextProps | null>(null);
const useImportCart = () => createHook('useImportCart', ImportCartContext);

interface ImportCartContextProviderProps {
  children: ReactNode;
}

const ImportCartContextProvider = ({ children }: ImportCartContextProviderProps) => {
  const { setIsLoading } = useAuth();
  /**
   * Saves the imported plan ID along with the current timestamp to local storage.
   * The function constructs an object with the current date (timestamp) and the provided plan ID,
   * then serializes this object to a JSON string for storage.
   *
   * @param {string} cartObject - The plan ID to be saved in local storage.
   */
  const savePlanIdWithTimestamp = (cartObject: Plan[]) => {
    const plan = cartObject[0];
    const planData: PlanData = {
      date: Date.now(),
      id: plan.id,
    };
    localStorage.setItem(CART_PLAN_ID_KEY, JSON.stringify(planData));
  };

  /**
   * Retrieves the plan ID from the URL query parameter 'cart', decodes it,
   * and then saves it to local storage using the savePlanIdWithTimestamp function.
   * If the 'cart' parameter is not present in the URL, the function will not perform any action.
   *
   * This function is intended to be used for extracting plan ID data that has been
   * passed through a URL parameter, decoding it from its URL-encoded format,
   * and then storing it for future use.
   * @param {string} cartData - Encoded cart object extracted from the URL
   */
  const processPlanIdFromUrl = (cartData: string) => {
    const cartStr = decodeURIComponent(cartData);
    const cartObject: Plan[] = JSON.parse(cartStr);
    if (cartObject && cartObject.length > 0) {
      savePlanIdWithTimestamp(cartObject);
    }
  };

  /**
   * Retrieves and returns the saved plan ID from local storage if it's not expired.
   * It checks the expiration of the plan ID based on a predefined time period.
   * If the saved data is not present, expired, or if there's an error in parsing the data,
   * the function will return null.
   *
   * @returns {string|null} The saved plan ID if it's valid and not expired, otherwise null.
   */
  const getSavedPlanId = (): string | null => {
    const savedValue = localStorage.getItem(CART_PLAN_ID_KEY);
    if (!savedValue) {
      return null;
    }

    try {
      const planData = JSON.parse(savedValue);
      const currentTime = Date.now();
      const timeElapsedInHours = (currentTime - planData.date) / (1000 * 60 * 60);

      if (timeElapsedInHours < CART_PLAN_ID_EXPIRATION_PERIOD_IN_HOURS) {
        return planData.id;
      } else {
        localStorage.removeItem(CART_PLAN_ID_KEY);
      }
    } catch (error) {
      console.error('Error parsing plan data:', error);
    }

    return null;
  };

  /**
   * Determines the next navigation action based on the presence of a saved plan ID.
   * If a saved plan ID exists, initiates the checkout process. Otherwise, navigates to the dashboard.
   *
   * @param navigate - The navigation function from react-router-dom.
   * @param routeState - The current state of the route, including parameters like email.
   * @param actionWithPlanId - A generic function to execute if a plan ID is present. It should accept a plan ID
   * and an email, along with a callback function.
   */
  const handleNavigationAfterEmailConfirm = (
    navigate: NavigateFunction,
    routeState: { email: string },
    actionWithPlanId: (planId: string, email: string, callback: () => void) => void,
  ) => {
    const planId = getSavedPlanId();
    const nextAction = planId
      ? () => actionWithPlanId(planId, routeState.email, () => navigate(ROUTE_PATHS.DASHBOARD))
      : () => {
          if (isMobile) {
            setIsLoading(true); // prevent redirect from <ProtectedRoute />
            navigate(ROUTE_PATHS.CHOOSE_YOUR_SUBSCRIPTION);
          } else {
            navigate(ROUTE_PATHS.DASHBOARD);
          }
        };
    // Add timeout so AWS has time to sign the confirmed user in
    // 1 second is not enough
    setTimeout(nextAction, 1500);
  };

  return (
    <ImportCartContext.Provider
      value={{
        processPlanIdFromUrl,
        getSavedPlanId,
        handleNavigationAfterEmailConfirm,
      }}
    >
      {children}
    </ImportCartContext.Provider>
  );
};

export { ImportCartContextProvider, useImportCart };
