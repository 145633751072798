import React, { useState, useEffect, createContext } from 'react';
import { createHook } from '../utils/utils';

const BluetoothAlertContext = createContext(null);
const useBluetoothAlert = () => createHook('useBluetoothAlert', BluetoothAlertContext);

function BluetoothAlertProvider({ children }) {
  const [isBluetoothAvailable, setIsBluetoothAvailable] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if ('bluetooth' in navigator) {
      navigator.bluetooth
        .getAvailability()
        .then((isAvailable) => {
          setIsBluetoothAvailable(isAvailable);
        })
        .catch((error) => {
          console.error('Error checking Bluetooth availability', error);
        });
    } else if (userAgent.includes('safari') || userAgent.includes('firefox')) {
      setIsBluetoothAvailable(true);
    } else {
      console.log('This device does not support Bluetooth');
    }
  }, []);

  return (
    <BluetoothAlertContext.Provider
      value={{
        isBluetoothAvailable,
      }}
    >
      {children}
    </BluetoothAlertContext.Provider>
  );
}

export { BluetoothAlertProvider, useBluetoothAlert };
