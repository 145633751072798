import i18n from 'i18n-js';
import { setLocale } from '@feelrobotics/strapi-sdk';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';

const translationGetters = {
  en: require('../translations/en.json'),
  ru: require('../translations/ru.json'),
  es: require('../translations/es.json'),
  de: require('../translations/de.json'),
  fr: require('../translations/fr.json'),
  ja: require('../translations/ja.json'),
};

const translations = i18n.t;

// set i18n-js config
i18n.defaultLocale = 'en';
i18n.fallbacks = true; // For unknown language fall back to English
i18n.translations = {
  en: translationGetters.en,
  ru: translationGetters.ru,
  es: translationGetters.es,
};

const language = window.navigator.language || i18n.defaultLocale;
let languageTag = language.split('-')[0];

if (!Object.keys(i18n.translations).includes(languageTag)) {
  languageTag = i18n.defaultLocale;
}

setLocale(languageTag);
i18n.locale = languageTag;

const found = Object.keys(i18n.translations).find((lang) => lang === languageTag);
dayjs.locale(found || 'en');

export { translations, languageTag };
