import { translations } from 'i18n';
import React, { createContext, useState, useEffect } from 'react';
import { createHook, extractHostname } from 'utils/utils';
import StrapiClient from 'StrapiClient';
import useLocalStorage from 'use-local-storage';
import { MAX_REQUEST_WHITELIST_AMOUNT } from 'data/constants';
import { useWebsiteCompatibility } from 'hooks/WebsiteCompatibilityHook';

const ContentChannelsContext = createContext();
const useContentChannels = () => createHook('useContentChannels', ContentChannelsContext);

function ContentChannelsProvider({ children }) {
  const [activeCategory, setActiveCategory] = useState(null);
  const [sitesFilterValue, setSitesFilterValue] = useState('');
  const [whitelistSites, setWhitelistSites] = useState([]);
  const [sortBy, setSortBy] = useState(null);

  const [favoriteSites, setFavoriteSites] = useLocalStorage('favoriteSites', []);
  const [activeMobileItem, setActiveMobileItem] = useState(null);
  const [comingSoonImage, setComingSoonImage] = useState(null);
  const [requestList, setRequestList] = useState([]);
  const [whitelistError, setWhitelistError] = useState('');
  const { checkCompatibility } = useWebsiteCompatibility();

  const addSiteToRequestList = (link) => {
    const site = {
      hostname: extractHostname(link),
      link,
    };

    const sameSiteInList = requestList.find((s) => s.hostname === site.hostname);

    if (!sameSiteInList) {
      setRequestList((prev) => [...prev, site]);
    }
  };

  const removeSiteFromRequestList = (hostname) => {
    const updatedList = requestList.filter((site) => site.hostname !== hostname);
    setRequestList(updatedList);
  };

  const whitelistErrorHandler = (url) => {
    if (!url) {
      setWhitelistError('');
      return false;
    }
    const isAlreadyWhitelisted = checkCompatibility(url);

    if (requestList.length === MAX_REQUEST_WHITELIST_AMOUNT) {
      setWhitelistError(translations('ContentChannels.MaxRequestAmountIsFive'));
      return true;
    }

    if (isAlreadyWhitelisted) {
      setWhitelistError(translations('ContentChannels.SiteIsAlreadyWhitelisted'));
      return true;
    }

    const isLink = /^https?:\/\/\S+$/.test(url);
    if (!isLink) {
      setWhitelistError(translations('ContentChannels.InvalidUrl'));
      return true;
    }

    setWhitelistError('');
    return false;
  };

  // filter sites by
  function filterWhitelistBy(sites, filterBy, category = null) {
    if (!sites || sites.length === 0) {
      return [];
    }
    let filteredSites = [];

    if (filterBy === translations('ContentChannels.Popular').toLowerCase()) {
      filteredSites = sites.filter((site) => site.popular);
    } else if (filterBy === translations('ContentChannels.Featured').toLowerCase()) {
      filteredSites = sites
        .filter((site) => site.featured)
        .sort((siteA, siteB) => siteA.featured_position - siteB.featured_position);
    } else if (
      filterBy === translations('ContentChannels.Favorite').toLowerCase() ||
      category === translations('ContentChannels.Favorite').toLowerCase()
    ) {
      filteredSites = favoriteSites;
    } else {
      filteredSites = sites;
    }

    if (category) {
      if (category === translations('ContentChannels.Popular').toLowerCase()) {
        filteredSites = filteredSites.filter((site) => site.popular);
      } else if (category === translations('ContentChannels.Featured').toLowerCase()) {
        filteredSites = filteredSites.filter((site) => site.featured);
      } else if (category === translations('ContentChannels.Favorite').toLowerCase()) {
        filteredSites = favoriteSites;
      } else {
        filteredSites = filteredSites.filter((site) => {
          return site.categories.some((cat) => {
            return cat.value === category;
          });
        });
      }
    }

    filteredSites = filteredSites.filter((site) => {
      return (
        site?.url.toLowerCase().includes(sitesFilterValue.toLowerCase()) ||
        site?.name.toLowerCase().includes(sitesFilterValue.toLowerCase())
      );
    });

    return filteredSites;
  }
  const isSitesListEmpty = filterWhitelistBy(whitelistSites).length === 0;

  // toggle favorite site ( add or remove )
  const toggleFavoriteSite = (allSites, id) => {
    const site = allSites.find((site) => site.id === id);
    const isAlreadyFavorite = favoriteSites.some((favSite) => favSite?.id === id);
    setFavoriteSites((prevFavorites) => {
      if (isAlreadyFavorite) {
        // Remove the site from favorites
        return prevFavorites.filter((favSite) => favSite?.id !== id);
      } else {
        // Add the site to favorites
        return [site, ...prevFavorites];
      }
    });
  };

  const checkIsFavorite = (id) => favoriteSites.some((site) => site?.id === id);

  // filtered favorite sites by sitesFilterValue
  const filteredFavoriteSites = favoriteSites.filter((site) => {
    return (
      site?.url.toLowerCase().includes(sitesFilterValue.toLowerCase()) ||
      site?.name.toLowerCase().includes(sitesFilterValue.toLowerCase())
    );
  });

  // set active catagory and reset filter value
  const openCategory = (category) => {
    setActiveCategory(category);
  };

  useEffect(() => {
    StrapiClient.getWhiteListSites().then((strapiResult) => {
      if (!strapiResult.error) {
        setWhitelistSites(strapiResult.response);
      }
    });
    StrapiClient.getcomingSoonImage().then((strapiResult) => {
      if (!strapiResult.error) {
        setComingSoonImage(strapiResult.response);
      }
    });
  }, []);

  return (
    <ContentChannelsContext.Provider
      value={{
        activeCategory,
        setActiveCategory,
        openCategory,
        whitelistSites,
        setWhitelistSites,
        favoriteSites,
        toggleFavoriteSite,
        filterWhitelistBy,
        sitesFilterValue,
        setSitesFilterValue,
        activeMobileItem,
        sortBy,
        setSortBy,
        setActiveMobileItem,
        filteredFavoriteSites,
        checkIsFavorite,
        comingSoonImage,
        setComingSoonImage,
        requestList,
        setRequestList,
        addSiteToRequestList,
        removeSiteFromRequestList,
        whitelistError,
        whitelistErrorHandler,
        isSitesListEmpty,
      }}
    >
      {children}
    </ContentChannelsContext.Provider>
  );
}

export { ContentChannelsProvider, useContentChannels };
