import React, { createContext, useState, useEffect } from 'react';
import billingApi from 'api/billing-api';
import { getAccessToken, getRegistrationToken } from 'api/oauth-api';
import { translations } from 'i18n';
import { toast } from 'react-toastify';
import { isMobile, isMobileSafari } from 'react-device-detect';

import { createHook } from 'utils/utils';
import { useAuth } from './AuthHook';
import { useWizard } from './WizardHook';
import {
  CHARGEBEE_SUB_STATUSES,
  SUB_STATUS,
  AFFILIATES_REF_KEY,
  AFFILIATES_REF_EXPIRATION_PERIOD_IN_DAYS,
  UPDATE_TOKEN_TIME,
  DEVICE_FEELME_PLAN_TITLE,
  FEELME_PLAN_TITLE,
  USER_ID_KEY,
} from 'data/constants';
import { useUserSettings } from './UserSettingsHook';

const SubscriptionContext = createContext();
const useSubscription = () => createHook('useSubscription', SubscriptionContext);

const SubscriptionContextProvider = ({ children }) => {
  const { authenticatedUser, temporaryUser } = useAuth();
  const { step, showNext } = useWizard();
  const { codeCurrency } = useUserSettings();
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [tokenObj, setTokenObj] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [registrationToken, setRegistrationToken] = useState(null);

  const [isLoadingSubscriptionInfo, setIsLoadingSubscriptionInfo] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const isLoading = isLoadingSubscriptionInfo || isLoadingPlans || isLoadingToken;

  const [plans, setPlans] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(SUB_STATUS.NEVER);
  const [isPartnerSubscription, setPartnerSubscription] = useState(false);
  const [isSubscriptionRenewing, setSubscriptionRenewing] = useState(false);
  // used for <GetStartedModal /> as a conditions when it should be shown
  const [subscriptionCheckedOnload, setSubscriptionCheckedOnload] = useState(false);

  const getStatusString = () => {
    if (!subscriptionInfo) {
      return translations('SubscriptionPage.NoSubscription');
    }

    if (
      subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.IN_TRIAL &&
      subscriptionInfo.next_billing_at === 0
    ) {
      return translations('SubscriptionPage.TrialExpiresAt');
    } else if (subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.IN_TRIAL) {
      return translations('SubscriptionPage.Trial');
    } else if (subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.ACTIVE) {
      return translations('SubscriptionPage.Active');
    } else if (subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.NON_RENEWING) {
      return translations('SubscriptionPage.ExpiresAt');
    } else if (subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.PAUSED) {
      return translations('SubscriptionPage.Paused');
    } else if (subscriptionInfo.status === CHARGEBEE_SUB_STATUSES.CANCELLED) {
      return translations('SubscriptionPage.Cancelled');
    }

    return translations('SubscriptionPage.NoSubscription');
  };

  const getFilteredPlans = (includeKeon) =>
    plans
      .filter((plan) => {
        return includeKeon
          ? plan.family === DEVICE_FEELME_PLAN_TITLE
          : plan.family === FEELME_PLAN_TITLE;
      })
      .sort((a, b) => b.price - a.price);

  const getPeriodString = () => {
    if (isPartnerSubscription) {
      const partnerName = subscriptionInfo.partner_name || translations('SubscriptionPage.Active');
      return `${translations('SubscriptionPage.Subscription')}: ${partnerName}`;
    }

    if (!subscriptionInfo?.period) {
      return;
    }

    return `${subscriptionInfo.period} ${translations(
      'SubscriptionPage.' + subscriptionInfo.period_unit,
    )} ${translations('SubscriptionPage.Subscription')}`;
  };

  const manageSubscription = async () => {
    try {
      if (isPartnerSubscription && subscriptionInfo.partner_url) {
        window.open(subscriptionInfo.partner_url, '_blank');
        return;
      }

      if (isMobile) {
        // GTM not working inside VW Mobile, refers to https://feelrobotics.atlassian.net/browse/DEV-5873
        const result = await billingApi('/portal');
        window.open(result.access_url, '_blank');
      } else {
        const cbInstance = window.Chargebee.getInstance();
        const portalInstance = cbInstance.createChargebeePortal();
        cbInstance.setPortalSession(async () => {
          return await billingApi('/portal');
        });
        portalInstance.open({
          close: () => {
            refreshSubscriptionInfo();
          },
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAffiliateCode = () => {
    const savedValue = localStorage.getItem(AFFILIATES_REF_KEY);
    const refCode = !!savedValue ? JSON.parse(savedValue) : '';
    if (!!refCode) {
      // Make sure the referral code is not expired
      if (
        (Date.now() - refCode.slice(0, 13)) / (1000 * 60 * 60 * 24) <
        AFFILIATES_REF_EXPIRATION_PERIOD_IN_DAYS
      ) {
        return refCode.slice(13);
      }
    }
  };

  const openCheckout = async (planId, email, onClose = () => {}) => {
    try {
      const payload = {
        plan_id: planId,
        email: email,
        ref: getAffiliateCode(),
      };
      if (isMobileSafari) {
        // Safari only allows to be called as a result of a direct user action
        // So for safari we navigate to chargebee in the same window
        // After the payment is done, user is redirected to the portal
        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        const result = await billingApi('/checkout', true, {}, { method: 'POST' }, payload);
        window.location.href = result.url;
      } else if (isMobile) {
        // GTM not working inside VW Mobile, refers to https://feelrobotics.atlassian.net/browse/DEV-5873
        const result = await billingApi('/checkout', true, {}, { method: 'POST' }, payload);
        window.open(result.url, '_blank');
      } else {
        const cbInstance = window.Chargebee.getInstance();
        cbInstance.openCheckout({
          hostedPage: async () => {
            return await billingApi('/checkout', true, {}, { method: 'POST' }, payload);
          },
          close: () => {
            refreshSubscriptionInfo();
            onClose();
          },
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    refreshSubscriptionInfo();
  }, [authenticatedUser?.attributes?.email]);

  useEffect(() => {
    setIsLoadingPlans(true);
    if (codeCurrency) {
      billingApi(`/subscriptions/${codeCurrency}`, false)
        .then((data) => {
          setIsLoadingPlans(false);
          setPlans(data.sort((a, b) => a.price - b.price));
        })
        .catch((error) => toast.error(error.message));
    }
  }, [codeCurrency]);

  const refreshSubscriptionInfo = () => {
    if (authenticatedUser) {
      setIsLoadingSubscriptionInfo(true);
      billingApi('/customer/subscription')
        .then((data) => {
          setSubscriptionInfo({
            active_until: data.active_until,
            period: data.billing_period,
            period_unit: data.billing_period_unit,
            next_billing_at: data.next_billing_at * 1000,
            status: data.status,
            trial_end: data.trial_end,
            partner_name: data.partner_name,
            partner_url: data.partner_url,
          });
          setSubscriptionStatus(
            [
              CHARGEBEE_SUB_STATUSES.ACTIVE,
              CHARGEBEE_SUB_STATUSES.IN_TRIAL,
              CHARGEBEE_SUB_STATUSES.NON_RENEWING,
            ].includes(data.status)
              ? SUB_STATUS.ACTIVE
              : SUB_STATUS.INACTIVE,
          );
          setSubscriptionRenewing(
            [
              CHARGEBEE_SUB_STATUSES.ACTIVE,
              CHARGEBEE_SUB_STATUSES.IN_TRIAL,
              CHARGEBEE_SUB_STATUSES.PAUSED,
            ].includes(data.status),
          );
          setPartnerSubscription(Boolean(data.partner_name || data.partner_url));

          // user just subscribed, show next WizardStep
          if (step === 1) {
            showNext();
          }

          // remove user id after successful subscription
          sessionStorage.removeItem(USER_ID_KEY);
        })
        .catch(() => {
          setSubscriptionInfo(null);
          setSubscriptionStatus(SUB_STATUS.NEVER);
        })
        .finally(() => {
          setIsLoadingSubscriptionInfo(false);
          setSubscriptionCheckedOnload(true);
        });
    } else {
      setSubscriptionInfo(null);
      setSubscriptionStatus(SUB_STATUS.NEVER);
      setSubscriptionCheckedOnload(false);
    }
  };

  const fetchTokens = () => {
    setIsLoadingToken(true);

    // Returns a tokens that can be used to authenticate against the ML server
    Promise.allSettled([
      billingApi('/token')
        .then((data) => {
          setTokenObj(data);
        })
        .catch((err) => {
          console.error('Error when receiving old token:', err);
          setTokenObj(null);
        }),

      getAccessToken()
        .then((accessToken) => {
          setAccessToken(accessToken);
          return getRegistrationToken();
        })
        .then((registrationToken, ws_server_url) => {
          setRegistrationToken(registrationToken);
        })
        .catch((err) => {
          console.error('Error when receiving OAuth tokens:', err);
          setAccessToken(null);
          setRegistrationToken(null);
        }),
    ]).finally(() => setIsLoadingToken(false));
  };

  useEffect(() => {
    if (subscriptionInfo) {
      fetchTokens();

      const interval = setInterval(() => {
        fetchTokens();
      }, UPDATE_TOKEN_TIME);

      return () => {
        console.log('clear interval');
        clearInterval(interval);
      };
    } else {
      setTokenObj(null);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (step === 4 && !subscriptionInfo) {
      // if the user doesn't have a subscription yet, move onboading to the next step
      showNext();
    }
  }, [step, subscriptionInfo]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionInfo,
        getStatusString,
        getPeriodString,
        manageSubscription,
        openCheckout,
        tokenObj,
        accessToken,
        registrationToken,
        isLoading,
        subscriptionStatus,
        isSubscriptionRenewing,
        subscriptionCheckedOnload,
        getFilteredPlans,
        isPartnerSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContextProvider, useSubscription };
