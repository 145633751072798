import React from 'react';
import cn from 'classnames';
import styles from './style.module.scss';

interface ButtonProps {
  secondary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  text: string;
  icon?: string;
  style?: React.CSSProperties;
  pressed?: boolean;
  link?: string;
  hideShadow?: boolean;
  type?: 'submit' | 'reset';
}

const Button = ({
  secondary,
  disabled,
  onClick,
  text,
  icon,
  pressed,
  link,
  hideShadow,
  type,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className={cn(
        secondary ? styles.secondary : styles.main,
        pressed && styles.pressed,
        icon && styles.includeIcon,
        hideShadow && styles.hideShadow,
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {link ? (
        <a href={link} target="_blank">
          {text}
        </a>
      ) : (
        text
      )}
      {icon ? <img className={styles.image} src={icon} alt={text} /> : null}
    </button>
  );
};

export default Button;
