import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import { wait } from '../../../utils/wait';
import { AsyncQueue } from '../../AsyncQueue';

/**
 * Class used to encapsulate Stroker movements in response to predictions
 */
export default class Stroker extends BaseDeviceWrapper {
  private commandQueue: AsyncQueue;
  private ambientMovementMixin: StrokerAmbientMovementMixin;
  private currentPosition: number;
  maxPosition: number;
  minPosition: number;
  fullCycleTimeMsec: number;

  constructor(
    device: BluetoothDevice,
    serviceUuid: string,
    commandCharacteristicUuid: string,
    image: File,
    statusCharacteristicUuid: string,
    maxPosition: number,
    minPosition: number,
    fullCycleTimeMsec: number,
  ) {
    super(device, serviceUuid, commandCharacteristicUuid, image, statusCharacteristicUuid);
    this.commandQueue = new AsyncQueue();
    this.ambientMovementMixin = new StrokerAmbientMovementMixin();
    this.currentPosition = 0;
    this.maxPosition = maxPosition;
    this.minPosition = minPosition;
    this.fullCycleTimeMsec = fullCycleTimeMsec;
  }

  async getBattery(): Promise<number> {
    throw new Error('Not implemented');
  }

  async writeValue(position: number, speed: number): Promise<void> {
    throw new Error('Not implemented');
  }

  async writePauseValue(): Promise<void> {
    throw new Error('Not implemented');
  }

  async writeMovementBetween(
    minPosition: number,
    maxPosition: number,
    speed: number,
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  /**
   * Initiates the movement to the defined position
   * @param {number} position - the target position (0 to 100)
   * @param {number} speed - the speed with which the device needs to move to the target position
   */
  async moveTo(position: number, speed: number): Promise<void> {
    const pos = Math.max(this.minPosition, Math.min(this.maxPosition, Math.round(position)));
    const spd = Math.max(0, Math.min(99, Math.round(speed)));
    this.commandQueue.execute(() => {
      this.writeValue(pos, spd);
    });
  }

  /**
   * Initiates the device's repetitive movement from min position to max position
   * With a given period
   * @param {number} periodMsec - the duration of one up-down movement
   */
  async setDeviceMovement(periodMsec: number): Promise<void> {
    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    const speedRaw = Math.round((maxIntensity / 100) * (this.fullCycleTimeMsec / periodMsec) * 100);
    const speed = Math.min(100, speedRaw);
    const min = 0;
    const max = Math.max(0, Math.min(99, maxIntensity));
    this.commandQueue.execute(() => {
      this.writeMovementBetween(min, max, speed);
    });
  }

  /**
   * Sends the "paused" signal to the device
   */
  async writePaused() {
    this.commandQueue.execute(() => {
      this.writePauseValue();
    });
  }

  /**
   * Sends the target position to the device
   * @param {number} percent - target position
   * @returns void
   */
  async write(percent: number, speed: number) {
    if (this.currentPosition === percent) {
      // Don't write the same value to the device twice
      return;
    }
    this.currentPosition = percent;

    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    percent = 100 - percent; // Device min position is on top, max - on bottom
    let pos = Math.min(100, Math.round((percent * maxIntensity) / 100));
    const spd = Math.round((speed * maxIntensity) / 100);
    await this.moveTo(pos, spd);
    await this.ambientMovementMixin.onWrite();
  }

  /**
   * Sets the ambient movement intensity
   * @param {number} percent - the intensity of the ambient movement
   */
  async setAmbientMovement(percent: number) {
    await this.ambientMovementMixin.setAmbientMovement(percent);
  }

  /**
   * Sets the maximum intensity for the device
   * @param {number} percent - the value of maximum intensity to set
   */
  async setMaxIntensity(percent: number) {
    await this.ambientMovementMixin.setMaxIntensity(percent);
  }

  /**
   * Starts the ambient movement for the device
   * (The sequence of repetitive movements sent to the device when no action is happening)
   */
  async onTimer() {
    await this.ambientMovementMixin.onTimer(async (pos: number, speed: number) => {
      await this.moveTo(pos, speed);
    });
  }

  /**
   * Sends test commands to the device
   */
  async testDevice() {
    await this.setDeviceMovement(3000);
    await wait(2000);
    await this.writePaused();
  }
}
