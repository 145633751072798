import React from 'react';

import CheckBox from 'components/common/CheckBox';
import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

export const TestSignalSwitch = () => {
  const { testSignal, setTestSignal } = useChromeExtensionPopup();

  return (
    <>
      <div
        className={styles.autopilotCheckboxRow}
        onClick={() => {
          setTestSignal(!testSignal);
        }}
      >
        <CheckBox checked={testSignal} />
        <span>Test signal</span>
      </div>
      <div>
        <span className={styles.settingsLabelDescription}>Test signals from server</span>
      </div>
    </>
  );
};
