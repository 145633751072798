import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import image from 'images/devices/onyx-promotion.png';
import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import StrokerFinishModeMixin from './StrokerFinishModeMixin';

const serviceUuid = '00001900-0000-1000-8000-00805f9b34fb';
const statusCharacteristicUuid = 0x1901;
const commandCharacteristicUuid = 0x1902;

const names = ['Onyx+', 'Onyx+Realm', 'Realm1.1', 'Rey'];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Onyx extends BaseDeviceWrapper {
  constructor(device) {
    super(device, serviceUuid, commandCharacteristicUuid, image, statusCharacteristicUuid);
    this.ambientMovementMixin = new StrokerAmbientMovementMixin();
    this.finishModeMixin = new StrokerFinishModeMixin();
    this.currentPosition = 0;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return 'Kiiroo';
  }

  async connect() {
    await super.connect();
    // Move Onyx to the Bluetooth mode
    await this.motorChar.writeValue(new Uint8Array([0x00]));
  }

  async disconnect() {
    await super.disconnect();
  }

  /**
   * Move the device to specific position
   * @param {int} speed - device speed [0..99]
   * @param {int} position - device position [0..99], where 0 is lower position (full penetration)
   *                         and 99 - is the top position (no penetration)
   */
  async moveTo(position, speed) {
    const pos = 99 - Math.max(0, Math.min(99, Math.round(position)));
    const spd = Math.max(0, Math.min(99, Math.round(speed)));
    await this.motorChar.writeValue(new Uint8Array([0x03, 0x00, spd, pos]));
  }

  async write(percent, speed, isBlowJob) {
    // Do not use intermediate positions to make movement smoother
    percent = percent === 100 ? 100 : 0;
    if (this.currentPosition === percent) {
      // Don't write the same value to the device twice
      return;
    }
    this.currentPosition = percent;

    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    percent = 100 - percent; // Device min position is on top, max - on bottom
    let pos = Math.min(100, Math.round((percent * maxIntensity) / 100));
    const spd = Math.round((speed * maxIntensity) / 100);
    await this.moveTo(pos, spd);
    this.ambientMovementMixin.onWrite();
  }

  async setAmbientMovement(percent) {
    // Lower intensity doesn't move Onyx at all
    const newPercent = percent < 25 ? 0 : 50 + percent / 2;
    this.ambientMovementMixin.setAmbientMovement(newPercent);
  }

  async setMaxIntensity(percent) {
    this.ambientMovementMixin.setMaxIntensity(percent);
  }

  async onTimer() {
    await this.ambientMovementMixin.onTimer((pos, speed) => {
      this.moveTo(pos, speed);
    });
    await this.finishModeMixin.onTimer();
  }

  async getBattery() {
    const data = await this.statusChar.readValue();
    return data?.getUint8(5);
  }
}
