import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';

const deviceWrappers = [
  require('./wrappers/Keon').default,
  require('./wrappers/Onyx').default,
  require('./wrappers/Onyx2').default,
  require('./wrappers/PulseInteractive').default,
  require('./wrappers/PlayBulb').default,
  require('./wrappers/PowerBlow').default,
  require('./wrappers/Launch').default,
  require('./wrappers/SvakomSam').default,
  require('./wrappers/SvakomAlex').default,
  require('./wrappers/SvakomAlex2').default,
  require('./wrappers/Handy.js').default,
  require('./wrappers/Titan11').default,
  require('./wrappers/AutoblowUltra').default,
  require('./wrappers/UnsupportedDevice').default,
];

/**
 * Get array of all possible device names
 */
export function getAllDeviceNames() {
  return deviceWrappers.reduce((names, wrapper) => names.concat(wrapper.deviceNames), []);
}

export function createDevice(webBleDevice, stateCallback) {
  const deviceName = webBleDevice.name;
  let result = null;
  for (let wrapper of deviceWrappers) {
    if (wrapper.deviceNames.includes(deviceName)) {
      result = new wrapper(webBleDevice);
      result.connectionStateCallback = stateCallback;
      break; // exit the loop once a device has been found
    }
  }
  return result;
}

/**
 * Connect a bluetooth device wrapper.
 * Device wrapper is a class around BluetoothDevice.
 * https://developer.mozilla.org/en-US/docs/Web/API/BluetoothDevice
 * The wrapper cannot exist without BluetoothDevice and cannot be created without
 * actual connection to the bluetooth device.
 * @return a promise with is resolved with the device wrapper
 */
export const discoverDevice = async (stateCallback) => {
  const allDevices = getAllDeviceNames().map((name) => ({ name }));
  const allServices = deviceWrappers.reduce(
    (services, wrapper) => services.concat(wrapper.services),
    [],
  );
  const options = {
    filters: allDevices,
    optionalServices: allServices,
  };

  let device = null;
  try {
    device = await navigator.bluetooth.requestDevice(options);
  } catch (error) {
    if (error.toString().includes('User cancelled the requestDevice() chooser.')) {
      sendToAnalyticsCustomEventObj('user_cancelled_chooser');
    } else if (error.toString().includes('Bluetooth adapter not available.')) {
      sendToAnalyticsCustomEventObj('bluetooth_adapter_not_available', {
        user_agent: navigator.userAgent,
      });
    } else {
      sendToAnalyticsCustomEventObj('popup_connect_device_exception', {
        message: error.toString(),
      });
    }

    // Device selection was probably cancelled
    return null;
  }
  const deviceWrapper = await createDevice(device, stateCallback);
  return deviceWrapper;
};
