import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import image from '../../../images/devices/titan-promotion.png';
import { AsyncQueue } from '../../AsyncQueue';

const serviceUuid = '00001900-0000-1000-8000-00805f9b34fb';
const statusCharacteristicUuid = 0x1901;
const commandCharacteristicUuid = 0x1902;

const DEVICE_NAME = 'Titan1.1';
const COMPANY_NAME = 'Kiiroo';
const MAX_DEVICE_INTENSITY = 100;
const MOTOR_TIME = 100; // ms

/**
 * Create a device wrapper over the Web Bluetooth device object
 * For the Titan 1.1 device.
 * See full documentation at
 * https://feelrobotics.atlassian.net/wiki/spaces/FM/pages/448856086/18-3+Titan+firmware+1.3+20181113
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Titan11 extends BaseDeviceWrapper {
  private commandQueue: AsyncQueue;
  private ambientMovementMixin: StrokerAmbientMovementMixin;
  private averageDeviceResponseTime: number;
  private motorTime: number;
  private motorState: object;
  private operationStartTime: number;

  constructor(device: BaseDeviceWrapper) {
    super(device, serviceUuid, commandCharacteristicUuid, image, statusCharacteristicUuid);

    this.ambientMovementMixin = new StrokerAmbientMovementMixin();
    this.commandQueue = new AsyncQueue();

    this.averageDeviceResponseTime = 100; // average motor response time

    // motors vibration intensity
    this.motorState = {
      0: 0, // the motor is located closer to the charging port
      1: 0,
      2: 0, // the motor is located closer to the front
    };

    this.motorTime = MOTOR_TIME; // expected motor reaction time
  }

  static get deviceNames(): Array<string> {
    return [DEVICE_NAME];
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services(): Array<string> {
    return [serviceUuid];
  }

  get companyName(): string {
    return COMPANY_NAME;
  }

  /**
   * Send commands to the device
   *
   * @param {list} data array of device command
   * @returns
   */
  async writeValue(data: Array<number>): Promise<void> {
    if (data.length === 0) return;

    this.operationStartTime = performance.now();
    this.motorChar?.writeValue(new Uint8Array(data));
  }

  /**
   * Сonverting data into commands for the device
   *
   * @param {dict} data intensity of motors {motorId: intensity, ...}
   * @returns {list} array of device command
   */
  createArrayForMotorsInColumn(data: object): Array<number> {
    let result = [];
    for (let motorId in data) {
      let motorCode = parseInt(motorId);
      const percent = data[motorCode];

      if (this.motorState[motorCode] === percent) continue;

      this.motorState[motorCode] = percent;
      result.push(0x02, motorCode, percent);
    }
    return result;
  }

  /**
   * Create a command for the device to change the intensity of the motors for each row separately
   *
   * @param {Object} data intensity of motors {motorId: intensity, ...}
   */
  actionAllMotorsByColumn(data: object) {
    let array = this.createArrayForMotorsInColumn(data);
    this.commandQueue.execute(() => {
      this.writeValue(array);
    });
  }

  /**
   * Will check the motor status: is any of them vibrate
   */
  isMotorWorking(): boolean {
    return Object.values(this.motorState).reduce((a, b) => a + b, 0) > 0;
  }

  /**
   * Makes the whole device vibrate as a response to the signal from the server
   *
   * @param {Number} percent 0-100
   */
  actionAllMotors(percent: number) {
    for (let motorCode in this.motorState) {
      this.motorState[motorCode] = percent;
    }

    this.commandQueue.execute(() => {
      this.writeValue([0x01, percent]);
    });
  }

  /**
   * This function set the motor intensity with the given ID.
   *
   * @param {number} motorId The ID of the motor to stop:
   * @param {number} percent 0-100
   */
  actionMotor(motorId: number, percent: number) {
    if (this.motorState[motorId] === percent) {
      return;
    }

    this.motorState[motorId] = percent;
    this.commandQueue.execute(() => {
      this.writeValue([0x02, motorId, percent]);
    });
  }

  /**
   * Stops all the motors
   */
  stopAllMotors() {
    this.actionAllMotors(0);
  }

  /**
   * This function stops the motor with the given ID.
   *
   * @param {number} motorId The ID of the motor to stop:
   * 0 for the bottom motors, 1 for the middle motors, 2 for the top motors.
   */
  stopMotorsInRow(motorId: number) {
    this.actionMotor(motorId, 0);
  }

  /**
   * Convert percent to motor intensity as a stroker
   *
   * @param {number} percent 0-100
   * @returns {Object} data intensity of motors {motorId: intensity, ...}
   */
  calcCarriagePos(percent: number): object {
    let maxIntensity = this.ambientMovementMixin.maxIntensity;
    return {
      0: percent > 10 && percent <= 70 ? maxIntensity : 0,
      1: percent < 10 ? 0 : maxIntensity < 35 ? 0 : maxIntensity / 2,
      2: percent > 70 ? maxIntensity : 0,
    };
  }

  /**
   * Convert percent to motor intensity as a vibrator
   *
   * @param {number} percent 0-100
   * @returns {Object} data intensity of motors {motorId: intensity, ...}
   */
  calcBarPos(percent: number): object {
    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    return {
      0: percent > 10 ? maxIntensity : 0,
      1: percent > 50 ? maxIntensity / 2 : 0,
      2: percent > 70 ? maxIntensity : 0,
    };
  }

  async writePaused(): Promise<void> {
    this.stopAllMotors();
  }

  async write(percent: number, speed: number, isBlowJob: boolean = false): Promise<void> {
    this.ambientMovementMixin.currentPosition = percent;

    let data = this.calcCarriagePos(percent);
    this.actionAllMotorsByColumn(data);
  }

  async setMaxIntensity(percent: number): Promise<void> {
    this.ambientMovementMixin.setMaxIntensity(Math.min(MAX_DEVICE_INTENSITY, percent));
  }

  async getBattery(): Promise<void> {
    const data = await this.statusChar.readValue();
    return data?.getUint8(5);
  }

  /**
   * Will make all motors vibrate row by row
   */
  async testDevice(): Promise<void> {
    console.log('testing Titan');
    for (let i = 0; i < 3; i++) {
      for (let percent of [40, 60, 80, 60]) {
        let data = this.calcCarriagePos(percent);
        this.actionAllMotorsByColumn(data);
        await new Promise((resolve) => {
          setTimeout(resolve, this.motorTime * (i + 2));
        });
      }
    }
    this.stopAllMotors();
  }

  async setAmbientMovement(percent: number): Promise<void> {
    return this.ambientMovementMixin.setAmbientMovement(percent);
  }

  async onTimer(): Promise<void> {
    this.ambientMovementMixin.onTimer((pos: number, speed: number) => {
      this.actionAllMotors(pos);
    });

    const end = performance.now();
    if (this.isMotorWorking() && end - this.operationStartTime > this.motorTime * 5) {
      this.stopAllMotors();
    }
  }
}
