import { getMulti, downloadImageAsBase64, getImageUrl } from '@feelrobotics/strapi-sdk';

const NOTE_TYPE = 'notification';

type NotificationResult = {
  response: Object[];
  error: string | undefined;
};

async function getNotifications(): Promise<NotificationResult> {
  const searchString = 'fm-notifications';

  const res = await getMulti(searchString, NOTE_TYPE);

  const mapped = (await Promise.all(
    await (res.response as Object[]).map(async (item) => {
      return {
        date: item['attributes']['date'],
        id: item['id'],
        title: item['attributes']['title'],
        body: item['attributes']['body'],
        imageUrl: item['attributes']['image_url'],
        videoUrl: item['attributes']['video_url'],
        image:
          item['attributes']['image']['data'] === null
            ? ''
            : await downloadImageAsBase64(getImageUrl(item['attributes'], 'image')),
      };
    }),
  )) as Object[];

  return {
    response: mapped,
    error: res.error,
  };
}

export { getNotifications };
