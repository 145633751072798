export type DeviceInfo = {
  firmwareVersion: number;
  firmwareBranch: string;
  hardwareVersion: string;
  firmwareStatus: FirmwareStatus;
  mac: string;
};

export type DeviceState = {
  operationalMode: DeviceOperationalMode;
  localScript: number;
  localScriptSpeed: number;
  motorTemperature: number;
  oscillatorTargetSpeed: number;
  oscillatorLowPoint: number;
  oscillatorHighPoint: number;
  syncScriptCurrentTime: number;
  syncScriptOffsetTime: number;
  syncScriptToken: string;
  syncScriptLoop: boolean;
};

export type Funscript = {
  metadata: {
    id: number;
    version: number;
  };
  actions: {
    at: number;
    pos: number;
  }[];
};

export type FirmwareStatus = keyof typeof FIRMWARE_STATUS;
export type DeviceOperationalMode = keyof typeof DEVICE_OPPERATIONAL_MODE;
export type EmbeddedDeviceOperationalModeCode =
  keyof typeof DEVICE_OPPERATIONAL_MODE_MAP;

export const FIRMWARE_STATUS = {
  UP_TO_DATE: 'UP_TO_DATE',
  UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
  UPDATE_REQUIRED: 'UPDATE_REQUIRED',
} as const;

export const DEVICE_OPPERATIONAL_MODE = {
  POWERED_OFF: 'POWERED_OFF',
  CALIBRATION: 'CALIBRATION',
  REST_AT_TOP: 'REST_AT_TOP',
  INIT: 'INIT',
  ONLINE_CONNECTED: 'ONLINE_CONNECTED',
  SYNC_SCRIPT_PLAYING: 'SYNC_SCRIPT_PLAYING',
  SYNC_SCRIPT_PAUSED: 'SYNC_SCRIPT_PAUSED',
  LOCAL_SCRIPT_PLAYING: 'LOCAL_SCRIPT_PLAYING',
  LOCAL_SCRIPT_PAUSED: 'LOCAL_SCRIPT_PAUSED',
  OSCILLATOR_PLAYING: 'OSCILLATOR_PLAYING',
  OSCILLATOR_PAUSED: 'OSCILLATOR_PAUSED',
  GO_TO: 'GO_TO',
  SETUP: 'SETUP',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  FIRMWARE_UPDATING: 'FIRMWARE_UPDATING',
  ERROR_MOTOR_STUCK: 'ERROR_MOTOR_STUCK',
  ERROR_CALIBRATION: 'ERROR_CALIBRATION',
  ERROR_OVERHEATING: 'ERROR_OVERHEATING',
} as const;

export const DEVICE_OPPERATIONAL_MODE_MAP = {
  0: DEVICE_OPPERATIONAL_MODE.POWERED_OFF,
  10: DEVICE_OPPERATIONAL_MODE.CALIBRATION,
  15: DEVICE_OPPERATIONAL_MODE.REST_AT_TOP,
  20: DEVICE_OPPERATIONAL_MODE.INIT,
  30: DEVICE_OPPERATIONAL_MODE.LOCAL_SCRIPT_PLAYING,
  40: DEVICE_OPPERATIONAL_MODE.LOCAL_SCRIPT_PAUSED,
  50: DEVICE_OPPERATIONAL_MODE.ONLINE_CONNECTED,
  60: DEVICE_OPPERATIONAL_MODE.SYNC_SCRIPT_PLAYING,
  70: DEVICE_OPPERATIONAL_MODE.SYNC_SCRIPT_PAUSED,
  100: DEVICE_OPPERATIONAL_MODE.OSCILLATOR_PLAYING,
  110: DEVICE_OPPERATIONAL_MODE.OSCILLATOR_PAUSED,
  120: DEVICE_OPPERATIONAL_MODE.GO_TO,
  130: DEVICE_OPPERATIONAL_MODE.SETUP,
  140: DEVICE_OPPERATIONAL_MODE.LOADING,
  150: DEVICE_OPPERATIONAL_MODE.FIRMWARE_UPDATING,
  500: DEVICE_OPPERATIONAL_MODE.ERROR,
  510: DEVICE_OPPERATIONAL_MODE.ERROR_MOTOR_STUCK,
  520: DEVICE_OPPERATIONAL_MODE.ERROR_CALIBRATION,
  530: DEVICE_OPPERATIONAL_MODE.ERROR_OVERHEATING,
};

export class ValidationError extends Error {
  code = 'ValidationError';
  statusCode = 400;
  constructor(message = 'Validation error') {
    super(message);
    this.name = 'ValidationError';
  }
}

export class DeviceNotConnectedError extends Error {
  code = 'DeviceNotConnectedError';
  statusCode = 502;
  constructor(message = 'Device not connected') {
    super(message);
    this.name = 'DeviceNotConnectedError';
  }
}
export class DeviceNotInitializedError extends Error {
  code = 'DeviceNotConnectedError';
  statusCode = 502;
  constructor(message = 'Device not connected') {
    super(message);
    this.name = 'DeviceNotConnectedError';
  }
}

export class DeviceTimeoutError extends Error {
  code = 'DeviceTimeoutError';
  statusCode = 504;
  constructor(message = 'Device timeout') {
    super(message);
    this.name = 'DeviceTimeoutError';
  }
}

export class DeviceCommandResultError extends Error {
  code = 'DeviceCommandResultError';
  statusCode = 400;
  constructor(message = 'Device command error') {
    super(message);
    this.name = 'DeviceCommandResultError';
  }
}

export class UnauthorizedRequestError extends Error {
  code = 'UnauthorizedRequest';
  statusCode = 401;
  constructor(message = 'Unauthorized request') {
    super(message);
    this.name = 'UnauthorizedRequest';
  }
}

export class NotFoundError extends Error {
  code = 'Notfound';
  statusCode = 404;
  constructor(message = 'NotFound') {
    super(message);
    this.name = 'Notfound';
  }
}
