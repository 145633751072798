import React, { useState } from 'react';
import { translations } from 'i18n';

import styles from './style.module.scss';
import feelmeLogo from 'images/logo.png';
import { openVWBInStore } from 'utils/utils';
import StepItem from 'components/StepItem';
import ToggleButton from './components/ToggleButton';
import DeviceCard from './components/DeviceCard';
import { DEVICES, STEPS } from './data';
import Button from 'components/common/Button';

const NotDesktopBanner = () => {
  const [toggled, setToggled] = useState<boolean>(false);

  return (
    <div className={styles.notDesktopBannerContainer}>
      <div className={styles.bannerHeader}>
        <img className={styles.feelmeLogo} src={feelmeLogo} alt="Logo" />
      </div>
      <div className={styles.bannerTitle}>
        {translations('NotDesktopBanner.DownloadOurVideoWatcherBrowserApp')}
      </div>
      <Button
        text={translations('NotDesktopBanner.DownloadApp')}
        onClick={openVWBInStore}
        hideShadow
      />
      <div className={styles.bannerSteps}>
        <div className={styles.stepsTitle}>
          {translations('NotDesktopBanner.FollowTheNextSteps')}
        </div>
        <div className={styles.steps}>
          {STEPS.map(({ image, text }, index) => {
            return <StepItem key={index} image={image} text={text} styles={{}} />;
          })}
        </div>
      </div>
      <div className={styles.supportedDevicesTitle}>
        {translations('NotDesktopBanner.SupportedDevices')}
      </div>
      <ToggleButton toggled={toggled} setToggled={setToggled} />
      <div className={styles.supportedDevices}>
        {DEVICES.map(
          ({ company, deviceImage, name, link, background, borderColor, shadowColor }, index) => {
            if (
              (!toggled && company === translations('Companies.Kiiroo')) ||
              (toggled && company === translations('Companies.Handy'))
            ) {
              return (
                <DeviceCard
                  background={background}
                  shadowColor={shadowColor}
                  borderColor={borderColor}
                  key={index}
                  deviceImage={deviceImage}
                  name={name}
                  link={link}
                  namePadding={company === translations('Companies.Handy')}
                />
              );
            }
            return null;
          },
        )}
      </div>
    </div>
  );
};

export default NotDesktopBanner;
