import React from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTE_PATHS } from 'data/constants';

const ProtectedRoute = ({ user, redirectPath = ROUTE_PATHS.DASHBOARD, children }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
