import React from 'react';
import { translations } from 'i18n';

import styles from './style.module.scss';

const BlockedDomain = ({ siteUrl }) => {
  return (
    <div className={styles.container}>
      <p className={styles.notSupported}>
        {translations('UnsupportedDomain.NotSupported', {
          site: siteUrl ? siteUrl : 'unknown',
        })}
      </p>
      <p className={styles.text}>{translations('UnsupportedDomain.ClickBelow')}</p>
      {/* Disabled for MVP */}
      {/* <a className={styles.link} href="www.google.nl">
        {translations('UnsupportedDomain.Link')}
      </a> */}
      <p className={styles.text}>{translations('UnsupportedDomain.FavoriteWebsite')}</p>
      <p className={styles.text}>{translations('UnsupportedDomain.NotaBene')}</p>
      {/* Disabled for MVP */}
      {/* <button className={styles.sendButton}>
        {translations('UnsupportedDomain.Send')}
      </button> */}
    </div>
  );
};

export default BlockedDomain;
