import styles from './style.module.scss';
import kiirooLogo from 'images/KIIROO-logo.png';
import handyLogo from 'images/HANDY-logo.png';
import { translations } from 'i18n';

interface ToggleButtonProps {
  toggled: boolean;
  setToggled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleButton = ({ toggled, setToggled }: ToggleButtonProps) => {
  return (
    <div className={styles.toggleSwitchContainer}>
      <div className={styles.logosContainer}>
        <div className={styles.logoContainer} onClick={() => setToggled(false)}>
          {translations('Companies.Kiiroo')}
        </div>
        <div className={styles.logoContainer} onClick={() => setToggled(true)}>
          {translations('Companies.HandyName')}
        </div>
      </div>
      <label>
        <input type="checkbox" checked={toggled} readOnly />
        <span></span>
      </label>
    </div>
  );
};

export default ToggleButton;
