import React, { createContext, useReducer, useRef } from 'react';
import { createHook } from 'utils/utils';

const MLPerformanceContext = createContext();

export const useMLPerformance = () => createHook('useMLPerformance', MLPerformanceContext);

const LAST_FRAMES_COUNT = 90;
const INITIAL_FRAMES_STATE = {
  // array of { frameNo, sentTimestamp, receivedTimestamp }
  lastFrames: [],
  serverDroppedFramePercentage: 0,
  averageServerTurnaroundMsec: 0,
  frameNo: 0, // Last processed frame no
  // Flag indicating whether we are receiving frames or not.
  // Could be true if video is paused or the video tab is
  // not active.
  paused: false,
};

// If we don't receive new frames for this amount of time, we consider video paused
const VIDEO_PAUSE_DETECT_MSEC = 200;

const framesReducer = (state, action) => {
  switch (action.type) {
    case 'onFrame': {
      const { frameNo, timestamp } = action;
      const frame = state.lastFrames.find((f) => f.frameNo === frameNo);
      if (frame) {
        // This frame has been already processed
        return {
          ...state,
          paused: false,
        };
      }
      // Append the new frame
      const lastFrames = [...state.lastFrames, { frameNo, sentTimestamp: timestamp }];
      // Trim the frames array to LAST_FRAMES_COUNT length
      lastFrames.splice(0, Math.max(0, lastFrames.length - LAST_FRAMES_COUNT));
      const frameCount = lastFrames.length;
      const fps = Math.round(
        (frameCount / (lastFrames[frameCount - 1].sentTimestamp - lastFrames[0].sentTimestamp)) *
        1000,
      );
      return { ...state, lastFrames, fps, paused: false };
    }
    case 'pause': {
      return { ...state, paused: true, lastFrames: [] };
    }
    case 'onMLServerMessage': {
      const { frameNo, timestamp } = action;
      const frame = state.lastFrames.find((f) => f.frameNo === frameNo);
      if (!frame) {
        // This frame has gone already
        return state;
      }
      const lastFrames = [...state.lastFrames];
      frame.receivedTimestamp = timestamp;
      const processedFrames = lastFrames.filter((f) => f.receivedTimestamp);
      const averageServerTurnaroundMsec = Math.round(
        processedFrames.reduce(
          (prev, cur) => prev + (cur.receivedTimestamp - cur.sentTimestamp),
          0,
        ) / processedFrames.length,
      );

      // Calculate dropped frames
      const reversed = lastFrames.slice().reverse();
      const lastProcessedIndex = reversed.findIndex((f) => f.receivedTimestamp);
      const processedOnly = reversed.slice(lastProcessedIndex);
      const droppedCount = processedOnly.reduce((prev, cur) => {
        return prev + (cur.receivedTimestamp ? 0 : 1);
      }, 0);
      const serverDroppedFramePercentage = Math.round((droppedCount / processedOnly.length) * 100);

      return {
        ...state,
        lastFrames,
        serverDroppedFramePercentage,
        averageServerTurnaroundMsec,
        frameNo,
      };
    }
    default:
      throw new Error();
  }
};

export const MLPerformanceContextProvider = ({ children }) => {
  const [framesState, framesDispatch] = useReducer(framesReducer, INITIAL_FRAMES_STATE);
  const pauseDetectTimeout = useRef(null);

  /**
   * Record frame processing on client (in browser)
   * @param {int} frameNo - Frame index
   * @param {bool} isDropped - Is the frame processind dropped on client
   * @param {float} timestamp - frame timestamp
   */
  const onFrame = (frameNo, timestamp) => {
    // Clear the previous pause detection timeout
    clearTimeout(pauseDetectTimeout.current);
    pauseDetectTimeout.current = setTimeout(() => {
      // If there are no new frames for VIDEO_PAUSE_DETECT_MSEC
      // set state paused attribute to true
      framesDispatch({ type: 'pause' });
    }, VIDEO_PAUSE_DETECT_MSEC);
    framesDispatch({
      type: 'onFrame',
      frameNo,
      timestamp,
    });
  };

  /**
   * Record server frame processing result message
   * @param {int} frameNo - Frame index
   * @param {float} timestamp - frame timestamp
   */
  const onMLServerMessage = (frameNo, timestamp) => {
    framesDispatch({ type: 'onMLServerMessage', frameNo, timestamp });
  };

  return (
    <MLPerformanceContext.Provider
      value={{
        averageServerTurnaroundMsec: framesState.averageServerTurnaroundMsec,
        serverDroppedFramePercentage: framesState.serverDroppedFramePercentage,
        fps: framesState.fps,
        frameNo: framesState.frameNo,
        paused: framesState.paused,
        onFrame,
        onMLServerMessage,
      }}
    >
      {children}
    </MLPerformanceContext.Provider>
  );
};
