import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import image from 'images/devices/default-device.png';

const serviceUuid = 0xff02;
const characteristicUuid = 0xfffc;
const devices = [
  {
    names: ['Virtual Blowbot'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: ['Pearl2', 'Pearl2.1'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: ['Blowbot1.1'],
    services: ['00801900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['Cliona'],
    services: ['00001900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['THE COWGIRL', 'THE COWGIRL SEX MACHINE', 'THE UNICORN', 'THE UNICORN SEX MACHINE'],
    services: 0xfe00,
  },
  {
    names: ['ESCA2', 'OhMiBod 4.0', 'OhMiBod LUMEN', 'OhMiBod ESCA'],
    services: ['a0d70001-4c16-4ba7-977a-d394920e13a3'],
  },
  {
    names: ['Fuse'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: ['Fuse1.1'],
    services: [
      'c0961900-0000-1000-8000-00805f9b34fb',
      '00001900-0000-1000-8000-00805f9b34fb',
      0x1900,
    ],
  },
  {
    names: ['Pearl2'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: ['Pearl 2+', 'Pearl 3', 'Pearl2.1'],
    services: ['00001900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['Endurance'],
    services: ['00001900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['Indulgence'],
    services: ['00001900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['Treasure'],
    services: ['00001900-0000-1000-8000-00805f9b34fb', 0x1900],
  },
  {
    names: [
      'AOGU SUV',
      'AOGU SCB',
      'Ella NEO',
      'Vick NEO',
      'Emma NEO',
      'Phoenix NEO',
      '116',
      'Pulse Lite NEO',
      'SWK-SX013A',
      'Hannes NEO',
      'Phoenix Neo 2',
    ],
    services: [0xffe0],
  },
  {
    names: ['Titan'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: ['Titan1.1'],
    services: ['00001900-0000-1000-8000-00805f9b34fb'],
  },
  {
    names: ['RABBIT', 'VIRTUAL RABBIT'],
    services: ['88f82580-0000-01e6-aace-0002a5d5c51b'],
  },
  {
    names: [
      'Cougar',
      '4 Plus',
      '4_Plus',
      '4plus',
      'Bloom',
      'classic',
      'Classic',
      'Ditto',
      'Gala',
      'Jive',
      'Melt',
      'Moxie',
      'Nova',
      'NOVAV2',
      'Pivot',
      'Rave',
      'Sync',
      'Vector',
      'Verge',
      'Wish',
    ],
    services: ['f000bb03-0451-4000-b000-000000000000'],
  },
];

export default class UnsupportedDevice extends BaseDeviceWrapper {
  constructor(device) {
    super(device, serviceUuid, characteristicUuid, image);
    this.unsupported = true;
  }

  static get deviceNames() {
    return devices.reduce((names, device) => names.concat(device.names), []);
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return devices.reduce((services, device) => services.concat(device.services), []);
  }
}
