import React, { useState, useEffect } from 'react';
import LineGraph from 'react-line-graph';
import takeRight from 'lodash/takeRight';

import styles from './style.module.scss';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

const SHOW_FRAMES_COUNT = 320;
const chartPlaceholder = new Array(SHOW_FRAMES_COUNT).fill(0);

export const FrameDetails = ({ msg }) => {
  const [chartData, setChartData] = useState([]);
  const [frameDetails, setFrameDetails] = useState(null);

  const { subscribeFrameMessages, devices, serverUrl } = useChromeExtensionPopup();
  useEffect(() => {
    const unsubscribe = subscribeFrameMessages((msg) => {
      setFrameDetails(msg);
      // Update the chart right away
      const { predict } = msg;
      setChartData((prev) => [...prev, predict * 100]);
    });
    return () => {
      unsubscribe();
    };
  }, [subscribeFrameMessages]);

  const { frameNo, averageServerTurnaroundMsec, fps, serverDroppedFramePercentage, is_blowjob } =
    frameDetails || {};

  const data = [1, ...takeRight([...chartPlaceholder, ...chartData], SHOW_FRAMES_COUNT - 1)];

  return (
    <>
      <div className={styles.graph}>
        <LineGraph data={data} smoothing={0.75} strokeWidth={2} height={60} />
      </div>

      <div className={styles.videoInfoContainer}>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Server:&nbsp;</span>
          <span className={styles.videoInfo}>{serverUrl}</span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Devices:</span>
          <span className={styles.videoInfo}>
            {devices?.length ? devices.map((d) => d.name).join(', ') : 'None'}
          </span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Frame:</span>
          <span className={styles.videoInfo}>{frameNo ? `#${frameNo}` : '-'}</span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>FPS:</span>
          <span className={styles.videoInfo}>{fps || '-'}</span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Server turnaround time, msec:</span>
          <span className={styles.videoInfo}>{averageServerTurnaroundMsec || '-'}</span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Frames dropped:</span>
          <span className={styles.videoInfo}>{serverDroppedFramePercentage || '0'}%</span>
        </div>
        <div className={styles.videoInfoItem}>
          <span className={styles.videoInfoItemTitle}>Is this a blowjob?</span>
          <span className={styles.videoInfo}>{is_blowjob ? 'Hell yeah!' : "Don't think so"}</span>
        </div>
      </div>
    </>
  );
};
