/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const cloud = $root.cloud = (() => {

    /**
     * Namespace cloud.
     * @exports cloud
     * @namespace
     */
    const cloud = {};

    /**
     * CloudConfigStatus enum.
     * @name cloud.CloudConfigStatus
     * @enum {number}
     * @property {number} Success=0 Success value
     * @property {number} InvalidParam=1 InvalidParam value
     * @property {number} InvalidState=2 InvalidState value
     */
    cloud.CloudConfigStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Success"] = 0;
        values[valuesById[1] = "InvalidParam"] = 1;
        values[valuesById[2] = "InvalidState"] = 2;
        return values;
    })();

    cloud.CmdGetSetDetails = (function() {

        /**
         * Properties of a CmdGetSetDetails.
         * @memberof cloud
         * @interface ICmdGetSetDetails
         * @property {string|null} [UserID] CmdGetSetDetails UserID
         * @property {string|null} [SecretKey] CmdGetSetDetails SecretKey
         */

        /**
         * Constructs a new CmdGetSetDetails.
         * @memberof cloud
         * @classdesc Represents a CmdGetSetDetails.
         * @implements ICmdGetSetDetails
         * @constructor
         * @param {cloud.ICmdGetSetDetails=} [properties] Properties to set
         */
        function CmdGetSetDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CmdGetSetDetails UserID.
         * @member {string} UserID
         * @memberof cloud.CmdGetSetDetails
         * @instance
         */
        CmdGetSetDetails.prototype.UserID = "";

        /**
         * CmdGetSetDetails SecretKey.
         * @member {string} SecretKey
         * @memberof cloud.CmdGetSetDetails
         * @instance
         */
        CmdGetSetDetails.prototype.SecretKey = "";

        /**
         * Creates a new CmdGetSetDetails instance using the specified properties.
         * @function create
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {cloud.ICmdGetSetDetails=} [properties] Properties to set
         * @returns {cloud.CmdGetSetDetails} CmdGetSetDetails instance
         */
        CmdGetSetDetails.create = function create(properties) {
            return new CmdGetSetDetails(properties);
        };

        /**
         * Encodes the specified CmdGetSetDetails message. Does not implicitly {@link cloud.CmdGetSetDetails.verify|verify} messages.
         * @function encode
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {cloud.CmdGetSetDetails} message CmdGetSetDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdGetSetDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.UserID != null && Object.hasOwnProperty.call(message, "UserID"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.UserID);
            if (message.SecretKey != null && Object.hasOwnProperty.call(message, "SecretKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.SecretKey);
            return writer;
        };

        /**
         * Encodes the specified CmdGetSetDetails message, length delimited. Does not implicitly {@link cloud.CmdGetSetDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {cloud.CmdGetSetDetails} message CmdGetSetDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdGetSetDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdGetSetDetails message from the specified reader or buffer.
         * @function decode
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cloud.CmdGetSetDetails} CmdGetSetDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdGetSetDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cloud.CmdGetSetDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.UserID = reader.string();
                    break;
                case 2:
                    message.SecretKey = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdGetSetDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cloud.CmdGetSetDetails} CmdGetSetDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdGetSetDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdGetSetDetails message.
         * @function verify
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdGetSetDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.UserID != null && message.hasOwnProperty("UserID"))
                if (!$util.isString(message.UserID))
                    return "UserID: string expected";
            if (message.SecretKey != null && message.hasOwnProperty("SecretKey"))
                if (!$util.isString(message.SecretKey))
                    return "SecretKey: string expected";
            return null;
        };

        /**
         * Creates a CmdGetSetDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cloud.CmdGetSetDetails} CmdGetSetDetails
         */
        CmdGetSetDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.cloud.CmdGetSetDetails)
                return object;
            let message = new $root.cloud.CmdGetSetDetails();
            if (object.UserID != null)
                message.UserID = String(object.UserID);
            if (object.SecretKey != null)
                message.SecretKey = String(object.SecretKey);
            return message;
        };

        /**
         * Creates a plain object from a CmdGetSetDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cloud.CmdGetSetDetails
         * @static
         * @param {cloud.CmdGetSetDetails} message CmdGetSetDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdGetSetDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.UserID = "";
                object.SecretKey = "";
            }
            if (message.UserID != null && message.hasOwnProperty("UserID"))
                object.UserID = message.UserID;
            if (message.SecretKey != null && message.hasOwnProperty("SecretKey"))
                object.SecretKey = message.SecretKey;
            return object;
        };

        /**
         * Converts this CmdGetSetDetails to JSON.
         * @function toJSON
         * @memberof cloud.CmdGetSetDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdGetSetDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdGetSetDetails;
    })();

    cloud.RespGetSetDetails = (function() {

        /**
         * Properties of a RespGetSetDetails.
         * @memberof cloud
         * @interface IRespGetSetDetails
         * @property {cloud.CloudConfigStatus|null} [Status] RespGetSetDetails Status
         * @property {string|null} [DeviceSecret] RespGetSetDetails DeviceSecret
         */

        /**
         * Constructs a new RespGetSetDetails.
         * @memberof cloud
         * @classdesc Represents a RespGetSetDetails.
         * @implements IRespGetSetDetails
         * @constructor
         * @param {cloud.IRespGetSetDetails=} [properties] Properties to set
         */
        function RespGetSetDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespGetSetDetails Status.
         * @member {cloud.CloudConfigStatus} Status
         * @memberof cloud.RespGetSetDetails
         * @instance
         */
        RespGetSetDetails.prototype.Status = 0;

        /**
         * RespGetSetDetails DeviceSecret.
         * @member {string} DeviceSecret
         * @memberof cloud.RespGetSetDetails
         * @instance
         */
        RespGetSetDetails.prototype.DeviceSecret = "";

        /**
         * Creates a new RespGetSetDetails instance using the specified properties.
         * @function create
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {cloud.IRespGetSetDetails=} [properties] Properties to set
         * @returns {cloud.RespGetSetDetails} RespGetSetDetails instance
         */
        RespGetSetDetails.create = function create(properties) {
            return new RespGetSetDetails(properties);
        };

        /**
         * Encodes the specified RespGetSetDetails message. Does not implicitly {@link cloud.RespGetSetDetails.verify|verify} messages.
         * @function encode
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {cloud.RespGetSetDetails} message RespGetSetDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespGetSetDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Status);
            if (message.DeviceSecret != null && Object.hasOwnProperty.call(message, "DeviceSecret"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.DeviceSecret);
            return writer;
        };

        /**
         * Encodes the specified RespGetSetDetails message, length delimited. Does not implicitly {@link cloud.RespGetSetDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {cloud.RespGetSetDetails} message RespGetSetDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespGetSetDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespGetSetDetails message from the specified reader or buffer.
         * @function decode
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cloud.RespGetSetDetails} RespGetSetDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespGetSetDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cloud.RespGetSetDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Status = reader.int32();
                    break;
                case 2:
                    message.DeviceSecret = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespGetSetDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cloud.RespGetSetDetails} RespGetSetDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespGetSetDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespGetSetDetails message.
         * @function verify
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespGetSetDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Status != null && message.hasOwnProperty("Status"))
                switch (message.Status) {
                default:
                    return "Status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.DeviceSecret != null && message.hasOwnProperty("DeviceSecret"))
                if (!$util.isString(message.DeviceSecret))
                    return "DeviceSecret: string expected";
            return null;
        };

        /**
         * Creates a RespGetSetDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cloud.RespGetSetDetails} RespGetSetDetails
         */
        RespGetSetDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.cloud.RespGetSetDetails)
                return object;
            let message = new $root.cloud.RespGetSetDetails();
            switch (object.Status) {
            case "Success":
            case 0:
                message.Status = 0;
                break;
            case "InvalidParam":
            case 1:
                message.Status = 1;
                break;
            case "InvalidState":
            case 2:
                message.Status = 2;
                break;
            }
            if (object.DeviceSecret != null)
                message.DeviceSecret = String(object.DeviceSecret);
            return message;
        };

        /**
         * Creates a plain object from a RespGetSetDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cloud.RespGetSetDetails
         * @static
         * @param {cloud.RespGetSetDetails} message RespGetSetDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespGetSetDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.Status = options.enums === String ? "Success" : 0;
                object.DeviceSecret = "";
            }
            if (message.Status != null && message.hasOwnProperty("Status"))
                object.Status = options.enums === String ? $root.cloud.CloudConfigStatus[message.Status] : message.Status;
            if (message.DeviceSecret != null && message.hasOwnProperty("DeviceSecret"))
                object.DeviceSecret = message.DeviceSecret;
            return object;
        };

        /**
         * Converts this RespGetSetDetails to JSON.
         * @function toJSON
         * @memberof cloud.RespGetSetDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespGetSetDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespGetSetDetails;
    })();

    /**
     * CloudConfigMsgType enum.
     * @name cloud.CloudConfigMsgType
     * @enum {number}
     * @property {number} TypeCmdGetSetDetails=0 TypeCmdGetSetDetails value
     * @property {number} TypeRespGetSetDetails=1 TypeRespGetSetDetails value
     */
    cloud.CloudConfigMsgType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TypeCmdGetSetDetails"] = 0;
        values[valuesById[1] = "TypeRespGetSetDetails"] = 1;
        return values;
    })();

    cloud.CloudConfigPayload = (function() {

        /**
         * Properties of a CloudConfigPayload.
         * @memberof cloud
         * @interface ICloudConfigPayload
         * @property {cloud.CloudConfigMsgType|null} [msg] CloudConfigPayload msg
         * @property {cloud.CmdGetSetDetails|null} [cmdGetSetDetails] CloudConfigPayload cmdGetSetDetails
         * @property {cloud.RespGetSetDetails|null} [respGetSetDetails] CloudConfigPayload respGetSetDetails
         */

        /**
         * Constructs a new CloudConfigPayload.
         * @memberof cloud
         * @classdesc Represents a CloudConfigPayload.
         * @implements ICloudConfigPayload
         * @constructor
         * @param {cloud.ICloudConfigPayload=} [properties] Properties to set
         */
        function CloudConfigPayload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloudConfigPayload msg.
         * @member {cloud.CloudConfigMsgType} msg
         * @memberof cloud.CloudConfigPayload
         * @instance
         */
        CloudConfigPayload.prototype.msg = 0;

        /**
         * CloudConfigPayload cmdGetSetDetails.
         * @member {cloud.CmdGetSetDetails|null|undefined} cmdGetSetDetails
         * @memberof cloud.CloudConfigPayload
         * @instance
         */
        CloudConfigPayload.prototype.cmdGetSetDetails = null;

        /**
         * CloudConfigPayload respGetSetDetails.
         * @member {cloud.RespGetSetDetails|null|undefined} respGetSetDetails
         * @memberof cloud.CloudConfigPayload
         * @instance
         */
        CloudConfigPayload.prototype.respGetSetDetails = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CloudConfigPayload payload.
         * @member {"cmdGetSetDetails"|"respGetSetDetails"|undefined} payload
         * @memberof cloud.CloudConfigPayload
         * @instance
         */
        Object.defineProperty(CloudConfigPayload.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["cmdGetSetDetails", "respGetSetDetails"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CloudConfigPayload instance using the specified properties.
         * @function create
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {cloud.ICloudConfigPayload=} [properties] Properties to set
         * @returns {cloud.CloudConfigPayload} CloudConfigPayload instance
         */
        CloudConfigPayload.create = function create(properties) {
            return new CloudConfigPayload(properties);
        };

        /**
         * Encodes the specified CloudConfigPayload message. Does not implicitly {@link cloud.CloudConfigPayload.verify|verify} messages.
         * @function encode
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {cloud.CloudConfigPayload} message CloudConfigPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudConfigPayload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
            if (message.cmdGetSetDetails != null && Object.hasOwnProperty.call(message, "cmdGetSetDetails"))
                $root.cloud.CmdGetSetDetails.encode(message.cmdGetSetDetails, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.respGetSetDetails != null && Object.hasOwnProperty.call(message, "respGetSetDetails"))
                $root.cloud.RespGetSetDetails.encode(message.respGetSetDetails, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CloudConfigPayload message, length delimited. Does not implicitly {@link cloud.CloudConfigPayload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {cloud.CloudConfigPayload} message CloudConfigPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloudConfigPayload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloudConfigPayload message from the specified reader or buffer.
         * @function decode
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cloud.CloudConfigPayload} CloudConfigPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudConfigPayload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cloud.CloudConfigPayload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.msg = reader.int32();
                    break;
                case 10:
                    message.cmdGetSetDetails = $root.cloud.CmdGetSetDetails.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.respGetSetDetails = $root.cloud.RespGetSetDetails.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloudConfigPayload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cloud.CloudConfigPayload} CloudConfigPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloudConfigPayload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloudConfigPayload message.
         * @function verify
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloudConfigPayload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msg != null && message.hasOwnProperty("msg"))
                switch (message.msg) {
                default:
                    return "msg: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.cmdGetSetDetails != null && message.hasOwnProperty("cmdGetSetDetails")) {
                properties.payload = 1;
                {
                    let error = $root.cloud.CmdGetSetDetails.verify(message.cmdGetSetDetails);
                    if (error)
                        return "cmdGetSetDetails." + error;
                }
            }
            if (message.respGetSetDetails != null && message.hasOwnProperty("respGetSetDetails")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.cloud.RespGetSetDetails.verify(message.respGetSetDetails);
                    if (error)
                        return "respGetSetDetails." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CloudConfigPayload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cloud.CloudConfigPayload} CloudConfigPayload
         */
        CloudConfigPayload.fromObject = function fromObject(object) {
            if (object instanceof $root.cloud.CloudConfigPayload)
                return object;
            let message = new $root.cloud.CloudConfigPayload();
            switch (object.msg) {
            case "TypeCmdGetSetDetails":
            case 0:
                message.msg = 0;
                break;
            case "TypeRespGetSetDetails":
            case 1:
                message.msg = 1;
                break;
            }
            if (object.cmdGetSetDetails != null) {
                if (typeof object.cmdGetSetDetails !== "object")
                    throw TypeError(".cloud.CloudConfigPayload.cmdGetSetDetails: object expected");
                message.cmdGetSetDetails = $root.cloud.CmdGetSetDetails.fromObject(object.cmdGetSetDetails);
            }
            if (object.respGetSetDetails != null) {
                if (typeof object.respGetSetDetails !== "object")
                    throw TypeError(".cloud.CloudConfigPayload.respGetSetDetails: object expected");
                message.respGetSetDetails = $root.cloud.RespGetSetDetails.fromObject(object.respGetSetDetails);
            }
            return message;
        };

        /**
         * Creates a plain object from a CloudConfigPayload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cloud.CloudConfigPayload
         * @static
         * @param {cloud.CloudConfigPayload} message CloudConfigPayload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloudConfigPayload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = options.enums === String ? "TypeCmdGetSetDetails" : 0;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.enums === String ? $root.cloud.CloudConfigMsgType[message.msg] : message.msg;
            if (message.cmdGetSetDetails != null && message.hasOwnProperty("cmdGetSetDetails")) {
                object.cmdGetSetDetails = $root.cloud.CmdGetSetDetails.toObject(message.cmdGetSetDetails, options);
                if (options.oneofs)
                    object.payload = "cmdGetSetDetails";
            }
            if (message.respGetSetDetails != null && message.hasOwnProperty("respGetSetDetails")) {
                object.respGetSetDetails = $root.cloud.RespGetSetDetails.toObject(message.respGetSetDetails, options);
                if (options.oneofs)
                    object.payload = "respGetSetDetails";
            }
            return object;
        };

        /**
         * Converts this CloudConfigPayload to JSON.
         * @function toJSON
         * @memberof cloud.CloudConfigPayload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloudConfigPayload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CloudConfigPayload;
    })();

    return cloud;
})();

export const espressif = $root.espressif = (() => {

    /**
     * Namespace espressif.
     * @exports espressif
     * @namespace
     */
    const espressif = {};

    /**
     * Status enum.
     * @name espressif.Status
     * @enum {number}
     * @property {number} Success=0 Success value
     * @property {number} InvalidSecScheme=1 InvalidSecScheme value
     * @property {number} InvalidProto=2 InvalidProto value
     * @property {number} TooManySessions=3 TooManySessions value
     * @property {number} InvalidArgument=4 InvalidArgument value
     * @property {number} InternalError=5 InternalError value
     * @property {number} CryptoError=6 CryptoError value
     * @property {number} InvalidSession=7 InvalidSession value
     */
    espressif.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Success"] = 0;
        values[valuesById[1] = "InvalidSecScheme"] = 1;
        values[valuesById[2] = "InvalidProto"] = 2;
        values[valuesById[3] = "TooManySessions"] = 3;
        values[valuesById[4] = "InvalidArgument"] = 4;
        values[valuesById[5] = "InternalError"] = 5;
        values[valuesById[6] = "CryptoError"] = 6;
        values[valuesById[7] = "InvalidSession"] = 7;
        return values;
    })();

    espressif.S0SessionCmd = (function() {

        /**
         * Properties of a S0SessionCmd.
         * @memberof espressif
         * @interface IS0SessionCmd
         */

        /**
         * Constructs a new S0SessionCmd.
         * @memberof espressif
         * @classdesc Represents a S0SessionCmd.
         * @implements IS0SessionCmd
         * @constructor
         * @param {espressif.IS0SessionCmd=} [properties] Properties to set
         */
        function S0SessionCmd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new S0SessionCmd instance using the specified properties.
         * @function create
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {espressif.IS0SessionCmd=} [properties] Properties to set
         * @returns {espressif.S0SessionCmd} S0SessionCmd instance
         */
        S0SessionCmd.create = function create(properties) {
            return new S0SessionCmd(properties);
        };

        /**
         * Encodes the specified S0SessionCmd message. Does not implicitly {@link espressif.S0SessionCmd.verify|verify} messages.
         * @function encode
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {espressif.S0SessionCmd} message S0SessionCmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S0SessionCmd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified S0SessionCmd message, length delimited. Does not implicitly {@link espressif.S0SessionCmd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {espressif.S0SessionCmd} message S0SessionCmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S0SessionCmd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S0SessionCmd message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.S0SessionCmd} S0SessionCmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S0SessionCmd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.S0SessionCmd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S0SessionCmd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.S0SessionCmd} S0SessionCmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S0SessionCmd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S0SessionCmd message.
         * @function verify
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S0SessionCmd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a S0SessionCmd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.S0SessionCmd} S0SessionCmd
         */
        S0SessionCmd.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.S0SessionCmd)
                return object;
            return new $root.espressif.S0SessionCmd();
        };

        /**
         * Creates a plain object from a S0SessionCmd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.S0SessionCmd
         * @static
         * @param {espressif.S0SessionCmd} message S0SessionCmd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S0SessionCmd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this S0SessionCmd to JSON.
         * @function toJSON
         * @memberof espressif.S0SessionCmd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S0SessionCmd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return S0SessionCmd;
    })();

    espressif.S0SessionResp = (function() {

        /**
         * Properties of a S0SessionResp.
         * @memberof espressif
         * @interface IS0SessionResp
         * @property {espressif.Status|null} [status] S0SessionResp status
         */

        /**
         * Constructs a new S0SessionResp.
         * @memberof espressif
         * @classdesc Represents a S0SessionResp.
         * @implements IS0SessionResp
         * @constructor
         * @param {espressif.IS0SessionResp=} [properties] Properties to set
         */
        function S0SessionResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * S0SessionResp status.
         * @member {espressif.Status} status
         * @memberof espressif.S0SessionResp
         * @instance
         */
        S0SessionResp.prototype.status = 0;

        /**
         * Creates a new S0SessionResp instance using the specified properties.
         * @function create
         * @memberof espressif.S0SessionResp
         * @static
         * @param {espressif.IS0SessionResp=} [properties] Properties to set
         * @returns {espressif.S0SessionResp} S0SessionResp instance
         */
        S0SessionResp.create = function create(properties) {
            return new S0SessionResp(properties);
        };

        /**
         * Encodes the specified S0SessionResp message. Does not implicitly {@link espressif.S0SessionResp.verify|verify} messages.
         * @function encode
         * @memberof espressif.S0SessionResp
         * @static
         * @param {espressif.S0SessionResp} message S0SessionResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S0SessionResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified S0SessionResp message, length delimited. Does not implicitly {@link espressif.S0SessionResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.S0SessionResp
         * @static
         * @param {espressif.S0SessionResp} message S0SessionResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        S0SessionResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a S0SessionResp message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.S0SessionResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.S0SessionResp} S0SessionResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S0SessionResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.S0SessionResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a S0SessionResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.S0SessionResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.S0SessionResp} S0SessionResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        S0SessionResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a S0SessionResp message.
         * @function verify
         * @memberof espressif.S0SessionResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        S0SessionResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a S0SessionResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.S0SessionResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.S0SessionResp} S0SessionResp
         */
        S0SessionResp.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.S0SessionResp)
                return object;
            let message = new $root.espressif.S0SessionResp();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a S0SessionResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.S0SessionResp
         * @static
         * @param {espressif.S0SessionResp} message S0SessionResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        S0SessionResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "Success" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this S0SessionResp to JSON.
         * @function toJSON
         * @memberof espressif.S0SessionResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        S0SessionResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return S0SessionResp;
    })();

    /**
     * Sec0MsgType enum.
     * @name espressif.Sec0MsgType
     * @enum {number}
     * @property {number} S0_Session_Command=0 S0_Session_Command value
     * @property {number} S0_Session_Response=1 S0_Session_Response value
     */
    espressif.Sec0MsgType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "S0_Session_Command"] = 0;
        values[valuesById[1] = "S0_Session_Response"] = 1;
        return values;
    })();

    espressif.Sec0Payload = (function() {

        /**
         * Properties of a Sec0Payload.
         * @memberof espressif
         * @interface ISec0Payload
         * @property {espressif.Sec0MsgType|null} [msg] Sec0Payload msg
         * @property {espressif.S0SessionCmd|null} [sc] Sec0Payload sc
         * @property {espressif.S0SessionResp|null} [sr] Sec0Payload sr
         */

        /**
         * Constructs a new Sec0Payload.
         * @memberof espressif
         * @classdesc Represents a Sec0Payload.
         * @implements ISec0Payload
         * @constructor
         * @param {espressif.ISec0Payload=} [properties] Properties to set
         */
        function Sec0Payload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Sec0Payload msg.
         * @member {espressif.Sec0MsgType} msg
         * @memberof espressif.Sec0Payload
         * @instance
         */
        Sec0Payload.prototype.msg = 0;

        /**
         * Sec0Payload sc.
         * @member {espressif.S0SessionCmd|null|undefined} sc
         * @memberof espressif.Sec0Payload
         * @instance
         */
        Sec0Payload.prototype.sc = null;

        /**
         * Sec0Payload sr.
         * @member {espressif.S0SessionResp|null|undefined} sr
         * @memberof espressif.Sec0Payload
         * @instance
         */
        Sec0Payload.prototype.sr = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Sec0Payload payload.
         * @member {"sc"|"sr"|undefined} payload
         * @memberof espressif.Sec0Payload
         * @instance
         */
        Object.defineProperty(Sec0Payload.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["sc", "sr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Sec0Payload instance using the specified properties.
         * @function create
         * @memberof espressif.Sec0Payload
         * @static
         * @param {espressif.ISec0Payload=} [properties] Properties to set
         * @returns {espressif.Sec0Payload} Sec0Payload instance
         */
        Sec0Payload.create = function create(properties) {
            return new Sec0Payload(properties);
        };

        /**
         * Encodes the specified Sec0Payload message. Does not implicitly {@link espressif.Sec0Payload.verify|verify} messages.
         * @function encode
         * @memberof espressif.Sec0Payload
         * @static
         * @param {espressif.Sec0Payload} message Sec0Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sec0Payload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
            if (message.sc != null && Object.hasOwnProperty.call(message, "sc"))
                $root.espressif.S0SessionCmd.encode(message.sc, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.sr != null && Object.hasOwnProperty.call(message, "sr"))
                $root.espressif.S0SessionResp.encode(message.sr, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Sec0Payload message, length delimited. Does not implicitly {@link espressif.Sec0Payload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.Sec0Payload
         * @static
         * @param {espressif.Sec0Payload} message Sec0Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sec0Payload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Sec0Payload message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.Sec0Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.Sec0Payload} Sec0Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sec0Payload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.Sec0Payload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.msg = reader.int32();
                    break;
                case 20:
                    message.sc = $root.espressif.S0SessionCmd.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.sr = $root.espressif.S0SessionResp.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Sec0Payload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.Sec0Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.Sec0Payload} Sec0Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sec0Payload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Sec0Payload message.
         * @function verify
         * @memberof espressif.Sec0Payload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Sec0Payload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msg != null && message.hasOwnProperty("msg"))
                switch (message.msg) {
                default:
                    return "msg: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.sc != null && message.hasOwnProperty("sc")) {
                properties.payload = 1;
                {
                    let error = $root.espressif.S0SessionCmd.verify(message.sc);
                    if (error)
                        return "sc." + error;
                }
            }
            if (message.sr != null && message.hasOwnProperty("sr")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.S0SessionResp.verify(message.sr);
                    if (error)
                        return "sr." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Sec0Payload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.Sec0Payload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.Sec0Payload} Sec0Payload
         */
        Sec0Payload.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.Sec0Payload)
                return object;
            let message = new $root.espressif.Sec0Payload();
            switch (object.msg) {
            case "S0_Session_Command":
            case 0:
                message.msg = 0;
                break;
            case "S0_Session_Response":
            case 1:
                message.msg = 1;
                break;
            }
            if (object.sc != null) {
                if (typeof object.sc !== "object")
                    throw TypeError(".espressif.Sec0Payload.sc: object expected");
                message.sc = $root.espressif.S0SessionCmd.fromObject(object.sc);
            }
            if (object.sr != null) {
                if (typeof object.sr !== "object")
                    throw TypeError(".espressif.Sec0Payload.sr: object expected");
                message.sr = $root.espressif.S0SessionResp.fromObject(object.sr);
            }
            return message;
        };

        /**
         * Creates a plain object from a Sec0Payload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.Sec0Payload
         * @static
         * @param {espressif.Sec0Payload} message Sec0Payload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sec0Payload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = options.enums === String ? "S0_Session_Command" : 0;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.enums === String ? $root.espressif.Sec0MsgType[message.msg] : message.msg;
            if (message.sc != null && message.hasOwnProperty("sc")) {
                object.sc = $root.espressif.S0SessionCmd.toObject(message.sc, options);
                if (options.oneofs)
                    object.payload = "sc";
            }
            if (message.sr != null && message.hasOwnProperty("sr")) {
                object.sr = $root.espressif.S0SessionResp.toObject(message.sr, options);
                if (options.oneofs)
                    object.payload = "sr";
            }
            return object;
        };

        /**
         * Converts this Sec0Payload to JSON.
         * @function toJSON
         * @memberof espressif.Sec0Payload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sec0Payload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Sec0Payload;
    })();

    espressif.SessionCmd1 = (function() {

        /**
         * Properties of a SessionCmd1.
         * @memberof espressif
         * @interface ISessionCmd1
         * @property {Uint8Array|null} [clientVerifyData] SessionCmd1 clientVerifyData
         */

        /**
         * Constructs a new SessionCmd1.
         * @memberof espressif
         * @classdesc Represents a SessionCmd1.
         * @implements ISessionCmd1
         * @constructor
         * @param {espressif.ISessionCmd1=} [properties] Properties to set
         */
        function SessionCmd1(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionCmd1 clientVerifyData.
         * @member {Uint8Array} clientVerifyData
         * @memberof espressif.SessionCmd1
         * @instance
         */
        SessionCmd1.prototype.clientVerifyData = $util.newBuffer([]);

        /**
         * Creates a new SessionCmd1 instance using the specified properties.
         * @function create
         * @memberof espressif.SessionCmd1
         * @static
         * @param {espressif.ISessionCmd1=} [properties] Properties to set
         * @returns {espressif.SessionCmd1} SessionCmd1 instance
         */
        SessionCmd1.create = function create(properties) {
            return new SessionCmd1(properties);
        };

        /**
         * Encodes the specified SessionCmd1 message. Does not implicitly {@link espressif.SessionCmd1.verify|verify} messages.
         * @function encode
         * @memberof espressif.SessionCmd1
         * @static
         * @param {espressif.SessionCmd1} message SessionCmd1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionCmd1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientVerifyData != null && Object.hasOwnProperty.call(message, "clientVerifyData"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.clientVerifyData);
            return writer;
        };

        /**
         * Encodes the specified SessionCmd1 message, length delimited. Does not implicitly {@link espressif.SessionCmd1.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.SessionCmd1
         * @static
         * @param {espressif.SessionCmd1} message SessionCmd1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionCmd1.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionCmd1 message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.SessionCmd1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.SessionCmd1} SessionCmd1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionCmd1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.SessionCmd1();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.clientVerifyData = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionCmd1 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.SessionCmd1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.SessionCmd1} SessionCmd1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionCmd1.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionCmd1 message.
         * @function verify
         * @memberof espressif.SessionCmd1
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionCmd1.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.clientVerifyData != null && message.hasOwnProperty("clientVerifyData"))
                if (!(message.clientVerifyData && typeof message.clientVerifyData.length === "number" || $util.isString(message.clientVerifyData)))
                    return "clientVerifyData: buffer expected";
            return null;
        };

        /**
         * Creates a SessionCmd1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.SessionCmd1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.SessionCmd1} SessionCmd1
         */
        SessionCmd1.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.SessionCmd1)
                return object;
            let message = new $root.espressif.SessionCmd1();
            if (object.clientVerifyData != null)
                if (typeof object.clientVerifyData === "string")
                    $util.base64.decode(object.clientVerifyData, message.clientVerifyData = $util.newBuffer($util.base64.length(object.clientVerifyData)), 0);
                else if (object.clientVerifyData.length)
                    message.clientVerifyData = object.clientVerifyData;
            return message;
        };

        /**
         * Creates a plain object from a SessionCmd1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.SessionCmd1
         * @static
         * @param {espressif.SessionCmd1} message SessionCmd1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionCmd1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.clientVerifyData = "";
                else {
                    object.clientVerifyData = [];
                    if (options.bytes !== Array)
                        object.clientVerifyData = $util.newBuffer(object.clientVerifyData);
                }
            if (message.clientVerifyData != null && message.hasOwnProperty("clientVerifyData"))
                object.clientVerifyData = options.bytes === String ? $util.base64.encode(message.clientVerifyData, 0, message.clientVerifyData.length) : options.bytes === Array ? Array.prototype.slice.call(message.clientVerifyData) : message.clientVerifyData;
            return object;
        };

        /**
         * Converts this SessionCmd1 to JSON.
         * @function toJSON
         * @memberof espressif.SessionCmd1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionCmd1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SessionCmd1;
    })();

    espressif.SessionResp1 = (function() {

        /**
         * Properties of a SessionResp1.
         * @memberof espressif
         * @interface ISessionResp1
         * @property {espressif.Status|null} [status] SessionResp1 status
         * @property {Uint8Array|null} [deviceVerifyData] SessionResp1 deviceVerifyData
         */

        /**
         * Constructs a new SessionResp1.
         * @memberof espressif
         * @classdesc Represents a SessionResp1.
         * @implements ISessionResp1
         * @constructor
         * @param {espressif.ISessionResp1=} [properties] Properties to set
         */
        function SessionResp1(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionResp1 status.
         * @member {espressif.Status} status
         * @memberof espressif.SessionResp1
         * @instance
         */
        SessionResp1.prototype.status = 0;

        /**
         * SessionResp1 deviceVerifyData.
         * @member {Uint8Array} deviceVerifyData
         * @memberof espressif.SessionResp1
         * @instance
         */
        SessionResp1.prototype.deviceVerifyData = $util.newBuffer([]);

        /**
         * Creates a new SessionResp1 instance using the specified properties.
         * @function create
         * @memberof espressif.SessionResp1
         * @static
         * @param {espressif.ISessionResp1=} [properties] Properties to set
         * @returns {espressif.SessionResp1} SessionResp1 instance
         */
        SessionResp1.create = function create(properties) {
            return new SessionResp1(properties);
        };

        /**
         * Encodes the specified SessionResp1 message. Does not implicitly {@link espressif.SessionResp1.verify|verify} messages.
         * @function encode
         * @memberof espressif.SessionResp1
         * @static
         * @param {espressif.SessionResp1} message SessionResp1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionResp1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.deviceVerifyData != null && Object.hasOwnProperty.call(message, "deviceVerifyData"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.deviceVerifyData);
            return writer;
        };

        /**
         * Encodes the specified SessionResp1 message, length delimited. Does not implicitly {@link espressif.SessionResp1.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.SessionResp1
         * @static
         * @param {espressif.SessionResp1} message SessionResp1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionResp1.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionResp1 message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.SessionResp1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.SessionResp1} SessionResp1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionResp1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.SessionResp1();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 3:
                    message.deviceVerifyData = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionResp1 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.SessionResp1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.SessionResp1} SessionResp1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionResp1.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionResp1 message.
         * @function verify
         * @memberof espressif.SessionResp1
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionResp1.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.deviceVerifyData != null && message.hasOwnProperty("deviceVerifyData"))
                if (!(message.deviceVerifyData && typeof message.deviceVerifyData.length === "number" || $util.isString(message.deviceVerifyData)))
                    return "deviceVerifyData: buffer expected";
            return null;
        };

        /**
         * Creates a SessionResp1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.SessionResp1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.SessionResp1} SessionResp1
         */
        SessionResp1.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.SessionResp1)
                return object;
            let message = new $root.espressif.SessionResp1();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            if (object.deviceVerifyData != null)
                if (typeof object.deviceVerifyData === "string")
                    $util.base64.decode(object.deviceVerifyData, message.deviceVerifyData = $util.newBuffer($util.base64.length(object.deviceVerifyData)), 0);
                else if (object.deviceVerifyData.length)
                    message.deviceVerifyData = object.deviceVerifyData;
            return message;
        };

        /**
         * Creates a plain object from a SessionResp1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.SessionResp1
         * @static
         * @param {espressif.SessionResp1} message SessionResp1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionResp1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "Success" : 0;
                if (options.bytes === String)
                    object.deviceVerifyData = "";
                else {
                    object.deviceVerifyData = [];
                    if (options.bytes !== Array)
                        object.deviceVerifyData = $util.newBuffer(object.deviceVerifyData);
                }
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            if (message.deviceVerifyData != null && message.hasOwnProperty("deviceVerifyData"))
                object.deviceVerifyData = options.bytes === String ? $util.base64.encode(message.deviceVerifyData, 0, message.deviceVerifyData.length) : options.bytes === Array ? Array.prototype.slice.call(message.deviceVerifyData) : message.deviceVerifyData;
            return object;
        };

        /**
         * Converts this SessionResp1 to JSON.
         * @function toJSON
         * @memberof espressif.SessionResp1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionResp1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SessionResp1;
    })();

    espressif.SessionCmd0 = (function() {

        /**
         * Properties of a SessionCmd0.
         * @memberof espressif
         * @interface ISessionCmd0
         * @property {Uint8Array|null} [clientPubkey] SessionCmd0 clientPubkey
         */

        /**
         * Constructs a new SessionCmd0.
         * @memberof espressif
         * @classdesc Represents a SessionCmd0.
         * @implements ISessionCmd0
         * @constructor
         * @param {espressif.ISessionCmd0=} [properties] Properties to set
         */
        function SessionCmd0(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionCmd0 clientPubkey.
         * @member {Uint8Array} clientPubkey
         * @memberof espressif.SessionCmd0
         * @instance
         */
        SessionCmd0.prototype.clientPubkey = $util.newBuffer([]);

        /**
         * Creates a new SessionCmd0 instance using the specified properties.
         * @function create
         * @memberof espressif.SessionCmd0
         * @static
         * @param {espressif.ISessionCmd0=} [properties] Properties to set
         * @returns {espressif.SessionCmd0} SessionCmd0 instance
         */
        SessionCmd0.create = function create(properties) {
            return new SessionCmd0(properties);
        };

        /**
         * Encodes the specified SessionCmd0 message. Does not implicitly {@link espressif.SessionCmd0.verify|verify} messages.
         * @function encode
         * @memberof espressif.SessionCmd0
         * @static
         * @param {espressif.SessionCmd0} message SessionCmd0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionCmd0.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientPubkey != null && Object.hasOwnProperty.call(message, "clientPubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.clientPubkey);
            return writer;
        };

        /**
         * Encodes the specified SessionCmd0 message, length delimited. Does not implicitly {@link espressif.SessionCmd0.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.SessionCmd0
         * @static
         * @param {espressif.SessionCmd0} message SessionCmd0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionCmd0.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionCmd0 message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.SessionCmd0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.SessionCmd0} SessionCmd0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionCmd0.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.SessionCmd0();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientPubkey = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionCmd0 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.SessionCmd0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.SessionCmd0} SessionCmd0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionCmd0.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionCmd0 message.
         * @function verify
         * @memberof espressif.SessionCmd0
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionCmd0.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.clientPubkey != null && message.hasOwnProperty("clientPubkey"))
                if (!(message.clientPubkey && typeof message.clientPubkey.length === "number" || $util.isString(message.clientPubkey)))
                    return "clientPubkey: buffer expected";
            return null;
        };

        /**
         * Creates a SessionCmd0 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.SessionCmd0
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.SessionCmd0} SessionCmd0
         */
        SessionCmd0.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.SessionCmd0)
                return object;
            let message = new $root.espressif.SessionCmd0();
            if (object.clientPubkey != null)
                if (typeof object.clientPubkey === "string")
                    $util.base64.decode(object.clientPubkey, message.clientPubkey = $util.newBuffer($util.base64.length(object.clientPubkey)), 0);
                else if (object.clientPubkey.length)
                    message.clientPubkey = object.clientPubkey;
            return message;
        };

        /**
         * Creates a plain object from a SessionCmd0 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.SessionCmd0
         * @static
         * @param {espressif.SessionCmd0} message SessionCmd0
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionCmd0.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.clientPubkey = "";
                else {
                    object.clientPubkey = [];
                    if (options.bytes !== Array)
                        object.clientPubkey = $util.newBuffer(object.clientPubkey);
                }
            if (message.clientPubkey != null && message.hasOwnProperty("clientPubkey"))
                object.clientPubkey = options.bytes === String ? $util.base64.encode(message.clientPubkey, 0, message.clientPubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.clientPubkey) : message.clientPubkey;
            return object;
        };

        /**
         * Converts this SessionCmd0 to JSON.
         * @function toJSON
         * @memberof espressif.SessionCmd0
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionCmd0.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SessionCmd0;
    })();

    espressif.SessionResp0 = (function() {

        /**
         * Properties of a SessionResp0.
         * @memberof espressif
         * @interface ISessionResp0
         * @property {espressif.Status|null} [status] SessionResp0 status
         * @property {Uint8Array|null} [devicePubkey] SessionResp0 devicePubkey
         * @property {Uint8Array|null} [deviceRandom] SessionResp0 deviceRandom
         */

        /**
         * Constructs a new SessionResp0.
         * @memberof espressif
         * @classdesc Represents a SessionResp0.
         * @implements ISessionResp0
         * @constructor
         * @param {espressif.ISessionResp0=} [properties] Properties to set
         */
        function SessionResp0(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionResp0 status.
         * @member {espressif.Status} status
         * @memberof espressif.SessionResp0
         * @instance
         */
        SessionResp0.prototype.status = 0;

        /**
         * SessionResp0 devicePubkey.
         * @member {Uint8Array} devicePubkey
         * @memberof espressif.SessionResp0
         * @instance
         */
        SessionResp0.prototype.devicePubkey = $util.newBuffer([]);

        /**
         * SessionResp0 deviceRandom.
         * @member {Uint8Array} deviceRandom
         * @memberof espressif.SessionResp0
         * @instance
         */
        SessionResp0.prototype.deviceRandom = $util.newBuffer([]);

        /**
         * Creates a new SessionResp0 instance using the specified properties.
         * @function create
         * @memberof espressif.SessionResp0
         * @static
         * @param {espressif.ISessionResp0=} [properties] Properties to set
         * @returns {espressif.SessionResp0} SessionResp0 instance
         */
        SessionResp0.create = function create(properties) {
            return new SessionResp0(properties);
        };

        /**
         * Encodes the specified SessionResp0 message. Does not implicitly {@link espressif.SessionResp0.verify|verify} messages.
         * @function encode
         * @memberof espressif.SessionResp0
         * @static
         * @param {espressif.SessionResp0} message SessionResp0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionResp0.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.devicePubkey != null && Object.hasOwnProperty.call(message, "devicePubkey"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.devicePubkey);
            if (message.deviceRandom != null && Object.hasOwnProperty.call(message, "deviceRandom"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.deviceRandom);
            return writer;
        };

        /**
         * Encodes the specified SessionResp0 message, length delimited. Does not implicitly {@link espressif.SessionResp0.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.SessionResp0
         * @static
         * @param {espressif.SessionResp0} message SessionResp0 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionResp0.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionResp0 message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.SessionResp0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.SessionResp0} SessionResp0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionResp0.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.SessionResp0();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.devicePubkey = reader.bytes();
                    break;
                case 3:
                    message.deviceRandom = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionResp0 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.SessionResp0
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.SessionResp0} SessionResp0
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionResp0.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionResp0 message.
         * @function verify
         * @memberof espressif.SessionResp0
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionResp0.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.devicePubkey != null && message.hasOwnProperty("devicePubkey"))
                if (!(message.devicePubkey && typeof message.devicePubkey.length === "number" || $util.isString(message.devicePubkey)))
                    return "devicePubkey: buffer expected";
            if (message.deviceRandom != null && message.hasOwnProperty("deviceRandom"))
                if (!(message.deviceRandom && typeof message.deviceRandom.length === "number" || $util.isString(message.deviceRandom)))
                    return "deviceRandom: buffer expected";
            return null;
        };

        /**
         * Creates a SessionResp0 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.SessionResp0
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.SessionResp0} SessionResp0
         */
        SessionResp0.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.SessionResp0)
                return object;
            let message = new $root.espressif.SessionResp0();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            if (object.devicePubkey != null)
                if (typeof object.devicePubkey === "string")
                    $util.base64.decode(object.devicePubkey, message.devicePubkey = $util.newBuffer($util.base64.length(object.devicePubkey)), 0);
                else if (object.devicePubkey.length)
                    message.devicePubkey = object.devicePubkey;
            if (object.deviceRandom != null)
                if (typeof object.deviceRandom === "string")
                    $util.base64.decode(object.deviceRandom, message.deviceRandom = $util.newBuffer($util.base64.length(object.deviceRandom)), 0);
                else if (object.deviceRandom.length)
                    message.deviceRandom = object.deviceRandom;
            return message;
        };

        /**
         * Creates a plain object from a SessionResp0 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.SessionResp0
         * @static
         * @param {espressif.SessionResp0} message SessionResp0
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionResp0.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "Success" : 0;
                if (options.bytes === String)
                    object.devicePubkey = "";
                else {
                    object.devicePubkey = [];
                    if (options.bytes !== Array)
                        object.devicePubkey = $util.newBuffer(object.devicePubkey);
                }
                if (options.bytes === String)
                    object.deviceRandom = "";
                else {
                    object.deviceRandom = [];
                    if (options.bytes !== Array)
                        object.deviceRandom = $util.newBuffer(object.deviceRandom);
                }
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            if (message.devicePubkey != null && message.hasOwnProperty("devicePubkey"))
                object.devicePubkey = options.bytes === String ? $util.base64.encode(message.devicePubkey, 0, message.devicePubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.devicePubkey) : message.devicePubkey;
            if (message.deviceRandom != null && message.hasOwnProperty("deviceRandom"))
                object.deviceRandom = options.bytes === String ? $util.base64.encode(message.deviceRandom, 0, message.deviceRandom.length) : options.bytes === Array ? Array.prototype.slice.call(message.deviceRandom) : message.deviceRandom;
            return object;
        };

        /**
         * Converts this SessionResp0 to JSON.
         * @function toJSON
         * @memberof espressif.SessionResp0
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionResp0.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SessionResp0;
    })();

    /**
     * Sec1MsgType enum.
     * @name espressif.Sec1MsgType
     * @enum {number}
     * @property {number} Session_Command0=0 Session_Command0 value
     * @property {number} Session_Response0=1 Session_Response0 value
     * @property {number} Session_Command1=2 Session_Command1 value
     * @property {number} Session_Response1=3 Session_Response1 value
     */
    espressif.Sec1MsgType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Session_Command0"] = 0;
        values[valuesById[1] = "Session_Response0"] = 1;
        values[valuesById[2] = "Session_Command1"] = 2;
        values[valuesById[3] = "Session_Response1"] = 3;
        return values;
    })();

    espressif.Sec1Payload = (function() {

        /**
         * Properties of a Sec1Payload.
         * @memberof espressif
         * @interface ISec1Payload
         * @property {espressif.Sec1MsgType|null} [msg] Sec1Payload msg
         * @property {espressif.SessionCmd0|null} [sc0] Sec1Payload sc0
         * @property {espressif.SessionResp0|null} [sr0] Sec1Payload sr0
         * @property {espressif.SessionCmd1|null} [sc1] Sec1Payload sc1
         * @property {espressif.SessionResp1|null} [sr1] Sec1Payload sr1
         */

        /**
         * Constructs a new Sec1Payload.
         * @memberof espressif
         * @classdesc Represents a Sec1Payload.
         * @implements ISec1Payload
         * @constructor
         * @param {espressif.ISec1Payload=} [properties] Properties to set
         */
        function Sec1Payload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Sec1Payload msg.
         * @member {espressif.Sec1MsgType} msg
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Sec1Payload.prototype.msg = 0;

        /**
         * Sec1Payload sc0.
         * @member {espressif.SessionCmd0|null|undefined} sc0
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Sec1Payload.prototype.sc0 = null;

        /**
         * Sec1Payload sr0.
         * @member {espressif.SessionResp0|null|undefined} sr0
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Sec1Payload.prototype.sr0 = null;

        /**
         * Sec1Payload sc1.
         * @member {espressif.SessionCmd1|null|undefined} sc1
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Sec1Payload.prototype.sc1 = null;

        /**
         * Sec1Payload sr1.
         * @member {espressif.SessionResp1|null|undefined} sr1
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Sec1Payload.prototype.sr1 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Sec1Payload payload.
         * @member {"sc0"|"sr0"|"sc1"|"sr1"|undefined} payload
         * @memberof espressif.Sec1Payload
         * @instance
         */
        Object.defineProperty(Sec1Payload.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["sc0", "sr0", "sc1", "sr1"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Sec1Payload instance using the specified properties.
         * @function create
         * @memberof espressif.Sec1Payload
         * @static
         * @param {espressif.ISec1Payload=} [properties] Properties to set
         * @returns {espressif.Sec1Payload} Sec1Payload instance
         */
        Sec1Payload.create = function create(properties) {
            return new Sec1Payload(properties);
        };

        /**
         * Encodes the specified Sec1Payload message. Does not implicitly {@link espressif.Sec1Payload.verify|verify} messages.
         * @function encode
         * @memberof espressif.Sec1Payload
         * @static
         * @param {espressif.Sec1Payload} message Sec1Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sec1Payload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
            if (message.sc0 != null && Object.hasOwnProperty.call(message, "sc0"))
                $root.espressif.SessionCmd0.encode(message.sc0, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.sr0 != null && Object.hasOwnProperty.call(message, "sr0"))
                $root.espressif.SessionResp0.encode(message.sr0, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.sc1 != null && Object.hasOwnProperty.call(message, "sc1"))
                $root.espressif.SessionCmd1.encode(message.sc1, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.sr1 != null && Object.hasOwnProperty.call(message, "sr1"))
                $root.espressif.SessionResp1.encode(message.sr1, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Sec1Payload message, length delimited. Does not implicitly {@link espressif.Sec1Payload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.Sec1Payload
         * @static
         * @param {espressif.Sec1Payload} message Sec1Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sec1Payload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Sec1Payload message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.Sec1Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.Sec1Payload} Sec1Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sec1Payload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.Sec1Payload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.msg = reader.int32();
                    break;
                case 20:
                    message.sc0 = $root.espressif.SessionCmd0.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.sr0 = $root.espressif.SessionResp0.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.sc1 = $root.espressif.SessionCmd1.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.sr1 = $root.espressif.SessionResp1.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Sec1Payload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.Sec1Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.Sec1Payload} Sec1Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sec1Payload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Sec1Payload message.
         * @function verify
         * @memberof espressif.Sec1Payload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Sec1Payload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msg != null && message.hasOwnProperty("msg"))
                switch (message.msg) {
                default:
                    return "msg: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.sc0 != null && message.hasOwnProperty("sc0")) {
                properties.payload = 1;
                {
                    let error = $root.espressif.SessionCmd0.verify(message.sc0);
                    if (error)
                        return "sc0." + error;
                }
            }
            if (message.sr0 != null && message.hasOwnProperty("sr0")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.SessionResp0.verify(message.sr0);
                    if (error)
                        return "sr0." + error;
                }
            }
            if (message.sc1 != null && message.hasOwnProperty("sc1")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.SessionCmd1.verify(message.sc1);
                    if (error)
                        return "sc1." + error;
                }
            }
            if (message.sr1 != null && message.hasOwnProperty("sr1")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.SessionResp1.verify(message.sr1);
                    if (error)
                        return "sr1." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Sec1Payload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.Sec1Payload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.Sec1Payload} Sec1Payload
         */
        Sec1Payload.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.Sec1Payload)
                return object;
            let message = new $root.espressif.Sec1Payload();
            switch (object.msg) {
            case "Session_Command0":
            case 0:
                message.msg = 0;
                break;
            case "Session_Response0":
            case 1:
                message.msg = 1;
                break;
            case "Session_Command1":
            case 2:
                message.msg = 2;
                break;
            case "Session_Response1":
            case 3:
                message.msg = 3;
                break;
            }
            if (object.sc0 != null) {
                if (typeof object.sc0 !== "object")
                    throw TypeError(".espressif.Sec1Payload.sc0: object expected");
                message.sc0 = $root.espressif.SessionCmd0.fromObject(object.sc0);
            }
            if (object.sr0 != null) {
                if (typeof object.sr0 !== "object")
                    throw TypeError(".espressif.Sec1Payload.sr0: object expected");
                message.sr0 = $root.espressif.SessionResp0.fromObject(object.sr0);
            }
            if (object.sc1 != null) {
                if (typeof object.sc1 !== "object")
                    throw TypeError(".espressif.Sec1Payload.sc1: object expected");
                message.sc1 = $root.espressif.SessionCmd1.fromObject(object.sc1);
            }
            if (object.sr1 != null) {
                if (typeof object.sr1 !== "object")
                    throw TypeError(".espressif.Sec1Payload.sr1: object expected");
                message.sr1 = $root.espressif.SessionResp1.fromObject(object.sr1);
            }
            return message;
        };

        /**
         * Creates a plain object from a Sec1Payload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.Sec1Payload
         * @static
         * @param {espressif.Sec1Payload} message Sec1Payload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sec1Payload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = options.enums === String ? "Session_Command0" : 0;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.enums === String ? $root.espressif.Sec1MsgType[message.msg] : message.msg;
            if (message.sc0 != null && message.hasOwnProperty("sc0")) {
                object.sc0 = $root.espressif.SessionCmd0.toObject(message.sc0, options);
                if (options.oneofs)
                    object.payload = "sc0";
            }
            if (message.sr0 != null && message.hasOwnProperty("sr0")) {
                object.sr0 = $root.espressif.SessionResp0.toObject(message.sr0, options);
                if (options.oneofs)
                    object.payload = "sr0";
            }
            if (message.sc1 != null && message.hasOwnProperty("sc1")) {
                object.sc1 = $root.espressif.SessionCmd1.toObject(message.sc1, options);
                if (options.oneofs)
                    object.payload = "sc1";
            }
            if (message.sr1 != null && message.hasOwnProperty("sr1")) {
                object.sr1 = $root.espressif.SessionResp1.toObject(message.sr1, options);
                if (options.oneofs)
                    object.payload = "sr1";
            }
            return object;
        };

        /**
         * Converts this Sec1Payload to JSON.
         * @function toJSON
         * @memberof espressif.Sec1Payload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sec1Payload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Sec1Payload;
    })();

    /**
     * SecSchemeVersion enum.
     * @name espressif.SecSchemeVersion
     * @enum {number}
     * @property {number} SecScheme0=0 SecScheme0 value
     * @property {number} SecScheme1=1 SecScheme1 value
     */
    espressif.SecSchemeVersion = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SecScheme0"] = 0;
        values[valuesById[1] = "SecScheme1"] = 1;
        return values;
    })();

    espressif.SessionData = (function() {

        /**
         * Properties of a SessionData.
         * @memberof espressif
         * @interface ISessionData
         * @property {espressif.SecSchemeVersion|null} [secVer] SessionData secVer
         * @property {espressif.Sec0Payload|null} [sec0] SessionData sec0
         * @property {espressif.Sec1Payload|null} [sec1] SessionData sec1
         */

        /**
         * Constructs a new SessionData.
         * @memberof espressif
         * @classdesc Represents a SessionData.
         * @implements ISessionData
         * @constructor
         * @param {espressif.ISessionData=} [properties] Properties to set
         */
        function SessionData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SessionData secVer.
         * @member {espressif.SecSchemeVersion} secVer
         * @memberof espressif.SessionData
         * @instance
         */
        SessionData.prototype.secVer = 0;

        /**
         * SessionData sec0.
         * @member {espressif.Sec0Payload|null|undefined} sec0
         * @memberof espressif.SessionData
         * @instance
         */
        SessionData.prototype.sec0 = null;

        /**
         * SessionData sec1.
         * @member {espressif.Sec1Payload|null|undefined} sec1
         * @memberof espressif.SessionData
         * @instance
         */
        SessionData.prototype.sec1 = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SessionData proto.
         * @member {"sec0"|"sec1"|undefined} proto
         * @memberof espressif.SessionData
         * @instance
         */
        Object.defineProperty(SessionData.prototype, "proto", {
            get: $util.oneOfGetter($oneOfFields = ["sec0", "sec1"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SessionData instance using the specified properties.
         * @function create
         * @memberof espressif.SessionData
         * @static
         * @param {espressif.ISessionData=} [properties] Properties to set
         * @returns {espressif.SessionData} SessionData instance
         */
        SessionData.create = function create(properties) {
            return new SessionData(properties);
        };

        /**
         * Encodes the specified SessionData message. Does not implicitly {@link espressif.SessionData.verify|verify} messages.
         * @function encode
         * @memberof espressif.SessionData
         * @static
         * @param {espressif.SessionData} message SessionData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.secVer != null && Object.hasOwnProperty.call(message, "secVer"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.secVer);
            if (message.sec0 != null && Object.hasOwnProperty.call(message, "sec0"))
                $root.espressif.Sec0Payload.encode(message.sec0, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.sec1 != null && Object.hasOwnProperty.call(message, "sec1"))
                $root.espressif.Sec1Payload.encode(message.sec1, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SessionData message, length delimited. Does not implicitly {@link espressif.SessionData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.SessionData
         * @static
         * @param {espressif.SessionData} message SessionData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SessionData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SessionData message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.SessionData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.SessionData} SessionData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.SessionData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.secVer = reader.int32();
                    break;
                case 10:
                    message.sec0 = $root.espressif.Sec0Payload.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.sec1 = $root.espressif.Sec1Payload.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SessionData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.SessionData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.SessionData} SessionData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SessionData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SessionData message.
         * @function verify
         * @memberof espressif.SessionData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SessionData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.secVer != null && message.hasOwnProperty("secVer"))
                switch (message.secVer) {
                default:
                    return "secVer: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.sec0 != null && message.hasOwnProperty("sec0")) {
                properties.proto = 1;
                {
                    let error = $root.espressif.Sec0Payload.verify(message.sec0);
                    if (error)
                        return "sec0." + error;
                }
            }
            if (message.sec1 != null && message.hasOwnProperty("sec1")) {
                if (properties.proto === 1)
                    return "proto: multiple values";
                properties.proto = 1;
                {
                    let error = $root.espressif.Sec1Payload.verify(message.sec1);
                    if (error)
                        return "sec1." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SessionData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.SessionData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.SessionData} SessionData
         */
        SessionData.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.SessionData)
                return object;
            let message = new $root.espressif.SessionData();
            switch (object.secVer) {
            case "SecScheme0":
            case 0:
                message.secVer = 0;
                break;
            case "SecScheme1":
            case 1:
                message.secVer = 1;
                break;
            }
            if (object.sec0 != null) {
                if (typeof object.sec0 !== "object")
                    throw TypeError(".espressif.SessionData.sec0: object expected");
                message.sec0 = $root.espressif.Sec0Payload.fromObject(object.sec0);
            }
            if (object.sec1 != null) {
                if (typeof object.sec1 !== "object")
                    throw TypeError(".espressif.SessionData.sec1: object expected");
                message.sec1 = $root.espressif.Sec1Payload.fromObject(object.sec1);
            }
            return message;
        };

        /**
         * Creates a plain object from a SessionData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.SessionData
         * @static
         * @param {espressif.SessionData} message SessionData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SessionData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.secVer = options.enums === String ? "SecScheme0" : 0;
            if (message.secVer != null && message.hasOwnProperty("secVer"))
                object.secVer = options.enums === String ? $root.espressif.SecSchemeVersion[message.secVer] : message.secVer;
            if (message.sec0 != null && message.hasOwnProperty("sec0")) {
                object.sec0 = $root.espressif.Sec0Payload.toObject(message.sec0, options);
                if (options.oneofs)
                    object.proto = "sec0";
            }
            if (message.sec1 != null && message.hasOwnProperty("sec1")) {
                object.sec1 = $root.espressif.Sec1Payload.toObject(message.sec1, options);
                if (options.oneofs)
                    object.proto = "sec1";
            }
            return object;
        };

        /**
         * Converts this SessionData to JSON.
         * @function toJSON
         * @memberof espressif.SessionData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SessionData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SessionData;
    })();

    espressif.CmdGetStatus = (function() {

        /**
         * Properties of a CmdGetStatus.
         * @memberof espressif
         * @interface ICmdGetStatus
         */

        /**
         * Constructs a new CmdGetStatus.
         * @memberof espressif
         * @classdesc Represents a CmdGetStatus.
         * @implements ICmdGetStatus
         * @constructor
         * @param {espressif.ICmdGetStatus=} [properties] Properties to set
         */
        function CmdGetStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CmdGetStatus instance using the specified properties.
         * @function create
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {espressif.ICmdGetStatus=} [properties] Properties to set
         * @returns {espressif.CmdGetStatus} CmdGetStatus instance
         */
        CmdGetStatus.create = function create(properties) {
            return new CmdGetStatus(properties);
        };

        /**
         * Encodes the specified CmdGetStatus message. Does not implicitly {@link espressif.CmdGetStatus.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {espressif.CmdGetStatus} message CmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdGetStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CmdGetStatus message, length delimited. Does not implicitly {@link espressif.CmdGetStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {espressif.CmdGetStatus} message CmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdGetStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdGetStatus message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdGetStatus} CmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdGetStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdGetStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdGetStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdGetStatus} CmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdGetStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdGetStatus message.
         * @function verify
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdGetStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CmdGetStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdGetStatus} CmdGetStatus
         */
        CmdGetStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdGetStatus)
                return object;
            return new $root.espressif.CmdGetStatus();
        };

        /**
         * Creates a plain object from a CmdGetStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdGetStatus
         * @static
         * @param {espressif.CmdGetStatus} message CmdGetStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdGetStatus.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CmdGetStatus to JSON.
         * @function toJSON
         * @memberof espressif.CmdGetStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdGetStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdGetStatus;
    })();

    espressif.RespGetStatus = (function() {

        /**
         * Properties of a RespGetStatus.
         * @memberof espressif
         * @interface IRespGetStatus
         * @property {espressif.Status|null} [status] RespGetStatus status
         * @property {espressif.WifiStationState|null} [staState] RespGetStatus staState
         * @property {espressif.WifiConnectFailedReason|null} [failReason] RespGetStatus failReason
         * @property {espressif.WifiConnectedState|null} [connected] RespGetStatus connected
         */

        /**
         * Constructs a new RespGetStatus.
         * @memberof espressif
         * @classdesc Represents a RespGetStatus.
         * @implements IRespGetStatus
         * @constructor
         * @param {espressif.IRespGetStatus=} [properties] Properties to set
         */
        function RespGetStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespGetStatus status.
         * @member {espressif.Status} status
         * @memberof espressif.RespGetStatus
         * @instance
         */
        RespGetStatus.prototype.status = 0;

        /**
         * RespGetStatus staState.
         * @member {espressif.WifiStationState} staState
         * @memberof espressif.RespGetStatus
         * @instance
         */
        RespGetStatus.prototype.staState = 0;

        /**
         * RespGetStatus failReason.
         * @member {espressif.WifiConnectFailedReason|null|undefined} failReason
         * @memberof espressif.RespGetStatus
         * @instance
         */
        RespGetStatus.prototype.failReason = null;

        /**
         * RespGetStatus connected.
         * @member {espressif.WifiConnectedState|null|undefined} connected
         * @memberof espressif.RespGetStatus
         * @instance
         */
        RespGetStatus.prototype.connected = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RespGetStatus state.
         * @member {"failReason"|"connected"|undefined} state
         * @memberof espressif.RespGetStatus
         * @instance
         */
        Object.defineProperty(RespGetStatus.prototype, "state", {
            get: $util.oneOfGetter($oneOfFields = ["failReason", "connected"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RespGetStatus instance using the specified properties.
         * @function create
         * @memberof espressif.RespGetStatus
         * @static
         * @param {espressif.IRespGetStatus=} [properties] Properties to set
         * @returns {espressif.RespGetStatus} RespGetStatus instance
         */
        RespGetStatus.create = function create(properties) {
            return new RespGetStatus(properties);
        };

        /**
         * Encodes the specified RespGetStatus message. Does not implicitly {@link espressif.RespGetStatus.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespGetStatus
         * @static
         * @param {espressif.RespGetStatus} message RespGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespGetStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.staState != null && Object.hasOwnProperty.call(message, "staState"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.staState);
            if (message.failReason != null && Object.hasOwnProperty.call(message, "failReason"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.failReason);
            if (message.connected != null && Object.hasOwnProperty.call(message, "connected"))
                $root.espressif.WifiConnectedState.encode(message.connected, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RespGetStatus message, length delimited. Does not implicitly {@link espressif.RespGetStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespGetStatus
         * @static
         * @param {espressif.RespGetStatus} message RespGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespGetStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespGetStatus message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespGetStatus} RespGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespGetStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespGetStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.staState = reader.int32();
                    break;
                case 10:
                    message.failReason = reader.int32();
                    break;
                case 11:
                    message.connected = $root.espressif.WifiConnectedState.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespGetStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespGetStatus} RespGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespGetStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespGetStatus message.
         * @function verify
         * @memberof espressif.RespGetStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespGetStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.staState != null && message.hasOwnProperty("staState"))
                switch (message.staState) {
                default:
                    return "staState: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.failReason != null && message.hasOwnProperty("failReason")) {
                properties.state = 1;
                switch (message.failReason) {
                default:
                    return "failReason: enum value expected";
                case 0:
                case 1:
                    break;
                }
            }
            if (message.connected != null && message.hasOwnProperty("connected")) {
                if (properties.state === 1)
                    return "state: multiple values";
                properties.state = 1;
                {
                    let error = $root.espressif.WifiConnectedState.verify(message.connected);
                    if (error)
                        return "connected." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RespGetStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespGetStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespGetStatus} RespGetStatus
         */
        RespGetStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespGetStatus)
                return object;
            let message = new $root.espressif.RespGetStatus();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            switch (object.staState) {
            case "Connected":
            case 0:
                message.staState = 0;
                break;
            case "Connecting":
            case 1:
                message.staState = 1;
                break;
            case "Disconnected":
            case 2:
                message.staState = 2;
                break;
            case "ConnectionFailed":
            case 3:
                message.staState = 3;
                break;
            }
            switch (object.failReason) {
            case "AuthError":
            case 0:
                message.failReason = 0;
                break;
            case "NetworkNotFound":
            case 1:
                message.failReason = 1;
                break;
            }
            if (object.connected != null) {
                if (typeof object.connected !== "object")
                    throw TypeError(".espressif.RespGetStatus.connected: object expected");
                message.connected = $root.espressif.WifiConnectedState.fromObject(object.connected);
            }
            return message;
        };

        /**
         * Creates a plain object from a RespGetStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespGetStatus
         * @static
         * @param {espressif.RespGetStatus} message RespGetStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespGetStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "Success" : 0;
                object.staState = options.enums === String ? "Connected" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            if (message.staState != null && message.hasOwnProperty("staState"))
                object.staState = options.enums === String ? $root.espressif.WifiStationState[message.staState] : message.staState;
            if (message.failReason != null && message.hasOwnProperty("failReason")) {
                object.failReason = options.enums === String ? $root.espressif.WifiConnectFailedReason[message.failReason] : message.failReason;
                if (options.oneofs)
                    object.state = "failReason";
            }
            if (message.connected != null && message.hasOwnProperty("connected")) {
                object.connected = $root.espressif.WifiConnectedState.toObject(message.connected, options);
                if (options.oneofs)
                    object.state = "connected";
            }
            return object;
        };

        /**
         * Converts this RespGetStatus to JSON.
         * @function toJSON
         * @memberof espressif.RespGetStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespGetStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespGetStatus;
    })();

    espressif.CmdSetConfig = (function() {

        /**
         * Properties of a CmdSetConfig.
         * @memberof espressif
         * @interface ICmdSetConfig
         * @property {Uint8Array|null} [ssid] CmdSetConfig ssid
         * @property {Uint8Array|null} [passphrase] CmdSetConfig passphrase
         * @property {Uint8Array|null} [bssid] CmdSetConfig bssid
         * @property {number|null} [channel] CmdSetConfig channel
         */

        /**
         * Constructs a new CmdSetConfig.
         * @memberof espressif
         * @classdesc Represents a CmdSetConfig.
         * @implements ICmdSetConfig
         * @constructor
         * @param {espressif.ICmdSetConfig=} [properties] Properties to set
         */
        function CmdSetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CmdSetConfig ssid.
         * @member {Uint8Array} ssid
         * @memberof espressif.CmdSetConfig
         * @instance
         */
        CmdSetConfig.prototype.ssid = $util.newBuffer([]);

        /**
         * CmdSetConfig passphrase.
         * @member {Uint8Array} passphrase
         * @memberof espressif.CmdSetConfig
         * @instance
         */
        CmdSetConfig.prototype.passphrase = $util.newBuffer([]);

        /**
         * CmdSetConfig bssid.
         * @member {Uint8Array} bssid
         * @memberof espressif.CmdSetConfig
         * @instance
         */
        CmdSetConfig.prototype.bssid = $util.newBuffer([]);

        /**
         * CmdSetConfig channel.
         * @member {number} channel
         * @memberof espressif.CmdSetConfig
         * @instance
         */
        CmdSetConfig.prototype.channel = 0;

        /**
         * Creates a new CmdSetConfig instance using the specified properties.
         * @function create
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {espressif.ICmdSetConfig=} [properties] Properties to set
         * @returns {espressif.CmdSetConfig} CmdSetConfig instance
         */
        CmdSetConfig.create = function create(properties) {
            return new CmdSetConfig(properties);
        };

        /**
         * Encodes the specified CmdSetConfig message. Does not implicitly {@link espressif.CmdSetConfig.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {espressif.CmdSetConfig} message CmdSetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdSetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssid != null && Object.hasOwnProperty.call(message, "ssid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.ssid);
            if (message.passphrase != null && Object.hasOwnProperty.call(message, "passphrase"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.passphrase);
            if (message.bssid != null && Object.hasOwnProperty.call(message, "bssid"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.bssid);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.channel);
            return writer;
        };

        /**
         * Encodes the specified CmdSetConfig message, length delimited. Does not implicitly {@link espressif.CmdSetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {espressif.CmdSetConfig} message CmdSetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdSetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdSetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdSetConfig} CmdSetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdSetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdSetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssid = reader.bytes();
                    break;
                case 2:
                    message.passphrase = reader.bytes();
                    break;
                case 3:
                    message.bssid = reader.bytes();
                    break;
                case 4:
                    message.channel = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdSetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdSetConfig} CmdSetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdSetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdSetConfig message.
         * @function verify
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdSetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                if (!(message.ssid && typeof message.ssid.length === "number" || $util.isString(message.ssid)))
                    return "ssid: buffer expected";
            if (message.passphrase != null && message.hasOwnProperty("passphrase"))
                if (!(message.passphrase && typeof message.passphrase.length === "number" || $util.isString(message.passphrase)))
                    return "passphrase: buffer expected";
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                if (!(message.bssid && typeof message.bssid.length === "number" || $util.isString(message.bssid)))
                    return "bssid: buffer expected";
            if (message.channel != null && message.hasOwnProperty("channel"))
                if (!$util.isInteger(message.channel))
                    return "channel: integer expected";
            return null;
        };

        /**
         * Creates a CmdSetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdSetConfig} CmdSetConfig
         */
        CmdSetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdSetConfig)
                return object;
            let message = new $root.espressif.CmdSetConfig();
            if (object.ssid != null)
                if (typeof object.ssid === "string")
                    $util.base64.decode(object.ssid, message.ssid = $util.newBuffer($util.base64.length(object.ssid)), 0);
                else if (object.ssid.length)
                    message.ssid = object.ssid;
            if (object.passphrase != null)
                if (typeof object.passphrase === "string")
                    $util.base64.decode(object.passphrase, message.passphrase = $util.newBuffer($util.base64.length(object.passphrase)), 0);
                else if (object.passphrase.length)
                    message.passphrase = object.passphrase;
            if (object.bssid != null)
                if (typeof object.bssid === "string")
                    $util.base64.decode(object.bssid, message.bssid = $util.newBuffer($util.base64.length(object.bssid)), 0);
                else if (object.bssid.length)
                    message.bssid = object.bssid;
            if (object.channel != null)
                message.channel = object.channel | 0;
            return message;
        };

        /**
         * Creates a plain object from a CmdSetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdSetConfig
         * @static
         * @param {espressif.CmdSetConfig} message CmdSetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdSetConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.ssid = "";
                else {
                    object.ssid = [];
                    if (options.bytes !== Array)
                        object.ssid = $util.newBuffer(object.ssid);
                }
                if (options.bytes === String)
                    object.passphrase = "";
                else {
                    object.passphrase = [];
                    if (options.bytes !== Array)
                        object.passphrase = $util.newBuffer(object.passphrase);
                }
                if (options.bytes === String)
                    object.bssid = "";
                else {
                    object.bssid = [];
                    if (options.bytes !== Array)
                        object.bssid = $util.newBuffer(object.bssid);
                }
                object.channel = 0;
            }
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                object.ssid = options.bytes === String ? $util.base64.encode(message.ssid, 0, message.ssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.ssid) : message.ssid;
            if (message.passphrase != null && message.hasOwnProperty("passphrase"))
                object.passphrase = options.bytes === String ? $util.base64.encode(message.passphrase, 0, message.passphrase.length) : options.bytes === Array ? Array.prototype.slice.call(message.passphrase) : message.passphrase;
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                object.bssid = options.bytes === String ? $util.base64.encode(message.bssid, 0, message.bssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.bssid) : message.bssid;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            return object;
        };

        /**
         * Converts this CmdSetConfig to JSON.
         * @function toJSON
         * @memberof espressif.CmdSetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdSetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdSetConfig;
    })();

    espressif.RespSetConfig = (function() {

        /**
         * Properties of a RespSetConfig.
         * @memberof espressif
         * @interface IRespSetConfig
         * @property {espressif.Status|null} [status] RespSetConfig status
         */

        /**
         * Constructs a new RespSetConfig.
         * @memberof espressif
         * @classdesc Represents a RespSetConfig.
         * @implements IRespSetConfig
         * @constructor
         * @param {espressif.IRespSetConfig=} [properties] Properties to set
         */
        function RespSetConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespSetConfig status.
         * @member {espressif.Status} status
         * @memberof espressif.RespSetConfig
         * @instance
         */
        RespSetConfig.prototype.status = 0;

        /**
         * Creates a new RespSetConfig instance using the specified properties.
         * @function create
         * @memberof espressif.RespSetConfig
         * @static
         * @param {espressif.IRespSetConfig=} [properties] Properties to set
         * @returns {espressif.RespSetConfig} RespSetConfig instance
         */
        RespSetConfig.create = function create(properties) {
            return new RespSetConfig(properties);
        };

        /**
         * Encodes the specified RespSetConfig message. Does not implicitly {@link espressif.RespSetConfig.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespSetConfig
         * @static
         * @param {espressif.RespSetConfig} message RespSetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespSetConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified RespSetConfig message, length delimited. Does not implicitly {@link espressif.RespSetConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespSetConfig
         * @static
         * @param {espressif.RespSetConfig} message RespSetConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespSetConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespSetConfig message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespSetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespSetConfig} RespSetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespSetConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespSetConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespSetConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespSetConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespSetConfig} RespSetConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespSetConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespSetConfig message.
         * @function verify
         * @memberof espressif.RespSetConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespSetConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a RespSetConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespSetConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespSetConfig} RespSetConfig
         */
        RespSetConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespSetConfig)
                return object;
            let message = new $root.espressif.RespSetConfig();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RespSetConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespSetConfig
         * @static
         * @param {espressif.RespSetConfig} message RespSetConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespSetConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "Success" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this RespSetConfig to JSON.
         * @function toJSON
         * @memberof espressif.RespSetConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespSetConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespSetConfig;
    })();

    espressif.CmdApplyConfig = (function() {

        /**
         * Properties of a CmdApplyConfig.
         * @memberof espressif
         * @interface ICmdApplyConfig
         */

        /**
         * Constructs a new CmdApplyConfig.
         * @memberof espressif
         * @classdesc Represents a CmdApplyConfig.
         * @implements ICmdApplyConfig
         * @constructor
         * @param {espressif.ICmdApplyConfig=} [properties] Properties to set
         */
        function CmdApplyConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CmdApplyConfig instance using the specified properties.
         * @function create
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {espressif.ICmdApplyConfig=} [properties] Properties to set
         * @returns {espressif.CmdApplyConfig} CmdApplyConfig instance
         */
        CmdApplyConfig.create = function create(properties) {
            return new CmdApplyConfig(properties);
        };

        /**
         * Encodes the specified CmdApplyConfig message. Does not implicitly {@link espressif.CmdApplyConfig.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {espressif.CmdApplyConfig} message CmdApplyConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdApplyConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CmdApplyConfig message, length delimited. Does not implicitly {@link espressif.CmdApplyConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {espressif.CmdApplyConfig} message CmdApplyConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdApplyConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdApplyConfig message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdApplyConfig} CmdApplyConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdApplyConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdApplyConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdApplyConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdApplyConfig} CmdApplyConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdApplyConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdApplyConfig message.
         * @function verify
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdApplyConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CmdApplyConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdApplyConfig} CmdApplyConfig
         */
        CmdApplyConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdApplyConfig)
                return object;
            return new $root.espressif.CmdApplyConfig();
        };

        /**
         * Creates a plain object from a CmdApplyConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdApplyConfig
         * @static
         * @param {espressif.CmdApplyConfig} message CmdApplyConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdApplyConfig.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CmdApplyConfig to JSON.
         * @function toJSON
         * @memberof espressif.CmdApplyConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdApplyConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdApplyConfig;
    })();

    espressif.RespApplyConfig = (function() {

        /**
         * Properties of a RespApplyConfig.
         * @memberof espressif
         * @interface IRespApplyConfig
         * @property {espressif.Status|null} [status] RespApplyConfig status
         */

        /**
         * Constructs a new RespApplyConfig.
         * @memberof espressif
         * @classdesc Represents a RespApplyConfig.
         * @implements IRespApplyConfig
         * @constructor
         * @param {espressif.IRespApplyConfig=} [properties] Properties to set
         */
        function RespApplyConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespApplyConfig status.
         * @member {espressif.Status} status
         * @memberof espressif.RespApplyConfig
         * @instance
         */
        RespApplyConfig.prototype.status = 0;

        /**
         * Creates a new RespApplyConfig instance using the specified properties.
         * @function create
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {espressif.IRespApplyConfig=} [properties] Properties to set
         * @returns {espressif.RespApplyConfig} RespApplyConfig instance
         */
        RespApplyConfig.create = function create(properties) {
            return new RespApplyConfig(properties);
        };

        /**
         * Encodes the specified RespApplyConfig message. Does not implicitly {@link espressif.RespApplyConfig.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {espressif.RespApplyConfig} message RespApplyConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespApplyConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified RespApplyConfig message, length delimited. Does not implicitly {@link espressif.RespApplyConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {espressif.RespApplyConfig} message RespApplyConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespApplyConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespApplyConfig message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespApplyConfig} RespApplyConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespApplyConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespApplyConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespApplyConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespApplyConfig} RespApplyConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespApplyConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespApplyConfig message.
         * @function verify
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespApplyConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            return null;
        };

        /**
         * Creates a RespApplyConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespApplyConfig} RespApplyConfig
         */
        RespApplyConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespApplyConfig)
                return object;
            let message = new $root.espressif.RespApplyConfig();
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RespApplyConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespApplyConfig
         * @static
         * @param {espressif.RespApplyConfig} message RespApplyConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespApplyConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.status = options.enums === String ? "Success" : 0;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            return object;
        };

        /**
         * Converts this RespApplyConfig to JSON.
         * @function toJSON
         * @memberof espressif.RespApplyConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespApplyConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespApplyConfig;
    })();

    /**
     * WiFiConfigMsgType enum.
     * @name espressif.WiFiConfigMsgType
     * @enum {number}
     * @property {number} TypeCmdGetStatus=0 TypeCmdGetStatus value
     * @property {number} TypeRespGetStatus=1 TypeRespGetStatus value
     * @property {number} TypeCmdSetConfig=2 TypeCmdSetConfig value
     * @property {number} TypeRespSetConfig=3 TypeRespSetConfig value
     * @property {number} TypeCmdApplyConfig=4 TypeCmdApplyConfig value
     * @property {number} TypeRespApplyConfig=5 TypeRespApplyConfig value
     */
    espressif.WiFiConfigMsgType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TypeCmdGetStatus"] = 0;
        values[valuesById[1] = "TypeRespGetStatus"] = 1;
        values[valuesById[2] = "TypeCmdSetConfig"] = 2;
        values[valuesById[3] = "TypeRespSetConfig"] = 3;
        values[valuesById[4] = "TypeCmdApplyConfig"] = 4;
        values[valuesById[5] = "TypeRespApplyConfig"] = 5;
        return values;
    })();

    espressif.WiFiConfigPayload = (function() {

        /**
         * Properties of a WiFiConfigPayload.
         * @memberof espressif
         * @interface IWiFiConfigPayload
         * @property {espressif.WiFiConfigMsgType|null} [msg] WiFiConfigPayload msg
         * @property {espressif.CmdGetStatus|null} [cmdGetStatus] WiFiConfigPayload cmdGetStatus
         * @property {espressif.RespGetStatus|null} [respGetStatus] WiFiConfigPayload respGetStatus
         * @property {espressif.CmdSetConfig|null} [cmdSetConfig] WiFiConfigPayload cmdSetConfig
         * @property {espressif.RespSetConfig|null} [respSetConfig] WiFiConfigPayload respSetConfig
         * @property {espressif.CmdApplyConfig|null} [cmdApplyConfig] WiFiConfigPayload cmdApplyConfig
         * @property {espressif.RespApplyConfig|null} [respApplyConfig] WiFiConfigPayload respApplyConfig
         */

        /**
         * Constructs a new WiFiConfigPayload.
         * @memberof espressif
         * @classdesc Represents a WiFiConfigPayload.
         * @implements IWiFiConfigPayload
         * @constructor
         * @param {espressif.IWiFiConfigPayload=} [properties] Properties to set
         */
        function WiFiConfigPayload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WiFiConfigPayload msg.
         * @member {espressif.WiFiConfigMsgType} msg
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.msg = 0;

        /**
         * WiFiConfigPayload cmdGetStatus.
         * @member {espressif.CmdGetStatus|null|undefined} cmdGetStatus
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.cmdGetStatus = null;

        /**
         * WiFiConfigPayload respGetStatus.
         * @member {espressif.RespGetStatus|null|undefined} respGetStatus
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.respGetStatus = null;

        /**
         * WiFiConfigPayload cmdSetConfig.
         * @member {espressif.CmdSetConfig|null|undefined} cmdSetConfig
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.cmdSetConfig = null;

        /**
         * WiFiConfigPayload respSetConfig.
         * @member {espressif.RespSetConfig|null|undefined} respSetConfig
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.respSetConfig = null;

        /**
         * WiFiConfigPayload cmdApplyConfig.
         * @member {espressif.CmdApplyConfig|null|undefined} cmdApplyConfig
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.cmdApplyConfig = null;

        /**
         * WiFiConfigPayload respApplyConfig.
         * @member {espressif.RespApplyConfig|null|undefined} respApplyConfig
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        WiFiConfigPayload.prototype.respApplyConfig = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * WiFiConfigPayload payload.
         * @member {"cmdGetStatus"|"respGetStatus"|"cmdSetConfig"|"respSetConfig"|"cmdApplyConfig"|"respApplyConfig"|undefined} payload
         * @memberof espressif.WiFiConfigPayload
         * @instance
         */
        Object.defineProperty(WiFiConfigPayload.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["cmdGetStatus", "respGetStatus", "cmdSetConfig", "respSetConfig", "cmdApplyConfig", "respApplyConfig"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WiFiConfigPayload instance using the specified properties.
         * @function create
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {espressif.IWiFiConfigPayload=} [properties] Properties to set
         * @returns {espressif.WiFiConfigPayload} WiFiConfigPayload instance
         */
        WiFiConfigPayload.create = function create(properties) {
            return new WiFiConfigPayload(properties);
        };

        /**
         * Encodes the specified WiFiConfigPayload message. Does not implicitly {@link espressif.WiFiConfigPayload.verify|verify} messages.
         * @function encode
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {espressif.WiFiConfigPayload} message WiFiConfigPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiConfigPayload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
            if (message.cmdGetStatus != null && Object.hasOwnProperty.call(message, "cmdGetStatus"))
                $root.espressif.CmdGetStatus.encode(message.cmdGetStatus, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.respGetStatus != null && Object.hasOwnProperty.call(message, "respGetStatus"))
                $root.espressif.RespGetStatus.encode(message.respGetStatus, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.cmdSetConfig != null && Object.hasOwnProperty.call(message, "cmdSetConfig"))
                $root.espressif.CmdSetConfig.encode(message.cmdSetConfig, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.respSetConfig != null && Object.hasOwnProperty.call(message, "respSetConfig"))
                $root.espressif.RespSetConfig.encode(message.respSetConfig, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.cmdApplyConfig != null && Object.hasOwnProperty.call(message, "cmdApplyConfig"))
                $root.espressif.CmdApplyConfig.encode(message.cmdApplyConfig, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.respApplyConfig != null && Object.hasOwnProperty.call(message, "respApplyConfig"))
                $root.espressif.RespApplyConfig.encode(message.respApplyConfig, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WiFiConfigPayload message, length delimited. Does not implicitly {@link espressif.WiFiConfigPayload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {espressif.WiFiConfigPayload} message WiFiConfigPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiConfigPayload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WiFiConfigPayload message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.WiFiConfigPayload} WiFiConfigPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiConfigPayload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.WiFiConfigPayload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.msg = reader.int32();
                    break;
                case 10:
                    message.cmdGetStatus = $root.espressif.CmdGetStatus.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.respGetStatus = $root.espressif.RespGetStatus.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.cmdSetConfig = $root.espressif.CmdSetConfig.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.respSetConfig = $root.espressif.RespSetConfig.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.cmdApplyConfig = $root.espressif.CmdApplyConfig.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.respApplyConfig = $root.espressif.RespApplyConfig.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WiFiConfigPayload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.WiFiConfigPayload} WiFiConfigPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiConfigPayload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WiFiConfigPayload message.
         * @function verify
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WiFiConfigPayload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msg != null && message.hasOwnProperty("msg"))
                switch (message.msg) {
                default:
                    return "msg: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.cmdGetStatus != null && message.hasOwnProperty("cmdGetStatus")) {
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdGetStatus.verify(message.cmdGetStatus);
                    if (error)
                        return "cmdGetStatus." + error;
                }
            }
            if (message.respGetStatus != null && message.hasOwnProperty("respGetStatus")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespGetStatus.verify(message.respGetStatus);
                    if (error)
                        return "respGetStatus." + error;
                }
            }
            if (message.cmdSetConfig != null && message.hasOwnProperty("cmdSetConfig")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdSetConfig.verify(message.cmdSetConfig);
                    if (error)
                        return "cmdSetConfig." + error;
                }
            }
            if (message.respSetConfig != null && message.hasOwnProperty("respSetConfig")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespSetConfig.verify(message.respSetConfig);
                    if (error)
                        return "respSetConfig." + error;
                }
            }
            if (message.cmdApplyConfig != null && message.hasOwnProperty("cmdApplyConfig")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdApplyConfig.verify(message.cmdApplyConfig);
                    if (error)
                        return "cmdApplyConfig." + error;
                }
            }
            if (message.respApplyConfig != null && message.hasOwnProperty("respApplyConfig")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespApplyConfig.verify(message.respApplyConfig);
                    if (error)
                        return "respApplyConfig." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WiFiConfigPayload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.WiFiConfigPayload} WiFiConfigPayload
         */
        WiFiConfigPayload.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.WiFiConfigPayload)
                return object;
            let message = new $root.espressif.WiFiConfigPayload();
            switch (object.msg) {
            case "TypeCmdGetStatus":
            case 0:
                message.msg = 0;
                break;
            case "TypeRespGetStatus":
            case 1:
                message.msg = 1;
                break;
            case "TypeCmdSetConfig":
            case 2:
                message.msg = 2;
                break;
            case "TypeRespSetConfig":
            case 3:
                message.msg = 3;
                break;
            case "TypeCmdApplyConfig":
            case 4:
                message.msg = 4;
                break;
            case "TypeRespApplyConfig":
            case 5:
                message.msg = 5;
                break;
            }
            if (object.cmdGetStatus != null) {
                if (typeof object.cmdGetStatus !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.cmdGetStatus: object expected");
                message.cmdGetStatus = $root.espressif.CmdGetStatus.fromObject(object.cmdGetStatus);
            }
            if (object.respGetStatus != null) {
                if (typeof object.respGetStatus !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.respGetStatus: object expected");
                message.respGetStatus = $root.espressif.RespGetStatus.fromObject(object.respGetStatus);
            }
            if (object.cmdSetConfig != null) {
                if (typeof object.cmdSetConfig !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.cmdSetConfig: object expected");
                message.cmdSetConfig = $root.espressif.CmdSetConfig.fromObject(object.cmdSetConfig);
            }
            if (object.respSetConfig != null) {
                if (typeof object.respSetConfig !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.respSetConfig: object expected");
                message.respSetConfig = $root.espressif.RespSetConfig.fromObject(object.respSetConfig);
            }
            if (object.cmdApplyConfig != null) {
                if (typeof object.cmdApplyConfig !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.cmdApplyConfig: object expected");
                message.cmdApplyConfig = $root.espressif.CmdApplyConfig.fromObject(object.cmdApplyConfig);
            }
            if (object.respApplyConfig != null) {
                if (typeof object.respApplyConfig !== "object")
                    throw TypeError(".espressif.WiFiConfigPayload.respApplyConfig: object expected");
                message.respApplyConfig = $root.espressif.RespApplyConfig.fromObject(object.respApplyConfig);
            }
            return message;
        };

        /**
         * Creates a plain object from a WiFiConfigPayload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.WiFiConfigPayload
         * @static
         * @param {espressif.WiFiConfigPayload} message WiFiConfigPayload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WiFiConfigPayload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = options.enums === String ? "TypeCmdGetStatus" : 0;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.enums === String ? $root.espressif.WiFiConfigMsgType[message.msg] : message.msg;
            if (message.cmdGetStatus != null && message.hasOwnProperty("cmdGetStatus")) {
                object.cmdGetStatus = $root.espressif.CmdGetStatus.toObject(message.cmdGetStatus, options);
                if (options.oneofs)
                    object.payload = "cmdGetStatus";
            }
            if (message.respGetStatus != null && message.hasOwnProperty("respGetStatus")) {
                object.respGetStatus = $root.espressif.RespGetStatus.toObject(message.respGetStatus, options);
                if (options.oneofs)
                    object.payload = "respGetStatus";
            }
            if (message.cmdSetConfig != null && message.hasOwnProperty("cmdSetConfig")) {
                object.cmdSetConfig = $root.espressif.CmdSetConfig.toObject(message.cmdSetConfig, options);
                if (options.oneofs)
                    object.payload = "cmdSetConfig";
            }
            if (message.respSetConfig != null && message.hasOwnProperty("respSetConfig")) {
                object.respSetConfig = $root.espressif.RespSetConfig.toObject(message.respSetConfig, options);
                if (options.oneofs)
                    object.payload = "respSetConfig";
            }
            if (message.cmdApplyConfig != null && message.hasOwnProperty("cmdApplyConfig")) {
                object.cmdApplyConfig = $root.espressif.CmdApplyConfig.toObject(message.cmdApplyConfig, options);
                if (options.oneofs)
                    object.payload = "cmdApplyConfig";
            }
            if (message.respApplyConfig != null && message.hasOwnProperty("respApplyConfig")) {
                object.respApplyConfig = $root.espressif.RespApplyConfig.toObject(message.respApplyConfig, options);
                if (options.oneofs)
                    object.payload = "respApplyConfig";
            }
            return object;
        };

        /**
         * Converts this WiFiConfigPayload to JSON.
         * @function toJSON
         * @memberof espressif.WiFiConfigPayload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WiFiConfigPayload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WiFiConfigPayload;
    })();

    /**
     * WifiStationState enum.
     * @name espressif.WifiStationState
     * @enum {number}
     * @property {number} Connected=0 Connected value
     * @property {number} Connecting=1 Connecting value
     * @property {number} Disconnected=2 Disconnected value
     * @property {number} ConnectionFailed=3 ConnectionFailed value
     */
    espressif.WifiStationState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Connected"] = 0;
        values[valuesById[1] = "Connecting"] = 1;
        values[valuesById[2] = "Disconnected"] = 2;
        values[valuesById[3] = "ConnectionFailed"] = 3;
        return values;
    })();

    /**
     * WifiConnectFailedReason enum.
     * @name espressif.WifiConnectFailedReason
     * @enum {number}
     * @property {number} AuthError=0 AuthError value
     * @property {number} NetworkNotFound=1 NetworkNotFound value
     */
    espressif.WifiConnectFailedReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AuthError"] = 0;
        values[valuesById[1] = "NetworkNotFound"] = 1;
        return values;
    })();

    /**
     * WifiAuthMode enum.
     * @name espressif.WifiAuthMode
     * @enum {number}
     * @property {number} Open=0 Open value
     * @property {number} WEP=1 WEP value
     * @property {number} WPA_PSK=2 WPA_PSK value
     * @property {number} WPA2_PSK=3 WPA2_PSK value
     * @property {number} WPA_WPA2_PSK=4 WPA_WPA2_PSK value
     * @property {number} WPA2_ENTERPRISE=5 WPA2_ENTERPRISE value
     */
    espressif.WifiAuthMode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Open"] = 0;
        values[valuesById[1] = "WEP"] = 1;
        values[valuesById[2] = "WPA_PSK"] = 2;
        values[valuesById[3] = "WPA2_PSK"] = 3;
        values[valuesById[4] = "WPA_WPA2_PSK"] = 4;
        values[valuesById[5] = "WPA2_ENTERPRISE"] = 5;
        return values;
    })();

    espressif.WifiConnectedState = (function() {

        /**
         * Properties of a WifiConnectedState.
         * @memberof espressif
         * @interface IWifiConnectedState
         * @property {string|null} [ip4Addr] WifiConnectedState ip4Addr
         * @property {espressif.WifiAuthMode|null} [authMode] WifiConnectedState authMode
         * @property {Uint8Array|null} [ssid] WifiConnectedState ssid
         * @property {Uint8Array|null} [bssid] WifiConnectedState bssid
         * @property {number|null} [channel] WifiConnectedState channel
         */

        /**
         * Constructs a new WifiConnectedState.
         * @memberof espressif
         * @classdesc Represents a WifiConnectedState.
         * @implements IWifiConnectedState
         * @constructor
         * @param {espressif.IWifiConnectedState=} [properties] Properties to set
         */
        function WifiConnectedState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WifiConnectedState ip4Addr.
         * @member {string} ip4Addr
         * @memberof espressif.WifiConnectedState
         * @instance
         */
        WifiConnectedState.prototype.ip4Addr = "";

        /**
         * WifiConnectedState authMode.
         * @member {espressif.WifiAuthMode} authMode
         * @memberof espressif.WifiConnectedState
         * @instance
         */
        WifiConnectedState.prototype.authMode = 0;

        /**
         * WifiConnectedState ssid.
         * @member {Uint8Array} ssid
         * @memberof espressif.WifiConnectedState
         * @instance
         */
        WifiConnectedState.prototype.ssid = $util.newBuffer([]);

        /**
         * WifiConnectedState bssid.
         * @member {Uint8Array} bssid
         * @memberof espressif.WifiConnectedState
         * @instance
         */
        WifiConnectedState.prototype.bssid = $util.newBuffer([]);

        /**
         * WifiConnectedState channel.
         * @member {number} channel
         * @memberof espressif.WifiConnectedState
         * @instance
         */
        WifiConnectedState.prototype.channel = 0;

        /**
         * Creates a new WifiConnectedState instance using the specified properties.
         * @function create
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {espressif.IWifiConnectedState=} [properties] Properties to set
         * @returns {espressif.WifiConnectedState} WifiConnectedState instance
         */
        WifiConnectedState.create = function create(properties) {
            return new WifiConnectedState(properties);
        };

        /**
         * Encodes the specified WifiConnectedState message. Does not implicitly {@link espressif.WifiConnectedState.verify|verify} messages.
         * @function encode
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {espressif.WifiConnectedState} message WifiConnectedState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WifiConnectedState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ip4Addr != null && Object.hasOwnProperty.call(message, "ip4Addr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ip4Addr);
            if (message.authMode != null && Object.hasOwnProperty.call(message, "authMode"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.authMode);
            if (message.ssid != null && Object.hasOwnProperty.call(message, "ssid"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.ssid);
            if (message.bssid != null && Object.hasOwnProperty.call(message, "bssid"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.bssid);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.channel);
            return writer;
        };

        /**
         * Encodes the specified WifiConnectedState message, length delimited. Does not implicitly {@link espressif.WifiConnectedState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {espressif.WifiConnectedState} message WifiConnectedState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WifiConnectedState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WifiConnectedState message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.WifiConnectedState} WifiConnectedState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WifiConnectedState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.WifiConnectedState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ip4Addr = reader.string();
                    break;
                case 2:
                    message.authMode = reader.int32();
                    break;
                case 3:
                    message.ssid = reader.bytes();
                    break;
                case 4:
                    message.bssid = reader.bytes();
                    break;
                case 5:
                    message.channel = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WifiConnectedState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.WifiConnectedState} WifiConnectedState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WifiConnectedState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WifiConnectedState message.
         * @function verify
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WifiConnectedState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ip4Addr != null && message.hasOwnProperty("ip4Addr"))
                if (!$util.isString(message.ip4Addr))
                    return "ip4Addr: string expected";
            if (message.authMode != null && message.hasOwnProperty("authMode"))
                switch (message.authMode) {
                default:
                    return "authMode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                if (!(message.ssid && typeof message.ssid.length === "number" || $util.isString(message.ssid)))
                    return "ssid: buffer expected";
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                if (!(message.bssid && typeof message.bssid.length === "number" || $util.isString(message.bssid)))
                    return "bssid: buffer expected";
            if (message.channel != null && message.hasOwnProperty("channel"))
                if (!$util.isInteger(message.channel))
                    return "channel: integer expected";
            return null;
        };

        /**
         * Creates a WifiConnectedState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.WifiConnectedState} WifiConnectedState
         */
        WifiConnectedState.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.WifiConnectedState)
                return object;
            let message = new $root.espressif.WifiConnectedState();
            if (object.ip4Addr != null)
                message.ip4Addr = String(object.ip4Addr);
            switch (object.authMode) {
            case "Open":
            case 0:
                message.authMode = 0;
                break;
            case "WEP":
            case 1:
                message.authMode = 1;
                break;
            case "WPA_PSK":
            case 2:
                message.authMode = 2;
                break;
            case "WPA2_PSK":
            case 3:
                message.authMode = 3;
                break;
            case "WPA_WPA2_PSK":
            case 4:
                message.authMode = 4;
                break;
            case "WPA2_ENTERPRISE":
            case 5:
                message.authMode = 5;
                break;
            }
            if (object.ssid != null)
                if (typeof object.ssid === "string")
                    $util.base64.decode(object.ssid, message.ssid = $util.newBuffer($util.base64.length(object.ssid)), 0);
                else if (object.ssid.length)
                    message.ssid = object.ssid;
            if (object.bssid != null)
                if (typeof object.bssid === "string")
                    $util.base64.decode(object.bssid, message.bssid = $util.newBuffer($util.base64.length(object.bssid)), 0);
                else if (object.bssid.length)
                    message.bssid = object.bssid;
            if (object.channel != null)
                message.channel = object.channel | 0;
            return message;
        };

        /**
         * Creates a plain object from a WifiConnectedState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.WifiConnectedState
         * @static
         * @param {espressif.WifiConnectedState} message WifiConnectedState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WifiConnectedState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ip4Addr = "";
                object.authMode = options.enums === String ? "Open" : 0;
                if (options.bytes === String)
                    object.ssid = "";
                else {
                    object.ssid = [];
                    if (options.bytes !== Array)
                        object.ssid = $util.newBuffer(object.ssid);
                }
                if (options.bytes === String)
                    object.bssid = "";
                else {
                    object.bssid = [];
                    if (options.bytes !== Array)
                        object.bssid = $util.newBuffer(object.bssid);
                }
                object.channel = 0;
            }
            if (message.ip4Addr != null && message.hasOwnProperty("ip4Addr"))
                object.ip4Addr = message.ip4Addr;
            if (message.authMode != null && message.hasOwnProperty("authMode"))
                object.authMode = options.enums === String ? $root.espressif.WifiAuthMode[message.authMode] : message.authMode;
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                object.ssid = options.bytes === String ? $util.base64.encode(message.ssid, 0, message.ssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.ssid) : message.ssid;
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                object.bssid = options.bytes === String ? $util.base64.encode(message.bssid, 0, message.bssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.bssid) : message.bssid;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            return object;
        };

        /**
         * Converts this WifiConnectedState to JSON.
         * @function toJSON
         * @memberof espressif.WifiConnectedState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WifiConnectedState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WifiConnectedState;
    })();

    espressif.CmdScanStart = (function() {

        /**
         * Properties of a CmdScanStart.
         * @memberof espressif
         * @interface ICmdScanStart
         * @property {boolean|null} [blocking] CmdScanStart blocking
         * @property {boolean|null} [passive] CmdScanStart passive
         * @property {number|null} [groupChannels] CmdScanStart groupChannels
         * @property {number|null} [periodMs] CmdScanStart periodMs
         */

        /**
         * Constructs a new CmdScanStart.
         * @memberof espressif
         * @classdesc Represents a CmdScanStart.
         * @implements ICmdScanStart
         * @constructor
         * @param {espressif.ICmdScanStart=} [properties] Properties to set
         */
        function CmdScanStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CmdScanStart blocking.
         * @member {boolean} blocking
         * @memberof espressif.CmdScanStart
         * @instance
         */
        CmdScanStart.prototype.blocking = false;

        /**
         * CmdScanStart passive.
         * @member {boolean} passive
         * @memberof espressif.CmdScanStart
         * @instance
         */
        CmdScanStart.prototype.passive = false;

        /**
         * CmdScanStart groupChannels.
         * @member {number} groupChannels
         * @memberof espressif.CmdScanStart
         * @instance
         */
        CmdScanStart.prototype.groupChannels = 0;

        /**
         * CmdScanStart periodMs.
         * @member {number} periodMs
         * @memberof espressif.CmdScanStart
         * @instance
         */
        CmdScanStart.prototype.periodMs = 0;

        /**
         * Creates a new CmdScanStart instance using the specified properties.
         * @function create
         * @memberof espressif.CmdScanStart
         * @static
         * @param {espressif.ICmdScanStart=} [properties] Properties to set
         * @returns {espressif.CmdScanStart} CmdScanStart instance
         */
        CmdScanStart.create = function create(properties) {
            return new CmdScanStart(properties);
        };

        /**
         * Encodes the specified CmdScanStart message. Does not implicitly {@link espressif.CmdScanStart.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdScanStart
         * @static
         * @param {espressif.CmdScanStart} message CmdScanStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blocking != null && Object.hasOwnProperty.call(message, "blocking"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.blocking);
            if (message.passive != null && Object.hasOwnProperty.call(message, "passive"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.passive);
            if (message.groupChannels != null && Object.hasOwnProperty.call(message, "groupChannels"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.groupChannels);
            if (message.periodMs != null && Object.hasOwnProperty.call(message, "periodMs"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.periodMs);
            return writer;
        };

        /**
         * Encodes the specified CmdScanStart message, length delimited. Does not implicitly {@link espressif.CmdScanStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdScanStart
         * @static
         * @param {espressif.CmdScanStart} message CmdScanStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdScanStart message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdScanStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdScanStart} CmdScanStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdScanStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.blocking = reader.bool();
                    break;
                case 2:
                    message.passive = reader.bool();
                    break;
                case 3:
                    message.groupChannels = reader.uint32();
                    break;
                case 4:
                    message.periodMs = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdScanStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdScanStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdScanStart} CmdScanStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdScanStart message.
         * @function verify
         * @memberof espressif.CmdScanStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdScanStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.blocking != null && message.hasOwnProperty("blocking"))
                if (typeof message.blocking !== "boolean")
                    return "blocking: boolean expected";
            if (message.passive != null && message.hasOwnProperty("passive"))
                if (typeof message.passive !== "boolean")
                    return "passive: boolean expected";
            if (message.groupChannels != null && message.hasOwnProperty("groupChannels"))
                if (!$util.isInteger(message.groupChannels))
                    return "groupChannels: integer expected";
            if (message.periodMs != null && message.hasOwnProperty("periodMs"))
                if (!$util.isInteger(message.periodMs))
                    return "periodMs: integer expected";
            return null;
        };

        /**
         * Creates a CmdScanStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdScanStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdScanStart} CmdScanStart
         */
        CmdScanStart.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdScanStart)
                return object;
            let message = new $root.espressif.CmdScanStart();
            if (object.blocking != null)
                message.blocking = Boolean(object.blocking);
            if (object.passive != null)
                message.passive = Boolean(object.passive);
            if (object.groupChannels != null)
                message.groupChannels = object.groupChannels >>> 0;
            if (object.periodMs != null)
                message.periodMs = object.periodMs >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a CmdScanStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdScanStart
         * @static
         * @param {espressif.CmdScanStart} message CmdScanStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdScanStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.blocking = false;
                object.passive = false;
                object.groupChannels = 0;
                object.periodMs = 0;
            }
            if (message.blocking != null && message.hasOwnProperty("blocking"))
                object.blocking = message.blocking;
            if (message.passive != null && message.hasOwnProperty("passive"))
                object.passive = message.passive;
            if (message.groupChannels != null && message.hasOwnProperty("groupChannels"))
                object.groupChannels = message.groupChannels;
            if (message.periodMs != null && message.hasOwnProperty("periodMs"))
                object.periodMs = message.periodMs;
            return object;
        };

        /**
         * Converts this CmdScanStart to JSON.
         * @function toJSON
         * @memberof espressif.CmdScanStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdScanStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdScanStart;
    })();

    espressif.RespScanStart = (function() {

        /**
         * Properties of a RespScanStart.
         * @memberof espressif
         * @interface IRespScanStart
         */

        /**
         * Constructs a new RespScanStart.
         * @memberof espressif
         * @classdesc Represents a RespScanStart.
         * @implements IRespScanStart
         * @constructor
         * @param {espressif.IRespScanStart=} [properties] Properties to set
         */
        function RespScanStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RespScanStart instance using the specified properties.
         * @function create
         * @memberof espressif.RespScanStart
         * @static
         * @param {espressif.IRespScanStart=} [properties] Properties to set
         * @returns {espressif.RespScanStart} RespScanStart instance
         */
        RespScanStart.create = function create(properties) {
            return new RespScanStart(properties);
        };

        /**
         * Encodes the specified RespScanStart message. Does not implicitly {@link espressif.RespScanStart.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespScanStart
         * @static
         * @param {espressif.RespScanStart} message RespScanStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RespScanStart message, length delimited. Does not implicitly {@link espressif.RespScanStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespScanStart
         * @static
         * @param {espressif.RespScanStart} message RespScanStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespScanStart message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespScanStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespScanStart} RespScanStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespScanStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespScanStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespScanStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespScanStart} RespScanStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespScanStart message.
         * @function verify
         * @memberof espressif.RespScanStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespScanStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RespScanStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespScanStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespScanStart} RespScanStart
         */
        RespScanStart.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespScanStart)
                return object;
            return new $root.espressif.RespScanStart();
        };

        /**
         * Creates a plain object from a RespScanStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespScanStart
         * @static
         * @param {espressif.RespScanStart} message RespScanStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespScanStart.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RespScanStart to JSON.
         * @function toJSON
         * @memberof espressif.RespScanStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespScanStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespScanStart;
    })();

    espressif.CmdScanStatus = (function() {

        /**
         * Properties of a CmdScanStatus.
         * @memberof espressif
         * @interface ICmdScanStatus
         */

        /**
         * Constructs a new CmdScanStatus.
         * @memberof espressif
         * @classdesc Represents a CmdScanStatus.
         * @implements ICmdScanStatus
         * @constructor
         * @param {espressif.ICmdScanStatus=} [properties] Properties to set
         */
        function CmdScanStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CmdScanStatus instance using the specified properties.
         * @function create
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {espressif.ICmdScanStatus=} [properties] Properties to set
         * @returns {espressif.CmdScanStatus} CmdScanStatus instance
         */
        CmdScanStatus.create = function create(properties) {
            return new CmdScanStatus(properties);
        };

        /**
         * Encodes the specified CmdScanStatus message. Does not implicitly {@link espressif.CmdScanStatus.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {espressif.CmdScanStatus} message CmdScanStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CmdScanStatus message, length delimited. Does not implicitly {@link espressif.CmdScanStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {espressif.CmdScanStatus} message CmdScanStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdScanStatus message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdScanStatus} CmdScanStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdScanStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdScanStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdScanStatus} CmdScanStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdScanStatus message.
         * @function verify
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdScanStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CmdScanStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdScanStatus} CmdScanStatus
         */
        CmdScanStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdScanStatus)
                return object;
            return new $root.espressif.CmdScanStatus();
        };

        /**
         * Creates a plain object from a CmdScanStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdScanStatus
         * @static
         * @param {espressif.CmdScanStatus} message CmdScanStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdScanStatus.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CmdScanStatus to JSON.
         * @function toJSON
         * @memberof espressif.CmdScanStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdScanStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdScanStatus;
    })();

    espressif.RespScanStatus = (function() {

        /**
         * Properties of a RespScanStatus.
         * @memberof espressif
         * @interface IRespScanStatus
         * @property {boolean|null} [scanFinished] RespScanStatus scanFinished
         * @property {number|null} [resultCount] RespScanStatus resultCount
         */

        /**
         * Constructs a new RespScanStatus.
         * @memberof espressif
         * @classdesc Represents a RespScanStatus.
         * @implements IRespScanStatus
         * @constructor
         * @param {espressif.IRespScanStatus=} [properties] Properties to set
         */
        function RespScanStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespScanStatus scanFinished.
         * @member {boolean} scanFinished
         * @memberof espressif.RespScanStatus
         * @instance
         */
        RespScanStatus.prototype.scanFinished = false;

        /**
         * RespScanStatus resultCount.
         * @member {number} resultCount
         * @memberof espressif.RespScanStatus
         * @instance
         */
        RespScanStatus.prototype.resultCount = 0;

        /**
         * Creates a new RespScanStatus instance using the specified properties.
         * @function create
         * @memberof espressif.RespScanStatus
         * @static
         * @param {espressif.IRespScanStatus=} [properties] Properties to set
         * @returns {espressif.RespScanStatus} RespScanStatus instance
         */
        RespScanStatus.create = function create(properties) {
            return new RespScanStatus(properties);
        };

        /**
         * Encodes the specified RespScanStatus message. Does not implicitly {@link espressif.RespScanStatus.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespScanStatus
         * @static
         * @param {espressif.RespScanStatus} message RespScanStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scanFinished != null && Object.hasOwnProperty.call(message, "scanFinished"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.scanFinished);
            if (message.resultCount != null && Object.hasOwnProperty.call(message, "resultCount"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.resultCount);
            return writer;
        };

        /**
         * Encodes the specified RespScanStatus message, length delimited. Does not implicitly {@link espressif.RespScanStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespScanStatus
         * @static
         * @param {espressif.RespScanStatus} message RespScanStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespScanStatus message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespScanStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespScanStatus} RespScanStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespScanStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scanFinished = reader.bool();
                    break;
                case 2:
                    message.resultCount = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespScanStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespScanStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespScanStatus} RespScanStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespScanStatus message.
         * @function verify
         * @memberof espressif.RespScanStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespScanStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scanFinished != null && message.hasOwnProperty("scanFinished"))
                if (typeof message.scanFinished !== "boolean")
                    return "scanFinished: boolean expected";
            if (message.resultCount != null && message.hasOwnProperty("resultCount"))
                if (!$util.isInteger(message.resultCount))
                    return "resultCount: integer expected";
            return null;
        };

        /**
         * Creates a RespScanStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespScanStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespScanStatus} RespScanStatus
         */
        RespScanStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespScanStatus)
                return object;
            let message = new $root.espressif.RespScanStatus();
            if (object.scanFinished != null)
                message.scanFinished = Boolean(object.scanFinished);
            if (object.resultCount != null)
                message.resultCount = object.resultCount >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RespScanStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespScanStatus
         * @static
         * @param {espressif.RespScanStatus} message RespScanStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespScanStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scanFinished = false;
                object.resultCount = 0;
            }
            if (message.scanFinished != null && message.hasOwnProperty("scanFinished"))
                object.scanFinished = message.scanFinished;
            if (message.resultCount != null && message.hasOwnProperty("resultCount"))
                object.resultCount = message.resultCount;
            return object;
        };

        /**
         * Converts this RespScanStatus to JSON.
         * @function toJSON
         * @memberof espressif.RespScanStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespScanStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespScanStatus;
    })();

    espressif.CmdScanResult = (function() {

        /**
         * Properties of a CmdScanResult.
         * @memberof espressif
         * @interface ICmdScanResult
         * @property {number|null} [startIndex] CmdScanResult startIndex
         * @property {number|null} [count] CmdScanResult count
         */

        /**
         * Constructs a new CmdScanResult.
         * @memberof espressif
         * @classdesc Represents a CmdScanResult.
         * @implements ICmdScanResult
         * @constructor
         * @param {espressif.ICmdScanResult=} [properties] Properties to set
         */
        function CmdScanResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CmdScanResult startIndex.
         * @member {number} startIndex
         * @memberof espressif.CmdScanResult
         * @instance
         */
        CmdScanResult.prototype.startIndex = 0;

        /**
         * CmdScanResult count.
         * @member {number} count
         * @memberof espressif.CmdScanResult
         * @instance
         */
        CmdScanResult.prototype.count = 0;

        /**
         * Creates a new CmdScanResult instance using the specified properties.
         * @function create
         * @memberof espressif.CmdScanResult
         * @static
         * @param {espressif.ICmdScanResult=} [properties] Properties to set
         * @returns {espressif.CmdScanResult} CmdScanResult instance
         */
        CmdScanResult.create = function create(properties) {
            return new CmdScanResult(properties);
        };

        /**
         * Encodes the specified CmdScanResult message. Does not implicitly {@link espressif.CmdScanResult.verify|verify} messages.
         * @function encode
         * @memberof espressif.CmdScanResult
         * @static
         * @param {espressif.CmdScanResult} message CmdScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startIndex != null && Object.hasOwnProperty.call(message, "startIndex"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.startIndex);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.count);
            return writer;
        };

        /**
         * Encodes the specified CmdScanResult message, length delimited. Does not implicitly {@link espressif.CmdScanResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.CmdScanResult
         * @static
         * @param {espressif.CmdScanResult} message CmdScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CmdScanResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CmdScanResult message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.CmdScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.CmdScanResult} CmdScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.CmdScanResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.startIndex = reader.uint32();
                    break;
                case 2:
                    message.count = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CmdScanResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.CmdScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.CmdScanResult} CmdScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CmdScanResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CmdScanResult message.
         * @function verify
         * @memberof espressif.CmdScanResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CmdScanResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startIndex != null && message.hasOwnProperty("startIndex"))
                if (!$util.isInteger(message.startIndex))
                    return "startIndex: integer expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            return null;
        };

        /**
         * Creates a CmdScanResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.CmdScanResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.CmdScanResult} CmdScanResult
         */
        CmdScanResult.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.CmdScanResult)
                return object;
            let message = new $root.espressif.CmdScanResult();
            if (object.startIndex != null)
                message.startIndex = object.startIndex >>> 0;
            if (object.count != null)
                message.count = object.count >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a CmdScanResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.CmdScanResult
         * @static
         * @param {espressif.CmdScanResult} message CmdScanResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CmdScanResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.startIndex = 0;
                object.count = 0;
            }
            if (message.startIndex != null && message.hasOwnProperty("startIndex"))
                object.startIndex = message.startIndex;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            return object;
        };

        /**
         * Converts this CmdScanResult to JSON.
         * @function toJSON
         * @memberof espressif.CmdScanResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CmdScanResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CmdScanResult;
    })();

    espressif.WiFiScanResult = (function() {

        /**
         * Properties of a WiFiScanResult.
         * @memberof espressif
         * @interface IWiFiScanResult
         * @property {Uint8Array|null} [ssid] WiFiScanResult ssid
         * @property {number|null} [channel] WiFiScanResult channel
         * @property {number|null} [rssi] WiFiScanResult rssi
         * @property {Uint8Array|null} [bssid] WiFiScanResult bssid
         * @property {espressif.WifiAuthMode|null} [auth] WiFiScanResult auth
         */

        /**
         * Constructs a new WiFiScanResult.
         * @memberof espressif
         * @classdesc Represents a WiFiScanResult.
         * @implements IWiFiScanResult
         * @constructor
         * @param {espressif.IWiFiScanResult=} [properties] Properties to set
         */
        function WiFiScanResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WiFiScanResult ssid.
         * @member {Uint8Array} ssid
         * @memberof espressif.WiFiScanResult
         * @instance
         */
        WiFiScanResult.prototype.ssid = $util.newBuffer([]);

        /**
         * WiFiScanResult channel.
         * @member {number} channel
         * @memberof espressif.WiFiScanResult
         * @instance
         */
        WiFiScanResult.prototype.channel = 0;

        /**
         * WiFiScanResult rssi.
         * @member {number} rssi
         * @memberof espressif.WiFiScanResult
         * @instance
         */
        WiFiScanResult.prototype.rssi = 0;

        /**
         * WiFiScanResult bssid.
         * @member {Uint8Array} bssid
         * @memberof espressif.WiFiScanResult
         * @instance
         */
        WiFiScanResult.prototype.bssid = $util.newBuffer([]);

        /**
         * WiFiScanResult auth.
         * @member {espressif.WifiAuthMode} auth
         * @memberof espressif.WiFiScanResult
         * @instance
         */
        WiFiScanResult.prototype.auth = 0;

        /**
         * Creates a new WiFiScanResult instance using the specified properties.
         * @function create
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {espressif.IWiFiScanResult=} [properties] Properties to set
         * @returns {espressif.WiFiScanResult} WiFiScanResult instance
         */
        WiFiScanResult.create = function create(properties) {
            return new WiFiScanResult(properties);
        };

        /**
         * Encodes the specified WiFiScanResult message. Does not implicitly {@link espressif.WiFiScanResult.verify|verify} messages.
         * @function encode
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {espressif.WiFiScanResult} message WiFiScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiScanResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ssid != null && Object.hasOwnProperty.call(message, "ssid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.ssid);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.channel);
            if (message.rssi != null && Object.hasOwnProperty.call(message, "rssi"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rssi);
            if (message.bssid != null && Object.hasOwnProperty.call(message, "bssid"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.bssid);
            if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.auth);
            return writer;
        };

        /**
         * Encodes the specified WiFiScanResult message, length delimited. Does not implicitly {@link espressif.WiFiScanResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {espressif.WiFiScanResult} message WiFiScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiScanResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WiFiScanResult message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.WiFiScanResult} WiFiScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiScanResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.WiFiScanResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ssid = reader.bytes();
                    break;
                case 2:
                    message.channel = reader.uint32();
                    break;
                case 3:
                    message.rssi = reader.int32();
                    break;
                case 4:
                    message.bssid = reader.bytes();
                    break;
                case 5:
                    message.auth = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WiFiScanResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.WiFiScanResult} WiFiScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiScanResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WiFiScanResult message.
         * @function verify
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WiFiScanResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                if (!(message.ssid && typeof message.ssid.length === "number" || $util.isString(message.ssid)))
                    return "ssid: buffer expected";
            if (message.channel != null && message.hasOwnProperty("channel"))
                if (!$util.isInteger(message.channel))
                    return "channel: integer expected";
            if (message.rssi != null && message.hasOwnProperty("rssi"))
                if (!$util.isInteger(message.rssi))
                    return "rssi: integer expected";
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                if (!(message.bssid && typeof message.bssid.length === "number" || $util.isString(message.bssid)))
                    return "bssid: buffer expected";
            if (message.auth != null && message.hasOwnProperty("auth"))
                switch (message.auth) {
                default:
                    return "auth: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a WiFiScanResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.WiFiScanResult} WiFiScanResult
         */
        WiFiScanResult.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.WiFiScanResult)
                return object;
            let message = new $root.espressif.WiFiScanResult();
            if (object.ssid != null)
                if (typeof object.ssid === "string")
                    $util.base64.decode(object.ssid, message.ssid = $util.newBuffer($util.base64.length(object.ssid)), 0);
                else if (object.ssid.length)
                    message.ssid = object.ssid;
            if (object.channel != null)
                message.channel = object.channel >>> 0;
            if (object.rssi != null)
                message.rssi = object.rssi | 0;
            if (object.bssid != null)
                if (typeof object.bssid === "string")
                    $util.base64.decode(object.bssid, message.bssid = $util.newBuffer($util.base64.length(object.bssid)), 0);
                else if (object.bssid.length)
                    message.bssid = object.bssid;
            switch (object.auth) {
            case "Open":
            case 0:
                message.auth = 0;
                break;
            case "WEP":
            case 1:
                message.auth = 1;
                break;
            case "WPA_PSK":
            case 2:
                message.auth = 2;
                break;
            case "WPA2_PSK":
            case 3:
                message.auth = 3;
                break;
            case "WPA_WPA2_PSK":
            case 4:
                message.auth = 4;
                break;
            case "WPA2_ENTERPRISE":
            case 5:
                message.auth = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a WiFiScanResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.WiFiScanResult
         * @static
         * @param {espressif.WiFiScanResult} message WiFiScanResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WiFiScanResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.ssid = "";
                else {
                    object.ssid = [];
                    if (options.bytes !== Array)
                        object.ssid = $util.newBuffer(object.ssid);
                }
                object.channel = 0;
                object.rssi = 0;
                if (options.bytes === String)
                    object.bssid = "";
                else {
                    object.bssid = [];
                    if (options.bytes !== Array)
                        object.bssid = $util.newBuffer(object.bssid);
                }
                object.auth = options.enums === String ? "Open" : 0;
            }
            if (message.ssid != null && message.hasOwnProperty("ssid"))
                object.ssid = options.bytes === String ? $util.base64.encode(message.ssid, 0, message.ssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.ssid) : message.ssid;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            if (message.rssi != null && message.hasOwnProperty("rssi"))
                object.rssi = message.rssi;
            if (message.bssid != null && message.hasOwnProperty("bssid"))
                object.bssid = options.bytes === String ? $util.base64.encode(message.bssid, 0, message.bssid.length) : options.bytes === Array ? Array.prototype.slice.call(message.bssid) : message.bssid;
            if (message.auth != null && message.hasOwnProperty("auth"))
                object.auth = options.enums === String ? $root.espressif.WifiAuthMode[message.auth] : message.auth;
            return object;
        };

        /**
         * Converts this WiFiScanResult to JSON.
         * @function toJSON
         * @memberof espressif.WiFiScanResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WiFiScanResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WiFiScanResult;
    })();

    espressif.RespScanResult = (function() {

        /**
         * Properties of a RespScanResult.
         * @memberof espressif
         * @interface IRespScanResult
         * @property {Array.<espressif.WiFiScanResult>|null} [entries] RespScanResult entries
         */

        /**
         * Constructs a new RespScanResult.
         * @memberof espressif
         * @classdesc Represents a RespScanResult.
         * @implements IRespScanResult
         * @constructor
         * @param {espressif.IRespScanResult=} [properties] Properties to set
         */
        function RespScanResult(properties) {
            this.entries = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RespScanResult entries.
         * @member {Array.<espressif.WiFiScanResult>} entries
         * @memberof espressif.RespScanResult
         * @instance
         */
        RespScanResult.prototype.entries = $util.emptyArray;

        /**
         * Creates a new RespScanResult instance using the specified properties.
         * @function create
         * @memberof espressif.RespScanResult
         * @static
         * @param {espressif.IRespScanResult=} [properties] Properties to set
         * @returns {espressif.RespScanResult} RespScanResult instance
         */
        RespScanResult.create = function create(properties) {
            return new RespScanResult(properties);
        };

        /**
         * Encodes the specified RespScanResult message. Does not implicitly {@link espressif.RespScanResult.verify|verify} messages.
         * @function encode
         * @memberof espressif.RespScanResult
         * @static
         * @param {espressif.RespScanResult} message RespScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entries != null && message.entries.length)
                for (let i = 0; i < message.entries.length; ++i)
                    $root.espressif.WiFiScanResult.encode(message.entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RespScanResult message, length delimited. Does not implicitly {@link espressif.RespScanResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.RespScanResult
         * @static
         * @param {espressif.RespScanResult} message RespScanResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RespScanResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RespScanResult message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.RespScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.RespScanResult} RespScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.RespScanResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.entries && message.entries.length))
                        message.entries = [];
                    message.entries.push($root.espressif.WiFiScanResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RespScanResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.RespScanResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.RespScanResult} RespScanResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RespScanResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RespScanResult message.
         * @function verify
         * @memberof espressif.RespScanResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RespScanResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entries != null && message.hasOwnProperty("entries")) {
                if (!Array.isArray(message.entries))
                    return "entries: array expected";
                for (let i = 0; i < message.entries.length; ++i) {
                    let error = $root.espressif.WiFiScanResult.verify(message.entries[i]);
                    if (error)
                        return "entries." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RespScanResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.RespScanResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.RespScanResult} RespScanResult
         */
        RespScanResult.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.RespScanResult)
                return object;
            let message = new $root.espressif.RespScanResult();
            if (object.entries) {
                if (!Array.isArray(object.entries))
                    throw TypeError(".espressif.RespScanResult.entries: array expected");
                message.entries = [];
                for (let i = 0; i < object.entries.length; ++i) {
                    if (typeof object.entries[i] !== "object")
                        throw TypeError(".espressif.RespScanResult.entries: object expected");
                    message.entries[i] = $root.espressif.WiFiScanResult.fromObject(object.entries[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RespScanResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.RespScanResult
         * @static
         * @param {espressif.RespScanResult} message RespScanResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RespScanResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.entries = [];
            if (message.entries && message.entries.length) {
                object.entries = [];
                for (let j = 0; j < message.entries.length; ++j)
                    object.entries[j] = $root.espressif.WiFiScanResult.toObject(message.entries[j], options);
            }
            return object;
        };

        /**
         * Converts this RespScanResult to JSON.
         * @function toJSON
         * @memberof espressif.RespScanResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RespScanResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RespScanResult;
    })();

    /**
     * WiFiScanMsgType enum.
     * @name espressif.WiFiScanMsgType
     * @enum {number}
     * @property {number} TypeCmdScanStart=0 TypeCmdScanStart value
     * @property {number} TypeRespScanStart=1 TypeRespScanStart value
     * @property {number} TypeCmdScanStatus=2 TypeCmdScanStatus value
     * @property {number} TypeRespScanStatus=3 TypeRespScanStatus value
     * @property {number} TypeCmdScanResult=4 TypeCmdScanResult value
     * @property {number} TypeRespScanResult=5 TypeRespScanResult value
     */
    espressif.WiFiScanMsgType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TypeCmdScanStart"] = 0;
        values[valuesById[1] = "TypeRespScanStart"] = 1;
        values[valuesById[2] = "TypeCmdScanStatus"] = 2;
        values[valuesById[3] = "TypeRespScanStatus"] = 3;
        values[valuesById[4] = "TypeCmdScanResult"] = 4;
        values[valuesById[5] = "TypeRespScanResult"] = 5;
        return values;
    })();

    espressif.WiFiScanPayload = (function() {

        /**
         * Properties of a WiFiScanPayload.
         * @memberof espressif
         * @interface IWiFiScanPayload
         * @property {espressif.WiFiScanMsgType|null} [msg] WiFiScanPayload msg
         * @property {espressif.Status|null} [status] WiFiScanPayload status
         * @property {espressif.CmdScanStart|null} [cmdScanStart] WiFiScanPayload cmdScanStart
         * @property {espressif.RespScanStart|null} [respScanStart] WiFiScanPayload respScanStart
         * @property {espressif.CmdScanStatus|null} [cmdScanStatus] WiFiScanPayload cmdScanStatus
         * @property {espressif.RespScanStatus|null} [respScanStatus] WiFiScanPayload respScanStatus
         * @property {espressif.CmdScanResult|null} [cmdScanResult] WiFiScanPayload cmdScanResult
         * @property {espressif.RespScanResult|null} [respScanResult] WiFiScanPayload respScanResult
         */

        /**
         * Constructs a new WiFiScanPayload.
         * @memberof espressif
         * @classdesc Represents a WiFiScanPayload.
         * @implements IWiFiScanPayload
         * @constructor
         * @param {espressif.IWiFiScanPayload=} [properties] Properties to set
         */
        function WiFiScanPayload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WiFiScanPayload msg.
         * @member {espressif.WiFiScanMsgType} msg
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.msg = 0;

        /**
         * WiFiScanPayload status.
         * @member {espressif.Status} status
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.status = 0;

        /**
         * WiFiScanPayload cmdScanStart.
         * @member {espressif.CmdScanStart|null|undefined} cmdScanStart
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.cmdScanStart = null;

        /**
         * WiFiScanPayload respScanStart.
         * @member {espressif.RespScanStart|null|undefined} respScanStart
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.respScanStart = null;

        /**
         * WiFiScanPayload cmdScanStatus.
         * @member {espressif.CmdScanStatus|null|undefined} cmdScanStatus
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.cmdScanStatus = null;

        /**
         * WiFiScanPayload respScanStatus.
         * @member {espressif.RespScanStatus|null|undefined} respScanStatus
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.respScanStatus = null;

        /**
         * WiFiScanPayload cmdScanResult.
         * @member {espressif.CmdScanResult|null|undefined} cmdScanResult
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.cmdScanResult = null;

        /**
         * WiFiScanPayload respScanResult.
         * @member {espressif.RespScanResult|null|undefined} respScanResult
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        WiFiScanPayload.prototype.respScanResult = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * WiFiScanPayload payload.
         * @member {"cmdScanStart"|"respScanStart"|"cmdScanStatus"|"respScanStatus"|"cmdScanResult"|"respScanResult"|undefined} payload
         * @memberof espressif.WiFiScanPayload
         * @instance
         */
        Object.defineProperty(WiFiScanPayload.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["cmdScanStart", "respScanStart", "cmdScanStatus", "respScanStatus", "cmdScanResult", "respScanResult"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WiFiScanPayload instance using the specified properties.
         * @function create
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {espressif.IWiFiScanPayload=} [properties] Properties to set
         * @returns {espressif.WiFiScanPayload} WiFiScanPayload instance
         */
        WiFiScanPayload.create = function create(properties) {
            return new WiFiScanPayload(properties);
        };

        /**
         * Encodes the specified WiFiScanPayload message. Does not implicitly {@link espressif.WiFiScanPayload.verify|verify} messages.
         * @function encode
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {espressif.WiFiScanPayload} message WiFiScanPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiScanPayload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.cmdScanStart != null && Object.hasOwnProperty.call(message, "cmdScanStart"))
                $root.espressif.CmdScanStart.encode(message.cmdScanStart, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.respScanStart != null && Object.hasOwnProperty.call(message, "respScanStart"))
                $root.espressif.RespScanStart.encode(message.respScanStart, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.cmdScanStatus != null && Object.hasOwnProperty.call(message, "cmdScanStatus"))
                $root.espressif.CmdScanStatus.encode(message.cmdScanStatus, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.respScanStatus != null && Object.hasOwnProperty.call(message, "respScanStatus"))
                $root.espressif.RespScanStatus.encode(message.respScanStatus, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.cmdScanResult != null && Object.hasOwnProperty.call(message, "cmdScanResult"))
                $root.espressif.CmdScanResult.encode(message.cmdScanResult, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.respScanResult != null && Object.hasOwnProperty.call(message, "respScanResult"))
                $root.espressif.RespScanResult.encode(message.respScanResult, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WiFiScanPayload message, length delimited. Does not implicitly {@link espressif.WiFiScanPayload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {espressif.WiFiScanPayload} message WiFiScanPayload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WiFiScanPayload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WiFiScanPayload message from the specified reader or buffer.
         * @function decode
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {espressif.WiFiScanPayload} WiFiScanPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiScanPayload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.espressif.WiFiScanPayload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.msg = reader.int32();
                    break;
                case 2:
                    message.status = reader.int32();
                    break;
                case 10:
                    message.cmdScanStart = $root.espressif.CmdScanStart.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.respScanStart = $root.espressif.RespScanStart.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.cmdScanStatus = $root.espressif.CmdScanStatus.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.respScanStatus = $root.espressif.RespScanStatus.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.cmdScanResult = $root.espressif.CmdScanResult.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.respScanResult = $root.espressif.RespScanResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WiFiScanPayload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {espressif.WiFiScanPayload} WiFiScanPayload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WiFiScanPayload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WiFiScanPayload message.
         * @function verify
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WiFiScanPayload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.msg != null && message.hasOwnProperty("msg"))
                switch (message.msg) {
                default:
                    return "msg: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.cmdScanStart != null && message.hasOwnProperty("cmdScanStart")) {
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdScanStart.verify(message.cmdScanStart);
                    if (error)
                        return "cmdScanStart." + error;
                }
            }
            if (message.respScanStart != null && message.hasOwnProperty("respScanStart")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespScanStart.verify(message.respScanStart);
                    if (error)
                        return "respScanStart." + error;
                }
            }
            if (message.cmdScanStatus != null && message.hasOwnProperty("cmdScanStatus")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdScanStatus.verify(message.cmdScanStatus);
                    if (error)
                        return "cmdScanStatus." + error;
                }
            }
            if (message.respScanStatus != null && message.hasOwnProperty("respScanStatus")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespScanStatus.verify(message.respScanStatus);
                    if (error)
                        return "respScanStatus." + error;
                }
            }
            if (message.cmdScanResult != null && message.hasOwnProperty("cmdScanResult")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.CmdScanResult.verify(message.cmdScanResult);
                    if (error)
                        return "cmdScanResult." + error;
                }
            }
            if (message.respScanResult != null && message.hasOwnProperty("respScanResult")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.espressif.RespScanResult.verify(message.respScanResult);
                    if (error)
                        return "respScanResult." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WiFiScanPayload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {espressif.WiFiScanPayload} WiFiScanPayload
         */
        WiFiScanPayload.fromObject = function fromObject(object) {
            if (object instanceof $root.espressif.WiFiScanPayload)
                return object;
            let message = new $root.espressif.WiFiScanPayload();
            switch (object.msg) {
            case "TypeCmdScanStart":
            case 0:
                message.msg = 0;
                break;
            case "TypeRespScanStart":
            case 1:
                message.msg = 1;
                break;
            case "TypeCmdScanStatus":
            case 2:
                message.msg = 2;
                break;
            case "TypeRespScanStatus":
            case 3:
                message.msg = 3;
                break;
            case "TypeCmdScanResult":
            case 4:
                message.msg = 4;
                break;
            case "TypeRespScanResult":
            case 5:
                message.msg = 5;
                break;
            }
            switch (object.status) {
            case "Success":
            case 0:
                message.status = 0;
                break;
            case "InvalidSecScheme":
            case 1:
                message.status = 1;
                break;
            case "InvalidProto":
            case 2:
                message.status = 2;
                break;
            case "TooManySessions":
            case 3:
                message.status = 3;
                break;
            case "InvalidArgument":
            case 4:
                message.status = 4;
                break;
            case "InternalError":
            case 5:
                message.status = 5;
                break;
            case "CryptoError":
            case 6:
                message.status = 6;
                break;
            case "InvalidSession":
            case 7:
                message.status = 7;
                break;
            }
            if (object.cmdScanStart != null) {
                if (typeof object.cmdScanStart !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.cmdScanStart: object expected");
                message.cmdScanStart = $root.espressif.CmdScanStart.fromObject(object.cmdScanStart);
            }
            if (object.respScanStart != null) {
                if (typeof object.respScanStart !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.respScanStart: object expected");
                message.respScanStart = $root.espressif.RespScanStart.fromObject(object.respScanStart);
            }
            if (object.cmdScanStatus != null) {
                if (typeof object.cmdScanStatus !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.cmdScanStatus: object expected");
                message.cmdScanStatus = $root.espressif.CmdScanStatus.fromObject(object.cmdScanStatus);
            }
            if (object.respScanStatus != null) {
                if (typeof object.respScanStatus !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.respScanStatus: object expected");
                message.respScanStatus = $root.espressif.RespScanStatus.fromObject(object.respScanStatus);
            }
            if (object.cmdScanResult != null) {
                if (typeof object.cmdScanResult !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.cmdScanResult: object expected");
                message.cmdScanResult = $root.espressif.CmdScanResult.fromObject(object.cmdScanResult);
            }
            if (object.respScanResult != null) {
                if (typeof object.respScanResult !== "object")
                    throw TypeError(".espressif.WiFiScanPayload.respScanResult: object expected");
                message.respScanResult = $root.espressif.RespScanResult.fromObject(object.respScanResult);
            }
            return message;
        };

        /**
         * Creates a plain object from a WiFiScanPayload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof espressif.WiFiScanPayload
         * @static
         * @param {espressif.WiFiScanPayload} message WiFiScanPayload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WiFiScanPayload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.msg = options.enums === String ? "TypeCmdScanStart" : 0;
                object.status = options.enums === String ? "Success" : 0;
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.enums === String ? $root.espressif.WiFiScanMsgType[message.msg] : message.msg;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.espressif.Status[message.status] : message.status;
            if (message.cmdScanStart != null && message.hasOwnProperty("cmdScanStart")) {
                object.cmdScanStart = $root.espressif.CmdScanStart.toObject(message.cmdScanStart, options);
                if (options.oneofs)
                    object.payload = "cmdScanStart";
            }
            if (message.respScanStart != null && message.hasOwnProperty("respScanStart")) {
                object.respScanStart = $root.espressif.RespScanStart.toObject(message.respScanStart, options);
                if (options.oneofs)
                    object.payload = "respScanStart";
            }
            if (message.cmdScanStatus != null && message.hasOwnProperty("cmdScanStatus")) {
                object.cmdScanStatus = $root.espressif.CmdScanStatus.toObject(message.cmdScanStatus, options);
                if (options.oneofs)
                    object.payload = "cmdScanStatus";
            }
            if (message.respScanStatus != null && message.hasOwnProperty("respScanStatus")) {
                object.respScanStatus = $root.espressif.RespScanStatus.toObject(message.respScanStatus, options);
                if (options.oneofs)
                    object.payload = "respScanStatus";
            }
            if (message.cmdScanResult != null && message.hasOwnProperty("cmdScanResult")) {
                object.cmdScanResult = $root.espressif.CmdScanResult.toObject(message.cmdScanResult, options);
                if (options.oneofs)
                    object.payload = "cmdScanResult";
            }
            if (message.respScanResult != null && message.hasOwnProperty("respScanResult")) {
                object.respScanResult = $root.espressif.RespScanResult.toObject(message.respScanResult, options);
                if (options.oneofs)
                    object.payload = "respScanResult";
            }
            return object;
        };

        /**
         * Converts this WiFiScanPayload to JSON.
         * @function toJSON
         * @memberof espressif.WiFiScanPayload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WiFiScanPayload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WiFiScanPayload;
    })();

    return espressif;
})();

export const hdyRpc = $root.hdyRpc = (() => {

    /**
     * Namespace hdyRpc.
     * @exports hdyRpc
     * @namespace
     */
    const hdyRpc = {};

    /**
     * ResultCodes enum.
     * @name hdyRpc.ResultCodes
     * @enum {number}
     * @property {number} RESULT_UNKNOWN=0 RESULT_UNKNOWN value
     * @property {number} RESULT_OK=1 RESULT_OK value
     * @property {number} RESULT_OK_ALREADY_SET=2 RESULT_OK_ALREADY_SET value
     * @property {number} RESULT_ERR_GENERIC=-1 RESULT_ERR_GENERIC value
     * @property {number} RESULT_ERR_NO_MEMORY=-2 RESULT_ERR_NO_MEMORY value
     * @property {number} RESULT_ERR_INVALID_ARG=-3 RESULT_ERR_INVALID_ARG value
     * @property {number} RESULT_ERR_INVALID_STATE=-4 RESULT_ERR_INVALID_STATE value
     * @property {number} RESULT_ERR_INVALID_SIZE=-5 RESULT_ERR_INVALID_SIZE value
     * @property {number} RESULT_ERR_NOT_FOUND=-6 RESULT_ERR_NOT_FOUND value
     * @property {number} RESULT_ERR_NOT_SUPPORTED=-7 RESULT_ERR_NOT_SUPPORTED value
     * @property {number} RESULT_ERR_TIMEOUT=-8 RESULT_ERR_TIMEOUT value
     * @property {number} RESULT_ERR_INVALID_RESPONSE=-9 RESULT_ERR_INVALID_RESPONSE value
     */
    hdyRpc.ResultCodes = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RESULT_UNKNOWN"] = 0;
        values[valuesById[1] = "RESULT_OK"] = 1;
        values[valuesById[2] = "RESULT_OK_ALREADY_SET"] = 2;
        values[valuesById[-1] = "RESULT_ERR_GENERIC"] = -1;
        values[valuesById[-2] = "RESULT_ERR_NO_MEMORY"] = -2;
        values[valuesById[-3] = "RESULT_ERR_INVALID_ARG"] = -3;
        values[valuesById[-4] = "RESULT_ERR_INVALID_STATE"] = -4;
        values[valuesById[-5] = "RESULT_ERR_INVALID_SIZE"] = -5;
        values[valuesById[-6] = "RESULT_ERR_NOT_FOUND"] = -6;
        values[valuesById[-7] = "RESULT_ERR_NOT_SUPPORTED"] = -7;
        values[valuesById[-8] = "RESULT_ERR_TIMEOUT"] = -8;
        values[valuesById[-9] = "RESULT_ERR_INVALID_RESPONSE"] = -9;
        return values;
    })();

    /**
     * Modes enum.
     * @name hdyRpc.Modes
     * @enum {number}
     * @property {number} MODE_HAMP=0 MODE_HAMP value
     * @property {number} MODE_HDSP=1 MODE_HDSP value
     * @property {number} MODE_HSSP=2 MODE_HSSP value
     * @property {number} MODE_MAINTENANCE=3 MODE_MAINTENANCE value
     */
    hdyRpc.Modes = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MODE_HAMP"] = 0;
        values[valuesById[1] = "MODE_HDSP"] = 1;
        values[valuesById[2] = "MODE_HSSP"] = 2;
        values[valuesById[3] = "MODE_MAINTENANCE"] = 3;
        return values;
    })();

    /**
     * HampStates enum.
     * @name hdyRpc.HampStates
     * @enum {number}
     * @property {number} HAMP_STATE_STOPPED=0 HAMP_STATE_STOPPED value
     * @property {number} HAMP_STATE_RUNNING=1 HAMP_STATE_RUNNING value
     */
    hdyRpc.HampStates = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "HAMP_STATE_STOPPED"] = 0;
        values[valuesById[1] = "HAMP_STATE_RUNNING"] = 1;
        return values;
    })();

    hdyRpc.RequestCmdGetServerTime = (function() {

        /**
         * Properties of a RequestCmdGetServerTime.
         * @memberof hdyRpc
         * @interface IRequestCmdGetServerTime
         */

        /**
         * Constructs a new RequestCmdGetServerTime.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetServerTime.
         * @implements IRequestCmdGetServerTime
         * @constructor
         * @param {hdyRpc.IRequestCmdGetServerTime=} [properties] Properties to set
         */
        function RequestCmdGetServerTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetServerTime instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {hdyRpc.IRequestCmdGetServerTime=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetServerTime} RequestCmdGetServerTime instance
         */
        RequestCmdGetServerTime.create = function create(properties) {
            return new RequestCmdGetServerTime(properties);
        };

        /**
         * Encodes the specified RequestCmdGetServerTime message. Does not implicitly {@link hdyRpc.RequestCmdGetServerTime.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {hdyRpc.RequestCmdGetServerTime} message RequestCmdGetServerTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetServerTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetServerTime message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetServerTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {hdyRpc.RequestCmdGetServerTime} message RequestCmdGetServerTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetServerTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetServerTime message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetServerTime} RequestCmdGetServerTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetServerTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetServerTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetServerTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetServerTime} RequestCmdGetServerTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetServerTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetServerTime message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetServerTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetServerTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetServerTime} RequestCmdGetServerTime
         */
        RequestCmdGetServerTime.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetServerTime)
                return object;
            return new $root.hdyRpc.RequestCmdGetServerTime();
        };

        /**
         * Creates a plain object from a RequestCmdGetServerTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @static
         * @param {hdyRpc.RequestCmdGetServerTime} message RequestCmdGetServerTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetServerTime.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetServerTime to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetServerTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetServerTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetServerTime;
    })();

    hdyRpc.ResponseCmdGetServerTime = (function() {

        /**
         * Properties of a ResponseCmdGetServerTime.
         * @memberof hdyRpc
         * @interface IResponseCmdGetServerTime
         * @property {number|Long|null} [time] ResponseCmdGetServerTime time
         */

        /**
         * Constructs a new ResponseCmdGetServerTime.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetServerTime.
         * @implements IResponseCmdGetServerTime
         * @constructor
         * @param {hdyRpc.IResponseCmdGetServerTime=} [properties] Properties to set
         */
        function ResponseCmdGetServerTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetServerTime time.
         * @member {number|Long} time
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @instance
         */
        ResponseCmdGetServerTime.prototype.time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ResponseCmdGetServerTime instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {hdyRpc.IResponseCmdGetServerTime=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetServerTime} ResponseCmdGetServerTime instance
         */
        ResponseCmdGetServerTime.create = function create(properties) {
            return new ResponseCmdGetServerTime(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetServerTime message. Does not implicitly {@link hdyRpc.ResponseCmdGetServerTime.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {hdyRpc.ResponseCmdGetServerTime} message ResponseCmdGetServerTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetServerTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.time);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetServerTime message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetServerTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {hdyRpc.ResponseCmdGetServerTime} message ResponseCmdGetServerTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetServerTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetServerTime message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetServerTime} ResponseCmdGetServerTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetServerTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetServerTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.time = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetServerTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetServerTime} ResponseCmdGetServerTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetServerTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetServerTime message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetServerTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGetServerTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetServerTime} ResponseCmdGetServerTime
         */
        ResponseCmdGetServerTime.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetServerTime)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetServerTime();
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = true;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetServerTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @static
         * @param {hdyRpc.ResponseCmdGetServerTime} message ResponseCmdGetServerTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetServerTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true) : message.time;
            return object;
        };

        /**
         * Converts this ResponseCmdGetServerTime to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetServerTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetServerTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetServerTime;
    })();

    hdyRpc.RequestCmdWsHandshake = (function() {

        /**
         * Properties of a RequestCmdWsHandshake.
         * @memberof hdyRpc
         * @interface IRequestCmdWsHandshake
         * @property {string|null} [test] RequestCmdWsHandshake test
         */

        /**
         * Constructs a new RequestCmdWsHandshake.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdWsHandshake.
         * @implements IRequestCmdWsHandshake
         * @constructor
         * @param {hdyRpc.IRequestCmdWsHandshake=} [properties] Properties to set
         */
        function RequestCmdWsHandshake(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdWsHandshake test.
         * @member {string} test
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @instance
         */
        RequestCmdWsHandshake.prototype.test = "";

        /**
         * Creates a new RequestCmdWsHandshake instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {hdyRpc.IRequestCmdWsHandshake=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdWsHandshake} RequestCmdWsHandshake instance
         */
        RequestCmdWsHandshake.create = function create(properties) {
            return new RequestCmdWsHandshake(properties);
        };

        /**
         * Encodes the specified RequestCmdWsHandshake message. Does not implicitly {@link hdyRpc.RequestCmdWsHandshake.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {hdyRpc.RequestCmdWsHandshake} message RequestCmdWsHandshake message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdWsHandshake.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.test != null && Object.hasOwnProperty.call(message, "test"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.test);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdWsHandshake message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdWsHandshake.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {hdyRpc.RequestCmdWsHandshake} message RequestCmdWsHandshake message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdWsHandshake.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdWsHandshake message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdWsHandshake} RequestCmdWsHandshake
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdWsHandshake.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdWsHandshake();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.test = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdWsHandshake message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdWsHandshake} RequestCmdWsHandshake
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdWsHandshake.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdWsHandshake message.
         * @function verify
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdWsHandshake.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.test != null && message.hasOwnProperty("test"))
                if (!$util.isString(message.test))
                    return "test: string expected";
            return null;
        };

        /**
         * Creates a RequestCmdWsHandshake message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdWsHandshake} RequestCmdWsHandshake
         */
        RequestCmdWsHandshake.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdWsHandshake)
                return object;
            let message = new $root.hdyRpc.RequestCmdWsHandshake();
            if (object.test != null)
                message.test = String(object.test);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdWsHandshake message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @static
         * @param {hdyRpc.RequestCmdWsHandshake} message RequestCmdWsHandshake
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdWsHandshake.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.test = "";
            if (message.test != null && message.hasOwnProperty("test"))
                object.test = message.test;
            return object;
        };

        /**
         * Converts this RequestCmdWsHandshake to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdWsHandshake
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdWsHandshake.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdWsHandshake;
    })();

    hdyRpc.ResponseCmdWsHandshake = (function() {

        /**
         * Properties of a ResponseCmdWsHandshake.
         * @memberof hdyRpc
         * @interface IResponseCmdWsHandshake
         * @property {string|null} [testResult] ResponseCmdWsHandshake testResult
         */

        /**
         * Constructs a new ResponseCmdWsHandshake.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdWsHandshake.
         * @implements IResponseCmdWsHandshake
         * @constructor
         * @param {hdyRpc.IResponseCmdWsHandshake=} [properties] Properties to set
         */
        function ResponseCmdWsHandshake(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdWsHandshake testResult.
         * @member {string} testResult
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @instance
         */
        ResponseCmdWsHandshake.prototype.testResult = "";

        /**
         * Creates a new ResponseCmdWsHandshake instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {hdyRpc.IResponseCmdWsHandshake=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdWsHandshake} ResponseCmdWsHandshake instance
         */
        ResponseCmdWsHandshake.create = function create(properties) {
            return new ResponseCmdWsHandshake(properties);
        };

        /**
         * Encodes the specified ResponseCmdWsHandshake message. Does not implicitly {@link hdyRpc.ResponseCmdWsHandshake.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {hdyRpc.ResponseCmdWsHandshake} message ResponseCmdWsHandshake message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdWsHandshake.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.testResult != null && Object.hasOwnProperty.call(message, "testResult"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.testResult);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdWsHandshake message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdWsHandshake.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {hdyRpc.ResponseCmdWsHandshake} message ResponseCmdWsHandshake message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdWsHandshake.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdWsHandshake message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdWsHandshake} ResponseCmdWsHandshake
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdWsHandshake.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdWsHandshake();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.testResult = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdWsHandshake message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdWsHandshake} ResponseCmdWsHandshake
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdWsHandshake.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdWsHandshake message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdWsHandshake.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.testResult != null && message.hasOwnProperty("testResult"))
                if (!$util.isString(message.testResult))
                    return "testResult: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdWsHandshake message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdWsHandshake} ResponseCmdWsHandshake
         */
        ResponseCmdWsHandshake.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdWsHandshake)
                return object;
            let message = new $root.hdyRpc.ResponseCmdWsHandshake();
            if (object.testResult != null)
                message.testResult = String(object.testResult);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdWsHandshake message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @static
         * @param {hdyRpc.ResponseCmdWsHandshake} message ResponseCmdWsHandshake
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdWsHandshake.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.testResult = "";
            if (message.testResult != null && message.hasOwnProperty("testResult"))
                object.testResult = message.testResult;
            return object;
        };

        /**
         * Converts this ResponseCmdWsHandshake to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdWsHandshake
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdWsHandshake.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdWsHandshake;
    })();

    hdyRpc.RequestCmdGenerateConnectionKey = (function() {

        /**
         * Properties of a RequestCmdGenerateConnectionKey.
         * @memberof hdyRpc
         * @interface IRequestCmdGenerateConnectionKey
         * @property {number|null} [length] RequestCmdGenerateConnectionKey length
         */

        /**
         * Constructs a new RequestCmdGenerateConnectionKey.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGenerateConnectionKey.
         * @implements IRequestCmdGenerateConnectionKey
         * @constructor
         * @param {hdyRpc.IRequestCmdGenerateConnectionKey=} [properties] Properties to set
         */
        function RequestCmdGenerateConnectionKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdGenerateConnectionKey length.
         * @member {number} length
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @instance
         */
        RequestCmdGenerateConnectionKey.prototype.length = 0;

        /**
         * Creates a new RequestCmdGenerateConnectionKey instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.IRequestCmdGenerateConnectionKey=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGenerateConnectionKey} RequestCmdGenerateConnectionKey instance
         */
        RequestCmdGenerateConnectionKey.create = function create(properties) {
            return new RequestCmdGenerateConnectionKey(properties);
        };

        /**
         * Encodes the specified RequestCmdGenerateConnectionKey message. Does not implicitly {@link hdyRpc.RequestCmdGenerateConnectionKey.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdGenerateConnectionKey} message RequestCmdGenerateConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGenerateConnectionKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.length != null && Object.hasOwnProperty.call(message, "length"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.length);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGenerateConnectionKey message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGenerateConnectionKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdGenerateConnectionKey} message RequestCmdGenerateConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGenerateConnectionKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGenerateConnectionKey message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGenerateConnectionKey} RequestCmdGenerateConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGenerateConnectionKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGenerateConnectionKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.length = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGenerateConnectionKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGenerateConnectionKey} RequestCmdGenerateConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGenerateConnectionKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGenerateConnectionKey message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGenerateConnectionKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.length != null && message.hasOwnProperty("length"))
                if (!$util.isInteger(message.length))
                    return "length: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdGenerateConnectionKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGenerateConnectionKey} RequestCmdGenerateConnectionKey
         */
        RequestCmdGenerateConnectionKey.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGenerateConnectionKey)
                return object;
            let message = new $root.hdyRpc.RequestCmdGenerateConnectionKey();
            if (object.length != null)
                message.length = object.length >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdGenerateConnectionKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdGenerateConnectionKey} message RequestCmdGenerateConnectionKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGenerateConnectionKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.length = 0;
            if (message.length != null && message.hasOwnProperty("length"))
                object.length = message.length;
            return object;
        };

        /**
         * Converts this RequestCmdGenerateConnectionKey to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGenerateConnectionKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGenerateConnectionKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGenerateConnectionKey;
    })();

    hdyRpc.ResponseCmdGenerateConnectionKey = (function() {

        /**
         * Properties of a ResponseCmdGenerateConnectionKey.
         * @memberof hdyRpc
         * @interface IResponseCmdGenerateConnectionKey
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGenerateConnectionKey result
         * @property {string|null} [key] ResponseCmdGenerateConnectionKey key
         */

        /**
         * Constructs a new ResponseCmdGenerateConnectionKey.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGenerateConnectionKey.
         * @implements IResponseCmdGenerateConnectionKey
         * @constructor
         * @param {hdyRpc.IResponseCmdGenerateConnectionKey=} [properties] Properties to set
         */
        function ResponseCmdGenerateConnectionKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGenerateConnectionKey result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @instance
         */
        ResponseCmdGenerateConnectionKey.prototype.result = 0;

        /**
         * ResponseCmdGenerateConnectionKey key.
         * @member {string} key
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @instance
         */
        ResponseCmdGenerateConnectionKey.prototype.key = "";

        /**
         * Creates a new ResponseCmdGenerateConnectionKey instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.IResponseCmdGenerateConnectionKey=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGenerateConnectionKey} ResponseCmdGenerateConnectionKey instance
         */
        ResponseCmdGenerateConnectionKey.create = function create(properties) {
            return new ResponseCmdGenerateConnectionKey(properties);
        };

        /**
         * Encodes the specified ResponseCmdGenerateConnectionKey message. Does not implicitly {@link hdyRpc.ResponseCmdGenerateConnectionKey.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdGenerateConnectionKey} message ResponseCmdGenerateConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGenerateConnectionKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGenerateConnectionKey message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGenerateConnectionKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdGenerateConnectionKey} message ResponseCmdGenerateConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGenerateConnectionKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGenerateConnectionKey message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGenerateConnectionKey} ResponseCmdGenerateConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGenerateConnectionKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGenerateConnectionKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGenerateConnectionKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGenerateConnectionKey} ResponseCmdGenerateConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGenerateConnectionKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGenerateConnectionKey message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGenerateConnectionKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGenerateConnectionKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGenerateConnectionKey} ResponseCmdGenerateConnectionKey
         */
        ResponseCmdGenerateConnectionKey.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGenerateConnectionKey)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGenerateConnectionKey();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGenerateConnectionKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdGenerateConnectionKey} message ResponseCmdGenerateConnectionKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGenerateConnectionKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.key = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this ResponseCmdGenerateConnectionKey to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGenerateConnectionKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGenerateConnectionKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGenerateConnectionKey;
    })();

    hdyRpc.RequestCmdSetConnectionKey = (function() {

        /**
         * Properties of a RequestCmdSetConnectionKey.
         * @memberof hdyRpc
         * @interface IRequestCmdSetConnectionKey
         * @property {string|null} [key] RequestCmdSetConnectionKey key
         */

        /**
         * Constructs a new RequestCmdSetConnectionKey.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdSetConnectionKey.
         * @implements IRequestCmdSetConnectionKey
         * @constructor
         * @param {hdyRpc.IRequestCmdSetConnectionKey=} [properties] Properties to set
         */
        function RequestCmdSetConnectionKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdSetConnectionKey key.
         * @member {string} key
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @instance
         */
        RequestCmdSetConnectionKey.prototype.key = "";

        /**
         * Creates a new RequestCmdSetConnectionKey instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {hdyRpc.IRequestCmdSetConnectionKey=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdSetConnectionKey} RequestCmdSetConnectionKey instance
         */
        RequestCmdSetConnectionKey.create = function create(properties) {
            return new RequestCmdSetConnectionKey(properties);
        };

        /**
         * Encodes the specified RequestCmdSetConnectionKey message. Does not implicitly {@link hdyRpc.RequestCmdSetConnectionKey.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdSetConnectionKey} message RequestCmdSetConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdSetConnectionKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdSetConnectionKey message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdSetConnectionKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdSetConnectionKey} message RequestCmdSetConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdSetConnectionKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdSetConnectionKey message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdSetConnectionKey} RequestCmdSetConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdSetConnectionKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdSetConnectionKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdSetConnectionKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdSetConnectionKey} RequestCmdSetConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdSetConnectionKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdSetConnectionKey message.
         * @function verify
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdSetConnectionKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RequestCmdSetConnectionKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdSetConnectionKey} RequestCmdSetConnectionKey
         */
        RequestCmdSetConnectionKey.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdSetConnectionKey)
                return object;
            let message = new $root.hdyRpc.RequestCmdSetConnectionKey();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdSetConnectionKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @static
         * @param {hdyRpc.RequestCmdSetConnectionKey} message RequestCmdSetConnectionKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdSetConnectionKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RequestCmdSetConnectionKey to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdSetConnectionKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdSetConnectionKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdSetConnectionKey;
    })();

    hdyRpc.ResponseCmdSetConnectionKey = (function() {

        /**
         * Properties of a ResponseCmdSetConnectionKey.
         * @memberof hdyRpc
         * @interface IResponseCmdSetConnectionKey
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdSetConnectionKey result
         */

        /**
         * Constructs a new ResponseCmdSetConnectionKey.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdSetConnectionKey.
         * @implements IResponseCmdSetConnectionKey
         * @constructor
         * @param {hdyRpc.IResponseCmdSetConnectionKey=} [properties] Properties to set
         */
        function ResponseCmdSetConnectionKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdSetConnectionKey result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @instance
         */
        ResponseCmdSetConnectionKey.prototype.result = 0;

        /**
         * Creates a new ResponseCmdSetConnectionKey instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {hdyRpc.IResponseCmdSetConnectionKey=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdSetConnectionKey} ResponseCmdSetConnectionKey instance
         */
        ResponseCmdSetConnectionKey.create = function create(properties) {
            return new ResponseCmdSetConnectionKey(properties);
        };

        /**
         * Encodes the specified ResponseCmdSetConnectionKey message. Does not implicitly {@link hdyRpc.ResponseCmdSetConnectionKey.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdSetConnectionKey} message ResponseCmdSetConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdSetConnectionKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdSetConnectionKey message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdSetConnectionKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdSetConnectionKey} message ResponseCmdSetConnectionKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdSetConnectionKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdSetConnectionKey message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdSetConnectionKey} ResponseCmdSetConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdSetConnectionKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdSetConnectionKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdSetConnectionKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdSetConnectionKey} ResponseCmdSetConnectionKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdSetConnectionKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdSetConnectionKey message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdSetConnectionKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdSetConnectionKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdSetConnectionKey} ResponseCmdSetConnectionKey
         */
        ResponseCmdSetConnectionKey.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdSetConnectionKey)
                return object;
            let message = new $root.hdyRpc.ResponseCmdSetConnectionKey();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdSetConnectionKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @static
         * @param {hdyRpc.ResponseCmdSetConnectionKey} message ResponseCmdSetConnectionKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdSetConnectionKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdSetConnectionKey to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdSetConnectionKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdSetConnectionKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdSetConnectionKey;
    })();

    hdyRpc.RequestCmdGetMode = (function() {

        /**
         * Properties of a RequestCmdGetMode.
         * @memberof hdyRpc
         * @interface IRequestCmdGetMode
         */

        /**
         * Constructs a new RequestCmdGetMode.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetMode.
         * @implements IRequestCmdGetMode
         * @constructor
         * @param {hdyRpc.IRequestCmdGetMode=} [properties] Properties to set
         */
        function RequestCmdGetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetMode instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {hdyRpc.IRequestCmdGetMode=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetMode} RequestCmdGetMode instance
         */
        RequestCmdGetMode.create = function create(properties) {
            return new RequestCmdGetMode(properties);
        };

        /**
         * Encodes the specified RequestCmdGetMode message. Does not implicitly {@link hdyRpc.RequestCmdGetMode.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {hdyRpc.RequestCmdGetMode} message RequestCmdGetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetMode message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {hdyRpc.RequestCmdGetMode} message RequestCmdGetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetMode message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetMode} RequestCmdGetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetMode} RequestCmdGetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetMode message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetMode} RequestCmdGetMode
         */
        RequestCmdGetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetMode)
                return object;
            return new $root.hdyRpc.RequestCmdGetMode();
        };

        /**
         * Creates a plain object from a RequestCmdGetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetMode
         * @static
         * @param {hdyRpc.RequestCmdGetMode} message RequestCmdGetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetMode.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetMode to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetMode;
    })();

    hdyRpc.ResponseCmdGetMode = (function() {

        /**
         * Properties of a ResponseCmdGetMode.
         * @memberof hdyRpc
         * @interface IResponseCmdGetMode
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGetMode result
         * @property {hdyRpc.Modes|null} [mode] ResponseCmdGetMode mode
         */

        /**
         * Constructs a new ResponseCmdGetMode.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetMode.
         * @implements IResponseCmdGetMode
         * @constructor
         * @param {hdyRpc.IResponseCmdGetMode=} [properties] Properties to set
         */
        function ResponseCmdGetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetMode result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGetMode
         * @instance
         */
        ResponseCmdGetMode.prototype.result = 0;

        /**
         * ResponseCmdGetMode mode.
         * @member {hdyRpc.Modes} mode
         * @memberof hdyRpc.ResponseCmdGetMode
         * @instance
         */
        ResponseCmdGetMode.prototype.mode = 0;

        /**
         * Creates a new ResponseCmdGetMode instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {hdyRpc.IResponseCmdGetMode=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetMode} ResponseCmdGetMode instance
         */
        ResponseCmdGetMode.create = function create(properties) {
            return new ResponseCmdGetMode(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetMode message. Does not implicitly {@link hdyRpc.ResponseCmdGetMode.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {hdyRpc.ResponseCmdGetMode} message ResponseCmdGetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.mode);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetMode message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {hdyRpc.ResponseCmdGetMode} message ResponseCmdGetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetMode message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetMode} ResponseCmdGetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.mode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetMode} ResponseCmdGetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetMode message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.mode != null && message.hasOwnProperty("mode"))
                switch (message.mode) {
                default:
                    return "mode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdGetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetMode} ResponseCmdGetMode
         */
        ResponseCmdGetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetMode)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetMode();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            switch (object.mode) {
            case "MODE_HAMP":
            case 0:
                message.mode = 0;
                break;
            case "MODE_HDSP":
            case 1:
                message.mode = 1;
                break;
            case "MODE_HSSP":
            case 2:
                message.mode = 2;
                break;
            case "MODE_MAINTENANCE":
            case 3:
                message.mode = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetMode
         * @static
         * @param {hdyRpc.ResponseCmdGetMode} message ResponseCmdGetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetMode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.mode = options.enums === String ? "MODE_HAMP" : 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.mode != null && message.hasOwnProperty("mode"))
                object.mode = options.enums === String ? $root.hdyRpc.Modes[message.mode] : message.mode;
            return object;
        };

        /**
         * Converts this ResponseCmdGetMode to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetMode;
    })();

    hdyRpc.RequestCmdSetMode = (function() {

        /**
         * Properties of a RequestCmdSetMode.
         * @memberof hdyRpc
         * @interface IRequestCmdSetMode
         * @property {hdyRpc.Modes|null} [mode] RequestCmdSetMode mode
         */

        /**
         * Constructs a new RequestCmdSetMode.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdSetMode.
         * @implements IRequestCmdSetMode
         * @constructor
         * @param {hdyRpc.IRequestCmdSetMode=} [properties] Properties to set
         */
        function RequestCmdSetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdSetMode mode.
         * @member {hdyRpc.Modes} mode
         * @memberof hdyRpc.RequestCmdSetMode
         * @instance
         */
        RequestCmdSetMode.prototype.mode = 0;

        /**
         * Creates a new RequestCmdSetMode instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {hdyRpc.IRequestCmdSetMode=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdSetMode} RequestCmdSetMode instance
         */
        RequestCmdSetMode.create = function create(properties) {
            return new RequestCmdSetMode(properties);
        };

        /**
         * Encodes the specified RequestCmdSetMode message. Does not implicitly {@link hdyRpc.RequestCmdSetMode.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {hdyRpc.RequestCmdSetMode} message RequestCmdSetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdSetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mode);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdSetMode message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdSetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {hdyRpc.RequestCmdSetMode} message RequestCmdSetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdSetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdSetMode message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdSetMode} RequestCmdSetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdSetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdSetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdSetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdSetMode} RequestCmdSetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdSetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdSetMode message.
         * @function verify
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdSetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mode != null && message.hasOwnProperty("mode"))
                switch (message.mode) {
                default:
                    return "mode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a RequestCmdSetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdSetMode} RequestCmdSetMode
         */
        RequestCmdSetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdSetMode)
                return object;
            let message = new $root.hdyRpc.RequestCmdSetMode();
            switch (object.mode) {
            case "MODE_HAMP":
            case 0:
                message.mode = 0;
                break;
            case "MODE_HDSP":
            case 1:
                message.mode = 1;
                break;
            case "MODE_HSSP":
            case 2:
                message.mode = 2;
                break;
            case "MODE_MAINTENANCE":
            case 3:
                message.mode = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdSetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdSetMode
         * @static
         * @param {hdyRpc.RequestCmdSetMode} message RequestCmdSetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdSetMode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mode = options.enums === String ? "MODE_HAMP" : 0;
            if (message.mode != null && message.hasOwnProperty("mode"))
                object.mode = options.enums === String ? $root.hdyRpc.Modes[message.mode] : message.mode;
            return object;
        };

        /**
         * Converts this RequestCmdSetMode to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdSetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdSetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdSetMode;
    })();

    hdyRpc.ResponseCmdSetMode = (function() {

        /**
         * Properties of a ResponseCmdSetMode.
         * @memberof hdyRpc
         * @interface IResponseCmdSetMode
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdSetMode result
         */

        /**
         * Constructs a new ResponseCmdSetMode.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdSetMode.
         * @implements IResponseCmdSetMode
         * @constructor
         * @param {hdyRpc.IResponseCmdSetMode=} [properties] Properties to set
         */
        function ResponseCmdSetMode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdSetMode result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdSetMode
         * @instance
         */
        ResponseCmdSetMode.prototype.result = 0;

        /**
         * Creates a new ResponseCmdSetMode instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {hdyRpc.IResponseCmdSetMode=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdSetMode} ResponseCmdSetMode instance
         */
        ResponseCmdSetMode.create = function create(properties) {
            return new ResponseCmdSetMode(properties);
        };

        /**
         * Encodes the specified ResponseCmdSetMode message. Does not implicitly {@link hdyRpc.ResponseCmdSetMode.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {hdyRpc.ResponseCmdSetMode} message ResponseCmdSetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdSetMode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdSetMode message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdSetMode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {hdyRpc.ResponseCmdSetMode} message ResponseCmdSetMode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdSetMode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdSetMode message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdSetMode} ResponseCmdSetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdSetMode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdSetMode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdSetMode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdSetMode} ResponseCmdSetMode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdSetMode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdSetMode message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdSetMode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdSetMode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdSetMode} ResponseCmdSetMode
         */
        ResponseCmdSetMode.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdSetMode)
                return object;
            let message = new $root.hdyRpc.ResponseCmdSetMode();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdSetMode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdSetMode
         * @static
         * @param {hdyRpc.ResponseCmdSetMode} message ResponseCmdSetMode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdSetMode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdSetMode to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdSetMode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdSetMode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdSetMode;
    })();

    hdyRpc.RequestCmdGetConnected = (function() {

        /**
         * Properties of a RequestCmdGetConnected.
         * @memberof hdyRpc
         * @interface IRequestCmdGetConnected
         */

        /**
         * Constructs a new RequestCmdGetConnected.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetConnected.
         * @implements IRequestCmdGetConnected
         * @constructor
         * @param {hdyRpc.IRequestCmdGetConnected=} [properties] Properties to set
         */
        function RequestCmdGetConnected(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetConnected instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {hdyRpc.IRequestCmdGetConnected=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetConnected} RequestCmdGetConnected instance
         */
        RequestCmdGetConnected.create = function create(properties) {
            return new RequestCmdGetConnected(properties);
        };

        /**
         * Encodes the specified RequestCmdGetConnected message. Does not implicitly {@link hdyRpc.RequestCmdGetConnected.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {hdyRpc.RequestCmdGetConnected} message RequestCmdGetConnected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetConnected.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetConnected message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetConnected.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {hdyRpc.RequestCmdGetConnected} message RequestCmdGetConnected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetConnected.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetConnected message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetConnected} RequestCmdGetConnected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetConnected.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetConnected();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetConnected message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetConnected} RequestCmdGetConnected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetConnected.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetConnected message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetConnected.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetConnected message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetConnected} RequestCmdGetConnected
         */
        RequestCmdGetConnected.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetConnected)
                return object;
            return new $root.hdyRpc.RequestCmdGetConnected();
        };

        /**
         * Creates a plain object from a RequestCmdGetConnected message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetConnected
         * @static
         * @param {hdyRpc.RequestCmdGetConnected} message RequestCmdGetConnected
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetConnected.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetConnected to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetConnected
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetConnected.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetConnected;
    })();

    hdyRpc.ResponseCmdGetConnected = (function() {

        /**
         * Properties of a ResponseCmdGetConnected.
         * @memberof hdyRpc
         * @interface IResponseCmdGetConnected
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGetConnected result
         * @property {boolean|null} [connected] ResponseCmdGetConnected connected
         */

        /**
         * Constructs a new ResponseCmdGetConnected.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetConnected.
         * @implements IResponseCmdGetConnected
         * @constructor
         * @param {hdyRpc.IResponseCmdGetConnected=} [properties] Properties to set
         */
        function ResponseCmdGetConnected(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetConnected result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @instance
         */
        ResponseCmdGetConnected.prototype.result = 0;

        /**
         * ResponseCmdGetConnected connected.
         * @member {boolean} connected
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @instance
         */
        ResponseCmdGetConnected.prototype.connected = false;

        /**
         * Creates a new ResponseCmdGetConnected instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {hdyRpc.IResponseCmdGetConnected=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetConnected} ResponseCmdGetConnected instance
         */
        ResponseCmdGetConnected.create = function create(properties) {
            return new ResponseCmdGetConnected(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetConnected message. Does not implicitly {@link hdyRpc.ResponseCmdGetConnected.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {hdyRpc.ResponseCmdGetConnected} message ResponseCmdGetConnected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetConnected.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.connected != null && Object.hasOwnProperty.call(message, "connected"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.connected);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetConnected message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetConnected.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {hdyRpc.ResponseCmdGetConnected} message ResponseCmdGetConnected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetConnected.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetConnected message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetConnected} ResponseCmdGetConnected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetConnected.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetConnected();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.connected = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetConnected message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetConnected} ResponseCmdGetConnected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetConnected.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetConnected message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetConnected.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.connected != null && message.hasOwnProperty("connected"))
                if (typeof message.connected !== "boolean")
                    return "connected: boolean expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGetConnected message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetConnected} ResponseCmdGetConnected
         */
        ResponseCmdGetConnected.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetConnected)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetConnected();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.connected != null)
                message.connected = Boolean(object.connected);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetConnected message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @static
         * @param {hdyRpc.ResponseCmdGetConnected} message ResponseCmdGetConnected
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetConnected.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.connected = false;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.connected != null && message.hasOwnProperty("connected"))
                object.connected = message.connected;
            return object;
        };

        /**
         * Converts this ResponseCmdGetConnected to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetConnected
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetConnected.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetConnected;
    })();

    hdyRpc.RequestCmdGetInfo = (function() {

        /**
         * Properties of a RequestCmdGetInfo.
         * @memberof hdyRpc
         * @interface IRequestCmdGetInfo
         */

        /**
         * Constructs a new RequestCmdGetInfo.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetInfo.
         * @implements IRequestCmdGetInfo
         * @constructor
         * @param {hdyRpc.IRequestCmdGetInfo=} [properties] Properties to set
         */
        function RequestCmdGetInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetInfo instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {hdyRpc.IRequestCmdGetInfo=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetInfo} RequestCmdGetInfo instance
         */
        RequestCmdGetInfo.create = function create(properties) {
            return new RequestCmdGetInfo(properties);
        };

        /**
         * Encodes the specified RequestCmdGetInfo message. Does not implicitly {@link hdyRpc.RequestCmdGetInfo.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {hdyRpc.RequestCmdGetInfo} message RequestCmdGetInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetInfo message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {hdyRpc.RequestCmdGetInfo} message RequestCmdGetInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetInfo message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetInfo} RequestCmdGetInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetInfo} RequestCmdGetInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetInfo message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetInfo} RequestCmdGetInfo
         */
        RequestCmdGetInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetInfo)
                return object;
            return new $root.hdyRpc.RequestCmdGetInfo();
        };

        /**
         * Creates a plain object from a RequestCmdGetInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetInfo
         * @static
         * @param {hdyRpc.RequestCmdGetInfo} message RequestCmdGetInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetInfo.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetInfo to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetInfo;
    })();

    hdyRpc.ResponseCmdGetInfo = (function() {

        /**
         * Properties of a ResponseCmdGetInfo.
         * @memberof hdyRpc
         * @interface IResponseCmdGetInfo
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGetInfo result
         * @property {string|null} [info] ResponseCmdGetInfo info
         */

        /**
         * Constructs a new ResponseCmdGetInfo.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetInfo.
         * @implements IResponseCmdGetInfo
         * @constructor
         * @param {hdyRpc.IResponseCmdGetInfo=} [properties] Properties to set
         */
        function ResponseCmdGetInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetInfo result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @instance
         */
        ResponseCmdGetInfo.prototype.result = 0;

        /**
         * ResponseCmdGetInfo info.
         * @member {string} info
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @instance
         */
        ResponseCmdGetInfo.prototype.info = "";

        /**
         * Creates a new ResponseCmdGetInfo instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {hdyRpc.IResponseCmdGetInfo=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetInfo} ResponseCmdGetInfo instance
         */
        ResponseCmdGetInfo.create = function create(properties) {
            return new ResponseCmdGetInfo(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetInfo message. Does not implicitly {@link hdyRpc.ResponseCmdGetInfo.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {hdyRpc.ResponseCmdGetInfo} message ResponseCmdGetInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.info);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetInfo message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {hdyRpc.ResponseCmdGetInfo} message ResponseCmdGetInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetInfo message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetInfo} ResponseCmdGetInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.info = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetInfo} ResponseCmdGetInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetInfo message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGetInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetInfo} ResponseCmdGetInfo
         */
        ResponseCmdGetInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetInfo)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetInfo();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.info != null)
                message.info = String(object.info);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @static
         * @param {hdyRpc.ResponseCmdGetInfo} message ResponseCmdGetInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.info = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            return object;
        };

        /**
         * Converts this ResponseCmdGetInfo to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetInfo;
    })();

    hdyRpc.RequestCmdGetSettings = (function() {

        /**
         * Properties of a RequestCmdGetSettings.
         * @memberof hdyRpc
         * @interface IRequestCmdGetSettings
         */

        /**
         * Constructs a new RequestCmdGetSettings.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetSettings.
         * @implements IRequestCmdGetSettings
         * @constructor
         * @param {hdyRpc.IRequestCmdGetSettings=} [properties] Properties to set
         */
        function RequestCmdGetSettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetSettings instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {hdyRpc.IRequestCmdGetSettings=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetSettings} RequestCmdGetSettings instance
         */
        RequestCmdGetSettings.create = function create(properties) {
            return new RequestCmdGetSettings(properties);
        };

        /**
         * Encodes the specified RequestCmdGetSettings message. Does not implicitly {@link hdyRpc.RequestCmdGetSettings.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {hdyRpc.RequestCmdGetSettings} message RequestCmdGetSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetSettings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetSettings message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetSettings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {hdyRpc.RequestCmdGetSettings} message RequestCmdGetSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetSettings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetSettings message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetSettings} RequestCmdGetSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetSettings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetSettings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetSettings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetSettings} RequestCmdGetSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetSettings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetSettings message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetSettings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetSettings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetSettings} RequestCmdGetSettings
         */
        RequestCmdGetSettings.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetSettings)
                return object;
            return new $root.hdyRpc.RequestCmdGetSettings();
        };

        /**
         * Creates a plain object from a RequestCmdGetSettings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetSettings
         * @static
         * @param {hdyRpc.RequestCmdGetSettings} message RequestCmdGetSettings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetSettings.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetSettings to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetSettings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetSettings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetSettings;
    })();

    hdyRpc.ResponseCmdGetSettings = (function() {

        /**
         * Properties of a ResponseCmdGetSettings.
         * @memberof hdyRpc
         * @interface IResponseCmdGetSettings
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGetSettings result
         * @property {string|null} [settings] ResponseCmdGetSettings settings
         */

        /**
         * Constructs a new ResponseCmdGetSettings.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetSettings.
         * @implements IResponseCmdGetSettings
         * @constructor
         * @param {hdyRpc.IResponseCmdGetSettings=} [properties] Properties to set
         */
        function ResponseCmdGetSettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetSettings result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @instance
         */
        ResponseCmdGetSettings.prototype.result = 0;

        /**
         * ResponseCmdGetSettings settings.
         * @member {string} settings
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @instance
         */
        ResponseCmdGetSettings.prototype.settings = "";

        /**
         * Creates a new ResponseCmdGetSettings instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {hdyRpc.IResponseCmdGetSettings=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetSettings} ResponseCmdGetSettings instance
         */
        ResponseCmdGetSettings.create = function create(properties) {
            return new ResponseCmdGetSettings(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetSettings message. Does not implicitly {@link hdyRpc.ResponseCmdGetSettings.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {hdyRpc.ResponseCmdGetSettings} message ResponseCmdGetSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetSettings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.settings);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetSettings message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetSettings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {hdyRpc.ResponseCmdGetSettings} message ResponseCmdGetSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetSettings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetSettings message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetSettings} ResponseCmdGetSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetSettings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetSettings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.settings = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetSettings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetSettings} ResponseCmdGetSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetSettings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetSettings message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetSettings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.settings != null && message.hasOwnProperty("settings"))
                if (!$util.isString(message.settings))
                    return "settings: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGetSettings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetSettings} ResponseCmdGetSettings
         */
        ResponseCmdGetSettings.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetSettings)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetSettings();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.settings != null)
                message.settings = String(object.settings);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetSettings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @static
         * @param {hdyRpc.ResponseCmdGetSettings} message ResponseCmdGetSettings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetSettings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.settings = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.settings != null && message.hasOwnProperty("settings"))
                object.settings = message.settings;
            return object;
        };

        /**
         * Converts this ResponseCmdGetSettings to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetSettings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetSettings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetSettings;
    })();

    hdyRpc.RequestCmdGetStatus = (function() {

        /**
         * Properties of a RequestCmdGetStatus.
         * @memberof hdyRpc
         * @interface IRequestCmdGetStatus
         */

        /**
         * Constructs a new RequestCmdGetStatus.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdGetStatus.
         * @implements IRequestCmdGetStatus
         * @constructor
         * @param {hdyRpc.IRequestCmdGetStatus=} [properties] Properties to set
         */
        function RequestCmdGetStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdGetStatus instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {hdyRpc.IRequestCmdGetStatus=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdGetStatus} RequestCmdGetStatus instance
         */
        RequestCmdGetStatus.create = function create(properties) {
            return new RequestCmdGetStatus(properties);
        };

        /**
         * Encodes the specified RequestCmdGetStatus message. Does not implicitly {@link hdyRpc.RequestCmdGetStatus.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {hdyRpc.RequestCmdGetStatus} message RequestCmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdGetStatus message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdGetStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {hdyRpc.RequestCmdGetStatus} message RequestCmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdGetStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdGetStatus message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdGetStatus} RequestCmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdGetStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdGetStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdGetStatus} RequestCmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdGetStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdGetStatus message.
         * @function verify
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdGetStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdGetStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdGetStatus} RequestCmdGetStatus
         */
        RequestCmdGetStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdGetStatus)
                return object;
            return new $root.hdyRpc.RequestCmdGetStatus();
        };

        /**
         * Creates a plain object from a RequestCmdGetStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdGetStatus
         * @static
         * @param {hdyRpc.RequestCmdGetStatus} message RequestCmdGetStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdGetStatus.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdGetStatus to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdGetStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdGetStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdGetStatus;
    })();

    hdyRpc.ResponseCmdGetStatus = (function() {

        /**
         * Properties of a ResponseCmdGetStatus.
         * @memberof hdyRpc
         * @interface IResponseCmdGetStatus
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdGetStatus result
         * @property {string|null} [status] ResponseCmdGetStatus status
         */

        /**
         * Constructs a new ResponseCmdGetStatus.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdGetStatus.
         * @implements IResponseCmdGetStatus
         * @constructor
         * @param {hdyRpc.IResponseCmdGetStatus=} [properties] Properties to set
         */
        function ResponseCmdGetStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdGetStatus result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @instance
         */
        ResponseCmdGetStatus.prototype.result = 0;

        /**
         * ResponseCmdGetStatus status.
         * @member {string} status
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @instance
         */
        ResponseCmdGetStatus.prototype.status = "";

        /**
         * Creates a new ResponseCmdGetStatus instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {hdyRpc.IResponseCmdGetStatus=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdGetStatus} ResponseCmdGetStatus instance
         */
        ResponseCmdGetStatus.create = function create(properties) {
            return new ResponseCmdGetStatus(properties);
        };

        /**
         * Encodes the specified ResponseCmdGetStatus message. Does not implicitly {@link hdyRpc.ResponseCmdGetStatus.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {hdyRpc.ResponseCmdGetStatus} message ResponseCmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdGetStatus message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdGetStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {hdyRpc.ResponseCmdGetStatus} message ResponseCmdGetStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdGetStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdGetStatus message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdGetStatus} ResponseCmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdGetStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdGetStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdGetStatus} ResponseCmdGetStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdGetStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdGetStatus message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdGetStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdGetStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdGetStatus} ResponseCmdGetStatus
         */
        ResponseCmdGetStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdGetStatus)
                return object;
            let message = new $root.hdyRpc.ResponseCmdGetStatus();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.status != null)
                message.status = String(object.status);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdGetStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @static
         * @param {hdyRpc.ResponseCmdGetStatus} message ResponseCmdGetStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdGetStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.status = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            return object;
        };

        /**
         * Converts this ResponseCmdGetStatus to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdGetStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdGetStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdGetStatus;
    })();

    hdyRpc.RequestCmdHampSetStart = (function() {

        /**
         * Properties of a RequestCmdHampSetStart.
         * @memberof hdyRpc
         * @interface IRequestCmdHampSetStart
         */

        /**
         * Constructs a new RequestCmdHampSetStart.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHampSetStart.
         * @implements IRequestCmdHampSetStart
         * @constructor
         * @param {hdyRpc.IRequestCmdHampSetStart=} [properties] Properties to set
         */
        function RequestCmdHampSetStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdHampSetStart instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {hdyRpc.IRequestCmdHampSetStart=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHampSetStart} RequestCmdHampSetStart instance
         */
        RequestCmdHampSetStart.create = function create(properties) {
            return new RequestCmdHampSetStart(properties);
        };

        /**
         * Encodes the specified RequestCmdHampSetStart message. Does not implicitly {@link hdyRpc.RequestCmdHampSetStart.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {hdyRpc.RequestCmdHampSetStart} message RequestCmdHampSetStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHampSetStart message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHampSetStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {hdyRpc.RequestCmdHampSetStart} message RequestCmdHampSetStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHampSetStart message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHampSetStart} RequestCmdHampSetStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHampSetStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHampSetStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHampSetStart} RequestCmdHampSetStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHampSetStart message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHampSetStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdHampSetStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHampSetStart} RequestCmdHampSetStart
         */
        RequestCmdHampSetStart.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHampSetStart)
                return object;
            return new $root.hdyRpc.RequestCmdHampSetStart();
        };

        /**
         * Creates a plain object from a RequestCmdHampSetStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @static
         * @param {hdyRpc.RequestCmdHampSetStart} message RequestCmdHampSetStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHampSetStart.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdHampSetStart to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHampSetStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHampSetStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHampSetStart;
    })();

    hdyRpc.ResponseCmdHampSetStart = (function() {

        /**
         * Properties of a ResponseCmdHampSetStart.
         * @memberof hdyRpc
         * @interface IResponseCmdHampSetStart
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHampSetStart result
         */

        /**
         * Constructs a new ResponseCmdHampSetStart.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHampSetStart.
         * @implements IResponseCmdHampSetStart
         * @constructor
         * @param {hdyRpc.IResponseCmdHampSetStart=} [properties] Properties to set
         */
        function ResponseCmdHampSetStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHampSetStart result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @instance
         */
        ResponseCmdHampSetStart.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHampSetStart instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {hdyRpc.IResponseCmdHampSetStart=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHampSetStart} ResponseCmdHampSetStart instance
         */
        ResponseCmdHampSetStart.create = function create(properties) {
            return new ResponseCmdHampSetStart(properties);
        };

        /**
         * Encodes the specified ResponseCmdHampSetStart message. Does not implicitly {@link hdyRpc.ResponseCmdHampSetStart.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStart} message ResponseCmdHampSetStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHampSetStart message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHampSetStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStart} message ResponseCmdHampSetStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHampSetStart message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHampSetStart} ResponseCmdHampSetStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHampSetStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHampSetStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHampSetStart} ResponseCmdHampSetStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHampSetStart message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHampSetStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHampSetStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHampSetStart} ResponseCmdHampSetStart
         */
        ResponseCmdHampSetStart.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHampSetStart)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHampSetStart();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHampSetStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStart} message ResponseCmdHampSetStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHampSetStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHampSetStart to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHampSetStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHampSetStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHampSetStart;
    })();

    hdyRpc.RequestCmdHampSetStop = (function() {

        /**
         * Properties of a RequestCmdHampSetStop.
         * @memberof hdyRpc
         * @interface IRequestCmdHampSetStop
         */

        /**
         * Constructs a new RequestCmdHampSetStop.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHampSetStop.
         * @implements IRequestCmdHampSetStop
         * @constructor
         * @param {hdyRpc.IRequestCmdHampSetStop=} [properties] Properties to set
         */
        function RequestCmdHampSetStop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdHampSetStop instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {hdyRpc.IRequestCmdHampSetStop=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHampSetStop} RequestCmdHampSetStop instance
         */
        RequestCmdHampSetStop.create = function create(properties) {
            return new RequestCmdHampSetStop(properties);
        };

        /**
         * Encodes the specified RequestCmdHampSetStop message. Does not implicitly {@link hdyRpc.RequestCmdHampSetStop.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {hdyRpc.RequestCmdHampSetStop} message RequestCmdHampSetStop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetStop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHampSetStop message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHampSetStop.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {hdyRpc.RequestCmdHampSetStop} message RequestCmdHampSetStop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetStop.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHampSetStop message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHampSetStop} RequestCmdHampSetStop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetStop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHampSetStop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHampSetStop message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHampSetStop} RequestCmdHampSetStop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetStop.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHampSetStop message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHampSetStop.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdHampSetStop message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHampSetStop} RequestCmdHampSetStop
         */
        RequestCmdHampSetStop.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHampSetStop)
                return object;
            return new $root.hdyRpc.RequestCmdHampSetStop();
        };

        /**
         * Creates a plain object from a RequestCmdHampSetStop message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @static
         * @param {hdyRpc.RequestCmdHampSetStop} message RequestCmdHampSetStop
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHampSetStop.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdHampSetStop to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHampSetStop
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHampSetStop.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHampSetStop;
    })();

    hdyRpc.ResponseCmdHampSetStop = (function() {

        /**
         * Properties of a ResponseCmdHampSetStop.
         * @memberof hdyRpc
         * @interface IResponseCmdHampSetStop
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHampSetStop result
         */

        /**
         * Constructs a new ResponseCmdHampSetStop.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHampSetStop.
         * @implements IResponseCmdHampSetStop
         * @constructor
         * @param {hdyRpc.IResponseCmdHampSetStop=} [properties] Properties to set
         */
        function ResponseCmdHampSetStop(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHampSetStop result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @instance
         */
        ResponseCmdHampSetStop.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHampSetStop instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {hdyRpc.IResponseCmdHampSetStop=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHampSetStop} ResponseCmdHampSetStop instance
         */
        ResponseCmdHampSetStop.create = function create(properties) {
            return new ResponseCmdHampSetStop(properties);
        };

        /**
         * Encodes the specified ResponseCmdHampSetStop message. Does not implicitly {@link hdyRpc.ResponseCmdHampSetStop.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStop} message ResponseCmdHampSetStop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetStop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHampSetStop message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHampSetStop.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStop} message ResponseCmdHampSetStop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetStop.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHampSetStop message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHampSetStop} ResponseCmdHampSetStop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetStop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHampSetStop();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHampSetStop message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHampSetStop} ResponseCmdHampSetStop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetStop.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHampSetStop message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHampSetStop.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHampSetStop message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHampSetStop} ResponseCmdHampSetStop
         */
        ResponseCmdHampSetStop.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHampSetStop)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHampSetStop();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHampSetStop message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @static
         * @param {hdyRpc.ResponseCmdHampSetStop} message ResponseCmdHampSetStop
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHampSetStop.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHampSetStop to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHampSetStop
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHampSetStop.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHampSetStop;
    })();

    hdyRpc.RequestCmdHampGetVelocity = (function() {

        /**
         * Properties of a RequestCmdHampGetVelocity.
         * @memberof hdyRpc
         * @interface IRequestCmdHampGetVelocity
         */

        /**
         * Constructs a new RequestCmdHampGetVelocity.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHampGetVelocity.
         * @implements IRequestCmdHampGetVelocity
         * @constructor
         * @param {hdyRpc.IRequestCmdHampGetVelocity=} [properties] Properties to set
         */
        function RequestCmdHampGetVelocity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdHampGetVelocity instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {hdyRpc.IRequestCmdHampGetVelocity=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHampGetVelocity} RequestCmdHampGetVelocity instance
         */
        RequestCmdHampGetVelocity.create = function create(properties) {
            return new RequestCmdHampGetVelocity(properties);
        };

        /**
         * Encodes the specified RequestCmdHampGetVelocity message. Does not implicitly {@link hdyRpc.RequestCmdHampGetVelocity.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampGetVelocity} message RequestCmdHampGetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampGetVelocity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHampGetVelocity message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHampGetVelocity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampGetVelocity} message RequestCmdHampGetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampGetVelocity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHampGetVelocity message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHampGetVelocity} RequestCmdHampGetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampGetVelocity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHampGetVelocity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHampGetVelocity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHampGetVelocity} RequestCmdHampGetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampGetVelocity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHampGetVelocity message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHampGetVelocity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdHampGetVelocity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHampGetVelocity} RequestCmdHampGetVelocity
         */
        RequestCmdHampGetVelocity.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHampGetVelocity)
                return object;
            return new $root.hdyRpc.RequestCmdHampGetVelocity();
        };

        /**
         * Creates a plain object from a RequestCmdHampGetVelocity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampGetVelocity} message RequestCmdHampGetVelocity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHampGetVelocity.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdHampGetVelocity to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHampGetVelocity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHampGetVelocity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHampGetVelocity;
    })();

    hdyRpc.ResponseCmdHampGetVelocity = (function() {

        /**
         * Properties of a ResponseCmdHampGetVelocity.
         * @memberof hdyRpc
         * @interface IResponseCmdHampGetVelocity
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHampGetVelocity result
         * @property {number|null} [velocity] ResponseCmdHampGetVelocity velocity
         */

        /**
         * Constructs a new ResponseCmdHampGetVelocity.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHampGetVelocity.
         * @implements IResponseCmdHampGetVelocity
         * @constructor
         * @param {hdyRpc.IResponseCmdHampGetVelocity=} [properties] Properties to set
         */
        function ResponseCmdHampGetVelocity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHampGetVelocity result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @instance
         */
        ResponseCmdHampGetVelocity.prototype.result = 0;

        /**
         * ResponseCmdHampGetVelocity velocity.
         * @member {number} velocity
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @instance
         */
        ResponseCmdHampGetVelocity.prototype.velocity = 0;

        /**
         * Creates a new ResponseCmdHampGetVelocity instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {hdyRpc.IResponseCmdHampGetVelocity=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHampGetVelocity} ResponseCmdHampGetVelocity instance
         */
        ResponseCmdHampGetVelocity.create = function create(properties) {
            return new ResponseCmdHampGetVelocity(properties);
        };

        /**
         * Encodes the specified ResponseCmdHampGetVelocity message. Does not implicitly {@link hdyRpc.ResponseCmdHampGetVelocity.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampGetVelocity} message ResponseCmdHampGetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampGetVelocity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.velocity != null && Object.hasOwnProperty.call(message, "velocity"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.velocity);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHampGetVelocity message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHampGetVelocity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampGetVelocity} message ResponseCmdHampGetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampGetVelocity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHampGetVelocity message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHampGetVelocity} ResponseCmdHampGetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampGetVelocity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHampGetVelocity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.velocity = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHampGetVelocity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHampGetVelocity} ResponseCmdHampGetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampGetVelocity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHampGetVelocity message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHampGetVelocity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                if (typeof message.velocity !== "number")
                    return "velocity: number expected";
            return null;
        };

        /**
         * Creates a ResponseCmdHampGetVelocity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHampGetVelocity} ResponseCmdHampGetVelocity
         */
        ResponseCmdHampGetVelocity.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHampGetVelocity)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHampGetVelocity();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.velocity != null)
                message.velocity = Number(object.velocity);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHampGetVelocity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampGetVelocity} message ResponseCmdHampGetVelocity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHampGetVelocity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.velocity = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                object.velocity = options.json && !isFinite(message.velocity) ? String(message.velocity) : message.velocity;
            return object;
        };

        /**
         * Converts this ResponseCmdHampGetVelocity to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHampGetVelocity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHampGetVelocity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHampGetVelocity;
    })();

    hdyRpc.RequestCmdHampSetVelocity = (function() {

        /**
         * Properties of a RequestCmdHampSetVelocity.
         * @memberof hdyRpc
         * @interface IRequestCmdHampSetVelocity
         * @property {number|null} [velocity] RequestCmdHampSetVelocity velocity
         */

        /**
         * Constructs a new RequestCmdHampSetVelocity.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHampSetVelocity.
         * @implements IRequestCmdHampSetVelocity
         * @constructor
         * @param {hdyRpc.IRequestCmdHampSetVelocity=} [properties] Properties to set
         */
        function RequestCmdHampSetVelocity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHampSetVelocity velocity.
         * @member {number} velocity
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @instance
         */
        RequestCmdHampSetVelocity.prototype.velocity = 0;

        /**
         * Creates a new RequestCmdHampSetVelocity instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {hdyRpc.IRequestCmdHampSetVelocity=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHampSetVelocity} RequestCmdHampSetVelocity instance
         */
        RequestCmdHampSetVelocity.create = function create(properties) {
            return new RequestCmdHampSetVelocity(properties);
        };

        /**
         * Encodes the specified RequestCmdHampSetVelocity message. Does not implicitly {@link hdyRpc.RequestCmdHampSetVelocity.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampSetVelocity} message RequestCmdHampSetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetVelocity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.velocity != null && Object.hasOwnProperty.call(message, "velocity"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.velocity);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHampSetVelocity message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHampSetVelocity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampSetVelocity} message RequestCmdHampSetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampSetVelocity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHampSetVelocity message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHampSetVelocity} RequestCmdHampSetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetVelocity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHampSetVelocity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.velocity = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHampSetVelocity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHampSetVelocity} RequestCmdHampSetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampSetVelocity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHampSetVelocity message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHampSetVelocity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                if (typeof message.velocity !== "number")
                    return "velocity: number expected";
            return null;
        };

        /**
         * Creates a RequestCmdHampSetVelocity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHampSetVelocity} RequestCmdHampSetVelocity
         */
        RequestCmdHampSetVelocity.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHampSetVelocity)
                return object;
            let message = new $root.hdyRpc.RequestCmdHampSetVelocity();
            if (object.velocity != null)
                message.velocity = Number(object.velocity);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHampSetVelocity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @static
         * @param {hdyRpc.RequestCmdHampSetVelocity} message RequestCmdHampSetVelocity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHampSetVelocity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.velocity = 0;
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                object.velocity = options.json && !isFinite(message.velocity) ? String(message.velocity) : message.velocity;
            return object;
        };

        /**
         * Converts this RequestCmdHampSetVelocity to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHampSetVelocity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHampSetVelocity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHampSetVelocity;
    })();

    hdyRpc.ResponseCmdHampSetVelocity = (function() {

        /**
         * Properties of a ResponseCmdHampSetVelocity.
         * @memberof hdyRpc
         * @interface IResponseCmdHampSetVelocity
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHampSetVelocity result
         */

        /**
         * Constructs a new ResponseCmdHampSetVelocity.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHampSetVelocity.
         * @implements IResponseCmdHampSetVelocity
         * @constructor
         * @param {hdyRpc.IResponseCmdHampSetVelocity=} [properties] Properties to set
         */
        function ResponseCmdHampSetVelocity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHampSetVelocity result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @instance
         */
        ResponseCmdHampSetVelocity.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHampSetVelocity instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {hdyRpc.IResponseCmdHampSetVelocity=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHampSetVelocity} ResponseCmdHampSetVelocity instance
         */
        ResponseCmdHampSetVelocity.create = function create(properties) {
            return new ResponseCmdHampSetVelocity(properties);
        };

        /**
         * Encodes the specified ResponseCmdHampSetVelocity message. Does not implicitly {@link hdyRpc.ResponseCmdHampSetVelocity.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampSetVelocity} message ResponseCmdHampSetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetVelocity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHampSetVelocity message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHampSetVelocity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampSetVelocity} message ResponseCmdHampSetVelocity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampSetVelocity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHampSetVelocity message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHampSetVelocity} ResponseCmdHampSetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetVelocity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHampSetVelocity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHampSetVelocity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHampSetVelocity} ResponseCmdHampSetVelocity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampSetVelocity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHampSetVelocity message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHampSetVelocity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHampSetVelocity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHampSetVelocity} ResponseCmdHampSetVelocity
         */
        ResponseCmdHampSetVelocity.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHampSetVelocity)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHampSetVelocity();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHampSetVelocity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @static
         * @param {hdyRpc.ResponseCmdHampSetVelocity} message ResponseCmdHampSetVelocity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHampSetVelocity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHampSetVelocity to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHampSetVelocity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHampSetVelocity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHampSetVelocity;
    })();

    hdyRpc.RequestCmdHampGetState = (function() {

        /**
         * Properties of a RequestCmdHampGetState.
         * @memberof hdyRpc
         * @interface IRequestCmdHampGetState
         */

        /**
         * Constructs a new RequestCmdHampGetState.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHampGetState.
         * @implements IRequestCmdHampGetState
         * @constructor
         * @param {hdyRpc.IRequestCmdHampGetState=} [properties] Properties to set
         */
        function RequestCmdHampGetState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdHampGetState instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {hdyRpc.IRequestCmdHampGetState=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHampGetState} RequestCmdHampGetState instance
         */
        RequestCmdHampGetState.create = function create(properties) {
            return new RequestCmdHampGetState(properties);
        };

        /**
         * Encodes the specified RequestCmdHampGetState message. Does not implicitly {@link hdyRpc.RequestCmdHampGetState.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {hdyRpc.RequestCmdHampGetState} message RequestCmdHampGetState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampGetState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHampGetState message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHampGetState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {hdyRpc.RequestCmdHampGetState} message RequestCmdHampGetState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHampGetState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHampGetState message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHampGetState} RequestCmdHampGetState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampGetState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHampGetState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHampGetState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHampGetState} RequestCmdHampGetState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHampGetState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHampGetState message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHampGetState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdHampGetState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHampGetState} RequestCmdHampGetState
         */
        RequestCmdHampGetState.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHampGetState)
                return object;
            return new $root.hdyRpc.RequestCmdHampGetState();
        };

        /**
         * Creates a plain object from a RequestCmdHampGetState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHampGetState
         * @static
         * @param {hdyRpc.RequestCmdHampGetState} message RequestCmdHampGetState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHampGetState.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdHampGetState to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHampGetState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHampGetState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHampGetState;
    })();

    hdyRpc.ResponseCmdHampGetState = (function() {

        /**
         * Properties of a ResponseCmdHampGetState.
         * @memberof hdyRpc
         * @interface IResponseCmdHampGetState
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHampGetState result
         * @property {hdyRpc.HampStates|null} [state] ResponseCmdHampGetState state
         */

        /**
         * Constructs a new ResponseCmdHampGetState.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHampGetState.
         * @implements IResponseCmdHampGetState
         * @constructor
         * @param {hdyRpc.IResponseCmdHampGetState=} [properties] Properties to set
         */
        function ResponseCmdHampGetState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHampGetState result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @instance
         */
        ResponseCmdHampGetState.prototype.result = 0;

        /**
         * ResponseCmdHampGetState state.
         * @member {hdyRpc.HampStates} state
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @instance
         */
        ResponseCmdHampGetState.prototype.state = 0;

        /**
         * Creates a new ResponseCmdHampGetState instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {hdyRpc.IResponseCmdHampGetState=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHampGetState} ResponseCmdHampGetState instance
         */
        ResponseCmdHampGetState.create = function create(properties) {
            return new ResponseCmdHampGetState(properties);
        };

        /**
         * Encodes the specified ResponseCmdHampGetState message. Does not implicitly {@link hdyRpc.ResponseCmdHampGetState.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {hdyRpc.ResponseCmdHampGetState} message ResponseCmdHampGetState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampGetState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHampGetState message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHampGetState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {hdyRpc.ResponseCmdHampGetState} message ResponseCmdHampGetState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHampGetState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHampGetState message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHampGetState} ResponseCmdHampGetState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampGetState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHampGetState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.state = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHampGetState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHampGetState} ResponseCmdHampGetState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHampGetState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHampGetState message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHampGetState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHampGetState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHampGetState} ResponseCmdHampGetState
         */
        ResponseCmdHampGetState.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHampGetState)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHampGetState();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            switch (object.state) {
            case "HAMP_STATE_STOPPED":
            case 0:
                message.state = 0;
                break;
            case "HAMP_STATE_RUNNING":
            case 1:
                message.state = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHampGetState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @static
         * @param {hdyRpc.ResponseCmdHampGetState} message ResponseCmdHampGetState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHampGetState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.state = options.enums === String ? "HAMP_STATE_STOPPED" : 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.hdyRpc.HampStates[message.state] : message.state;
            return object;
        };

        /**
         * Converts this ResponseCmdHampGetState to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHampGetState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHampGetState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHampGetState;
    })();

    hdyRpc.RequestCmdHdspSetXaVa = (function() {

        /**
         * Properties of a RequestCmdHdspSetXaVa.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXaVa
         * @property {number|null} [x] RequestCmdHdspSetXaVa x
         * @property {number|null} [v] RequestCmdHdspSetXaVa v
         */

        /**
         * Constructs a new RequestCmdHdspSetXaVa.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXaVa.
         * @implements IRequestCmdHdspSetXaVa
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXaVa=} [properties] Properties to set
         */
        function RequestCmdHdspSetXaVa(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXaVa x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @instance
         */
        RequestCmdHdspSetXaVa.prototype.x = 0;

        /**
         * RequestCmdHdspSetXaVa v.
         * @member {number} v
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @instance
         */
        RequestCmdHdspSetXaVa.prototype.v = 0;

        /**
         * Creates a new RequestCmdHdspSetXaVa instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXaVa=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXaVa} RequestCmdHdspSetXaVa instance
         */
        RequestCmdHdspSetXaVa.create = function create(properties) {
            return new RequestCmdHdspSetXaVa(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaVa message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaVa.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVa} message RequestCmdHdspSetXaVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaVa.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.v != null && Object.hasOwnProperty.call(message, "v"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.v);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaVa message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaVa.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVa} message RequestCmdHdspSetXaVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaVa.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXaVa message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXaVa} RequestCmdHdspSetXaVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaVa.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXaVa();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.v = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXaVa message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXaVa} RequestCmdHdspSetXaVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaVa.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXaVa message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXaVa.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.v != null && message.hasOwnProperty("v"))
                if (typeof message.v !== "number")
                    return "v: number expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXaVa message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXaVa} RequestCmdHdspSetXaVa
         */
        RequestCmdHdspSetXaVa.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXaVa)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXaVa();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.v != null)
                message.v = Number(object.v);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXaVa message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVa} message RequestCmdHdspSetXaVa
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXaVa.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.v = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.v != null && message.hasOwnProperty("v"))
                object.v = options.json && !isFinite(message.v) ? String(message.v) : message.v;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXaVa to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXaVa
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXaVa.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXaVa;
    })();

    hdyRpc.ResponseCmdHdspSetXaVa = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXaVa.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXaVa
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXaVa result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXaVa.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXaVa.
         * @implements IResponseCmdHdspSetXaVa
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXaVa=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXaVa(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXaVa result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @instance
         */
        ResponseCmdHdspSetXaVa.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXaVa instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXaVa=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXaVa} ResponseCmdHdspSetXaVa instance
         */
        ResponseCmdHdspSetXaVa.create = function create(properties) {
            return new ResponseCmdHdspSetXaVa(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaVa message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaVa.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVa} message ResponseCmdHdspSetXaVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaVa.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaVa message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaVa.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVa} message ResponseCmdHdspSetXaVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaVa.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXaVa message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXaVa} ResponseCmdHdspSetXaVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaVa.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXaVa();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXaVa message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXaVa} ResponseCmdHdspSetXaVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaVa.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXaVa message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXaVa.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXaVa message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXaVa} ResponseCmdHdspSetXaVa
         */
        ResponseCmdHdspSetXaVa.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXaVa)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXaVa();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXaVa message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVa} message ResponseCmdHdspSetXaVa
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXaVa.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXaVa to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXaVa
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXaVa.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXaVa;
    })();

    hdyRpc.RequestCmdHdspSetXpVa = (function() {

        /**
         * Properties of a RequestCmdHdspSetXpVa.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXpVa
         * @property {number|null} [x] RequestCmdHdspSetXpVa x
         * @property {number|null} [v] RequestCmdHdspSetXpVa v
         */

        /**
         * Constructs a new RequestCmdHdspSetXpVa.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXpVa.
         * @implements IRequestCmdHdspSetXpVa
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXpVa=} [properties] Properties to set
         */
        function RequestCmdHdspSetXpVa(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXpVa x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @instance
         */
        RequestCmdHdspSetXpVa.prototype.x = 0;

        /**
         * RequestCmdHdspSetXpVa v.
         * @member {number} v
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @instance
         */
        RequestCmdHdspSetXpVa.prototype.v = 0;

        /**
         * Creates a new RequestCmdHdspSetXpVa instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXpVa=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXpVa} RequestCmdHdspSetXpVa instance
         */
        RequestCmdHdspSetXpVa.create = function create(properties) {
            return new RequestCmdHdspSetXpVa(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpVa message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpVa.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVa} message RequestCmdHdspSetXpVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpVa.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.v != null && Object.hasOwnProperty.call(message, "v"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.v);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpVa message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpVa.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVa} message RequestCmdHdspSetXpVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpVa.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXpVa message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXpVa} RequestCmdHdspSetXpVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpVa.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXpVa();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.v = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXpVa message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXpVa} RequestCmdHdspSetXpVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpVa.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXpVa message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXpVa.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.v != null && message.hasOwnProperty("v"))
                if (typeof message.v !== "number")
                    return "v: number expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXpVa message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXpVa} RequestCmdHdspSetXpVa
         */
        RequestCmdHdspSetXpVa.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXpVa)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXpVa();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.v != null)
                message.v = Number(object.v);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXpVa message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVa} message RequestCmdHdspSetXpVa
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXpVa.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.v = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.v != null && message.hasOwnProperty("v"))
                object.v = options.json && !isFinite(message.v) ? String(message.v) : message.v;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXpVa to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXpVa
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXpVa.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXpVa;
    })();

    hdyRpc.ResponseCmdHdspSetXpVa = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXpVa.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXpVa
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXpVa result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXpVa.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXpVa.
         * @implements IResponseCmdHdspSetXpVa
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXpVa=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXpVa(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXpVa result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @instance
         */
        ResponseCmdHdspSetXpVa.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXpVa instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXpVa=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXpVa} ResponseCmdHdspSetXpVa instance
         */
        ResponseCmdHdspSetXpVa.create = function create(properties) {
            return new ResponseCmdHdspSetXpVa(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpVa message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpVa.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVa} message ResponseCmdHdspSetXpVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpVa.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpVa message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpVa.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVa} message ResponseCmdHdspSetXpVa message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpVa.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXpVa message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXpVa} ResponseCmdHdspSetXpVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpVa.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXpVa();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXpVa message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXpVa} ResponseCmdHdspSetXpVa
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpVa.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXpVa message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXpVa.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXpVa message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXpVa} ResponseCmdHdspSetXpVa
         */
        ResponseCmdHdspSetXpVa.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXpVa)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXpVa();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXpVa message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVa} message ResponseCmdHdspSetXpVa
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXpVa.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXpVa to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXpVa
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXpVa.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXpVa;
    })();

    hdyRpc.RequestCmdHdspSetXpVp = (function() {

        /**
         * Properties of a RequestCmdHdspSetXpVp.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXpVp
         * @property {number|null} [x] RequestCmdHdspSetXpVp x
         * @property {number|null} [v] RequestCmdHdspSetXpVp v
         */

        /**
         * Constructs a new RequestCmdHdspSetXpVp.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXpVp.
         * @implements IRequestCmdHdspSetXpVp
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXpVp=} [properties] Properties to set
         */
        function RequestCmdHdspSetXpVp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXpVp x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @instance
         */
        RequestCmdHdspSetXpVp.prototype.x = 0;

        /**
         * RequestCmdHdspSetXpVp v.
         * @member {number} v
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @instance
         */
        RequestCmdHdspSetXpVp.prototype.v = 0;

        /**
         * Creates a new RequestCmdHdspSetXpVp instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXpVp=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXpVp} RequestCmdHdspSetXpVp instance
         */
        RequestCmdHdspSetXpVp.create = function create(properties) {
            return new RequestCmdHdspSetXpVp(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpVp message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpVp.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVp} message RequestCmdHdspSetXpVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpVp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.v != null && Object.hasOwnProperty.call(message, "v"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.v);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpVp message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpVp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVp} message RequestCmdHdspSetXpVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpVp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXpVp message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXpVp} RequestCmdHdspSetXpVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpVp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXpVp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.v = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXpVp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXpVp} RequestCmdHdspSetXpVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpVp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXpVp message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXpVp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.v != null && message.hasOwnProperty("v"))
                if (typeof message.v !== "number")
                    return "v: number expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXpVp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXpVp} RequestCmdHdspSetXpVp
         */
        RequestCmdHdspSetXpVp.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXpVp)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXpVp();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.v != null)
                message.v = Number(object.v);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXpVp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpVp} message RequestCmdHdspSetXpVp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXpVp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.v = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.v != null && message.hasOwnProperty("v"))
                object.v = options.json && !isFinite(message.v) ? String(message.v) : message.v;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXpVp to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXpVp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXpVp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXpVp;
    })();

    hdyRpc.ResponseCmdHdspSetXpVp = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXpVp.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXpVp
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXpVp result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXpVp.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXpVp.
         * @implements IResponseCmdHdspSetXpVp
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXpVp=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXpVp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXpVp result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @instance
         */
        ResponseCmdHdspSetXpVp.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXpVp instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXpVp=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXpVp} ResponseCmdHdspSetXpVp instance
         */
        ResponseCmdHdspSetXpVp.create = function create(properties) {
            return new ResponseCmdHdspSetXpVp(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpVp message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpVp.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVp} message ResponseCmdHdspSetXpVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpVp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpVp message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpVp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVp} message ResponseCmdHdspSetXpVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpVp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXpVp message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXpVp} ResponseCmdHdspSetXpVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpVp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXpVp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXpVp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXpVp} ResponseCmdHdspSetXpVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpVp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXpVp message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXpVp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXpVp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXpVp} ResponseCmdHdspSetXpVp
         */
        ResponseCmdHdspSetXpVp.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXpVp)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXpVp();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXpVp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpVp} message ResponseCmdHdspSetXpVp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXpVp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXpVp to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXpVp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXpVp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXpVp;
    })();

    hdyRpc.RequestCmdHdspSetXaT = (function() {

        /**
         * Properties of a RequestCmdHdspSetXaT.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXaT
         * @property {number|null} [x] RequestCmdHdspSetXaT x
         * @property {number|null} [t] RequestCmdHdspSetXaT t
         */

        /**
         * Constructs a new RequestCmdHdspSetXaT.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXaT.
         * @implements IRequestCmdHdspSetXaT
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXaT=} [properties] Properties to set
         */
        function RequestCmdHdspSetXaT(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXaT x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @instance
         */
        RequestCmdHdspSetXaT.prototype.x = 0;

        /**
         * RequestCmdHdspSetXaT t.
         * @member {number} t
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @instance
         */
        RequestCmdHdspSetXaT.prototype.t = 0;

        /**
         * Creates a new RequestCmdHdspSetXaT instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXaT=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXaT} RequestCmdHdspSetXaT instance
         */
        RequestCmdHdspSetXaT.create = function create(properties) {
            return new RequestCmdHdspSetXaT(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaT message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaT.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaT} message RequestCmdHdspSetXaT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaT.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.t != null && Object.hasOwnProperty.call(message, "t"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.t);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaT message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaT.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaT} message RequestCmdHdspSetXaT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaT.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXaT message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXaT} RequestCmdHdspSetXaT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaT.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXaT();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.t = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXaT message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXaT} RequestCmdHdspSetXaT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaT.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXaT message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXaT.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.t != null && message.hasOwnProperty("t"))
                if (!$util.isInteger(message.t))
                    return "t: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXaT message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXaT} RequestCmdHdspSetXaT
         */
        RequestCmdHdspSetXaT.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXaT)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXaT();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.t != null)
                message.t = object.t >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXaT message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaT} message RequestCmdHdspSetXaT
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXaT.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.t = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.t != null && message.hasOwnProperty("t"))
                object.t = message.t;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXaT to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXaT
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXaT.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXaT;
    })();

    hdyRpc.ResponseCmdHdspSetXaT = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXaT.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXaT
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXaT result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXaT.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXaT.
         * @implements IResponseCmdHdspSetXaT
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXaT=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXaT(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXaT result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @instance
         */
        ResponseCmdHdspSetXaT.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXaT instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXaT=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXaT} ResponseCmdHdspSetXaT instance
         */
        ResponseCmdHdspSetXaT.create = function create(properties) {
            return new ResponseCmdHdspSetXaT(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaT message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaT.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaT} message ResponseCmdHdspSetXaT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaT.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaT message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaT.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaT} message ResponseCmdHdspSetXaT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaT.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXaT message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXaT} ResponseCmdHdspSetXaT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaT.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXaT();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXaT message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXaT} ResponseCmdHdspSetXaT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaT.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXaT message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXaT.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXaT message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXaT} ResponseCmdHdspSetXaT
         */
        ResponseCmdHdspSetXaT.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXaT)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXaT();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXaT message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaT} message ResponseCmdHdspSetXaT
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXaT.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXaT to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXaT
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXaT.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXaT;
    })();

    hdyRpc.RequestCmdHdspSetXpT = (function() {

        /**
         * Properties of a RequestCmdHdspSetXpT.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXpT
         * @property {number|null} [x] RequestCmdHdspSetXpT x
         * @property {number|null} [t] RequestCmdHdspSetXpT t
         */

        /**
         * Constructs a new RequestCmdHdspSetXpT.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXpT.
         * @implements IRequestCmdHdspSetXpT
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXpT=} [properties] Properties to set
         */
        function RequestCmdHdspSetXpT(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXpT x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @instance
         */
        RequestCmdHdspSetXpT.prototype.x = 0;

        /**
         * RequestCmdHdspSetXpT t.
         * @member {number} t
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @instance
         */
        RequestCmdHdspSetXpT.prototype.t = 0;

        /**
         * Creates a new RequestCmdHdspSetXpT instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXpT=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXpT} RequestCmdHdspSetXpT instance
         */
        RequestCmdHdspSetXpT.create = function create(properties) {
            return new RequestCmdHdspSetXpT(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpT message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpT.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpT} message RequestCmdHdspSetXpT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpT.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.t != null && Object.hasOwnProperty.call(message, "t"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.t);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXpT message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXpT.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpT} message RequestCmdHdspSetXpT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXpT.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXpT message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXpT} RequestCmdHdspSetXpT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpT.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXpT();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.t = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXpT message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXpT} RequestCmdHdspSetXpT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXpT.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXpT message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXpT.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.t != null && message.hasOwnProperty("t"))
                if (!$util.isInteger(message.t))
                    return "t: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXpT message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXpT} RequestCmdHdspSetXpT
         */
        RequestCmdHdspSetXpT.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXpT)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXpT();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.t != null)
                message.t = object.t >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXpT message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXpT} message RequestCmdHdspSetXpT
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXpT.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.t = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.t != null && message.hasOwnProperty("t"))
                object.t = message.t;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXpT to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXpT
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXpT.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXpT;
    })();

    hdyRpc.ResponseCmdHdspSetXpT = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXpT.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXpT
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXpT result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXpT.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXpT.
         * @implements IResponseCmdHdspSetXpT
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXpT=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXpT(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXpT result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @instance
         */
        ResponseCmdHdspSetXpT.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXpT instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXpT=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXpT} ResponseCmdHdspSetXpT instance
         */
        ResponseCmdHdspSetXpT.create = function create(properties) {
            return new ResponseCmdHdspSetXpT(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpT message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpT.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpT} message ResponseCmdHdspSetXpT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpT.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXpT message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXpT.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpT} message ResponseCmdHdspSetXpT message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXpT.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXpT message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXpT} ResponseCmdHdspSetXpT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpT.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXpT();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXpT message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXpT} ResponseCmdHdspSetXpT
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXpT.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXpT message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXpT.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXpT message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXpT} ResponseCmdHdspSetXpT
         */
        ResponseCmdHdspSetXpT.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXpT)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXpT();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXpT message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXpT} message ResponseCmdHdspSetXpT
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXpT.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXpT to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXpT
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXpT.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXpT;
    })();

    hdyRpc.RequestCmdHdspSetXaVp = (function() {

        /**
         * Properties of a RequestCmdHdspSetXaVp.
         * @memberof hdyRpc
         * @interface IRequestCmdHdspSetXaVp
         * @property {number|null} [x] RequestCmdHdspSetXaVp x
         * @property {number|null} [v] RequestCmdHdspSetXaVp v
         */

        /**
         * Constructs a new RequestCmdHdspSetXaVp.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHdspSetXaVp.
         * @implements IRequestCmdHdspSetXaVp
         * @constructor
         * @param {hdyRpc.IRequestCmdHdspSetXaVp=} [properties] Properties to set
         */
        function RequestCmdHdspSetXaVp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHdspSetXaVp x.
         * @member {number} x
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @instance
         */
        RequestCmdHdspSetXaVp.prototype.x = 0;

        /**
         * RequestCmdHdspSetXaVp v.
         * @member {number} v
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @instance
         */
        RequestCmdHdspSetXaVp.prototype.v = 0;

        /**
         * Creates a new RequestCmdHdspSetXaVp instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.IRequestCmdHdspSetXaVp=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHdspSetXaVp} RequestCmdHdspSetXaVp instance
         */
        RequestCmdHdspSetXaVp.create = function create(properties) {
            return new RequestCmdHdspSetXaVp(properties);
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaVp message. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaVp.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVp} message RequestCmdHdspSetXaVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaVp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.x);
            if (message.v != null && Object.hasOwnProperty.call(message, "v"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.v);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHdspSetXaVp message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHdspSetXaVp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVp} message RequestCmdHdspSetXaVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHdspSetXaVp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHdspSetXaVp message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHdspSetXaVp} RequestCmdHdspSetXaVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaVp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHdspSetXaVp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.x = reader.float();
                    break;
                case 2:
                    message.v = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHdspSetXaVp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHdspSetXaVp} RequestCmdHdspSetXaVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHdspSetXaVp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHdspSetXaVp message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHdspSetXaVp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.v != null && message.hasOwnProperty("v"))
                if (typeof message.v !== "number")
                    return "v: number expected";
            return null;
        };

        /**
         * Creates a RequestCmdHdspSetXaVp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHdspSetXaVp} RequestCmdHdspSetXaVp
         */
        RequestCmdHdspSetXaVp.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHdspSetXaVp)
                return object;
            let message = new $root.hdyRpc.RequestCmdHdspSetXaVp();
            if (object.x != null)
                message.x = Number(object.x);
            if (object.v != null)
                message.v = Number(object.v);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHdspSetXaVp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.RequestCmdHdspSetXaVp} message RequestCmdHdspSetXaVp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHdspSetXaVp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.v = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.v != null && message.hasOwnProperty("v"))
                object.v = options.json && !isFinite(message.v) ? String(message.v) : message.v;
            return object;
        };

        /**
         * Converts this RequestCmdHdspSetXaVp to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHdspSetXaVp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHdspSetXaVp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHdspSetXaVp;
    })();

    hdyRpc.ResponseCmdHdspSetXaVp = (function() {

        /**
         * Properties of a ResponseCmdHdspSetXaVp.
         * @memberof hdyRpc
         * @interface IResponseCmdHdspSetXaVp
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHdspSetXaVp result
         */

        /**
         * Constructs a new ResponseCmdHdspSetXaVp.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHdspSetXaVp.
         * @implements IResponseCmdHdspSetXaVp
         * @constructor
         * @param {hdyRpc.IResponseCmdHdspSetXaVp=} [properties] Properties to set
         */
        function ResponseCmdHdspSetXaVp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHdspSetXaVp result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @instance
         */
        ResponseCmdHdspSetXaVp.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHdspSetXaVp instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.IResponseCmdHdspSetXaVp=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHdspSetXaVp} ResponseCmdHdspSetXaVp instance
         */
        ResponseCmdHdspSetXaVp.create = function create(properties) {
            return new ResponseCmdHdspSetXaVp(properties);
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaVp message. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaVp.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVp} message ResponseCmdHdspSetXaVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaVp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHdspSetXaVp message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHdspSetXaVp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVp} message ResponseCmdHdspSetXaVp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHdspSetXaVp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHdspSetXaVp message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHdspSetXaVp} ResponseCmdHdspSetXaVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaVp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHdspSetXaVp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHdspSetXaVp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHdspSetXaVp} ResponseCmdHdspSetXaVp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHdspSetXaVp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHdspSetXaVp message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHdspSetXaVp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHdspSetXaVp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHdspSetXaVp} ResponseCmdHdspSetXaVp
         */
        ResponseCmdHdspSetXaVp.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHdspSetXaVp)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHdspSetXaVp();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHdspSetXaVp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @static
         * @param {hdyRpc.ResponseCmdHdspSetXaVp} message ResponseCmdHdspSetXaVp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHdspSetXaVp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHdspSetXaVp to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHdspSetXaVp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHdspSetXaVp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHdspSetXaVp;
    })();

    hdyRpc.RequestCmdHstpGetTime = (function() {

        /**
         * Properties of a RequestCmdHstpGetTime.
         * @memberof hdyRpc
         * @interface IRequestCmdHstpGetTime
         * @property {number|Long|null} [time] RequestCmdHstpGetTime time
         */

        /**
         * Constructs a new RequestCmdHstpGetTime.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHstpGetTime.
         * @implements IRequestCmdHstpGetTime
         * @constructor
         * @param {hdyRpc.IRequestCmdHstpGetTime=} [properties] Properties to set
         */
        function RequestCmdHstpGetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHstpGetTime time.
         * @member {number|Long} time
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @instance
         */
        RequestCmdHstpGetTime.prototype.time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new RequestCmdHstpGetTime instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {hdyRpc.IRequestCmdHstpGetTime=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHstpGetTime} RequestCmdHstpGetTime instance
         */
        RequestCmdHstpGetTime.create = function create(properties) {
            return new RequestCmdHstpGetTime(properties);
        };

        /**
         * Encodes the specified RequestCmdHstpGetTime message. Does not implicitly {@link hdyRpc.RequestCmdHstpGetTime.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {hdyRpc.RequestCmdHstpGetTime} message RequestCmdHstpGetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.time);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHstpGetTime message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHstpGetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {hdyRpc.RequestCmdHstpGetTime} message RequestCmdHstpGetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHstpGetTime message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHstpGetTime} RequestCmdHstpGetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHstpGetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.time = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHstpGetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHstpGetTime} RequestCmdHstpGetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHstpGetTime message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHstpGetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestCmdHstpGetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHstpGetTime} RequestCmdHstpGetTime
         */
        RequestCmdHstpGetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHstpGetTime)
                return object;
            let message = new $root.hdyRpc.RequestCmdHstpGetTime();
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = true;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHstpGetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @static
         * @param {hdyRpc.RequestCmdHstpGetTime} message RequestCmdHstpGetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHstpGetTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true) : message.time;
            return object;
        };

        /**
         * Converts this RequestCmdHstpGetTime to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHstpGetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHstpGetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHstpGetTime;
    })();

    hdyRpc.ResponseCmdHstpGetTime = (function() {

        /**
         * Properties of a ResponseCmdHstpGetTime.
         * @memberof hdyRpc
         * @interface IResponseCmdHstpGetTime
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHstpGetTime result
         * @property {number|Long|null} [time] ResponseCmdHstpGetTime time
         */

        /**
         * Constructs a new ResponseCmdHstpGetTime.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHstpGetTime.
         * @implements IResponseCmdHstpGetTime
         * @constructor
         * @param {hdyRpc.IResponseCmdHstpGetTime=} [properties] Properties to set
         */
        function ResponseCmdHstpGetTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHstpGetTime result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @instance
         */
        ResponseCmdHstpGetTime.prototype.result = 0;

        /**
         * ResponseCmdHstpGetTime time.
         * @member {number|Long} time
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @instance
         */
        ResponseCmdHstpGetTime.prototype.time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ResponseCmdHstpGetTime instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {hdyRpc.IResponseCmdHstpGetTime=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHstpGetTime} ResponseCmdHstpGetTime instance
         */
        ResponseCmdHstpGetTime.create = function create(properties) {
            return new ResponseCmdHstpGetTime(properties);
        };

        /**
         * Encodes the specified ResponseCmdHstpGetTime message. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetTime.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetTime} message ResponseCmdHstpGetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.time);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHstpGetTime message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetTime} message ResponseCmdHstpGetTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHstpGetTime message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHstpGetTime} ResponseCmdHstpGetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHstpGetTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.time = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHstpGetTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHstpGetTime} ResponseCmdHstpGetTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHstpGetTime message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHstpGetTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            return null;
        };

        /**
         * Creates a ResponseCmdHstpGetTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHstpGetTime} ResponseCmdHstpGetTime
         */
        ResponseCmdHstpGetTime.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHstpGetTime)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHstpGetTime();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = true;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHstpGetTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetTime} message ResponseCmdHstpGetTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHstpGetTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true) : message.time;
            return object;
        };

        /**
         * Converts this ResponseCmdHstpGetTime to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHstpGetTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHstpGetTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHstpGetTime;
    })();

    hdyRpc.RequestCmdHstpGetOffset = (function() {

        /**
         * Properties of a RequestCmdHstpGetOffset.
         * @memberof hdyRpc
         * @interface IRequestCmdHstpGetOffset
         * @property {number|null} [offset] RequestCmdHstpGetOffset offset
         */

        /**
         * Constructs a new RequestCmdHstpGetOffset.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHstpGetOffset.
         * @implements IRequestCmdHstpGetOffset
         * @constructor
         * @param {hdyRpc.IRequestCmdHstpGetOffset=} [properties] Properties to set
         */
        function RequestCmdHstpGetOffset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHstpGetOffset offset.
         * @member {number} offset
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @instance
         */
        RequestCmdHstpGetOffset.prototype.offset = 0;

        /**
         * Creates a new RequestCmdHstpGetOffset instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {hdyRpc.IRequestCmdHstpGetOffset=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHstpGetOffset} RequestCmdHstpGetOffset instance
         */
        RequestCmdHstpGetOffset.create = function create(properties) {
            return new RequestCmdHstpGetOffset(properties);
        };

        /**
         * Encodes the specified RequestCmdHstpGetOffset message. Does not implicitly {@link hdyRpc.RequestCmdHstpGetOffset.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpGetOffset} message RequestCmdHstpGetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetOffset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 1, wireType 0 =*/8).sint32(message.offset);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHstpGetOffset message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHstpGetOffset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpGetOffset} message RequestCmdHstpGetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetOffset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHstpGetOffset message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHstpGetOffset} RequestCmdHstpGetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetOffset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHstpGetOffset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.offset = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHstpGetOffset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHstpGetOffset} RequestCmdHstpGetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetOffset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHstpGetOffset message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHstpGetOffset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdHstpGetOffset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHstpGetOffset} RequestCmdHstpGetOffset
         */
        RequestCmdHstpGetOffset.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHstpGetOffset)
                return object;
            let message = new $root.hdyRpc.RequestCmdHstpGetOffset();
            if (object.offset != null)
                message.offset = object.offset | 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHstpGetOffset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpGetOffset} message RequestCmdHstpGetOffset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHstpGetOffset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.offset = 0;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            return object;
        };

        /**
         * Converts this RequestCmdHstpGetOffset to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHstpGetOffset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHstpGetOffset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHstpGetOffset;
    })();

    hdyRpc.ResponseCmdHstpGetOffset = (function() {

        /**
         * Properties of a ResponseCmdHstpGetOffset.
         * @memberof hdyRpc
         * @interface IResponseCmdHstpGetOffset
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHstpGetOffset result
         * @property {number|null} [offset] ResponseCmdHstpGetOffset offset
         */

        /**
         * Constructs a new ResponseCmdHstpGetOffset.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHstpGetOffset.
         * @implements IResponseCmdHstpGetOffset
         * @constructor
         * @param {hdyRpc.IResponseCmdHstpGetOffset=} [properties] Properties to set
         */
        function ResponseCmdHstpGetOffset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHstpGetOffset result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @instance
         */
        ResponseCmdHstpGetOffset.prototype.result = 0;

        /**
         * ResponseCmdHstpGetOffset offset.
         * @member {number} offset
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @instance
         */
        ResponseCmdHstpGetOffset.prototype.offset = 0;

        /**
         * Creates a new ResponseCmdHstpGetOffset instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {hdyRpc.IResponseCmdHstpGetOffset=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHstpGetOffset} ResponseCmdHstpGetOffset instance
         */
        ResponseCmdHstpGetOffset.create = function create(properties) {
            return new ResponseCmdHstpGetOffset(properties);
        };

        /**
         * Encodes the specified ResponseCmdHstpGetOffset message. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetOffset.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetOffset} message ResponseCmdHstpGetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetOffset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.offset);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHstpGetOffset message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetOffset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetOffset} message ResponseCmdHstpGetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetOffset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHstpGetOffset message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHstpGetOffset} ResponseCmdHstpGetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetOffset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHstpGetOffset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.offset = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHstpGetOffset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHstpGetOffset} ResponseCmdHstpGetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetOffset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHstpGetOffset message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHstpGetOffset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            return null;
        };

        /**
         * Creates a ResponseCmdHstpGetOffset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHstpGetOffset} ResponseCmdHstpGetOffset
         */
        ResponseCmdHstpGetOffset.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHstpGetOffset)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHstpGetOffset();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHstpGetOffset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetOffset} message ResponseCmdHstpGetOffset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHstpGetOffset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.offset = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            return object;
        };

        /**
         * Converts this ResponseCmdHstpGetOffset to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHstpGetOffset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHstpGetOffset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHstpGetOffset;
    })();

    hdyRpc.RequestCmdHstpSetOffset = (function() {

        /**
         * Properties of a RequestCmdHstpSetOffset.
         * @memberof hdyRpc
         * @interface IRequestCmdHstpSetOffset
         * @property {number|null} [offset] RequestCmdHstpSetOffset offset
         */

        /**
         * Constructs a new RequestCmdHstpSetOffset.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHstpSetOffset.
         * @implements IRequestCmdHstpSetOffset
         * @constructor
         * @param {hdyRpc.IRequestCmdHstpSetOffset=} [properties] Properties to set
         */
        function RequestCmdHstpSetOffset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHstpSetOffset offset.
         * @member {number} offset
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @instance
         */
        RequestCmdHstpSetOffset.prototype.offset = 0;

        /**
         * Creates a new RequestCmdHstpSetOffset instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {hdyRpc.IRequestCmdHstpSetOffset=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHstpSetOffset} RequestCmdHstpSetOffset instance
         */
        RequestCmdHstpSetOffset.create = function create(properties) {
            return new RequestCmdHstpSetOffset(properties);
        };

        /**
         * Encodes the specified RequestCmdHstpSetOffset message. Does not implicitly {@link hdyRpc.RequestCmdHstpSetOffset.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpSetOffset} message RequestCmdHstpSetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpSetOffset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 1, wireType 0 =*/8).sint32(message.offset);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHstpSetOffset message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHstpSetOffset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpSetOffset} message RequestCmdHstpSetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpSetOffset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHstpSetOffset message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHstpSetOffset} RequestCmdHstpSetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpSetOffset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHstpSetOffset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.offset = reader.sint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHstpSetOffset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHstpSetOffset} RequestCmdHstpSetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpSetOffset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHstpSetOffset message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHstpSetOffset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdHstpSetOffset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHstpSetOffset} RequestCmdHstpSetOffset
         */
        RequestCmdHstpSetOffset.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHstpSetOffset)
                return object;
            let message = new $root.hdyRpc.RequestCmdHstpSetOffset();
            if (object.offset != null)
                message.offset = object.offset | 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHstpSetOffset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @static
         * @param {hdyRpc.RequestCmdHstpSetOffset} message RequestCmdHstpSetOffset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHstpSetOffset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.offset = 0;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            return object;
        };

        /**
         * Converts this RequestCmdHstpSetOffset to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHstpSetOffset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHstpSetOffset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHstpSetOffset;
    })();

    hdyRpc.ResponseCmdHstpSetOffset = (function() {

        /**
         * Properties of a ResponseCmdHstpSetOffset.
         * @memberof hdyRpc
         * @interface IResponseCmdHstpSetOffset
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHstpSetOffset result
         */

        /**
         * Constructs a new ResponseCmdHstpSetOffset.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHstpSetOffset.
         * @implements IResponseCmdHstpSetOffset
         * @constructor
         * @param {hdyRpc.IResponseCmdHstpSetOffset=} [properties] Properties to set
         */
        function ResponseCmdHstpSetOffset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHstpSetOffset result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @instance
         */
        ResponseCmdHstpSetOffset.prototype.result = 0;

        /**
         * Creates a new ResponseCmdHstpSetOffset instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {hdyRpc.IResponseCmdHstpSetOffset=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHstpSetOffset} ResponseCmdHstpSetOffset instance
         */
        ResponseCmdHstpSetOffset.create = function create(properties) {
            return new ResponseCmdHstpSetOffset(properties);
        };

        /**
         * Encodes the specified ResponseCmdHstpSetOffset message. Does not implicitly {@link hdyRpc.ResponseCmdHstpSetOffset.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetOffset} message ResponseCmdHstpSetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpSetOffset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHstpSetOffset message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHstpSetOffset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetOffset} message ResponseCmdHstpSetOffset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpSetOffset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHstpSetOffset message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHstpSetOffset} ResponseCmdHstpSetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpSetOffset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHstpSetOffset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHstpSetOffset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHstpSetOffset} ResponseCmdHstpSetOffset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpSetOffset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHstpSetOffset message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHstpSetOffset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdHstpSetOffset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHstpSetOffset} ResponseCmdHstpSetOffset
         */
        ResponseCmdHstpSetOffset.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHstpSetOffset)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHstpSetOffset();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHstpSetOffset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetOffset} message ResponseCmdHstpSetOffset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHstpSetOffset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdHstpSetOffset to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHstpSetOffset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHstpSetOffset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHstpSetOffset;
    })();

    hdyRpc.RequestCmdHstpGetRtd = (function() {

        /**
         * Properties of a RequestCmdHstpGetRtd.
         * @memberof hdyRpc
         * @interface IRequestCmdHstpGetRtd
         */

        /**
         * Constructs a new RequestCmdHstpGetRtd.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHstpGetRtd.
         * @implements IRequestCmdHstpGetRtd
         * @constructor
         * @param {hdyRpc.IRequestCmdHstpGetRtd=} [properties] Properties to set
         */
        function RequestCmdHstpGetRtd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdHstpGetRtd instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {hdyRpc.IRequestCmdHstpGetRtd=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHstpGetRtd} RequestCmdHstpGetRtd instance
         */
        RequestCmdHstpGetRtd.create = function create(properties) {
            return new RequestCmdHstpGetRtd(properties);
        };

        /**
         * Encodes the specified RequestCmdHstpGetRtd message. Does not implicitly {@link hdyRpc.RequestCmdHstpGetRtd.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {hdyRpc.RequestCmdHstpGetRtd} message RequestCmdHstpGetRtd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetRtd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHstpGetRtd message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHstpGetRtd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {hdyRpc.RequestCmdHstpGetRtd} message RequestCmdHstpGetRtd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpGetRtd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHstpGetRtd message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHstpGetRtd} RequestCmdHstpGetRtd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetRtd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHstpGetRtd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHstpGetRtd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHstpGetRtd} RequestCmdHstpGetRtd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpGetRtd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHstpGetRtd message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHstpGetRtd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdHstpGetRtd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHstpGetRtd} RequestCmdHstpGetRtd
         */
        RequestCmdHstpGetRtd.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHstpGetRtd)
                return object;
            return new $root.hdyRpc.RequestCmdHstpGetRtd();
        };

        /**
         * Creates a plain object from a RequestCmdHstpGetRtd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @static
         * @param {hdyRpc.RequestCmdHstpGetRtd} message RequestCmdHstpGetRtd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHstpGetRtd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdHstpGetRtd to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHstpGetRtd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHstpGetRtd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHstpGetRtd;
    })();

    hdyRpc.ResponseCmdHstpGetRtd = (function() {

        /**
         * Properties of a ResponseCmdHstpGetRtd.
         * @memberof hdyRpc
         * @interface IResponseCmdHstpGetRtd
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHstpGetRtd result
         * @property {number|null} [rtd] ResponseCmdHstpGetRtd rtd
         */

        /**
         * Constructs a new ResponseCmdHstpGetRtd.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHstpGetRtd.
         * @implements IResponseCmdHstpGetRtd
         * @constructor
         * @param {hdyRpc.IResponseCmdHstpGetRtd=} [properties] Properties to set
         */
        function ResponseCmdHstpGetRtd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHstpGetRtd result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @instance
         */
        ResponseCmdHstpGetRtd.prototype.result = 0;

        /**
         * ResponseCmdHstpGetRtd rtd.
         * @member {number} rtd
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @instance
         */
        ResponseCmdHstpGetRtd.prototype.rtd = 0;

        /**
         * Creates a new ResponseCmdHstpGetRtd instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {hdyRpc.IResponseCmdHstpGetRtd=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHstpGetRtd} ResponseCmdHstpGetRtd instance
         */
        ResponseCmdHstpGetRtd.create = function create(properties) {
            return new ResponseCmdHstpGetRtd(properties);
        };

        /**
         * Encodes the specified ResponseCmdHstpGetRtd message. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetRtd.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetRtd} message ResponseCmdHstpGetRtd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetRtd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.rtd != null && Object.hasOwnProperty.call(message, "rtd"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.rtd);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHstpGetRtd message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHstpGetRtd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetRtd} message ResponseCmdHstpGetRtd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpGetRtd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHstpGetRtd message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHstpGetRtd} ResponseCmdHstpGetRtd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetRtd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHstpGetRtd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.rtd = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHstpGetRtd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHstpGetRtd} ResponseCmdHstpGetRtd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpGetRtd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHstpGetRtd message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHstpGetRtd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.rtd != null && message.hasOwnProperty("rtd"))
                if (!$util.isInteger(message.rtd))
                    return "rtd: integer expected";
            return null;
        };

        /**
         * Creates a ResponseCmdHstpGetRtd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHstpGetRtd} ResponseCmdHstpGetRtd
         */
        ResponseCmdHstpGetRtd.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHstpGetRtd)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHstpGetRtd();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.rtd != null)
                message.rtd = object.rtd >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHstpGetRtd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @static
         * @param {hdyRpc.ResponseCmdHstpGetRtd} message ResponseCmdHstpGetRtd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHstpGetRtd.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.rtd = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.rtd != null && message.hasOwnProperty("rtd"))
                object.rtd = message.rtd;
            return object;
        };

        /**
         * Converts this ResponseCmdHstpGetRtd to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHstpGetRtd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHstpGetRtd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHstpGetRtd;
    })();

    hdyRpc.RequestCmdHstpSetSync = (function() {

        /**
         * Properties of a RequestCmdHstpSetSync.
         * @memberof hdyRpc
         * @interface IRequestCmdHstpSetSync
         * @property {number|null} [syncCount] RequestCmdHstpSetSync syncCount
         * @property {number|null} [outliers] RequestCmdHstpSetSync outliers
         */

        /**
         * Constructs a new RequestCmdHstpSetSync.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdHstpSetSync.
         * @implements IRequestCmdHstpSetSync
         * @constructor
         * @param {hdyRpc.IRequestCmdHstpSetSync=} [properties] Properties to set
         */
        function RequestCmdHstpSetSync(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdHstpSetSync syncCount.
         * @member {number} syncCount
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @instance
         */
        RequestCmdHstpSetSync.prototype.syncCount = 0;

        /**
         * RequestCmdHstpSetSync outliers.
         * @member {number} outliers
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @instance
         */
        RequestCmdHstpSetSync.prototype.outliers = 0;

        /**
         * Creates a new RequestCmdHstpSetSync instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {hdyRpc.IRequestCmdHstpSetSync=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdHstpSetSync} RequestCmdHstpSetSync instance
         */
        RequestCmdHstpSetSync.create = function create(properties) {
            return new RequestCmdHstpSetSync(properties);
        };

        /**
         * Encodes the specified RequestCmdHstpSetSync message. Does not implicitly {@link hdyRpc.RequestCmdHstpSetSync.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {hdyRpc.RequestCmdHstpSetSync} message RequestCmdHstpSetSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpSetSync.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncCount != null && Object.hasOwnProperty.call(message, "syncCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.syncCount);
            if (message.outliers != null && Object.hasOwnProperty.call(message, "outliers"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.outliers);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdHstpSetSync message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdHstpSetSync.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {hdyRpc.RequestCmdHstpSetSync} message RequestCmdHstpSetSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdHstpSetSync.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdHstpSetSync message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdHstpSetSync} RequestCmdHstpSetSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpSetSync.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdHstpSetSync();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncCount = reader.uint32();
                    break;
                case 2:
                    message.outliers = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdHstpSetSync message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdHstpSetSync} RequestCmdHstpSetSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdHstpSetSync.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdHstpSetSync message.
         * @function verify
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdHstpSetSync.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncCount != null && message.hasOwnProperty("syncCount"))
                if (!$util.isInteger(message.syncCount))
                    return "syncCount: integer expected";
            if (message.outliers != null && message.hasOwnProperty("outliers"))
                if (!$util.isInteger(message.outliers))
                    return "outliers: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdHstpSetSync message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdHstpSetSync} RequestCmdHstpSetSync
         */
        RequestCmdHstpSetSync.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdHstpSetSync)
                return object;
            let message = new $root.hdyRpc.RequestCmdHstpSetSync();
            if (object.syncCount != null)
                message.syncCount = object.syncCount >>> 0;
            if (object.outliers != null)
                message.outliers = object.outliers >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdHstpSetSync message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @static
         * @param {hdyRpc.RequestCmdHstpSetSync} message RequestCmdHstpSetSync
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdHstpSetSync.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.syncCount = 0;
                object.outliers = 0;
            }
            if (message.syncCount != null && message.hasOwnProperty("syncCount"))
                object.syncCount = message.syncCount;
            if (message.outliers != null && message.hasOwnProperty("outliers"))
                object.outliers = message.outliers;
            return object;
        };

        /**
         * Converts this RequestCmdHstpSetSync to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdHstpSetSync
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdHstpSetSync.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdHstpSetSync;
    })();

    hdyRpc.ResponseCmdHstpSetSync = (function() {

        /**
         * Properties of a ResponseCmdHstpSetSync.
         * @memberof hdyRpc
         * @interface IResponseCmdHstpSetSync
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdHstpSetSync result
         * @property {number|Long|null} [time] ResponseCmdHstpSetSync time
         * @property {number|null} [rtd] ResponseCmdHstpSetSync rtd
         */

        /**
         * Constructs a new ResponseCmdHstpSetSync.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdHstpSetSync.
         * @implements IResponseCmdHstpSetSync
         * @constructor
         * @param {hdyRpc.IResponseCmdHstpSetSync=} [properties] Properties to set
         */
        function ResponseCmdHstpSetSync(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdHstpSetSync result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @instance
         */
        ResponseCmdHstpSetSync.prototype.result = 0;

        /**
         * ResponseCmdHstpSetSync time.
         * @member {number|Long} time
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @instance
         */
        ResponseCmdHstpSetSync.prototype.time = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ResponseCmdHstpSetSync rtd.
         * @member {number} rtd
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @instance
         */
        ResponseCmdHstpSetSync.prototype.rtd = 0;

        /**
         * Creates a new ResponseCmdHstpSetSync instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {hdyRpc.IResponseCmdHstpSetSync=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdHstpSetSync} ResponseCmdHstpSetSync instance
         */
        ResponseCmdHstpSetSync.create = function create(properties) {
            return new ResponseCmdHstpSetSync(properties);
        };

        /**
         * Encodes the specified ResponseCmdHstpSetSync message. Does not implicitly {@link hdyRpc.ResponseCmdHstpSetSync.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetSync} message ResponseCmdHstpSetSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpSetSync.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.time);
            if (message.rtd != null && Object.hasOwnProperty.call(message, "rtd"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.rtd);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdHstpSetSync message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdHstpSetSync.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetSync} message ResponseCmdHstpSetSync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdHstpSetSync.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdHstpSetSync message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdHstpSetSync} ResponseCmdHstpSetSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpSetSync.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdHstpSetSync();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.time = reader.uint64();
                    break;
                case 3:
                    message.rtd = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdHstpSetSync message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdHstpSetSync} ResponseCmdHstpSetSync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdHstpSetSync.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdHstpSetSync message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdHstpSetSync.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            if (message.rtd != null && message.hasOwnProperty("rtd"))
                if (!$util.isInteger(message.rtd))
                    return "rtd: integer expected";
            return null;
        };

        /**
         * Creates a ResponseCmdHstpSetSync message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdHstpSetSync} ResponseCmdHstpSetSync
         */
        ResponseCmdHstpSetSync.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdHstpSetSync)
                return object;
            let message = new $root.hdyRpc.ResponseCmdHstpSetSync();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = true;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
            if (object.rtd != null)
                message.rtd = object.rtd >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdHstpSetSync message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @static
         * @param {hdyRpc.ResponseCmdHstpSetSync} message ResponseCmdHstpSetSync
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdHstpSetSync.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
                object.rtd = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true) : message.time;
            if (message.rtd != null && message.hasOwnProperty("rtd"))
                object.rtd = message.rtd;
            return object;
        };

        /**
         * Converts this ResponseCmdHstpSetSync to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdHstpSetSync
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdHstpSetSync.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdHstpSetSync;
    })();

    hdyRpc.RequestCmdMaintanceSetRestart = (function() {

        /**
         * Properties of a RequestCmdMaintanceSetRestart.
         * @memberof hdyRpc
         * @interface IRequestCmdMaintanceSetRestart
         * @property {boolean|null} [reconnect] RequestCmdMaintanceSetRestart reconnect
         */

        /**
         * Constructs a new RequestCmdMaintanceSetRestart.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdMaintanceSetRestart.
         * @implements IRequestCmdMaintanceSetRestart
         * @constructor
         * @param {hdyRpc.IRequestCmdMaintanceSetRestart=} [properties] Properties to set
         */
        function RequestCmdMaintanceSetRestart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdMaintanceSetRestart reconnect.
         * @member {boolean} reconnect
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @instance
         */
        RequestCmdMaintanceSetRestart.prototype.reconnect = false;

        /**
         * Creates a new RequestCmdMaintanceSetRestart instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.IRequestCmdMaintanceSetRestart=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdMaintanceSetRestart} RequestCmdMaintanceSetRestart instance
         */
        RequestCmdMaintanceSetRestart.create = function create(properties) {
            return new RequestCmdMaintanceSetRestart(properties);
        };

        /**
         * Encodes the specified RequestCmdMaintanceSetRestart message. Does not implicitly {@link hdyRpc.RequestCmdMaintanceSetRestart.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetRestart} message RequestCmdMaintanceSetRestart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceSetRestart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reconnect != null && Object.hasOwnProperty.call(message, "reconnect"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.reconnect);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdMaintanceSetRestart message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdMaintanceSetRestart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetRestart} message RequestCmdMaintanceSetRestart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceSetRestart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdMaintanceSetRestart message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdMaintanceSetRestart} RequestCmdMaintanceSetRestart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceSetRestart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdMaintanceSetRestart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.reconnect = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdMaintanceSetRestart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdMaintanceSetRestart} RequestCmdMaintanceSetRestart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceSetRestart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdMaintanceSetRestart message.
         * @function verify
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdMaintanceSetRestart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reconnect != null && message.hasOwnProperty("reconnect"))
                if (typeof message.reconnect !== "boolean")
                    return "reconnect: boolean expected";
            return null;
        };

        /**
         * Creates a RequestCmdMaintanceSetRestart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdMaintanceSetRestart} RequestCmdMaintanceSetRestart
         */
        RequestCmdMaintanceSetRestart.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdMaintanceSetRestart)
                return object;
            let message = new $root.hdyRpc.RequestCmdMaintanceSetRestart();
            if (object.reconnect != null)
                message.reconnect = Boolean(object.reconnect);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdMaintanceSetRestart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetRestart} message RequestCmdMaintanceSetRestart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdMaintanceSetRestart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.reconnect = false;
            if (message.reconnect != null && message.hasOwnProperty("reconnect"))
                object.reconnect = message.reconnect;
            return object;
        };

        /**
         * Converts this RequestCmdMaintanceSetRestart to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdMaintanceSetRestart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdMaintanceSetRestart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdMaintanceSetRestart;
    })();

    hdyRpc.ResponseCmdMaintanceSetRestart = (function() {

        /**
         * Properties of a ResponseCmdMaintanceSetRestart.
         * @memberof hdyRpc
         * @interface IResponseCmdMaintanceSetRestart
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdMaintanceSetRestart result
         */

        /**
         * Constructs a new ResponseCmdMaintanceSetRestart.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdMaintanceSetRestart.
         * @implements IResponseCmdMaintanceSetRestart
         * @constructor
         * @param {hdyRpc.IResponseCmdMaintanceSetRestart=} [properties] Properties to set
         */
        function ResponseCmdMaintanceSetRestart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdMaintanceSetRestart result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @instance
         */
        ResponseCmdMaintanceSetRestart.prototype.result = 0;

        /**
         * Creates a new ResponseCmdMaintanceSetRestart instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.IResponseCmdMaintanceSetRestart=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdMaintanceSetRestart} ResponseCmdMaintanceSetRestart instance
         */
        ResponseCmdMaintanceSetRestart.create = function create(properties) {
            return new ResponseCmdMaintanceSetRestart(properties);
        };

        /**
         * Encodes the specified ResponseCmdMaintanceSetRestart message. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceSetRestart.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetRestart} message ResponseCmdMaintanceSetRestart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceSetRestart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdMaintanceSetRestart message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceSetRestart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetRestart} message ResponseCmdMaintanceSetRestart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceSetRestart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdMaintanceSetRestart message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdMaintanceSetRestart} ResponseCmdMaintanceSetRestart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceSetRestart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdMaintanceSetRestart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdMaintanceSetRestart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdMaintanceSetRestart} ResponseCmdMaintanceSetRestart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceSetRestart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdMaintanceSetRestart message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdMaintanceSetRestart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdMaintanceSetRestart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdMaintanceSetRestart} ResponseCmdMaintanceSetRestart
         */
        ResponseCmdMaintanceSetRestart.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdMaintanceSetRestart)
                return object;
            let message = new $root.hdyRpc.ResponseCmdMaintanceSetRestart();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdMaintanceSetRestart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetRestart} message ResponseCmdMaintanceSetRestart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdMaintanceSetRestart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdMaintanceSetRestart to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdMaintanceSetRestart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdMaintanceSetRestart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdMaintanceSetRestart;
    })();

    hdyRpc.RequestCmdMaintanceSetUpdatePerform = (function() {

        /**
         * Properties of a RequestCmdMaintanceSetUpdatePerform.
         * @memberof hdyRpc
         * @interface IRequestCmdMaintanceSetUpdatePerform
         * @property {string|null} [fwVersion] RequestCmdMaintanceSetUpdatePerform fwVersion
         */

        /**
         * Constructs a new RequestCmdMaintanceSetUpdatePerform.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdMaintanceSetUpdatePerform.
         * @implements IRequestCmdMaintanceSetUpdatePerform
         * @constructor
         * @param {hdyRpc.IRequestCmdMaintanceSetUpdatePerform=} [properties] Properties to set
         */
        function RequestCmdMaintanceSetUpdatePerform(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdMaintanceSetUpdatePerform fwVersion.
         * @member {string} fwVersion
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @instance
         */
        RequestCmdMaintanceSetUpdatePerform.prototype.fwVersion = "";

        /**
         * Creates a new RequestCmdMaintanceSetUpdatePerform instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.IRequestCmdMaintanceSetUpdatePerform=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdMaintanceSetUpdatePerform} RequestCmdMaintanceSetUpdatePerform instance
         */
        RequestCmdMaintanceSetUpdatePerform.create = function create(properties) {
            return new RequestCmdMaintanceSetUpdatePerform(properties);
        };

        /**
         * Encodes the specified RequestCmdMaintanceSetUpdatePerform message. Does not implicitly {@link hdyRpc.RequestCmdMaintanceSetUpdatePerform.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetUpdatePerform} message RequestCmdMaintanceSetUpdatePerform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceSetUpdatePerform.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fwVersion != null && Object.hasOwnProperty.call(message, "fwVersion"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fwVersion);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdMaintanceSetUpdatePerform message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdMaintanceSetUpdatePerform.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetUpdatePerform} message RequestCmdMaintanceSetUpdatePerform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceSetUpdatePerform.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdMaintanceSetUpdatePerform message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdMaintanceSetUpdatePerform} RequestCmdMaintanceSetUpdatePerform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceSetUpdatePerform.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdMaintanceSetUpdatePerform();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fwVersion = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdMaintanceSetUpdatePerform message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdMaintanceSetUpdatePerform} RequestCmdMaintanceSetUpdatePerform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceSetUpdatePerform.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdMaintanceSetUpdatePerform message.
         * @function verify
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdMaintanceSetUpdatePerform.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fwVersion != null && message.hasOwnProperty("fwVersion"))
                if (!$util.isString(message.fwVersion))
                    return "fwVersion: string expected";
            return null;
        };

        /**
         * Creates a RequestCmdMaintanceSetUpdatePerform message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdMaintanceSetUpdatePerform} RequestCmdMaintanceSetUpdatePerform
         */
        RequestCmdMaintanceSetUpdatePerform.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdMaintanceSetUpdatePerform)
                return object;
            let message = new $root.hdyRpc.RequestCmdMaintanceSetUpdatePerform();
            if (object.fwVersion != null)
                message.fwVersion = String(object.fwVersion);
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdMaintanceSetUpdatePerform message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.RequestCmdMaintanceSetUpdatePerform} message RequestCmdMaintanceSetUpdatePerform
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdMaintanceSetUpdatePerform.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fwVersion = "";
            if (message.fwVersion != null && message.hasOwnProperty("fwVersion"))
                object.fwVersion = message.fwVersion;
            return object;
        };

        /**
         * Converts this RequestCmdMaintanceSetUpdatePerform to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdMaintanceSetUpdatePerform
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdMaintanceSetUpdatePerform.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdMaintanceSetUpdatePerform;
    })();

    hdyRpc.ResponseCmdMaintanceSetUpdatePerform = (function() {

        /**
         * Properties of a ResponseCmdMaintanceSetUpdatePerform.
         * @memberof hdyRpc
         * @interface IResponseCmdMaintanceSetUpdatePerform
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdMaintanceSetUpdatePerform result
         */

        /**
         * Constructs a new ResponseCmdMaintanceSetUpdatePerform.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdMaintanceSetUpdatePerform.
         * @implements IResponseCmdMaintanceSetUpdatePerform
         * @constructor
         * @param {hdyRpc.IResponseCmdMaintanceSetUpdatePerform=} [properties] Properties to set
         */
        function ResponseCmdMaintanceSetUpdatePerform(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdMaintanceSetUpdatePerform result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @instance
         */
        ResponseCmdMaintanceSetUpdatePerform.prototype.result = 0;

        /**
         * Creates a new ResponseCmdMaintanceSetUpdatePerform instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.IResponseCmdMaintanceSetUpdatePerform=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} ResponseCmdMaintanceSetUpdatePerform instance
         */
        ResponseCmdMaintanceSetUpdatePerform.create = function create(properties) {
            return new ResponseCmdMaintanceSetUpdatePerform(properties);
        };

        /**
         * Encodes the specified ResponseCmdMaintanceSetUpdatePerform message. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceSetUpdatePerform.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} message ResponseCmdMaintanceSetUpdatePerform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceSetUpdatePerform.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdMaintanceSetUpdatePerform message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceSetUpdatePerform.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} message ResponseCmdMaintanceSetUpdatePerform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceSetUpdatePerform.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdMaintanceSetUpdatePerform message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} ResponseCmdMaintanceSetUpdatePerform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceSetUpdatePerform.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdMaintanceSetUpdatePerform();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdMaintanceSetUpdatePerform message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} ResponseCmdMaintanceSetUpdatePerform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceSetUpdatePerform.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdMaintanceSetUpdatePerform message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdMaintanceSetUpdatePerform.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdMaintanceSetUpdatePerform message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} ResponseCmdMaintanceSetUpdatePerform
         */
        ResponseCmdMaintanceSetUpdatePerform.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdMaintanceSetUpdatePerform)
                return object;
            let message = new $root.hdyRpc.ResponseCmdMaintanceSetUpdatePerform();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdMaintanceSetUpdatePerform message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceSetUpdatePerform} message ResponseCmdMaintanceSetUpdatePerform
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdMaintanceSetUpdatePerform.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdMaintanceSetUpdatePerform to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdMaintanceSetUpdatePerform
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdMaintanceSetUpdatePerform.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdMaintanceSetUpdatePerform;
    })();

    hdyRpc.RequestCmdMaintanceGetUpdateStatus = (function() {

        /**
         * Properties of a RequestCmdMaintanceGetUpdateStatus.
         * @memberof hdyRpc
         * @interface IRequestCmdMaintanceGetUpdateStatus
         */

        /**
         * Constructs a new RequestCmdMaintanceGetUpdateStatus.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdMaintanceGetUpdateStatus.
         * @implements IRequestCmdMaintanceGetUpdateStatus
         * @constructor
         * @param {hdyRpc.IRequestCmdMaintanceGetUpdateStatus=} [properties] Properties to set
         */
        function RequestCmdMaintanceGetUpdateStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdMaintanceGetUpdateStatus instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.IRequestCmdMaintanceGetUpdateStatus=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdMaintanceGetUpdateStatus} RequestCmdMaintanceGetUpdateStatus instance
         */
        RequestCmdMaintanceGetUpdateStatus.create = function create(properties) {
            return new RequestCmdMaintanceGetUpdateStatus(properties);
        };

        /**
         * Encodes the specified RequestCmdMaintanceGetUpdateStatus message. Does not implicitly {@link hdyRpc.RequestCmdMaintanceGetUpdateStatus.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.RequestCmdMaintanceGetUpdateStatus} message RequestCmdMaintanceGetUpdateStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceGetUpdateStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdMaintanceGetUpdateStatus message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdMaintanceGetUpdateStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.RequestCmdMaintanceGetUpdateStatus} message RequestCmdMaintanceGetUpdateStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdMaintanceGetUpdateStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdMaintanceGetUpdateStatus message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdMaintanceGetUpdateStatus} RequestCmdMaintanceGetUpdateStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceGetUpdateStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdMaintanceGetUpdateStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdMaintanceGetUpdateStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdMaintanceGetUpdateStatus} RequestCmdMaintanceGetUpdateStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdMaintanceGetUpdateStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdMaintanceGetUpdateStatus message.
         * @function verify
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdMaintanceGetUpdateStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdMaintanceGetUpdateStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdMaintanceGetUpdateStatus} RequestCmdMaintanceGetUpdateStatus
         */
        RequestCmdMaintanceGetUpdateStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdMaintanceGetUpdateStatus)
                return object;
            return new $root.hdyRpc.RequestCmdMaintanceGetUpdateStatus();
        };

        /**
         * Creates a plain object from a RequestCmdMaintanceGetUpdateStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.RequestCmdMaintanceGetUpdateStatus} message RequestCmdMaintanceGetUpdateStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdMaintanceGetUpdateStatus.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdMaintanceGetUpdateStatus to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdMaintanceGetUpdateStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdMaintanceGetUpdateStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdMaintanceGetUpdateStatus;
    })();

    hdyRpc.ResponseCmdMaintanceGetUpdateStatus = (function() {

        /**
         * Properties of a ResponseCmdMaintanceGetUpdateStatus.
         * @memberof hdyRpc
         * @interface IResponseCmdMaintanceGetUpdateStatus
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdMaintanceGetUpdateStatus result
         * @property {number|null} [percentComplete] ResponseCmdMaintanceGetUpdateStatus percentComplete
         */

        /**
         * Constructs a new ResponseCmdMaintanceGetUpdateStatus.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdMaintanceGetUpdateStatus.
         * @implements IResponseCmdMaintanceGetUpdateStatus
         * @constructor
         * @param {hdyRpc.IResponseCmdMaintanceGetUpdateStatus=} [properties] Properties to set
         */
        function ResponseCmdMaintanceGetUpdateStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdMaintanceGetUpdateStatus result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @instance
         */
        ResponseCmdMaintanceGetUpdateStatus.prototype.result = 0;

        /**
         * ResponseCmdMaintanceGetUpdateStatus percentComplete.
         * @member {number} percentComplete
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @instance
         */
        ResponseCmdMaintanceGetUpdateStatus.prototype.percentComplete = 0;

        /**
         * Creates a new ResponseCmdMaintanceGetUpdateStatus instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.IResponseCmdMaintanceGetUpdateStatus=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} ResponseCmdMaintanceGetUpdateStatus instance
         */
        ResponseCmdMaintanceGetUpdateStatus.create = function create(properties) {
            return new ResponseCmdMaintanceGetUpdateStatus(properties);
        };

        /**
         * Encodes the specified ResponseCmdMaintanceGetUpdateStatus message. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceGetUpdateStatus.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} message ResponseCmdMaintanceGetUpdateStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceGetUpdateStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.percentComplete != null && Object.hasOwnProperty.call(message, "percentComplete"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.percentComplete);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdMaintanceGetUpdateStatus message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdMaintanceGetUpdateStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} message ResponseCmdMaintanceGetUpdateStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdMaintanceGetUpdateStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdMaintanceGetUpdateStatus message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} ResponseCmdMaintanceGetUpdateStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceGetUpdateStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdMaintanceGetUpdateStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.percentComplete = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdMaintanceGetUpdateStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} ResponseCmdMaintanceGetUpdateStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdMaintanceGetUpdateStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdMaintanceGetUpdateStatus message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdMaintanceGetUpdateStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.percentComplete != null && message.hasOwnProperty("percentComplete"))
                if (!$util.isInteger(message.percentComplete))
                    return "percentComplete: integer expected";
            return null;
        };

        /**
         * Creates a ResponseCmdMaintanceGetUpdateStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} ResponseCmdMaintanceGetUpdateStatus
         */
        ResponseCmdMaintanceGetUpdateStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdMaintanceGetUpdateStatus)
                return object;
            let message = new $root.hdyRpc.ResponseCmdMaintanceGetUpdateStatus();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.percentComplete != null)
                message.percentComplete = object.percentComplete >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdMaintanceGetUpdateStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @static
         * @param {hdyRpc.ResponseCmdMaintanceGetUpdateStatus} message ResponseCmdMaintanceGetUpdateStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdMaintanceGetUpdateStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.percentComplete = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.percentComplete != null && message.hasOwnProperty("percentComplete"))
                object.percentComplete = message.percentComplete;
            return object;
        };

        /**
         * Converts this ResponseCmdMaintanceGetUpdateStatus to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdMaintanceGetUpdateStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdMaintanceGetUpdateStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdMaintanceGetUpdateStatus;
    })();

    hdyRpc.RequestCmdOtaGetLatest = (function() {

        /**
         * Properties of a RequestCmdOtaGetLatest.
         * @memberof hdyRpc
         * @interface IRequestCmdOtaGetLatest
         */

        /**
         * Constructs a new RequestCmdOtaGetLatest.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdOtaGetLatest.
         * @implements IRequestCmdOtaGetLatest
         * @constructor
         * @param {hdyRpc.IRequestCmdOtaGetLatest=} [properties] Properties to set
         */
        function RequestCmdOtaGetLatest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdOtaGetLatest instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {hdyRpc.IRequestCmdOtaGetLatest=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdOtaGetLatest} RequestCmdOtaGetLatest instance
         */
        RequestCmdOtaGetLatest.create = function create(properties) {
            return new RequestCmdOtaGetLatest(properties);
        };

        /**
         * Encodes the specified RequestCmdOtaGetLatest message. Does not implicitly {@link hdyRpc.RequestCmdOtaGetLatest.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {hdyRpc.RequestCmdOtaGetLatest} message RequestCmdOtaGetLatest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdOtaGetLatest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdOtaGetLatest message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdOtaGetLatest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {hdyRpc.RequestCmdOtaGetLatest} message RequestCmdOtaGetLatest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdOtaGetLatest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdOtaGetLatest message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdOtaGetLatest} RequestCmdOtaGetLatest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdOtaGetLatest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdOtaGetLatest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdOtaGetLatest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdOtaGetLatest} RequestCmdOtaGetLatest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdOtaGetLatest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdOtaGetLatest message.
         * @function verify
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdOtaGetLatest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdOtaGetLatest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdOtaGetLatest} RequestCmdOtaGetLatest
         */
        RequestCmdOtaGetLatest.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdOtaGetLatest)
                return object;
            return new $root.hdyRpc.RequestCmdOtaGetLatest();
        };

        /**
         * Creates a plain object from a RequestCmdOtaGetLatest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @static
         * @param {hdyRpc.RequestCmdOtaGetLatest} message RequestCmdOtaGetLatest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdOtaGetLatest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdOtaGetLatest to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdOtaGetLatest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdOtaGetLatest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdOtaGetLatest;
    })();

    hdyRpc.ResponseCmdOtaGetLatest = (function() {

        /**
         * Properties of a ResponseCmdOtaGetLatest.
         * @memberof hdyRpc
         * @interface IResponseCmdOtaGetLatest
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdOtaGetLatest result
         * @property {string|null} [version] ResponseCmdOtaGetLatest version
         */

        /**
         * Constructs a new ResponseCmdOtaGetLatest.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdOtaGetLatest.
         * @implements IResponseCmdOtaGetLatest
         * @constructor
         * @param {hdyRpc.IResponseCmdOtaGetLatest=} [properties] Properties to set
         */
        function ResponseCmdOtaGetLatest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdOtaGetLatest result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @instance
         */
        ResponseCmdOtaGetLatest.prototype.result = 0;

        /**
         * ResponseCmdOtaGetLatest version.
         * @member {string} version
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @instance
         */
        ResponseCmdOtaGetLatest.prototype.version = "";

        /**
         * Creates a new ResponseCmdOtaGetLatest instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {hdyRpc.IResponseCmdOtaGetLatest=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdOtaGetLatest} ResponseCmdOtaGetLatest instance
         */
        ResponseCmdOtaGetLatest.create = function create(properties) {
            return new ResponseCmdOtaGetLatest(properties);
        };

        /**
         * Encodes the specified ResponseCmdOtaGetLatest message. Does not implicitly {@link hdyRpc.ResponseCmdOtaGetLatest.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {hdyRpc.ResponseCmdOtaGetLatest} message ResponseCmdOtaGetLatest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdOtaGetLatest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.version);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdOtaGetLatest message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdOtaGetLatest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {hdyRpc.ResponseCmdOtaGetLatest} message ResponseCmdOtaGetLatest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdOtaGetLatest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdOtaGetLatest message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdOtaGetLatest} ResponseCmdOtaGetLatest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdOtaGetLatest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdOtaGetLatest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdOtaGetLatest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdOtaGetLatest} ResponseCmdOtaGetLatest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdOtaGetLatest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdOtaGetLatest message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdOtaGetLatest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            return null;
        };

        /**
         * Creates a ResponseCmdOtaGetLatest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdOtaGetLatest} ResponseCmdOtaGetLatest
         */
        ResponseCmdOtaGetLatest.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdOtaGetLatest)
                return object;
            let message = new $root.hdyRpc.ResponseCmdOtaGetLatest();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.version != null)
                message.version = String(object.version);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdOtaGetLatest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @static
         * @param {hdyRpc.ResponseCmdOtaGetLatest} message ResponseCmdOtaGetLatest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdOtaGetLatest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.version = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            return object;
        };

        /**
         * Converts this ResponseCmdOtaGetLatest to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdOtaGetLatest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdOtaGetLatest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdOtaGetLatest;
    })();

    hdyRpc.RequestCmdStrokeGetStroke = (function() {

        /**
         * Properties of a RequestCmdStrokeGetStroke.
         * @memberof hdyRpc
         * @interface IRequestCmdStrokeGetStroke
         */

        /**
         * Constructs a new RequestCmdStrokeGetStroke.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdStrokeGetStroke.
         * @implements IRequestCmdStrokeGetStroke
         * @constructor
         * @param {hdyRpc.IRequestCmdStrokeGetStroke=} [properties] Properties to set
         */
        function RequestCmdStrokeGetStroke(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdStrokeGetStroke instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.IRequestCmdStrokeGetStroke=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdStrokeGetStroke} RequestCmdStrokeGetStroke instance
         */
        RequestCmdStrokeGetStroke.create = function create(properties) {
            return new RequestCmdStrokeGetStroke(properties);
        };

        /**
         * Encodes the specified RequestCmdStrokeGetStroke message. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetStroke.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetStroke} message RequestCmdStrokeGetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetStroke.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdStrokeGetStroke message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetStroke.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetStroke} message RequestCmdStrokeGetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetStroke.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdStrokeGetStroke message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdStrokeGetStroke} RequestCmdStrokeGetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetStroke.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdStrokeGetStroke();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdStrokeGetStroke message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdStrokeGetStroke} RequestCmdStrokeGetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetStroke.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdStrokeGetStroke message.
         * @function verify
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdStrokeGetStroke.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdStrokeGetStroke message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdStrokeGetStroke} RequestCmdStrokeGetStroke
         */
        RequestCmdStrokeGetStroke.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdStrokeGetStroke)
                return object;
            return new $root.hdyRpc.RequestCmdStrokeGetStroke();
        };

        /**
         * Creates a plain object from a RequestCmdStrokeGetStroke message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetStroke} message RequestCmdStrokeGetStroke
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdStrokeGetStroke.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdStrokeGetStroke to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdStrokeGetStroke
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdStrokeGetStroke.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdStrokeGetStroke;
    })();

    hdyRpc.ResponseCmdStrokeGetStroke = (function() {

        /**
         * Properties of a ResponseCmdStrokeGetStroke.
         * @memberof hdyRpc
         * @interface IResponseCmdStrokeGetStroke
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdStrokeGetStroke result
         * @property {number|null} [min] ResponseCmdStrokeGetStroke min
         * @property {number|null} [max] ResponseCmdStrokeGetStroke max
         */

        /**
         * Constructs a new ResponseCmdStrokeGetStroke.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdStrokeGetStroke.
         * @implements IResponseCmdStrokeGetStroke
         * @constructor
         * @param {hdyRpc.IResponseCmdStrokeGetStroke=} [properties] Properties to set
         */
        function ResponseCmdStrokeGetStroke(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdStrokeGetStroke result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @instance
         */
        ResponseCmdStrokeGetStroke.prototype.result = 0;

        /**
         * ResponseCmdStrokeGetStroke min.
         * @member {number} min
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @instance
         */
        ResponseCmdStrokeGetStroke.prototype.min = 0;

        /**
         * ResponseCmdStrokeGetStroke max.
         * @member {number} max
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @instance
         */
        ResponseCmdStrokeGetStroke.prototype.max = 0;

        /**
         * Creates a new ResponseCmdStrokeGetStroke instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.IResponseCmdStrokeGetStroke=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdStrokeGetStroke} ResponseCmdStrokeGetStroke instance
         */
        ResponseCmdStrokeGetStroke.create = function create(properties) {
            return new ResponseCmdStrokeGetStroke(properties);
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetStroke message. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetStroke.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetStroke} message ResponseCmdStrokeGetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetStroke.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.max);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetStroke message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetStroke.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetStroke} message ResponseCmdStrokeGetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetStroke.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdStrokeGetStroke message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdStrokeGetStroke} ResponseCmdStrokeGetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetStroke.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdStrokeGetStroke();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.min = reader.uint32();
                    break;
                case 3:
                    message.max = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdStrokeGetStroke message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdStrokeGetStroke} ResponseCmdStrokeGetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetStroke.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdStrokeGetStroke message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdStrokeGetStroke.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.min != null && message.hasOwnProperty("min"))
                if (!$util.isInteger(message.min))
                    return "min: integer expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (!$util.isInteger(message.max))
                    return "max: integer expected";
            return null;
        };

        /**
         * Creates a ResponseCmdStrokeGetStroke message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdStrokeGetStroke} ResponseCmdStrokeGetStroke
         */
        ResponseCmdStrokeGetStroke.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdStrokeGetStroke)
                return object;
            let message = new $root.hdyRpc.ResponseCmdStrokeGetStroke();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.min != null)
                message.min = object.min >>> 0;
            if (object.max != null)
                message.max = object.max >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdStrokeGetStroke message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetStroke} message ResponseCmdStrokeGetStroke
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdStrokeGetStroke.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.min = 0;
                object.max = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.min != null && message.hasOwnProperty("min"))
                object.min = message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                object.max = message.max;
            return object;
        };

        /**
         * Converts this ResponseCmdStrokeGetStroke to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdStrokeGetStroke
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdStrokeGetStroke.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdStrokeGetStroke;
    })();

    hdyRpc.RequestCmdStrokeSetStroke = (function() {

        /**
         * Properties of a RequestCmdStrokeSetStroke.
         * @memberof hdyRpc
         * @interface IRequestCmdStrokeSetStroke
         * @property {number|null} [min] RequestCmdStrokeSetStroke min
         * @property {number|null} [max] RequestCmdStrokeSetStroke max
         */

        /**
         * Constructs a new RequestCmdStrokeSetStroke.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdStrokeSetStroke.
         * @implements IRequestCmdStrokeSetStroke
         * @constructor
         * @param {hdyRpc.IRequestCmdStrokeSetStroke=} [properties] Properties to set
         */
        function RequestCmdStrokeSetStroke(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestCmdStrokeSetStroke min.
         * @member {number} min
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @instance
         */
        RequestCmdStrokeSetStroke.prototype.min = 0;

        /**
         * RequestCmdStrokeSetStroke max.
         * @member {number} max
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @instance
         */
        RequestCmdStrokeSetStroke.prototype.max = 0;

        /**
         * Creates a new RequestCmdStrokeSetStroke instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.IRequestCmdStrokeSetStroke=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdStrokeSetStroke} RequestCmdStrokeSetStroke instance
         */
        RequestCmdStrokeSetStroke.create = function create(properties) {
            return new RequestCmdStrokeSetStroke(properties);
        };

        /**
         * Encodes the specified RequestCmdStrokeSetStroke message. Does not implicitly {@link hdyRpc.RequestCmdStrokeSetStroke.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeSetStroke} message RequestCmdStrokeSetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeSetStroke.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.min != null && Object.hasOwnProperty.call(message, "min"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.min);
            if (message.max != null && Object.hasOwnProperty.call(message, "max"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.max);
            return writer;
        };

        /**
         * Encodes the specified RequestCmdStrokeSetStroke message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdStrokeSetStroke.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeSetStroke} message RequestCmdStrokeSetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeSetStroke.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdStrokeSetStroke message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdStrokeSetStroke} RequestCmdStrokeSetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeSetStroke.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdStrokeSetStroke();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.min = reader.uint32();
                    break;
                case 2:
                    message.max = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdStrokeSetStroke message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdStrokeSetStroke} RequestCmdStrokeSetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeSetStroke.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdStrokeSetStroke message.
         * @function verify
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdStrokeSetStroke.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.min != null && message.hasOwnProperty("min"))
                if (!$util.isInteger(message.min))
                    return "min: integer expected";
            if (message.max != null && message.hasOwnProperty("max"))
                if (!$util.isInteger(message.max))
                    return "max: integer expected";
            return null;
        };

        /**
         * Creates a RequestCmdStrokeSetStroke message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdStrokeSetStroke} RequestCmdStrokeSetStroke
         */
        RequestCmdStrokeSetStroke.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdStrokeSetStroke)
                return object;
            let message = new $root.hdyRpc.RequestCmdStrokeSetStroke();
            if (object.min != null)
                message.min = object.min >>> 0;
            if (object.max != null)
                message.max = object.max >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestCmdStrokeSetStroke message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.RequestCmdStrokeSetStroke} message RequestCmdStrokeSetStroke
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdStrokeSetStroke.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.min = 0;
                object.max = 0;
            }
            if (message.min != null && message.hasOwnProperty("min"))
                object.min = message.min;
            if (message.max != null && message.hasOwnProperty("max"))
                object.max = message.max;
            return object;
        };

        /**
         * Converts this RequestCmdStrokeSetStroke to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdStrokeSetStroke
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdStrokeSetStroke.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdStrokeSetStroke;
    })();

    hdyRpc.ResponseCmdStrokeSetStroke = (function() {

        /**
         * Properties of a ResponseCmdStrokeSetStroke.
         * @memberof hdyRpc
         * @interface IResponseCmdStrokeSetStroke
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdStrokeSetStroke result
         */

        /**
         * Constructs a new ResponseCmdStrokeSetStroke.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdStrokeSetStroke.
         * @implements IResponseCmdStrokeSetStroke
         * @constructor
         * @param {hdyRpc.IResponseCmdStrokeSetStroke=} [properties] Properties to set
         */
        function ResponseCmdStrokeSetStroke(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdStrokeSetStroke result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @instance
         */
        ResponseCmdStrokeSetStroke.prototype.result = 0;

        /**
         * Creates a new ResponseCmdStrokeSetStroke instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.IResponseCmdStrokeSetStroke=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdStrokeSetStroke} ResponseCmdStrokeSetStroke instance
         */
        ResponseCmdStrokeSetStroke.create = function create(properties) {
            return new ResponseCmdStrokeSetStroke(properties);
        };

        /**
         * Encodes the specified ResponseCmdStrokeSetStroke message. Does not implicitly {@link hdyRpc.ResponseCmdStrokeSetStroke.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeSetStroke} message ResponseCmdStrokeSetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeSetStroke.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdStrokeSetStroke message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdStrokeSetStroke.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeSetStroke} message ResponseCmdStrokeSetStroke message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeSetStroke.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdStrokeSetStroke message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdStrokeSetStroke} ResponseCmdStrokeSetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeSetStroke.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdStrokeSetStroke();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdStrokeSetStroke message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdStrokeSetStroke} ResponseCmdStrokeSetStroke
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeSetStroke.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdStrokeSetStroke message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdStrokeSetStroke.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            return null;
        };

        /**
         * Creates a ResponseCmdStrokeSetStroke message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdStrokeSetStroke} ResponseCmdStrokeSetStroke
         */
        ResponseCmdStrokeSetStroke.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdStrokeSetStroke)
                return object;
            let message = new $root.hdyRpc.ResponseCmdStrokeSetStroke();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdStrokeSetStroke message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @static
         * @param {hdyRpc.ResponseCmdStrokeSetStroke} message ResponseCmdStrokeSetStroke
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdStrokeSetStroke.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            return object;
        };

        /**
         * Converts this ResponseCmdStrokeSetStroke to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdStrokeSetStroke
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdStrokeSetStroke.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdStrokeSetStroke;
    })();

    hdyRpc.RequestCmdStrokeGetPositionAbsolute = (function() {

        /**
         * Properties of a RequestCmdStrokeGetPositionAbsolute.
         * @memberof hdyRpc
         * @interface IRequestCmdStrokeGetPositionAbsolute
         */

        /**
         * Constructs a new RequestCmdStrokeGetPositionAbsolute.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdStrokeGetPositionAbsolute.
         * @implements IRequestCmdStrokeGetPositionAbsolute
         * @constructor
         * @param {hdyRpc.IRequestCmdStrokeGetPositionAbsolute=} [properties] Properties to set
         */
        function RequestCmdStrokeGetPositionAbsolute(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdStrokeGetPositionAbsolute instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.IRequestCmdStrokeGetPositionAbsolute=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdStrokeGetPositionAbsolute} RequestCmdStrokeGetPositionAbsolute instance
         */
        RequestCmdStrokeGetPositionAbsolute.create = function create(properties) {
            return new RequestCmdStrokeGetPositionAbsolute(properties);
        };

        /**
         * Encodes the specified RequestCmdStrokeGetPositionAbsolute message. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetPositionAbsolute.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionAbsolute} message RequestCmdStrokeGetPositionAbsolute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetPositionAbsolute.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdStrokeGetPositionAbsolute message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetPositionAbsolute.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionAbsolute} message RequestCmdStrokeGetPositionAbsolute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetPositionAbsolute.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdStrokeGetPositionAbsolute message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdStrokeGetPositionAbsolute} RequestCmdStrokeGetPositionAbsolute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetPositionAbsolute.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdStrokeGetPositionAbsolute();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdStrokeGetPositionAbsolute message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdStrokeGetPositionAbsolute} RequestCmdStrokeGetPositionAbsolute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetPositionAbsolute.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdStrokeGetPositionAbsolute message.
         * @function verify
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdStrokeGetPositionAbsolute.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdStrokeGetPositionAbsolute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdStrokeGetPositionAbsolute} RequestCmdStrokeGetPositionAbsolute
         */
        RequestCmdStrokeGetPositionAbsolute.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdStrokeGetPositionAbsolute)
                return object;
            return new $root.hdyRpc.RequestCmdStrokeGetPositionAbsolute();
        };

        /**
         * Creates a plain object from a RequestCmdStrokeGetPositionAbsolute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionAbsolute} message RequestCmdStrokeGetPositionAbsolute
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdStrokeGetPositionAbsolute.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdStrokeGetPositionAbsolute to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdStrokeGetPositionAbsolute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdStrokeGetPositionAbsolute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdStrokeGetPositionAbsolute;
    })();

    hdyRpc.ResponseCmdStrokeGetPositionAbsolute = (function() {

        /**
         * Properties of a ResponseCmdStrokeGetPositionAbsolute.
         * @memberof hdyRpc
         * @interface IResponseCmdStrokeGetPositionAbsolute
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdStrokeGetPositionAbsolute result
         * @property {number|null} [position] ResponseCmdStrokeGetPositionAbsolute position
         */

        /**
         * Constructs a new ResponseCmdStrokeGetPositionAbsolute.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdStrokeGetPositionAbsolute.
         * @implements IResponseCmdStrokeGetPositionAbsolute
         * @constructor
         * @param {hdyRpc.IResponseCmdStrokeGetPositionAbsolute=} [properties] Properties to set
         */
        function ResponseCmdStrokeGetPositionAbsolute(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdStrokeGetPositionAbsolute result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @instance
         */
        ResponseCmdStrokeGetPositionAbsolute.prototype.result = 0;

        /**
         * ResponseCmdStrokeGetPositionAbsolute position.
         * @member {number} position
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @instance
         */
        ResponseCmdStrokeGetPositionAbsolute.prototype.position = 0;

        /**
         * Creates a new ResponseCmdStrokeGetPositionAbsolute instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.IResponseCmdStrokeGetPositionAbsolute=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} ResponseCmdStrokeGetPositionAbsolute instance
         */
        ResponseCmdStrokeGetPositionAbsolute.create = function create(properties) {
            return new ResponseCmdStrokeGetPositionAbsolute(properties);
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetPositionAbsolute message. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetPositionAbsolute.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} message ResponseCmdStrokeGetPositionAbsolute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetPositionAbsolute.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.position);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetPositionAbsolute message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetPositionAbsolute.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} message ResponseCmdStrokeGetPositionAbsolute message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetPositionAbsolute.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdStrokeGetPositionAbsolute message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} ResponseCmdStrokeGetPositionAbsolute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetPositionAbsolute.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdStrokeGetPositionAbsolute();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.position = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdStrokeGetPositionAbsolute message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} ResponseCmdStrokeGetPositionAbsolute
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetPositionAbsolute.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdStrokeGetPositionAbsolute message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdStrokeGetPositionAbsolute.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.position != null && message.hasOwnProperty("position"))
                if (typeof message.position !== "number")
                    return "position: number expected";
            return null;
        };

        /**
         * Creates a ResponseCmdStrokeGetPositionAbsolute message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} ResponseCmdStrokeGetPositionAbsolute
         */
        ResponseCmdStrokeGetPositionAbsolute.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdStrokeGetPositionAbsolute)
                return object;
            let message = new $root.hdyRpc.ResponseCmdStrokeGetPositionAbsolute();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.position != null)
                message.position = Number(object.position);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdStrokeGetPositionAbsolute message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionAbsolute} message ResponseCmdStrokeGetPositionAbsolute
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdStrokeGetPositionAbsolute.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.position = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.position != null && message.hasOwnProperty("position"))
                object.position = options.json && !isFinite(message.position) ? String(message.position) : message.position;
            return object;
        };

        /**
         * Converts this ResponseCmdStrokeGetPositionAbsolute to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionAbsolute
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdStrokeGetPositionAbsolute.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdStrokeGetPositionAbsolute;
    })();

    hdyRpc.RequestCmdStrokeGetPositionRelative = (function() {

        /**
         * Properties of a RequestCmdStrokeGetPositionRelative.
         * @memberof hdyRpc
         * @interface IRequestCmdStrokeGetPositionRelative
         */

        /**
         * Constructs a new RequestCmdStrokeGetPositionRelative.
         * @memberof hdyRpc
         * @classdesc Represents a RequestCmdStrokeGetPositionRelative.
         * @implements IRequestCmdStrokeGetPositionRelative
         * @constructor
         * @param {hdyRpc.IRequestCmdStrokeGetPositionRelative=} [properties] Properties to set
         */
        function RequestCmdStrokeGetPositionRelative(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestCmdStrokeGetPositionRelative instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.IRequestCmdStrokeGetPositionRelative=} [properties] Properties to set
         * @returns {hdyRpc.RequestCmdStrokeGetPositionRelative} RequestCmdStrokeGetPositionRelative instance
         */
        RequestCmdStrokeGetPositionRelative.create = function create(properties) {
            return new RequestCmdStrokeGetPositionRelative(properties);
        };

        /**
         * Encodes the specified RequestCmdStrokeGetPositionRelative message. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetPositionRelative.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionRelative} message RequestCmdStrokeGetPositionRelative message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetPositionRelative.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestCmdStrokeGetPositionRelative message, length delimited. Does not implicitly {@link hdyRpc.RequestCmdStrokeGetPositionRelative.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionRelative} message RequestCmdStrokeGetPositionRelative message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestCmdStrokeGetPositionRelative.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestCmdStrokeGetPositionRelative message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RequestCmdStrokeGetPositionRelative} RequestCmdStrokeGetPositionRelative
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetPositionRelative.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RequestCmdStrokeGetPositionRelative();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestCmdStrokeGetPositionRelative message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RequestCmdStrokeGetPositionRelative} RequestCmdStrokeGetPositionRelative
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestCmdStrokeGetPositionRelative.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestCmdStrokeGetPositionRelative message.
         * @function verify
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestCmdStrokeGetPositionRelative.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestCmdStrokeGetPositionRelative message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RequestCmdStrokeGetPositionRelative} RequestCmdStrokeGetPositionRelative
         */
        RequestCmdStrokeGetPositionRelative.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RequestCmdStrokeGetPositionRelative)
                return object;
            return new $root.hdyRpc.RequestCmdStrokeGetPositionRelative();
        };

        /**
         * Creates a plain object from a RequestCmdStrokeGetPositionRelative message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.RequestCmdStrokeGetPositionRelative} message RequestCmdStrokeGetPositionRelative
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestCmdStrokeGetPositionRelative.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestCmdStrokeGetPositionRelative to JSON.
         * @function toJSON
         * @memberof hdyRpc.RequestCmdStrokeGetPositionRelative
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestCmdStrokeGetPositionRelative.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestCmdStrokeGetPositionRelative;
    })();

    hdyRpc.ResponseCmdStrokeGetPositionRelative = (function() {

        /**
         * Properties of a ResponseCmdStrokeGetPositionRelative.
         * @memberof hdyRpc
         * @interface IResponseCmdStrokeGetPositionRelative
         * @property {hdyRpc.ResultCodes|null} [result] ResponseCmdStrokeGetPositionRelative result
         * @property {number|null} [position] ResponseCmdStrokeGetPositionRelative position
         */

        /**
         * Constructs a new ResponseCmdStrokeGetPositionRelative.
         * @memberof hdyRpc
         * @classdesc Represents a ResponseCmdStrokeGetPositionRelative.
         * @implements IResponseCmdStrokeGetPositionRelative
         * @constructor
         * @param {hdyRpc.IResponseCmdStrokeGetPositionRelative=} [properties] Properties to set
         */
        function ResponseCmdStrokeGetPositionRelative(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseCmdStrokeGetPositionRelative result.
         * @member {hdyRpc.ResultCodes} result
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @instance
         */
        ResponseCmdStrokeGetPositionRelative.prototype.result = 0;

        /**
         * ResponseCmdStrokeGetPositionRelative position.
         * @member {number} position
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @instance
         */
        ResponseCmdStrokeGetPositionRelative.prototype.position = 0;

        /**
         * Creates a new ResponseCmdStrokeGetPositionRelative instance using the specified properties.
         * @function create
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.IResponseCmdStrokeGetPositionRelative=} [properties] Properties to set
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionRelative} ResponseCmdStrokeGetPositionRelative instance
         */
        ResponseCmdStrokeGetPositionRelative.create = function create(properties) {
            return new ResponseCmdStrokeGetPositionRelative(properties);
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetPositionRelative message. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetPositionRelative.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionRelative} message ResponseCmdStrokeGetPositionRelative message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetPositionRelative.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.position);
            return writer;
        };

        /**
         * Encodes the specified ResponseCmdStrokeGetPositionRelative message, length delimited. Does not implicitly {@link hdyRpc.ResponseCmdStrokeGetPositionRelative.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionRelative} message ResponseCmdStrokeGetPositionRelative message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseCmdStrokeGetPositionRelative.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseCmdStrokeGetPositionRelative message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionRelative} ResponseCmdStrokeGetPositionRelative
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetPositionRelative.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.ResponseCmdStrokeGetPositionRelative();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.position = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseCmdStrokeGetPositionRelative message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionRelative} ResponseCmdStrokeGetPositionRelative
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseCmdStrokeGetPositionRelative.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseCmdStrokeGetPositionRelative message.
         * @function verify
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseCmdStrokeGetPositionRelative.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case -1:
                case -2:
                case -3:
                case -4:
                case -5:
                case -6:
                case -7:
                case -8:
                case -9:
                    break;
                }
            if (message.position != null && message.hasOwnProperty("position"))
                if (typeof message.position !== "number")
                    return "position: number expected";
            return null;
        };

        /**
         * Creates a ResponseCmdStrokeGetPositionRelative message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.ResponseCmdStrokeGetPositionRelative} ResponseCmdStrokeGetPositionRelative
         */
        ResponseCmdStrokeGetPositionRelative.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.ResponseCmdStrokeGetPositionRelative)
                return object;
            let message = new $root.hdyRpc.ResponseCmdStrokeGetPositionRelative();
            switch (object.result) {
            case "RESULT_UNKNOWN":
            case 0:
                message.result = 0;
                break;
            case "RESULT_OK":
            case 1:
                message.result = 1;
                break;
            case "RESULT_OK_ALREADY_SET":
            case 2:
                message.result = 2;
                break;
            case "RESULT_ERR_GENERIC":
            case -1:
                message.result = -1;
                break;
            case "RESULT_ERR_NO_MEMORY":
            case -2:
                message.result = -2;
                break;
            case "RESULT_ERR_INVALID_ARG":
            case -3:
                message.result = -3;
                break;
            case "RESULT_ERR_INVALID_STATE":
            case -4:
                message.result = -4;
                break;
            case "RESULT_ERR_INVALID_SIZE":
            case -5:
                message.result = -5;
                break;
            case "RESULT_ERR_NOT_FOUND":
            case -6:
                message.result = -6;
                break;
            case "RESULT_ERR_NOT_SUPPORTED":
            case -7:
                message.result = -7;
                break;
            case "RESULT_ERR_TIMEOUT":
            case -8:
                message.result = -8;
                break;
            case "RESULT_ERR_INVALID_RESPONSE":
            case -9:
                message.result = -9;
                break;
            }
            if (object.position != null)
                message.position = Number(object.position);
            return message;
        };

        /**
         * Creates a plain object from a ResponseCmdStrokeGetPositionRelative message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @static
         * @param {hdyRpc.ResponseCmdStrokeGetPositionRelative} message ResponseCmdStrokeGetPositionRelative
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseCmdStrokeGetPositionRelative.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "RESULT_UNKNOWN" : 0;
                object.position = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.hdyRpc.ResultCodes[message.result] : message.result;
            if (message.position != null && message.hasOwnProperty("position"))
                object.position = options.json && !isFinite(message.position) ? String(message.position) : message.position;
            return object;
        };

        /**
         * Converts this ResponseCmdStrokeGetPositionRelative to JSON.
         * @function toJSON
         * @memberof hdyRpc.ResponseCmdStrokeGetPositionRelative
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseCmdStrokeGetPositionRelative.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseCmdStrokeGetPositionRelative;
    })();

    /**
     * RequestMethods enum.
     * @name hdyRpc.RequestMethods
     * @enum {number}
     * @property {number} REQUEST_METHOD_UNKNOWN=0 REQUEST_METHOD_UNKNOWN value
     * @property {number} REQUEST_METHOD_GET_SERVER_TIME=300 REQUEST_METHOD_GET_SERVER_TIME value
     * @property {number} REQUEST_METHOD_WS_HANDSHAKE=500 REQUEST_METHOD_WS_HANDSHAKE value
     * @property {number} REQUEST_METHOD_GENERATE_CONNECTION_KEY=501 REQUEST_METHOD_GENERATE_CONNECTION_KEY value
     * @property {number} REQUEST_METHOD_SET_CONNECTION_KEY=502 REQUEST_METHOD_SET_CONNECTION_KEY value
     * @property {number} REQUEST_METHOD_GET_MODE=700 REQUEST_METHOD_GET_MODE value
     * @property {number} REQUEST_METHOD_SET_MODE=701 REQUEST_METHOD_SET_MODE value
     * @property {number} REQUEST_METHOD_GET_CONNECTED=702 REQUEST_METHOD_GET_CONNECTED value
     * @property {number} REQUEST_METHOD_GET_INFO=703 REQUEST_METHOD_GET_INFO value
     * @property {number} REQUEST_METHOD_GET_SETTINGS=704 REQUEST_METHOD_GET_SETTINGS value
     * @property {number} REQUEST_METHOD_GET_STATUS=705 REQUEST_METHOD_GET_STATUS value
     * @property {number} REQUEST_METHOD_HAMP_SET_START=720 REQUEST_METHOD_HAMP_SET_START value
     * @property {number} REQUEST_METHOD_HAMP_SET_STOP=721 REQUEST_METHOD_HAMP_SET_STOP value
     * @property {number} REQUEST_METHOD_HAMP_GET_VELOCITY=722 REQUEST_METHOD_HAMP_GET_VELOCITY value
     * @property {number} REQUEST_METHOD_HAMP_SET_VELOCITY=723 REQUEST_METHOD_HAMP_SET_VELOCITY value
     * @property {number} REQUEST_METHOD_HAMP_GET_STATE=724 REQUEST_METHOD_HAMP_GET_STATE value
     * @property {number} REQUEST_METHOD_HDSP_SET_XAVA=740 REQUEST_METHOD_HDSP_SET_XAVA value
     * @property {number} REQUEST_METHOD_HDSP_SET_XPVA=741 REQUEST_METHOD_HDSP_SET_XPVA value
     * @property {number} REQUEST_METHOD_HDSP_SET_XPVP=742 REQUEST_METHOD_HDSP_SET_XPVP value
     * @property {number} REQUEST_METHOD_HDSP_SET_XAT=743 REQUEST_METHOD_HDSP_SET_XAT value
     * @property {number} REQUEST_METHOD_HDSP_SET_XPT=744 REQUEST_METHOD_HDSP_SET_XPT value
     * @property {number} REQUEST_METHOD_HDSP_SET_XAVP=745 REQUEST_METHOD_HDSP_SET_XAVP value
     * @property {number} REQUEST_METHOD_HSTP_GET_TIME=780 REQUEST_METHOD_HSTP_GET_TIME value
     * @property {number} REQUEST_METHOD_HSTP_GET_OFFSET=781 REQUEST_METHOD_HSTP_GET_OFFSET value
     * @property {number} REQUEST_METHOD_HSTP_SET_OFFSET=782 REQUEST_METHOD_HSTP_SET_OFFSET value
     * @property {number} REQUEST_METHOD_HSTP_GET_RTD=783 REQUEST_METHOD_HSTP_GET_RTD value
     * @property {number} REQUEST_METHOD_HSTP_SET_SYNC=784 REQUEST_METHOD_HSTP_SET_SYNC value
     * @property {number} REQUEST_METHOD_MAINTENANCE_SET_RESTART=800 REQUEST_METHOD_MAINTENANCE_SET_RESTART value
     * @property {number} REQUEST_METHOD_MAINTENANCE_SET_UPDATE_PERFORM=801 REQUEST_METHOD_MAINTENANCE_SET_UPDATE_PERFORM value
     * @property {number} REQUEST_METHOD_MAINTENANCE_SET_UPDATE_STATUS=802 REQUEST_METHOD_MAINTENANCE_SET_UPDATE_STATUS value
     * @property {number} REQUEST_METHOD_OTA_GET_LATEST=820 REQUEST_METHOD_OTA_GET_LATEST value
     * @property {number} REQUEST_METHOD_STROKE_GET_STROKE=840 REQUEST_METHOD_STROKE_GET_STROKE value
     * @property {number} REQUEST_METHOD_STROKE_SET_STROKE=841 REQUEST_METHOD_STROKE_SET_STROKE value
     * @property {number} REQUEST_METHOD_STROKE_GET_STROKE_POSITION_ABSOLUTE=842 REQUEST_METHOD_STROKE_GET_STROKE_POSITION_ABSOLUTE value
     * @property {number} REQUEST_METHOD_STROKE_GET_STROKE_POSITION_RELATIVE=843 REQUEST_METHOD_STROKE_GET_STROKE_POSITION_RELATIVE value
     */
    hdyRpc.RequestMethods = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REQUEST_METHOD_UNKNOWN"] = 0;
        values[valuesById[300] = "REQUEST_METHOD_GET_SERVER_TIME"] = 300;
        values[valuesById[500] = "REQUEST_METHOD_WS_HANDSHAKE"] = 500;
        values[valuesById[501] = "REQUEST_METHOD_GENERATE_CONNECTION_KEY"] = 501;
        values[valuesById[502] = "REQUEST_METHOD_SET_CONNECTION_KEY"] = 502;
        values[valuesById[700] = "REQUEST_METHOD_GET_MODE"] = 700;
        values[valuesById[701] = "REQUEST_METHOD_SET_MODE"] = 701;
        values[valuesById[702] = "REQUEST_METHOD_GET_CONNECTED"] = 702;
        values[valuesById[703] = "REQUEST_METHOD_GET_INFO"] = 703;
        values[valuesById[704] = "REQUEST_METHOD_GET_SETTINGS"] = 704;
        values[valuesById[705] = "REQUEST_METHOD_GET_STATUS"] = 705;
        values[valuesById[720] = "REQUEST_METHOD_HAMP_SET_START"] = 720;
        values[valuesById[721] = "REQUEST_METHOD_HAMP_SET_STOP"] = 721;
        values[valuesById[722] = "REQUEST_METHOD_HAMP_GET_VELOCITY"] = 722;
        values[valuesById[723] = "REQUEST_METHOD_HAMP_SET_VELOCITY"] = 723;
        values[valuesById[724] = "REQUEST_METHOD_HAMP_GET_STATE"] = 724;
        values[valuesById[740] = "REQUEST_METHOD_HDSP_SET_XAVA"] = 740;
        values[valuesById[741] = "REQUEST_METHOD_HDSP_SET_XPVA"] = 741;
        values[valuesById[742] = "REQUEST_METHOD_HDSP_SET_XPVP"] = 742;
        values[valuesById[743] = "REQUEST_METHOD_HDSP_SET_XAT"] = 743;
        values[valuesById[744] = "REQUEST_METHOD_HDSP_SET_XPT"] = 744;
        values[valuesById[745] = "REQUEST_METHOD_HDSP_SET_XAVP"] = 745;
        values[valuesById[780] = "REQUEST_METHOD_HSTP_GET_TIME"] = 780;
        values[valuesById[781] = "REQUEST_METHOD_HSTP_GET_OFFSET"] = 781;
        values[valuesById[782] = "REQUEST_METHOD_HSTP_SET_OFFSET"] = 782;
        values[valuesById[783] = "REQUEST_METHOD_HSTP_GET_RTD"] = 783;
        values[valuesById[784] = "REQUEST_METHOD_HSTP_SET_SYNC"] = 784;
        values[valuesById[800] = "REQUEST_METHOD_MAINTENANCE_SET_RESTART"] = 800;
        values[valuesById[801] = "REQUEST_METHOD_MAINTENANCE_SET_UPDATE_PERFORM"] = 801;
        values[valuesById[802] = "REQUEST_METHOD_MAINTENANCE_SET_UPDATE_STATUS"] = 802;
        values[valuesById[820] = "REQUEST_METHOD_OTA_GET_LATEST"] = 820;
        values[valuesById[840] = "REQUEST_METHOD_STROKE_GET_STROKE"] = 840;
        values[valuesById[841] = "REQUEST_METHOD_STROKE_SET_STROKE"] = 841;
        values[valuesById[842] = "REQUEST_METHOD_STROKE_GET_STROKE_POSITION_ABSOLUTE"] = 842;
        values[valuesById[843] = "REQUEST_METHOD_STROKE_GET_STROKE_POSITION_RELATIVE"] = 843;
        return values;
    })();

    hdyRpc.Request = (function() {

        /**
         * Properties of a Request.
         * @memberof hdyRpc
         * @interface IRequest
         * @property {hdyRpc.RequestMethods|null} [method] Request method
         * @property {hdyRpc.RequestCmdGetServerTime|null} [requestCmdGetServerTime] Request requestCmdGetServerTime
         * @property {hdyRpc.RequestCmdWsHandshake|null} [requestCmdWsHandshake] Request requestCmdWsHandshake
         * @property {hdyRpc.RequestCmdGenerateConnectionKey|null} [requestCmdGenerateConnectionKey] Request requestCmdGenerateConnectionKey
         * @property {hdyRpc.RequestCmdSetConnectionKey|null} [requestCmdSetConnectionKey] Request requestCmdSetConnectionKey
         * @property {hdyRpc.RequestCmdStrokeGetStroke|null} [requestCmdStrokeGetStroke] Request requestCmdStrokeGetStroke
         * @property {number|null} [id] Request id
         */

        /**
         * Constructs a new Request.
         * @memberof hdyRpc
         * @classdesc Represents a Request.
         * @implements IRequest
         * @constructor
         * @param {hdyRpc.IRequest=} [properties] Properties to set
         */
        function Request(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Request method.
         * @member {hdyRpc.RequestMethods} method
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.method = 0;

        /**
         * Request requestCmdGetServerTime.
         * @member {hdyRpc.RequestCmdGetServerTime|null|undefined} requestCmdGetServerTime
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.requestCmdGetServerTime = null;

        /**
         * Request requestCmdWsHandshake.
         * @member {hdyRpc.RequestCmdWsHandshake|null|undefined} requestCmdWsHandshake
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.requestCmdWsHandshake = null;

        /**
         * Request requestCmdGenerateConnectionKey.
         * @member {hdyRpc.RequestCmdGenerateConnectionKey|null|undefined} requestCmdGenerateConnectionKey
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.requestCmdGenerateConnectionKey = null;

        /**
         * Request requestCmdSetConnectionKey.
         * @member {hdyRpc.RequestCmdSetConnectionKey|null|undefined} requestCmdSetConnectionKey
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.requestCmdSetConnectionKey = null;

        /**
         * Request requestCmdStrokeGetStroke.
         * @member {hdyRpc.RequestCmdStrokeGetStroke|null|undefined} requestCmdStrokeGetStroke
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.requestCmdStrokeGetStroke = null;

        /**
         * Request id.
         * @member {number} id
         * @memberof hdyRpc.Request
         * @instance
         */
        Request.prototype.id = 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Request params.
         * @member {"requestCmdGetServerTime"|"requestCmdWsHandshake"|"requestCmdGenerateConnectionKey"|"requestCmdSetConnectionKey"|"requestCmdStrokeGetStroke"|undefined} params
         * @memberof hdyRpc.Request
         * @instance
         */
        Object.defineProperty(Request.prototype, "params", {
            get: $util.oneOfGetter($oneOfFields = ["requestCmdGetServerTime", "requestCmdWsHandshake", "requestCmdGenerateConnectionKey", "requestCmdSetConnectionKey", "requestCmdStrokeGetStroke"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Request instance using the specified properties.
         * @function create
         * @memberof hdyRpc.Request
         * @static
         * @param {hdyRpc.IRequest=} [properties] Properties to set
         * @returns {hdyRpc.Request} Request instance
         */
        Request.create = function create(properties) {
            return new Request(properties);
        };

        /**
         * Encodes the specified Request message. Does not implicitly {@link hdyRpc.Request.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.Request
         * @static
         * @param {hdyRpc.Request} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.method != null && Object.hasOwnProperty.call(message, "method"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.method);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.id);
            if (message.requestCmdGetServerTime != null && Object.hasOwnProperty.call(message, "requestCmdGetServerTime"))
                $root.hdyRpc.RequestCmdGetServerTime.encode(message.requestCmdGetServerTime, writer.uint32(/* id 300, wireType 2 =*/2402).fork()).ldelim();
            if (message.requestCmdWsHandshake != null && Object.hasOwnProperty.call(message, "requestCmdWsHandshake"))
                $root.hdyRpc.RequestCmdWsHandshake.encode(message.requestCmdWsHandshake, writer.uint32(/* id 500, wireType 2 =*/4002).fork()).ldelim();
            if (message.requestCmdGenerateConnectionKey != null && Object.hasOwnProperty.call(message, "requestCmdGenerateConnectionKey"))
                $root.hdyRpc.RequestCmdGenerateConnectionKey.encode(message.requestCmdGenerateConnectionKey, writer.uint32(/* id 501, wireType 2 =*/4010).fork()).ldelim();
            if (message.requestCmdSetConnectionKey != null && Object.hasOwnProperty.call(message, "requestCmdSetConnectionKey"))
                $root.hdyRpc.RequestCmdSetConnectionKey.encode(message.requestCmdSetConnectionKey, writer.uint32(/* id 502, wireType 2 =*/4018).fork()).ldelim();
            if (message.requestCmdStrokeGetStroke != null && Object.hasOwnProperty.call(message, "requestCmdStrokeGetStroke"))
                $root.hdyRpc.RequestCmdStrokeGetStroke.encode(message.requestCmdStrokeGetStroke, writer.uint32(/* id 840, wireType 2 =*/6722).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Request message, length delimited. Does not implicitly {@link hdyRpc.Request.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.Request
         * @static
         * @param {hdyRpc.Request} message Request message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Request.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Request message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.Request();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.method = reader.int32();
                    break;
                case 300:
                    message.requestCmdGetServerTime = $root.hdyRpc.RequestCmdGetServerTime.decode(reader, reader.uint32());
                    break;
                case 500:
                    message.requestCmdWsHandshake = $root.hdyRpc.RequestCmdWsHandshake.decode(reader, reader.uint32());
                    break;
                case 501:
                    message.requestCmdGenerateConnectionKey = $root.hdyRpc.RequestCmdGenerateConnectionKey.decode(reader, reader.uint32());
                    break;
                case 502:
                    message.requestCmdSetConnectionKey = $root.hdyRpc.RequestCmdSetConnectionKey.decode(reader, reader.uint32());
                    break;
                case 840:
                    message.requestCmdStrokeGetStroke = $root.hdyRpc.RequestCmdStrokeGetStroke.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.id = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Request message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.Request
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.Request} Request
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Request.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Request message.
         * @function verify
         * @memberof hdyRpc.Request
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Request.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.method != null && message.hasOwnProperty("method"))
                switch (message.method) {
                default:
                    return "method: enum value expected";
                case 0:
                case 300:
                case 500:
                case 501:
                case 502:
                case 700:
                case 701:
                case 702:
                case 703:
                case 704:
                case 705:
                case 720:
                case 721:
                case 722:
                case 723:
                case 724:
                case 740:
                case 741:
                case 742:
                case 743:
                case 744:
                case 745:
                case 780:
                case 781:
                case 782:
                case 783:
                case 784:
                case 800:
                case 801:
                case 802:
                case 820:
                case 840:
                case 841:
                case 842:
                case 843:
                    break;
                }
            if (message.requestCmdGetServerTime != null && message.hasOwnProperty("requestCmdGetServerTime")) {
                properties.params = 1;
                {
                    let error = $root.hdyRpc.RequestCmdGetServerTime.verify(message.requestCmdGetServerTime);
                    if (error)
                        return "requestCmdGetServerTime." + error;
                }
            }
            if (message.requestCmdWsHandshake != null && message.hasOwnProperty("requestCmdWsHandshake")) {
                if (properties.params === 1)
                    return "params: multiple values";
                properties.params = 1;
                {
                    let error = $root.hdyRpc.RequestCmdWsHandshake.verify(message.requestCmdWsHandshake);
                    if (error)
                        return "requestCmdWsHandshake." + error;
                }
            }
            if (message.requestCmdGenerateConnectionKey != null && message.hasOwnProperty("requestCmdGenerateConnectionKey")) {
                if (properties.params === 1)
                    return "params: multiple values";
                properties.params = 1;
                {
                    let error = $root.hdyRpc.RequestCmdGenerateConnectionKey.verify(message.requestCmdGenerateConnectionKey);
                    if (error)
                        return "requestCmdGenerateConnectionKey." + error;
                }
            }
            if (message.requestCmdSetConnectionKey != null && message.hasOwnProperty("requestCmdSetConnectionKey")) {
                if (properties.params === 1)
                    return "params: multiple values";
                properties.params = 1;
                {
                    let error = $root.hdyRpc.RequestCmdSetConnectionKey.verify(message.requestCmdSetConnectionKey);
                    if (error)
                        return "requestCmdSetConnectionKey." + error;
                }
            }
            if (message.requestCmdStrokeGetStroke != null && message.hasOwnProperty("requestCmdStrokeGetStroke")) {
                if (properties.params === 1)
                    return "params: multiple values";
                properties.params = 1;
                {
                    let error = $root.hdyRpc.RequestCmdStrokeGetStroke.verify(message.requestCmdStrokeGetStroke);
                    if (error)
                        return "requestCmdStrokeGetStroke." + error;
                }
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            return null;
        };

        /**
         * Creates a Request message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.Request
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.Request} Request
         */
        Request.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.Request)
                return object;
            let message = new $root.hdyRpc.Request();
            switch (object.method) {
            case "REQUEST_METHOD_UNKNOWN":
            case 0:
                message.method = 0;
                break;
            case "REQUEST_METHOD_GET_SERVER_TIME":
            case 300:
                message.method = 300;
                break;
            case "REQUEST_METHOD_WS_HANDSHAKE":
            case 500:
                message.method = 500;
                break;
            case "REQUEST_METHOD_GENERATE_CONNECTION_KEY":
            case 501:
                message.method = 501;
                break;
            case "REQUEST_METHOD_SET_CONNECTION_KEY":
            case 502:
                message.method = 502;
                break;
            case "REQUEST_METHOD_GET_MODE":
            case 700:
                message.method = 700;
                break;
            case "REQUEST_METHOD_SET_MODE":
            case 701:
                message.method = 701;
                break;
            case "REQUEST_METHOD_GET_CONNECTED":
            case 702:
                message.method = 702;
                break;
            case "REQUEST_METHOD_GET_INFO":
            case 703:
                message.method = 703;
                break;
            case "REQUEST_METHOD_GET_SETTINGS":
            case 704:
                message.method = 704;
                break;
            case "REQUEST_METHOD_GET_STATUS":
            case 705:
                message.method = 705;
                break;
            case "REQUEST_METHOD_HAMP_SET_START":
            case 720:
                message.method = 720;
                break;
            case "REQUEST_METHOD_HAMP_SET_STOP":
            case 721:
                message.method = 721;
                break;
            case "REQUEST_METHOD_HAMP_GET_VELOCITY":
            case 722:
                message.method = 722;
                break;
            case "REQUEST_METHOD_HAMP_SET_VELOCITY":
            case 723:
                message.method = 723;
                break;
            case "REQUEST_METHOD_HAMP_GET_STATE":
            case 724:
                message.method = 724;
                break;
            case "REQUEST_METHOD_HDSP_SET_XAVA":
            case 740:
                message.method = 740;
                break;
            case "REQUEST_METHOD_HDSP_SET_XPVA":
            case 741:
                message.method = 741;
                break;
            case "REQUEST_METHOD_HDSP_SET_XPVP":
            case 742:
                message.method = 742;
                break;
            case "REQUEST_METHOD_HDSP_SET_XAT":
            case 743:
                message.method = 743;
                break;
            case "REQUEST_METHOD_HDSP_SET_XPT":
            case 744:
                message.method = 744;
                break;
            case "REQUEST_METHOD_HDSP_SET_XAVP":
            case 745:
                message.method = 745;
                break;
            case "REQUEST_METHOD_HSTP_GET_TIME":
            case 780:
                message.method = 780;
                break;
            case "REQUEST_METHOD_HSTP_GET_OFFSET":
            case 781:
                message.method = 781;
                break;
            case "REQUEST_METHOD_HSTP_SET_OFFSET":
            case 782:
                message.method = 782;
                break;
            case "REQUEST_METHOD_HSTP_GET_RTD":
            case 783:
                message.method = 783;
                break;
            case "REQUEST_METHOD_HSTP_SET_SYNC":
            case 784:
                message.method = 784;
                break;
            case "REQUEST_METHOD_MAINTENANCE_SET_RESTART":
            case 800:
                message.method = 800;
                break;
            case "REQUEST_METHOD_MAINTENANCE_SET_UPDATE_PERFORM":
            case 801:
                message.method = 801;
                break;
            case "REQUEST_METHOD_MAINTENANCE_SET_UPDATE_STATUS":
            case 802:
                message.method = 802;
                break;
            case "REQUEST_METHOD_OTA_GET_LATEST":
            case 820:
                message.method = 820;
                break;
            case "REQUEST_METHOD_STROKE_GET_STROKE":
            case 840:
                message.method = 840;
                break;
            case "REQUEST_METHOD_STROKE_SET_STROKE":
            case 841:
                message.method = 841;
                break;
            case "REQUEST_METHOD_STROKE_GET_STROKE_POSITION_ABSOLUTE":
            case 842:
                message.method = 842;
                break;
            case "REQUEST_METHOD_STROKE_GET_STROKE_POSITION_RELATIVE":
            case 843:
                message.method = 843;
                break;
            }
            if (object.requestCmdGetServerTime != null) {
                if (typeof object.requestCmdGetServerTime !== "object")
                    throw TypeError(".hdyRpc.Request.requestCmdGetServerTime: object expected");
                message.requestCmdGetServerTime = $root.hdyRpc.RequestCmdGetServerTime.fromObject(object.requestCmdGetServerTime);
            }
            if (object.requestCmdWsHandshake != null) {
                if (typeof object.requestCmdWsHandshake !== "object")
                    throw TypeError(".hdyRpc.Request.requestCmdWsHandshake: object expected");
                message.requestCmdWsHandshake = $root.hdyRpc.RequestCmdWsHandshake.fromObject(object.requestCmdWsHandshake);
            }
            if (object.requestCmdGenerateConnectionKey != null) {
                if (typeof object.requestCmdGenerateConnectionKey !== "object")
                    throw TypeError(".hdyRpc.Request.requestCmdGenerateConnectionKey: object expected");
                message.requestCmdGenerateConnectionKey = $root.hdyRpc.RequestCmdGenerateConnectionKey.fromObject(object.requestCmdGenerateConnectionKey);
            }
            if (object.requestCmdSetConnectionKey != null) {
                if (typeof object.requestCmdSetConnectionKey !== "object")
                    throw TypeError(".hdyRpc.Request.requestCmdSetConnectionKey: object expected");
                message.requestCmdSetConnectionKey = $root.hdyRpc.RequestCmdSetConnectionKey.fromObject(object.requestCmdSetConnectionKey);
            }
            if (object.requestCmdStrokeGetStroke != null) {
                if (typeof object.requestCmdStrokeGetStroke !== "object")
                    throw TypeError(".hdyRpc.Request.requestCmdStrokeGetStroke: object expected");
                message.requestCmdStrokeGetStroke = $root.hdyRpc.RequestCmdStrokeGetStroke.fromObject(object.requestCmdStrokeGetStroke);
            }
            if (object.id != null)
                message.id = object.id >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Request message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.Request
         * @static
         * @param {hdyRpc.Request} message Request
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Request.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.method = options.enums === String ? "REQUEST_METHOD_UNKNOWN" : 0;
                object.id = 0;
            }
            if (message.method != null && message.hasOwnProperty("method"))
                object.method = options.enums === String ? $root.hdyRpc.RequestMethods[message.method] : message.method;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.requestCmdGetServerTime != null && message.hasOwnProperty("requestCmdGetServerTime")) {
                object.requestCmdGetServerTime = $root.hdyRpc.RequestCmdGetServerTime.toObject(message.requestCmdGetServerTime, options);
                if (options.oneofs)
                    object.params = "requestCmdGetServerTime";
            }
            if (message.requestCmdWsHandshake != null && message.hasOwnProperty("requestCmdWsHandshake")) {
                object.requestCmdWsHandshake = $root.hdyRpc.RequestCmdWsHandshake.toObject(message.requestCmdWsHandshake, options);
                if (options.oneofs)
                    object.params = "requestCmdWsHandshake";
            }
            if (message.requestCmdGenerateConnectionKey != null && message.hasOwnProperty("requestCmdGenerateConnectionKey")) {
                object.requestCmdGenerateConnectionKey = $root.hdyRpc.RequestCmdGenerateConnectionKey.toObject(message.requestCmdGenerateConnectionKey, options);
                if (options.oneofs)
                    object.params = "requestCmdGenerateConnectionKey";
            }
            if (message.requestCmdSetConnectionKey != null && message.hasOwnProperty("requestCmdSetConnectionKey")) {
                object.requestCmdSetConnectionKey = $root.hdyRpc.RequestCmdSetConnectionKey.toObject(message.requestCmdSetConnectionKey, options);
                if (options.oneofs)
                    object.params = "requestCmdSetConnectionKey";
            }
            if (message.requestCmdStrokeGetStroke != null && message.hasOwnProperty("requestCmdStrokeGetStroke")) {
                object.requestCmdStrokeGetStroke = $root.hdyRpc.RequestCmdStrokeGetStroke.toObject(message.requestCmdStrokeGetStroke, options);
                if (options.oneofs)
                    object.params = "requestCmdStrokeGetStroke";
            }
            return object;
        };

        /**
         * Converts this Request to JSON.
         * @function toJSON
         * @memberof hdyRpc.Request
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Request.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Request;
    })();

    hdyRpc.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof hdyRpc
         * @interface IError
         * @property {number|null} [code] Error code
         * @property {string|null} [message] Error message
         * @property {string|null} [data] Error data
         */

        /**
         * Constructs a new Error.
         * @memberof hdyRpc
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {hdyRpc.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error code.
         * @member {number} code
         * @memberof hdyRpc.Error
         * @instance
         */
        Error.prototype.code = 0;

        /**
         * Error message.
         * @member {string} message
         * @memberof hdyRpc.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Error data.
         * @member {string} data
         * @memberof hdyRpc.Error
         * @instance
         */
        Error.prototype.data = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof hdyRpc.Error
         * @static
         * @param {hdyRpc.IError=} [properties] Properties to set
         * @returns {hdyRpc.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link hdyRpc.Error.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.Error
         * @static
         * @param {hdyRpc.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.code);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.data);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link hdyRpc.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.Error
         * @static
         * @param {hdyRpc.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.Error();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.uint32();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.data = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof hdyRpc.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!$util.isString(message.data))
                    return "data: string expected";
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.Error)
                return object;
            let message = new $root.hdyRpc.Error();
            if (object.code != null)
                message.code = object.code >>> 0;
            if (object.message != null)
                message.message = String(object.message);
            if (object.data != null)
                message.data = String(object.data);
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.Error
         * @static
         * @param {hdyRpc.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.message = "";
                object.data = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = message.data;
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof hdyRpc.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Error;
    })();

    hdyRpc.Response = (function() {

        /**
         * Properties of a Response.
         * @memberof hdyRpc
         * @interface IResponse
         * @property {number|null} [id] Response id
         * @property {hdyRpc.ResponseCmdGetServerTime|null} [responseCmdGetServerTime] Response responseCmdGetServerTime
         * @property {hdyRpc.ResponseCmdWsHandshake|null} [responseCmdWsHandshake] Response responseCmdWsHandshake
         * @property {hdyRpc.ResponseCmdGenerateConnectionKey|null} [responseCmdGenerateConnectionKey] Response responseCmdGenerateConnectionKey
         * @property {hdyRpc.ResponseCmdSetConnectionKey|null} [responseCmdSetConnectionKey] Response responseCmdSetConnectionKey
         * @property {hdyRpc.ResponseCmdStrokeGetStroke|null} [responseCmdStrokeGetStroke] Response responseCmdStrokeGetStroke
         * @property {hdyRpc.Error|null} [error] Response error
         */

        /**
         * Constructs a new Response.
         * @memberof hdyRpc
         * @classdesc Represents a Response.
         * @implements IResponse
         * @constructor
         * @param {hdyRpc.IResponse=} [properties] Properties to set
         */
        function Response(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Response id.
         * @member {number} id
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.id = 0;

        /**
         * Response responseCmdGetServerTime.
         * @member {hdyRpc.ResponseCmdGetServerTime|null|undefined} responseCmdGetServerTime
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.responseCmdGetServerTime = null;

        /**
         * Response responseCmdWsHandshake.
         * @member {hdyRpc.ResponseCmdWsHandshake|null|undefined} responseCmdWsHandshake
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.responseCmdWsHandshake = null;

        /**
         * Response responseCmdGenerateConnectionKey.
         * @member {hdyRpc.ResponseCmdGenerateConnectionKey|null|undefined} responseCmdGenerateConnectionKey
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.responseCmdGenerateConnectionKey = null;

        /**
         * Response responseCmdSetConnectionKey.
         * @member {hdyRpc.ResponseCmdSetConnectionKey|null|undefined} responseCmdSetConnectionKey
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.responseCmdSetConnectionKey = null;

        /**
         * Response responseCmdStrokeGetStroke.
         * @member {hdyRpc.ResponseCmdStrokeGetStroke|null|undefined} responseCmdStrokeGetStroke
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.responseCmdStrokeGetStroke = null;

        /**
         * Response error.
         * @member {hdyRpc.Error|null|undefined} error
         * @memberof hdyRpc.Response
         * @instance
         */
        Response.prototype.error = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Response result.
         * @member {"responseCmdGetServerTime"|"responseCmdWsHandshake"|"responseCmdGenerateConnectionKey"|"responseCmdSetConnectionKey"|"responseCmdStrokeGetStroke"|undefined} result
         * @memberof hdyRpc.Response
         * @instance
         */
        Object.defineProperty(Response.prototype, "result", {
            get: $util.oneOfGetter($oneOfFields = ["responseCmdGetServerTime", "responseCmdWsHandshake", "responseCmdGenerateConnectionKey", "responseCmdSetConnectionKey", "responseCmdStrokeGetStroke"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Response instance using the specified properties.
         * @function create
         * @memberof hdyRpc.Response
         * @static
         * @param {hdyRpc.IResponse=} [properties] Properties to set
         * @returns {hdyRpc.Response} Response instance
         */
        Response.create = function create(properties) {
            return new Response(properties);
        };

        /**
         * Encodes the specified Response message. Does not implicitly {@link hdyRpc.Response.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.Response
         * @static
         * @param {hdyRpc.Response} message Response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Response.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.hdyRpc.Error.encode(message.error, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.responseCmdGetServerTime != null && Object.hasOwnProperty.call(message, "responseCmdGetServerTime"))
                $root.hdyRpc.ResponseCmdGetServerTime.encode(message.responseCmdGetServerTime, writer.uint32(/* id 300, wireType 2 =*/2402).fork()).ldelim();
            if (message.responseCmdWsHandshake != null && Object.hasOwnProperty.call(message, "responseCmdWsHandshake"))
                $root.hdyRpc.ResponseCmdWsHandshake.encode(message.responseCmdWsHandshake, writer.uint32(/* id 500, wireType 2 =*/4002).fork()).ldelim();
            if (message.responseCmdGenerateConnectionKey != null && Object.hasOwnProperty.call(message, "responseCmdGenerateConnectionKey"))
                $root.hdyRpc.ResponseCmdGenerateConnectionKey.encode(message.responseCmdGenerateConnectionKey, writer.uint32(/* id 501, wireType 2 =*/4010).fork()).ldelim();
            if (message.responseCmdSetConnectionKey != null && Object.hasOwnProperty.call(message, "responseCmdSetConnectionKey"))
                $root.hdyRpc.ResponseCmdSetConnectionKey.encode(message.responseCmdSetConnectionKey, writer.uint32(/* id 502, wireType 2 =*/4018).fork()).ldelim();
            if (message.responseCmdStrokeGetStroke != null && Object.hasOwnProperty.call(message, "responseCmdStrokeGetStroke"))
                $root.hdyRpc.ResponseCmdStrokeGetStroke.encode(message.responseCmdStrokeGetStroke, writer.uint32(/* id 840, wireType 2 =*/6722).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Response message, length delimited. Does not implicitly {@link hdyRpc.Response.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.Response
         * @static
         * @param {hdyRpc.Response} message Response message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Response.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Response message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.Response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.Response} Response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Response.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.Response();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.uint32();
                    break;
                case 300:
                    message.responseCmdGetServerTime = $root.hdyRpc.ResponseCmdGetServerTime.decode(reader, reader.uint32());
                    break;
                case 500:
                    message.responseCmdWsHandshake = $root.hdyRpc.ResponseCmdWsHandshake.decode(reader, reader.uint32());
                    break;
                case 501:
                    message.responseCmdGenerateConnectionKey = $root.hdyRpc.ResponseCmdGenerateConnectionKey.decode(reader, reader.uint32());
                    break;
                case 502:
                    message.responseCmdSetConnectionKey = $root.hdyRpc.ResponseCmdSetConnectionKey.decode(reader, reader.uint32());
                    break;
                case 840:
                    message.responseCmdStrokeGetStroke = $root.hdyRpc.ResponseCmdStrokeGetStroke.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.error = $root.hdyRpc.Error.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Response message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.Response
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.Response} Response
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Response.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Response message.
         * @function verify
         * @memberof hdyRpc.Response
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Response.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.responseCmdGetServerTime != null && message.hasOwnProperty("responseCmdGetServerTime")) {
                properties.result = 1;
                {
                    let error = $root.hdyRpc.ResponseCmdGetServerTime.verify(message.responseCmdGetServerTime);
                    if (error)
                        return "responseCmdGetServerTime." + error;
                }
            }
            if (message.responseCmdWsHandshake != null && message.hasOwnProperty("responseCmdWsHandshake")) {
                if (properties.result === 1)
                    return "result: multiple values";
                properties.result = 1;
                {
                    let error = $root.hdyRpc.ResponseCmdWsHandshake.verify(message.responseCmdWsHandshake);
                    if (error)
                        return "responseCmdWsHandshake." + error;
                }
            }
            if (message.responseCmdGenerateConnectionKey != null && message.hasOwnProperty("responseCmdGenerateConnectionKey")) {
                if (properties.result === 1)
                    return "result: multiple values";
                properties.result = 1;
                {
                    let error = $root.hdyRpc.ResponseCmdGenerateConnectionKey.verify(message.responseCmdGenerateConnectionKey);
                    if (error)
                        return "responseCmdGenerateConnectionKey." + error;
                }
            }
            if (message.responseCmdSetConnectionKey != null && message.hasOwnProperty("responseCmdSetConnectionKey")) {
                if (properties.result === 1)
                    return "result: multiple values";
                properties.result = 1;
                {
                    let error = $root.hdyRpc.ResponseCmdSetConnectionKey.verify(message.responseCmdSetConnectionKey);
                    if (error)
                        return "responseCmdSetConnectionKey." + error;
                }
            }
            if (message.responseCmdStrokeGetStroke != null && message.hasOwnProperty("responseCmdStrokeGetStroke")) {
                if (properties.result === 1)
                    return "result: multiple values";
                properties.result = 1;
                {
                    let error = $root.hdyRpc.ResponseCmdStrokeGetStroke.verify(message.responseCmdStrokeGetStroke);
                    if (error)
                        return "responseCmdStrokeGetStroke." + error;
                }
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.hdyRpc.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            return null;
        };

        /**
         * Creates a Response message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.Response
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.Response} Response
         */
        Response.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.Response)
                return object;
            let message = new $root.hdyRpc.Response();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.responseCmdGetServerTime != null) {
                if (typeof object.responseCmdGetServerTime !== "object")
                    throw TypeError(".hdyRpc.Response.responseCmdGetServerTime: object expected");
                message.responseCmdGetServerTime = $root.hdyRpc.ResponseCmdGetServerTime.fromObject(object.responseCmdGetServerTime);
            }
            if (object.responseCmdWsHandshake != null) {
                if (typeof object.responseCmdWsHandshake !== "object")
                    throw TypeError(".hdyRpc.Response.responseCmdWsHandshake: object expected");
                message.responseCmdWsHandshake = $root.hdyRpc.ResponseCmdWsHandshake.fromObject(object.responseCmdWsHandshake);
            }
            if (object.responseCmdGenerateConnectionKey != null) {
                if (typeof object.responseCmdGenerateConnectionKey !== "object")
                    throw TypeError(".hdyRpc.Response.responseCmdGenerateConnectionKey: object expected");
                message.responseCmdGenerateConnectionKey = $root.hdyRpc.ResponseCmdGenerateConnectionKey.fromObject(object.responseCmdGenerateConnectionKey);
            }
            if (object.responseCmdSetConnectionKey != null) {
                if (typeof object.responseCmdSetConnectionKey !== "object")
                    throw TypeError(".hdyRpc.Response.responseCmdSetConnectionKey: object expected");
                message.responseCmdSetConnectionKey = $root.hdyRpc.ResponseCmdSetConnectionKey.fromObject(object.responseCmdSetConnectionKey);
            }
            if (object.responseCmdStrokeGetStroke != null) {
                if (typeof object.responseCmdStrokeGetStroke !== "object")
                    throw TypeError(".hdyRpc.Response.responseCmdStrokeGetStroke: object expected");
                message.responseCmdStrokeGetStroke = $root.hdyRpc.ResponseCmdStrokeGetStroke.fromObject(object.responseCmdStrokeGetStroke);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".hdyRpc.Response.error: object expected");
                message.error = $root.hdyRpc.Error.fromObject(object.error);
            }
            return message;
        };

        /**
         * Creates a plain object from a Response message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.Response
         * @static
         * @param {hdyRpc.Response} message Response
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Response.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.error = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.hdyRpc.Error.toObject(message.error, options);
            if (message.responseCmdGetServerTime != null && message.hasOwnProperty("responseCmdGetServerTime")) {
                object.responseCmdGetServerTime = $root.hdyRpc.ResponseCmdGetServerTime.toObject(message.responseCmdGetServerTime, options);
                if (options.oneofs)
                    object.result = "responseCmdGetServerTime";
            }
            if (message.responseCmdWsHandshake != null && message.hasOwnProperty("responseCmdWsHandshake")) {
                object.responseCmdWsHandshake = $root.hdyRpc.ResponseCmdWsHandshake.toObject(message.responseCmdWsHandshake, options);
                if (options.oneofs)
                    object.result = "responseCmdWsHandshake";
            }
            if (message.responseCmdGenerateConnectionKey != null && message.hasOwnProperty("responseCmdGenerateConnectionKey")) {
                object.responseCmdGenerateConnectionKey = $root.hdyRpc.ResponseCmdGenerateConnectionKey.toObject(message.responseCmdGenerateConnectionKey, options);
                if (options.oneofs)
                    object.result = "responseCmdGenerateConnectionKey";
            }
            if (message.responseCmdSetConnectionKey != null && message.hasOwnProperty("responseCmdSetConnectionKey")) {
                object.responseCmdSetConnectionKey = $root.hdyRpc.ResponseCmdSetConnectionKey.toObject(message.responseCmdSetConnectionKey, options);
                if (options.oneofs)
                    object.result = "responseCmdSetConnectionKey";
            }
            if (message.responseCmdStrokeGetStroke != null && message.hasOwnProperty("responseCmdStrokeGetStroke")) {
                object.responseCmdStrokeGetStroke = $root.hdyRpc.ResponseCmdStrokeGetStroke.toObject(message.responseCmdStrokeGetStroke, options);
                if (options.oneofs)
                    object.result = "responseCmdStrokeGetStroke";
            }
            return object;
        };

        /**
         * Converts this Response to JSON.
         * @function toJSON
         * @memberof hdyRpc.Response
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Response.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Response;
    })();

    /**
     * MessageType enum.
     * @name hdyRpc.MessageType
     * @enum {number}
     * @property {number} MESSAGE_TYPE_UNKNOWN=0 MESSAGE_TYPE_UNKNOWN value
     * @property {number} MESSAGE_TYPE_REQUEST=1 MESSAGE_TYPE_REQUEST value
     * @property {number} MESSAGE_TYPE_RESPONSE=2 MESSAGE_TYPE_RESPONSE value
     */
    hdyRpc.MessageType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGE_TYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "MESSAGE_TYPE_REQUEST"] = 1;
        values[valuesById[2] = "MESSAGE_TYPE_RESPONSE"] = 2;
        return values;
    })();

    hdyRpc.RpcMessage = (function() {

        /**
         * Properties of a RpcMessage.
         * @memberof hdyRpc
         * @interface IRpcMessage
         * @property {hdyRpc.MessageType|null} [type] RpcMessage type
         * @property {hdyRpc.Request|null} [request] RpcMessage request
         * @property {hdyRpc.Response|null} [response] RpcMessage response
         */

        /**
         * Constructs a new RpcMessage.
         * @memberof hdyRpc
         * @classdesc Represents a RpcMessage.
         * @implements IRpcMessage
         * @constructor
         * @param {hdyRpc.IRpcMessage=} [properties] Properties to set
         */
        function RpcMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcMessage type.
         * @member {hdyRpc.MessageType} type
         * @memberof hdyRpc.RpcMessage
         * @instance
         */
        RpcMessage.prototype.type = 0;

        /**
         * RpcMessage request.
         * @member {hdyRpc.Request|null|undefined} request
         * @memberof hdyRpc.RpcMessage
         * @instance
         */
        RpcMessage.prototype.request = null;

        /**
         * RpcMessage response.
         * @member {hdyRpc.Response|null|undefined} response
         * @memberof hdyRpc.RpcMessage
         * @instance
         */
        RpcMessage.prototype.response = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RpcMessage message.
         * @member {"request"|"response"|undefined} message
         * @memberof hdyRpc.RpcMessage
         * @instance
         */
        Object.defineProperty(RpcMessage.prototype, "message", {
            get: $util.oneOfGetter($oneOfFields = ["request", "response"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RpcMessage instance using the specified properties.
         * @function create
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {hdyRpc.IRpcMessage=} [properties] Properties to set
         * @returns {hdyRpc.RpcMessage} RpcMessage instance
         */
        RpcMessage.create = function create(properties) {
            return new RpcMessage(properties);
        };

        /**
         * Encodes the specified RpcMessage message. Does not implicitly {@link hdyRpc.RpcMessage.verify|verify} messages.
         * @function encode
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {hdyRpc.RpcMessage} message RpcMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.request != null && Object.hasOwnProperty.call(message, "request"))
                $root.hdyRpc.Request.encode(message.request, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                $root.hdyRpc.Response.encode(message.response, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcMessage message, length delimited. Does not implicitly {@link hdyRpc.RpcMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {hdyRpc.RpcMessage} message RpcMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcMessage message from the specified reader or buffer.
         * @function decode
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hdyRpc.RpcMessage} RpcMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.hdyRpc.RpcMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.request = $root.hdyRpc.Request.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.response = $root.hdyRpc.Response.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hdyRpc.RpcMessage} RpcMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcMessage message.
         * @function verify
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.request != null && message.hasOwnProperty("request")) {
                properties.message = 1;
                {
                    let error = $root.hdyRpc.Request.verify(message.request);
                    if (error)
                        return "request." + error;
                }
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                if (properties.message === 1)
                    return "message: multiple values";
                properties.message = 1;
                {
                    let error = $root.hdyRpc.Response.verify(message.response);
                    if (error)
                        return "response." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RpcMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hdyRpc.RpcMessage} RpcMessage
         */
        RpcMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.hdyRpc.RpcMessage)
                return object;
            let message = new $root.hdyRpc.RpcMessage();
            switch (object.type) {
            case "MESSAGE_TYPE_UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "MESSAGE_TYPE_REQUEST":
            case 1:
                message.type = 1;
                break;
            case "MESSAGE_TYPE_RESPONSE":
            case 2:
                message.type = 2;
                break;
            }
            if (object.request != null) {
                if (typeof object.request !== "object")
                    throw TypeError(".hdyRpc.RpcMessage.request: object expected");
                message.request = $root.hdyRpc.Request.fromObject(object.request);
            }
            if (object.response != null) {
                if (typeof object.response !== "object")
                    throw TypeError(".hdyRpc.RpcMessage.response: object expected");
                message.response = $root.hdyRpc.Response.fromObject(object.response);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hdyRpc.RpcMessage
         * @static
         * @param {hdyRpc.RpcMessage} message RpcMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.type = options.enums === String ? "MESSAGE_TYPE_UNKNOWN" : 0;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.hdyRpc.MessageType[message.type] : message.type;
            if (message.request != null && message.hasOwnProperty("request")) {
                object.request = $root.hdyRpc.Request.toObject(message.request, options);
                if (options.oneofs)
                    object.message = "request";
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                object.response = $root.hdyRpc.Response.toObject(message.response, options);
                if (options.oneofs)
                    object.message = "response";
            }
            return object;
        };

        /**
         * Converts this RpcMessage to JSON.
         * @function toJSON
         * @memberof hdyRpc.RpcMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RpcMessage;
    })();

    return hdyRpc;
})();

export const handyplug = $root.handyplug = (() => {

    /**
     * Namespace handyplug.
     * @exports handyplug
     * @namespace
     */
    const handyplug = {};

    handyplug.Ok = (function() {

        /**
         * Properties of an Ok.
         * @memberof handyplug
         * @interface IOk
         * @property {number|null} [Id] Ok Id
         */

        /**
         * Constructs a new Ok.
         * @memberof handyplug
         * @classdesc Represents an Ok.
         * @implements IOk
         * @constructor
         * @param {handyplug.IOk=} [properties] Properties to set
         */
        function Ok(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ok Id.
         * @member {number} Id
         * @memberof handyplug.Ok
         * @instance
         */
        Ok.prototype.Id = 0;

        /**
         * Creates a new Ok instance using the specified properties.
         * @function create
         * @memberof handyplug.Ok
         * @static
         * @param {handyplug.IOk=} [properties] Properties to set
         * @returns {handyplug.Ok} Ok instance
         */
        Ok.create = function create(properties) {
            return new Ok(properties);
        };

        /**
         * Encodes the specified Ok message. Does not implicitly {@link handyplug.Ok.verify|verify} messages.
         * @function encode
         * @memberof handyplug.Ok
         * @static
         * @param {handyplug.Ok} message Ok message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ok.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            return writer;
        };

        /**
         * Encodes the specified Ok message, length delimited. Does not implicitly {@link handyplug.Ok.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.Ok
         * @static
         * @param {handyplug.Ok} message Ok message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ok.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ok message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.Ok
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.Ok} Ok
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ok.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.Ok();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ok message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.Ok
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.Ok} Ok
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ok.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ok message.
         * @function verify
         * @memberof handyplug.Ok
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ok.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            return null;
        };

        /**
         * Creates an Ok message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.Ok
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.Ok} Ok
         */
        Ok.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.Ok)
                return object;
            let message = new $root.handyplug.Ok();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an Ok message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.Ok
         * @static
         * @param {handyplug.Ok} message Ok
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ok.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.Id = 0;
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            return object;
        };

        /**
         * Converts this Ok to JSON.
         * @function toJSON
         * @memberof handyplug.Ok
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ok.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ok;
    })();

    handyplug.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof handyplug
         * @interface IError
         * @property {number|null} [Id] Error Id
         * @property {string|null} [ErrorMessage] Error ErrorMessage
         * @property {number|null} [ErrorCode] Error ErrorCode
         */

        /**
         * Constructs a new Error.
         * @memberof handyplug
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {handyplug.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error Id.
         * @member {number} Id
         * @memberof handyplug.Error
         * @instance
         */
        Error.prototype.Id = 0;

        /**
         * Error ErrorMessage.
         * @member {string} ErrorMessage
         * @memberof handyplug.Error
         * @instance
         */
        Error.prototype.ErrorMessage = "";

        /**
         * Error ErrorCode.
         * @member {number} ErrorCode
         * @memberof handyplug.Error
         * @instance
         */
        Error.prototype.ErrorCode = 0;

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof handyplug.Error
         * @static
         * @param {handyplug.IError=} [properties] Properties to set
         * @returns {handyplug.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link handyplug.Error.verify|verify} messages.
         * @function encode
         * @memberof handyplug.Error
         * @static
         * @param {handyplug.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            if (message.ErrorMessage != null && Object.hasOwnProperty.call(message, "ErrorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ErrorMessage);
            if (message.ErrorCode != null && Object.hasOwnProperty.call(message, "ErrorCode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ErrorCode);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link handyplug.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.Error
         * @static
         * @param {handyplug.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.Error();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                case 2:
                    message.ErrorMessage = reader.string();
                    break;
                case 3:
                    message.ErrorCode = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof handyplug.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            if (message.ErrorMessage != null && message.hasOwnProperty("ErrorMessage"))
                if (!$util.isString(message.ErrorMessage))
                    return "ErrorMessage: string expected";
            if (message.ErrorCode != null && message.hasOwnProperty("ErrorCode"))
                if (!$util.isInteger(message.ErrorCode))
                    return "ErrorCode: integer expected";
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.Error)
                return object;
            let message = new $root.handyplug.Error();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            if (object.ErrorMessage != null)
                message.ErrorMessage = String(object.ErrorMessage);
            if (object.ErrorCode != null)
                message.ErrorCode = object.ErrorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.Error
         * @static
         * @param {handyplug.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.Id = 0;
                object.ErrorMessage = "";
                object.ErrorCode = 0;
            }
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            if (message.ErrorMessage != null && message.hasOwnProperty("ErrorMessage"))
                object.ErrorMessage = message.ErrorMessage;
            if (message.ErrorCode != null && message.hasOwnProperty("ErrorCode"))
                object.ErrorCode = message.ErrorCode;
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof handyplug.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Error;
    })();

    handyplug.Ping = (function() {

        /**
         * Properties of a Ping.
         * @memberof handyplug
         * @interface IPing
         * @property {number|null} [Id] Ping Id
         */

        /**
         * Constructs a new Ping.
         * @memberof handyplug
         * @classdesc Represents a Ping.
         * @implements IPing
         * @constructor
         * @param {handyplug.IPing=} [properties] Properties to set
         */
        function Ping(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ping Id.
         * @member {number} Id
         * @memberof handyplug.Ping
         * @instance
         */
        Ping.prototype.Id = 0;

        /**
         * Creates a new Ping instance using the specified properties.
         * @function create
         * @memberof handyplug.Ping
         * @static
         * @param {handyplug.IPing=} [properties] Properties to set
         * @returns {handyplug.Ping} Ping instance
         */
        Ping.create = function create(properties) {
            return new Ping(properties);
        };

        /**
         * Encodes the specified Ping message. Does not implicitly {@link handyplug.Ping.verify|verify} messages.
         * @function encode
         * @memberof handyplug.Ping
         * @static
         * @param {handyplug.Ping} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            return writer;
        };

        /**
         * Encodes the specified Ping message, length delimited. Does not implicitly {@link handyplug.Ping.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.Ping
         * @static
         * @param {handyplug.Ping} message Ping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ping.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Ping message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.Ping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Ping message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.Ping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.Ping} Ping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ping.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Ping message.
         * @function verify
         * @memberof handyplug.Ping
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ping.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            return null;
        };

        /**
         * Creates a Ping message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.Ping
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.Ping} Ping
         */
        Ping.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.Ping)
                return object;
            let message = new $root.handyplug.Ping();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Ping message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.Ping
         * @static
         * @param {handyplug.Ping} message Ping
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ping.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.Id = 0;
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            return object;
        };

        /**
         * Converts this Ping to JSON.
         * @function toJSON
         * @memberof handyplug.Ping
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ping.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ping;
    })();

    handyplug.RequestServerInfo = (function() {

        /**
         * Properties of a RequestServerInfo.
         * @memberof handyplug
         * @interface IRequestServerInfo
         * @property {number|null} [Id] RequestServerInfo Id
         * @property {string|null} [ClientName] RequestServerInfo ClientName
         * @property {number|null} [MessageVersion] RequestServerInfo MessageVersion
         */

        /**
         * Constructs a new RequestServerInfo.
         * @memberof handyplug
         * @classdesc Represents a RequestServerInfo.
         * @implements IRequestServerInfo
         * @constructor
         * @param {handyplug.IRequestServerInfo=} [properties] Properties to set
         */
        function RequestServerInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestServerInfo Id.
         * @member {number} Id
         * @memberof handyplug.RequestServerInfo
         * @instance
         */
        RequestServerInfo.prototype.Id = 0;

        /**
         * RequestServerInfo ClientName.
         * @member {string} ClientName
         * @memberof handyplug.RequestServerInfo
         * @instance
         */
        RequestServerInfo.prototype.ClientName = "";

        /**
         * RequestServerInfo MessageVersion.
         * @member {number} MessageVersion
         * @memberof handyplug.RequestServerInfo
         * @instance
         */
        RequestServerInfo.prototype.MessageVersion = 0;

        /**
         * Creates a new RequestServerInfo instance using the specified properties.
         * @function create
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {handyplug.IRequestServerInfo=} [properties] Properties to set
         * @returns {handyplug.RequestServerInfo} RequestServerInfo instance
         */
        RequestServerInfo.create = function create(properties) {
            return new RequestServerInfo(properties);
        };

        /**
         * Encodes the specified RequestServerInfo message. Does not implicitly {@link handyplug.RequestServerInfo.verify|verify} messages.
         * @function encode
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {handyplug.RequestServerInfo} message RequestServerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestServerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            if (message.ClientName != null && Object.hasOwnProperty.call(message, "ClientName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ClientName);
            if (message.MessageVersion != null && Object.hasOwnProperty.call(message, "MessageVersion"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.MessageVersion);
            return writer;
        };

        /**
         * Encodes the specified RequestServerInfo message, length delimited. Does not implicitly {@link handyplug.RequestServerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {handyplug.RequestServerInfo} message RequestServerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestServerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestServerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.RequestServerInfo} RequestServerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestServerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.RequestServerInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                case 2:
                    message.ClientName = reader.string();
                    break;
                case 3:
                    message.MessageVersion = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestServerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.RequestServerInfo} RequestServerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestServerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestServerInfo message.
         * @function verify
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestServerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            if (message.ClientName != null && message.hasOwnProperty("ClientName"))
                if (!$util.isString(message.ClientName))
                    return "ClientName: string expected";
            if (message.MessageVersion != null && message.hasOwnProperty("MessageVersion"))
                if (!$util.isInteger(message.MessageVersion))
                    return "MessageVersion: integer expected";
            return null;
        };

        /**
         * Creates a RequestServerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.RequestServerInfo} RequestServerInfo
         */
        RequestServerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.RequestServerInfo)
                return object;
            let message = new $root.handyplug.RequestServerInfo();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            if (object.ClientName != null)
                message.ClientName = String(object.ClientName);
            if (object.MessageVersion != null)
                message.MessageVersion = object.MessageVersion >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestServerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.RequestServerInfo
         * @static
         * @param {handyplug.RequestServerInfo} message RequestServerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestServerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.Id = 0;
                object.ClientName = "";
                object.MessageVersion = 0;
            }
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            if (message.ClientName != null && message.hasOwnProperty("ClientName"))
                object.ClientName = message.ClientName;
            if (message.MessageVersion != null && message.hasOwnProperty("MessageVersion"))
                object.MessageVersion = message.MessageVersion;
            return object;
        };

        /**
         * Converts this RequestServerInfo to JSON.
         * @function toJSON
         * @memberof handyplug.RequestServerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestServerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestServerInfo;
    })();

    handyplug.ServerInfo = (function() {

        /**
         * Properties of a ServerInfo.
         * @memberof handyplug
         * @interface IServerInfo
         * @property {number|null} [Id] ServerInfo Id
         * @property {string|null} [ServerName] ServerInfo ServerName
         * @property {number|null} [MessageVersion] ServerInfo MessageVersion
         * @property {number|null} [MaxPingTime] ServerInfo MaxPingTime
         */

        /**
         * Constructs a new ServerInfo.
         * @memberof handyplug
         * @classdesc Represents a ServerInfo.
         * @implements IServerInfo
         * @constructor
         * @param {handyplug.IServerInfo=} [properties] Properties to set
         */
        function ServerInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerInfo Id.
         * @member {number} Id
         * @memberof handyplug.ServerInfo
         * @instance
         */
        ServerInfo.prototype.Id = 0;

        /**
         * ServerInfo ServerName.
         * @member {string} ServerName
         * @memberof handyplug.ServerInfo
         * @instance
         */
        ServerInfo.prototype.ServerName = "";

        /**
         * ServerInfo MessageVersion.
         * @member {number} MessageVersion
         * @memberof handyplug.ServerInfo
         * @instance
         */
        ServerInfo.prototype.MessageVersion = 0;

        /**
         * ServerInfo MaxPingTime.
         * @member {number} MaxPingTime
         * @memberof handyplug.ServerInfo
         * @instance
         */
        ServerInfo.prototype.MaxPingTime = 0;

        /**
         * Creates a new ServerInfo instance using the specified properties.
         * @function create
         * @memberof handyplug.ServerInfo
         * @static
         * @param {handyplug.IServerInfo=} [properties] Properties to set
         * @returns {handyplug.ServerInfo} ServerInfo instance
         */
        ServerInfo.create = function create(properties) {
            return new ServerInfo(properties);
        };

        /**
         * Encodes the specified ServerInfo message. Does not implicitly {@link handyplug.ServerInfo.verify|verify} messages.
         * @function encode
         * @memberof handyplug.ServerInfo
         * @static
         * @param {handyplug.ServerInfo} message ServerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            if (message.ServerName != null && Object.hasOwnProperty.call(message, "ServerName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ServerName);
            if (message.MessageVersion != null && Object.hasOwnProperty.call(message, "MessageVersion"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.MessageVersion);
            if (message.MaxPingTime != null && Object.hasOwnProperty.call(message, "MaxPingTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.MaxPingTime);
            return writer;
        };

        /**
         * Encodes the specified ServerInfo message, length delimited. Does not implicitly {@link handyplug.ServerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.ServerInfo
         * @static
         * @param {handyplug.ServerInfo} message ServerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.ServerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.ServerInfo} ServerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.ServerInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                case 2:
                    message.ServerName = reader.string();
                    break;
                case 3:
                    message.MessageVersion = reader.uint32();
                    break;
                case 4:
                    message.MaxPingTime = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.ServerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.ServerInfo} ServerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerInfo message.
         * @function verify
         * @memberof handyplug.ServerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            if (message.ServerName != null && message.hasOwnProperty("ServerName"))
                if (!$util.isString(message.ServerName))
                    return "ServerName: string expected";
            if (message.MessageVersion != null && message.hasOwnProperty("MessageVersion"))
                if (!$util.isInteger(message.MessageVersion))
                    return "MessageVersion: integer expected";
            if (message.MaxPingTime != null && message.hasOwnProperty("MaxPingTime"))
                if (!$util.isInteger(message.MaxPingTime))
                    return "MaxPingTime: integer expected";
            return null;
        };

        /**
         * Creates a ServerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.ServerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.ServerInfo} ServerInfo
         */
        ServerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.ServerInfo)
                return object;
            let message = new $root.handyplug.ServerInfo();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            if (object.ServerName != null)
                message.ServerName = String(object.ServerName);
            if (object.MessageVersion != null)
                message.MessageVersion = object.MessageVersion >>> 0;
            if (object.MaxPingTime != null)
                message.MaxPingTime = object.MaxPingTime >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ServerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.ServerInfo
         * @static
         * @param {handyplug.ServerInfo} message ServerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.Id = 0;
                object.ServerName = "";
                object.MessageVersion = 0;
                object.MaxPingTime = 0;
            }
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            if (message.ServerName != null && message.hasOwnProperty("ServerName"))
                object.ServerName = message.ServerName;
            if (message.MessageVersion != null && message.hasOwnProperty("MessageVersion"))
                object.MessageVersion = message.MessageVersion;
            if (message.MaxPingTime != null && message.hasOwnProperty("MaxPingTime"))
                object.MaxPingTime = message.MaxPingTime;
            return object;
        };

        /**
         * Converts this ServerInfo to JSON.
         * @function toJSON
         * @memberof handyplug.ServerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServerInfo;
    })();

    handyplug.LinearCmd = (function() {

        /**
         * Properties of a LinearCmd.
         * @memberof handyplug
         * @interface ILinearCmd
         * @property {number|null} [Id] LinearCmd Id
         * @property {number|null} [DeviceIndex] LinearCmd DeviceIndex
         * @property {Array.<handyplug.LinearCmd.Vector>|null} [Vectors] LinearCmd Vectors
         */

        /**
         * Constructs a new LinearCmd.
         * @memberof handyplug
         * @classdesc Represents a LinearCmd.
         * @implements ILinearCmd
         * @constructor
         * @param {handyplug.ILinearCmd=} [properties] Properties to set
         */
        function LinearCmd(properties) {
            this.Vectors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LinearCmd Id.
         * @member {number} Id
         * @memberof handyplug.LinearCmd
         * @instance
         */
        LinearCmd.prototype.Id = 0;

        /**
         * LinearCmd DeviceIndex.
         * @member {number} DeviceIndex
         * @memberof handyplug.LinearCmd
         * @instance
         */
        LinearCmd.prototype.DeviceIndex = 0;

        /**
         * LinearCmd Vectors.
         * @member {Array.<handyplug.LinearCmd.Vector>} Vectors
         * @memberof handyplug.LinearCmd
         * @instance
         */
        LinearCmd.prototype.Vectors = $util.emptyArray;

        /**
         * Creates a new LinearCmd instance using the specified properties.
         * @function create
         * @memberof handyplug.LinearCmd
         * @static
         * @param {handyplug.ILinearCmd=} [properties] Properties to set
         * @returns {handyplug.LinearCmd} LinearCmd instance
         */
        LinearCmd.create = function create(properties) {
            return new LinearCmd(properties);
        };

        /**
         * Encodes the specified LinearCmd message. Does not implicitly {@link handyplug.LinearCmd.verify|verify} messages.
         * @function encode
         * @memberof handyplug.LinearCmd
         * @static
         * @param {handyplug.LinearCmd} message LinearCmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LinearCmd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Id);
            if (message.DeviceIndex != null && Object.hasOwnProperty.call(message, "DeviceIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.DeviceIndex);
            if (message.Vectors != null && message.Vectors.length)
                for (let i = 0; i < message.Vectors.length; ++i)
                    $root.handyplug.LinearCmd.Vector.encode(message.Vectors[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LinearCmd message, length delimited. Does not implicitly {@link handyplug.LinearCmd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.LinearCmd
         * @static
         * @param {handyplug.LinearCmd} message LinearCmd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LinearCmd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LinearCmd message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.LinearCmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.LinearCmd} LinearCmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LinearCmd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.LinearCmd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.Id = reader.uint32();
                    break;
                case 2:
                    message.DeviceIndex = reader.uint32();
                    break;
                case 3:
                    if (!(message.Vectors && message.Vectors.length))
                        message.Vectors = [];
                    message.Vectors.push($root.handyplug.LinearCmd.Vector.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LinearCmd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.LinearCmd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.LinearCmd} LinearCmd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LinearCmd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LinearCmd message.
         * @function verify
         * @memberof handyplug.LinearCmd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LinearCmd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Id != null && message.hasOwnProperty("Id"))
                if (!$util.isInteger(message.Id))
                    return "Id: integer expected";
            if (message.DeviceIndex != null && message.hasOwnProperty("DeviceIndex"))
                if (!$util.isInteger(message.DeviceIndex))
                    return "DeviceIndex: integer expected";
            if (message.Vectors != null && message.hasOwnProperty("Vectors")) {
                if (!Array.isArray(message.Vectors))
                    return "Vectors: array expected";
                for (let i = 0; i < message.Vectors.length; ++i) {
                    let error = $root.handyplug.LinearCmd.Vector.verify(message.Vectors[i]);
                    if (error)
                        return "Vectors." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LinearCmd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.LinearCmd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.LinearCmd} LinearCmd
         */
        LinearCmd.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.LinearCmd)
                return object;
            let message = new $root.handyplug.LinearCmd();
            if (object.Id != null)
                message.Id = object.Id >>> 0;
            if (object.DeviceIndex != null)
                message.DeviceIndex = object.DeviceIndex >>> 0;
            if (object.Vectors) {
                if (!Array.isArray(object.Vectors))
                    throw TypeError(".handyplug.LinearCmd.Vectors: array expected");
                message.Vectors = [];
                for (let i = 0; i < object.Vectors.length; ++i) {
                    if (typeof object.Vectors[i] !== "object")
                        throw TypeError(".handyplug.LinearCmd.Vectors: object expected");
                    message.Vectors[i] = $root.handyplug.LinearCmd.Vector.fromObject(object.Vectors[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LinearCmd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.LinearCmd
         * @static
         * @param {handyplug.LinearCmd} message LinearCmd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LinearCmd.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.Vectors = [];
            if (options.defaults) {
                object.Id = 0;
                object.DeviceIndex = 0;
            }
            if (message.Id != null && message.hasOwnProperty("Id"))
                object.Id = message.Id;
            if (message.DeviceIndex != null && message.hasOwnProperty("DeviceIndex"))
                object.DeviceIndex = message.DeviceIndex;
            if (message.Vectors && message.Vectors.length) {
                object.Vectors = [];
                for (let j = 0; j < message.Vectors.length; ++j)
                    object.Vectors[j] = $root.handyplug.LinearCmd.Vector.toObject(message.Vectors[j], options);
            }
            return object;
        };

        /**
         * Converts this LinearCmd to JSON.
         * @function toJSON
         * @memberof handyplug.LinearCmd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LinearCmd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LinearCmd.Vector = (function() {

            /**
             * Properties of a Vector.
             * @memberof handyplug.LinearCmd
             * @interface IVector
             * @property {number|null} [Index] Vector Index
             * @property {number|null} [Duration] Vector Duration
             * @property {number|null} [Position] Vector Position
             */

            /**
             * Constructs a new Vector.
             * @memberof handyplug.LinearCmd
             * @classdesc Represents a Vector.
             * @implements IVector
             * @constructor
             * @param {handyplug.LinearCmd.IVector=} [properties] Properties to set
             */
            function Vector(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Vector Index.
             * @member {number} Index
             * @memberof handyplug.LinearCmd.Vector
             * @instance
             */
            Vector.prototype.Index = 0;

            /**
             * Vector Duration.
             * @member {number} Duration
             * @memberof handyplug.LinearCmd.Vector
             * @instance
             */
            Vector.prototype.Duration = 0;

            /**
             * Vector Position.
             * @member {number} Position
             * @memberof handyplug.LinearCmd.Vector
             * @instance
             */
            Vector.prototype.Position = 0;

            /**
             * Creates a new Vector instance using the specified properties.
             * @function create
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {handyplug.LinearCmd.IVector=} [properties] Properties to set
             * @returns {handyplug.LinearCmd.Vector} Vector instance
             */
            Vector.create = function create(properties) {
                return new Vector(properties);
            };

            /**
             * Encodes the specified Vector message. Does not implicitly {@link handyplug.LinearCmd.Vector.verify|verify} messages.
             * @function encode
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {handyplug.LinearCmd.Vector} message Vector message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vector.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.Index != null && Object.hasOwnProperty.call(message, "Index"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.Index);
                if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.Duration);
                if (message.Position != null && Object.hasOwnProperty.call(message, "Position"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.Position);
                return writer;
            };

            /**
             * Encodes the specified Vector message, length delimited. Does not implicitly {@link handyplug.LinearCmd.Vector.verify|verify} messages.
             * @function encodeDelimited
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {handyplug.LinearCmd.Vector} message Vector message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Vector.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Vector message from the specified reader or buffer.
             * @function decode
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {handyplug.LinearCmd.Vector} Vector
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vector.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.LinearCmd.Vector();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.Index = reader.uint32();
                        break;
                    case 2:
                        message.Duration = reader.uint32();
                        break;
                    case 3:
                        message.Position = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Vector message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {handyplug.LinearCmd.Vector} Vector
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Vector.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Vector message.
             * @function verify
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Vector.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.Index != null && message.hasOwnProperty("Index"))
                    if (!$util.isInteger(message.Index))
                        return "Index: integer expected";
                if (message.Duration != null && message.hasOwnProperty("Duration"))
                    if (!$util.isInteger(message.Duration))
                        return "Duration: integer expected";
                if (message.Position != null && message.hasOwnProperty("Position"))
                    if (typeof message.Position !== "number")
                        return "Position: number expected";
                return null;
            };

            /**
             * Creates a Vector message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {handyplug.LinearCmd.Vector} Vector
             */
            Vector.fromObject = function fromObject(object) {
                if (object instanceof $root.handyplug.LinearCmd.Vector)
                    return object;
                let message = new $root.handyplug.LinearCmd.Vector();
                if (object.Index != null)
                    message.Index = object.Index >>> 0;
                if (object.Duration != null)
                    message.Duration = object.Duration >>> 0;
                if (object.Position != null)
                    message.Position = Number(object.Position);
                return message;
            };

            /**
             * Creates a plain object from a Vector message. Also converts values to other types if specified.
             * @function toObject
             * @memberof handyplug.LinearCmd.Vector
             * @static
             * @param {handyplug.LinearCmd.Vector} message Vector
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Vector.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.Index = 0;
                    object.Duration = 0;
                    object.Position = 0;
                }
                if (message.Index != null && message.hasOwnProperty("Index"))
                    object.Index = message.Index;
                if (message.Duration != null && message.hasOwnProperty("Duration"))
                    object.Duration = message.Duration;
                if (message.Position != null && message.hasOwnProperty("Position"))
                    object.Position = options.json && !isFinite(message.Position) ? String(message.Position) : message.Position;
                return object;
            };

            /**
             * Converts this Vector to JSON.
             * @function toJSON
             * @memberof handyplug.LinearCmd.Vector
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Vector.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Vector;
        })();

        return LinearCmd;
    })();

    handyplug.Message = (function() {

        /**
         * Properties of a Message.
         * @memberof handyplug
         * @interface IMessage
         * @property {handyplug.Ok|null} [Ok] Message Ok
         * @property {handyplug.Error|null} [Error] Message Error
         * @property {handyplug.Ping|null} [Ping] Message Ping
         * @property {handyplug.RequestServerInfo|null} [RequestServerInfo] Message RequestServerInfo
         * @property {handyplug.ServerInfo|null} [ServerInfo] Message ServerInfo
         * @property {handyplug.LinearCmd|null} [LinearCmd] Message LinearCmd
         */

        /**
         * Constructs a new Message.
         * @memberof handyplug
         * @classdesc Represents a Message.
         * @implements IMessage
         * @constructor
         * @param {handyplug.IMessage=} [properties] Properties to set
         */
        function Message(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Message Ok.
         * @member {handyplug.Ok|null|undefined} Ok
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.Ok = null;

        /**
         * Message Error.
         * @member {handyplug.Error|null|undefined} Error
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.Error = null;

        /**
         * Message Ping.
         * @member {handyplug.Ping|null|undefined} Ping
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.Ping = null;

        /**
         * Message RequestServerInfo.
         * @member {handyplug.RequestServerInfo|null|undefined} RequestServerInfo
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.RequestServerInfo = null;

        /**
         * Message ServerInfo.
         * @member {handyplug.ServerInfo|null|undefined} ServerInfo
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.ServerInfo = null;

        /**
         * Message LinearCmd.
         * @member {handyplug.LinearCmd|null|undefined} LinearCmd
         * @memberof handyplug.Message
         * @instance
         */
        Message.prototype.LinearCmd = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Message Message.
         * @member {"Ok"|"Error"|"Ping"|"RequestServerInfo"|"ServerInfo"|"LinearCmd"|undefined} Message
         * @memberof handyplug.Message
         * @instance
         */
        Object.defineProperty(Message.prototype, "Message", {
            get: $util.oneOfGetter($oneOfFields = ["Ok", "Error", "Ping", "RequestServerInfo", "ServerInfo", "LinearCmd"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Message instance using the specified properties.
         * @function create
         * @memberof handyplug.Message
         * @static
         * @param {handyplug.IMessage=} [properties] Properties to set
         * @returns {handyplug.Message} Message instance
         */
        Message.create = function create(properties) {
            return new Message(properties);
        };

        /**
         * Encodes the specified Message message. Does not implicitly {@link handyplug.Message.verify|verify} messages.
         * @function encode
         * @memberof handyplug.Message
         * @static
         * @param {handyplug.Message} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Ok != null && Object.hasOwnProperty.call(message, "Ok"))
                $root.handyplug.Ok.encode(message.Ok, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            if (message.Error != null && Object.hasOwnProperty.call(message, "Error"))
                $root.handyplug.Error.encode(message.Error, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            if (message.Ping != null && Object.hasOwnProperty.call(message, "Ping"))
                $root.handyplug.Ping.encode(message.Ping, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
            if (message.RequestServerInfo != null && Object.hasOwnProperty.call(message, "RequestServerInfo"))
                $root.handyplug.RequestServerInfo.encode(message.RequestServerInfo, writer.uint32(/* id 200, wireType 2 =*/1602).fork()).ldelim();
            if (message.ServerInfo != null && Object.hasOwnProperty.call(message, "ServerInfo"))
                $root.handyplug.ServerInfo.encode(message.ServerInfo, writer.uint32(/* id 201, wireType 2 =*/1610).fork()).ldelim();
            if (message.LinearCmd != null && Object.hasOwnProperty.call(message, "LinearCmd"))
                $root.handyplug.LinearCmd.encode(message.LinearCmd, writer.uint32(/* id 403, wireType 2 =*/3226).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Message message, length delimited. Does not implicitly {@link handyplug.Message.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.Message
         * @static
         * @param {handyplug.Message} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Message message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.Message();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 100:
                    message.Ok = $root.handyplug.Ok.decode(reader, reader.uint32());
                    break;
                case 101:
                    message.Error = $root.handyplug.Error.decode(reader, reader.uint32());
                    break;
                case 102:
                    message.Ping = $root.handyplug.Ping.decode(reader, reader.uint32());
                    break;
                case 200:
                    message.RequestServerInfo = $root.handyplug.RequestServerInfo.decode(reader, reader.uint32());
                    break;
                case 201:
                    message.ServerInfo = $root.handyplug.ServerInfo.decode(reader, reader.uint32());
                    break;
                case 403:
                    message.LinearCmd = $root.handyplug.LinearCmd.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Message message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Message message.
         * @function verify
         * @memberof handyplug.Message
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Message.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.Ok != null && message.hasOwnProperty("Ok")) {
                properties.Message = 1;
                {
                    let error = $root.handyplug.Ok.verify(message.Ok);
                    if (error)
                        return "Ok." + error;
                }
            }
            if (message.Error != null && message.hasOwnProperty("Error")) {
                if (properties.Message === 1)
                    return "Message: multiple values";
                properties.Message = 1;
                {
                    let error = $root.handyplug.Error.verify(message.Error);
                    if (error)
                        return "Error." + error;
                }
            }
            if (message.Ping != null && message.hasOwnProperty("Ping")) {
                if (properties.Message === 1)
                    return "Message: multiple values";
                properties.Message = 1;
                {
                    let error = $root.handyplug.Ping.verify(message.Ping);
                    if (error)
                        return "Ping." + error;
                }
            }
            if (message.RequestServerInfo != null && message.hasOwnProperty("RequestServerInfo")) {
                if (properties.Message === 1)
                    return "Message: multiple values";
                properties.Message = 1;
                {
                    let error = $root.handyplug.RequestServerInfo.verify(message.RequestServerInfo);
                    if (error)
                        return "RequestServerInfo." + error;
                }
            }
            if (message.ServerInfo != null && message.hasOwnProperty("ServerInfo")) {
                if (properties.Message === 1)
                    return "Message: multiple values";
                properties.Message = 1;
                {
                    let error = $root.handyplug.ServerInfo.verify(message.ServerInfo);
                    if (error)
                        return "ServerInfo." + error;
                }
            }
            if (message.LinearCmd != null && message.hasOwnProperty("LinearCmd")) {
                if (properties.Message === 1)
                    return "Message: multiple values";
                properties.Message = 1;
                {
                    let error = $root.handyplug.LinearCmd.verify(message.LinearCmd);
                    if (error)
                        return "LinearCmd." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Message message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.Message
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.Message} Message
         */
        Message.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.Message)
                return object;
            let message = new $root.handyplug.Message();
            if (object.Ok != null) {
                if (typeof object.Ok !== "object")
                    throw TypeError(".handyplug.Message.Ok: object expected");
                message.Ok = $root.handyplug.Ok.fromObject(object.Ok);
            }
            if (object.Error != null) {
                if (typeof object.Error !== "object")
                    throw TypeError(".handyplug.Message.Error: object expected");
                message.Error = $root.handyplug.Error.fromObject(object.Error);
            }
            if (object.Ping != null) {
                if (typeof object.Ping !== "object")
                    throw TypeError(".handyplug.Message.Ping: object expected");
                message.Ping = $root.handyplug.Ping.fromObject(object.Ping);
            }
            if (object.RequestServerInfo != null) {
                if (typeof object.RequestServerInfo !== "object")
                    throw TypeError(".handyplug.Message.RequestServerInfo: object expected");
                message.RequestServerInfo = $root.handyplug.RequestServerInfo.fromObject(object.RequestServerInfo);
            }
            if (object.ServerInfo != null) {
                if (typeof object.ServerInfo !== "object")
                    throw TypeError(".handyplug.Message.ServerInfo: object expected");
                message.ServerInfo = $root.handyplug.ServerInfo.fromObject(object.ServerInfo);
            }
            if (object.LinearCmd != null) {
                if (typeof object.LinearCmd !== "object")
                    throw TypeError(".handyplug.Message.LinearCmd: object expected");
                message.LinearCmd = $root.handyplug.LinearCmd.fromObject(object.LinearCmd);
            }
            return message;
        };

        /**
         * Creates a plain object from a Message message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.Message
         * @static
         * @param {handyplug.Message} message Message
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Message.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.Ok != null && message.hasOwnProperty("Ok")) {
                object.Ok = $root.handyplug.Ok.toObject(message.Ok, options);
                if (options.oneofs)
                    object.Message = "Ok";
            }
            if (message.Error != null && message.hasOwnProperty("Error")) {
                object.Error = $root.handyplug.Error.toObject(message.Error, options);
                if (options.oneofs)
                    object.Message = "Error";
            }
            if (message.Ping != null && message.hasOwnProperty("Ping")) {
                object.Ping = $root.handyplug.Ping.toObject(message.Ping, options);
                if (options.oneofs)
                    object.Message = "Ping";
            }
            if (message.RequestServerInfo != null && message.hasOwnProperty("RequestServerInfo")) {
                object.RequestServerInfo = $root.handyplug.RequestServerInfo.toObject(message.RequestServerInfo, options);
                if (options.oneofs)
                    object.Message = "RequestServerInfo";
            }
            if (message.ServerInfo != null && message.hasOwnProperty("ServerInfo")) {
                object.ServerInfo = $root.handyplug.ServerInfo.toObject(message.ServerInfo, options);
                if (options.oneofs)
                    object.Message = "ServerInfo";
            }
            if (message.LinearCmd != null && message.hasOwnProperty("LinearCmd")) {
                object.LinearCmd = $root.handyplug.LinearCmd.toObject(message.LinearCmd, options);
                if (options.oneofs)
                    object.Message = "LinearCmd";
            }
            return object;
        };

        /**
         * Converts this Message to JSON.
         * @function toJSON
         * @memberof handyplug.Message
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Message.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Message;
    })();

    handyplug.Payload = (function() {

        /**
         * Properties of a Payload.
         * @memberof handyplug
         * @interface IPayload
         * @property {Array.<handyplug.Message>|null} [Messages] Payload Messages
         */

        /**
         * Constructs a new Payload.
         * @memberof handyplug
         * @classdesc Represents a Payload.
         * @implements IPayload
         * @constructor
         * @param {handyplug.IPayload=} [properties] Properties to set
         */
        function Payload(properties) {
            this.Messages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payload Messages.
         * @member {Array.<handyplug.Message>} Messages
         * @memberof handyplug.Payload
         * @instance
         */
        Payload.prototype.Messages = $util.emptyArray;

        /**
         * Creates a new Payload instance using the specified properties.
         * @function create
         * @memberof handyplug.Payload
         * @static
         * @param {handyplug.IPayload=} [properties] Properties to set
         * @returns {handyplug.Payload} Payload instance
         */
        Payload.create = function create(properties) {
            return new Payload(properties);
        };

        /**
         * Encodes the specified Payload message. Does not implicitly {@link handyplug.Payload.verify|verify} messages.
         * @function encode
         * @memberof handyplug.Payload
         * @static
         * @param {handyplug.Payload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.Messages != null && message.Messages.length)
                for (let i = 0; i < message.Messages.length; ++i)
                    $root.handyplug.Message.encode(message.Messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Payload message, length delimited. Does not implicitly {@link handyplug.Payload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof handyplug.Payload
         * @static
         * @param {handyplug.Payload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payload message from the specified reader or buffer.
         * @function decode
         * @memberof handyplug.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {handyplug.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.handyplug.Payload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.Messages && message.Messages.length))
                        message.Messages = [];
                    message.Messages.push($root.handyplug.Message.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof handyplug.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {handyplug.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payload message.
         * @function verify
         * @memberof handyplug.Payload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.Messages != null && message.hasOwnProperty("Messages")) {
                if (!Array.isArray(message.Messages))
                    return "Messages: array expected";
                for (let i = 0; i < message.Messages.length; ++i) {
                    let error = $root.handyplug.Message.verify(message.Messages[i]);
                    if (error)
                        return "Messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Payload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof handyplug.Payload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {handyplug.Payload} Payload
         */
        Payload.fromObject = function fromObject(object) {
            if (object instanceof $root.handyplug.Payload)
                return object;
            let message = new $root.handyplug.Payload();
            if (object.Messages) {
                if (!Array.isArray(object.Messages))
                    throw TypeError(".handyplug.Payload.Messages: array expected");
                message.Messages = [];
                for (let i = 0; i < object.Messages.length; ++i) {
                    if (typeof object.Messages[i] !== "object")
                        throw TypeError(".handyplug.Payload.Messages: object expected");
                    message.Messages[i] = $root.handyplug.Message.fromObject(object.Messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Payload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof handyplug.Payload
         * @static
         * @param {handyplug.Payload} message Payload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.Messages = [];
            if (message.Messages && message.Messages.length) {
                object.Messages = [];
                for (let j = 0; j < message.Messages.length; ++j)
                    object.Messages[j] = $root.handyplug.Message.toObject(message.Messages[j], options);
            }
            return object;
        };

        /**
         * Converts this Payload to JSON.
         * @function toJSON
         * @memberof handyplug.Payload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Payload;
    })();

    return handyplug;
})();

export const CmdProtocolVersionGet = $root.CmdProtocolVersionGet = (() => {

    /**
     * Properties of a CmdProtocolVersionGet.
     * @exports ICmdProtocolVersionGet
     * @interface ICmdProtocolVersionGet
     */

    /**
     * Constructs a new CmdProtocolVersionGet.
     * @exports CmdProtocolVersionGet
     * @classdesc Represents a CmdProtocolVersionGet.
     * @implements ICmdProtocolVersionGet
     * @constructor
     * @param {ICmdProtocolVersionGet=} [properties] Properties to set
     */
    function CmdProtocolVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdProtocolVersionGet instance using the specified properties.
     * @function create
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {ICmdProtocolVersionGet=} [properties] Properties to set
     * @returns {CmdProtocolVersionGet} CmdProtocolVersionGet instance
     */
    CmdProtocolVersionGet.create = function create(properties) {
        return new CmdProtocolVersionGet(properties);
    };

    /**
     * Encodes the specified CmdProtocolVersionGet message. Does not implicitly {@link CmdProtocolVersionGet.verify|verify} messages.
     * @function encode
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {CmdProtocolVersionGet} message CmdProtocolVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdProtocolVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdProtocolVersionGet message, length delimited. Does not implicitly {@link CmdProtocolVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {CmdProtocolVersionGet} message CmdProtocolVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdProtocolVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdProtocolVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdProtocolVersionGet} CmdProtocolVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdProtocolVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdProtocolVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdProtocolVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdProtocolVersionGet} CmdProtocolVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdProtocolVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdProtocolVersionGet message.
     * @function verify
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdProtocolVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdProtocolVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdProtocolVersionGet} CmdProtocolVersionGet
     */
    CmdProtocolVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdProtocolVersionGet)
            return object;
        return new $root.CmdProtocolVersionGet();
    };

    /**
     * Creates a plain object from a CmdProtocolVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdProtocolVersionGet
     * @static
     * @param {CmdProtocolVersionGet} message CmdProtocolVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdProtocolVersionGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdProtocolVersionGet to JSON.
     * @function toJSON
     * @memberof CmdProtocolVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdProtocolVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdProtocolVersionGet;
})();

export const RespProtocolVersionGet = $root.RespProtocolVersionGet = (() => {

    /**
     * Properties of a RespProtocolVersionGet.
     * @exports IRespProtocolVersionGet
     * @interface IRespProtocolVersionGet
     * @property {number|null} [result] RespProtocolVersionGet result
     * @property {string|null} [semver] RespProtocolVersionGet semver
     */

    /**
     * Constructs a new RespProtocolVersionGet.
     * @exports RespProtocolVersionGet
     * @classdesc Represents a RespProtocolVersionGet.
     * @implements IRespProtocolVersionGet
     * @constructor
     * @param {IRespProtocolVersionGet=} [properties] Properties to set
     */
    function RespProtocolVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespProtocolVersionGet result.
     * @member {number} result
     * @memberof RespProtocolVersionGet
     * @instance
     */
    RespProtocolVersionGet.prototype.result = 0;

    /**
     * RespProtocolVersionGet semver.
     * @member {string} semver
     * @memberof RespProtocolVersionGet
     * @instance
     */
    RespProtocolVersionGet.prototype.semver = "";

    /**
     * Creates a new RespProtocolVersionGet instance using the specified properties.
     * @function create
     * @memberof RespProtocolVersionGet
     * @static
     * @param {IRespProtocolVersionGet=} [properties] Properties to set
     * @returns {RespProtocolVersionGet} RespProtocolVersionGet instance
     */
    RespProtocolVersionGet.create = function create(properties) {
        return new RespProtocolVersionGet(properties);
    };

    /**
     * Encodes the specified RespProtocolVersionGet message. Does not implicitly {@link RespProtocolVersionGet.verify|verify} messages.
     * @function encode
     * @memberof RespProtocolVersionGet
     * @static
     * @param {RespProtocolVersionGet} message RespProtocolVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespProtocolVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.semver != null && Object.hasOwnProperty.call(message, "semver"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.semver);
        return writer;
    };

    /**
     * Encodes the specified RespProtocolVersionGet message, length delimited. Does not implicitly {@link RespProtocolVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespProtocolVersionGet
     * @static
     * @param {RespProtocolVersionGet} message RespProtocolVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespProtocolVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespProtocolVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespProtocolVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespProtocolVersionGet} RespProtocolVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespProtocolVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespProtocolVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.semver = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespProtocolVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespProtocolVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespProtocolVersionGet} RespProtocolVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespProtocolVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespProtocolVersionGet message.
     * @function verify
     * @memberof RespProtocolVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespProtocolVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.semver != null && message.hasOwnProperty("semver"))
            if (!$util.isString(message.semver))
                return "semver: string expected";
        return null;
    };

    /**
     * Creates a RespProtocolVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespProtocolVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespProtocolVersionGet} RespProtocolVersionGet
     */
    RespProtocolVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespProtocolVersionGet)
            return object;
        let message = new $root.RespProtocolVersionGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.semver != null)
            message.semver = String(object.semver);
        return message;
    };

    /**
     * Creates a plain object from a RespProtocolVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespProtocolVersionGet
     * @static
     * @param {RespProtocolVersionGet} message RespProtocolVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespProtocolVersionGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.semver = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.semver != null && message.hasOwnProperty("semver"))
            object.semver = message.semver;
        return object;
    };

    /**
     * Converts this RespProtocolVersionGet to JSON.
     * @function toJSON
     * @memberof RespProtocolVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespProtocolVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespProtocolVersionGet;
})();

export const CmdConnectionKeyGet = $root.CmdConnectionKeyGet = (() => {

    /**
     * Properties of a CmdConnectionKeyGet.
     * @exports ICmdConnectionKeyGet
     * @interface ICmdConnectionKeyGet
     */

    /**
     * Constructs a new CmdConnectionKeyGet.
     * @exports CmdConnectionKeyGet
     * @classdesc Represents a CmdConnectionKeyGet.
     * @implements ICmdConnectionKeyGet
     * @constructor
     * @param {ICmdConnectionKeyGet=} [properties] Properties to set
     */
    function CmdConnectionKeyGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdConnectionKeyGet instance using the specified properties.
     * @function create
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {ICmdConnectionKeyGet=} [properties] Properties to set
     * @returns {CmdConnectionKeyGet} CmdConnectionKeyGet instance
     */
    CmdConnectionKeyGet.create = function create(properties) {
        return new CmdConnectionKeyGet(properties);
    };

    /**
     * Encodes the specified CmdConnectionKeyGet message. Does not implicitly {@link CmdConnectionKeyGet.verify|verify} messages.
     * @function encode
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {CmdConnectionKeyGet} message CmdConnectionKeyGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConnectionKeyGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdConnectionKeyGet message, length delimited. Does not implicitly {@link CmdConnectionKeyGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {CmdConnectionKeyGet} message CmdConnectionKeyGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConnectionKeyGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdConnectionKeyGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdConnectionKeyGet} CmdConnectionKeyGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConnectionKeyGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdConnectionKeyGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdConnectionKeyGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdConnectionKeyGet} CmdConnectionKeyGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConnectionKeyGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdConnectionKeyGet message.
     * @function verify
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdConnectionKeyGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdConnectionKeyGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdConnectionKeyGet} CmdConnectionKeyGet
     */
    CmdConnectionKeyGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdConnectionKeyGet)
            return object;
        return new $root.CmdConnectionKeyGet();
    };

    /**
     * Creates a plain object from a CmdConnectionKeyGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdConnectionKeyGet
     * @static
     * @param {CmdConnectionKeyGet} message CmdConnectionKeyGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdConnectionKeyGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdConnectionKeyGet to JSON.
     * @function toJSON
     * @memberof CmdConnectionKeyGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdConnectionKeyGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdConnectionKeyGet;
})();

export const RespConnectionKeyGet = $root.RespConnectionKeyGet = (() => {

    /**
     * Properties of a RespConnectionKeyGet.
     * @exports IRespConnectionKeyGet
     * @interface IRespConnectionKeyGet
     * @property {number|null} [result] RespConnectionKeyGet result
     * @property {string|null} [key] RespConnectionKeyGet key
     */

    /**
     * Constructs a new RespConnectionKeyGet.
     * @exports RespConnectionKeyGet
     * @classdesc Represents a RespConnectionKeyGet.
     * @implements IRespConnectionKeyGet
     * @constructor
     * @param {IRespConnectionKeyGet=} [properties] Properties to set
     */
    function RespConnectionKeyGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespConnectionKeyGet result.
     * @member {number} result
     * @memberof RespConnectionKeyGet
     * @instance
     */
    RespConnectionKeyGet.prototype.result = 0;

    /**
     * RespConnectionKeyGet key.
     * @member {string} key
     * @memberof RespConnectionKeyGet
     * @instance
     */
    RespConnectionKeyGet.prototype.key = "";

    /**
     * Creates a new RespConnectionKeyGet instance using the specified properties.
     * @function create
     * @memberof RespConnectionKeyGet
     * @static
     * @param {IRespConnectionKeyGet=} [properties] Properties to set
     * @returns {RespConnectionKeyGet} RespConnectionKeyGet instance
     */
    RespConnectionKeyGet.create = function create(properties) {
        return new RespConnectionKeyGet(properties);
    };

    /**
     * Encodes the specified RespConnectionKeyGet message. Does not implicitly {@link RespConnectionKeyGet.verify|verify} messages.
     * @function encode
     * @memberof RespConnectionKeyGet
     * @static
     * @param {RespConnectionKeyGet} message RespConnectionKeyGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConnectionKeyGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
        return writer;
    };

    /**
     * Encodes the specified RespConnectionKeyGet message, length delimited. Does not implicitly {@link RespConnectionKeyGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespConnectionKeyGet
     * @static
     * @param {RespConnectionKeyGet} message RespConnectionKeyGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConnectionKeyGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespConnectionKeyGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespConnectionKeyGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespConnectionKeyGet} RespConnectionKeyGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConnectionKeyGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespConnectionKeyGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.key = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespConnectionKeyGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespConnectionKeyGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespConnectionKeyGet} RespConnectionKeyGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConnectionKeyGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespConnectionKeyGet message.
     * @function verify
     * @memberof RespConnectionKeyGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespConnectionKeyGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isString(message.key))
                return "key: string expected";
        return null;
    };

    /**
     * Creates a RespConnectionKeyGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespConnectionKeyGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespConnectionKeyGet} RespConnectionKeyGet
     */
    RespConnectionKeyGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespConnectionKeyGet)
            return object;
        let message = new $root.RespConnectionKeyGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.key != null)
            message.key = String(object.key);
        return message;
    };

    /**
     * Creates a plain object from a RespConnectionKeyGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespConnectionKeyGet
     * @static
     * @param {RespConnectionKeyGet} message RespConnectionKeyGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespConnectionKeyGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.key = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        return object;
    };

    /**
     * Converts this RespConnectionKeyGet to JSON.
     * @function toJSON
     * @memberof RespConnectionKeyGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespConnectionKeyGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespConnectionKeyGet;
})();

export const CmdConnectionKeyGen = $root.CmdConnectionKeyGen = (() => {

    /**
     * Properties of a CmdConnectionKeyGen.
     * @exports ICmdConnectionKeyGen
     * @interface ICmdConnectionKeyGen
     * @property {number|null} [length] CmdConnectionKeyGen length
     */

    /**
     * Constructs a new CmdConnectionKeyGen.
     * @exports CmdConnectionKeyGen
     * @classdesc Represents a CmdConnectionKeyGen.
     * @implements ICmdConnectionKeyGen
     * @constructor
     * @param {ICmdConnectionKeyGen=} [properties] Properties to set
     */
    function CmdConnectionKeyGen(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CmdConnectionKeyGen length.
     * @member {number} length
     * @memberof CmdConnectionKeyGen
     * @instance
     */
    CmdConnectionKeyGen.prototype.length = 0;

    /**
     * Creates a new CmdConnectionKeyGen instance using the specified properties.
     * @function create
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {ICmdConnectionKeyGen=} [properties] Properties to set
     * @returns {CmdConnectionKeyGen} CmdConnectionKeyGen instance
     */
    CmdConnectionKeyGen.create = function create(properties) {
        return new CmdConnectionKeyGen(properties);
    };

    /**
     * Encodes the specified CmdConnectionKeyGen message. Does not implicitly {@link CmdConnectionKeyGen.verify|verify} messages.
     * @function encode
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {CmdConnectionKeyGen} message CmdConnectionKeyGen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConnectionKeyGen.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.length != null && Object.hasOwnProperty.call(message, "length"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.length);
        return writer;
    };

    /**
     * Encodes the specified CmdConnectionKeyGen message, length delimited. Does not implicitly {@link CmdConnectionKeyGen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {CmdConnectionKeyGen} message CmdConnectionKeyGen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConnectionKeyGen.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdConnectionKeyGen message from the specified reader or buffer.
     * @function decode
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdConnectionKeyGen} CmdConnectionKeyGen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConnectionKeyGen.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdConnectionKeyGen();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.length = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdConnectionKeyGen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdConnectionKeyGen} CmdConnectionKeyGen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConnectionKeyGen.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdConnectionKeyGen message.
     * @function verify
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdConnectionKeyGen.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.length != null && message.hasOwnProperty("length"))
            if (!$util.isInteger(message.length))
                return "length: integer expected";
        return null;
    };

    /**
     * Creates a CmdConnectionKeyGen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdConnectionKeyGen} CmdConnectionKeyGen
     */
    CmdConnectionKeyGen.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdConnectionKeyGen)
            return object;
        let message = new $root.CmdConnectionKeyGen();
        if (object.length != null)
            message.length = object.length >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a CmdConnectionKeyGen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdConnectionKeyGen
     * @static
     * @param {CmdConnectionKeyGen} message CmdConnectionKeyGen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdConnectionKeyGen.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.length = 0;
        if (message.length != null && message.hasOwnProperty("length"))
            object.length = message.length;
        return object;
    };

    /**
     * Converts this CmdConnectionKeyGen to JSON.
     * @function toJSON
     * @memberof CmdConnectionKeyGen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdConnectionKeyGen.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdConnectionKeyGen;
})();

export const RespConnectionKeyGen = $root.RespConnectionKeyGen = (() => {

    /**
     * Properties of a RespConnectionKeyGen.
     * @exports IRespConnectionKeyGen
     * @interface IRespConnectionKeyGen
     * @property {number|null} [result] RespConnectionKeyGen result
     * @property {string|null} [key] RespConnectionKeyGen key
     */

    /**
     * Constructs a new RespConnectionKeyGen.
     * @exports RespConnectionKeyGen
     * @classdesc Represents a RespConnectionKeyGen.
     * @implements IRespConnectionKeyGen
     * @constructor
     * @param {IRespConnectionKeyGen=} [properties] Properties to set
     */
    function RespConnectionKeyGen(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespConnectionKeyGen result.
     * @member {number} result
     * @memberof RespConnectionKeyGen
     * @instance
     */
    RespConnectionKeyGen.prototype.result = 0;

    /**
     * RespConnectionKeyGen key.
     * @member {string} key
     * @memberof RespConnectionKeyGen
     * @instance
     */
    RespConnectionKeyGen.prototype.key = "";

    /**
     * Creates a new RespConnectionKeyGen instance using the specified properties.
     * @function create
     * @memberof RespConnectionKeyGen
     * @static
     * @param {IRespConnectionKeyGen=} [properties] Properties to set
     * @returns {RespConnectionKeyGen} RespConnectionKeyGen instance
     */
    RespConnectionKeyGen.create = function create(properties) {
        return new RespConnectionKeyGen(properties);
    };

    /**
     * Encodes the specified RespConnectionKeyGen message. Does not implicitly {@link RespConnectionKeyGen.verify|verify} messages.
     * @function encode
     * @memberof RespConnectionKeyGen
     * @static
     * @param {RespConnectionKeyGen} message RespConnectionKeyGen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConnectionKeyGen.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
        return writer;
    };

    /**
     * Encodes the specified RespConnectionKeyGen message, length delimited. Does not implicitly {@link RespConnectionKeyGen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespConnectionKeyGen
     * @static
     * @param {RespConnectionKeyGen} message RespConnectionKeyGen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConnectionKeyGen.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespConnectionKeyGen message from the specified reader or buffer.
     * @function decode
     * @memberof RespConnectionKeyGen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespConnectionKeyGen} RespConnectionKeyGen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConnectionKeyGen.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespConnectionKeyGen();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.key = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespConnectionKeyGen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespConnectionKeyGen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespConnectionKeyGen} RespConnectionKeyGen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConnectionKeyGen.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespConnectionKeyGen message.
     * @function verify
     * @memberof RespConnectionKeyGen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespConnectionKeyGen.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isString(message.key))
                return "key: string expected";
        return null;
    };

    /**
     * Creates a RespConnectionKeyGen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespConnectionKeyGen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespConnectionKeyGen} RespConnectionKeyGen
     */
    RespConnectionKeyGen.fromObject = function fromObject(object) {
        if (object instanceof $root.RespConnectionKeyGen)
            return object;
        let message = new $root.RespConnectionKeyGen();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.key != null)
            message.key = String(object.key);
        return message;
    };

    /**
     * Creates a plain object from a RespConnectionKeyGen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespConnectionKeyGen
     * @static
     * @param {RespConnectionKeyGen} message RespConnectionKeyGen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespConnectionKeyGen.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.key = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        return object;
    };

    /**
     * Converts this RespConnectionKeyGen to JSON.
     * @function toJSON
     * @memberof RespConnectionKeyGen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespConnectionKeyGen.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespConnectionKeyGen;
})();

export const CmdSerialNumberGet = $root.CmdSerialNumberGet = (() => {

    /**
     * Properties of a CmdSerialNumberGet.
     * @exports ICmdSerialNumberGet
     * @interface ICmdSerialNumberGet
     */

    /**
     * Constructs a new CmdSerialNumberGet.
     * @exports CmdSerialNumberGet
     * @classdesc Represents a CmdSerialNumberGet.
     * @implements ICmdSerialNumberGet
     * @constructor
     * @param {ICmdSerialNumberGet=} [properties] Properties to set
     */
    function CmdSerialNumberGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdSerialNumberGet instance using the specified properties.
     * @function create
     * @memberof CmdSerialNumberGet
     * @static
     * @param {ICmdSerialNumberGet=} [properties] Properties to set
     * @returns {CmdSerialNumberGet} CmdSerialNumberGet instance
     */
    CmdSerialNumberGet.create = function create(properties) {
        return new CmdSerialNumberGet(properties);
    };

    /**
     * Encodes the specified CmdSerialNumberGet message. Does not implicitly {@link CmdSerialNumberGet.verify|verify} messages.
     * @function encode
     * @memberof CmdSerialNumberGet
     * @static
     * @param {CmdSerialNumberGet} message CmdSerialNumberGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdSerialNumberGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdSerialNumberGet message, length delimited. Does not implicitly {@link CmdSerialNumberGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdSerialNumberGet
     * @static
     * @param {CmdSerialNumberGet} message CmdSerialNumberGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdSerialNumberGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdSerialNumberGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdSerialNumberGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdSerialNumberGet} CmdSerialNumberGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdSerialNumberGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdSerialNumberGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdSerialNumberGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdSerialNumberGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdSerialNumberGet} CmdSerialNumberGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdSerialNumberGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdSerialNumberGet message.
     * @function verify
     * @memberof CmdSerialNumberGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdSerialNumberGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdSerialNumberGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdSerialNumberGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdSerialNumberGet} CmdSerialNumberGet
     */
    CmdSerialNumberGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdSerialNumberGet)
            return object;
        return new $root.CmdSerialNumberGet();
    };

    /**
     * Creates a plain object from a CmdSerialNumberGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdSerialNumberGet
     * @static
     * @param {CmdSerialNumberGet} message CmdSerialNumberGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdSerialNumberGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdSerialNumberGet to JSON.
     * @function toJSON
     * @memberof CmdSerialNumberGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdSerialNumberGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdSerialNumberGet;
})();

export const RespSerialNumberGet = $root.RespSerialNumberGet = (() => {

    /**
     * Properties of a RespSerialNumberGet.
     * @exports IRespSerialNumberGet
     * @interface IRespSerialNumberGet
     * @property {number|null} [result] RespSerialNumberGet result
     * @property {number|null} [serialNumber] RespSerialNumberGet serialNumber
     */

    /**
     * Constructs a new RespSerialNumberGet.
     * @exports RespSerialNumberGet
     * @classdesc Represents a RespSerialNumberGet.
     * @implements IRespSerialNumberGet
     * @constructor
     * @param {IRespSerialNumberGet=} [properties] Properties to set
     */
    function RespSerialNumberGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespSerialNumberGet result.
     * @member {number} result
     * @memberof RespSerialNumberGet
     * @instance
     */
    RespSerialNumberGet.prototype.result = 0;

    /**
     * RespSerialNumberGet serialNumber.
     * @member {number} serialNumber
     * @memberof RespSerialNumberGet
     * @instance
     */
    RespSerialNumberGet.prototype.serialNumber = 0;

    /**
     * Creates a new RespSerialNumberGet instance using the specified properties.
     * @function create
     * @memberof RespSerialNumberGet
     * @static
     * @param {IRespSerialNumberGet=} [properties] Properties to set
     * @returns {RespSerialNumberGet} RespSerialNumberGet instance
     */
    RespSerialNumberGet.create = function create(properties) {
        return new RespSerialNumberGet(properties);
    };

    /**
     * Encodes the specified RespSerialNumberGet message. Does not implicitly {@link RespSerialNumberGet.verify|verify} messages.
     * @function encode
     * @memberof RespSerialNumberGet
     * @static
     * @param {RespSerialNumberGet} message RespSerialNumberGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespSerialNumberGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.serialNumber != null && Object.hasOwnProperty.call(message, "serialNumber"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.serialNumber);
        return writer;
    };

    /**
     * Encodes the specified RespSerialNumberGet message, length delimited. Does not implicitly {@link RespSerialNumberGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespSerialNumberGet
     * @static
     * @param {RespSerialNumberGet} message RespSerialNumberGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespSerialNumberGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespSerialNumberGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespSerialNumberGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespSerialNumberGet} RespSerialNumberGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespSerialNumberGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespSerialNumberGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.serialNumber = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespSerialNumberGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespSerialNumberGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespSerialNumberGet} RespSerialNumberGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespSerialNumberGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespSerialNumberGet message.
     * @function verify
     * @memberof RespSerialNumberGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespSerialNumberGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            if (!$util.isInteger(message.serialNumber))
                return "serialNumber: integer expected";
        return null;
    };

    /**
     * Creates a RespSerialNumberGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespSerialNumberGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespSerialNumberGet} RespSerialNumberGet
     */
    RespSerialNumberGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespSerialNumberGet)
            return object;
        let message = new $root.RespSerialNumberGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.serialNumber != null)
            message.serialNumber = object.serialNumber >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a RespSerialNumberGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespSerialNumberGet
     * @static
     * @param {RespSerialNumberGet} message RespSerialNumberGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespSerialNumberGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.serialNumber = 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.serialNumber != null && message.hasOwnProperty("serialNumber"))
            object.serialNumber = message.serialNumber;
        return object;
    };

    /**
     * Converts this RespSerialNumberGet to JSON.
     * @function toJSON
     * @memberof RespSerialNumberGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespSerialNumberGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespSerialNumberGet;
})();

export const CmdProductionDateGet = $root.CmdProductionDateGet = (() => {

    /**
     * Properties of a CmdProductionDateGet.
     * @exports ICmdProductionDateGet
     * @interface ICmdProductionDateGet
     */

    /**
     * Constructs a new CmdProductionDateGet.
     * @exports CmdProductionDateGet
     * @classdesc Represents a CmdProductionDateGet.
     * @implements ICmdProductionDateGet
     * @constructor
     * @param {ICmdProductionDateGet=} [properties] Properties to set
     */
    function CmdProductionDateGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdProductionDateGet instance using the specified properties.
     * @function create
     * @memberof CmdProductionDateGet
     * @static
     * @param {ICmdProductionDateGet=} [properties] Properties to set
     * @returns {CmdProductionDateGet} CmdProductionDateGet instance
     */
    CmdProductionDateGet.create = function create(properties) {
        return new CmdProductionDateGet(properties);
    };

    /**
     * Encodes the specified CmdProductionDateGet message. Does not implicitly {@link CmdProductionDateGet.verify|verify} messages.
     * @function encode
     * @memberof CmdProductionDateGet
     * @static
     * @param {CmdProductionDateGet} message CmdProductionDateGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdProductionDateGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdProductionDateGet message, length delimited. Does not implicitly {@link CmdProductionDateGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdProductionDateGet
     * @static
     * @param {CmdProductionDateGet} message CmdProductionDateGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdProductionDateGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdProductionDateGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdProductionDateGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdProductionDateGet} CmdProductionDateGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdProductionDateGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdProductionDateGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdProductionDateGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdProductionDateGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdProductionDateGet} CmdProductionDateGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdProductionDateGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdProductionDateGet message.
     * @function verify
     * @memberof CmdProductionDateGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdProductionDateGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdProductionDateGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdProductionDateGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdProductionDateGet} CmdProductionDateGet
     */
    CmdProductionDateGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdProductionDateGet)
            return object;
        return new $root.CmdProductionDateGet();
    };

    /**
     * Creates a plain object from a CmdProductionDateGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdProductionDateGet
     * @static
     * @param {CmdProductionDateGet} message CmdProductionDateGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdProductionDateGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdProductionDateGet to JSON.
     * @function toJSON
     * @memberof CmdProductionDateGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdProductionDateGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdProductionDateGet;
})();

export const RespProductionDateGet = $root.RespProductionDateGet = (() => {

    /**
     * Properties of a RespProductionDateGet.
     * @exports IRespProductionDateGet
     * @interface IRespProductionDateGet
     * @property {number|null} [result] RespProductionDateGet result
     * @property {string|null} [productionDate] RespProductionDateGet productionDate
     */

    /**
     * Constructs a new RespProductionDateGet.
     * @exports RespProductionDateGet
     * @classdesc Represents a RespProductionDateGet.
     * @implements IRespProductionDateGet
     * @constructor
     * @param {IRespProductionDateGet=} [properties] Properties to set
     */
    function RespProductionDateGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespProductionDateGet result.
     * @member {number} result
     * @memberof RespProductionDateGet
     * @instance
     */
    RespProductionDateGet.prototype.result = 0;

    /**
     * RespProductionDateGet productionDate.
     * @member {string} productionDate
     * @memberof RespProductionDateGet
     * @instance
     */
    RespProductionDateGet.prototype.productionDate = "";

    /**
     * Creates a new RespProductionDateGet instance using the specified properties.
     * @function create
     * @memberof RespProductionDateGet
     * @static
     * @param {IRespProductionDateGet=} [properties] Properties to set
     * @returns {RespProductionDateGet} RespProductionDateGet instance
     */
    RespProductionDateGet.create = function create(properties) {
        return new RespProductionDateGet(properties);
    };

    /**
     * Encodes the specified RespProductionDateGet message. Does not implicitly {@link RespProductionDateGet.verify|verify} messages.
     * @function encode
     * @memberof RespProductionDateGet
     * @static
     * @param {RespProductionDateGet} message RespProductionDateGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespProductionDateGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.productionDate != null && Object.hasOwnProperty.call(message, "productionDate"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.productionDate);
        return writer;
    };

    /**
     * Encodes the specified RespProductionDateGet message, length delimited. Does not implicitly {@link RespProductionDateGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespProductionDateGet
     * @static
     * @param {RespProductionDateGet} message RespProductionDateGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespProductionDateGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespProductionDateGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespProductionDateGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespProductionDateGet} RespProductionDateGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespProductionDateGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespProductionDateGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.productionDate = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespProductionDateGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespProductionDateGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespProductionDateGet} RespProductionDateGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespProductionDateGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespProductionDateGet message.
     * @function verify
     * @memberof RespProductionDateGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespProductionDateGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.productionDate != null && message.hasOwnProperty("productionDate"))
            if (!$util.isString(message.productionDate))
                return "productionDate: string expected";
        return null;
    };

    /**
     * Creates a RespProductionDateGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespProductionDateGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespProductionDateGet} RespProductionDateGet
     */
    RespProductionDateGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespProductionDateGet)
            return object;
        let message = new $root.RespProductionDateGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.productionDate != null)
            message.productionDate = String(object.productionDate);
        return message;
    };

    /**
     * Creates a plain object from a RespProductionDateGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespProductionDateGet
     * @static
     * @param {RespProductionDateGet} message RespProductionDateGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespProductionDateGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.productionDate = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.productionDate != null && message.hasOwnProperty("productionDate"))
            object.productionDate = message.productionDate;
        return object;
    };

    /**
     * Converts this RespProductionDateGet to JSON.
     * @function toJSON
     * @memberof RespProductionDateGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespProductionDateGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespProductionDateGet;
})();

export const CmdHardwareVersionGet = $root.CmdHardwareVersionGet = (() => {

    /**
     * Properties of a CmdHardwareVersionGet.
     * @exports ICmdHardwareVersionGet
     * @interface ICmdHardwareVersionGet
     */

    /**
     * Constructs a new CmdHardwareVersionGet.
     * @exports CmdHardwareVersionGet
     * @classdesc Represents a CmdHardwareVersionGet.
     * @implements ICmdHardwareVersionGet
     * @constructor
     * @param {ICmdHardwareVersionGet=} [properties] Properties to set
     */
    function CmdHardwareVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdHardwareVersionGet instance using the specified properties.
     * @function create
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {ICmdHardwareVersionGet=} [properties] Properties to set
     * @returns {CmdHardwareVersionGet} CmdHardwareVersionGet instance
     */
    CmdHardwareVersionGet.create = function create(properties) {
        return new CmdHardwareVersionGet(properties);
    };

    /**
     * Encodes the specified CmdHardwareVersionGet message. Does not implicitly {@link CmdHardwareVersionGet.verify|verify} messages.
     * @function encode
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {CmdHardwareVersionGet} message CmdHardwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdHardwareVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdHardwareVersionGet message, length delimited. Does not implicitly {@link CmdHardwareVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {CmdHardwareVersionGet} message CmdHardwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdHardwareVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdHardwareVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdHardwareVersionGet} CmdHardwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdHardwareVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdHardwareVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdHardwareVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdHardwareVersionGet} CmdHardwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdHardwareVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdHardwareVersionGet message.
     * @function verify
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdHardwareVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdHardwareVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdHardwareVersionGet} CmdHardwareVersionGet
     */
    CmdHardwareVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdHardwareVersionGet)
            return object;
        return new $root.CmdHardwareVersionGet();
    };

    /**
     * Creates a plain object from a CmdHardwareVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdHardwareVersionGet
     * @static
     * @param {CmdHardwareVersionGet} message CmdHardwareVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdHardwareVersionGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdHardwareVersionGet to JSON.
     * @function toJSON
     * @memberof CmdHardwareVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdHardwareVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdHardwareVersionGet;
})();

export const RespHardwareVersionGet = $root.RespHardwareVersionGet = (() => {

    /**
     * Properties of a RespHardwareVersionGet.
     * @exports IRespHardwareVersionGet
     * @interface IRespHardwareVersionGet
     * @property {number|null} [result] RespHardwareVersionGet result
     * @property {number|null} [hardwareVersion] RespHardwareVersionGet hardwareVersion
     */

    /**
     * Constructs a new RespHardwareVersionGet.
     * @exports RespHardwareVersionGet
     * @classdesc Represents a RespHardwareVersionGet.
     * @implements IRespHardwareVersionGet
     * @constructor
     * @param {IRespHardwareVersionGet=} [properties] Properties to set
     */
    function RespHardwareVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespHardwareVersionGet result.
     * @member {number} result
     * @memberof RespHardwareVersionGet
     * @instance
     */
    RespHardwareVersionGet.prototype.result = 0;

    /**
     * RespHardwareVersionGet hardwareVersion.
     * @member {number} hardwareVersion
     * @memberof RespHardwareVersionGet
     * @instance
     */
    RespHardwareVersionGet.prototype.hardwareVersion = 0;

    /**
     * Creates a new RespHardwareVersionGet instance using the specified properties.
     * @function create
     * @memberof RespHardwareVersionGet
     * @static
     * @param {IRespHardwareVersionGet=} [properties] Properties to set
     * @returns {RespHardwareVersionGet} RespHardwareVersionGet instance
     */
    RespHardwareVersionGet.create = function create(properties) {
        return new RespHardwareVersionGet(properties);
    };

    /**
     * Encodes the specified RespHardwareVersionGet message. Does not implicitly {@link RespHardwareVersionGet.verify|verify} messages.
     * @function encode
     * @memberof RespHardwareVersionGet
     * @static
     * @param {RespHardwareVersionGet} message RespHardwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespHardwareVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.hardwareVersion != null && Object.hasOwnProperty.call(message, "hardwareVersion"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.hardwareVersion);
        return writer;
    };

    /**
     * Encodes the specified RespHardwareVersionGet message, length delimited. Does not implicitly {@link RespHardwareVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespHardwareVersionGet
     * @static
     * @param {RespHardwareVersionGet} message RespHardwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespHardwareVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespHardwareVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespHardwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespHardwareVersionGet} RespHardwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespHardwareVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespHardwareVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.hardwareVersion = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespHardwareVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespHardwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespHardwareVersionGet} RespHardwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespHardwareVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespHardwareVersionGet message.
     * @function verify
     * @memberof RespHardwareVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespHardwareVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.hardwareVersion != null && message.hasOwnProperty("hardwareVersion"))
            if (!$util.isInteger(message.hardwareVersion))
                return "hardwareVersion: integer expected";
        return null;
    };

    /**
     * Creates a RespHardwareVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespHardwareVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespHardwareVersionGet} RespHardwareVersionGet
     */
    RespHardwareVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespHardwareVersionGet)
            return object;
        let message = new $root.RespHardwareVersionGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.hardwareVersion != null)
            message.hardwareVersion = object.hardwareVersion >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a RespHardwareVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespHardwareVersionGet
     * @static
     * @param {RespHardwareVersionGet} message RespHardwareVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespHardwareVersionGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.hardwareVersion = 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.hardwareVersion != null && message.hasOwnProperty("hardwareVersion"))
            object.hardwareVersion = message.hardwareVersion;
        return object;
    };

    /**
     * Converts this RespHardwareVersionGet to JSON.
     * @function toJSON
     * @memberof RespHardwareVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespHardwareVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespHardwareVersionGet;
})();

export const CmdPcbaVersionGet = $root.CmdPcbaVersionGet = (() => {

    /**
     * Properties of a CmdPcbaVersionGet.
     * @exports ICmdPcbaVersionGet
     * @interface ICmdPcbaVersionGet
     */

    /**
     * Constructs a new CmdPcbaVersionGet.
     * @exports CmdPcbaVersionGet
     * @classdesc Represents a CmdPcbaVersionGet.
     * @implements ICmdPcbaVersionGet
     * @constructor
     * @param {ICmdPcbaVersionGet=} [properties] Properties to set
     */
    function CmdPcbaVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdPcbaVersionGet instance using the specified properties.
     * @function create
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {ICmdPcbaVersionGet=} [properties] Properties to set
     * @returns {CmdPcbaVersionGet} CmdPcbaVersionGet instance
     */
    CmdPcbaVersionGet.create = function create(properties) {
        return new CmdPcbaVersionGet(properties);
    };

    /**
     * Encodes the specified CmdPcbaVersionGet message. Does not implicitly {@link CmdPcbaVersionGet.verify|verify} messages.
     * @function encode
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {CmdPcbaVersionGet} message CmdPcbaVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdPcbaVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdPcbaVersionGet message, length delimited. Does not implicitly {@link CmdPcbaVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {CmdPcbaVersionGet} message CmdPcbaVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdPcbaVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdPcbaVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdPcbaVersionGet} CmdPcbaVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdPcbaVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdPcbaVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdPcbaVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdPcbaVersionGet} CmdPcbaVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdPcbaVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdPcbaVersionGet message.
     * @function verify
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdPcbaVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdPcbaVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdPcbaVersionGet} CmdPcbaVersionGet
     */
    CmdPcbaVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdPcbaVersionGet)
            return object;
        return new $root.CmdPcbaVersionGet();
    };

    /**
     * Creates a plain object from a CmdPcbaVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdPcbaVersionGet
     * @static
     * @param {CmdPcbaVersionGet} message CmdPcbaVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdPcbaVersionGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdPcbaVersionGet to JSON.
     * @function toJSON
     * @memberof CmdPcbaVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdPcbaVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdPcbaVersionGet;
})();

export const RespPcbaVersionGet = $root.RespPcbaVersionGet = (() => {

    /**
     * Properties of a RespPcbaVersionGet.
     * @exports IRespPcbaVersionGet
     * @interface IRespPcbaVersionGet
     * @property {number|null} [result] RespPcbaVersionGet result
     * @property {string|null} [PcbaVersion] RespPcbaVersionGet PcbaVersion
     */

    /**
     * Constructs a new RespPcbaVersionGet.
     * @exports RespPcbaVersionGet
     * @classdesc Represents a RespPcbaVersionGet.
     * @implements IRespPcbaVersionGet
     * @constructor
     * @param {IRespPcbaVersionGet=} [properties] Properties to set
     */
    function RespPcbaVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespPcbaVersionGet result.
     * @member {number} result
     * @memberof RespPcbaVersionGet
     * @instance
     */
    RespPcbaVersionGet.prototype.result = 0;

    /**
     * RespPcbaVersionGet PcbaVersion.
     * @member {string} PcbaVersion
     * @memberof RespPcbaVersionGet
     * @instance
     */
    RespPcbaVersionGet.prototype.PcbaVersion = "";

    /**
     * Creates a new RespPcbaVersionGet instance using the specified properties.
     * @function create
     * @memberof RespPcbaVersionGet
     * @static
     * @param {IRespPcbaVersionGet=} [properties] Properties to set
     * @returns {RespPcbaVersionGet} RespPcbaVersionGet instance
     */
    RespPcbaVersionGet.create = function create(properties) {
        return new RespPcbaVersionGet(properties);
    };

    /**
     * Encodes the specified RespPcbaVersionGet message. Does not implicitly {@link RespPcbaVersionGet.verify|verify} messages.
     * @function encode
     * @memberof RespPcbaVersionGet
     * @static
     * @param {RespPcbaVersionGet} message RespPcbaVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespPcbaVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.PcbaVersion != null && Object.hasOwnProperty.call(message, "PcbaVersion"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.PcbaVersion);
        return writer;
    };

    /**
     * Encodes the specified RespPcbaVersionGet message, length delimited. Does not implicitly {@link RespPcbaVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespPcbaVersionGet
     * @static
     * @param {RespPcbaVersionGet} message RespPcbaVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespPcbaVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespPcbaVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespPcbaVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespPcbaVersionGet} RespPcbaVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespPcbaVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespPcbaVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.PcbaVersion = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespPcbaVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespPcbaVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespPcbaVersionGet} RespPcbaVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespPcbaVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespPcbaVersionGet message.
     * @function verify
     * @memberof RespPcbaVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespPcbaVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.PcbaVersion != null && message.hasOwnProperty("PcbaVersion"))
            if (!$util.isString(message.PcbaVersion))
                return "PcbaVersion: string expected";
        return null;
    };

    /**
     * Creates a RespPcbaVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespPcbaVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespPcbaVersionGet} RespPcbaVersionGet
     */
    RespPcbaVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespPcbaVersionGet)
            return object;
        let message = new $root.RespPcbaVersionGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.PcbaVersion != null)
            message.PcbaVersion = String(object.PcbaVersion);
        return message;
    };

    /**
     * Creates a plain object from a RespPcbaVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespPcbaVersionGet
     * @static
     * @param {RespPcbaVersionGet} message RespPcbaVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespPcbaVersionGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.PcbaVersion = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.PcbaVersion != null && message.hasOwnProperty("PcbaVersion"))
            object.PcbaVersion = message.PcbaVersion;
        return object;
    };

    /**
     * Converts this RespPcbaVersionGet to JSON.
     * @function toJSON
     * @memberof RespPcbaVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespPcbaVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespPcbaVersionGet;
})();

export const CmdModelGet = $root.CmdModelGet = (() => {

    /**
     * Properties of a CmdModelGet.
     * @exports ICmdModelGet
     * @interface ICmdModelGet
     */

    /**
     * Constructs a new CmdModelGet.
     * @exports CmdModelGet
     * @classdesc Represents a CmdModelGet.
     * @implements ICmdModelGet
     * @constructor
     * @param {ICmdModelGet=} [properties] Properties to set
     */
    function CmdModelGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdModelGet instance using the specified properties.
     * @function create
     * @memberof CmdModelGet
     * @static
     * @param {ICmdModelGet=} [properties] Properties to set
     * @returns {CmdModelGet} CmdModelGet instance
     */
    CmdModelGet.create = function create(properties) {
        return new CmdModelGet(properties);
    };

    /**
     * Encodes the specified CmdModelGet message. Does not implicitly {@link CmdModelGet.verify|verify} messages.
     * @function encode
     * @memberof CmdModelGet
     * @static
     * @param {CmdModelGet} message CmdModelGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdModelGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdModelGet message, length delimited. Does not implicitly {@link CmdModelGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdModelGet
     * @static
     * @param {CmdModelGet} message CmdModelGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdModelGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdModelGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdModelGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdModelGet} CmdModelGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdModelGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdModelGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdModelGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdModelGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdModelGet} CmdModelGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdModelGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdModelGet message.
     * @function verify
     * @memberof CmdModelGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdModelGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdModelGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdModelGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdModelGet} CmdModelGet
     */
    CmdModelGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdModelGet)
            return object;
        return new $root.CmdModelGet();
    };

    /**
     * Creates a plain object from a CmdModelGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdModelGet
     * @static
     * @param {CmdModelGet} message CmdModelGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdModelGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdModelGet to JSON.
     * @function toJSON
     * @memberof CmdModelGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdModelGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdModelGet;
})();

export const RespModelGet = $root.RespModelGet = (() => {

    /**
     * Properties of a RespModelGet.
     * @exports IRespModelGet
     * @interface IRespModelGet
     * @property {number|null} [result] RespModelGet result
     * @property {string|null} [model] RespModelGet model
     */

    /**
     * Constructs a new RespModelGet.
     * @exports RespModelGet
     * @classdesc Represents a RespModelGet.
     * @implements IRespModelGet
     * @constructor
     * @param {IRespModelGet=} [properties] Properties to set
     */
    function RespModelGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespModelGet result.
     * @member {number} result
     * @memberof RespModelGet
     * @instance
     */
    RespModelGet.prototype.result = 0;

    /**
     * RespModelGet model.
     * @member {string} model
     * @memberof RespModelGet
     * @instance
     */
    RespModelGet.prototype.model = "";

    /**
     * Creates a new RespModelGet instance using the specified properties.
     * @function create
     * @memberof RespModelGet
     * @static
     * @param {IRespModelGet=} [properties] Properties to set
     * @returns {RespModelGet} RespModelGet instance
     */
    RespModelGet.create = function create(properties) {
        return new RespModelGet(properties);
    };

    /**
     * Encodes the specified RespModelGet message. Does not implicitly {@link RespModelGet.verify|verify} messages.
     * @function encode
     * @memberof RespModelGet
     * @static
     * @param {RespModelGet} message RespModelGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespModelGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.model != null && Object.hasOwnProperty.call(message, "model"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.model);
        return writer;
    };

    /**
     * Encodes the specified RespModelGet message, length delimited. Does not implicitly {@link RespModelGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespModelGet
     * @static
     * @param {RespModelGet} message RespModelGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespModelGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespModelGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespModelGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespModelGet} RespModelGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespModelGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespModelGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.model = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespModelGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespModelGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespModelGet} RespModelGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespModelGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespModelGet message.
     * @function verify
     * @memberof RespModelGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespModelGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.model != null && message.hasOwnProperty("model"))
            if (!$util.isString(message.model))
                return "model: string expected";
        return null;
    };

    /**
     * Creates a RespModelGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespModelGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespModelGet} RespModelGet
     */
    RespModelGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespModelGet)
            return object;
        let message = new $root.RespModelGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.model != null)
            message.model = String(object.model);
        return message;
    };

    /**
     * Creates a plain object from a RespModelGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespModelGet
     * @static
     * @param {RespModelGet} message RespModelGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespModelGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.model = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.model != null && message.hasOwnProperty("model"))
            object.model = message.model;
        return object;
    };

    /**
     * Converts this RespModelGet to JSON.
     * @function toJSON
     * @memberof RespModelGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespModelGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespModelGet;
})();

export const CmdTermsSet = $root.CmdTermsSet = (() => {

    /**
     * Properties of a CmdTermsSet.
     * @exports ICmdTermsSet
     * @interface ICmdTermsSet
     * @property {number|null} [termsBits] CmdTermsSet termsBits
     */

    /**
     * Constructs a new CmdTermsSet.
     * @exports CmdTermsSet
     * @classdesc Represents a CmdTermsSet.
     * @implements ICmdTermsSet
     * @constructor
     * @param {ICmdTermsSet=} [properties] Properties to set
     */
    function CmdTermsSet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CmdTermsSet termsBits.
     * @member {number} termsBits
     * @memberof CmdTermsSet
     * @instance
     */
    CmdTermsSet.prototype.termsBits = 0;

    /**
     * Creates a new CmdTermsSet instance using the specified properties.
     * @function create
     * @memberof CmdTermsSet
     * @static
     * @param {ICmdTermsSet=} [properties] Properties to set
     * @returns {CmdTermsSet} CmdTermsSet instance
     */
    CmdTermsSet.create = function create(properties) {
        return new CmdTermsSet(properties);
    };

    /**
     * Encodes the specified CmdTermsSet message. Does not implicitly {@link CmdTermsSet.verify|verify} messages.
     * @function encode
     * @memberof CmdTermsSet
     * @static
     * @param {CmdTermsSet} message CmdTermsSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdTermsSet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.termsBits != null && Object.hasOwnProperty.call(message, "termsBits"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.termsBits);
        return writer;
    };

    /**
     * Encodes the specified CmdTermsSet message, length delimited. Does not implicitly {@link CmdTermsSet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdTermsSet
     * @static
     * @param {CmdTermsSet} message CmdTermsSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdTermsSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdTermsSet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdTermsSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdTermsSet} CmdTermsSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdTermsSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdTermsSet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.termsBits = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdTermsSet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdTermsSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdTermsSet} CmdTermsSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdTermsSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdTermsSet message.
     * @function verify
     * @memberof CmdTermsSet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdTermsSet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.termsBits != null && message.hasOwnProperty("termsBits"))
            if (!$util.isInteger(message.termsBits))
                return "termsBits: integer expected";
        return null;
    };

    /**
     * Creates a CmdTermsSet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdTermsSet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdTermsSet} CmdTermsSet
     */
    CmdTermsSet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdTermsSet)
            return object;
        let message = new $root.CmdTermsSet();
        if (object.termsBits != null)
            message.termsBits = object.termsBits >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a CmdTermsSet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdTermsSet
     * @static
     * @param {CmdTermsSet} message CmdTermsSet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdTermsSet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.termsBits = 0;
        if (message.termsBits != null && message.hasOwnProperty("termsBits"))
            object.termsBits = message.termsBits;
        return object;
    };

    /**
     * Converts this CmdTermsSet to JSON.
     * @function toJSON
     * @memberof CmdTermsSet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdTermsSet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdTermsSet;
})();

export const RespTermsSet = $root.RespTermsSet = (() => {

    /**
     * Properties of a RespTermsSet.
     * @exports IRespTermsSet
     * @interface IRespTermsSet
     * @property {number|null} [result] RespTermsSet result
     */

    /**
     * Constructs a new RespTermsSet.
     * @exports RespTermsSet
     * @classdesc Represents a RespTermsSet.
     * @implements IRespTermsSet
     * @constructor
     * @param {IRespTermsSet=} [properties] Properties to set
     */
    function RespTermsSet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespTermsSet result.
     * @member {number} result
     * @memberof RespTermsSet
     * @instance
     */
    RespTermsSet.prototype.result = 0;

    /**
     * Creates a new RespTermsSet instance using the specified properties.
     * @function create
     * @memberof RespTermsSet
     * @static
     * @param {IRespTermsSet=} [properties] Properties to set
     * @returns {RespTermsSet} RespTermsSet instance
     */
    RespTermsSet.create = function create(properties) {
        return new RespTermsSet(properties);
    };

    /**
     * Encodes the specified RespTermsSet message. Does not implicitly {@link RespTermsSet.verify|verify} messages.
     * @function encode
     * @memberof RespTermsSet
     * @static
     * @param {RespTermsSet} message RespTermsSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespTermsSet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified RespTermsSet message, length delimited. Does not implicitly {@link RespTermsSet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespTermsSet
     * @static
     * @param {RespTermsSet} message RespTermsSet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespTermsSet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespTermsSet message from the specified reader or buffer.
     * @function decode
     * @memberof RespTermsSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespTermsSet} RespTermsSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespTermsSet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespTermsSet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespTermsSet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespTermsSet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespTermsSet} RespTermsSet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespTermsSet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespTermsSet message.
     * @function verify
     * @memberof RespTermsSet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespTermsSet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        return null;
    };

    /**
     * Creates a RespTermsSet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespTermsSet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespTermsSet} RespTermsSet
     */
    RespTermsSet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespTermsSet)
            return object;
        let message = new $root.RespTermsSet();
        if (object.result != null)
            message.result = object.result | 0;
        return message;
    };

    /**
     * Creates a plain object from a RespTermsSet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespTermsSet
     * @static
     * @param {RespTermsSet} message RespTermsSet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespTermsSet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        return object;
    };

    /**
     * Converts this RespTermsSet to JSON.
     * @function toJSON
     * @memberof RespTermsSet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespTermsSet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespTermsSet;
})();

export const CmdTermsGet = $root.CmdTermsGet = (() => {

    /**
     * Properties of a CmdTermsGet.
     * @exports ICmdTermsGet
     * @interface ICmdTermsGet
     */

    /**
     * Constructs a new CmdTermsGet.
     * @exports CmdTermsGet
     * @classdesc Represents a CmdTermsGet.
     * @implements ICmdTermsGet
     * @constructor
     * @param {ICmdTermsGet=} [properties] Properties to set
     */
    function CmdTermsGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdTermsGet instance using the specified properties.
     * @function create
     * @memberof CmdTermsGet
     * @static
     * @param {ICmdTermsGet=} [properties] Properties to set
     * @returns {CmdTermsGet} CmdTermsGet instance
     */
    CmdTermsGet.create = function create(properties) {
        return new CmdTermsGet(properties);
    };

    /**
     * Encodes the specified CmdTermsGet message. Does not implicitly {@link CmdTermsGet.verify|verify} messages.
     * @function encode
     * @memberof CmdTermsGet
     * @static
     * @param {CmdTermsGet} message CmdTermsGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdTermsGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdTermsGet message, length delimited. Does not implicitly {@link CmdTermsGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdTermsGet
     * @static
     * @param {CmdTermsGet} message CmdTermsGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdTermsGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdTermsGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdTermsGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdTermsGet} CmdTermsGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdTermsGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdTermsGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdTermsGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdTermsGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdTermsGet} CmdTermsGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdTermsGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdTermsGet message.
     * @function verify
     * @memberof CmdTermsGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdTermsGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdTermsGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdTermsGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdTermsGet} CmdTermsGet
     */
    CmdTermsGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdTermsGet)
            return object;
        return new $root.CmdTermsGet();
    };

    /**
     * Creates a plain object from a CmdTermsGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdTermsGet
     * @static
     * @param {CmdTermsGet} message CmdTermsGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdTermsGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdTermsGet to JSON.
     * @function toJSON
     * @memberof CmdTermsGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdTermsGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdTermsGet;
})();

export const RespTermsGet = $root.RespTermsGet = (() => {

    /**
     * Properties of a RespTermsGet.
     * @exports IRespTermsGet
     * @interface IRespTermsGet
     * @property {number|null} [result] RespTermsGet result
     * @property {number|null} [termsBits] RespTermsGet termsBits
     */

    /**
     * Constructs a new RespTermsGet.
     * @exports RespTermsGet
     * @classdesc Represents a RespTermsGet.
     * @implements IRespTermsGet
     * @constructor
     * @param {IRespTermsGet=} [properties] Properties to set
     */
    function RespTermsGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespTermsGet result.
     * @member {number} result
     * @memberof RespTermsGet
     * @instance
     */
    RespTermsGet.prototype.result = 0;

    /**
     * RespTermsGet termsBits.
     * @member {number} termsBits
     * @memberof RespTermsGet
     * @instance
     */
    RespTermsGet.prototype.termsBits = 0;

    /**
     * Creates a new RespTermsGet instance using the specified properties.
     * @function create
     * @memberof RespTermsGet
     * @static
     * @param {IRespTermsGet=} [properties] Properties to set
     * @returns {RespTermsGet} RespTermsGet instance
     */
    RespTermsGet.create = function create(properties) {
        return new RespTermsGet(properties);
    };

    /**
     * Encodes the specified RespTermsGet message. Does not implicitly {@link RespTermsGet.verify|verify} messages.
     * @function encode
     * @memberof RespTermsGet
     * @static
     * @param {RespTermsGet} message RespTermsGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespTermsGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.termsBits != null && Object.hasOwnProperty.call(message, "termsBits"))
            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.termsBits);
        return writer;
    };

    /**
     * Encodes the specified RespTermsGet message, length delimited. Does not implicitly {@link RespTermsGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespTermsGet
     * @static
     * @param {RespTermsGet} message RespTermsGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespTermsGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespTermsGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespTermsGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespTermsGet} RespTermsGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespTermsGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespTermsGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.termsBits = reader.uint32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespTermsGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespTermsGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespTermsGet} RespTermsGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespTermsGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespTermsGet message.
     * @function verify
     * @memberof RespTermsGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespTermsGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.termsBits != null && message.hasOwnProperty("termsBits"))
            if (!$util.isInteger(message.termsBits))
                return "termsBits: integer expected";
        return null;
    };

    /**
     * Creates a RespTermsGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespTermsGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespTermsGet} RespTermsGet
     */
    RespTermsGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespTermsGet)
            return object;
        let message = new $root.RespTermsGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.termsBits != null)
            message.termsBits = object.termsBits >>> 0;
        return message;
    };

    /**
     * Creates a plain object from a RespTermsGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespTermsGet
     * @static
     * @param {RespTermsGet} message RespTermsGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespTermsGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.termsBits = 0;
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.termsBits != null && message.hasOwnProperty("termsBits"))
            object.termsBits = message.termsBits;
        return object;
    };

    /**
     * Converts this RespTermsGet to JSON.
     * @function toJSON
     * @memberof RespTermsGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespTermsGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespTermsGet;
})();

export const CmdFirmwareVersionGet = $root.CmdFirmwareVersionGet = (() => {

    /**
     * Properties of a CmdFirmwareVersionGet.
     * @exports ICmdFirmwareVersionGet
     * @interface ICmdFirmwareVersionGet
     */

    /**
     * Constructs a new CmdFirmwareVersionGet.
     * @exports CmdFirmwareVersionGet
     * @classdesc Represents a CmdFirmwareVersionGet.
     * @implements ICmdFirmwareVersionGet
     * @constructor
     * @param {ICmdFirmwareVersionGet=} [properties] Properties to set
     */
    function CmdFirmwareVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdFirmwareVersionGet instance using the specified properties.
     * @function create
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {ICmdFirmwareVersionGet=} [properties] Properties to set
     * @returns {CmdFirmwareVersionGet} CmdFirmwareVersionGet instance
     */
    CmdFirmwareVersionGet.create = function create(properties) {
        return new CmdFirmwareVersionGet(properties);
    };

    /**
     * Encodes the specified CmdFirmwareVersionGet message. Does not implicitly {@link CmdFirmwareVersionGet.verify|verify} messages.
     * @function encode
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {CmdFirmwareVersionGet} message CmdFirmwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdFirmwareVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdFirmwareVersionGet message, length delimited. Does not implicitly {@link CmdFirmwareVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {CmdFirmwareVersionGet} message CmdFirmwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdFirmwareVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdFirmwareVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdFirmwareVersionGet} CmdFirmwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdFirmwareVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdFirmwareVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdFirmwareVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdFirmwareVersionGet} CmdFirmwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdFirmwareVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdFirmwareVersionGet message.
     * @function verify
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdFirmwareVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdFirmwareVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdFirmwareVersionGet} CmdFirmwareVersionGet
     */
    CmdFirmwareVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdFirmwareVersionGet)
            return object;
        return new $root.CmdFirmwareVersionGet();
    };

    /**
     * Creates a plain object from a CmdFirmwareVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdFirmwareVersionGet
     * @static
     * @param {CmdFirmwareVersionGet} message CmdFirmwareVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdFirmwareVersionGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdFirmwareVersionGet to JSON.
     * @function toJSON
     * @memberof CmdFirmwareVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdFirmwareVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdFirmwareVersionGet;
})();

export const RespFirmwareVersionGet = $root.RespFirmwareVersionGet = (() => {

    /**
     * Properties of a RespFirmwareVersionGet.
     * @exports IRespFirmwareVersionGet
     * @interface IRespFirmwareVersionGet
     * @property {number|null} [result] RespFirmwareVersionGet result
     * @property {string|null} [semver] RespFirmwareVersionGet semver
     */

    /**
     * Constructs a new RespFirmwareVersionGet.
     * @exports RespFirmwareVersionGet
     * @classdesc Represents a RespFirmwareVersionGet.
     * @implements IRespFirmwareVersionGet
     * @constructor
     * @param {IRespFirmwareVersionGet=} [properties] Properties to set
     */
    function RespFirmwareVersionGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespFirmwareVersionGet result.
     * @member {number} result
     * @memberof RespFirmwareVersionGet
     * @instance
     */
    RespFirmwareVersionGet.prototype.result = 0;

    /**
     * RespFirmwareVersionGet semver.
     * @member {string} semver
     * @memberof RespFirmwareVersionGet
     * @instance
     */
    RespFirmwareVersionGet.prototype.semver = "";

    /**
     * Creates a new RespFirmwareVersionGet instance using the specified properties.
     * @function create
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {IRespFirmwareVersionGet=} [properties] Properties to set
     * @returns {RespFirmwareVersionGet} RespFirmwareVersionGet instance
     */
    RespFirmwareVersionGet.create = function create(properties) {
        return new RespFirmwareVersionGet(properties);
    };

    /**
     * Encodes the specified RespFirmwareVersionGet message. Does not implicitly {@link RespFirmwareVersionGet.verify|verify} messages.
     * @function encode
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {RespFirmwareVersionGet} message RespFirmwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespFirmwareVersionGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.semver != null && Object.hasOwnProperty.call(message, "semver"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.semver);
        return writer;
    };

    /**
     * Encodes the specified RespFirmwareVersionGet message, length delimited. Does not implicitly {@link RespFirmwareVersionGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {RespFirmwareVersionGet} message RespFirmwareVersionGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespFirmwareVersionGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespFirmwareVersionGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespFirmwareVersionGet} RespFirmwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespFirmwareVersionGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespFirmwareVersionGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.semver = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespFirmwareVersionGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespFirmwareVersionGet} RespFirmwareVersionGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespFirmwareVersionGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespFirmwareVersionGet message.
     * @function verify
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespFirmwareVersionGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.semver != null && message.hasOwnProperty("semver"))
            if (!$util.isString(message.semver))
                return "semver: string expected";
        return null;
    };

    /**
     * Creates a RespFirmwareVersionGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespFirmwareVersionGet} RespFirmwareVersionGet
     */
    RespFirmwareVersionGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespFirmwareVersionGet)
            return object;
        let message = new $root.RespFirmwareVersionGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.semver != null)
            message.semver = String(object.semver);
        return message;
    };

    /**
     * Creates a plain object from a RespFirmwareVersionGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespFirmwareVersionGet
     * @static
     * @param {RespFirmwareVersionGet} message RespFirmwareVersionGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespFirmwareVersionGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.semver = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.semver != null && message.hasOwnProperty("semver"))
            object.semver = message.semver;
        return object;
    };

    /**
     * Converts this RespFirmwareVersionGet to JSON.
     * @function toJSON
     * @memberof RespFirmwareVersionGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespFirmwareVersionGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespFirmwareVersionGet;
})();

export const CmdWifiStaMacGet = $root.CmdWifiStaMacGet = (() => {

    /**
     * Properties of a CmdWifiStaMacGet.
     * @exports ICmdWifiStaMacGet
     * @interface ICmdWifiStaMacGet
     */

    /**
     * Constructs a new CmdWifiStaMacGet.
     * @exports CmdWifiStaMacGet
     * @classdesc Represents a CmdWifiStaMacGet.
     * @implements ICmdWifiStaMacGet
     * @constructor
     * @param {ICmdWifiStaMacGet=} [properties] Properties to set
     */
    function CmdWifiStaMacGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdWifiStaMacGet instance using the specified properties.
     * @function create
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {ICmdWifiStaMacGet=} [properties] Properties to set
     * @returns {CmdWifiStaMacGet} CmdWifiStaMacGet instance
     */
    CmdWifiStaMacGet.create = function create(properties) {
        return new CmdWifiStaMacGet(properties);
    };

    /**
     * Encodes the specified CmdWifiStaMacGet message. Does not implicitly {@link CmdWifiStaMacGet.verify|verify} messages.
     * @function encode
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {CmdWifiStaMacGet} message CmdWifiStaMacGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdWifiStaMacGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdWifiStaMacGet message, length delimited. Does not implicitly {@link CmdWifiStaMacGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {CmdWifiStaMacGet} message CmdWifiStaMacGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdWifiStaMacGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdWifiStaMacGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdWifiStaMacGet} CmdWifiStaMacGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdWifiStaMacGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdWifiStaMacGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdWifiStaMacGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdWifiStaMacGet} CmdWifiStaMacGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdWifiStaMacGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdWifiStaMacGet message.
     * @function verify
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdWifiStaMacGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdWifiStaMacGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdWifiStaMacGet} CmdWifiStaMacGet
     */
    CmdWifiStaMacGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdWifiStaMacGet)
            return object;
        return new $root.CmdWifiStaMacGet();
    };

    /**
     * Creates a plain object from a CmdWifiStaMacGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdWifiStaMacGet
     * @static
     * @param {CmdWifiStaMacGet} message CmdWifiStaMacGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdWifiStaMacGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdWifiStaMacGet to JSON.
     * @function toJSON
     * @memberof CmdWifiStaMacGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdWifiStaMacGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdWifiStaMacGet;
})();

export const RespWifiStaMacGet = $root.RespWifiStaMacGet = (() => {

    /**
     * Properties of a RespWifiStaMacGet.
     * @exports IRespWifiStaMacGet
     * @interface IRespWifiStaMacGet
     * @property {number|null} [result] RespWifiStaMacGet result
     * @property {string|null} [mac] RespWifiStaMacGet mac
     */

    /**
     * Constructs a new RespWifiStaMacGet.
     * @exports RespWifiStaMacGet
     * @classdesc Represents a RespWifiStaMacGet.
     * @implements IRespWifiStaMacGet
     * @constructor
     * @param {IRespWifiStaMacGet=} [properties] Properties to set
     */
    function RespWifiStaMacGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespWifiStaMacGet result.
     * @member {number} result
     * @memberof RespWifiStaMacGet
     * @instance
     */
    RespWifiStaMacGet.prototype.result = 0;

    /**
     * RespWifiStaMacGet mac.
     * @member {string} mac
     * @memberof RespWifiStaMacGet
     * @instance
     */
    RespWifiStaMacGet.prototype.mac = "";

    /**
     * Creates a new RespWifiStaMacGet instance using the specified properties.
     * @function create
     * @memberof RespWifiStaMacGet
     * @static
     * @param {IRespWifiStaMacGet=} [properties] Properties to set
     * @returns {RespWifiStaMacGet} RespWifiStaMacGet instance
     */
    RespWifiStaMacGet.create = function create(properties) {
        return new RespWifiStaMacGet(properties);
    };

    /**
     * Encodes the specified RespWifiStaMacGet message. Does not implicitly {@link RespWifiStaMacGet.verify|verify} messages.
     * @function encode
     * @memberof RespWifiStaMacGet
     * @static
     * @param {RespWifiStaMacGet} message RespWifiStaMacGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespWifiStaMacGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.mac != null && Object.hasOwnProperty.call(message, "mac"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.mac);
        return writer;
    };

    /**
     * Encodes the specified RespWifiStaMacGet message, length delimited. Does not implicitly {@link RespWifiStaMacGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespWifiStaMacGet
     * @static
     * @param {RespWifiStaMacGet} message RespWifiStaMacGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespWifiStaMacGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespWifiStaMacGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespWifiStaMacGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespWifiStaMacGet} RespWifiStaMacGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespWifiStaMacGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespWifiStaMacGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.mac = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespWifiStaMacGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespWifiStaMacGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespWifiStaMacGet} RespWifiStaMacGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespWifiStaMacGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespWifiStaMacGet message.
     * @function verify
     * @memberof RespWifiStaMacGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespWifiStaMacGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.mac != null && message.hasOwnProperty("mac"))
            if (!$util.isString(message.mac))
                return "mac: string expected";
        return null;
    };

    /**
     * Creates a RespWifiStaMacGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespWifiStaMacGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespWifiStaMacGet} RespWifiStaMacGet
     */
    RespWifiStaMacGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespWifiStaMacGet)
            return object;
        let message = new $root.RespWifiStaMacGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.mac != null)
            message.mac = String(object.mac);
        return message;
    };

    /**
     * Creates a plain object from a RespWifiStaMacGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespWifiStaMacGet
     * @static
     * @param {RespWifiStaMacGet} message RespWifiStaMacGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespWifiStaMacGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.mac = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.mac != null && message.hasOwnProperty("mac"))
            object.mac = message.mac;
        return object;
    };

    /**
     * Converts this RespWifiStaMacGet to JSON.
     * @function toJSON
     * @memberof RespWifiStaMacGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespWifiStaMacGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespWifiStaMacGet;
})();

export const CmdWifiStaIpGet = $root.CmdWifiStaIpGet = (() => {

    /**
     * Properties of a CmdWifiStaIpGet.
     * @exports ICmdWifiStaIpGet
     * @interface ICmdWifiStaIpGet
     */

    /**
     * Constructs a new CmdWifiStaIpGet.
     * @exports CmdWifiStaIpGet
     * @classdesc Represents a CmdWifiStaIpGet.
     * @implements ICmdWifiStaIpGet
     * @constructor
     * @param {ICmdWifiStaIpGet=} [properties] Properties to set
     */
    function CmdWifiStaIpGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdWifiStaIpGet instance using the specified properties.
     * @function create
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {ICmdWifiStaIpGet=} [properties] Properties to set
     * @returns {CmdWifiStaIpGet} CmdWifiStaIpGet instance
     */
    CmdWifiStaIpGet.create = function create(properties) {
        return new CmdWifiStaIpGet(properties);
    };

    /**
     * Encodes the specified CmdWifiStaIpGet message. Does not implicitly {@link CmdWifiStaIpGet.verify|verify} messages.
     * @function encode
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {CmdWifiStaIpGet} message CmdWifiStaIpGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdWifiStaIpGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdWifiStaIpGet message, length delimited. Does not implicitly {@link CmdWifiStaIpGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {CmdWifiStaIpGet} message CmdWifiStaIpGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdWifiStaIpGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdWifiStaIpGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdWifiStaIpGet} CmdWifiStaIpGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdWifiStaIpGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdWifiStaIpGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdWifiStaIpGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdWifiStaIpGet} CmdWifiStaIpGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdWifiStaIpGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdWifiStaIpGet message.
     * @function verify
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdWifiStaIpGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdWifiStaIpGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdWifiStaIpGet} CmdWifiStaIpGet
     */
    CmdWifiStaIpGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdWifiStaIpGet)
            return object;
        return new $root.CmdWifiStaIpGet();
    };

    /**
     * Creates a plain object from a CmdWifiStaIpGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdWifiStaIpGet
     * @static
     * @param {CmdWifiStaIpGet} message CmdWifiStaIpGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdWifiStaIpGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdWifiStaIpGet to JSON.
     * @function toJSON
     * @memberof CmdWifiStaIpGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdWifiStaIpGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdWifiStaIpGet;
})();

export const RespWifiStaIpGet = $root.RespWifiStaIpGet = (() => {

    /**
     * Properties of a RespWifiStaIpGet.
     * @exports IRespWifiStaIpGet
     * @interface IRespWifiStaIpGet
     * @property {number|null} [result] RespWifiStaIpGet result
     * @property {string|null} [ip] RespWifiStaIpGet ip
     */

    /**
     * Constructs a new RespWifiStaIpGet.
     * @exports RespWifiStaIpGet
     * @classdesc Represents a RespWifiStaIpGet.
     * @implements IRespWifiStaIpGet
     * @constructor
     * @param {IRespWifiStaIpGet=} [properties] Properties to set
     */
    function RespWifiStaIpGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespWifiStaIpGet result.
     * @member {number} result
     * @memberof RespWifiStaIpGet
     * @instance
     */
    RespWifiStaIpGet.prototype.result = 0;

    /**
     * RespWifiStaIpGet ip.
     * @member {string} ip
     * @memberof RespWifiStaIpGet
     * @instance
     */
    RespWifiStaIpGet.prototype.ip = "";

    /**
     * Creates a new RespWifiStaIpGet instance using the specified properties.
     * @function create
     * @memberof RespWifiStaIpGet
     * @static
     * @param {IRespWifiStaIpGet=} [properties] Properties to set
     * @returns {RespWifiStaIpGet} RespWifiStaIpGet instance
     */
    RespWifiStaIpGet.create = function create(properties) {
        return new RespWifiStaIpGet(properties);
    };

    /**
     * Encodes the specified RespWifiStaIpGet message. Does not implicitly {@link RespWifiStaIpGet.verify|verify} messages.
     * @function encode
     * @memberof RespWifiStaIpGet
     * @static
     * @param {RespWifiStaIpGet} message RespWifiStaIpGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespWifiStaIpGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ip);
        return writer;
    };

    /**
     * Encodes the specified RespWifiStaIpGet message, length delimited. Does not implicitly {@link RespWifiStaIpGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespWifiStaIpGet
     * @static
     * @param {RespWifiStaIpGet} message RespWifiStaIpGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespWifiStaIpGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespWifiStaIpGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespWifiStaIpGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespWifiStaIpGet} RespWifiStaIpGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespWifiStaIpGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespWifiStaIpGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.ip = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespWifiStaIpGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespWifiStaIpGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespWifiStaIpGet} RespWifiStaIpGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespWifiStaIpGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespWifiStaIpGet message.
     * @function verify
     * @memberof RespWifiStaIpGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespWifiStaIpGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.ip != null && message.hasOwnProperty("ip"))
            if (!$util.isString(message.ip))
                return "ip: string expected";
        return null;
    };

    /**
     * Creates a RespWifiStaIpGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespWifiStaIpGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespWifiStaIpGet} RespWifiStaIpGet
     */
    RespWifiStaIpGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespWifiStaIpGet)
            return object;
        let message = new $root.RespWifiStaIpGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.ip != null)
            message.ip = String(object.ip);
        return message;
    };

    /**
     * Creates a plain object from a RespWifiStaIpGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespWifiStaIpGet
     * @static
     * @param {RespWifiStaIpGet} message RespWifiStaIpGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespWifiStaIpGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.ip = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.ip != null && message.hasOwnProperty("ip"))
            object.ip = message.ip;
        return object;
    };

    /**
     * Converts this RespWifiStaIpGet to JSON.
     * @function toJSON
     * @memberof RespWifiStaIpGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespWifiStaIpGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespWifiStaIpGet;
})();

export const CmdPing = $root.CmdPing = (() => {

    /**
     * Properties of a CmdPing.
     * @exports ICmdPing
     * @interface ICmdPing
     */

    /**
     * Constructs a new CmdPing.
     * @exports CmdPing
     * @classdesc Represents a CmdPing.
     * @implements ICmdPing
     * @constructor
     * @param {ICmdPing=} [properties] Properties to set
     */
    function CmdPing(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdPing instance using the specified properties.
     * @function create
     * @memberof CmdPing
     * @static
     * @param {ICmdPing=} [properties] Properties to set
     * @returns {CmdPing} CmdPing instance
     */
    CmdPing.create = function create(properties) {
        return new CmdPing(properties);
    };

    /**
     * Encodes the specified CmdPing message. Does not implicitly {@link CmdPing.verify|verify} messages.
     * @function encode
     * @memberof CmdPing
     * @static
     * @param {CmdPing} message CmdPing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdPing.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdPing message, length delimited. Does not implicitly {@link CmdPing.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdPing
     * @static
     * @param {CmdPing} message CmdPing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdPing.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdPing message from the specified reader or buffer.
     * @function decode
     * @memberof CmdPing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdPing} CmdPing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdPing.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdPing();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdPing message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdPing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdPing} CmdPing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdPing.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdPing message.
     * @function verify
     * @memberof CmdPing
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdPing.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdPing message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdPing
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdPing} CmdPing
     */
    CmdPing.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdPing)
            return object;
        return new $root.CmdPing();
    };

    /**
     * Creates a plain object from a CmdPing message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdPing
     * @static
     * @param {CmdPing} message CmdPing
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdPing.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdPing to JSON.
     * @function toJSON
     * @memberof CmdPing
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdPing.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdPing;
})();

export const RespPing = $root.RespPing = (() => {

    /**
     * Properties of a RespPing.
     * @exports IRespPing
     * @interface IRespPing
     */

    /**
     * Constructs a new RespPing.
     * @exports RespPing
     * @classdesc Represents a RespPing.
     * @implements IRespPing
     * @constructor
     * @param {IRespPing=} [properties] Properties to set
     */
    function RespPing(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new RespPing instance using the specified properties.
     * @function create
     * @memberof RespPing
     * @static
     * @param {IRespPing=} [properties] Properties to set
     * @returns {RespPing} RespPing instance
     */
    RespPing.create = function create(properties) {
        return new RespPing(properties);
    };

    /**
     * Encodes the specified RespPing message. Does not implicitly {@link RespPing.verify|verify} messages.
     * @function encode
     * @memberof RespPing
     * @static
     * @param {RespPing} message RespPing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespPing.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified RespPing message, length delimited. Does not implicitly {@link RespPing.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespPing
     * @static
     * @param {RespPing} message RespPing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespPing.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespPing message from the specified reader or buffer.
     * @function decode
     * @memberof RespPing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespPing} RespPing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespPing.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespPing();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespPing message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespPing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespPing} RespPing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespPing.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespPing message.
     * @function verify
     * @memberof RespPing
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespPing.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a RespPing message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespPing
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespPing} RespPing
     */
    RespPing.fromObject = function fromObject(object) {
        if (object instanceof $root.RespPing)
            return object;
        return new $root.RespPing();
    };

    /**
     * Creates a plain object from a RespPing message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespPing
     * @static
     * @param {RespPing} message RespPing
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespPing.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this RespPing to JSON.
     * @function toJSON
     * @memberof RespPing
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespPing.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespPing;
})();

export const CmdEIOConnect = $root.CmdEIOConnect = (() => {

    /**
     * Properties of a CmdEIOConnect.
     * @exports ICmdEIOConnect
     * @interface ICmdEIOConnect
     */

    /**
     * Constructs a new CmdEIOConnect.
     * @exports CmdEIOConnect
     * @classdesc Represents a CmdEIOConnect.
     * @implements ICmdEIOConnect
     * @constructor
     * @param {ICmdEIOConnect=} [properties] Properties to set
     */
    function CmdEIOConnect(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdEIOConnect instance using the specified properties.
     * @function create
     * @memberof CmdEIOConnect
     * @static
     * @param {ICmdEIOConnect=} [properties] Properties to set
     * @returns {CmdEIOConnect} CmdEIOConnect instance
     */
    CmdEIOConnect.create = function create(properties) {
        return new CmdEIOConnect(properties);
    };

    /**
     * Encodes the specified CmdEIOConnect message. Does not implicitly {@link CmdEIOConnect.verify|verify} messages.
     * @function encode
     * @memberof CmdEIOConnect
     * @static
     * @param {CmdEIOConnect} message CmdEIOConnect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdEIOConnect.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdEIOConnect message, length delimited. Does not implicitly {@link CmdEIOConnect.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdEIOConnect
     * @static
     * @param {CmdEIOConnect} message CmdEIOConnect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdEIOConnect.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdEIOConnect message from the specified reader or buffer.
     * @function decode
     * @memberof CmdEIOConnect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdEIOConnect} CmdEIOConnect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdEIOConnect.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdEIOConnect();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdEIOConnect message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdEIOConnect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdEIOConnect} CmdEIOConnect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdEIOConnect.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdEIOConnect message.
     * @function verify
     * @memberof CmdEIOConnect
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdEIOConnect.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdEIOConnect message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdEIOConnect
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdEIOConnect} CmdEIOConnect
     */
    CmdEIOConnect.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdEIOConnect)
            return object;
        return new $root.CmdEIOConnect();
    };

    /**
     * Creates a plain object from a CmdEIOConnect message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdEIOConnect
     * @static
     * @param {CmdEIOConnect} message CmdEIOConnect
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdEIOConnect.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdEIOConnect to JSON.
     * @function toJSON
     * @memberof CmdEIOConnect
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdEIOConnect.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdEIOConnect;
})();

export const RespEIOConnect = $root.RespEIOConnect = (() => {

    /**
     * Properties of a RespEIOConnect.
     * @exports IRespEIOConnect
     * @interface IRespEIOConnect
     * @property {number|null} [result] RespEIOConnect result
     */

    /**
     * Constructs a new RespEIOConnect.
     * @exports RespEIOConnect
     * @classdesc Represents a RespEIOConnect.
     * @implements IRespEIOConnect
     * @constructor
     * @param {IRespEIOConnect=} [properties] Properties to set
     */
    function RespEIOConnect(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespEIOConnect result.
     * @member {number} result
     * @memberof RespEIOConnect
     * @instance
     */
    RespEIOConnect.prototype.result = 0;

    /**
     * Creates a new RespEIOConnect instance using the specified properties.
     * @function create
     * @memberof RespEIOConnect
     * @static
     * @param {IRespEIOConnect=} [properties] Properties to set
     * @returns {RespEIOConnect} RespEIOConnect instance
     */
    RespEIOConnect.create = function create(properties) {
        return new RespEIOConnect(properties);
    };

    /**
     * Encodes the specified RespEIOConnect message. Does not implicitly {@link RespEIOConnect.verify|verify} messages.
     * @function encode
     * @memberof RespEIOConnect
     * @static
     * @param {RespEIOConnect} message RespEIOConnect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespEIOConnect.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        return writer;
    };

    /**
     * Encodes the specified RespEIOConnect message, length delimited. Does not implicitly {@link RespEIOConnect.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespEIOConnect
     * @static
     * @param {RespEIOConnect} message RespEIOConnect message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespEIOConnect.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespEIOConnect message from the specified reader or buffer.
     * @function decode
     * @memberof RespEIOConnect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespEIOConnect} RespEIOConnect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespEIOConnect.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespEIOConnect();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespEIOConnect message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespEIOConnect
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespEIOConnect} RespEIOConnect
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespEIOConnect.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespEIOConnect message.
     * @function verify
     * @memberof RespEIOConnect
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespEIOConnect.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        return null;
    };

    /**
     * Creates a RespEIOConnect message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespEIOConnect
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespEIOConnect} RespEIOConnect
     */
    RespEIOConnect.fromObject = function fromObject(object) {
        if (object instanceof $root.RespEIOConnect)
            return object;
        let message = new $root.RespEIOConnect();
        if (object.result != null)
            message.result = object.result | 0;
        return message;
    };

    /**
     * Creates a plain object from a RespEIOConnect message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespEIOConnect
     * @static
     * @param {RespEIOConnect} message RespEIOConnect
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespEIOConnect.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.result = 0;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        return object;
    };

    /**
     * Converts this RespEIOConnect to JSON.
     * @function toJSON
     * @memberof RespEIOConnect
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespEIOConnect.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespEIOConnect;
})();

export const CmdConfigWifiSsidGet = $root.CmdConfigWifiSsidGet = (() => {

    /**
     * Properties of a CmdConfigWifiSsidGet.
     * @exports ICmdConfigWifiSsidGet
     * @interface ICmdConfigWifiSsidGet
     */

    /**
     * Constructs a new CmdConfigWifiSsidGet.
     * @exports CmdConfigWifiSsidGet
     * @classdesc Represents a CmdConfigWifiSsidGet.
     * @implements ICmdConfigWifiSsidGet
     * @constructor
     * @param {ICmdConfigWifiSsidGet=} [properties] Properties to set
     */
    function CmdConfigWifiSsidGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CmdConfigWifiSsidGet instance using the specified properties.
     * @function create
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {ICmdConfigWifiSsidGet=} [properties] Properties to set
     * @returns {CmdConfigWifiSsidGet} CmdConfigWifiSsidGet instance
     */
    CmdConfigWifiSsidGet.create = function create(properties) {
        return new CmdConfigWifiSsidGet(properties);
    };

    /**
     * Encodes the specified CmdConfigWifiSsidGet message. Does not implicitly {@link CmdConfigWifiSsidGet.verify|verify} messages.
     * @function encode
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {CmdConfigWifiSsidGet} message CmdConfigWifiSsidGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConfigWifiSsidGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CmdConfigWifiSsidGet message, length delimited. Does not implicitly {@link CmdConfigWifiSsidGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {CmdConfigWifiSsidGet} message CmdConfigWifiSsidGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CmdConfigWifiSsidGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CmdConfigWifiSsidGet message from the specified reader or buffer.
     * @function decode
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CmdConfigWifiSsidGet} CmdConfigWifiSsidGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConfigWifiSsidGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CmdConfigWifiSsidGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CmdConfigWifiSsidGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CmdConfigWifiSsidGet} CmdConfigWifiSsidGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CmdConfigWifiSsidGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CmdConfigWifiSsidGet message.
     * @function verify
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CmdConfigWifiSsidGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CmdConfigWifiSsidGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CmdConfigWifiSsidGet} CmdConfigWifiSsidGet
     */
    CmdConfigWifiSsidGet.fromObject = function fromObject(object) {
        if (object instanceof $root.CmdConfigWifiSsidGet)
            return object;
        return new $root.CmdConfigWifiSsidGet();
    };

    /**
     * Creates a plain object from a CmdConfigWifiSsidGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CmdConfigWifiSsidGet
     * @static
     * @param {CmdConfigWifiSsidGet} message CmdConfigWifiSsidGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CmdConfigWifiSsidGet.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CmdConfigWifiSsidGet to JSON.
     * @function toJSON
     * @memberof CmdConfigWifiSsidGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CmdConfigWifiSsidGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CmdConfigWifiSsidGet;
})();

export const RespConfigWifiSsidGet = $root.RespConfigWifiSsidGet = (() => {

    /**
     * Properties of a RespConfigWifiSsidGet.
     * @exports IRespConfigWifiSsidGet
     * @interface IRespConfigWifiSsidGet
     * @property {number|null} [result] RespConfigWifiSsidGet result
     * @property {string|null} [ssid] RespConfigWifiSsidGet ssid
     */

    /**
     * Constructs a new RespConfigWifiSsidGet.
     * @exports RespConfigWifiSsidGet
     * @classdesc Represents a RespConfigWifiSsidGet.
     * @implements IRespConfigWifiSsidGet
     * @constructor
     * @param {IRespConfigWifiSsidGet=} [properties] Properties to set
     */
    function RespConfigWifiSsidGet(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RespConfigWifiSsidGet result.
     * @member {number} result
     * @memberof RespConfigWifiSsidGet
     * @instance
     */
    RespConfigWifiSsidGet.prototype.result = 0;

    /**
     * RespConfigWifiSsidGet ssid.
     * @member {string} ssid
     * @memberof RespConfigWifiSsidGet
     * @instance
     */
    RespConfigWifiSsidGet.prototype.ssid = "";

    /**
     * Creates a new RespConfigWifiSsidGet instance using the specified properties.
     * @function create
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {IRespConfigWifiSsidGet=} [properties] Properties to set
     * @returns {RespConfigWifiSsidGet} RespConfigWifiSsidGet instance
     */
    RespConfigWifiSsidGet.create = function create(properties) {
        return new RespConfigWifiSsidGet(properties);
    };

    /**
     * Encodes the specified RespConfigWifiSsidGet message. Does not implicitly {@link RespConfigWifiSsidGet.verify|verify} messages.
     * @function encode
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {RespConfigWifiSsidGet} message RespConfigWifiSsidGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConfigWifiSsidGet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
        if (message.ssid != null && Object.hasOwnProperty.call(message, "ssid"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ssid);
        return writer;
    };

    /**
     * Encodes the specified RespConfigWifiSsidGet message, length delimited. Does not implicitly {@link RespConfigWifiSsidGet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {RespConfigWifiSsidGet} message RespConfigWifiSsidGet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RespConfigWifiSsidGet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RespConfigWifiSsidGet message from the specified reader or buffer.
     * @function decode
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RespConfigWifiSsidGet} RespConfigWifiSsidGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConfigWifiSsidGet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RespConfigWifiSsidGet();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.result = reader.int32();
                break;
            case 2:
                message.ssid = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RespConfigWifiSsidGet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RespConfigWifiSsidGet} RespConfigWifiSsidGet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RespConfigWifiSsidGet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RespConfigWifiSsidGet message.
     * @function verify
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RespConfigWifiSsidGet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!$util.isInteger(message.result))
                return "result: integer expected";
        if (message.ssid != null && message.hasOwnProperty("ssid"))
            if (!$util.isString(message.ssid))
                return "ssid: string expected";
        return null;
    };

    /**
     * Creates a RespConfigWifiSsidGet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RespConfigWifiSsidGet} RespConfigWifiSsidGet
     */
    RespConfigWifiSsidGet.fromObject = function fromObject(object) {
        if (object instanceof $root.RespConfigWifiSsidGet)
            return object;
        let message = new $root.RespConfigWifiSsidGet();
        if (object.result != null)
            message.result = object.result | 0;
        if (object.ssid != null)
            message.ssid = String(object.ssid);
        return message;
    };

    /**
     * Creates a plain object from a RespConfigWifiSsidGet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RespConfigWifiSsidGet
     * @static
     * @param {RespConfigWifiSsidGet} message RespConfigWifiSsidGet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RespConfigWifiSsidGet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.result = 0;
            object.ssid = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.ssid != null && message.hasOwnProperty("ssid"))
            object.ssid = message.ssid;
        return object;
    };

    /**
     * Converts this RespConfigWifiSsidGet to JSON.
     * @function toJSON
     * @memberof RespConfigWifiSsidGet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RespConfigWifiSsidGet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return RespConfigWifiSsidGet;
})();

/**
 * HdyMsgType enum.
 * @exports HdyMsgType
 * @enum {number}
 * @property {number} TypeCmdProtocolVersionGet=0 TypeCmdProtocolVersionGet value
 * @property {number} TypeRespProtocolVersionGet=1 TypeRespProtocolVersionGet value
 * @property {number} TypeCmdConnectionKeyGet=2 TypeCmdConnectionKeyGet value
 * @property {number} TypeRespConnectionKeyGet=3 TypeRespConnectionKeyGet value
 * @property {number} TypeCmdConnectionKeyGen=6 TypeCmdConnectionKeyGen value
 * @property {number} TypeRespConnectionKeyGen=7 TypeRespConnectionKeyGen value
 * @property {number} TypeCmdSerialNumberGet=8 TypeCmdSerialNumberGet value
 * @property {number} TypeRespSerialNumberGet=9 TypeRespSerialNumberGet value
 * @property {number} TypeCmdProductionDateGet=10 TypeCmdProductionDateGet value
 * @property {number} TypeRespProductionDateGet=11 TypeRespProductionDateGet value
 * @property {number} TypeCmdHardwareVersionGet=12 TypeCmdHardwareVersionGet value
 * @property {number} TypeRespHardwareVersionGet=13 TypeRespHardwareVersionGet value
 * @property {number} TypeCmdPcbaVersionGet=14 TypeCmdPcbaVersionGet value
 * @property {number} TypeRespPcbaVersionGet=15 TypeRespPcbaVersionGet value
 * @property {number} TypeCmdModelGet=16 TypeCmdModelGet value
 * @property {number} TypeRespModelGet=17 TypeRespModelGet value
 * @property {number} TypeCmdTermsSet=18 TypeCmdTermsSet value
 * @property {number} TypeRespTermsSet=19 TypeRespTermsSet value
 * @property {number} TypeCmdTermsGet=20 TypeCmdTermsGet value
 * @property {number} TypeRespTermsGet=21 TypeRespTermsGet value
 * @property {number} TypeCmdFirmwareVersionGet=22 TypeCmdFirmwareVersionGet value
 * @property {number} TypeRespFirmwareVersionGet=23 TypeRespFirmwareVersionGet value
 * @property {number} TypeCmdWifiStaMacGet=24 TypeCmdWifiStaMacGet value
 * @property {number} TypeRespWifiStaMacGet=25 TypeRespWifiStaMacGet value
 * @property {number} TypeCmdWifiStaIpGet=26 TypeCmdWifiStaIpGet value
 * @property {number} TypeRespWifiStaIpGet=27 TypeRespWifiStaIpGet value
 * @property {number} TypeCmdPing=28 TypeCmdPing value
 * @property {number} TypeRespPing=29 TypeRespPing value
 * @property {number} TypeCmdEIOConnect=30 TypeCmdEIOConnect value
 * @property {number} TypeRespEIOConnect=31 TypeRespEIOConnect value
 * @property {number} TypeCmdConfigWifiSsidGet=32 TypeCmdConfigWifiSsidGet value
 * @property {number} TypeRespConfigWifiSsidGet=33 TypeRespConfigWifiSsidGet value
 */
export const HdyMsgType = $root.HdyMsgType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "TypeCmdProtocolVersionGet"] = 0;
    values[valuesById[1] = "TypeRespProtocolVersionGet"] = 1;
    values[valuesById[2] = "TypeCmdConnectionKeyGet"] = 2;
    values[valuesById[3] = "TypeRespConnectionKeyGet"] = 3;
    values[valuesById[6] = "TypeCmdConnectionKeyGen"] = 6;
    values[valuesById[7] = "TypeRespConnectionKeyGen"] = 7;
    values[valuesById[8] = "TypeCmdSerialNumberGet"] = 8;
    values[valuesById[9] = "TypeRespSerialNumberGet"] = 9;
    values[valuesById[10] = "TypeCmdProductionDateGet"] = 10;
    values[valuesById[11] = "TypeRespProductionDateGet"] = 11;
    values[valuesById[12] = "TypeCmdHardwareVersionGet"] = 12;
    values[valuesById[13] = "TypeRespHardwareVersionGet"] = 13;
    values[valuesById[14] = "TypeCmdPcbaVersionGet"] = 14;
    values[valuesById[15] = "TypeRespPcbaVersionGet"] = 15;
    values[valuesById[16] = "TypeCmdModelGet"] = 16;
    values[valuesById[17] = "TypeRespModelGet"] = 17;
    values[valuesById[18] = "TypeCmdTermsSet"] = 18;
    values[valuesById[19] = "TypeRespTermsSet"] = 19;
    values[valuesById[20] = "TypeCmdTermsGet"] = 20;
    values[valuesById[21] = "TypeRespTermsGet"] = 21;
    values[valuesById[22] = "TypeCmdFirmwareVersionGet"] = 22;
    values[valuesById[23] = "TypeRespFirmwareVersionGet"] = 23;
    values[valuesById[24] = "TypeCmdWifiStaMacGet"] = 24;
    values[valuesById[25] = "TypeRespWifiStaMacGet"] = 25;
    values[valuesById[26] = "TypeCmdWifiStaIpGet"] = 26;
    values[valuesById[27] = "TypeRespWifiStaIpGet"] = 27;
    values[valuesById[28] = "TypeCmdPing"] = 28;
    values[valuesById[29] = "TypeRespPing"] = 29;
    values[valuesById[30] = "TypeCmdEIOConnect"] = 30;
    values[valuesById[31] = "TypeRespEIOConnect"] = 31;
    values[valuesById[32] = "TypeCmdConfigWifiSsidGet"] = 32;
    values[valuesById[33] = "TypeRespConfigWifiSsidGet"] = 33;
    return values;
})();

export const HdyPayload = $root.HdyPayload = (() => {

    /**
     * Properties of a HdyPayload.
     * @exports IHdyPayload
     * @interface IHdyPayload
     * @property {HdyMsgType|null} [msg] HdyPayload msg
     * @property {CmdProtocolVersionGet|null} [cmdProtocolVersionGet] HdyPayload cmdProtocolVersionGet
     * @property {RespProtocolVersionGet|null} [respProtocolVersionGet] HdyPayload respProtocolVersionGet
     * @property {CmdConnectionKeyGet|null} [cmdConnectionKeyGet] HdyPayload cmdConnectionKeyGet
     * @property {RespConnectionKeyGet|null} [respConnectionKeyGet] HdyPayload respConnectionKeyGet
     * @property {CmdConnectionKeyGen|null} [cmdConnectionKeyGen] HdyPayload cmdConnectionKeyGen
     * @property {RespConnectionKeyGen|null} [respConnectionKeyGen] HdyPayload respConnectionKeyGen
     * @property {CmdSerialNumberGet|null} [cmdSerialNumberGet] HdyPayload cmdSerialNumberGet
     * @property {RespSerialNumberGet|null} [respSerialNumberGet] HdyPayload respSerialNumberGet
     * @property {CmdProductionDateGet|null} [cmdProductionDateGet] HdyPayload cmdProductionDateGet
     * @property {RespProductionDateGet|null} [respProductionDateGet] HdyPayload respProductionDateGet
     * @property {CmdHardwareVersionGet|null} [cmdHardwareVersionGet] HdyPayload cmdHardwareVersionGet
     * @property {RespHardwareVersionGet|null} [respHardwareVersionGet] HdyPayload respHardwareVersionGet
     * @property {CmdPcbaVersionGet|null} [cmdPcbaVersionGet] HdyPayload cmdPcbaVersionGet
     * @property {RespPcbaVersionGet|null} [respPcbaVersionGet] HdyPayload respPcbaVersionGet
     * @property {CmdModelGet|null} [cmdModelGet] HdyPayload cmdModelGet
     * @property {RespModelGet|null} [respModelGet] HdyPayload respModelGet
     * @property {CmdTermsSet|null} [cmdTermsSet] HdyPayload cmdTermsSet
     * @property {RespTermsSet|null} [respTermsSet] HdyPayload respTermsSet
     * @property {CmdTermsGet|null} [cmdTermsGet] HdyPayload cmdTermsGet
     * @property {RespTermsGet|null} [respTermsGet] HdyPayload respTermsGet
     * @property {CmdFirmwareVersionGet|null} [cmdFirmwareVersionGet] HdyPayload cmdFirmwareVersionGet
     * @property {RespFirmwareVersionGet|null} [respFirmwareVersionGet] HdyPayload respFirmwareVersionGet
     * @property {CmdWifiStaMacGet|null} [cmdWifiStaMacGet] HdyPayload cmdWifiStaMacGet
     * @property {RespWifiStaMacGet|null} [respWifiStaMacGet] HdyPayload respWifiStaMacGet
     * @property {CmdWifiStaIpGet|null} [cmdWifiStaIpGet] HdyPayload cmdWifiStaIpGet
     * @property {RespWifiStaIpGet|null} [respWifiStaIpGet] HdyPayload respWifiStaIpGet
     * @property {CmdPing|null} [cmdPing] HdyPayload cmdPing
     * @property {RespPing|null} [respPing] HdyPayload respPing
     * @property {CmdEIOConnect|null} [cmdEioConnect] HdyPayload cmdEioConnect
     * @property {RespEIOConnect|null} [respEioConnect] HdyPayload respEioConnect
     * @property {CmdConfigWifiSsidGet|null} [cmdConfigWifiSsidGet] HdyPayload cmdConfigWifiSsidGet
     * @property {RespConfigWifiSsidGet|null} [respConfigWifiSsidGet] HdyPayload respConfigWifiSsidGet
     */

    /**
     * Constructs a new HdyPayload.
     * @exports HdyPayload
     * @classdesc Represents a HdyPayload.
     * @implements IHdyPayload
     * @constructor
     * @param {IHdyPayload=} [properties] Properties to set
     */
    function HdyPayload(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * HdyPayload msg.
     * @member {HdyMsgType} msg
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.msg = 0;

    /**
     * HdyPayload cmdProtocolVersionGet.
     * @member {CmdProtocolVersionGet|null|undefined} cmdProtocolVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdProtocolVersionGet = null;

    /**
     * HdyPayload respProtocolVersionGet.
     * @member {RespProtocolVersionGet|null|undefined} respProtocolVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respProtocolVersionGet = null;

    /**
     * HdyPayload cmdConnectionKeyGet.
     * @member {CmdConnectionKeyGet|null|undefined} cmdConnectionKeyGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdConnectionKeyGet = null;

    /**
     * HdyPayload respConnectionKeyGet.
     * @member {RespConnectionKeyGet|null|undefined} respConnectionKeyGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respConnectionKeyGet = null;

    /**
     * HdyPayload cmdConnectionKeyGen.
     * @member {CmdConnectionKeyGen|null|undefined} cmdConnectionKeyGen
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdConnectionKeyGen = null;

    /**
     * HdyPayload respConnectionKeyGen.
     * @member {RespConnectionKeyGen|null|undefined} respConnectionKeyGen
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respConnectionKeyGen = null;

    /**
     * HdyPayload cmdSerialNumberGet.
     * @member {CmdSerialNumberGet|null|undefined} cmdSerialNumberGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdSerialNumberGet = null;

    /**
     * HdyPayload respSerialNumberGet.
     * @member {RespSerialNumberGet|null|undefined} respSerialNumberGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respSerialNumberGet = null;

    /**
     * HdyPayload cmdProductionDateGet.
     * @member {CmdProductionDateGet|null|undefined} cmdProductionDateGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdProductionDateGet = null;

    /**
     * HdyPayload respProductionDateGet.
     * @member {RespProductionDateGet|null|undefined} respProductionDateGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respProductionDateGet = null;

    /**
     * HdyPayload cmdHardwareVersionGet.
     * @member {CmdHardwareVersionGet|null|undefined} cmdHardwareVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdHardwareVersionGet = null;

    /**
     * HdyPayload respHardwareVersionGet.
     * @member {RespHardwareVersionGet|null|undefined} respHardwareVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respHardwareVersionGet = null;

    /**
     * HdyPayload cmdPcbaVersionGet.
     * @member {CmdPcbaVersionGet|null|undefined} cmdPcbaVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdPcbaVersionGet = null;

    /**
     * HdyPayload respPcbaVersionGet.
     * @member {RespPcbaVersionGet|null|undefined} respPcbaVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respPcbaVersionGet = null;

    /**
     * HdyPayload cmdModelGet.
     * @member {CmdModelGet|null|undefined} cmdModelGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdModelGet = null;

    /**
     * HdyPayload respModelGet.
     * @member {RespModelGet|null|undefined} respModelGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respModelGet = null;

    /**
     * HdyPayload cmdTermsSet.
     * @member {CmdTermsSet|null|undefined} cmdTermsSet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdTermsSet = null;

    /**
     * HdyPayload respTermsSet.
     * @member {RespTermsSet|null|undefined} respTermsSet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respTermsSet = null;

    /**
     * HdyPayload cmdTermsGet.
     * @member {CmdTermsGet|null|undefined} cmdTermsGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdTermsGet = null;

    /**
     * HdyPayload respTermsGet.
     * @member {RespTermsGet|null|undefined} respTermsGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respTermsGet = null;

    /**
     * HdyPayload cmdFirmwareVersionGet.
     * @member {CmdFirmwareVersionGet|null|undefined} cmdFirmwareVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdFirmwareVersionGet = null;

    /**
     * HdyPayload respFirmwareVersionGet.
     * @member {RespFirmwareVersionGet|null|undefined} respFirmwareVersionGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respFirmwareVersionGet = null;

    /**
     * HdyPayload cmdWifiStaMacGet.
     * @member {CmdWifiStaMacGet|null|undefined} cmdWifiStaMacGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdWifiStaMacGet = null;

    /**
     * HdyPayload respWifiStaMacGet.
     * @member {RespWifiStaMacGet|null|undefined} respWifiStaMacGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respWifiStaMacGet = null;

    /**
     * HdyPayload cmdWifiStaIpGet.
     * @member {CmdWifiStaIpGet|null|undefined} cmdWifiStaIpGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdWifiStaIpGet = null;

    /**
     * HdyPayload respWifiStaIpGet.
     * @member {RespWifiStaIpGet|null|undefined} respWifiStaIpGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respWifiStaIpGet = null;

    /**
     * HdyPayload cmdPing.
     * @member {CmdPing|null|undefined} cmdPing
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdPing = null;

    /**
     * HdyPayload respPing.
     * @member {RespPing|null|undefined} respPing
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respPing = null;

    /**
     * HdyPayload cmdEioConnect.
     * @member {CmdEIOConnect|null|undefined} cmdEioConnect
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdEioConnect = null;

    /**
     * HdyPayload respEioConnect.
     * @member {RespEIOConnect|null|undefined} respEioConnect
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respEioConnect = null;

    /**
     * HdyPayload cmdConfigWifiSsidGet.
     * @member {CmdConfigWifiSsidGet|null|undefined} cmdConfigWifiSsidGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.cmdConfigWifiSsidGet = null;

    /**
     * HdyPayload respConfigWifiSsidGet.
     * @member {RespConfigWifiSsidGet|null|undefined} respConfigWifiSsidGet
     * @memberof HdyPayload
     * @instance
     */
    HdyPayload.prototype.respConfigWifiSsidGet = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * HdyPayload payload.
     * @member {"cmdProtocolVersionGet"|"respProtocolVersionGet"|"cmdConnectionKeyGet"|"respConnectionKeyGet"|"cmdConnectionKeyGen"|"respConnectionKeyGen"|"cmdSerialNumberGet"|"respSerialNumberGet"|"cmdProductionDateGet"|"respProductionDateGet"|"cmdHardwareVersionGet"|"respHardwareVersionGet"|"cmdPcbaVersionGet"|"respPcbaVersionGet"|"cmdModelGet"|"respModelGet"|"cmdTermsSet"|"respTermsSet"|"cmdTermsGet"|"respTermsGet"|"cmdFirmwareVersionGet"|"respFirmwareVersionGet"|"cmdWifiStaMacGet"|"respWifiStaMacGet"|"cmdWifiStaIpGet"|"respWifiStaIpGet"|"cmdPing"|"respPing"|"cmdEioConnect"|"respEioConnect"|"cmdConfigWifiSsidGet"|"respConfigWifiSsidGet"|undefined} payload
     * @memberof HdyPayload
     * @instance
     */
    Object.defineProperty(HdyPayload.prototype, "payload", {
        get: $util.oneOfGetter($oneOfFields = ["cmdProtocolVersionGet", "respProtocolVersionGet", "cmdConnectionKeyGet", "respConnectionKeyGet", "cmdConnectionKeyGen", "respConnectionKeyGen", "cmdSerialNumberGet", "respSerialNumberGet", "cmdProductionDateGet", "respProductionDateGet", "cmdHardwareVersionGet", "respHardwareVersionGet", "cmdPcbaVersionGet", "respPcbaVersionGet", "cmdModelGet", "respModelGet", "cmdTermsSet", "respTermsSet", "cmdTermsGet", "respTermsGet", "cmdFirmwareVersionGet", "respFirmwareVersionGet", "cmdWifiStaMacGet", "respWifiStaMacGet", "cmdWifiStaIpGet", "respWifiStaIpGet", "cmdPing", "respPing", "cmdEioConnect", "respEioConnect", "cmdConfigWifiSsidGet", "respConfigWifiSsidGet"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new HdyPayload instance using the specified properties.
     * @function create
     * @memberof HdyPayload
     * @static
     * @param {IHdyPayload=} [properties] Properties to set
     * @returns {HdyPayload} HdyPayload instance
     */
    HdyPayload.create = function create(properties) {
        return new HdyPayload(properties);
    };

    /**
     * Encodes the specified HdyPayload message. Does not implicitly {@link HdyPayload.verify|verify} messages.
     * @function encode
     * @memberof HdyPayload
     * @static
     * @param {HdyPayload} message HdyPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HdyPayload.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msg);
        if (message.cmdProtocolVersionGet != null && Object.hasOwnProperty.call(message, "cmdProtocolVersionGet"))
            $root.CmdProtocolVersionGet.encode(message.cmdProtocolVersionGet, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        if (message.respProtocolVersionGet != null && Object.hasOwnProperty.call(message, "respProtocolVersionGet"))
            $root.RespProtocolVersionGet.encode(message.respProtocolVersionGet, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
        if (message.cmdConnectionKeyGet != null && Object.hasOwnProperty.call(message, "cmdConnectionKeyGet"))
            $root.CmdConnectionKeyGet.encode(message.cmdConnectionKeyGet, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
        if (message.respConnectionKeyGet != null && Object.hasOwnProperty.call(message, "respConnectionKeyGet"))
            $root.RespConnectionKeyGet.encode(message.respConnectionKeyGet, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
        if (message.cmdConnectionKeyGen != null && Object.hasOwnProperty.call(message, "cmdConnectionKeyGen"))
            $root.CmdConnectionKeyGen.encode(message.cmdConnectionKeyGen, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
        if (message.respConnectionKeyGen != null && Object.hasOwnProperty.call(message, "respConnectionKeyGen"))
            $root.RespConnectionKeyGen.encode(message.respConnectionKeyGen, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
        if (message.cmdSerialNumberGet != null && Object.hasOwnProperty.call(message, "cmdSerialNumberGet"))
            $root.CmdSerialNumberGet.encode(message.cmdSerialNumberGet, writer.uint32(/* id 108, wireType 2 =*/866).fork()).ldelim();
        if (message.respSerialNumberGet != null && Object.hasOwnProperty.call(message, "respSerialNumberGet"))
            $root.RespSerialNumberGet.encode(message.respSerialNumberGet, writer.uint32(/* id 109, wireType 2 =*/874).fork()).ldelim();
        if (message.cmdProductionDateGet != null && Object.hasOwnProperty.call(message, "cmdProductionDateGet"))
            $root.CmdProductionDateGet.encode(message.cmdProductionDateGet, writer.uint32(/* id 110, wireType 2 =*/882).fork()).ldelim();
        if (message.respProductionDateGet != null && Object.hasOwnProperty.call(message, "respProductionDateGet"))
            $root.RespProductionDateGet.encode(message.respProductionDateGet, writer.uint32(/* id 111, wireType 2 =*/890).fork()).ldelim();
        if (message.cmdHardwareVersionGet != null && Object.hasOwnProperty.call(message, "cmdHardwareVersionGet"))
            $root.CmdHardwareVersionGet.encode(message.cmdHardwareVersionGet, writer.uint32(/* id 112, wireType 2 =*/898).fork()).ldelim();
        if (message.respHardwareVersionGet != null && Object.hasOwnProperty.call(message, "respHardwareVersionGet"))
            $root.RespHardwareVersionGet.encode(message.respHardwareVersionGet, writer.uint32(/* id 113, wireType 2 =*/906).fork()).ldelim();
        if (message.cmdPcbaVersionGet != null && Object.hasOwnProperty.call(message, "cmdPcbaVersionGet"))
            $root.CmdPcbaVersionGet.encode(message.cmdPcbaVersionGet, writer.uint32(/* id 114, wireType 2 =*/914).fork()).ldelim();
        if (message.respPcbaVersionGet != null && Object.hasOwnProperty.call(message, "respPcbaVersionGet"))
            $root.RespPcbaVersionGet.encode(message.respPcbaVersionGet, writer.uint32(/* id 115, wireType 2 =*/922).fork()).ldelim();
        if (message.cmdModelGet != null && Object.hasOwnProperty.call(message, "cmdModelGet"))
            $root.CmdModelGet.encode(message.cmdModelGet, writer.uint32(/* id 116, wireType 2 =*/930).fork()).ldelim();
        if (message.respModelGet != null && Object.hasOwnProperty.call(message, "respModelGet"))
            $root.RespModelGet.encode(message.respModelGet, writer.uint32(/* id 117, wireType 2 =*/938).fork()).ldelim();
        if (message.cmdTermsSet != null && Object.hasOwnProperty.call(message, "cmdTermsSet"))
            $root.CmdTermsSet.encode(message.cmdTermsSet, writer.uint32(/* id 118, wireType 2 =*/946).fork()).ldelim();
        if (message.respTermsSet != null && Object.hasOwnProperty.call(message, "respTermsSet"))
            $root.RespTermsSet.encode(message.respTermsSet, writer.uint32(/* id 119, wireType 2 =*/954).fork()).ldelim();
        if (message.cmdTermsGet != null && Object.hasOwnProperty.call(message, "cmdTermsGet"))
            $root.CmdTermsGet.encode(message.cmdTermsGet, writer.uint32(/* id 120, wireType 2 =*/962).fork()).ldelim();
        if (message.respTermsGet != null && Object.hasOwnProperty.call(message, "respTermsGet"))
            $root.RespTermsGet.encode(message.respTermsGet, writer.uint32(/* id 121, wireType 2 =*/970).fork()).ldelim();
        if (message.cmdFirmwareVersionGet != null && Object.hasOwnProperty.call(message, "cmdFirmwareVersionGet"))
            $root.CmdFirmwareVersionGet.encode(message.cmdFirmwareVersionGet, writer.uint32(/* id 122, wireType 2 =*/978).fork()).ldelim();
        if (message.respFirmwareVersionGet != null && Object.hasOwnProperty.call(message, "respFirmwareVersionGet"))
            $root.RespFirmwareVersionGet.encode(message.respFirmwareVersionGet, writer.uint32(/* id 123, wireType 2 =*/986).fork()).ldelim();
        if (message.cmdWifiStaMacGet != null && Object.hasOwnProperty.call(message, "cmdWifiStaMacGet"))
            $root.CmdWifiStaMacGet.encode(message.cmdWifiStaMacGet, writer.uint32(/* id 124, wireType 2 =*/994).fork()).ldelim();
        if (message.respWifiStaMacGet != null && Object.hasOwnProperty.call(message, "respWifiStaMacGet"))
            $root.RespWifiStaMacGet.encode(message.respWifiStaMacGet, writer.uint32(/* id 125, wireType 2 =*/1002).fork()).ldelim();
        if (message.cmdWifiStaIpGet != null && Object.hasOwnProperty.call(message, "cmdWifiStaIpGet"))
            $root.CmdWifiStaIpGet.encode(message.cmdWifiStaIpGet, writer.uint32(/* id 126, wireType 2 =*/1010).fork()).ldelim();
        if (message.respWifiStaIpGet != null && Object.hasOwnProperty.call(message, "respWifiStaIpGet"))
            $root.RespWifiStaIpGet.encode(message.respWifiStaIpGet, writer.uint32(/* id 127, wireType 2 =*/1018).fork()).ldelim();
        if (message.cmdPing != null && Object.hasOwnProperty.call(message, "cmdPing"))
            $root.CmdPing.encode(message.cmdPing, writer.uint32(/* id 128, wireType 2 =*/1026).fork()).ldelim();
        if (message.respPing != null && Object.hasOwnProperty.call(message, "respPing"))
            $root.RespPing.encode(message.respPing, writer.uint32(/* id 129, wireType 2 =*/1034).fork()).ldelim();
        if (message.cmdEioConnect != null && Object.hasOwnProperty.call(message, "cmdEioConnect"))
            $root.CmdEIOConnect.encode(message.cmdEioConnect, writer.uint32(/* id 130, wireType 2 =*/1042).fork()).ldelim();
        if (message.respEioConnect != null && Object.hasOwnProperty.call(message, "respEioConnect"))
            $root.RespEIOConnect.encode(message.respEioConnect, writer.uint32(/* id 131, wireType 2 =*/1050).fork()).ldelim();
        if (message.cmdConfigWifiSsidGet != null && Object.hasOwnProperty.call(message, "cmdConfigWifiSsidGet"))
            $root.CmdConfigWifiSsidGet.encode(message.cmdConfigWifiSsidGet, writer.uint32(/* id 132, wireType 2 =*/1058).fork()).ldelim();
        if (message.respConfigWifiSsidGet != null && Object.hasOwnProperty.call(message, "respConfigWifiSsidGet"))
            $root.RespConfigWifiSsidGet.encode(message.respConfigWifiSsidGet, writer.uint32(/* id 133, wireType 2 =*/1066).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified HdyPayload message, length delimited. Does not implicitly {@link HdyPayload.verify|verify} messages.
     * @function encodeDelimited
     * @memberof HdyPayload
     * @static
     * @param {HdyPayload} message HdyPayload message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HdyPayload.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a HdyPayload message from the specified reader or buffer.
     * @function decode
     * @memberof HdyPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {HdyPayload} HdyPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HdyPayload.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.HdyPayload();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.msg = reader.int32();
                break;
            case 100:
                message.cmdProtocolVersionGet = $root.CmdProtocolVersionGet.decode(reader, reader.uint32());
                break;
            case 101:
                message.respProtocolVersionGet = $root.RespProtocolVersionGet.decode(reader, reader.uint32());
                break;
            case 102:
                message.cmdConnectionKeyGet = $root.CmdConnectionKeyGet.decode(reader, reader.uint32());
                break;
            case 103:
                message.respConnectionKeyGet = $root.RespConnectionKeyGet.decode(reader, reader.uint32());
                break;
            case 106:
                message.cmdConnectionKeyGen = $root.CmdConnectionKeyGen.decode(reader, reader.uint32());
                break;
            case 107:
                message.respConnectionKeyGen = $root.RespConnectionKeyGen.decode(reader, reader.uint32());
                break;
            case 108:
                message.cmdSerialNumberGet = $root.CmdSerialNumberGet.decode(reader, reader.uint32());
                break;
            case 109:
                message.respSerialNumberGet = $root.RespSerialNumberGet.decode(reader, reader.uint32());
                break;
            case 110:
                message.cmdProductionDateGet = $root.CmdProductionDateGet.decode(reader, reader.uint32());
                break;
            case 111:
                message.respProductionDateGet = $root.RespProductionDateGet.decode(reader, reader.uint32());
                break;
            case 112:
                message.cmdHardwareVersionGet = $root.CmdHardwareVersionGet.decode(reader, reader.uint32());
                break;
            case 113:
                message.respHardwareVersionGet = $root.RespHardwareVersionGet.decode(reader, reader.uint32());
                break;
            case 114:
                message.cmdPcbaVersionGet = $root.CmdPcbaVersionGet.decode(reader, reader.uint32());
                break;
            case 115:
                message.respPcbaVersionGet = $root.RespPcbaVersionGet.decode(reader, reader.uint32());
                break;
            case 116:
                message.cmdModelGet = $root.CmdModelGet.decode(reader, reader.uint32());
                break;
            case 117:
                message.respModelGet = $root.RespModelGet.decode(reader, reader.uint32());
                break;
            case 118:
                message.cmdTermsSet = $root.CmdTermsSet.decode(reader, reader.uint32());
                break;
            case 119:
                message.respTermsSet = $root.RespTermsSet.decode(reader, reader.uint32());
                break;
            case 120:
                message.cmdTermsGet = $root.CmdTermsGet.decode(reader, reader.uint32());
                break;
            case 121:
                message.respTermsGet = $root.RespTermsGet.decode(reader, reader.uint32());
                break;
            case 122:
                message.cmdFirmwareVersionGet = $root.CmdFirmwareVersionGet.decode(reader, reader.uint32());
                break;
            case 123:
                message.respFirmwareVersionGet = $root.RespFirmwareVersionGet.decode(reader, reader.uint32());
                break;
            case 124:
                message.cmdWifiStaMacGet = $root.CmdWifiStaMacGet.decode(reader, reader.uint32());
                break;
            case 125:
                message.respWifiStaMacGet = $root.RespWifiStaMacGet.decode(reader, reader.uint32());
                break;
            case 126:
                message.cmdWifiStaIpGet = $root.CmdWifiStaIpGet.decode(reader, reader.uint32());
                break;
            case 127:
                message.respWifiStaIpGet = $root.RespWifiStaIpGet.decode(reader, reader.uint32());
                break;
            case 128:
                message.cmdPing = $root.CmdPing.decode(reader, reader.uint32());
                break;
            case 129:
                message.respPing = $root.RespPing.decode(reader, reader.uint32());
                break;
            case 130:
                message.cmdEioConnect = $root.CmdEIOConnect.decode(reader, reader.uint32());
                break;
            case 131:
                message.respEioConnect = $root.RespEIOConnect.decode(reader, reader.uint32());
                break;
            case 132:
                message.cmdConfigWifiSsidGet = $root.CmdConfigWifiSsidGet.decode(reader, reader.uint32());
                break;
            case 133:
                message.respConfigWifiSsidGet = $root.RespConfigWifiSsidGet.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a HdyPayload message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof HdyPayload
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {HdyPayload} HdyPayload
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HdyPayload.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a HdyPayload message.
     * @function verify
     * @memberof HdyPayload
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    HdyPayload.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        let properties = {};
        if (message.msg != null && message.hasOwnProperty("msg"))
            switch (message.msg) {
            default:
                return "msg: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 26:
            case 27:
            case 28:
            case 29:
            case 30:
            case 31:
            case 32:
            case 33:
                break;
            }
        if (message.cmdProtocolVersionGet != null && message.hasOwnProperty("cmdProtocolVersionGet")) {
            properties.payload = 1;
            {
                let error = $root.CmdProtocolVersionGet.verify(message.cmdProtocolVersionGet);
                if (error)
                    return "cmdProtocolVersionGet." + error;
            }
        }
        if (message.respProtocolVersionGet != null && message.hasOwnProperty("respProtocolVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespProtocolVersionGet.verify(message.respProtocolVersionGet);
                if (error)
                    return "respProtocolVersionGet." + error;
            }
        }
        if (message.cmdConnectionKeyGet != null && message.hasOwnProperty("cmdConnectionKeyGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdConnectionKeyGet.verify(message.cmdConnectionKeyGet);
                if (error)
                    return "cmdConnectionKeyGet." + error;
            }
        }
        if (message.respConnectionKeyGet != null && message.hasOwnProperty("respConnectionKeyGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespConnectionKeyGet.verify(message.respConnectionKeyGet);
                if (error)
                    return "respConnectionKeyGet." + error;
            }
        }
        if (message.cmdConnectionKeyGen != null && message.hasOwnProperty("cmdConnectionKeyGen")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdConnectionKeyGen.verify(message.cmdConnectionKeyGen);
                if (error)
                    return "cmdConnectionKeyGen." + error;
            }
        }
        if (message.respConnectionKeyGen != null && message.hasOwnProperty("respConnectionKeyGen")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespConnectionKeyGen.verify(message.respConnectionKeyGen);
                if (error)
                    return "respConnectionKeyGen." + error;
            }
        }
        if (message.cmdSerialNumberGet != null && message.hasOwnProperty("cmdSerialNumberGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdSerialNumberGet.verify(message.cmdSerialNumberGet);
                if (error)
                    return "cmdSerialNumberGet." + error;
            }
        }
        if (message.respSerialNumberGet != null && message.hasOwnProperty("respSerialNumberGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespSerialNumberGet.verify(message.respSerialNumberGet);
                if (error)
                    return "respSerialNumberGet." + error;
            }
        }
        if (message.cmdProductionDateGet != null && message.hasOwnProperty("cmdProductionDateGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdProductionDateGet.verify(message.cmdProductionDateGet);
                if (error)
                    return "cmdProductionDateGet." + error;
            }
        }
        if (message.respProductionDateGet != null && message.hasOwnProperty("respProductionDateGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespProductionDateGet.verify(message.respProductionDateGet);
                if (error)
                    return "respProductionDateGet." + error;
            }
        }
        if (message.cmdHardwareVersionGet != null && message.hasOwnProperty("cmdHardwareVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdHardwareVersionGet.verify(message.cmdHardwareVersionGet);
                if (error)
                    return "cmdHardwareVersionGet." + error;
            }
        }
        if (message.respHardwareVersionGet != null && message.hasOwnProperty("respHardwareVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespHardwareVersionGet.verify(message.respHardwareVersionGet);
                if (error)
                    return "respHardwareVersionGet." + error;
            }
        }
        if (message.cmdPcbaVersionGet != null && message.hasOwnProperty("cmdPcbaVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdPcbaVersionGet.verify(message.cmdPcbaVersionGet);
                if (error)
                    return "cmdPcbaVersionGet." + error;
            }
        }
        if (message.respPcbaVersionGet != null && message.hasOwnProperty("respPcbaVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespPcbaVersionGet.verify(message.respPcbaVersionGet);
                if (error)
                    return "respPcbaVersionGet." + error;
            }
        }
        if (message.cmdModelGet != null && message.hasOwnProperty("cmdModelGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdModelGet.verify(message.cmdModelGet);
                if (error)
                    return "cmdModelGet." + error;
            }
        }
        if (message.respModelGet != null && message.hasOwnProperty("respModelGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespModelGet.verify(message.respModelGet);
                if (error)
                    return "respModelGet." + error;
            }
        }
        if (message.cmdTermsSet != null && message.hasOwnProperty("cmdTermsSet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdTermsSet.verify(message.cmdTermsSet);
                if (error)
                    return "cmdTermsSet." + error;
            }
        }
        if (message.respTermsSet != null && message.hasOwnProperty("respTermsSet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespTermsSet.verify(message.respTermsSet);
                if (error)
                    return "respTermsSet." + error;
            }
        }
        if (message.cmdTermsGet != null && message.hasOwnProperty("cmdTermsGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdTermsGet.verify(message.cmdTermsGet);
                if (error)
                    return "cmdTermsGet." + error;
            }
        }
        if (message.respTermsGet != null && message.hasOwnProperty("respTermsGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespTermsGet.verify(message.respTermsGet);
                if (error)
                    return "respTermsGet." + error;
            }
        }
        if (message.cmdFirmwareVersionGet != null && message.hasOwnProperty("cmdFirmwareVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdFirmwareVersionGet.verify(message.cmdFirmwareVersionGet);
                if (error)
                    return "cmdFirmwareVersionGet." + error;
            }
        }
        if (message.respFirmwareVersionGet != null && message.hasOwnProperty("respFirmwareVersionGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespFirmwareVersionGet.verify(message.respFirmwareVersionGet);
                if (error)
                    return "respFirmwareVersionGet." + error;
            }
        }
        if (message.cmdWifiStaMacGet != null && message.hasOwnProperty("cmdWifiStaMacGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdWifiStaMacGet.verify(message.cmdWifiStaMacGet);
                if (error)
                    return "cmdWifiStaMacGet." + error;
            }
        }
        if (message.respWifiStaMacGet != null && message.hasOwnProperty("respWifiStaMacGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespWifiStaMacGet.verify(message.respWifiStaMacGet);
                if (error)
                    return "respWifiStaMacGet." + error;
            }
        }
        if (message.cmdWifiStaIpGet != null && message.hasOwnProperty("cmdWifiStaIpGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdWifiStaIpGet.verify(message.cmdWifiStaIpGet);
                if (error)
                    return "cmdWifiStaIpGet." + error;
            }
        }
        if (message.respWifiStaIpGet != null && message.hasOwnProperty("respWifiStaIpGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespWifiStaIpGet.verify(message.respWifiStaIpGet);
                if (error)
                    return "respWifiStaIpGet." + error;
            }
        }
        if (message.cmdPing != null && message.hasOwnProperty("cmdPing")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdPing.verify(message.cmdPing);
                if (error)
                    return "cmdPing." + error;
            }
        }
        if (message.respPing != null && message.hasOwnProperty("respPing")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespPing.verify(message.respPing);
                if (error)
                    return "respPing." + error;
            }
        }
        if (message.cmdEioConnect != null && message.hasOwnProperty("cmdEioConnect")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdEIOConnect.verify(message.cmdEioConnect);
                if (error)
                    return "cmdEioConnect." + error;
            }
        }
        if (message.respEioConnect != null && message.hasOwnProperty("respEioConnect")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespEIOConnect.verify(message.respEioConnect);
                if (error)
                    return "respEioConnect." + error;
            }
        }
        if (message.cmdConfigWifiSsidGet != null && message.hasOwnProperty("cmdConfigWifiSsidGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.CmdConfigWifiSsidGet.verify(message.cmdConfigWifiSsidGet);
                if (error)
                    return "cmdConfigWifiSsidGet." + error;
            }
        }
        if (message.respConfigWifiSsidGet != null && message.hasOwnProperty("respConfigWifiSsidGet")) {
            if (properties.payload === 1)
                return "payload: multiple values";
            properties.payload = 1;
            {
                let error = $root.RespConfigWifiSsidGet.verify(message.respConfigWifiSsidGet);
                if (error)
                    return "respConfigWifiSsidGet." + error;
            }
        }
        return null;
    };

    /**
     * Creates a HdyPayload message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof HdyPayload
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {HdyPayload} HdyPayload
     */
    HdyPayload.fromObject = function fromObject(object) {
        if (object instanceof $root.HdyPayload)
            return object;
        let message = new $root.HdyPayload();
        switch (object.msg) {
        case "TypeCmdProtocolVersionGet":
        case 0:
            message.msg = 0;
            break;
        case "TypeRespProtocolVersionGet":
        case 1:
            message.msg = 1;
            break;
        case "TypeCmdConnectionKeyGet":
        case 2:
            message.msg = 2;
            break;
        case "TypeRespConnectionKeyGet":
        case 3:
            message.msg = 3;
            break;
        case "TypeCmdConnectionKeyGen":
        case 6:
            message.msg = 6;
            break;
        case "TypeRespConnectionKeyGen":
        case 7:
            message.msg = 7;
            break;
        case "TypeCmdSerialNumberGet":
        case 8:
            message.msg = 8;
            break;
        case "TypeRespSerialNumberGet":
        case 9:
            message.msg = 9;
            break;
        case "TypeCmdProductionDateGet":
        case 10:
            message.msg = 10;
            break;
        case "TypeRespProductionDateGet":
        case 11:
            message.msg = 11;
            break;
        case "TypeCmdHardwareVersionGet":
        case 12:
            message.msg = 12;
            break;
        case "TypeRespHardwareVersionGet":
        case 13:
            message.msg = 13;
            break;
        case "TypeCmdPcbaVersionGet":
        case 14:
            message.msg = 14;
            break;
        case "TypeRespPcbaVersionGet":
        case 15:
            message.msg = 15;
            break;
        case "TypeCmdModelGet":
        case 16:
            message.msg = 16;
            break;
        case "TypeRespModelGet":
        case 17:
            message.msg = 17;
            break;
        case "TypeCmdTermsSet":
        case 18:
            message.msg = 18;
            break;
        case "TypeRespTermsSet":
        case 19:
            message.msg = 19;
            break;
        case "TypeCmdTermsGet":
        case 20:
            message.msg = 20;
            break;
        case "TypeRespTermsGet":
        case 21:
            message.msg = 21;
            break;
        case "TypeCmdFirmwareVersionGet":
        case 22:
            message.msg = 22;
            break;
        case "TypeRespFirmwareVersionGet":
        case 23:
            message.msg = 23;
            break;
        case "TypeCmdWifiStaMacGet":
        case 24:
            message.msg = 24;
            break;
        case "TypeRespWifiStaMacGet":
        case 25:
            message.msg = 25;
            break;
        case "TypeCmdWifiStaIpGet":
        case 26:
            message.msg = 26;
            break;
        case "TypeRespWifiStaIpGet":
        case 27:
            message.msg = 27;
            break;
        case "TypeCmdPing":
        case 28:
            message.msg = 28;
            break;
        case "TypeRespPing":
        case 29:
            message.msg = 29;
            break;
        case "TypeCmdEIOConnect":
        case 30:
            message.msg = 30;
            break;
        case "TypeRespEIOConnect":
        case 31:
            message.msg = 31;
            break;
        case "TypeCmdConfigWifiSsidGet":
        case 32:
            message.msg = 32;
            break;
        case "TypeRespConfigWifiSsidGet":
        case 33:
            message.msg = 33;
            break;
        }
        if (object.cmdProtocolVersionGet != null) {
            if (typeof object.cmdProtocolVersionGet !== "object")
                throw TypeError(".HdyPayload.cmdProtocolVersionGet: object expected");
            message.cmdProtocolVersionGet = $root.CmdProtocolVersionGet.fromObject(object.cmdProtocolVersionGet);
        }
        if (object.respProtocolVersionGet != null) {
            if (typeof object.respProtocolVersionGet !== "object")
                throw TypeError(".HdyPayload.respProtocolVersionGet: object expected");
            message.respProtocolVersionGet = $root.RespProtocolVersionGet.fromObject(object.respProtocolVersionGet);
        }
        if (object.cmdConnectionKeyGet != null) {
            if (typeof object.cmdConnectionKeyGet !== "object")
                throw TypeError(".HdyPayload.cmdConnectionKeyGet: object expected");
            message.cmdConnectionKeyGet = $root.CmdConnectionKeyGet.fromObject(object.cmdConnectionKeyGet);
        }
        if (object.respConnectionKeyGet != null) {
            if (typeof object.respConnectionKeyGet !== "object")
                throw TypeError(".HdyPayload.respConnectionKeyGet: object expected");
            message.respConnectionKeyGet = $root.RespConnectionKeyGet.fromObject(object.respConnectionKeyGet);
        }
        if (object.cmdConnectionKeyGen != null) {
            if (typeof object.cmdConnectionKeyGen !== "object")
                throw TypeError(".HdyPayload.cmdConnectionKeyGen: object expected");
            message.cmdConnectionKeyGen = $root.CmdConnectionKeyGen.fromObject(object.cmdConnectionKeyGen);
        }
        if (object.respConnectionKeyGen != null) {
            if (typeof object.respConnectionKeyGen !== "object")
                throw TypeError(".HdyPayload.respConnectionKeyGen: object expected");
            message.respConnectionKeyGen = $root.RespConnectionKeyGen.fromObject(object.respConnectionKeyGen);
        }
        if (object.cmdSerialNumberGet != null) {
            if (typeof object.cmdSerialNumberGet !== "object")
                throw TypeError(".HdyPayload.cmdSerialNumberGet: object expected");
            message.cmdSerialNumberGet = $root.CmdSerialNumberGet.fromObject(object.cmdSerialNumberGet);
        }
        if (object.respSerialNumberGet != null) {
            if (typeof object.respSerialNumberGet !== "object")
                throw TypeError(".HdyPayload.respSerialNumberGet: object expected");
            message.respSerialNumberGet = $root.RespSerialNumberGet.fromObject(object.respSerialNumberGet);
        }
        if (object.cmdProductionDateGet != null) {
            if (typeof object.cmdProductionDateGet !== "object")
                throw TypeError(".HdyPayload.cmdProductionDateGet: object expected");
            message.cmdProductionDateGet = $root.CmdProductionDateGet.fromObject(object.cmdProductionDateGet);
        }
        if (object.respProductionDateGet != null) {
            if (typeof object.respProductionDateGet !== "object")
                throw TypeError(".HdyPayload.respProductionDateGet: object expected");
            message.respProductionDateGet = $root.RespProductionDateGet.fromObject(object.respProductionDateGet);
        }
        if (object.cmdHardwareVersionGet != null) {
            if (typeof object.cmdHardwareVersionGet !== "object")
                throw TypeError(".HdyPayload.cmdHardwareVersionGet: object expected");
            message.cmdHardwareVersionGet = $root.CmdHardwareVersionGet.fromObject(object.cmdHardwareVersionGet);
        }
        if (object.respHardwareVersionGet != null) {
            if (typeof object.respHardwareVersionGet !== "object")
                throw TypeError(".HdyPayload.respHardwareVersionGet: object expected");
            message.respHardwareVersionGet = $root.RespHardwareVersionGet.fromObject(object.respHardwareVersionGet);
        }
        if (object.cmdPcbaVersionGet != null) {
            if (typeof object.cmdPcbaVersionGet !== "object")
                throw TypeError(".HdyPayload.cmdPcbaVersionGet: object expected");
            message.cmdPcbaVersionGet = $root.CmdPcbaVersionGet.fromObject(object.cmdPcbaVersionGet);
        }
        if (object.respPcbaVersionGet != null) {
            if (typeof object.respPcbaVersionGet !== "object")
                throw TypeError(".HdyPayload.respPcbaVersionGet: object expected");
            message.respPcbaVersionGet = $root.RespPcbaVersionGet.fromObject(object.respPcbaVersionGet);
        }
        if (object.cmdModelGet != null) {
            if (typeof object.cmdModelGet !== "object")
                throw TypeError(".HdyPayload.cmdModelGet: object expected");
            message.cmdModelGet = $root.CmdModelGet.fromObject(object.cmdModelGet);
        }
        if (object.respModelGet != null) {
            if (typeof object.respModelGet !== "object")
                throw TypeError(".HdyPayload.respModelGet: object expected");
            message.respModelGet = $root.RespModelGet.fromObject(object.respModelGet);
        }
        if (object.cmdTermsSet != null) {
            if (typeof object.cmdTermsSet !== "object")
                throw TypeError(".HdyPayload.cmdTermsSet: object expected");
            message.cmdTermsSet = $root.CmdTermsSet.fromObject(object.cmdTermsSet);
        }
        if (object.respTermsSet != null) {
            if (typeof object.respTermsSet !== "object")
                throw TypeError(".HdyPayload.respTermsSet: object expected");
            message.respTermsSet = $root.RespTermsSet.fromObject(object.respTermsSet);
        }
        if (object.cmdTermsGet != null) {
            if (typeof object.cmdTermsGet !== "object")
                throw TypeError(".HdyPayload.cmdTermsGet: object expected");
            message.cmdTermsGet = $root.CmdTermsGet.fromObject(object.cmdTermsGet);
        }
        if (object.respTermsGet != null) {
            if (typeof object.respTermsGet !== "object")
                throw TypeError(".HdyPayload.respTermsGet: object expected");
            message.respTermsGet = $root.RespTermsGet.fromObject(object.respTermsGet);
        }
        if (object.cmdFirmwareVersionGet != null) {
            if (typeof object.cmdFirmwareVersionGet !== "object")
                throw TypeError(".HdyPayload.cmdFirmwareVersionGet: object expected");
            message.cmdFirmwareVersionGet = $root.CmdFirmwareVersionGet.fromObject(object.cmdFirmwareVersionGet);
        }
        if (object.respFirmwareVersionGet != null) {
            if (typeof object.respFirmwareVersionGet !== "object")
                throw TypeError(".HdyPayload.respFirmwareVersionGet: object expected");
            message.respFirmwareVersionGet = $root.RespFirmwareVersionGet.fromObject(object.respFirmwareVersionGet);
        }
        if (object.cmdWifiStaMacGet != null) {
            if (typeof object.cmdWifiStaMacGet !== "object")
                throw TypeError(".HdyPayload.cmdWifiStaMacGet: object expected");
            message.cmdWifiStaMacGet = $root.CmdWifiStaMacGet.fromObject(object.cmdWifiStaMacGet);
        }
        if (object.respWifiStaMacGet != null) {
            if (typeof object.respWifiStaMacGet !== "object")
                throw TypeError(".HdyPayload.respWifiStaMacGet: object expected");
            message.respWifiStaMacGet = $root.RespWifiStaMacGet.fromObject(object.respWifiStaMacGet);
        }
        if (object.cmdWifiStaIpGet != null) {
            if (typeof object.cmdWifiStaIpGet !== "object")
                throw TypeError(".HdyPayload.cmdWifiStaIpGet: object expected");
            message.cmdWifiStaIpGet = $root.CmdWifiStaIpGet.fromObject(object.cmdWifiStaIpGet);
        }
        if (object.respWifiStaIpGet != null) {
            if (typeof object.respWifiStaIpGet !== "object")
                throw TypeError(".HdyPayload.respWifiStaIpGet: object expected");
            message.respWifiStaIpGet = $root.RespWifiStaIpGet.fromObject(object.respWifiStaIpGet);
        }
        if (object.cmdPing != null) {
            if (typeof object.cmdPing !== "object")
                throw TypeError(".HdyPayload.cmdPing: object expected");
            message.cmdPing = $root.CmdPing.fromObject(object.cmdPing);
        }
        if (object.respPing != null) {
            if (typeof object.respPing !== "object")
                throw TypeError(".HdyPayload.respPing: object expected");
            message.respPing = $root.RespPing.fromObject(object.respPing);
        }
        if (object.cmdEioConnect != null) {
            if (typeof object.cmdEioConnect !== "object")
                throw TypeError(".HdyPayload.cmdEioConnect: object expected");
            message.cmdEioConnect = $root.CmdEIOConnect.fromObject(object.cmdEioConnect);
        }
        if (object.respEioConnect != null) {
            if (typeof object.respEioConnect !== "object")
                throw TypeError(".HdyPayload.respEioConnect: object expected");
            message.respEioConnect = $root.RespEIOConnect.fromObject(object.respEioConnect);
        }
        if (object.cmdConfigWifiSsidGet != null) {
            if (typeof object.cmdConfigWifiSsidGet !== "object")
                throw TypeError(".HdyPayload.cmdConfigWifiSsidGet: object expected");
            message.cmdConfigWifiSsidGet = $root.CmdConfigWifiSsidGet.fromObject(object.cmdConfigWifiSsidGet);
        }
        if (object.respConfigWifiSsidGet != null) {
            if (typeof object.respConfigWifiSsidGet !== "object")
                throw TypeError(".HdyPayload.respConfigWifiSsidGet: object expected");
            message.respConfigWifiSsidGet = $root.RespConfigWifiSsidGet.fromObject(object.respConfigWifiSsidGet);
        }
        return message;
    };

    /**
     * Creates a plain object from a HdyPayload message. Also converts values to other types if specified.
     * @function toObject
     * @memberof HdyPayload
     * @static
     * @param {HdyPayload} message HdyPayload
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    HdyPayload.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.msg = options.enums === String ? "TypeCmdProtocolVersionGet" : 0;
        if (message.msg != null && message.hasOwnProperty("msg"))
            object.msg = options.enums === String ? $root.HdyMsgType[message.msg] : message.msg;
        if (message.cmdProtocolVersionGet != null && message.hasOwnProperty("cmdProtocolVersionGet")) {
            object.cmdProtocolVersionGet = $root.CmdProtocolVersionGet.toObject(message.cmdProtocolVersionGet, options);
            if (options.oneofs)
                object.payload = "cmdProtocolVersionGet";
        }
        if (message.respProtocolVersionGet != null && message.hasOwnProperty("respProtocolVersionGet")) {
            object.respProtocolVersionGet = $root.RespProtocolVersionGet.toObject(message.respProtocolVersionGet, options);
            if (options.oneofs)
                object.payload = "respProtocolVersionGet";
        }
        if (message.cmdConnectionKeyGet != null && message.hasOwnProperty("cmdConnectionKeyGet")) {
            object.cmdConnectionKeyGet = $root.CmdConnectionKeyGet.toObject(message.cmdConnectionKeyGet, options);
            if (options.oneofs)
                object.payload = "cmdConnectionKeyGet";
        }
        if (message.respConnectionKeyGet != null && message.hasOwnProperty("respConnectionKeyGet")) {
            object.respConnectionKeyGet = $root.RespConnectionKeyGet.toObject(message.respConnectionKeyGet, options);
            if (options.oneofs)
                object.payload = "respConnectionKeyGet";
        }
        if (message.cmdConnectionKeyGen != null && message.hasOwnProperty("cmdConnectionKeyGen")) {
            object.cmdConnectionKeyGen = $root.CmdConnectionKeyGen.toObject(message.cmdConnectionKeyGen, options);
            if (options.oneofs)
                object.payload = "cmdConnectionKeyGen";
        }
        if (message.respConnectionKeyGen != null && message.hasOwnProperty("respConnectionKeyGen")) {
            object.respConnectionKeyGen = $root.RespConnectionKeyGen.toObject(message.respConnectionKeyGen, options);
            if (options.oneofs)
                object.payload = "respConnectionKeyGen";
        }
        if (message.cmdSerialNumberGet != null && message.hasOwnProperty("cmdSerialNumberGet")) {
            object.cmdSerialNumberGet = $root.CmdSerialNumberGet.toObject(message.cmdSerialNumberGet, options);
            if (options.oneofs)
                object.payload = "cmdSerialNumberGet";
        }
        if (message.respSerialNumberGet != null && message.hasOwnProperty("respSerialNumberGet")) {
            object.respSerialNumberGet = $root.RespSerialNumberGet.toObject(message.respSerialNumberGet, options);
            if (options.oneofs)
                object.payload = "respSerialNumberGet";
        }
        if (message.cmdProductionDateGet != null && message.hasOwnProperty("cmdProductionDateGet")) {
            object.cmdProductionDateGet = $root.CmdProductionDateGet.toObject(message.cmdProductionDateGet, options);
            if (options.oneofs)
                object.payload = "cmdProductionDateGet";
        }
        if (message.respProductionDateGet != null && message.hasOwnProperty("respProductionDateGet")) {
            object.respProductionDateGet = $root.RespProductionDateGet.toObject(message.respProductionDateGet, options);
            if (options.oneofs)
                object.payload = "respProductionDateGet";
        }
        if (message.cmdHardwareVersionGet != null && message.hasOwnProperty("cmdHardwareVersionGet")) {
            object.cmdHardwareVersionGet = $root.CmdHardwareVersionGet.toObject(message.cmdHardwareVersionGet, options);
            if (options.oneofs)
                object.payload = "cmdHardwareVersionGet";
        }
        if (message.respHardwareVersionGet != null && message.hasOwnProperty("respHardwareVersionGet")) {
            object.respHardwareVersionGet = $root.RespHardwareVersionGet.toObject(message.respHardwareVersionGet, options);
            if (options.oneofs)
                object.payload = "respHardwareVersionGet";
        }
        if (message.cmdPcbaVersionGet != null && message.hasOwnProperty("cmdPcbaVersionGet")) {
            object.cmdPcbaVersionGet = $root.CmdPcbaVersionGet.toObject(message.cmdPcbaVersionGet, options);
            if (options.oneofs)
                object.payload = "cmdPcbaVersionGet";
        }
        if (message.respPcbaVersionGet != null && message.hasOwnProperty("respPcbaVersionGet")) {
            object.respPcbaVersionGet = $root.RespPcbaVersionGet.toObject(message.respPcbaVersionGet, options);
            if (options.oneofs)
                object.payload = "respPcbaVersionGet";
        }
        if (message.cmdModelGet != null && message.hasOwnProperty("cmdModelGet")) {
            object.cmdModelGet = $root.CmdModelGet.toObject(message.cmdModelGet, options);
            if (options.oneofs)
                object.payload = "cmdModelGet";
        }
        if (message.respModelGet != null && message.hasOwnProperty("respModelGet")) {
            object.respModelGet = $root.RespModelGet.toObject(message.respModelGet, options);
            if (options.oneofs)
                object.payload = "respModelGet";
        }
        if (message.cmdTermsSet != null && message.hasOwnProperty("cmdTermsSet")) {
            object.cmdTermsSet = $root.CmdTermsSet.toObject(message.cmdTermsSet, options);
            if (options.oneofs)
                object.payload = "cmdTermsSet";
        }
        if (message.respTermsSet != null && message.hasOwnProperty("respTermsSet")) {
            object.respTermsSet = $root.RespTermsSet.toObject(message.respTermsSet, options);
            if (options.oneofs)
                object.payload = "respTermsSet";
        }
        if (message.cmdTermsGet != null && message.hasOwnProperty("cmdTermsGet")) {
            object.cmdTermsGet = $root.CmdTermsGet.toObject(message.cmdTermsGet, options);
            if (options.oneofs)
                object.payload = "cmdTermsGet";
        }
        if (message.respTermsGet != null && message.hasOwnProperty("respTermsGet")) {
            object.respTermsGet = $root.RespTermsGet.toObject(message.respTermsGet, options);
            if (options.oneofs)
                object.payload = "respTermsGet";
        }
        if (message.cmdFirmwareVersionGet != null && message.hasOwnProperty("cmdFirmwareVersionGet")) {
            object.cmdFirmwareVersionGet = $root.CmdFirmwareVersionGet.toObject(message.cmdFirmwareVersionGet, options);
            if (options.oneofs)
                object.payload = "cmdFirmwareVersionGet";
        }
        if (message.respFirmwareVersionGet != null && message.hasOwnProperty("respFirmwareVersionGet")) {
            object.respFirmwareVersionGet = $root.RespFirmwareVersionGet.toObject(message.respFirmwareVersionGet, options);
            if (options.oneofs)
                object.payload = "respFirmwareVersionGet";
        }
        if (message.cmdWifiStaMacGet != null && message.hasOwnProperty("cmdWifiStaMacGet")) {
            object.cmdWifiStaMacGet = $root.CmdWifiStaMacGet.toObject(message.cmdWifiStaMacGet, options);
            if (options.oneofs)
                object.payload = "cmdWifiStaMacGet";
        }
        if (message.respWifiStaMacGet != null && message.hasOwnProperty("respWifiStaMacGet")) {
            object.respWifiStaMacGet = $root.RespWifiStaMacGet.toObject(message.respWifiStaMacGet, options);
            if (options.oneofs)
                object.payload = "respWifiStaMacGet";
        }
        if (message.cmdWifiStaIpGet != null && message.hasOwnProperty("cmdWifiStaIpGet")) {
            object.cmdWifiStaIpGet = $root.CmdWifiStaIpGet.toObject(message.cmdWifiStaIpGet, options);
            if (options.oneofs)
                object.payload = "cmdWifiStaIpGet";
        }
        if (message.respWifiStaIpGet != null && message.hasOwnProperty("respWifiStaIpGet")) {
            object.respWifiStaIpGet = $root.RespWifiStaIpGet.toObject(message.respWifiStaIpGet, options);
            if (options.oneofs)
                object.payload = "respWifiStaIpGet";
        }
        if (message.cmdPing != null && message.hasOwnProperty("cmdPing")) {
            object.cmdPing = $root.CmdPing.toObject(message.cmdPing, options);
            if (options.oneofs)
                object.payload = "cmdPing";
        }
        if (message.respPing != null && message.hasOwnProperty("respPing")) {
            object.respPing = $root.RespPing.toObject(message.respPing, options);
            if (options.oneofs)
                object.payload = "respPing";
        }
        if (message.cmdEioConnect != null && message.hasOwnProperty("cmdEioConnect")) {
            object.cmdEioConnect = $root.CmdEIOConnect.toObject(message.cmdEioConnect, options);
            if (options.oneofs)
                object.payload = "cmdEioConnect";
        }
        if (message.respEioConnect != null && message.hasOwnProperty("respEioConnect")) {
            object.respEioConnect = $root.RespEIOConnect.toObject(message.respEioConnect, options);
            if (options.oneofs)
                object.payload = "respEioConnect";
        }
        if (message.cmdConfigWifiSsidGet != null && message.hasOwnProperty("cmdConfigWifiSsidGet")) {
            object.cmdConfigWifiSsidGet = $root.CmdConfigWifiSsidGet.toObject(message.cmdConfigWifiSsidGet, options);
            if (options.oneofs)
                object.payload = "cmdConfigWifiSsidGet";
        }
        if (message.respConfigWifiSsidGet != null && message.hasOwnProperty("respConfigWifiSsidGet")) {
            object.respConfigWifiSsidGet = $root.RespConfigWifiSsidGet.toObject(message.respConfigWifiSsidGet, options);
            if (options.oneofs)
                object.payload = "respConfigWifiSsidGet";
        }
        return object;
    };

    /**
     * Converts this HdyPayload to JSON.
     * @function toJSON
     * @memberof HdyPayload
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    HdyPayload.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return HdyPayload;
})();

export { $root as default };
