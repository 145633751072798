import React from 'react';

import { translations } from 'i18n';
import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) {
    return '0 Bytes/sec';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i] + '/sec';
};

export const Bandwidth = () => {
  const { bytesPerSecond, fasterImageCompression, lowImageSize } = useChromeExtensionPopup();
  return (
    <>
      <span className={styles.settingsLabelDescription}>
        {translations('ExtensionPopup.CurrentBandwidth')}: {formatBytes(bytesPerSecond)}
      </span>
      {fasterImageCompression && (
        <div className={styles.bandwidthWarning}>
          <span className={styles.bandwidthWarningIcon}>⚠</span>{' '}
          {translations('ExtensionPopup.BandwidthWardningFasterImageCompression')}
        </div>
      )}
      {lowImageSize && (
        <div className={styles.bandwidthWarning}>
          <span className={styles.bandwidthWarningIcon}>⚠</span>{' '}
          {translations('ExtensionPopup.BandwidthWardningLowImageSize')}
        </div>
      )}
    </>
  );
};
