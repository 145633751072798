import React, { createContext, useEffect, useState, useRef } from 'react';
import { createHook } from 'utils/utils';

import { useMLPerformance } from './MLPerformanceHook';
import { useSubscription } from './SubscriptionHook';
import { usePrediction } from './PredictionHook';
import { useDeviceVisualization } from './DeviceVisualizationHook';
import { DeviceStateMachine } from '../lib/DeviceStateMachine';
import { useConnectedDevices, LOCAL_STORAGE_KEY, Device } from './ConnectedDevicesHook';

export interface DevicesOperationContextInterface {
  isPaused: boolean;
  setPaused: (isPaused: boolean) => void;
  intensity: number;
  setIntensity: (intensity: number) => void;
  ambientMovement: number;
  setAmbientMovement: (ambientMovement: number) => void;
  suctionMode: number;
  setSuctionMode: (suctionMode: number) => void;
}

const DevicesOperationContext = createContext<DevicesOperationContextInterface | null>(null);
export const useDevicesOperation = () => createHook('useDevicesOperation', DevicesOperationContext);

const deviceStateMachine = new DeviceStateMachine();

const LOCAL_STORAGE_SETTINGS = 'devices_settings';
const getLocalStorageSettings = (locaStorageSettings: string = LOCAL_STORAGE_SETTINGS) => {
  try {
    return JSON.parse(localStorage.getItem(locaStorageSettings)) || {};
  } catch (e) {
    return {};
  }
};

type Props = {
  children: React.ReactNode;
};

export const DevicesOperationContextProvider = (props: Props) => {
  const { tokenObj } = useSubscription();

  // Video was paused
  const { paused } = useMLPerformance();
  const { onDeviceMove, onMlSignal, onDeviceCommandSentToDevices } = useDeviceVisualization();
  const intervalWorker = useRef(null);

  const oldPercent = useRef(0);

  // Device was paused
  const [isPaused, setPaused] = useState<boolean>(false);
  const settings = getLocalStorageSettings();

  // Maximum vibration intensity/movement speed, 0..100
  const [intensity, setIntensity] = useState<number>(settings.intensity || 100);

  // Ambient movement/vibration, 0..100
  const [ambientMovement, setAmbientMovement] = useState<number>(settings.ambientMovement || 50);
  const [suctionMode, setSuctionMode] = useState<number>(settings.suctionMode || 0);

  const { getConnectedDevices, getConnectionState, disconnect, devices } = useConnectedDevices();
  const connectedDevices = getConnectedDevices();

  const { isCompatible, connection } = usePrediction();

  useEffect(() => {
    const worker = new Worker('/worker.js');
    // Start the worker
    worker.postMessage({});
    intervalWorker.current = worker;
  }, []);

  useEffect(() => {
    /**
     * Moves all connected devices to a specified position or vibrates them with a given intensity.
     * @param positionPercent The target position or intensity, ranging from 0 to 100.
     * @param speed The speed at which the device should move or vibrate.
     * @param isBlowJob A boolean flag indicating a specific mode of operation.
     */
    const moveDevices = async (positionPercent: number, speed: number, isBlowJob: boolean) => {
      if (oldPercent.current === positionPercent) {
        return;
      }
      oldPercent.current = positionPercent;
      const devicesToMove = connectedDevices;
      devicesToMove.forEach((dev: Device) => {
        dev.wrapper?.write(positionPercent, speed, isBlowJob);
      });
    };

    /**
     * Triggers device actions approximately 30 times per second to ensure smooth ambient movement.
     * This is necessary because setInterval's reliability decreases when a page is in the background.
     */
    const devicesOnTimer = async () => {
      for (const dev of connectedDevices) {
        if (!dev.wrapper?.onTimer) {
          continue;
        }
        await dev.wrapper.onTimer();
      }
    };

    /**
     * Handles messages from a worker, updating device states and invoking movement functions.
     */
    const onWorkerMessage = () => {
      if (isPaused || paused) {
        // Skip device movement updates if the devices or video playback is paused.
        return;
      }

      devicesOnTimer();
      deviceStateMachine.onFrame();
      const { updatedWithFuturePrediction, destination, isBlowJob, speed } =
        deviceStateMachine.getDeviceState();
      onDeviceCommandSentToDevices(destination, updatedWithFuturePrediction);
      moveDevices(destination * 100, speed, isBlowJob);
    };

    intervalWorker.current.addEventListener('message', onWorkerMessage);
    return () => {
      intervalWorker.current.removeEventListener('message', onWorkerMessage);
    };
  }, [connectedDevices, isPaused, paused]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    const unsubscribeFromPredictions = connection.subscribeServerPredictions(
      ({ engine_position: percent, period, timestamp, future, is_blowjob: isBlowJob }) => {
        if (isPaused) {
          // Do not move devices if we are on pause
          return;
        }
        onMlSignal({
          timestampSent: timestamp,
          timestampReceived: Date.now(),
          value: percent,
          period,
          future: future || [],
          isBlowJob,
        });
        deviceStateMachine.onMlCommand(percent, future, timestamp, isBlowJob);
      },
    );
    return () => {
      unsubscribeFromPredictions();
    };
  }, [devices, isPaused, connection]);

  // Stop the device's vibration when video or device is paused
  useEffect(() => {
    if (!isPaused && !paused) {
      return;
    }
    // Pause all devices
    const devicesToPause = connectedDevices;
    devicesToPause.forEach(async (dev: Device) => {
      await dev.wrapper?.writePaused();
    });
  }, [devices, isPaused, paused]);

  // Save settings to the local storage on each update
  useEffect(() => {
    const devicesToSave = devices.map((device: Device) => {
      const result = { ...device };
      result.wrapper = null;
      result.connectionState = getConnectionState(device);
      delete result.id;
      return result;
    });
    const devicesStr = JSON.stringify(devicesToSave);
    localStorage.setItem(LOCAL_STORAGE_KEY, devicesStr);
  }, [devices]);

  useEffect(() => {
    if (!tokenObj) {
      connectedDevices.forEach((device: Device, index: number) => {
        disconnect(index);
      });
    }
  }, [tokenObj]);

  useEffect(() => {
    // Save settings to local storage on every change
    localStorage.setItem(
      LOCAL_STORAGE_SETTINGS,
      JSON.stringify({ intensity, ambientMovement, suctionMode }),
    );
  }, [intensity, ambientMovement, suctionMode]);

  // Update devices paused and ambient movement states
  useEffect(() => {
    connectedDevices.forEach((d: Device) => {
      d.wrapper?.setAmbientMovement(isPaused || !isCompatible || paused ? 0 : ambientMovement);
    });
  }, [connectedDevices.length, isPaused, paused, +ambientMovement]);

  // Update device's suction mode on pause/unpause
  useEffect(() => {
    connectedDevices.forEach((d: Device) => {
      // @ts-expect-error: TS2339
      d.wrapper?.setSuctionMode &&
        // @ts-expect-error: TS2339
        d.wrapper.setSuctionMode(isPaused || !isCompatible || paused ? 0 : suctionMode);
    });
  }, [connectedDevices.length, isPaused, paused, +suctionMode]);

  // Update devices max intensity
  useEffect(() => {
    connectedDevices.forEach((d: Device) => d.wrapper?.setMaxIntensity(intensity));
  }, [connectedDevices.length, intensity]);

  useEffect(() => {
    devices.forEach((device: Device) => {
      const { wrapper } = device;
      if (wrapper?.setDeviceMovementCallback) {
        wrapper.setDeviceMovementCallback(onDeviceMove);
      }
    });
  }, [devices]);

  return (
    <DevicesOperationContext.Provider
      value={{
        ambientMovement,
        intensity,
        isPaused,
        suctionMode,
        setAmbientMovement,
        setIntensity,
        setPaused,
        setSuctionMode,
      }}
    >
      {props.children}
    </DevicesOperationContext.Provider>
  );
};
