import React, { useState, useEffect, useRef, createContext } from 'react';
import { createHook } from '../utils/utils';
import { toast } from 'react-toastify';
import DeviceStateNotification from 'components/DeviceStateNotification';

import {
  DEFAULT_POWERBLOW_SETTINGS,
  POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY,
  POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY,
  SETTINGS_OPTIONS,
} from 'data/constants';
import { useConnectedDevices } from 'hooks/ConnectedDevicesHook';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import { names as powerblowNames } from 'lib/devices/wrappers/PowerBlow';
import useLocalStorage from 'use-local-storage';

// NOTE: in JS setTimeout is not precise, so this extra delay is used to garantee
// that recursive testSettings function won't be invoked earlier than testDevice function will be executed
const EXTRA_DELAY = 100; // ms

const getSettingsFromLocalStorage = () => {
  const localStorageSettingsStr = localStorage.getItem(POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY);

  try {
    return JSON.parse(localStorageSettingsStr) || DEFAULT_POWERBLOW_SETTINGS;
  } catch (e) {
    return DEFAULT_POWERBLOW_SETTINGS;
  }
};

const getIsDefaultIsOnFromLocalStorage = () => {
  const localStorageVal = localStorage.getItem(POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY);
  try {
    return JSON.parse(localStorageVal) ?? true;
  } catch (e) {
    return true;
  }
};

const PowerBlowContext = createContext(null);
export const usePowerBlow = () => createHook('usePowerBlow', PowerBlowContext);

export const PowerBlowContextProvider = ({ children }) => {
  const { connectedDevices, currentDevice } = useConnectedDevices();
  const [isDefaultOn, setIsDefaultOn] = useState(getIsDefaultIsOnFromLocalStorage());
  const [suctionPower, setSuctionPower] = useState(getSettingsFromLocalStorage().suctionPower);
  const [suctionTime, setSuctionTime] = useState(getSettingsFromLocalStorage().suctionTime);
  const [vacuumHold, setVacuumHold] = useState(getSettingsFromLocalStorage().vacuumHold);
  const [pauseTime, setPauseTime] = useState(getSettingsFromLocalStorage().pauseTime);
  const [ambientMovement, setAmbientMovement] = useState(
    getSettingsFromLocalStorage().ambientMovement,
  );
  const [showPowerBlowAlert, setShowPowerBlowAlert] = useLocalStorage(
    'showPowerBlowNotSyncAlert',
    true,
  );

  const [isStopButtonDisplayed, setIsStopButtonDisplayed] = useState(false);
  const timeoutRef = useRef(null);
  const isPowerBlow = powerblowNames.includes(currentDevice?.name);

  const setDefaultSettings = () => {
    setIsDefaultOn(true);
    setSuctionPower(DEFAULT_POWERBLOW_SETTINGS.suctionPower);
    setSuctionTime(DEFAULT_POWERBLOW_SETTINGS.suctionTime);
    setVacuumHold(DEFAULT_POWERBLOW_SETTINGS.vacuumHold);
    setPauseTime(DEFAULT_POWERBLOW_SETTINGS.pauseTime);
    setAmbientMovement(DEFAULT_POWERBLOW_SETTINGS.ambientMovement);
  };

  const resetSettings = () => {
    setDefaultSettings();
    localStorage.removeItem(POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY);
    localStorage.removeItem(POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY);
  };

  const testSettings = () => {
    const powerblowDevices = connectedDevices.filter(
      (device) => device.wrapper.setPowerblowSettings,
    );
    powerblowDevices.forEach((device) => {
      device.wrapper.testDevice({
        suctionPower,
        suctionTime,
        vacuumHold,
        pauseTime,
      });
    });
    setIsStopButtonDisplayed(true);
    timeoutRef.current = setTimeout(() => {
      testSettings();
    }, suctionTime + vacuumHold + pauseTime + EXTRA_DELAY);
  };

  const stopDevice = () => {
    const powerblowDevices = connectedDevices.filter(
      (device) => device.wrapper.setPowerblowSettings,
    );
    powerblowDevices.forEach((device) => {
      device.wrapper.stopDevice();
    });

    clearTimeout(timeoutRef.current);
    setIsStopButtonDisplayed(false);
  };

  const saveSettings = (key, value) => {
    const settings = {
      suctionPower,
      suctionTime,
      vacuumHold,
      pauseTime,
      ambientMovement,
      [key]: value, // override previous value
    };
    const settingsStr = JSON.stringify(settings);
    localStorage.setItem(POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY, settingsStr);

    // set settings object in device wrapper
    const powerblowDevice = connectedDevices.find((device) => device.wrapper.setPowerblowSettings);
    powerblowDevice?.wrapper?.setPowerblowSettings(settings);
  };

  const updateSuctionPower = (value) => {
    setSuctionPower(value);
    saveSettings('suctionPower', value);
  };

  const updateSuctionTime = (value) => {
    setSuctionTime(value);
    saveSettings('suctionTime', value);
  };

  const updateVacuumHold = (value) => {
    setVacuumHold(value);
    saveSettings('vacuumHold', value);
  };

  const updatePauseTime = (value) => {
    setPauseTime(value);
    saveSettings('pauseTime', value);
  };

  const updatAambientMovement = (value) => {
    setAmbientMovement(value);
    saveSettings('ambientMovement', value);
  };

  const isDefaultModeNotificationShown = useRef(false);
  const isCustomModeNotificationShown = useRef(false);

  useEffect(() => {
    stopDevice();
    if (isDefaultOn) {
      setDefaultSettings();
    } else {
      const settings = getSettingsFromLocalStorage();
      setSuctionPower(settings.suctionPower);
      setSuctionTime(settings.suctionTime);
      setVacuumHold(settings.vacuumHold);
      setPauseTime(settings.pauseTime);
      setAmbientMovement(settings.ambientMovement);
    }
    // set isDefaultOn flag in device wrapper
    const powerblowDevice = connectedDevices.find(
      (device) => device.wrapper.setIsDefaultSettingsOn,
    );
    powerblowDevice?.wrapper?.setIsDefaultSettingsOn(isDefaultOn);

    // save in local storage
    localStorage.setItem(POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY, JSON.stringify(isDefaultOn));
  }, [isDefaultOn, connectedDevices.length]);

  useEffect(() => {
    const powerblowDevice = connectedDevices.find(
      (device) => device.wrapper.setIsDefaultSettingsOn,
    );

    // display each notification only for each mode while the view is mounted
    if (!isDefaultModeNotificationShown.current && isDefaultOn && powerblowDevice) {
      toast.dismiss();
      toast(
        <DeviceStateNotification
          device={powerblowDevice}
          state={ConnectionState.POWERBLOW_SETTINGS}
          isDefaultMode
        />,
        // @ts-ignore
        SETTINGS_OPTIONS,
      );
      isDefaultModeNotificationShown.current = true;
    }

    if (!isCustomModeNotificationShown.current && !isDefaultOn && powerblowDevice) {
      toast.dismiss();
      toast(
        <DeviceStateNotification
          device={powerblowDevice}
          state={ConnectionState.POWERBLOW_SETTINGS}
        />,
        // @ts-ignore
        SETTINGS_OPTIONS,
      );
      isCustomModeNotificationShown.current = true;
    }
  }, [isDefaultOn, connectedDevices.length]);

  return (
    <PowerBlowContext.Provider
      value={{
        isDefaultOn,
        setIsDefaultOn,
        suctionPower,
        suctionTime,
        vacuumHold,
        pauseTime,
        isStopButtonDisplayed,
        setIsStopButtonDisplayed,
        setDefaultSettings,
        resetSettings,
        testSettings,
        stopDevice,
        saveSettings,
        updateSuctionPower,
        updateSuctionTime,
        updateVacuumHold,
        updatePauseTime,
        isPowerBlow,
        showPowerBlowAlert,
        setShowPowerBlowAlert,
        ambientMovement,
        updatAambientMovement,
      }}
    >
      {children}
    </PowerBlowContext.Provider>
  );
};
