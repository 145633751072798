import React, { useState, useEffect, createContext } from 'react';
import StrapiClient from 'StrapiClient';
import { createHook } from '../utils/utils';
import useLocalStorage from 'use-local-storage';

const NotificationsContext = createContext(null);
const useNotifications = () => createHook('useNotifications', NotificationsContext);

function NotificationsProvider({ children }) {
  const [showNotificationsBar, setShowNotificationsBar] = useState(false);
  const [showNotificationPage, setShowNotificationPage] = useState(false);
  const [showNotificationBanner, setShowNotificationBanner] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useLocalStorage('readNotifications', []);
  const [showOnlyUnread, setShowOnlyUnread] = useLocalStorage('showOnlyUnread', false);
  const [showLatestNotification, setShowLatestNotification] = useLocalStorage(
    'showLatestNotification',
    true,
  );
  function markAllNotificationsAsRead() {
    const allNotificationIds = notifications.map(({ id }) => id);
    setReadNotifications(allNotificationIds);
  }

  const unreadNotifications = notifications.filter(({ id }) => !readNotifications.includes(id));

  // number of unread notifications
  const numUnreadNotifications = unreadNotifications.length;

  const latestUnreadNotification = notifications.find(({ id }) => !readNotifications.includes(id));
  const filteredNotifications = showOnlyUnread ? unreadNotifications : notifications;

  useEffect(() => {
    StrapiClient.getNotifications().then((result) => {
      if (!result.error) {
        const dateSorted = result.response.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNotifications(dateSorted);
      }
    });
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        readNotifications,
        setReadNotifications,
        notifications,
        showNotificationsBar,
        setShowNotificationsBar,
        markAllNotificationsAsRead,
        numUnreadNotifications,
        latestUnreadNotification,
        showNotificationPage,
        setShowNotificationPage,
        showNotificationBanner,
        setShowNotificationBanner,
        unreadNotifications,
        showOnlyUnread,
        setShowOnlyUnread,
        showLatestNotification,
        setShowLatestNotification,
        filteredNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export { NotificationsProvider, useNotifications };
