import {
  StrapiResult,
  downloadImageAsBase64,
  getImageUrl,
  getMulti,
} from '@feelrobotics/strapi-sdk';

const WHITE_TYPE = 'whitelist';

async function getWhiteListSites(): Promise<StrapiResult> {
  const total = [];
  let hasNextPage = true;
  let currentPage = 0;
  let searchString = '';
  const isDev = process.env.NODE_ENV === 'development';
  const filterString = isDev ? '' : '&filters[tested][$eqi]=true';
  do {
    currentPage++;
    searchString = `fm-suggested-sites?pagination[page]=${currentPage}${filterString}`;
    const res = await getMulti(searchString, WHITE_TYPE, null, true, true);
    if (res.error) {
      return res;
    }
    const numberOfPages = res.response['meta']['pagination']['pageCount'];
    const append = await Promise.all(
      (res.response['data'] as object[]).map(async (item) => {
        const attrs = item['attributes'];
        let imgUrl = '';
        try {
          imgUrl = getImageUrl(attrs, 'logo');
        } catch {
          console.warn(`img url issue for ${attrs['entry_name']}`);
        }
        return {
          logo: await downloadImageAsBase64(imgUrl),
          url: attrs['tracking_url'] ? attrs['tracking_url'] : attrs['url'],
          id: item['id'],
          categories: attrs['tags']['data'].map((tag) => ({
            name: tag['attributes']['entry_name'],
            value: (tag['attributes']['entry_name'] as string).toLowerCase(),
          })),
          featured: attrs['featured'],
          featured_position: attrs['featured_position'],
          popular: attrs['popular'],
          name: attrs['entry_name'],
          tested: attrs['tested'],
        };
      }),
    );
    total.push(...append);
    hasNextPage = currentPage < numberOfPages;
  } while (hasNextPage);

  return { response: total, error: undefined };
}

async function getcomingSoonImage(): Promise<StrapiResult> {
  return { response: {}, error: 'not implemented' };
}

export { getWhiteListSites, getcomingSoonImage };
