import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'; // ES 2015
import CryptoJS from 'crypto-js';
import { AFFILIATES_REF_KEY } from 'data/constants';

import { appstoreUrl, playstoreUrl } from 'data/constants';
import disconnectedIcon from 'images/device-status/disconnected-icon.png';
import connectedIcon from 'images/device-status/connected-icon.png';
import { translations } from 'i18n';
import { getDeviceDisplayName } from 'lib/devices/wrappers/DeviceUtils';

// Creating custom hook with context
const createHook = (name, context) => {
  const reactContext = React.useContext(context);
  if (!reactContext) {
    throw new Error(`${name} hook must be used within a ContextProvider component`);
  }
  return reactContext;
};

const dayJS = dayjs.extend(relativeTime);

const REMEMBER_ME_LOCAL_STORAGE_KEY = 'REMEMBER_ME';

const getRememberMeLocalStorage = () => {
  const result = localStorage.getItem(REMEMBER_ME_LOCAL_STORAGE_KEY);
  return result || '';
};

const openVWBInStore = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let isIos = false;
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    isIos = true;
  }

  window.open(isIos ? appstoreUrl : playstoreUrl, '_blank');
};

const getLocalStorageByKey = (key) => {
  try {
    return localStorage.getItem(key) || '';
  } catch (e) {
    console.log('e key', key, e);
    return '';
  }
};

// return dd.mm.year
const getFormattedDate = (dateString) => {
  const dateObj = new Date(dateString);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1; // Note: January is 0
  const year = dateObj.getUTCFullYear();
  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};

function extractHostname(link) {
  const isLink = /^https?:\/\/\S+$/.test(link);
  if (!isLink) {
    return;
  }
  let hostname;
  // Find and remove protocol (http, ftp, etc.)
  if (link.indexOf('://') > -1) {
    hostname = link.split('/')[2];
  } else {
    hostname = link.split('/')[0];
  }
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }

  // Remove extension ( .com, .uk, .ua, etc)
  const extensionIndex = hostname.lastIndexOf('.');
  if (extensionIndex > -1) {
    hostname = hostname.substring(0, extensionIndex);
  }
  return hostname;
}
const updateDocumentTitle = (deviceRef, isConnected) => {
  const deviceName = getDeviceDisplayName(deviceRef?.device?.name) ?? undefined;
  const title = translations(
    isConnected ? 'Notifications.DeviceIsConnected' : 'Notifications.DeviceIsDisconnected',
    {
      deviceName,
    },
  );
  // Show the device status in document title
  document.title = title.startsWith('[missing')
    ? translations('Notifications.DeviceIsDisconnectedDefault')
    : title;
  document.querySelector('link[rel="icon"]').href = isConnected ? connectedIcon : disconnectedIcon;
};

const shouldSendAnalytics = () => {
  const isLocalhost = () => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  };
  return process.env.NODE_ENV === 'production' && !isLocalhost();
};

const displaySystemNotification = (deviceRef, isConnected, body) => {
  const deviceName = getDeviceDisplayName(deviceRef.device.name);
  const title = translations(
    isConnected ? 'Notifications.DeviceIsConnected' : 'Notifications.DeviceIsDisconnected',
    { deviceName },
  );
  // create a new system notification
  const notification = new Notification(
    title.startsWith('[missing')
      ? translations('Notifications.DeviceIsDisconnectedDefault')
      : title,
    {
      icon: '/favicon.ico',
      body,
    },
  );

  // close the notification after 10 seconds
  setTimeout(() => {
    notification.close();
  }, 10 * 1000);
};

function truncateEmail(email, maxLength) {
  if (!email) return '';
  if (email.length <= maxLength) return email;
  return email.slice(0, maxLength) + '...';
}

const decrypt = (cipherText) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY || '';
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};

const queryParamsToObject = (urlSearchParams) => {
  const result = {};

  for (const [key, value] of urlSearchParams) {
    const keys = key.match(/[^\[\]]+/g);
    let currentObj = result;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        // If it's the last key, assign the value
        currentObj[k] = decodeURIComponent(value); // Decode URI components to get a clean value
      } else {
        // Otherwise, navigate or create objects
        currentObj = currentObj[k] = currentObj[k] || {};
      }
    });
  }

  return result;
};

/**
 * feelmeLogger. A logger that excepts everything a console.log would but prefixes
 * each log with [FEELME] so application logs can be easily filtered in the console.
 * @param  {...any} args
 */
const feelmeLogger = (...args) => {
  console.log('[FEELME]', ...args);
};

export {
  createHook,
  dayJS,
  REMEMBER_ME_LOCAL_STORAGE_KEY,
  decrypt,
  queryParamsToObject,
  getRememberMeLocalStorage,
  openVWBInStore,
  getLocalStorageByKey,
  getFormattedDate,
  extractHostname,
  updateDocumentTitle,
  displaySystemNotification,
  truncateEmail,
  shouldSendAnalytics,
  feelmeLogger,
};
