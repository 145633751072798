import React, { createContext, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { createHook } from 'utils/utils';
import { useChromeExtension } from 'hooks/ChromeExtensionHook';

type Props = {
  children: React.ReactNode;
};

const LOCAL_STORAGE_KEY = 'wizard';

export interface WizardContextInterface {
  // step 0 is not shown yet; -1 was already shown
  step: number;
  setStep: (step: number) => void;
  showNext: () => void;
  skipWizard: () => void;
}

const WizardContext = createContext<WizardContextInterface | null>(null);
const useWizard = () => createHook('useWizard', WizardContext);

const WizardContextProvider = (props: Props) => {
  const { isExtensionInstalled } = useChromeExtension();

  // set 1 as default to activate wizard
  const [step, setStep] = useLocalStorage<number>(LOCAL_STORAGE_KEY, -1);
  const skipWizard = () => {
    // set Wizard as already shown
    setStep(-1);
  };
  const showNext = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    if ((step === 2 || step === 3) && isExtensionInstalled) {
      setStep(4);
    }
  }, [step, isExtensionInstalled]);

  return (
    <WizardContext.Provider
      value={{
        step,
        setStep,
        showNext,
        skipWizard,
      }}
    >
      {props.children}
    </WizardContext.Provider>
  );
};

export { WizardContextProvider, useWizard };
