import React from 'react';

import './style.scss';
import styles from './style.module.scss';

const Loader = () => (
  <div className={styles.container}>
    <div className="la-ball-spin-clockwise-fade-rotating la-2x">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
