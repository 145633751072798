const FRAMES_PER_STROKE = 5;

export default class StrokerFinishModeMixin {
  constructor() {
    this.ticker = 0;
    this.upPosition = false;
  }

  getFinishingPosition(maxIntensity) {
    return Math.round(this.upPosition ? 0 : maxIntensity);
  }

  /**
   * Call this function ~30 times per second
   */
  async onTimer() {
    this.ticker++;
    if (this.ticker >= FRAMES_PER_STROKE) {
      this.ticker = 0;
      this.upPosition = !this.upPosition;
    }
  }
}
