import React from 'react';
import cn from 'classnames';

import styles from './style.module.scss';
import infoImg from 'images/info-icon.png';
import closeImg from 'images/close-icon.png';
import CheckBox from 'components/common/CheckBox';

import { translations } from 'i18n';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

// Device intensity percent value which corresponds
// to the minimum value on the slider
export const MIN_INTENSITY = 35;

const FullscreenControls = ({ closeSetting, displayInfo }) => {
  const {
    displaySuctionModeSlider,
    autopilot,
    setAutopilot,
    ambientMovement,
    setAmbientMovement,
    intensity,
    setIntensity,
    suctionMode,
    setSuctionMode,
  } = useChromeExtensionPopup();

  const sliderValue = ((intensity - MIN_INTENSITY) / (100 - MIN_INTENSITY)) * 100;
  const suctionSliderValue = suctionMode * 20;

  return (
    <>
      <div className={styles.header}>
        <p>{translations('ExtensionPopup.DeviceSettings')}</p>
        <div className={styles.rightIconsContainer}>
          <img src={infoImg} alt="logo" className={styles.infoImg} onClick={displayInfo} />
          <img src={closeImg} alt="logo" className={styles.closeImg} onClick={closeSetting} />
        </div>
      </div>

      <div
        className={styles.autopilotCheckboxRow}
        onClick={() => {
          setAutopilot(!autopilot);
        }}
      >
        <CheckBox checked={autopilot} mobile styles={styles.checked} strokeColor={'white'} />
        <span>{translations('ExtensionPopup.Autopilot')}</span>
      </div>

      <div className={cn(autopilot && styles.grayscale, styles.sliderContainer)}>
        <span className={styles.settingsLabel}>
          {translations('ExtensionPopup.AmbientMovement')}
        </span>
        <input
          className={styles.settingsRange}
          type="range"
          list="intensity"
          min="0"
          max="100"
          step="25"
          value={ambientMovement}
          disabled={autopilot}
          onChange={(evt) => {
            setIntensity(evt.target.value);
          }}
          style={{
            background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${ambientMovement}%, #4d445b ${ambientMovement}%, #4c445a 100%)`,
          }}
        />
        <datalist id="ambientMovement" className={styles.settingsOptions}>
          <option value="0" label="0%"></option>
          <option value="25" label="25%" className={styles.middleLabel}></option>
          <option value="50" label="50%" className={styles.middleLabel}></option>
          <option value="75" label="75%" className={styles.middleLabel}></option>
          <option value="100" label="100%"></option>
        </datalist>
      </div>

      <div className={styles.sliderContainer}>
        <span className={styles.settingsLabel}>{translations('ExtensionPopup.PowerControl')}</span>
        <input
          className={styles.settingsRange}
          type="range"
          list="intensity"
          min={MIN_INTENSITY}
          max="100"
          step={(100 - MIN_INTENSITY) / 2}
          value={intensity}
          onChange={(evt) => {
            setIntensity(evt.target.value);
          }}
          style={{
            background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${sliderValue}%, #4d445b ${sliderValue}%, #4c445a 100%)`,
          }}
        />
        <datalist id="intensity" className={styles.settingsOptions}>
          <option value="1" label="Low"></option>
          <option value="2" label="Medium"></option>
          <option value="3" label="High"></option>
        </datalist>
      </div>

      {displaySuctionModeSlider && (
        <div className={styles.sliderContainer}>
          <span className={styles.settingsLabel}>{translations('ExtensionPopup.SuctionMode')}</span>
          <input
            className={styles.settingsRange}
            type="range"
            list="suctionMode"
            min="0"
            max="5"
            step="1"
            value={suctionMode}
            onChange={(evt) => {
              setSuctionMode(evt.target.value);
            }}
            style={{
              background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${suctionSliderValue}%, #4d445b ${suctionSliderValue}%, #4c445a 100%)`,
            }}
          />
          <datalist id="suctionMode" className={styles.settingsOptions}>
            <option value="0" label="0"></option>
            <option value="1" label="1"></option>
            <option value="2" label="2"></option>
            <option value="3" label="3"></option>
            <option value="4" label="4"></option>
            <option value="5" label="5"></option>
          </datalist>
        </div>
      )}
    </>
  );
};

export default FullscreenControls;
