import React from 'react';
import { translations } from 'i18n';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

import LegacyButton from '../LegacyButton';
import logoImage from 'images/popup/i-video-watcher-logo.png';
import styles from './style.module.scss';

export const PauseButton = ({ showButton = true }) => {
  const { isPaused, setPaused, extensionVersion, devices } = useChromeExtensionPopup();
  return (
    <div className={styles.containerHeader}>
      <div className={styles.headerInfo}>
        <img className={styles.logoImage} src={logoImage} alt="Logo" />
        <div className={styles.title}>
          <p className={styles.logoText}>Video Watcher</p>
          <p className={styles.logoSubText}>
            {translations('ExtensionPopup.Version')}: {extensionVersion}
          </p>
        </div>
      </div>
      {showButton && (
        <LegacyButton
          styles={styles.btn}
          disabled={!devices?.length}
          onClick={() => {
            setPaused((prev) => !prev);
          }}
          text={
            isPaused
              ? translations('ExtensionPopup.PlayDevice')
              : translations('ExtensionPopup.PauseDevice')
          }
        />
      )}
    </div>
  );
};
