import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { ROUTE_PATHS } from 'data/constants';
import Loader from 'components/common/Loader';
import BlackBackdrop from 'components/common/BlackBackdrop';
import ProtectedRoute from './ProtectedRoute';
import { useAuth } from 'hooks/AuthHook';
import { PlatformRoute, PLATFORMS } from 'routes/PlatformRoute';
import { useAffiliate } from 'hooks/AffiliateHook';
import { useImportCart } from 'hooks/ImportCartHook';

const SignUpPage = lazy(() => import('containers/SignUpPage'));
const SignUpPageMobile = lazy(() => import('containers/SignUpPage/mobile'));
const LoginPage = lazy(() => import('containers/LoginPage'));
const LoginPageMobile = lazy(() => import('containers/LoginPage/mobile'));
const ForgotPasswordPage = lazy(() => import('containers/ForgotPasswordPage'));
const ConfirmEmailPage = lazy(() => import('containers/ConfirmEmailPage'));
const ConfirmEmailPageMobile = lazy(() => import('containers/ConfirmEmailPage/mobile'));
const ConfirmEmailFormPage = lazy(() => import('containers/ConfirmEmailFormPage'));
const ConfirmEmailFormPageMobile = lazy(() => import('containers/ConfirmEmailFormPage/mobile'));
const ResendConfirmationEmailPage = lazy(() => import('containers/ResendConfirmationEmail'));
const ResendConfirmationEmailPageMobile = lazy(
  () => import('containers/ResendConfirmationEmail/mobile'),
);
const RecoveryPasswordPage = lazy(() => import('containers/RecoveryPasswordPage'));
const MenuContainer = lazy(() => import('containers/MenuContainer'));
const MenuContainerMobile = lazy(() => import('containers/MenuContainer/mobile'));
const DocumentPage = lazy(() => import('containers/DocumentPage'));
const FAQPage = lazy(() => import('containers/FAQPage'));
const ContactUsPageMobile = lazy(() => import('containers/ContactUsPage/mobile'));
const ChooseYourSubscriptionPageMobile = lazy(
  () => import('containers/ChooseYourSubscriptionPage/mobile'),
);
const ContentChannelsMobile = lazy(() => import('containers/ContentChannels/mobile'));

const ConnectionPageMobile = lazy(() => import('containers/ConnectionPage/mobile'));
const InboxPageMobile = lazy(() => import('containers/InboxPage/mobile'));

const PortalAppRouting = () => {
  const { authenticatedUser, isLoading } = useAuth();
  const { saveAffiliateCode } = useAffiliate();
  const { processPlanIdFromUrl } = useImportCart();

  /**
   * Processes query parameters from the current URL. Specifically handles 'ref' and 'cart' parameters.
   * For 'ref', it saves the affiliate code using the saveAffiliateCode function.
   * For 'cart', it processes the plan ID using the processPlanIdFromUrl function.
   * Errors in processing are logged to the console.
   */
  const processUrlQueryParameters = () => {
    const queryParameters = new URLSearchParams(window.location.search);

    // Process 'ref' parameter
    const affiliatesRefCode = queryParameters.get('ref');
    if (!!affiliatesRefCode) {
      try {
        saveAffiliateCode(affiliatesRefCode);
      } catch (error) {
        console.error('Could not save affiliate code:', affiliatesRefCode, error);
      }
    }

    // Process 'cart' parameter
    const cart = queryParameters.get('cart');
    if (!!cart) {
      try {
        processPlanIdFromUrl(cart);
      } catch (error) {
        console.error('Could not save plan id from cart:', cart, error);
      }
    }
  };

  useEffect(() => {
    // Check if any referral code is present and if  it is,
    // save it to local storage in format YYYY-MM-DDaffiliate-code with the current date
    processUrlQueryParameters();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Suspense
      fallback={
        <BlackBackdrop>
          <Loader />
        </BlackBackdrop>
      }
    >
      <Routes>
        <Route
          path={'*'}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={!authenticatedUser}>
                <Navigate to={ROUTE_PATHS.LOGIN} replace />
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.SIGN_UP}
          element={
            <ProtectedRoute user={!authenticatedUser}>
              {isMobile ? <SignUpPageMobile /> : <SignUpPage />}
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.LOGIN}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={!authenticatedUser}>
                {isMobile ? <LoginPageMobile /> : <LoginPage />}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.RESEND_CONFIRMATION_EMAIL}
          element={
            <PlatformRoute allowed={[PLATFORMS.CHROME_DESKTOP, PLATFORMS.APP, PLATFORMS.MOBILE]}>
              <ProtectedRoute user={!authenticatedUser}>
                {isMobile ? <ResendConfirmationEmailPageMobile /> : <ResendConfirmationEmailPage />}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.FORGOT_PASSWORD}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={!authenticatedUser}>
                <ForgotPasswordPage />
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.CONFIRM_EMAIL}
          element={
            <ProtectedRoute user={!authenticatedUser}>
              {isMobile ? <ConfirmEmailPageMobile /> : <ConfirmEmailPage />}
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.CONFIRM_EMAIL_FORM}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={!authenticatedUser}>
                {isMobile ? <ConfirmEmailFormPageMobile /> : <ConfirmEmailFormPage />}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.RECOVERY_PASSWORD}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={!authenticatedUser}>
                <RecoveryPasswordPage />
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.CHOOSE_YOUR_SUBSCRIPTION}
          element={
            <ProtectedRoute user={authenticatedUser}>
              <MenuContainerMobile noBorder>
                <ChooseYourSubscriptionPageMobile />
              </MenuContainerMobile>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.CONTENT_CHANNELS}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={authenticatedUser} redirectPath={ROUTE_PATHS.LOGIN}>
                {isMobile ? (
                  <MenuContainerMobile noBorder={true}>
                    <ContentChannelsMobile />
                  </MenuContainerMobile>
                ) : null}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.INBOX}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={authenticatedUser} redirectPath={ROUTE_PATHS.LOGIN}>
                {isMobile ? (
                  <MenuContainerMobile noBorder={true}>
                    <InboxPageMobile />
                  </MenuContainerMobile>
                ) : null}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.DASHBOARD + '/*'}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={authenticatedUser} redirectPath={ROUTE_PATHS.LOGIN}>
                {isMobile ? (
                  <MenuContainerMobile>
                    <ConnectionPageMobile />
                  </MenuContainerMobile>
                ) : (
                  <MenuContainer />
                )}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.FAQ}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={authenticatedUser} redirectPath={ROUTE_PATHS.LOGIN}>
                {isMobile ? (
                  <MenuContainerMobile noBorder>
                    <FAQPage />
                  </MenuContainerMobile>
                ) : null}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route
          path={ROUTE_PATHS.CONTACT_US}
          element={
            <PlatformRoute allowed={[PLATFORMS.DESKTOP, PLATFORMS.APP]}>
              <ProtectedRoute user={authenticatedUser} redirectPath={ROUTE_PATHS.LOGIN}>
                {isMobile ? <ContactUsPageMobile /> : null}
              </ProtectedRoute>
            </PlatformRoute>
          }
        />
        <Route path={ROUTE_PATHS.TERMS_AND_CONDITIONS} element={<DocumentPage />} />
        <Route path={ROUTE_PATHS.PRIVACY_POLICY} element={<DocumentPage />} />
        <Route path={ROUTE_PATHS.CANCELLATION_POLICY} element={<DocumentPage />} />
      </Routes>
    </Suspense>
  );
};

export default PortalAppRouting;
