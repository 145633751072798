import { StrapiResult } from '@feelrobotics/strapi-sdk';
import { getSingle } from '@feelrobotics/strapi-sdk';

const FAQ_TYPE = 'faq';

async function getFaq(): Promise<StrapiResult> {
  const searchString = 'faq-groups?filters[entry_name][$eqi]=faq-feelme';
  const alternatePopulate = 'populate[questions][populate][0]=category';

  const res = await getSingle(searchString, FAQ_TYPE, alternatePopulate);

  res.response = res.response['questions']['data'].map((item) => {
    return {
      question: item.attributes.question,
      answer: item.attributes.answer,
      category: item.attributes.category.data.attributes.entry_name,
      order: item.attributes.order,
      id: item.id,
    };
  });

  return res;
}

export { getFaq };
