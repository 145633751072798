import React, { createContext, useEffect, useCallback, useState } from 'react';

import { createHook } from 'utils/utils';
import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';
import { CORS_DOMAINS } from 'data/constants';

const ChromeExtensionContext = createContext();

const useChromeExtension = () => createHook('useChromeExtension', ChromeExtensionContext);

export const EXTENSION_VERSION_UNKNOWN = '__unknown__';
export const EXTENSION_NOT_INSTALLED = '__not_installed__';
export const EXTENSION_MIN_VERSION = [1, 3, 6, 0]; // [major, minor, patch, build]

const ChromeExtensionContextProvider = ({ children }) => {
  const [extensionVersion, setExtensionVersion] = useState(EXTENSION_VERSION_UNKNOWN);
  const [isExtensionPinned, setExtensionPinned] = useState(true);
  // Current focused page content URL
  const [contentUrl, setContentUrl] = useState(null);

  useEffect(() => {
    const requestVersion = () => {
      const request = {
        what: 'CHROME_EXTENSION_CHECK_VERSION_REQUEST',
      };
      window.postMessage(request, '*');
    };

    const sendCorsDomains = () => {
      const request = {
        what: 'DOMAINS_FOR_CORS_OVERRIDE',
        domains: CORS_DOMAINS,
      };
      window.postMessage(request, '*');
    };
    // Request version after the page load
    // The delay is needed to wait until the content script is started
    setTimeout(requestVersion, 300);
    setTimeout(sendCorsDomains, 300);

    // If extensition didn't report its version within 400 msecs, consider that it's not installed
    setTimeout(() => {
      setExtensionVersion((oldVersion) =>
        oldVersion === EXTENSION_VERSION_UNKNOWN ? EXTENSION_NOT_INSTALLED : oldVersion,
      );
    }, 400);
  }, []);

  useEffect(() => {
    const requestIsExtensionPinned = () => {
      const request = {
        what: 'CHROME_EXTENSION_CHECK_IS_PINNED_REQUEST',
      };
      window.postMessage(request, '*');
    };
    // Request version after the page load
    // The delay is needed to wait until the content script is started
    setTimeout(requestIsExtensionPinned, 300);
    // If extensition didn't report its version within 400 msecs, consider that it's not installed
    setTimeout(
      () => setExtensionPinned((oldPinned) => (oldPinned === undefined ? true : oldPinned)),
      400,
    );
  }, []);

  const onMessageHandler = useCallback((event) => {
    const { data } = event;
    const { payload, what } = data;
    switch (what) {
      case 'CHROME_EXTENSION_VERSION':
        setExtensionVersion(payload.version);
        break;
      case 'CHROME_EXTENSION_IS_PINNED':
        setExtensionPinned(payload.isPinned);
        break;
      case 'CHROME_EXTENSION_CONTENT_URL':
        setContentUrl(payload.url);
        break;
      case 'VIDEO_SEEKED':
        sendToAnalyticsCustomEventObj('video_seeked', {
          current_time: payload.currentTime,
          duration: payload.duration,
          url: payload.url,
        });
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessageHandler);
    return () => {
      window.removeEventListener('message', onMessageHandler);
    };
  }, [onMessageHandler]);

  const isExtensionInstalled =
    extensionVersion !== EXTENSION_NOT_INSTALLED && extensionVersion !== EXTENSION_VERSION_UNKNOWN;

  const isExtensionUpToDate = () => {
    if (!isExtensionInstalled) {
      return false;
    }
    const parts = extensionVersion.split('.');
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] < EXTENSION_MIN_VERSION[i]) {
        return false;
      } else if (parts[i] > EXTENSION_MIN_VERSION[i]) {
        return true;
      }
    }
    return true;
  };

  return (
    <ChromeExtensionContext.Provider
      value={{
        extensionVersion,
        isExtensionInstalled,
        isExtensionUpToDate,
        isExtensionPinned,
        contentUrl,
      }}
    >
      {children}
    </ChromeExtensionContext.Provider>
  );
};

export { ChromeExtensionContextProvider, useChromeExtension };
