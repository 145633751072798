import React from 'react';
import cn from 'classnames';

import styles from './style.module.scss';

const CheckBox = ({ checked, mobile, styles: extraStyles, strokeColor = 'black' }) => {
  return checked ? (
    <div className={cn(styles.filled, mobile && styles.mobileFilled, extraStyles)}>
      <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
        <path d="M1 4.5L5 8.5L12.5 1" stroke={strokeColor} strokeWidth="2" />
      </svg>
    </div>
  ) : (
    <div className={cn(styles.empty, mobile && styles.mobileEmpty)} />
  );
};

export default CheckBox;
