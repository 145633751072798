import { getFaq } from './faq';
import { getCancellationPolicy, getPrivacyPolicy, getTermsAndConditions } from './document';
import { getNotifications } from './notifications';
import { getPromotionalBannerItems } from './promotional-banner';
import { getAuthDevicesData } from './auth-device-slider';
import { getWhiteListSites, getcomingSoonImage } from './whitelist';

const defaultExport = {
  getFaq,
  getCancellationPolicy,
  getPrivacyPolicy,
  getTermsAndConditions,
  getNotifications,
  getPromotionalBannerItems,
  getcomingSoonImage,
  getAuthDevicesData,
  getWhiteListSites,
};

export default defaultExport;
