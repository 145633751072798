import { DeviceState, PositionState } from './DeviceState';
import { Pendulum } from './Pendulum';
import { PredictionProcessor } from './Pendulum/PredictionProcessor';

const BLOW_JOB_BUFFER_MAX_LENGTH = 10;
// Average isBlowJob over which we say that a BJ is happening
const AVERAGE_BLOW_JOB_THRESHOLD = 0.5;

/**
 * This class is responsible for interpreting current frame predictions
 * and updating device state accordingly
 */
export class CurrentFrameInterpreter {
  private static MIN_STROKE_DURATION: number = 183;
  private static MAX_STROKE_DURATION: number = 1000;
  private static STOP_MOVEMENT_THRESHOLD: number = 10;

  private pendulum: Pendulum;
  private predictionProcessor: PredictionProcessor;
  private lastPosition: number;
  private consistentPositionCounter: number;
  private isBlowJobBuffer: number[];

  private lastIsBlowJob: number;

  constructor() {
    this.pendulum = new Pendulum();
    this.predictionProcessor = new PredictionProcessor(this.pendulum);
    this.isBlowJobBuffer = [];
    this.lastIsBlowJob = 0;
    this.lastPosition = 0;
    this.consistentPositionCounter = 0;
  }

  /**
   * Counts consecutive occurrences of the same position and updates the counter
   * or resets it if the position changes.
   * @param {number} position - The current position to be evaluated for consistency.
   */
  countConsistentPosition(position: number): void {
    if (this.lastPosition === position) {
      this.consistentPositionCounter++;
    } else {
      this.lastPosition = position;
      this.consistentPositionCounter = 1;
    }
  }

  /**
   * Calculates the speed percentage based on the period of the pendulum.
   * The speed is calculated by first determining the amplitude per second,
   * then applying a formula to convert it to a percentage, ensuring it stays within 0 to 100%.
   * @param {number} period - The period of the pendulum in milliseconds.
   * @returns {number} The calculated speed as a percentage.
   */
  private calculateSpeed(period: number): number {
    const speedAmpPerSec = Math.round(200000 / period);
    const speedPercent = Math.max(Math.min(Math.round(0.382 * speedAmpPerSec - 29.659), 100), 0);
    return speedPercent;
  }

  /**
   * This function decides whether a BJ is happening based on the last 10 frames
   * @param isBlowJob - the prediction we get from the ML server
   * @returns true if a BJ is happening, false otherwise
   */
  isBlowJobOnFrame(): boolean {
    const average = this.isBlowJobBuffer.reduce((a, b) => a + b, 0) / this.isBlowJobBuffer.length;
    return average > AVERAGE_BLOW_JOB_THRESHOLD;
  }

  /**
   * This function updates the devices isBlowJob state with every frame
   * Deciding whether the BJ is ongoing based on BLOW_JOB_BUFFER_MAX_LENGTH last frames
   * @param isBlowJob - boolean coming from the prediction
   */
  updateIsBlowJobOnFrame(deviceState: DeviceState) {
    if (this.isBlowJobBuffer.length === BLOW_JOB_BUFFER_MAX_LENGTH) {
      this.isBlowJobBuffer.shift();
    }
    this.isBlowJobBuffer.push(this.lastIsBlowJob);
    // Update the device state
    deviceState.isBlowJob = this.isBlowJobOnFrame();
  }

  /**
   * This function is called 30 times per second to update device state
   * @param deviceState - device state to update
   */
  updateDeviceStateOnFrame(deviceState: DeviceState) {
    deviceState.updatedWithFuturePrediction = false;

    if (this.consistentPositionCounter >= CurrentFrameInterpreter.STOP_MOVEMENT_THRESHOLD) {
      // This is where we stop moving
      return deviceState;
    }

    const { destination, period } = this.predictionProcessor.getPrediction();
    deviceState.speed = this.calculateSpeed(period);

    // Stroke duration has to be bigger than the fastest stroke duration but lower than the slowest
    deviceState.strokeDuration = Math.min(
      Math.max(Math.floor(period / 2), CurrentFrameInterpreter.MIN_STROKE_DURATION),
      CurrentFrameInterpreter.MAX_STROKE_DURATION,
    );
    deviceState.destination = destination;
    const futurePositionUp = destination > 0.5;
    if (deviceState.positionState === PositionState.Down && futurePositionUp) {
      // We should go up
      deviceState.positionState = PositionState.MoveUp;
    } else if (deviceState.positionState === PositionState.Up && !futurePositionUp) {
      // We should go down
      deviceState.positionState = PositionState.MoveDown;
    }
    this.updateIsBlowJobOnFrame(deviceState);
    return deviceState;
  }

  /**
   * This function is called when a new prediction is received from the ML server.
   * It processes the prediction by determining if it indicates a specific action
   * and then forwards the details to the prediction processor.
   * @param {number} position - The device position ranging from 0 to 1.
   * @param {number} isBlowJob - Indicator if the action is a specific type (e.g., 0 or 1).
   * @param {number} timestamp - The timestamp when the prediction was sent.
   */
  onMlCommand(position: number, isBlowJob: number, timestamp: number) {
    const isBJ = !!isBlowJob;

    const nextPosition = Math.round(position);

    // This is needed to spot where there should be no movement
    // Because otherwise we would always get a non-zero period
    this.countConsistentPosition(nextPosition);

    this.predictionProcessor.run({
      position: nextPosition,
      isBlowJob: isBJ,
      timestampSent: timestamp,
      timestampReceived: Date.now(),
    });
    this.lastIsBlowJob = isBlowJob;
  }
}
