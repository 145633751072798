import React from 'react';
import { translations } from 'i18n';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

export const SuctionMode = () => {
  const { suctionMode, setSuctionMode } = useChromeExtensionPopup();
  const sliderValue = suctionMode * 20;
  return (
    <div>
      <label>
        <span className={styles.settingsLabel}>{translations('ExtensionPopup.SuctionMode')}:</span>
        <span className={styles.settingsLabelDescription}>
          {translations('ExtensionPopup.SuctionModeDescription')}
        </span>
        <input
          className={styles.settingsRange}
          type="range"
          list="suctionMode"
          min="0"
          max="5"
          step="1"
          value={suctionMode}
          onChange={(evt) => {
            setSuctionMode(evt.target.value);
          }}
          style={{
            background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${sliderValue}%, #4d445b ${sliderValue}%, #4c445a 100%)`,
          }}
        />
      </label>
      <datalist id="suctionMode" className={styles.settingsOptions}>
        <option value="0" label="0"></option>
        <option value="1" label="1" className={styles.middleLabel}></option>
        <option value="2" label="2" className={styles.middleLabel}></option>
        <option value="3" label="3" className={styles.middleLabel}></option>
        <option value="4" label="4" className={styles.middleLabel}></option>
        <option value="5" label="5"></option>
      </datalist>
    </div>
  );
};
