import React from 'react';

import styles from './style.module.scss';

import {
  useChromeExtensionPopup,
  PORTAL_SEARCH_STATUS,
} from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

import { PauseButton } from '../PauseButton';
import { FrameDetails } from '../FrameDetails';
import { Intensity } from '../Intensity';
import { AmbientMovement } from '../AmbientMovement';
import { Bandwidth } from '../Bandwidth';
import { AutopilotSwitch } from '../AutopilotSwitch';
import { PortalNotFound } from '../PortalNotFound';
import { NotLoggedIn } from '../NotLoggedIn';
import { NoContentScript } from '../NoContentScript';
import { SuctionMode } from '../SuctionMode';
import { TestSignalSwitch } from '../TestSignalSwitch';

import Loader from 'components/common/Loader';
import { getDomain } from '../../../utils/domainUtils';
import BlockedDomain from '../BlockedDomain';

const PopupContainer = () => {
  const {
    portalStatus,
    whitelisted,
    videoUrl,
    displaySuctionModeSlider,
    displayTestSignalSwitch,
    isLoggedIn,
  } = useChromeExtensionPopup();

  if (portalStatus === PORTAL_SEARCH_STATUS.SEARCHING) {
    return (
      <div className={styles.container}>
        <div className={styles.backdrop} />
        <Loader />
      </div>
    );
  }

  if (portalStatus === PORTAL_SEARCH_STATUS.NOT_FOUND) {
    return <PortalNotFound />;
  }

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }

  if (!videoUrl) {
    return <NoContentScript />;
  }

  if (!whitelisted) {
    return (
      <div className={styles.container}>
        <div className={styles.backdrop} />
        <PauseButton showButton={false} />
        <BlockedDomain siteUrl={getDomain(videoUrl)} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.backdrop} />
      <PauseButton />
      <FrameDetails />
      <div className={styles.settingsContainer}>
        <form>
          <AutopilotSwitch />
          {displayTestSignalSwitch && <TestSignalSwitch />}
          <AmbientMovement />
          <Intensity />
          {displaySuctionModeSlider && <SuctionMode />}
          <Bandwidth />
        </form>
      </div>
    </div>
  );
};

export default PopupContainer;
