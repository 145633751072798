import React, { createContext, useEffect, useState, useCallback } from 'react';

import { createHook } from 'utils/utils';

import { useSubscription } from 'hooks/SubscriptionHook';
import { useMLPerformance } from 'hooks/MLPerformanceHook';
import { usePrediction } from 'hooks/PredictionHook';
import { useConnectedDevices } from 'hooks/ConnectedDevicesHook';
import { useDevicesOperation } from './DevicesOperationHook';
import { useWebsiteCompatibility } from 'hooks/WebsiteCompatibilityHook';

import * as ConnectionState from '../lib/devices/wrappers/DeviceStates';

const ANALYTICS_SERVER_URL = 'https://lfgvuzcqba.execute-api.us-east-1.amazonaws.com/production';
const LOCAL_STORAGE_USER_ID_KEY = 'feelme_analytics_server_user_id';

let clientId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY);
if (!clientId) {
  clientId =
    'portal-' +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, clientId);
}

// It's empty because the hook doesn't export anything
interface FeelmeAnalyticsServerContextInterface { }

type Props = {
  children: React.ReactNode;
};

const FeelmeAnalyticsServerContext = createContext<FeelmeAnalyticsServerContextInterface | null>(
  null,
);

export const useFeelmeAnalyticsServer = () =>
  createHook('useFeelmeAnalyticsServer', FeelmeAnalyticsServerContext);

export const FeelmeAnalyticsServerContextProvider = (props: Props) => {
  const [portalPageOpenEventSent, setPortalPageOpenEventSent] = useState(false);
  const { tokenObj } = useSubscription();
  const { token } = tokenObj || {};

  const { paused } = useMLPerformance();
  const { frameUrl, autopilot } = usePrediction();
  const { devices } = useConnectedDevices();
  const { isPaused, intensity, ambientMovement } = useDevicesOperation();
  const [connectedDevices, setConnectedDevices] = useState({});
  const { checkCompatibility } = useWebsiteCompatibility();

  /**
   * Call useEffect for the given setting value. If the value changes, send
   * settings_update event to Feelme Analytics Server with the given setting name
   * @param setting - setting value
   * @param settingName - setting name
   */
  const useEffectUpdateSettings = (setting: string, settingName: string) => {
    useEffect(() => {
      if (!token) {
        return;
      }
      sendToFeelmeAnalyticsServer({
        event_type: 'settings_update',
        [settingName]: setting,
      });
    }, [setting, settingName]);
  };

  /**
   * Send event to Feelme Analytics Server
   * @param {object} event - event object.
   * @see ANALYTICS_SERVER_URL+'/api/v1/doc' for more info on event object format
   */
  const sendToFeelmeAnalyticsServer = useCallback(
    async (event) => {
      if (!token) {
        throw new Error('Feelme Analytics Server token is not set');
      }

      const eventMessage = {
        ...event,
        client_id: clientId,
        portal_page_url: window.location.origin,
        user_agent: navigator.userAgent,
        // timestamp in number of seconds since epoch
        timestamp: Math.floor(Date.now() / 1000),
      };

      const body = JSON.stringify(eventMessage);
      const headers = {
        'Content-Type': 'application/json',
        'X-BILLING-API-TOKEN': token,
      };
      await fetch(ANALYTICS_SERVER_URL + '/api/v1/collect/event', {
        method: 'POST',
        headers,
        body,
      });
    },
    [token],
  );

  // As soon as we get token, send portal_page_open event
  useEffect(() => {
    if (!token || portalPageOpenEventSent) {
      return;
    }
    setPortalPageOpenEventSent(true);
    sendToFeelmeAnalyticsServer({
      event_type: 'portal_page_open',
    });
  }, [portalPageOpenEventSent, sendToFeelmeAnalyticsServer, token]);

  // On every change of frameUrl and paused, send
  // video_start or video_stop event
  useEffect(() => {
    if (!token || !frameUrl || !checkCompatibility(frameUrl)) {
      return;
    }
    sendToFeelmeAnalyticsServer({
      event_type: paused ? 'video_stop' : 'video_start',
      video_url: frameUrl,
    });
  }, [frameUrl, paused, sendToFeelmeAnalyticsServer, token, checkCompatibility]);

  // Array of objects containing device id and connected state
  const deviceConnectedFlags = devices.map((device) => ({
    id: device?.id,
    connected: device?.wrapper?.connectionState === ConnectionState.CONNECTED,
  }));
  // On every change of connected devices, send
  // device_connected or device_disconnected event
  useEffect(() => {
    let changed = false;
    const newConnectedDevices = {};
    devices.forEach((device) => {
      const deviceId = device?.id;
      if (!deviceId) {
        return;
      }

      const connected = device?.wrapper?.connectionState === ConnectionState.CONNECTED;
      newConnectedDevices[deviceId] = connected;
      if (Boolean(connectedDevices[deviceId]) !== connected) {
        changed = true;
        sendToFeelmeAnalyticsServer({
          event_type: connected ? 'device_connected' : 'device_disconnected',
          device_name: device?.name,
        });
      }
    });
    if (changed) {
      setConnectedDevices(newConnectedDevices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedDevices, JSON.stringify(deviceConnectedFlags), sendToFeelmeAnalyticsServer]);

  // On every change of settings, send settings_update event
  useEffectUpdateSettings(isPaused, 'devices_paused');
  useEffectUpdateSettings(intensity, 'power_control_intensity');
  useEffectUpdateSettings(ambientMovement, 'auto_control_intensity');
  useEffectUpdateSettings(autopilot, 'autopilot');

  return (
    <FeelmeAnalyticsServerContext.Provider value={{}}>
      {props.children}
    </FeelmeAnalyticsServerContext.Provider>
  );
};
