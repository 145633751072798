import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { init, Source, OperationMode } from '@feelrobotics/ftap-connector';

import './index.scss';
import 'fonts/fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const isProduction =
  window.location.hostname === 'portal.feelme.com' ||
  window.location.hostname === 'redesign.feelme.com';

window.Chargebee.init({
  site: isProduction ? 'feelme' : 'feelme-test',
  enableGTMTracking: true,
});

// Initialize FTAP connector
init(Source.FeelMe, isProduction ? OperationMode.Prod : OperationMode.Stage);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
