import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';

const baseURL = process.env.REACT_APP_BILLING_SERVER_API_URL ?? '';

const billingApi = async (path, isAuthRequired = true, params, options = {}, data) => {
  const headers = new Headers();

  try {
    // Attempt to get the current user session token
    const xAccessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    headers.set('x-access-token', xAccessToken);
  } catch (error) {
    console.error('Error retrieving user session: ', error);
    if (isAuthRequired) {
      // We don't have the access token, so no need to bother the billing server
      return;
    }
  }

  const rawResponse = await fetch(
    baseURL + path + (params ? '?' + new URLSearchParams(params) : ''),
    {
      ...options,
      headers,
      body: data && JSON.stringify(data),
    },
  );

  // Check if the response has content
  const contentType = rawResponse.headers.get('content-type');

  if (!rawResponse.ok) {
    if (rawResponse.status !== 401 && rawResponse.status !== 404) {
      toast.error(rawResponse.status + ' ' + rawResponse.statusText);
    }
    sendToAnalyticsCustomEventObj('api_error', {
      status: rawResponse.status,
      message: rawResponse.statusText,
    });
    throw new Error(rawResponse.statusText);
  } else if (rawResponse.status === 204) {
    // No Content or not JSON type
    return {};
  } else {
    return await rawResponse.json();
  }
};

export default billingApi;
