import MirrorKeon from 'images/Mirror-keon.png';
import NameKeon from 'images/Name-keon.png';
import MirrorOnyx from 'images/Mirror-onyx+.png';
import NameOnyx from 'images/Name-onyx+.png';
import MirrorSoloInteractive from 'images/Mirror-solo-interactive.png';
import NameSoloInteractive from 'images/Name-solo-interactive.png';
import MirrorTitan from 'images/Mirror-titan.png';
import NameTitan from 'images/Name-titan.png';
import MirrorLaunch from 'images/Mirror-launch.png';
import NameLaunch from 'images/Name-launch.png';
import MirrorHandy from 'images/Mirror-handy.png';
import NameHandy from 'images/HANDY-logo.png';

import download from 'images/not-desktop/icons/download.svg';
import bluetooth from 'images/not-desktop/icons/bluetooth.svg';
import phone from 'images/not-desktop/icons/phone.svg';
import ai from 'images/not-desktop/icons/ai.svg';
import settings from 'images/not-desktop/icons/settings.svg';
import whatcher from 'images/not-desktop/icons/whatcher.svg';

import { translations } from 'i18n';

export const DEVICES = [
  {
    deviceImage: MirrorKeon,
    name: NameKeon,
    link: 'https://www.kiiroo.com/collections/interactive-masturbators/products/keon-by-kiiroo',
    company: 'Kiiroo',
    background: 'linear-gradient(181.5deg, #E6A9A7 -2.29%, #A65F5C 99.25%)',
    shadowColor: '#D0908D',
    borderColor: '#22345F',
  },
  {
    deviceImage: MirrorOnyx,
    name: NameOnyx,
    link: 'https://www.kiiroo.com/collections/interactive-masturbators/products/onyx-plus',
    company: 'Kiiroo',
    background: 'linear-gradient(181.5deg, #96C8F0 -2.29%, #4C92D0 99.25%)',
    shadowColor: '#83BAE8',
    borderColor: '#091218',
  },
  {
    deviceImage: MirrorTitan,
    name: NameTitan,
    link: 'https://www.kiiroo.com/products/titan-by-kiiroo-interactive-male-masturbator',
    company: 'Kiiroo',
    background: 'linear-gradient(181.5deg, #9FB68E -2.29%, #728165 99.25%)',
    shadowColor: '#94A984',
    borderColor: '#142137',
  },
  {
    deviceImage: MirrorSoloInteractive,
    name: NameSoloInteractive,
    link: 'https://www.kiiroo.com/collections/interactive-masturbators/products/pulse-solo-interactive',
    company: 'Kiiroo',
    background: 'linear-gradient(180deg, #D3A887 0%, #936039 100%)',
    shadowColor: '#D0908D',
    borderColor: '#22345F',
  },
  {
    deviceImage: MirrorLaunch,
    name: NameLaunch,
    company: 'Kiiroo',
    background: 'linear-gradient(181.5deg, #D4D4D6 -2.29%, #9596A0 99.25%)',
    shadowColor: '#C5C5C9',
    borderColor: '#112324',
  },
  {
    deviceImage: MirrorHandy,
    name: NameHandy,
    company: 'Sweet Tech',
    background: 'linear-gradient(181.5deg, #B29DCD -2.29%, #74589B 99.25%)',
    shadowColor: '#9B84BB',
    borderColor: '#2E4070',
  },
  // TODO uncomment when Toon align with Svakom about Feelme
  // {
  //   deviceImage: MirrorAlexNeo,
  //   name: NameAlexNeo,
  //   link: 'https://www.svakom.com/products/alex-neo',
  //   company: 'Svakom',
  //   background: 'linear-gradient(180deg, #59A782 0%, #297853 100%)',
  //   shadowColor: '#2D7352',
  //   borderColor: '#095A34',
  // },
  // {
  //   deviceImage: MirrorSamNeo,
  //   name: NameSamNeo,
  //   link: 'https://www.svakom.com/products/sam-neo',
  //   company: 'Svakom',
  //   background: 'linear-gradient(181.5deg, #E6A9A7 -2.29%, #A65F5C 99.25%)',
  //   shadowColor: '#D0908D',
  //   borderColor: '#22345F',
  // },
];

export const STEPS = [
  { image: download, text: translations('NotDesktopBanner.1step') },
  {
    image: bluetooth,
    text: translations('NotDesktopBanner.2step'),
  },
  {
    image: phone,
    text: translations('NotDesktopBanner.3step'),
  },
  {
    image: ai,
    text: translations('NotDesktopBanner.4step'),
  },
  {
    image: settings,
    text: translations('NotDesktopBanner.5step'),
  },
  {
    image: whatcher,
    text: translations('NotDesktopBanner.6step'),
  },
];
