import React, { createContext, useEffect, useState } from 'react';
import EventEmitter from 'events';

import { createHook } from 'utils/utils';

const ee = new EventEmitter();
const EVENT_FRAME_DATA = 'EVENT_PREDICTION';

const CHECK_PORTAL_TIMEOUT = 1000;
export const PORTAL_SEARCH_STATUS = {
  SEARCHING: 0,
  FOUND: 1,
  NOT_FOUND: -1,
};

const ChromeExtensionPopupContext = createContext();

const useChromeExtensionPopup = () =>
  createHook('useChromeExtensionPopup', ChromeExtensionPopupContext);

const ChromeExtensionPopupContextProvider = ({ children }) => {
  const [isPaused, setPaused] = useState(null);
  const [intensity, setIntensity] = useState(null);
  const [ambientMovement, setAmbientMovement] = useState(null);
  const [suctionMode, setSuctionMode] = useState(null);
  const [displaySuctionModeSlider, setDisplaySuctionModeSlider] = useState(false);
  const [bytesPerSecond, setBytesPerSecond] = useState(null);
  const [extensionVersion, setExtensionVersion] = useState(null);
  const [devices, setDevices] = useState(null);
  const [batteryPerDevice, setBatteryPerDevice] = useState(null);
  const [serverUrl, setServerUrl] = useState(null);
  const [fasterImageCompression, setFasterImageCompression] = useState(false);
  const [lowImageSize, setLowImageSize] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // Flag to indicate that we cannot see the portal open in the other tab
  const [portalStatus, setPortalStatus] = useState(PORTAL_SEARCH_STATUS.SEARCHING);
  const [autopilot, setAutopilot] = useState(false);
  const [testSignal, setTestSignal] = useState(false);
  const [whitelisted, setWhitelisted] = useState(false);

  const [broadcastChannel, setBroadcastChannel] = useState(null);
  const [portalRequestStatusInterval, setPortalRequestStatusInterval] = useState(null);

  const devicesWithSuctionSlider = ['Sam Neo'];

  const displayTestSignalSwitch = ['staging.feelme.com', 'localhost:3000'].includes(
    window.location.host,
  );

  // Discover portal presense
  useEffect(() => {
    if (!broadcastChannel) {
      // Broadcast channel is not set yet
      return;
    }

    if (portalStatus === PORTAL_SEARCH_STATUS.FOUND) {
      // Portal is detected
      return;
    }

    if (portalRequestStatusInterval) {
      // setTimeout it not yet fired, let's wait
      return;
    }

    broadcastChannel.postMessage({ what: 'UPDATE_REQ' });
    const interval = setTimeout(() => {
      // Indicate that it's time to try again
      setPortalRequestStatusInterval(null);
    }, 100);
    setPortalRequestStatusInterval(interval);
  }, [broadcastChannel, portalRequestStatusInterval, portalStatus]);

  useEffect(() => {
    // TODO move "extension-popup" and other consts to a common place
    const bc = new BroadcastChannel('extension-popup');
    setBroadcastChannel(bc);
    setTimeout(() => {
      setPortalStatus((oldValue) => {
        // If poratl not found within CHECK_PORTAL_TIMEOUT,
        // indicate it as NOT_FOUND
        return oldValue === PORTAL_SEARCH_STATUS.SEARCHING
          ? PORTAL_SEARCH_STATUS.NOT_FOUND
          : oldValue;
      });
    }, CHECK_PORTAL_TIMEOUT);

    return () => {
      bc.close();
    };
  }, []);

  useEffect(() => {
    if (!broadcastChannel) {
      return;
    }

    broadcastChannel.onmessage = (event) => {
      const { data } = event;
      if (data.what === 'FRAME') {
        ee.emit(EVENT_FRAME_DATA, data);
      } else if (data.what === 'SERVER2POPUP_UPDATE') {
        setPaused(data.isPaused);
        setIntensity(data.intensity);
        setAmbientMovement(autopilot ? 0 : data.ambientMovement);
        setExtensionVersion(data.extensionVersion);
        setTestSignal(data.testSignal);
        setDevices(data.devices);
        setBatteryPerDevice(data.batteryPerDevice);
        setServerUrl(data.serverUrl);
        setFasterImageCompression(data.fasterImageCompression);
        setLowImageSize(data.lowImageSize);
        setVideoUrl(data.contentUrl);
        setIsLoggedIn(data.isLoggedIn);
        setPortalStatus(PORTAL_SEARCH_STATUS.FOUND);
        setAutopilot(data.autopilot);
        setWhitelisted(data.whitelisted);
        setSuctionMode(data.suctionMode);
      } else if (data.what === 'SERVER2POPUP_BYTES_PER_SECOND') {
        setBytesPerSecond(data.bytesPerSecond);
      }
    };

    if (isPaused !== null && intensity !== null && ambientMovement !== null) {
      broadcastChannel.postMessage({
        what: 'POPUP2SERVER_UPDATE',
        isPaused,
        intensity,
        ambientMovement,
        autopilot,
        suctionMode,
        testSignal,
      });
    }
  }, [isPaused, intensity, ambientMovement, broadcastChannel, autopilot, suctionMode, testSignal]);

  // We could simply return last frame message value from this hook,
  // but it doesn't give 30FPS performance.
  // That's why we use subscribe/unsubscribe for synchronous callbacks.
  const subscribeFrameMessages = (callback) => {
    ee.on(EVENT_FRAME_DATA, callback);
    return () => {
      ee.removeListener(EVENT_FRAME_DATA, callback);
    };
  };

  useEffect(() => {
    if (!devices) {
      return;
    }
    setDisplaySuctionModeSlider(false);
    for (let i = 0; i < devices.length; i++) {
      if (devicesWithSuctionSlider.includes(devices[i].name)) {
        setDisplaySuctionModeSlider(true);
      }
    }
  }, [devices?.length]);

  return (
    <ChromeExtensionPopupContext.Provider
      value={{
        subscribeFrameMessages,
        isPaused,
        setPaused,
        intensity,
        setIntensity,
        ambientMovement,
        setAmbientMovement,
        bytesPerSecond,
        extensionVersion,
        portalStatus,
        devices,
        batteryPerDevice,
        serverUrl,
        lowImageSize,
        videoUrl,
        isLoggedIn,
        fasterImageCompression,
        autopilot,
        setAutopilot,
        testSignal,
        setTestSignal,
        whitelisted,
        suctionMode,
        setSuctionMode,
        displaySuctionModeSlider,
        displayTestSignalSwitch,
      }}
    >
      {children}
    </ChromeExtensionPopupContext.Provider>
  );
};

export { ChromeExtensionPopupContextProvider, useChromeExtensionPopup };
