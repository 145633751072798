import { translations } from 'i18n';
import cn from 'classnames';

import styles from './style.module.scss';
import Button from 'components/common/Button';

interface DeviceCardProps {
  deviceImage: string;
  name: string;
  link: string;
  namePadding?: boolean;
  background: string;
  shadowColor: string;
  borderColor: string;
}

const DeviceCard = ({
  deviceImage,
  name,
  link,
  namePadding,
  background,
  shadowColor,
  borderColor,
}: DeviceCardProps) => {
  return (
    <div className={styles.deviceContainer}>
      <div
        className={styles.imageWrapper}
        style={{ background, boxShadow: `0px 5px 10px ${shadowColor}`, borderColor }}
      >
        <img className={styles.deviceImage} src={deviceImage} alt="Logo" />
      </div>
      <div className={styles.bottomContainer}>
        <img className={cn(styles.name, namePadding && styles.namePadding)} src={name} alt="Logo" />
        {link ? (
          <Button
            onClick={() => window.open(link, '_blank', 'noopener, noreferrer')}
            text={translations('DevicesPage.GetDevice')}
          />
        ) : (
          // device is not in sale anymore, placeholder
          <div style={{ height: 48 }} />
        )}
      </div>
    </div>
  );
};

export default DeviceCard;
