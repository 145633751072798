import { Pendulum } from '.';

interface Prediction {
  destination: number;
  isBlowJob: boolean;
  period: number;
}

interface ServerPrediction {
  position: number;
  isBlowJob: boolean;
  timestampReceived: number;
  timestampSent: number;
}

/**
 * Processes predictions from a server and updates a pendulum instance
 * based on the period and duration of received signals.
 */
class PredictionProcessor {
  private periodStartTime: number | null = null;
  private periodEndTime: number = 0;
  private inPeriod: boolean = false;
  private pendulum: Pendulum;
  private predictionTimeout: number;
  private lastPredictionTime: number = 0;
  private timeoutCheckInterval: number = 5000; // Check every 5 seconds for timeout

  private isBlowJob: boolean = false;

  /**
   * Initializes the PredictionProcessor with a Pendulum instance and a prediction timeout.
   * @param {Pendulum} pendulumInstance - An instance of Pendulum.
   * @param {number} [predictionTimeout=5000] - The timeout in milliseconds for predictions.
   */
  constructor(pendulumInstance: Pendulum, predictionTimeout: number = 5000) {
    this.pendulum = pendulumInstance;
    this.predictionTimeout = predictionTimeout;
    setInterval(() => this.checkForTimeout(), this.timeoutCheckInterval);
  }

  /**
   * Processes a prediction received from the server.
   * @param {ServerPrediction} serverPrediction - The prediction object from the server.
   */
  run(serverPrediction: ServerPrediction): void {
    const { position, isBlowJob, timestampSent, timestampReceived } = serverPrediction;

    this.isBlowJob = isBlowJob;
    this.lastPredictionTime = timestampReceived;

    if (!this.inPeriod) {
      this.periodStartTime = timestampSent;
      this.inPeriod = true;
      return;
    }

    if (position === 0) {
      this.periodEndTime = timestampSent;
    }

    if (
      position === 1 &&
      this.periodStartTime !== null &&
      this.periodEndTime > this.periodStartTime
    ) {
      const periodDuration = this.periodEndTime - this.periodStartTime;
      const signalAge = timestampReceived - timestampSent;
      this.periodStartTime = timestampReceived;
      this.periodEndTime = 0;
      this.updatePendulumWithPeriodDuration(periodDuration, signalAge);
    }
  }

  /**
   * Returns the current prediction for the pendulum.
   * @returns {Prediction} The prediction object.
   */
  getPrediction(): Prediction {
    this.pendulum.updateTime(Date.now());
    const destination = this.pendulum.getDoWeTick() ? 1 : 0;
    return {
      destination,
      isBlowJob: this.isBlowJob,
      period: this.pendulum.period,
    };
  }

  /**
   * Updates the pendulum with the period duration and resets tracking variables.
   * @param {number} periodDuration - The duration of the period in milliseconds.
   * @param {number} [signalAge=0.0] - The age of the signal in milliseconds.
   */
  private updatePendulumWithPeriodDuration(periodDuration: number, signalAge: number = 0.0): void {
    this.pendulum.updatePendulum(periodDuration, signalAge);
    this.resetTrackingVariables();
  }

  /**
   * Resets the tracking variables for period calculation.
   */
  private resetTrackingVariables(): void {
    this.inPeriod = false;
    this.periodStartTime = null;
    this.periodEndTime = 0;
  }

  /**
   * Checks for prediction timeout and updates the pendulum accordingly.
   */
  private checkForTimeout(): void {
    const now = Date.now();
    if (
      this.lastPredictionTime &&
      now - this.lastPredictionTime > this.predictionTimeout &&
      this.inPeriod
    ) {
      const finalPeriodDuration =
        this.periodEndTime && this.periodEndTime > this.periodStartTime
          ? this.periodEndTime - this.periodStartTime
          : now - (this.periodStartTime ?? now);
      this.updatePendulumWithPeriodDuration(finalPeriodDuration);
    }
  }
}

export { PredictionProcessor };
