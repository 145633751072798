import { toast } from 'react-toastify';
import DeviceStateNotification from 'components/DeviceStateNotification';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import {
  CONNECTED_NOTIFICATIONS_OPTIONS,
  DISCONNECTED_NOTIFICATIONS_OPTIONS,
} from 'data/constants';
import { translations } from 'i18n';
import { updateDocumentTitle, displaySystemNotification } from 'utils/utils';

/**
 * Dictionary holding displayNames that deviate from the device.name
 * Devices not in this list will return their name as displayName.
 */
const deviceDisplayNames = {
  'Keon R2': 'KEON',
  'Keon R1': 'KEON',
  'SWK-S63E': 'Alex NEO 2',
  'SWK-S63E-A': 'Alex NEO 2',
  'SWK-SE63': 'Alex NEO 2',
  'S63E Alex NEO 2': 'Alex NEO 2',
  'SWK-SE63E': 'Alex NEO 2',
  'PowerBlow R1': 'PowerBlow',
};

export const getDeviceDisplayName = (deviceName) => {
  return deviceDisplayNames[deviceName] || deviceName;
};

export const onGattServerDisconnected = async (objRef) => {
  // Wait to detect how the device was disconnected (e.g., by user click or another way)
  await new Promise((r) => setTimeout(r, 100));
  if (objRef.connectionState === ConnectionState.DISCONNECTED) {
    // Disconnected by clicking "Disconnect" button
    return;
  }

  // all others way of disconnected event
  objRef.setConnectionState(ConnectionState.DISCONNECTED);
  console.log('all others way of disconnected event');

  if (document.visibilityState === 'hidden') {
    updateDocumentTitle(objRef, false);
    displaySystemNotification(objRef, false);
  }
  toast.dismiss();
  toast(
    <DeviceStateNotification device={objRef.device} state={ConnectionState.CONNECTING} />,
    DISCONNECTED_NOTIFICATIONS_OPTIONS,
  );
  // There is a bug from chromium where we cannot reconnect to the device after ~2,5 minutes of attempting
  // we need to reload the page
  // see details here https://bugs.chromium.org/p/chromium/issues/detail?id=681435
  const refreshPageAfter2Minutes = setTimeout(() => {
    window.location.reload();
  }, 140 * 1000);
  objRef
    .connect()
    .then((result) => {
      objRef.setConnectionState(ConnectionState.CONNECTED);

      if (document.visibilityState === 'hidden') {
        updateDocumentTitle(objRef, true);
        displaySystemNotification(objRef, true);
      }
      toast.dismiss();
      toast(
        <DeviceStateNotification device={objRef.device} state={ConnectionState.CONNECTED} />,
        CONNECTED_NOTIFICATIONS_OPTIONS,
      );
    })
    .catch((err) => {
      objRef.setConnectionState(ConnectionState.DISCONNECTED);
      if (document.visibilityState === 'hidden') {
        displaySystemNotification(objRef, false, translations('Notifications.ReconnectManually'));
      }
      toast.dismiss();
      toast(
        <DeviceStateNotification
          device={objRef.device.name}
          state={ConnectionState.DISCONNECTED}
        />,
        DISCONNECTED_NOTIFICATIONS_OPTIONS,
      );
    })
    .finally(() => {
      clearTimeout(refreshPageAfter2Minutes);
    });
};
