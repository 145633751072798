import React, { useCallback } from 'react';

import styles from './style.module.scss';

const GREEN_COLOR = '#82EFB8';
const ORANGE_COLOR = '#FA9F4E';
const RED_COLOR = '#FFB2A7';

const BatteryIcon = ({ percent }) => {
  const getBatteryColor = useCallback(() => {
    return percent > 40 ? GREEN_COLOR : percent > 15 ? ORANGE_COLOR : RED_COLOR;
  }, [percent]);

  return (
    <div className={styles.container}>
      <svg
        width="20"
        height="10"
        viewBox="0 0 20 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style={{ mixBlendMode: 'overlay' }} opacity="0.9">
          <path
            d="M19 3V3C19.5523 3 20 3.44772 20 4V6C20 6.55228 19.5523 7 19 7V7V3Z"
            fill={percent > 15 ? 'white' : RED_COLOR}
          />
        </g>
        <g style={{ mixBlendMode: 'overlay' }} opacity="0.9">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34783 0H15.6522C16.9488 0 18 1.11929 18 2.5V7.5C18 8.88071 16.9488 10 15.6522 10H2.34783C1.05116 10 0 8.88071 0 7.5V2.5C0 1.11929 1.05116 0 2.34783 0ZM2.34783 0.833333C1.48338 0.833333 0.782609 1.57953 0.782609 2.5V7.5C0.782609 8.42047 1.48338 9.16667 2.34783 9.16667H15.6522C16.5166 9.16667 17.2174 8.42047 17.2174 7.5V2.5C17.2174 1.57953 16.5166 0.833333 15.6522 0.833333H2.34783Z"
            fill={percent > 15 ? 'white' : RED_COLOR}
          />
        </g>
      </svg>
      <div
        className={styles.fill}
        style={{ backgroundColor: getBatteryColor(), width: Math.round((14 * percent) / 100) }}
      />
    </div>
  );
};

export default BatteryIcon;
