import React, { createContext, useState } from 'react';

import { createHook } from 'utils/utils';

const VideoPlayerContext = createContext();
const useVideoPlayer = () => createHook('useVideoPlayer', VideoPlayerContext);

const VideoPlayerContextProvider = ({ children }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [videoId, setVideoId] = useState(null);

  const openModalVideo = (id) => {
    setIsVideoOpen(true);
    setVideoId(id);
  };
  return (
    <VideoPlayerContext.Provider
      value={{
        isVideoOpen,
        setIsVideoOpen,
        videoId,
        openModalVideo,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export { VideoPlayerContextProvider, useVideoPlayer };
