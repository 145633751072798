import { sendAnalytics } from '@feelrobotics/ftap-connector';
import image from 'images/devices/sam-promotion.png';
import { sendToAnalytics } from 'lib/googleAnalytics';
import { shouldSendAnalytics } from 'utils/utils';
import { AsyncQueueIter } from '../../AsyncQueue';
import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import * as DeviceStates from './DeviceStates';

const SERVICE_UUIDS = [0xae00, 0xffe0, 0x180a];
const CHAR_UUID = 0xae01; // write without response
const CONFIRM_CHAR_UUID = 0xae02; // response

const IDLE_TIMEOUT_BEFORE_AUTO_CONTROL_MODE = 2000; // timeout in milliseconds before switching toy to ambient mode
const STOP_MOTORS = 0;
const CONSTANTLY_VIBRATION = 4;

const names = ['Sam Neo'];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class SvakomSamNeo extends BaseDeviceWrapper {
  constructor(device) {
    super(device, SERVICE_UUIDS, CHAR_UUID, image);
    this.sensorChar = null;
    this.commandQueue = new AsyncQueueIter();
    this.sensorEventListener = (args) => {
      // magic response: "value": [18, 128, 1, 71]
      // - start byte (0x12)
      // - device statuses (0x80)
      // - status type
      // - status value
      let dataView = args?.currentTarget?.value;
      if (dataView) {
        let value = new Uint8Array(dataView.buffer);
        if (value.length > 3 && value[1] === 128 && value[2] === 1) {
          this.batteryLevel = value[3];
        }
      }

      this.commandQueue.next();
    };

    this.maxIntensity = 100;
    this.suctionMode = 0;
    this.ambientIdleTimeout = null;

    this.statMotorIntensity = 0;
    this.statMotorMode = 0;
    this.statSuctionMode = 0;
    this.statAmbientMode = 0;

    this.batteryLevel = null;
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return SERVICE_UUIDS;
  }

  get companyName() {
    return 'Svakom';
  }

  async connect() {
    const startTime = performance.now();

    this.setConnectionState(DeviceStates.CONNECTING);
    this.server = await this.device.gatt.connect();
    for (const serviceUuid of SERVICE_UUIDS) {
      try {
        this.sensorService = await this.server.getPrimaryService(serviceUuid);
        this.serviceUuid = serviceUuid;
        break;
      } catch (e) {
        // Do nothing, go to the next service
      }
    }
    this.charUuid = CHAR_UUID;
    this.motorChar = await this.sensorService.getCharacteristic(this.charUuid);
    this.sensorChar = await this.sensorService.getCharacteristic(CONFIRM_CHAR_UUID);
    await this.sensorChar.startNotifications();
    this.sensorChar.addEventListener('characteristicvaluechanged', this.sensorEventListener);

    // Some firmware have suction and vibraiton on when device is turned on
    // Need to turn them off
    this.changeSuction(STOP_MOTORS);
    this.changeVibration(STOP_MOTORS);

    this.setConnectionState(DeviceStates.CONNECTED);

    // Report to google analytics
    const milliseconds = Math.round(performance.now() - startTime);
    sendToAnalytics('device_connect', 'device', this.device.name, milliseconds);
    shouldSendAnalytics() && sendToAnalytics('connect', this.device.name, this.device.id);
    sendAnalytics('connect', this.device.name, this.device.id);
  }

  async disconnect() {
    this.sensorChar.removeEventListener('characteristicvaluechanged', this.sensorEventListener);
    await this.sensorChar.stopNotifications();
    await super.disconnect();
  }

  /**
   * Send vibration command to device
   * @param {number} motorIntensity - Intensity vibration from 0-10
   * @param {number} motorMode - Vibration mode from 0-5:
   * 0: stop vibration
   * 1: _ _ _ _ _
   * 2: ... ___
   * 3: ....
   * 4: constantly vibration
   * 5: .... short with decreasing intensity
   */
  writeVibration(motorIntensity, motorMode) {
    const data = [0x12, 0x01, 0x03, 0x00, motorMode, motorIntensity];
    const array = new Uint8Array(data);
    this.commandQueue.add(async () => {
      await this.motorChar.writeValue(array);
    });
  }

  /**
   * Send saction command to device
   * @param {number} suctionMode - pattern number 0..5
   */
  writeSuctionMode(suctionMode) {
    const data = [0x12, 0x06, 0x01, suctionMode];
    const array = new Uint8Array(data);
    this.commandQueue.add(async () => {
      await this.motorChar.writeValue(array);
    });
  }

  /**
   * A method for completely stopping device activity.
   */
  async writePaused() {
    clearTimeout(this.ambientIdleTimeout);
    this.ambientIdleTimeout = null;

    this.changeVibration(STOP_MOTORS, STOP_MOTORS);
    this.changeSuction(STOP_MOTORS);
  }

  /**
   * Method for preventing duplicate vibration commands for a device
   *
   * @param {number} intensity - Intensity vibration from 0-10
   * @param {number} mode - Vibration mode from 0-5
   * @returns
   */
  async changeVibration(intensity, mode) {
    if (this.statMotorIntensity === intensity && this.statMotorMode === mode) {
      return;
    }

    let motorMode = STOP_MOTORS;
    let motorIntensity = STOP_MOTORS;
    if (intensity !== STOP_MOTORS) {
      motorMode = mode ?? CONSTANTLY_VIBRATION;
      motorIntensity = intensity;
    }

    this.statMotorMode = motorMode;
    this.statMotorIntensity = motorIntensity;
    this.writeVibration(motorIntensity, motorMode);
  }

  /**
   * Method for preventing duplicate suction commands for a device
   * @param {number} suctionMode - pattern number 0..5
   */
  async changeSuction(suctionMode) {
    if (this.statSuctionMode !== suctionMode) {
      this.statSuctionMode = suctionMode;
      this.writeSuctionMode(suctionMode);
    }
  }

  /**
   * Method for entering commands from the ML server
   * @param {number} percent - 0..100
   * @param {number} speed
   * @param {boolean} isBlowJob - true/false
   */
  async write(percent, speed, isBlowJob) {
    let _suctionMode = isBlowJob ? this.suctionMode : STOP_MOTORS;
    await this.changeSuction(_suctionMode);

    let intensity = 0;
    if (percent > 0) {
      clearTimeout(this.ambientIdleTimeout);
      this.ambientIdleTimeout = null;

      // Adjust the percent according to the max intensity
      const adjustedPercent = percent > 70 ? this.maxIntensity : 0;
      intensity = Math.round(adjustedPercent);
    } else {
      // no penetration -> set 0 to the device and start timeout to use Auto Control
      if (!this.ambientIdleTimeout) {
        this.ambientIdleTimeout = setTimeout(async () => {
          if (this.statAmbientMode) {
            this.changeVibration(this.maxIntensity, this.statAmbientMode);
            this.changeSuction(STOP_MOTORS);
          }
        }, IDLE_TIMEOUT_BEFORE_AUTO_CONTROL_MODE);
      }
    }
    this.changeVibration(intensity, CONSTANTLY_VIBRATION);
  }

  /**
   * Set the suction mode
   * @param {number} mode - Suction mode from 1-5
   */
  async setSuctionMode(mode) {
    this.suctionMode = parseInt(mode);
  }

  async setAmbientMovement(percent) {
    let ambientMode = Math.round(parseInt(percent) / 25);
    if (this.statAmbientMode === ambientMode) {
      return;
    }

    this.statAmbientMode = ambientMode;
    this.changeVibration(this.maxIntensity, this.statAmbientMode);
  }

  async setMaxIntensity(percent) {
    this.maxIntensity = Math.round(Math.max(0, Math.min(percent, 99) / 10));
    if (this.ambientIdleTimeout) {
      this.changeVibration(this.maxIntensity, this.statAmbientMode);
    }
  }

  async getBattery() {
    if (this.batteryLevel) {
      return this.batteryLevel;
    }
  }
}
