import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import styles from './style.module.scss';
import bleRuneImg from 'images/ble-rune.png';
import logoImg from 'images/logo.png';
import pauseImg from 'images/pause.png';
import playImg from 'images/play.png';
import gearImg from 'images/gear.png';
import moveImg from 'images/icon-move.png';
import FullscreenControls from '../FullscreenControls';
import FullscreenControlsInformation from '../FullscreenControlsInformation';
import BatteryIcon from 'components/common/BatteryIcon';

import { translations } from 'i18n';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

const FullscreenWidgetContainer = () => {
  const { devices, isPaused, setPaused, fasterImageCompression, lowImageSize, batteryPerDevice } =
    useChromeExtensionPopup();
  // use this value for rendering recently disconnected device
  const [prevDeviceImg, setPrevDeviceImg] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [shownInfo, setShownInfo] = useState(false);

  const displayInfo = useCallback(() => {
    setShownInfo(true);
  }, []);

  const hideInfo = useCallback(() => {
    setShownInfo(false);
  }, []);

  useEffect(() => {
    if (devices?.length === 1) {
      setPrevDeviceImg(devices[0].image);
    }
  }, [devices?.length]);

  // show either device image or default bluetooth rune image (for 0 or 2+ connected devices at the beginning)
  const getBluetoothImg = () => {
    if (devices?.length === 1) {
      return {
        src: devices?.[0]?.image,
        css: styles.deviceImg,
      };
    }

    if (devices?.length > 1) {
      return { src: bleRuneImg, css: styles.defaultImg };
    }
    return {
      src: prevDeviceImg || bleRuneImg,
      css: prevDeviceImg ? styles.deviceImg : styles.defaultImg,
    };
  };

  const togglePause = () => {
    if (!devices?.length) {
      return;
    }
    setPaused((prev) => !prev);
  };

  const toggleSettings = () => {
    setExpanded((prev) => !prev);
  };

  const closeSetting = () => {
    setExpanded(false);
  };

  // fullscreen widget is broken since Chrome 115
  return null;

  return (
    <div className={styles.container}>
      <div className={styles.topWidgetContainer}>
        <div className={styles.topWidgetMainContainer}>
          <div className={styles.imageContainer}>
            <img src={getBluetoothImg().src} alt="device" className={getBluetoothImg().css} />
            {batteryPerDevice?.length === 1 ? (
              <div className={styles.batteryContainer}>
                <BatteryIcon percent={batteryPerDevice[0].battery} />
              </div>
            ) : (
              <div
                className={cn(
                  styles.indicator,
                  devices?.length ? styles.connected : styles.disconnected,
                )}
              />
            )}
          </div>
          <div className={styles.logoContainer}>
            <img src={logoImg} alt="logo" className={styles.logoImg} />
            <p>
              {devices?.length
                ? translations('DevicesBox.Connected')
                : translations('DevicesBox.Disconnected')}
            </p>
          </div>
          <div className={styles.roundBtn} onClick={togglePause}>
            <img src={isPaused ? playImg : pauseImg} alt="logo" className={styles.pauseImg} />
          </div>
          <div className={cn(styles.roundBtn, expanded && styles.clicked)} onClick={toggleSettings}>
            <img src={gearImg} alt="logo" className={styles.gearImg} />
          </div>
          <div className={cn(styles.roundBtn, styles.moveBtn)}>
            <img src={moveImg} alt="logo" className={styles.gearImg} />
          </div>
        </div>
        {(fasterImageCompression || lowImageSize) && (
          <div className={styles.warningPart}>
            <div className={styles.bandwidthWarning}>
              {translations('ExtensionPopup.PoorInternetConnection')}
            </div>
          </div>
        )}
      </div>
      {expanded ? (
        <div className={styles.bottomContainer}>
          <div className={styles.triagle} />
          <div className={styles.expandedContainer}>
            {shownInfo ? (
              <FullscreenControlsInformation hideInfo={hideInfo} />
            ) : (
              <FullscreenControls closeSetting={closeSetting} displayInfo={displayInfo} />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FullscreenWidgetContainer;
