import { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

import { createHook, REMEMBER_ME_LOCAL_STORAGE_KEY, getRememberMeLocalStorage } from 'utils/utils';
import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';

const AuthContext = createContext();
const useAuth = () => createHook('useAuth', AuthContext);

const AuthContextProvider = ({ children }) => {
  let location = useLocation();
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [temporaryUser, setTemporaryUser] = useState(null);
  const [isChangePasswordRequired, setChangePasswordRequired] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const rememberMe = getRememberMeLocalStorage();

  useEffect(() => {
    Auth.configure({ storage: rememberMe ? localStorage : sessionStorage });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthenticatedUser(user);
        setChangePasswordRequired(user.challengeName === 'NEW_PASSWORD_REQUIRED');
      })
      .catch(() => {
        setAuthenticatedUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location]);

  useEffect(() => {
    if (authenticatedUser) {
      window.profitwell('start', { user_email: authenticatedUser.attributes?.email });
    }
  }, [authenticatedUser?.attributes?.email]);

  useEffect(() => {
    if (!authenticatedUser) {
      return;
    }

    const sendTokenToVideoWatcher = async () => {
      const msg = {
        what: 'token',
        payload: {
          redirectUrl: window.location.protocol + '//' + window.location.host + '/login',
          server: process.env.REACT_APP_BILLING_SERVER_API_URL ?? '',
        },
      };
      try {
        const xAccessToken = (await Auth.currentSession())?.getAccessToken().getJwtToken();
        msg.payload.xAccessToken = xAccessToken;
      } catch (error) {
        console.warn('sendTokenToVideoWatcher err', error);
      } finally {
        window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
      }
    };
    sendTokenToVideoWatcher();
  }, [authenticatedUser?.attributes?.email]);

  const signUp = async (email, password) => {
    const result = {
      error: undefined,
      response: {},
    };
    try {
      result.response = await Auth.signUp({
        username: email,
        password: password,
        autoSignIn: { enabled: true },
      });
    } catch (error) {
      result.error = error;
    }
    return result;
  };

  const confirmSignUp = async (email, code) => {
    const result = {
      error: undefined,
      response: {},
    };
    try {
      result.response = await Auth.confirmSignUp(email, code);
      sendToAnalyticsCustomEventObj('user_created_and_confirmed_account', { user_email: email });
    } catch (error) {
      result.error = error;
    }
    return result;
  };

  const resendSignUp = async (email) => {
    const result = {
      error: undefined,
      response: {},
    };
    try {
      result.response = await Auth.resendSignUp(email);
    } catch (error) {
      result.error = error;
    }
    return result;
  };

  const signIn = async (email, password, rememberMe) => {
    Auth.configure({ storage: rememberMe ? localStorage : sessionStorage });
    const cognitoUser = await Auth.signIn(email, password);
    setAuthenticatedUser(cognitoUser);
    localStorage.setItem(REMEMBER_ME_LOCAL_STORAGE_KEY, rememberMe || '');
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setChangePasswordRequired(true);
      setTemporaryUser(cognitoUser);
    } else {
      setTemporaryUser(null);
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    setAuthenticatedUser(null);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ what: 'logout_from_portal' }));
    localStorage.removeItem(REMEMBER_ME_LOCAL_STORAGE_KEY);
  };

  const deleteUser = async () => {
    if (!authenticatedUser) {
      // User is not authenticated
      return;
    }

    return new Promise((resolve, reject) => {
      authenticatedUser.deleteUser((error) => {
        if (error) {
          return reject(error);
        }
        setAuthenticatedUser(null);
        resolve(authenticatedUser.attributes.email);
      });
    });
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  const setPermanentPassword = async (permanentPassword) => {
    await Auth.completeNewPassword(temporaryUser, permanentPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        signUp,
        signIn,
        signOut,
        authenticatedUser,
        confirmSignUp,
        resendSignUp,
        isChangePasswordRequired,
        setChangePasswordRequired,
        deleteUser,
        changePassword,
        setPermanentPassword,
        setIsLoading,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuth };
