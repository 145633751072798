import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import image from 'images/devices/alex-promotion.png';
import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import { wait } from '../../../utils/wait';

const SERVICE_UUID = 0xffe0;
const CHAR_UUID = 0xffe1;

const WAIT_TIME_BETWEEN_COMMANDS = 208;

const names = ['Alex NEO', 'Alex Neo'];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class SvakomAlex extends BaseDeviceWrapper {
  constructor(device) {
    super(device, SERVICE_UUID, CHAR_UUID, image);
    this.ambientMovementMixin = new StrokerAmbientMovementMixin();

    // We need to keep track of the last movement to avoid sending the same command
    // previousMovement is an object with two properties: ongoing (boolean) and direction (string: up or down)
    this.previousMovement = { ongoing: false, direction: 'down' };
  }

  static get deviceNames() {
    return names;
  }

  /**
   * Needed to request access to these services before connecting to the device
   */
  static get services() {
    return [SERVICE_UUID];
  }

  get companyName() {
    return 'Svakom';
  }

  async connect() {
    await super.connect();
  }

  async disconnect() {
    await super.disconnect();
  }

  async writeMode(mode) {
    const data = [0x12, 0x01, 0x03, 0x00, mode, 0x00];
    const array = new Uint8Array(data);
    await this.motorChar.writeValue(array);
  }

  async writeToMotorChar(intensity) {
    // Mode could be 01, 02 or 03, or 0xFF if you want to stop the device
    const mode = intensity <= 0 ? 0xff : intensity < 33 ? 1 : intensity < 66 ? 2 : 3;
    await this.writeMode(mode);
  }

  async write(percent, speed, isBlowJob) {
    const direction = percent > 0 ? 'up' : 'down';

    if (this.previousMovement.ongoing && this.previousMovement.direction === 'up') {
      return;
    }
    this.previousMovement = { ongoing: true, direction: direction };
    setTimeout(() => {
      this.previousMovement.ongoing = false;
    }, WAIT_TIME_BETWEEN_COMMANDS);

    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    const ambientIntensity = this.ambientMovementMixin.ambientIntensity;

    // Adjust the percent according to the max intensity
    const adjustedPercent = (percent * maxIntensity) / 100;

    // If signal is below ambient movement threshold, vibrate it with ambient movement
    // intensity
    const percentValue = Math.max(ambientIntensity, Math.min(100, adjustedPercent));

    const intensity = Math.round((percentValue * 10) / 100);
    await this.writeToMotorChar(intensity);
  }

  async writePaused() {
    await this.writeToMotorChar(0);
  }

  async testDevice() {
    await this.writeToMotorChar(100);
    await wait(1000);
    await this.writePaused();
  }

  async setAmbientMovement(percent) {
    await this.ambientMovementMixin.setAmbientMovement(percent);
  }

  async setMaxIntensity(percent) {
    await this.ambientMovementMixin.setMaxIntensity(percent);
  }
}
