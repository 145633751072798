import EventEmitter from 'events';
import io from 'socket.io-client';

import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';
import { isTestArtificialNetworkDelays } from 'utils/isTesting';

const EVENT_PREDICTION = 'EVENT_PREDICTION';
const EVENT_CONNECTION_ERROR = 'EVENT_CONNECTION_ERROR';
const CONN_STATUS_CHECK_INTERVAL_SEC = 2;

export type ServerPrediction = {
  // timestamp when the request was sent (not received!)
  timestamp: number;
  // Frame index
  frameIndex: number;
  // 0-100 value to be sent to the device
  funscript_decision: number;
  // same as funscript_decision
  engine_position: number;
  // flag indicating if it's a blowjob
  is_blowjob: number;
  // Flag indicating if it's in finish mode (1) or not (0)
  finish_mode: number;
  // If future prediction module detects the future movement,
  // then it's a sinusoid movement period in milliseconds
  period: number;
};

export type ServerPredictionCallback = (prediction: ServerPrediction) => void;

export const connectToServer = (serverUrl: string, token?: string, oauth_token?: string) => {
  const ee = new EventEmitter();

  const auth = {
    ...(token && { token }),
    ...(oauth_token && { Authorization: `Bearer ${oauth_token}` }),
  };

  console.log(`auth: ${JSON.stringify(auth)}`);

  const connection = io(serverUrl, {
    reconnection: true,
    reconnectionAttempts: 3,
    reconnectionDelay: 1000,
    withCredentials: true,
    transports: ['websocket'],
    auth,
  });
  const pendingRequestTimestamps = {};

  const queue = [];
  const Q = 1240;
  connection.on('PREDICT', (data) => {
    if (isTestArtificialNetworkDelays()) {
      queue.push(data);
      const cnt = Math.max(0, (queue.length + Q) * Math.random() - Q);
      for (let i = 0; i < cnt; i++) {
        const data2 = queue.shift();
        const { frameIndex } = data2;
        const timestamp = pendingRequestTimestamps[frameIndex];
        delete pendingRequestTimestamps[frameIndex];
        const prediction: ServerPrediction = {
          timestamp,
          ...data2,
        };

        ee.emit(EVENT_PREDICTION, prediction);
      }
    } else {
      const { frameIndex } = data;
      const timestamp = pendingRequestTimestamps[frameIndex];
      delete pendingRequestTimestamps[frameIndex];
      const prediction: ServerPrediction = {
        timestamp,
        ...data,
      };
      ee.emit(EVENT_PREDICTION, prediction);
    }
  });

  connection.on('TESTRESP', (data) => {
    const { frameIndex } = data;
    const timestamp = pendingRequestTimestamps[frameIndex];
    delete pendingRequestTimestamps[frameIndex];
    const prediction: ServerPrediction = {
      timestamp,
      ...data,
    };
    ee.emit(EVENT_PREDICTION, prediction);
  });

  connection.on('connect_error', (err) => {
    console.log(`connect_error with ${serverUrl} due to ${err.message}`);
    connection.connect();
    sendToAnalyticsCustomEventObj('socket_connect_error', { server_url: serverUrl });
  });

  connection.on('connect_timeout', (timeout) => {
    console.error(`Connection with ${serverUrl} timeout:`, timeout);
  });

  connection.on('error', (error) => {
    console.error('Socket error with ${serverUrl}:', error);
  });

  connection.on('disconnect', (reason) => {
    console.log(`Disconnected with ${serverUrl} due to: ${reason}`);
  });

  connection.on('reconnect_attempt', (attemptNumber) => {
    console.log(`Attempting to reconnect (attempt ${attemptNumber}) with ${serverUrl}`);
  });

  connection.on('reconnect', (attemptNumber) => {
    console.log(`Successfully reconnected after ${attemptNumber} attempts with ${serverUrl}`);
  });

  connection.on('reconnect_failed', () => {
    console.log('Reconnection failed with ${serverUrl}');
  });

  // Check websocket connection to the server
  // every CONN_STATUS_CHECK_INTERVAL_SEC seconds
  // and notify subscribers if connection is lost
  const connectionCheckInterval = setInterval(() => {
    if (connection.connected) {
      return;
    }
    connection.connect();
    ee.emit(EVENT_CONNECTION_ERROR);
  }, CONN_STATUS_CHECK_INTERVAL_SEC * 1000);

  const subscribeServerPredictions = (callback: ServerPredictionCallback) => {
    ee.on(EVENT_PREDICTION, callback);
    return () => {
      ee.removeListener(EVENT_PREDICTION, callback);
    };
  };

  const subscribeConnectionError = (callback) => {
    ee.on(EVENT_CONNECTION_ERROR, callback);
    return () => {
      ee.removeListener(EVENT_CONNECTION_ERROR, callback);
    };
  };

  const requestServerPrediction = (request, isNewServer: boolean = false) => {
    pendingRequestTimestamps[request.frameIndex] = Date.now();
    // Send data to the server
    if (isNewServer) {
      connection.emit('recognition', request);
    } else {
      connection.emit('REQ', request);
    }
  };

  const requestTestServerPrediction = (request) => {
    pendingRequestTimestamps[request.frameIndex] = Date.now();
    // Send data to the server
    connection.emit('TESTREQ', request);
  };

  const closeConnection = () => {
    clearInterval(connectionCheckInterval);
    connection.close();
  };

  const onConnected = (callback) => {
    connection.on('connect', callback);
  };

  return {
    subscribeServerPredictions,
    subscribeConnectionError,
    requestServerPrediction,
    requestTestServerPrediction,
    closeConnection,
    onConnected,
  };
};
