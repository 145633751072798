import { translations } from 'i18n';
import camsites from 'images/content-channels/categories/camsites.png';
import free from 'images/content-channels/categories/free.png';
import hentai from 'images/content-channels/categories/hentai.png';
import pov from 'images/content-channels/categories/pov.png';
import premium from 'images/content-channels/categories/premium.png';
import vr from 'images/content-channels/categories/vr.png';

export const APP_TITLE = 'FeelMe AI';

export const ROUTE_PATHS = {
  SIGN_UP: '/signup',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_EMAIL: '/confirm-email',
  CONFIRM_EMAIL_FORM: '/confirm-form',
  RESEND_CONFIRMATION_EMAIL: '/resend-confirmation-email',
  RECOVERY_PASSWORD: '/recovery-password',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CANCELLATION_POLICY: '/cancellation-policy',
  DASHBOARD: '/dashboard',
  CONNECTION: '/connection',
  ACCOUNT: '/account',
  FAQ: '/faq',
  CONTACT_US: '/contact-us',
  CHOOSE_YOUR_SUBSCRIPTION: '/choose-your-subscription',
  CONTENT_CHANNELS: '/channels-content',
  DEVICES: '/devices',
  INBOX: '/inbox',
  SETTINGS: '/settings',
};

export const MODAL_STYLES = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: '1px solid',
    borderImage:
      'linear-gradient(45deg, #f99e4d 0%, #c9b2a2 22%, #9b9eb9 75%, #8ab2ff 96%) 1 / 2px stretch',
    background: 'none',
    borderWidth: 2,
    borderRadius: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '999998',
    overflow: 'scroll',
  },
};

export const CUSTOMER_SUPPORT_MODAL = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '800px',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none !important',
    background: 'none',
    borderWidth: 0,
    borderRadius: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '999998',
    overflow: 'scroll',
  },
};

export const SMALL_MODAL_STYLES = {
  content: {
    ...MODAL_STYLES.content,
    left: '61%',
    top: '50%',
  },
  overlay: {
    backgroundColor: 'transparent',
    overflow: 'scroll',
  },
};

export const GET_STARTED_MODAL_STYLES = {
  content: {
    ...MODAL_STYLES.content,
    borderImage:
      'linear-gradient(21.47deg, rgba(255, 203, 141, 1) -2.89%, rgba(201, 178, 162, 0.2398) 35.89%, rgba(227, 184, 137, 0.65) 69.27%, rgba(227, 184, 137, 1) 92.58%) 1 / 2px stretch',
  },
  overlay: MODAL_STYLES.overlay,
};

export const INCOGNITO_MODE_MODAL_STYLES = {
  content: {
    ...MODAL_STYLES.content,
    top: '20px',
    transform: 'translate(-50%, 0%)',
  },
  overlay: MODAL_STYLES.overlay,
};

export const LONG_MODAL_STYLES = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -12%)',
    padding: 0,
    border: '1px solid',
    borderImage:
      'linear-gradient(45deg, #f99e4d 0%, #c9b2a2 22%, #9b9eb9 75%, #8ab2ff 96%) 1 / 2px stretch',
    background: 'none',
    borderWidth: 2,
    borderRadius: 0,
    blockSize: 'fit-content',
    width: 'min-content',
  },
  overlay: MODAL_STYLES.overlay,
};

export const TOP_RIGHT_MODAL_STYLES = {
  content: {
    width: '500px',
    top: '20px',
    left: 'auto',
    right: '20px',
    bottom: 'auto',
    padding: 0,
    border: '2px solid',
    borderImage:
      'linear-gradient(21.47deg, #ffcb8d -2.89%, rgba(201, 178, 162, 0.239846) 35.89%, rgba(155, 158, 185, 0.65) 69.27%, #8DB4FF 92.58%) 1 / 2px stretch',
    background:
      'linear-gradient(125.97deg, rgba(255, 255, 255, 0.4) 9.19%, rgba(255, 255, 255, 0.1) 72.55%)',
    overflow: 'auto',
  },
  overlay: MODAL_STYLES.overlay,
};

export const CHARGEBEE_SUB_STATUSES = {
  IN_TRIAL: 'in_trial',
  ACTIVE: 'active',
  NON_RENEWING: 'non_renewing',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
};

export const SUB_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NEVER: 'NEVER',
};

export const extensionLink =
  process.env.REACT_APP_CHROME_EXTENSION_URL ||
  'https://chrome.google.com/webstore/detail/video-watcher/bopfahfhdfcbkjalkkdibdhkbjfcnnlm?hl=en&authuser=2';

export const DOWNLOAD_CHROME_LINK = 'https://www.google.com/chrome/';

export const AUTH_CODE_INPUT_LENGTH = 6;

export const KIIROO_URL =
  'https://www.kiiroo.com/collections/compare-feelme-ai?utm_source=feelme&utm_medium=portal&utm_campaign=buy';

export const VIDEO_GUIDE_ID = '812849475';
export const VIDEO_GUIDE_MOBILE_ID = '812851996';
export const VIDEO_KEON_ID = '905592277';

export const passwordStrengthOptions = [
  {
    id: 0,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 1,
    value: 'Medium',
    minDiversity: 4,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Strong',
    minDiversity: 4,
    minLength: 8,
  },
];

export const requestTypeOptions = [
  { id: 174, label: 'General Enquiry', translate: 'ContactUsPage.GeneralEnquiry' },
  { id: 175, label: 'Sign Up/Login', translate: 'ContactUsPage.SignUpLogin' },
  {
    id: 176,
    label: 'Subscription Plans & Upgrades',
    translate: 'ContactUsPage.SubscriptionPlans&Upgrades',
  },
  { id: 177, label: 'Video Watcher Plugin', translate: 'ContactUsPage.VideoWatcherPlugin' },
  { id: 178, label: 'Accounts & Billing', translate: 'ContactUsPage.Accounts&Billing' },
  { id: 179, label: 'Adult Toys', translate: 'ContactUsPage.AdultToys' },
  { id: 180, label: 'Technical Issues', translate: 'ContactUsPage.TechnicalIssues' },
  { id: 171, label: 'Report a bug', translate: 'ContactUsPage.ReportABug' },
  // TODO fix two options with the same id 182
  { id: 182, label: 'Videos', translate: 'ContactUsPage.Videos' },
  { id: 182, label: 'Cancellations & Refunds', translate: 'ContactUsPage.Cancellations&Refunds' },
  { id: 183, label: 'Security & Privacy', translate: 'ContactUsPage.Security&Privacy' },
  { id: 172, label: 'Suggest a new feature', translate: 'ContactUsPage.SuggestANewFeature' },
  { id: 184, label: 'Whitelist Website', translate: 'ContactUsPage.WhitelistWebsite' },
  { id: 169, label: 'Other questions', translate: 'ContactUsPage.OtherQuestions' },
];

export const VIDEOWATCHER_APP_NAME = 'VideoWatcher';

export const AFFILIATES_REF_KEY = 'affiliates-code';

export const USER_ID_KEY = 'user-id';

export const CART_PLAN_ID_KEY = 'cart-plan-id';

export const CART_PLAN_ID_EXPIRATION_PERIOD_IN_HOURS = 24;

export const AFFILIATES_REF_EXPIRATION_PERIOD_IN_DAYS = 60;

export const SETUP_GUIDE_URL =
  'https://cdn.shopify.com/s/files/1/2331/0997/files/PDF_Manual_FeelMe_AI-V5.pdf?v=1680094082';

export const SETUP_GUIDE_MOBILE_URL =
  'https://cdn.shopify.com/s/files/1/2331/0997/files/PDF_Manual_Video_Watcher-V5.pdf?v=1680094082';

// token update timeout (4hrs)
export const UPDATE_TOKEN_TIME = 1000 * 60 * 60 * 4;

// 8 hours timeout to logout user in case of inactivity
export const IDLE_TIMEOUT = 1000 * 60 * 60 * 8;

// threshold for battery notification
export const BATTERY_THRESHOLD_WARNING = 15;

export const BATTERY_NOTIFICATIONS_OPTIONS = {
  position: 'top-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: true,
  icon: false,
};

export const SETTINGS_OPTIONS = {
  ...BATTERY_NOTIFICATIONS_OPTIONS,
  autoClose: 5000,
};

export const CONNECTED_NOTIFICATIONS_OPTIONS = {
  type: 'success',
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  icon: false,
  draggable: true,
};

export const DISCONNECTED_NOTIFICATIONS_OPTIONS = {
  type: 'warning',
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  icon: false,
  closeOnClick: true,
  draggable: true,
};

export const SHOW_MAX_NOTIFICATION_DESCRIPTION = 150;
export const SHOW_MAX_NOTIFICATION_TITLE = 65;
export const MAX_REQUEST_WHITELIST_AMOUNT = 5;

export const FIXED_OLD_PRICE_KEON_SUBSCRIPTION = 27999;

export const OLD_SUBSCRIPTIONS_PRICES = [1499.0, 5999.0, 11999.0, 49999.0];

export const DEVICE_FEELME_PLAN_TITLE = 'Device-FeelMe';
export const FEELME_PLAN_TITLE = 'FeelMe';

export const appstoreUrl = 'https://apps.apple.com/us/app/video-watcher-browser/id6444533019';
export const playstoreUrl = 'https://play.google.com/store/apps/details?id=com.videowatchermobile';

export const CORS_DOMAINS = [
  'members.nubiles-porn.com',
  'members.nubiles.net',
  'members.anilos.com',
  'members.nubilefilms.com',
  'members.brattysis.com',
  'members.nfbusty.com',
  'members.nubiles-porn.com',
  'members.deeplush.com',
  'members.blacked.com',
  'members.tushy.com',
  'members.vixen.com',
  'members.blackedraw.com',
  'members.tushyraw.com',
  'members.deeper.com',
  'members.slayed.com',
  'members.vip4k.com',
  'www.porntube.com',
  'faperoni.com',
  'www.fapnado.com',
  'hypnotube.com',
];

export const MAX_HOSTNAME_LENGTH = 18;

export const MAX_SHORT_NOTIFICATION_HEIGHT = 900;

export const categoriesItems = [
  { image: camsites, title: 'Camsites', isUpper: false },
  { image: free, title: 'Free', isUpper: false },
  { image: hentai, title: 'Hentai', isUpper: false },
  { image: pov, title: 'Pov', isUpper: true },
  { image: premium, title: 'Premium', isUpper: false },
];

export const CONNECTION_WAY = {
  WIFI: 'WIFI',
  BT: 'BT',
};

export const HANDY_APP_URL = 'https://handyfeeling.com/remote';
export const AUTOBLOW_APP_URL = 'https://autoblowme.com/autoblow-ai/online-setup';

// allow Portal for 'Chrome',
// display "Download VWB" banner for 'Mobile Safari', 'MIUI Browser', 'Samsung Browser' and mobile 'Chrome'
export const NON_CHROME_DESKTOP_BROWSERS = [
  'Firefox',
  'Opera',
  'Yandex',
  'Safari',
  'Internet Explorer',
  'Edge',
  'Chromium',
  'IE',
  'Edge Chromium',
];

export const CHROME_DESKTOP_BROWSER_NAME = 'Chrome';

export const TEXT_INPUT_ITEMS = [
  { label: 'Low', position: 0 },
  { label: 'Medium', position: 50 },
  { label: 'High', position: 100 },
];

export const PERCENT_INPUT_ITEMS = [
  { label: '0%', position: 0 },
  { label: '25%', position: 25 },
  { label: '50%', position: 50 },
  { label: '75%', position: 75 },
  { label: '100%', position: 100 },
];

// PowerBlow default settings
export const POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY = 'powerblow_settings';
export const POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY = 'powerblow_is_default_on';

export const DEFAULT_POWERBLOW_SETTINGS = {
  suctionPower: 90, // %
  suctionTime: 500, // msec
  vacuumHold: 500, // msec
  pauseTime: 500, // msec
  ambientMovement: 0, // off
};

// hide general overlay for these wizard steps
export const HIDE_OVERLAY_STEPS = [1, 2, 5];

export const SUBSCRIPTIONS_PLANS_BENEFITS = {
  Lifetime: [
    translations('SubscriptionPage.TenDaysTrial'),
    translations('SubscriptionPage.AdditionalFreeFeelStarStroker'),
    ,
    translations('SubscriptionPage.AccesToFreePremiumContent'),
  ],
  Yearly: [
    translations('SubscriptionPage.TenDaysTrial'),
    translations('SubscriptionPage.AdditionalFreeFeelStarStroker'),
    ,
  ],
  '6-Monthly': [translations('SubscriptionPage.TenDaysTrial')],
};
