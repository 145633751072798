import { BaseDeviceWrapper } from './BaseDeviceWrapper';
// TODO change to Launch image
import image from 'images/devices/launch-promotion-blurred-logo.png';
import StrokerAmbientMovementMixin from './StrokerAmbientMovementMixin';
import StrokerFinishModeMixin from './StrokerFinishModeMixin';

const serviceUuid = '88f80580-0000-01e6-aace-0002a5d5c51b';
const commandUuid = '88f80583-0000-01e6-aace-0002a5d5c51b';
const characteristicUuid = '88f80581-0000-01e6-aace-0002a5d5c51b';

const names = ['Launch'];

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Launch extends BaseDeviceWrapper {
  constructor(device) {
    super(device, serviceUuid, characteristicUuid, image);
    this.ambientMovementMixin = new StrokerAmbientMovementMixin();
    this.finishModeMixin = new StrokerFinishModeMixin();
    this.currentPosition = 0;
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return 'Kiiroo';
  }

  async connect() {
    await super.connect();
    this.commandChar = await this.sensorService.getCharacteristic(commandUuid);
    // Move Launch to the movement mode
    await this.commandChar.writeValue(new Uint8Array([0x00]));
  }

  async disconnect() {
    await super.disconnect();
  }

  async moveTo(position, speed) {
    const pos = Math.max(0, Math.min(99, Math.round(position)));
    const spd = Math.max(0, Math.min(99, Math.round(speed)));
    this.deviceMovementCallback({ position: pos, speed: spd });
    await this.motorChar.writeValue(new Uint8Array([pos, spd]));
  }

  async write(percent, speed, isBlowJob) {
    if (this.currentPosition === percent) {
      // Don't write the same value to the device twice
      return;
    }
    this.currentPosition = percent;
    const maxIntensity = this.ambientMovementMixin.maxIntensity;
    percent = 100 - percent; // Device min position is on top, max - on bottom
    let pos = Math.min(100, Math.round((percent * maxIntensity) / 100));
    const spd = Math.round((speed * maxIntensity) / 100);

    await this.moveTo(pos, speed);
    this.ambientMovementMixin.onWrite();
  }

  async setAmbientMovement(percent) {
    this.ambientMovementMixin.setAmbientMovement(percent);
  }

  async setMaxIntensity(percent) {
    this.ambientMovementMixin.setMaxIntensity(percent);
  }

  async onTimer() {
    await this.ambientMovementMixin.onTimer((pos, speed) => {
      this.moveTo(pos, speed);
    });
    await this.finishModeMixin.onTimer();
  }
}
