import { createContext, useEffect, useState, useRef } from 'react';
import { connectToServer } from '../lib/serverModel';
import { useMLPerformance } from 'hooks/MLPerformanceHook';
import { useSubscription } from 'hooks/SubscriptionHook';
import { useWebsiteCompatibility } from './WebsiteCompatibilityHook';
import { createHook } from 'utils/utils';
import { toast } from 'react-toastify';
import { translations } from 'i18n';
import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';
import { useBandwidth } from './BandwidthHooks';
import { useConnectedDevices } from './ConnectedDevicesHook';
import { CC_WSS_URL_LOCAL_STORAGE_KEY } from 'api/oauth-api'; 

const toBase64 = (bytes) => {
  let binary = '';
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const additionalMlServersString = process.env.REACT_APP_ADDITIONAL_ML_SERVERS;

let additionalMlServersArray = [];
if (additionalMlServersString) {
  let additionalMlServers = {};
  try {
    additionalMlServers = JSON.parse(additionalMlServersString);
    additionalMlServersArray = Object.entries(additionalMlServers).map(([name, value]) => ({
      name,
      value,
    }));
  } catch (error) {
    console.error('REACT_APP_ADDITIONAL_ML_SERVERS:', additionalMlServersString);
    console.error('Incorrect or missing parameter:', error);
  }
}

const ccWsServerUrl = localStorage.getItem(CC_WSS_URL_LOCAL_STORAGE_KEY);
if (ccWsServerUrl) {
  additionalMlServersArray.push({
    name: 'NEW',
    value: ccWsServerUrl,
  });
}

export const SERVERS = [
  { name: 'US', value: 'https://mlserver.feelme.com' },
  { name: 'EU', value: 'https://mlserver-eu.feelme.com' },
  ...additionalMlServersArray,
];

const SERVER_LOCAL_STORAGE_KEY = 'ml-server_v2';
const AUTOPILOT_MODE_STORAGE_KEY = 'ml-autopilot-mode';
const TEST_MODE_STORAGE_KEY = 'ml-test-mode';
const ON_VALUE = 'on';

const PredictionContext = createContext();

export const usePrediction = () => createHook('usePrediction', PredictionContext);

export const PredictionContextProvider = ({ children }) => {
  const [connection, setConnection] = useState(null);
  // Value predicted by the network
  const [predict, setPredict] = useState(0);
  // Current frame number
  const [frameNo, setFrameNo] = useState(0);
  const [frameData, setFrameData] = useState([]);
  const [frameUrl, setFrameUrl] = useState('');
  const { onFrame, onMLServerMessage } = useMLPerformance();
  const [serverUrl, setServerUrl] = useState(
    localStorage.getItem(SERVER_LOCAL_STORAGE_KEY) || SERVERS[0].value,
  );
  const [autopilot, setAutopilot] = useState(
    localStorage.getItem(AUTOPILOT_MODE_STORAGE_KEY) === ON_VALUE,
  );
  const [testSignal, setTestSignal] = useState(
    localStorage.getItem(TEST_MODE_STORAGE_KEY) === ON_VALUE,
  );
  const { tokenObj, accessToken } = useSubscription();
  const { token } = tokenObj || {};

  const { logSentSize } = useBandwidth();

  const { checkCompatibility } = useWebsiteCompatibility();
  const [isCompatible, setIsCompatible] = useState(false);
  const [isConnectedToMLServer, setIsConnectedToMLServer] = useState(false);

  const toastId = useRef(null);

  const { getConnectedDevices } = useConnectedDevices();
  const connectedDevices = getConnectedDevices();

  useEffect(() => {
    // Save server URL to local storage on change
    if (!serverUrl) {
      return;
    }
    localStorage.setItem(SERVER_LOCAL_STORAGE_KEY, serverUrl);
  }, [serverUrl]);

  useEffect(() => {
    setIsCompatible(checkCompatibility(frameUrl));
  }, [frameUrl, checkCompatibility]);

  useEffect(() => {
    // If autopilot mode is ON, we keep 'on' value in the AUTOPILOT_MODE_STORAGE_KEY key.
    // If autopilot mode is OFF, we remove the key. This is the default value.
    if (autopilot) {
      localStorage.setItem(AUTOPILOT_MODE_STORAGE_KEY, ON_VALUE);
    } else {
      localStorage.removeItem(AUTOPILOT_MODE_STORAGE_KEY);
    }
  }, [autopilot]);

  useEffect(() => {
    // If test mode is ON, we keep 'on' value in the TEST_MODE_STORAGE_KEY key.
    // If test mode is OFF, we remove the key. This is the default value.
    if (testSignal) {
      localStorage.setItem(TEST_MODE_STORAGE_KEY, ON_VALUE);
    } else {
      localStorage.removeItem(TEST_MODE_STORAGE_KEY);
    }
  }, [testSignal]);

  useEffect(() => {
    if (!serverUrl || !token) {
      return;
    }

    const start = async () => {
      setIsConnectedToMLServer(false);
      const serverConnection = connectToServer(serverUrl, token, accessToken);
      setConnection(serverConnection);

      serverConnection.onConnected(() => {
        setIsConnectedToMLServer(true);

        toast.dismiss(toastId.current);
        toastId.current = null;
      });

      serverConnection.subscribeConnectionError(() => {
        setIsConnectedToMLServer(false);
        // display notification only once for each disconnection period,
        // until successful reconnection or user dismisses it
        if (!toastId.current) {
          toastId.current = toast.info(translations('Error.ReconnectingToServer'), {
            autoClose: false,
          });
        }
      });

      /**
       * Process a video frame
       * @param {obj} payload - message payload, containing the frame and its dimentions
       */
      const handleFrameData = async (payload) => {
        if (!payload?.webp) {
          return;
        }

        const { index, webp, url } = payload;
        setFrameNo(index);
        setFrameUrl(url);
        setFrameData(webp);
        onFrame(index, performance.now());

        // Stop function and history recording when an unsupported website is detected.
        if (!isCompatible) {
          return;
        }

        const msg = {
          frameIndex: index,
          image: toBase64(webp),
          autopilot: autopilot ? 1 : 0,
        };
        const msgSize = JSON.stringify(msg).length;
        logSentSize(index, msgSize);

        // Test frames do not use the resources of the ML server and can be processed even if there are no connected devices.
        if (testSignal) {
          serverConnection.requestTestServerPrediction(msg);
        } else {
          if (connectedDevices.length) {
            const ccWsServerUrl = localStorage.getItem(CC_WSS_URL_LOCAL_STORAGE_KEY);
            const isNewMlServer = ccWsServerUrl == serverUrl;
            serverConnection.requestServerPrediction(msg, isNewMlServer);
          }
        }
      };

      let prevIdx = 0;
      const unsubscribeFromPredictions = serverConnection.subscribeServerPredictions(
        ({ frameIndex, engine_position: percent }) => {
          if (prevIdx === frameIndex) {
            // This frame can be ignored
            // Could be caused by video FPS lower than 30.
            return;
          }
          prevIdx = frameIndex;
          onMLServerMessage(frameIndex, performance.now());
          setPredict(percent);
          const { pos, speed } = window.keonState || {};
          const timestamp = Date.now();
          // TODO fix it. It crashes after adding short strokes funtionality
          // updateHistoryWithPrediction(frameIndex, frameUrl, percent, speed, pos, timestamp);
        },
      );

      const listener = (event) => {
        // On every message from the Chrome extension content script
        // We only accept messages from ourselves
        if (event.source !== window) {
          return;
        }
        const { data } = event;
        const { payload, what } = data;
        if (!payload || what !== 'frame') {
          return;
        }
        handleFrameData(payload);
      };
      window.addEventListener('message', listener, false);

      return () => {
        // Return cleanup function
        unsubscribeFromPredictions();
        window.removeEventListener('message', listener);
        serverConnection.closeConnection();
      };
    };
    const initializationPromise = start();
    return async () => {
      const cleanup = await initializationPromise;
      cleanup();
    };
  }, [
    serverUrl,
    token,
    accessToken,
    frameUrl,
    autopilot,
    testSignal,
    isCompatible,
    connectedDevices.length,
  ]);

  useEffect(() => {
    sendToAnalyticsCustomEventObj('website_connected', { hostname: frameUrl });
  }, [frameUrl]);

  return (
    <PredictionContext.Provider
      value={{
        predict,
        frameNo,
        frameData,
        frameUrl,
        serverUrl,
        setServerUrl,
        testSignal,
        setTestSignal,
        autopilot,
        setAutopilot,
        isCompatible,
        connection,
        isConnectedToMLServer,
      }}
    >
      {children}
    </PredictionContext.Provider>
  );
};
