import React from 'react';
import { translations } from 'i18n';
import styles from './style.module.scss';

export const PortalNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.backdrop} />
      <h1>{translations('ExtensionPopup.NoPortalTitle')}</h1>
      <p>
        {translations('ExtensionPopup.NoPortalTextPart1')}{' '}
        <a
          href={'https://' + window.location.hostname}
          className={styles.host}
          target="_blank"
          rel="noopener noreferrer"
        >
          {window.location.hostname}
        </a>{' '}
        {translations('ExtensionPopup.NoPortalTextPart2')}
      </p>
    </div>
  );
};
