import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import image from 'images/devices/powerblow-promotion.png';
import {
  DEFAULT_POWERBLOW_SETTINGS,
  POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY,
  POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY,
} from 'data/constants';

const SERVICE_UUID: BluetoothServiceUUID = 0x1400;

// The motor reduces the air pressure
const MOTOR_UUID: BluetoothCharacteristicUUID = 0x1401;
// The solenoid releases the air
const SOLENOID_UUID: BluetoothCharacteristicUUID = 0x1402;

const BATTERY_SERVICE_UUID: BluetoothServiceUUID = 0x180f;
const BATTERY_LEVEL_UUID: BluetoothCharacteristicUUID = 0x2a19;

const DEVICE_INFO_SERVICE_UUID: BluetoothServiceUUID = 0x180a;
const FIRMWARE_REVISION_UUID: BluetoothCharacteristicUUID = 0x2a26;

const SERVICE_OVERRIDE_UUID: BluetoothServiceUUID = 0x1900;
const OVERRIDE_UUID: BluetoothCharacteristicUUID = 0x1902;

const LED_SERVICE_UUID: BluetoothServiceUUID = 0x1600;
const LED_CHARACTERISTIC_UUID: BluetoothCharacteristicUUID = 0x1601;

const OTA_SERVICE_UUID: BluetoothServiceUUID = 0x1700;
const OTA_DATA_UUID: BluetoothCharacteristicUUID = 0x1701;
const OTA_CONTROL_UUID: BluetoothCharacteristicUUID = 0x1702;

const IDLE_TIMEOUT_BEFORE_AUTO_CONTROL_MODE = 2000; // timeout in milliseconds before switching toy to ambient mode

export const names: string[] = ['PowerBlow R1', 'Xontrol C2'];

type PowerBlowSettings = {
  suctionPower: number;
  suctionTime: number;
  vacuumHold: number;
  pauseTime: number;
  ambientMovement: number;
};

export default class PowerBlow extends BaseDeviceWrapper {
  server: BluetoothRemoteGATTServer;
  sensorService: BluetoothRemoteGATTService;
  motorChar: BluetoothRemoteGATTCharacteristic;
  private batteryService: BluetoothRemoteGATTService;
  private batteryChar: BluetoothRemoteGATTCharacteristic;
  private solenoidChar: BluetoothRemoteGATTCharacteristic;
  private isOngoing: boolean;
  private settings: PowerBlowSettings;
  private isDefaultOn: boolean;
  private ambientIdleTimeout: NodeJS.Timeout | null;
  private ambientMovementTimeout: NodeJS.Timeout | null;
  private solenoidTimeout?: ReturnType<typeof setTimeout>;
  private finishTimeout?: ReturnType<typeof setTimeout>;

  constructor(device: BluetoothDevice) {
    super(device, SERVICE_UUID, MOTOR_UUID, image);
    this.batteryChar = null;
    this.solenoidChar = null;
    this.sensorService = null;
    this.isOngoing = false;
    this.settings = DEFAULT_POWERBLOW_SETTINGS;
    this.isDefaultOn = true;
    this.solenoidTimeout = null;
    this.finishTimeout = null;
    this.ambientIdleTimeout = null;
    this.ambientMovementTimeout = null;
    const localStorageSettingsStr = localStorage.getItem(POWERBLOW_SETTINGS_LOCAL_STORAGE_KEY);
    const isDefaultOn = localStorage.getItem(POWERBLOW_IS_DEFAULT_ON_LOCAL_STORAGE_KEY);
    try {
      this.settings = JSON.parse(localStorageSettingsStr) || DEFAULT_POWERBLOW_SETTINGS;
      this.isDefaultOn = JSON.parse(isDefaultOn) ?? true;
    } catch (e) {
      console.log('e constructor', e);
    }
  }

  static get deviceNames(): string[] {
    return names;
  }

  static get services(): BluetoothServiceUUID[] {
    return [SERVICE_UUID, BATTERY_SERVICE_UUID];
  }

  get companyName(): string {
    return 'Kiiroo';
  }

  async connect(): Promise<void> {
    try {
      await super.connect(async () => {
        this.batteryService = await this.server.getPrimaryService(BATTERY_SERVICE_UUID);
        this.batteryChar = await this.batteryService.getCharacteristic(BATTERY_LEVEL_UUID);
        this.solenoidChar = await this.sensorService.getCharacteristic(SOLENOID_UUID);
      });
    } catch (e) {
      console.error('Error while connecting to device: ', e);
    }
  }

  async getBattery(): Promise<number | undefined> {
    const data = await this.batteryChar?.readValue();
    return data?.getUint8(0);
  }

  async setAmbientMovement(percent: number): Promise<void> {}

  /**
   * Converts the input value from % to a value that can be sent to the device
   * PWM conversion: PWM/256= Actuation Force in %
   */
  private convertIntensity(value: number): number {
    return Math.max(1, Math.round((value / 100) * 255));
  }

  /**
   * Converts the input time from ms to the value that can be sent to the device
   * Time conversion * 50 = Actuation time in Ms
   */
  private convertTime(value: number): number {
    return Math.max(1, Math.round(value / 50));
  }

  async activateMotor(settings: PowerBlowSettings): Promise<void> {
    const time = this.convertTime(settings.suctionTime);
    const intensity = this.convertIntensity(settings.suctionPower);
    this.motorChar.writeValue(new Uint8Array([intensity, time]));
  }

  async activateSolenoid(settings: PowerBlowSettings): Promise<void> {
    const time = this.convertTime(settings.vacuumHold);
    const intensity = this.convertIntensity(settings.suctionPower);
    this.solenoidChar.writeValue(new Uint8Array([intensity, time]));
  }

  // Needed because the device should move independently of the signals
  // from the ML server as long as is_blowjob=true
  // That's why the write method is empty, and the doSuction method is called
  // When we need the PowerBlow to react
  async write(percent: number, speed: number, isBlowJob: boolean): Promise<void> {
    if (isBlowJob) {
      this.clearAmbientTimeout();

      // As per the requirements, the suction doesn't depend on the
      // predicted percent but only on the maximum intensity set in the popup
      await this.doSuction(this.isDefaultOn ? DEFAULT_POWERBLOW_SETTINGS : this.settings);
    } else {
      const ambientMovement = () => {
        const settings = this.isDefaultOn ? DEFAULT_POWERBLOW_SETTINGS : this.settings;
        this.ambientMovementTimeout = setTimeout(async () => {
          await this.doSuction(settings);
          if (this.settings.ambientMovement) {
            ambientMovement();
          } else {
            this.clearAmbientTimeout();
          }
        }, settings.suctionTime + settings.vacuumHold + settings.pauseTime);
      };

      if (this.settings.ambientMovement) {
        // no penetration -> set 0 to the device and start timeout to use Auto Control
        if (!this.ambientIdleTimeout) {
          this.ambientIdleTimeout = setTimeout(async () => {
            ambientMovement();
          }, IDLE_TIMEOUT_BEFORE_AUTO_CONTROL_MODE);
        }
      } else {
        this.clearAmbientTimeout();
      }
    }
  }

  async testDevice(settings?: PowerBlowSettings): Promise<void> {
    await this.doSuction(this.isDefaultOn ? DEFAULT_POWERBLOW_SETTINGS : settings ?? this.settings);
  }

  async setMaxIntensity(percent: number): Promise<void> {}

  setIsDefaultSettingsOn(isDefaultOn: boolean): void {
    this.isDefaultOn = isDefaultOn;
  }

  setPowerblowSettings(settings: PowerBlowSettings): void {
    this.settings = settings;
  }

  async doSuction(settings: PowerBlowSettings): Promise<void> {
    if (this.isOngoing) {
      return;
    }
    this.isOngoing = true;
    await this.activateMotor(settings);

    this.solenoidTimeout = setTimeout(() => {
      this.activateSolenoid(settings); // Release
    }, settings.suctionTime + settings.vacuumHold);

    this.finishTimeout = setTimeout(() => {
      // Set isOngoing back to false for next movement
      this.isOngoing = false;
    }, settings.suctionTime + settings.vacuumHold + settings.pauseTime);
  }

  async stopDevice(): Promise<void> {
    // Stop the device
    await this.motorChar.writeValue(new Uint8Array([0x01, 0x01]));
    // Release the air!
    await this.solenoidChar.writeValue(new Uint8Array([255, 0x01]));

    clearTimeout(this.solenoidTimeout);
    clearTimeout(this.finishTimeout);
    this.isOngoing = false;

    this.clearAmbientTimeout();
  }

  async writePaused(): Promise<void> {
    this.stopDevice();
  }

  clearAmbientTimeout(): void {
    clearTimeout(this.ambientIdleTimeout);
    clearTimeout(this.ambientMovementTimeout);
    this.ambientIdleTimeout = null;
    this.ambientMovementTimeout = null;
  }
}
