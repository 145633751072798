/**
 * A hook to adjust amount of data sent over the internet to the ML server
 */
import React, { createContext, useEffect, useState } from 'react';
import { createHook } from 'utils/utils';
import { useMLPerformance } from './MLPerformanceHook';
import { useBandwidth } from './BandwidthHooks';

const BandwidthAutoAdjustContext = createContext();

export const useBandwidthAutoAdjust = () =>
  createHook('useBandwidthAutoAdjust', BandwidthAutoAdjustContext);

// If server message turnaround time in msecs is above this level,
// we need to switch to the smaller image size (lower image quality)
const TURNAROUND_THRESHOLD = 2000;
// If FPS is below this level, we need to switch to the faster image
// compression webp->jpeg
const FPS_THRESHOLD = 20;
// Check if parameters stay below the threshold for this number of milliseconds
// before doing the action
const WATCH_TIME = 5000;

export const BandwidthAutoAdjustContextProvider = ({ children }) => {
  const { averageServerTurnaroundMsec, fps } = useMLPerformance();
  const { setFasterImageCompression, setLowImageSize } = useBandwidth();
  const [turnaroundTimeout, setTurnaroundTimeout] = useState(null);
  const [fpsTimeout, setFpsTimeout] = useState(null);

  useEffect(() => {
    if (averageServerTurnaroundMsec > TURNAROUND_THRESHOLD) {
      if (turnaroundTimeout) {
        return;
      }
      const timeout = setTimeout(() => {
        // Adjust bandwidth
        setLowImageSize(true);
      }, WATCH_TIME);
      setTurnaroundTimeout(timeout);
    } else {
      clearTimeout(turnaroundTimeout);
      setTurnaroundTimeout(null);
    }
  }, [averageServerTurnaroundMsec]);

  useEffect(() => {
    // If FPS is too low, change from webp to jpeg
    if (fps < FPS_THRESHOLD) {
      if (fpsTimeout) {
        return;
      }
      const timeout = setTimeout(() => {
        // Adjust bandwidth
        setFasterImageCompression(true);
      }, WATCH_TIME);
      setFpsTimeout(timeout);
    } else {
      clearTimeout(fpsTimeout);
      setFpsTimeout(null);
    }
  }, [fps]);

  return (
    <BandwidthAutoAdjustContext.Provider value={{}}>{children}</BandwidthAutoAdjustContext.Provider>
  );
};
