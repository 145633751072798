/* global gtag */

export const sendToAnalytics = (action, category, label, intValue) => {
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: intValue,
  });
};

export const sendToAnalyticsCustomEventObj = (action, params = {}) => {
  gtag('event', action, params);
};
