import { StrapiResult, getSingle } from '@feelrobotics/strapi-sdk';

const PRIVACY_TYPE = 'privacy';
const TERMS_TYPE = 'terms';
const CANCEL_TYPE = 'cancellation';

async function getPrivacyPolicy(): Promise<StrapiResult> {
  const searchString = 'fm-privacy-policy';

  return getDocument(searchString, PRIVACY_TYPE);
}

async function getTermsAndConditions(): Promise<StrapiResult> {
  const searchString = 'fm-terms-and-conditions';
  return getDocument(searchString, TERMS_TYPE);
}

async function getCancellationPolicy(): Promise<StrapiResult> {
  const searchString = 'fm-cancellation-policy';
  return getDocument(searchString, CANCEL_TYPE);
}

async function getDocument(searchString, assetType): Promise<StrapiResult> {
  const res = await getSingle(searchString, assetType);
  res.response = {
    title: res.response['title'],
    content: res.response['content'],
  };
  return res;
}

export { getPrivacyPolicy, getTermsAndConditions, getCancellationPolicy };
