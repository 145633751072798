import {
  DeviceInfo,
  DeviceNotConnectedError,
  DeviceState,
  DeviceTimeoutError,
  Funscript,
  ValidationError,
  FIRMWARE_STATUS,
  DeviceNotInitializedError,
} from './types';

export class Autoblow {
  private deviceToken: string | null = null;
  private connectedCluster: string | null = null;

  async init(deviceToken: string): Promise<DeviceInfo> {
    if (!deviceToken) {
      throw new Error('Device token is required');
    } else if (deviceToken.length < 5) {
      throw new Error('Device token is invalid');
    }

    const connectionInfo = await this.getConnectionInfo(deviceToken);
    if (!connectionInfo.connected || !connectionInfo.cluster) {
      throw new DeviceNotConnectedError();
    }
    this.connectedCluster = connectionInfo.cluster;
    this.deviceToken = deviceToken;
    const deviceInfo = await this.getInfo();
    return deviceInfo;
  }

  getConnectedCluster() {
    return this.connectedCluster;
  }

  async getConnectionInfo(token: string): Promise<DeviceConnection> {
    if (!token) {
      throw new Error('Set Device Token First');
    }
    const res = await fetch(
      'https://latency.autoblowapi.com/autoblow/connected',
      {
        method: 'GET',
        headers: {
          'x-device-token': token,
        },
      },
    );

    if (!res.ok) {
      return {
        connected: false,
      };
    }

    const data = await res.json();
    return data;
  }

  async getInfo(): Promise<DeviceInfo> {
    return await this.request('autoblow/info');
  }

  async getState(): Promise<DeviceState> {
    return await this.request('autoblow/state');
  }

  async localScriptSet(
    localScriptIndex: number,
    speedIndex: number,
  ): Promise<DeviceState> {
    return await this.request('autoblow/local-script', {
      method: 'PUT',
      body: JSON.stringify({
        localScriptIndex,
        speedIndex,
      }),
    });
  }

  async localScriptStop(): Promise<DeviceState> {
    return await this.request('autoblow/local-script/stop', {
      method: 'PUT',
    });
  }

  async localScriptStart(): Promise<DeviceState> {
    return await this.request('autoblow/local-script/start', {
      method: 'PUT',
    });
  }

  async localScriptList(): Promise<number[]> {
    const response = await this.request<LoadedLocalScriptList>(
      'autoblow/local-script/loaded',
    );
    return response.loadedScripts;
  }

  async localScriptReplace(
    replacedLocalScriptIndex: number,
    newLocalScriptId: number,
  ): Promise<number[]> {
    const response = await this.request<LoadedLocalScriptList>(
      'autoblow/local-script/replace',
      {
        method: 'PUT',
        body: JSON.stringify({
          replacedLocalScriptIndex,
          newLocalScriptId,
        }),
      },
    );
    return response.loadedScripts;
  }

  async localScriptSwap(
    scriptIndexA: number,
    scriptIndexB: number,
  ): Promise<number[]> {
    const response = await this.request<LoadedLocalScriptList>(
      'autoblow/local-script/swap',
      {
        method: 'PUT',
        body: JSON.stringify({
          scriptIndexA,
          scriptIndexB,
        }),
      },
    );
    return response.loadedScripts;
  }

  async localScriptMove(fromIndex: number, toIndex: number): Promise<number[]> {
    const response = await this.request<LoadedLocalScriptList>(
      'autoblow/local-script/move',
      {
        method: 'PUT',
        body: JSON.stringify({
          fromIndex,
          toIndex,
        }),
      },
    );
    return response.loadedScripts;
  }

  async oscillateSet(
    speed: number,
    minY: number,
    maxY: number,
  ): Promise<DeviceState> {
    return await this.request('autoblow/oscillate', {
      method: 'PUT',
      body: JSON.stringify({
        speed,
        minY,
        maxY,
      }),
    });
  }

  async oscillateStop(): Promise<DeviceState> {
    return await this.request('autoblow/oscillate/stop', {
      method: 'PUT',
    });
  }

  async oscillateStart(): Promise<DeviceState> {
    return await this.request('autoblow/oscillate/start', {
      method: 'PUT',
    });
  }

  async goToPosition(position: number, speed: number): Promise<DeviceState> {
    return await this.request('autoblow/goto', {
      method: 'PUT',
      body: JSON.stringify({
        position,
        speed,
      }),
    });
  }

  async syncScriptLoadToken(token: string): Promise<DeviceState> {
    return await this.request('autoblow/sync-script/load-token', {
      method: 'PUT',
      body: JSON.stringify({
        scriptToken: token,
      }),
    });
  }

  async syncScriptUploadFunscriptUrl(
    funscriptUrl: string,
  ): Promise<DeviceState> {
    return await this.request('autoblow/sync-script/upload-funscript-url', {
      method: 'PUT',
      body: JSON.stringify({
        url: funscriptUrl,
      }),
    });
  }

  async syncScriptUploadFunscriptFile(
    funscript: Funscript,
  ): Promise<DeviceState> {
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(funscript)], {
      type: 'application/json',
    });
    formData.append('file', blob, 'funscript.json');
    return await this.request(
      'autoblow/sync-script/upload-funscript',
      {
        method: 'PUT',
        body: formData,
      },
      false,
    );
  }

  async syncScriptUploadCsvUrl(csvUrl: string): Promise<DeviceState> {
    return await this.request('autoblow/sync-script/upload-csv-url', {
      method: 'PUT',
      body: JSON.stringify({
        url: csvUrl,
      }),
    });
  }

  async syncScriptUploadCsvFile(csvContent: string): Promise<DeviceState> {
    const formData = new FormData();
    const blob = new Blob([csvContent], {type: 'text/csv'});
    formData.append('file', blob, 'script.csv');
    return await this.request(
      'autoblow/sync-script/upload-csv',
      {
        method: 'PUT',
        body: formData,
      },
      false,
    );
  }

  async syncScriptStop(): Promise<DeviceState> {
    return await this.request('autoblow/sync-script/stop', {
      method: 'PUT',
    });
  }

  async syncScriptStart(startTimeMs: number): Promise<DeviceState> {
    startTimeMs = Math.round(startTimeMs);

    const result = await this.request<Promise<DeviceState>>(
      'autoblow/sync-script/start',
      {
        method: 'PUT',
        body: JSON.stringify({
          startTimeMs,
        }),
      },
    );

    return result;
  }

  async syncScriptOffset(offsetTimeMs: number): Promise<DeviceState> {
    return await this.request('autoblow/sync-script/offset', {
      method: 'PUT',
      body: JSON.stringify({
        offsetTimeMs,
      }),
    });
  }

  async estimateLatency(noOfRequests = 10): Promise<number> {
    //Will estimate the latency between the client and the device
    let latencySum = 0;
    for (let i = 0; i < noOfRequests; i++) {
      const start = Date.now();
      await this.request('cluster/time');
      const end = Date.now();
      const roundTriptT = end - start;
      const latency = roundTriptT / 2;
      latencySum += latency;
    }
    const latencyClientServer = Math.round(latencySum / noOfRequests);
    const latencyClientDevice = latencyClientServer * 3; // Client (1) -> Server (2)-> Device (3)

    // Latency overhead for the processing requests on the server and the device
    // Note that the device needs to load a big buffer of the script before it can start playing it
    // This buffer latency is already accounted for in the device, it will just add a small overhead to the latency
    // But we need to account for the latency overhead of the CLIENT -> SERVER -> DEVICE
    const latencyOverhead = 30;

    return latencyClientDevice + latencyOverhead;
  }

  async firmwareUpdateStart(): Promise<true> {
    const result = await this.request<GenericResult | GenericError>(
      'autoblow/firmware/update-start',
      {
        method: 'PUT',
      },
    );
    if ('success' in result && result.success == true) {
      return true;
    } else if ('code' in result) {
      throw new Error(
        `Firmware update failed, code: ${result.code}, description: ${result.description}`,
      );
    } else {
      throw new Error('Firmware update failed');
    }
  }

  async firmwareUpdateInfo(): Promise<FirmwareInfo> {
    return await this.request('autoblow/firmware/update-info');
  }

  private async request<T>(
    url: string,
    options: RequestInit = {},
    useJsonContentType = true,
  ): Promise<T> {
    if (!this.deviceToken) throw new Error('Set Device Token First');
    if (!this.connectedCluster) throw new DeviceNotInitializedError();

    options.headers = {...options.headers, 'x-device-token': this.deviceToken};

    if (useJsonContentType && options.body) {
      options.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
      };
    }

    const response = await fetch(
      `https://${this.connectedCluster}/${url}`,
      options,
    );
    if (response.ok) return await response.json();

    if (response.status === 400) {
      const json = await response.json();
      throw new ValidationError(
        json.error.message ||
          json.error.code ||
          json.error ||
          'Unknown validation error',
      );
    } else if (response.status === 502) {
      this.connectedCluster = null;
      throw new DeviceNotConnectedError();
    } else if (response.status === 504) {
      this.connectedCluster = null;
      throw new DeviceTimeoutError();
    } else {
      const json = await response.json();
      if (json) {
        throw new Error(
          json.error.message ||
            json.error.code ||
            json.error ||
            json ||
            'Unknown error',
        );
      }
      throw new Error(`Fetch error: ${response.status} ${response.statusText}`);
    }
  }
}

export type DeviceConnection = {
  connected: boolean;
  cluster?: string;
};

export type GenericResult = {
  success: boolean;
};

export type GenericError = {
  code: string;
  description: string;
};

export type FirmwareInfo = {
  branch: number;
  firmwareVersion: string;
  hardwareVersion: string;
};

export type LoadedLocalScriptList = {
  loadedScripts: number[];
};

export {
  DeviceNotConnectedError,
  DeviceTimeoutError,
  ValidationError,
  FIRMWARE_STATUS,
};
