import React, { createContext, useState, useCallback, useEffect } from 'react';
import { createHook } from 'utils/utils';
import { getDomain } from '../utils/domainUtils';
import { useAuth } from './AuthHook';

import billingApi from 'api/billing-api';

const PORTAL_FULLSCREEN_WIDGET_URL =
  window.location.protocol + '//' + window.location.host + '/extension/fullscreen';

const WebsiteCompatibilityContext = createContext();
const useWebsiteCompatibility = () =>
  createHook('useWebsiteCompatibility', WebsiteCompatibilityContext);

const WebsiteCompatibilityContextProvider = ({ children }) => {
  const { authenticatedUser } = useAuth();
  const [compatibleWebsites, setCompatibleWebsites] = useState([]);

  useEffect(() => {
    if (authenticatedUser?.attributes?.email) {
      billingApi('/whitelist').then((data) => {
        setCompatibleWebsites(data.whitelist);
      });
    }
  }, [authenticatedUser?.attributes?.email]);

  // pass list of whitelisted websites to chrome extension
  const postMessageCompatibleWebsites = () => {
    if (compatibleWebsites.length) {
      const request = {
        what: 'WHITELISTED_WEBSITES',
        compatibleWebsites,
        url: PORTAL_FULLSCREEN_WIDGET_URL,
      };
      window.postMessage(request, '*');
    }
  };

  useEffect(() => {
    postMessageCompatibleWebsites();
  }, [compatibleWebsites.length]);

  const onMessageHandler = useCallback(
    (event) => {
      // content page reconnected and background page in extension reinitialezed,
      //  list of compatible websites was reset to [], so we need to retrieve this list again
      if (event?.data?.what === 'WHITELISTED_WEBSITES_RESET') {
        postMessageCompatibleWebsites();
      }
    },
    [compatibleWebsites.length],
  );

  useEffect(() => {
    window.addEventListener('message', onMessageHandler);
    return () => {
      window.removeEventListener('message', onMessageHandler);
    };
  }, [onMessageHandler]);

  function cleanUrl(url) {
    let trimmedUrl = url.replace(/^https?:\/\//, '');
    if (trimmedUrl.endsWith('/')) {
      trimmedUrl = trimmedUrl.slice(0, -1);
    }
    return trimmedUrl;
  }

  const checkCompatibility = (url) => {
    const domain = getDomain(url);
    return !!compatibleWebsites.find((s) => cleanUrl(s) === domain);
  };

  return (
    <WebsiteCompatibilityContext.Provider
      value={{
        compatibleWebsites,
        getDomain,
        checkCompatibility,
      }}
    >
      {children}
    </WebsiteCompatibilityContext.Provider>
  );
};

export { WebsiteCompatibilityContextProvider, useWebsiteCompatibility };
