import React, { createContext, useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

import { connectToServer } from '../lib/serverModel';
import { useSubscription } from 'hooks/SubscriptionHook';
import { usePrediction, SERVERS } from 'hooks/PredictionHook';

import { createHook } from 'utils/utils';

// An empty pink image, 288x176 pixesl, webp format, base64 encoded.
const testImage =
  'UklGRrAAAABXRUJQVlA4IKQAAACQDwCdASogAbAAP/3+/3+/urayJIgD8D+JaW7hdrEWgPdKxHulYj3SsR7pWI90vUs1+WJASAjIe2Z94c45xzZepZr8sSAkBGQ9sz7w5xzjmy9SzX5YkBICMh7fcBICQEgIyHtmfeHOOcc2XqWa/LEgJARkPbM+8Occ45svUs1+WJASAjAAAP77qPTev1aTSJRmDrTAgAACFFBcwFHoK30eBAAAAA==';

export enum AutoSelectServerStatus {
  DISCONNECTED = 'DISCONNECTED',
  PROBING = 'PROBING',
  SELECTED = 'SELECTED',
}

// How long to wait for a server to respond to all frames
const SERVER_TEST_DURATION_MSEC = 2000;

// How long to wait until calculating which server was the fastest
// Should be SERVER_TEST_DURATION_MSEC plus some extra time needed to
// establish connection to the servers
const TOTAL_DURATION_MSEC = 4000;

// How many frames to send to each server
const TEST_FRAMES = 10;

// How many frames per second to send
const FPS = 30;

const AUTO_SELECT_LOCAL_STORAGE_KEY = 'AutoSelectServer:isAutoSelectEnabled';

interface AutoSelectServerContextInterface {
  autoSelectServerStatus: AutoSelectServerStatus;
  isAutoSelected: boolean;
  setIsAutoSelected: (isAutoSelected: boolean) => void;
}

type Props = {
  children: React.ReactNode;
};

// Statistics on a frame sent to a server and then received back
type FrameStat = {
  frameIndex: number;
  // Time when the frame was sent
  start: number;
  // Time when the frame was received back
  end: number;
};

type FrameStatArray = FrameStat[];

/**
 * Calculate the fastest server based on the frame statistics
 * @param stats Array of frame statistics. Each element corresponds to a server in SERVERS
 * @returns Index of the fastest server in SERVERS array
 */
export const findBestServerIndex = (stats: FrameStatArray[]) => {
  const frameCounts = stats.map((stat) => stat.length);
  const maxFrameCount = Math.max(...frameCounts);
  const avgDurations = stats.map((stat) => {
    if (stat.length !== maxFrameCount) {
      return Number.MAX_SAFE_INTEGER;
    }
    const durations = stat.map((frameStat) => Math.abs(frameStat.end - frameStat.start));
    const avgDuration = durations.reduce((a, b) => a + b, 0) / durations.length;
    return avgDuration;
  });
  const minAvgDuration = Math.min(...avgDurations);
  const bestServerIndex = avgDurations.indexOf(minAvgDuration);
  return bestServerIndex;
};

const AutoSelectServerContext = createContext<AutoSelectServerContextInterface | null>(null);

export const useAutoSelectServer = () => createHook('useAutoSelectServer', AutoSelectServerContext);

export const AutoSelectServerContextProvider = (props: Props) => {
  const [isAutoSelected, setIsAutoSelected] = useLocalStorage<boolean>(
    AUTO_SELECT_LOCAL_STORAGE_KEY,
    true,
  );
  const [status, setStatus] = useState<AutoSelectServerStatus>(AutoSelectServerStatus.DISCONNECTED);

  const { tokenObj, accessToken } = useSubscription();
  const { token } = tokenObj || {};

  const { setServerUrl } = usePrediction();

  useEffect(() => {
    const startTestingServers = async () => {
      const stats: FrameStatArray[] = [];
      SERVERS.forEach((s) => {
        const frameStats: FrameStatArray = [];
        stats.push(frameStats);
        const connection = connectToServer(s.value, token, accessToken);
        connection.subscribeServerPredictions(({ frameIndex }) => {
          const frameStat = frameStats.find((fs) => fs.frameIndex === frameIndex);

          // TODO fix this. frameStats is always empty array and frameStat will be undefined
          if (frameStat) {
            frameStat.end = Date.now();
          }
        });

        // Wait until connection is established.
        connection.onConnected(() => {
          // Send TEST_FRAMES messages
          let frameIndex = 0;
          const interval = setInterval(() => {
            // Send a frame
            connection.requestServerPrediction({
              frameIndex,
              image: testImage,
              autopilot: false,
            });
            // Record the time when the frame was sent
            frameStats.push({
              frameIndex,
              start: Date.now(),
              end: 0,
            });
            frameIndex++;
            // Stop sending frames after TEST_FRAMES frames
            if (frameIndex > TEST_FRAMES) {
              clearInterval(interval);
            }
          }, 1000 / FPS);
          setTimeout(() => {
            // Close the connection to the server after SERVER_TEST_DURATION_MSEC
            connection.closeConnection();
          }, SERVER_TEST_DURATION_MSEC);
          return connection;
        });
      });
      setTimeout(() => {
        // Calculate the fastest server after TOTAL_DURATION_MSEC
        const serverIndex = findBestServerIndex(stats);
        const server = SERVERS[serverIndex];
        // Set the fastest server as the selected server
        setServerUrl(server.value);
        setStatus(AutoSelectServerStatus.SELECTED);
      }, TOTAL_DURATION_MSEC);
    };

    if (!isAutoSelected) {
      // Not autoselecting, do nothing
      setStatus(AutoSelectServerStatus.DISCONNECTED);
      return;
    } else if (status !== AutoSelectServerStatus.DISCONNECTED) {
      // We can start probing only in disconnected (initial) state,
      return;
    } else if (!token) {
      // Wait until token is available
      return;
    }
    setStatus(AutoSelectServerStatus.PROBING);
    startTestingServers();
  }, [isAutoSelected, setServerUrl, status, token, accessToken]);

  return (
    <AutoSelectServerContext.Provider
      value={{
        autoSelectServerStatus: status,
        isAutoSelected,
        setIsAutoSelected,
      }}
    >
      {props.children}
    </AutoSelectServerContext.Provider>
  );
};
