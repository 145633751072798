import React from 'react';
import { translations } from 'i18n';
import cn from 'classnames';

import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

export const AmbientMovement = () => {
  const { ambientMovement, setAmbientMovement, autopilot } = useChromeExtensionPopup();
  return (
    <div className={cn(autopilot && styles.grayscale)}>
      <label>
        <span className={styles.settingsLabel}>
          {translations('ExtensionPopup.AmbientMovement')}:
        </span>
        <span className={styles.settingsLabelDescription}>
          {translations('ExtensionPopup.AmbientMovementDescription')}
        </span>
        <input
          className={styles.settingsRange}
          type="range"
          list="intensity"
          min="0"
          max="100"
          step="25"
          value={ambientMovement}
          disabled={autopilot}
          onChange={(evt) => {
            setAmbientMovement(evt.target.value);
          }}
          style={{
            background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${ambientMovement}%, #4d445b ${ambientMovement}%, #4c445a 100%)`,
          }}
        />
      </label>
      <datalist id="ambientMovement" className={styles.settingsOptions}>
        <option value="0" label="0%"></option>
        <option value="25" label="25%" className={styles.middleLabel}></option>
        <option value="50" label="50%" className={styles.middleLabel}></option>
        <option value="75" label="75%" className={styles.middleLabel}></option>
        <option value="100" label="100%"></option>
      </datalist>
    </div>
  );
};
