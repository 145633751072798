import React, { createContext, useEffect, useState } from 'react';
import { createHook } from 'utils/utils';
import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';

import { useMLPerformance } from './MLPerformanceHook';
import { usePrediction } from './PredictionHook';

// The hook state contains average values
// for network turnaround, number of dropped frames
// and FPS
const DEFAULT_STATE = {
  averageTurnaround: 0,
  countTurnaround: 0,
  averageDropped: 0,
  countDropped: 0,
  averageFps: 0,
  countFps: 0,
  serverUrl: null,
  paused: false,
};

const GoogleAnalyticsContext = createContext();

export const useGoogleAnalytics = () => createHook('useGoogleAnalytics', GoogleAnalyticsContext);

export const GoogleAnalyticsContextProvider = ({ children }) => {
  const { averageServerTurnaroundMsec, serverDroppedFramePercentage, fps, paused } =
    useMLPerformance();
  const { serverUrl } = usePrediction();
  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    if (!isFinite(fps)) {
      // Not yet measured
      return;
    }

    if (
      // ML server URL has changed and it was not empty before
      (state.serverUrl && state.serverUrl !== serverUrl) ||
      // Video has been paused/stopped
      (paused && state.paused !== paused)
    ) {
      // If ML server has changed or the video has been paused
      // Send analytics data
      const data = {
        ml_server: state.serverUrl,
        network_turnaround: Math.round(state.averageTurnaround),
        fps: Math.round(state.averageFps),
        skipped_frames_percent: Math.round(state.averageDropped),
      };
      sendToAnalyticsCustomEventObj('ml_metrics', data);
      // Reset the state
      const newState = { ...DEFAULT_STATE, serverUrl: serverUrl, paused };
      setState(newState);
    } else {
      // Recalculate average values
      const newState = {
        averageTurnaround:
          (state.averageTurnaround * state.countTurnaround + averageServerTurnaroundMsec) /
          (state.countTurnaround + 1),
        countTurnaround: state.countTurnaround + 1,
        averageDropped:
          (state.averageDropped * state.countDropped + serverDroppedFramePercentage) /
          (state.countDropped + 1),
        countDropped: state.countDropped + 1,
        averageFps: (state.averageFps * state.countFps + fps) / (state.countFps + 1),
        countFps: state.countFps + 1,
        serverUrl: serverUrl,
        paused,
      };
      setState(newState);
    }
  }, [serverUrl, averageServerTurnaroundMsec, fps, serverDroppedFramePercentage, paused]);

  return <GoogleAnalyticsContext.Provider value={{}}>{children}</GoogleAnalyticsContext.Provider>;
};
