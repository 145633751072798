import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ChromeExtensionPopupContextProvider } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';

import Popup from 'ChromeExtensionPopup/components/PopupContainer';
import FullscreenWidget from 'ChromeExtensionPopup/components/FullscreenWidgetContainer';

const ExtensionApp = () => {
  return (
    <ChromeExtensionPopupContextProvider>
      <Routes>
        <Route path={'/popup'} element={<Popup />} />
        <Route path={'/fullscreen'} element={<FullscreenWidget />} />
      </Routes>
    </ChromeExtensionPopupContextProvider>
  );
};

export default ExtensionApp;
