export function getDomain(url) {
  var prefix = /^https?:\/\//;
  var domain = /^[^/:]+/;
  // remove any prefix
  url = url.replace(prefix, '');
  // assume any URL that starts with a / is on the current page's domain
  if (url.charAt(0) === '/') {
    url = window.location.hostname + url;
  }

  // now extract just the domain
  var match = url.match(domain);
  if (match) {
    // remove subdomain
    const split = match[0].split('.');
    if (split.length > 2) {
      return split[1] + '.' + split[2];
    } else {
      return match[0];
    }
  }
  return null;
}
