class Pendulum {
  private static DAMPING_FACTOR: number = 0.993;
  private static TICK_THRESHOLD_MULTIPLIER: number = 1.3;
  private static MIN_TICK_PERIOD_MULTIPLIER: number = 0.7;
  private static HIGH_PHASE_RATIO_THRESHOLD: number = 0.9 * 1000; // msec
  private static LOW_PHASE_RATIO_THRESHOLD: number = 0.2 * 1000; // msec
  private static STD_DEV_THRESHOLD_HIGH: number = 0.25; // 25% of the period
  private static STD_DEV_THRESHOLD_LOW: number = 0.05; // 5% of the period
  private static STD_DEV_THRESHOLD_CRITICAL: number = 0.7; // 70% of the period
  private static PERIOD_UPDATE_COEF_HIGH: number = 0.6;
  private static PERIOD_UPDATE_COEF_MED: number = 0.55;
  private static PERIOD_UPDATE_COEF_LOW: number = 0.5;
  private static AMPLITUDE_RESET_VALUE: number = 1;
  private static AMPLITUDE_TICK_THRESHOLD: number = 0.5;

  private amplitude: number;
  public period: number;
  private phase: number;
  private time: number;
  private doWeTick: boolean;
  private lastTick: number;
  private lastPeriods: number[];

  constructor() {
    this.amplitude = 0.0;
    this.period = 20000.0; // msec
    this.phase = 5000.0; // msec
    this.time = 0.0;
    this.doWeTick = false;
    this.lastTick = -100.0; // msec
    this.lastPeriods = [0.0, 0.0];
  }

  updatePendulum(newPeriod: number, signalAge: number = 0.0): void {
    this.lastPeriods.push(newPeriod);
    this.lastPeriods.shift();

    let coef = Pendulum.PERIOD_UPDATE_COEF_HIGH;
    const stdDev = this.calculateStdDev(this.lastPeriods);

    const averagePeriod = this.lastPeriods.reduce((a, b) => a + b, 0) / this.lastPeriods.length;

    if (stdDev > averagePeriod * Pendulum.STD_DEV_THRESHOLD_CRITICAL) {
      // The change is too big, ignoring it to keep the movement smooth
      return;
    }

    if (stdDev < averagePeriod * Pendulum.STD_DEV_THRESHOLD_LOW) {
      coef = Pendulum.PERIOD_UPDATE_COEF_LOW;
    } else if (stdDev < averagePeriod * Pendulum.STD_DEV_THRESHOLD_HIGH) {
      coef = Pendulum.PERIOD_UPDATE_COEF_MED;
    }

    this.period = this.period * coef + (1 - coef) * newPeriod;

    // Phase update
    const actualTimeWhenTheTickHappened = this.time - signalAge;
    const nbOfCycles = Math.floor(actualTimeWhenTheTickHappened / this.period);
    const newPhase = actualTimeWhenTheTickHappened - this.period * nbOfCycles;
    this.phase = newPhase;

    // Amplitude update
    this.amplitude = Pendulum.AMPLITUDE_RESET_VALUE;
  }

  private calculateStdDev(values: number[]): number {
    const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
    const squareDiffs = values.map((val) => (val - mean) ** 2);
    const avgSquareDiff = squareDiffs.reduce((acc, val) => acc + val, 0) / squareDiffs.length;
    return Math.sqrt(avgSquareDiff);
  }

  updateTime(newTime: number): void {
    const nbOfCycles = Math.floor(this.time / this.period);
    const currentPosition = this.time - this.period * nbOfCycles;

    if (this.doWeTick) {
      if (newTime - this.lastTick > this.period / 2) {
        this.doWeTick = false;
      }
    }

    let weNeedToTick = false;

    if (!this.doWeTick) {
      if (newTime - this.lastTick > Pendulum.TICK_THRESHOLD_MULTIPLIER * this.period) {
        weNeedToTick = true;
      }
      if (newTime - this.lastTick > Pendulum.MIN_TICK_PERIOD_MULTIPLIER * this.period) {
        let delta = currentPosition - this.phase;
        if (
          this.phase / this.period > Pendulum.HIGH_PHASE_RATIO_THRESHOLD &&
          currentPosition / this.period < Pendulum.LOW_PHASE_RATIO_THRESHOLD
        ) {
          delta += this.period;
        }
        if (delta > 0 && delta / this.period < 0.2) {
          weNeedToTick = true;
        }
      }
      if (weNeedToTick) {
        this.doWeTick = true;
        this.lastTick = newTime;
      }
    }
    this.amplitude *= Pendulum.DAMPING_FACTOR;
    this.time = newTime;
  }

  getDoWeTick(): boolean {
    return this.amplitude > Pendulum.AMPLITUDE_TICK_THRESHOLD ? this.doWeTick : false;
  }
}

export { Pendulum };
