import React, { ReactNode } from 'react';
import { VIDEOWATCHER_APP_NAME } from 'data/constants';
import { getUA, isMobile } from 'react-device-detect';
import NotDesktopBanner from 'components/NotDesktopBanner';
import { useSubscription } from 'hooks/SubscriptionHook';

export const PLATFORMS: Record<
  string,
  'app' | 'mobile' | 'chrome_desktop' | 'non_chrome_desktop' | 'desktop'
> = {
  APP: 'app',
  MOBILE: 'mobile',
  CHROME_DESKTOP: 'chrome_desktop',
  NON_CHROME_DESKTOP: 'non_chrome_desktop',
  DESKTOP: 'desktop',
} as const;

interface PlatformRouteProps {
  allowed?: Array<keyof typeof PLATFORMS> | null;
  children: ReactNode;
}

const currentPlatform = () => {
  if (getUA.includes(VIDEOWATCHER_APP_NAME)) {
    return PLATFORMS.APP;
  } else if (isMobile) {
    return PLATFORMS.MOBILE;
  } else {
    return PLATFORMS.DESKTOP;
  }
};

/**
 * PlatformRoute is a React component that conditionally renders children based on the current platform.
 * It is used to restrict access to certain parts of the application depending on the platform being used.
 *
 * @param {PlatformRouteProps} props - The properties passed to the component.
 * @param {Array<keyof typeof PLATFORMS> | null} props.allowed - An array of platforms that are allowed to access the route.
 * @param {React.ReactNode} props.children - The child components or elements to render if the platform is allowed.
 * @returns {React.ReactElement | null} A React element representing the allowed content for the current platform,
 *    or a banner indicating restriction based on the platform.
 */
export const PlatformRoute: React.FC<PlatformRouteProps> = ({ allowed, children }) => {
  const { subscriptionInfo } = useSubscription();
  if (!allowed.includes(currentPlatform()) && subscriptionInfo) {
    return <NotDesktopBanner />;
  }
  return <>{children}</>;
};
