import image from 'images/devices/keon-promotion.png';
import Stroker from './Stroker';

const serviceUuid = '00001900-0000-1000-8000-00805f9b34fb';
const statusCharacteristicUuid = 0x1901;
const commandCharacteristicUuid = 0x1902;

const names = ['KEON', 'Keon R1', 'Keon R2'];

const KEON_MIN_POS = 0;
const KEON_MAX_POS = 90;

/**
 * @note For KEON it takes 533 ms to go full cycle
 * (e.g. from the top position to the top position)
 * if it moves with 100% speed.
 */

const KEON_FULL_CYCLE_TIME_MSEC = 533;

/**
 * Create a device wrapper over the Web Bluetooth device object
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export default class Keon extends Stroker {
  constructor(device) {
    super(
      device,
      serviceUuid,
      commandCharacteristicUuid,
      image,
      statusCharacteristicUuid,
      KEON_MAX_POS,
      KEON_MIN_POS,
      KEON_FULL_CYCLE_TIME_MSEC,
    );
  }

  static get deviceNames() {
    return names;
  }

  static get services() {
    return [serviceUuid];
  }

  get companyName() {
    return 'Kiiroo';
  }

  async connect() {
    await super.connect();
    // Move Keon to the movement mode
    await this.motorChar.writeValue(new Uint8Array([0x00]));
    await this.motorChar.startNotifications();
    this.motorChar.addEventListener('characteristicvaluechanged', (event) => {
      const valueArray = new Uint8Array(event.currentTarget.value.buffer);
      const pos = 100 - valueArray[1]; // because it's reversed, 0 is down (full penitration)
      this.deviceMovementCallback({ position: pos, speed: 0 });
    });
  }

  async disconnect() {
    await super.disconnect();
  }

  async writeValue(position, speed) {
    await this.motorChar.writeValue(new Uint8Array([0x03, 0x01, speed, position]));
  }

  async writePauseValue() {
    await this.motorChar.writeValue(new Uint8Array([0x00]));
  }

  async writeMovementBetween(minPosition, maxPosition, speed) {
    await this.motorChar.writeValue(new Uint8Array([0x04, 0x00, speed, minPosition, maxPosition]));
  }

  async getBattery() {
    const data = await this.statusChar.readValue();
    return data?.getUint8(5);
  }
}
