import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import Stroker from './Stroker';

import image from 'images/devices/autoblow-ai-ultra.png';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import { sendToAnalytics } from 'lib/googleAnalytics';
import { sendAnalytics } from '@feelrobotics/ftap-connector';
import { shouldSendAnalytics } from 'utils/utils';

import { Autoblow } from '../xsense-api/autoblow';

const DEVICE_NAMES = ['Autoblow AI Ultra'];
const COMPANY_NAME = 'XSense';

export default class AutoblowUltra extends Stroker {
  private autoblowApi: Autoblow;
  private deviceToken: string;

  constructor(device: BluetoothDevice) {
    super(device, '', '', image, '', 100, 0, 200);
    this.autoblowApi = new Autoblow();
    // @ts-ignore
    this.deviceToken = device?.connectionKey;
    this.supportWifi = true;
  }
  static get deviceNames(): Array<string> {
    return DEVICE_NAMES;
  }

  // For compatibility with bluetooth devices
  static get services(): Array<string> {
    return [];
  }

  get companyName(): string {
    return COMPANY_NAME;
  }

  /**
   * Connect to the device via WiFi
   */
  async connect() {
    const startTime = performance.now();

    this.setConnectionState(ConnectionState.CONNECTING);

    try {
      let info = await this.autoblowApi.init(this.deviceToken);
      this.device.id = info.mac;
    } catch {
      this.setConnectionState(ConnectionState.DISCONNECTED);
      throw new Error('Failed to connect to Autoblow');
    }

    this.setConnectionState(ConnectionState.CONNECTED);

    const milliseconds = Math.round(performance.now() - startTime);
    sendToAnalytics('device_connect', 'device', 'The Handy WIFI', milliseconds);
    shouldSendAnalytics() && sendToAnalytics('connect', this.device.name, this.device.id);
    sendAnalytics('connect', this.device.name, this.device.id);
  }

  async writePaused() {
    await this.autoblowApi.oscillateStop();
  }

  async disconnect() {
    this.setConnectionState(ConnectionState.WIFI_MANUALLY_DISCONNECTED);
    shouldSendAnalytics() && sendToAnalytics('disconnect', this.device.name, this.device.id);
    sendAnalytics('disconnect', this.device.name, this.device.id);
  }

  async writeValue(position: number, speed: number): Promise<void> {
    await this.autoblowApi.goToPosition(position, speed);
  }

  async writeMovementBetween(
    minPosition: number,
    maxPosition: number,
    speed: number,
  ): Promise<void> {
    await this.autoblowApi.oscillateSet(speed, minPosition, maxPosition);
  }

  // @ts-ignore
  async getBattery(): Promise<void> {}
}
