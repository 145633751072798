import * as DeviceStates from './DeviceStates';
import { onGattServerDisconnected } from './DeviceUtils';
import { sendToAnalytics } from 'lib/googleAnalytics';
import { sendAnalytics } from '@feelrobotics/ftap-connector';
import { shouldSendAnalytics } from 'utils/utils';

/**
 * Device wrapper over the Web Bluetooth device object
 * @respons Responsible for
 * - holding the WebBluetooth object, its service and charactristics
 * - Connecting and disconnecting to the Web Bluetooth device
 * - Discovering its service and characteristic during the connection process
 * - holding Web Bluetooth device connectin state (connected, disconnected, connecting)
 * - Handling Web Bluetooth device disconnection
 *
 * @collab Is a base class for concrete device classes like Pearl2, Cliona, etc.
 * @param {obj} webBleDevice - Web Bluetooth device object
 */
export class BaseDeviceWrapper {
  constructor(device, serviceUuid, charUuid, image, statusUuid) {
    this.device = device;
    this.serviceUuid = serviceUuid;
    this.charUuid = charUuid;
    this.image = image;
    this.statusUuid = statusUuid;
    this.supportWifi = false;
    this.unsupported = false;

    this.server = null;
    this.sensorService = null;
    this.motorChar = null;
    this.sensorChar = null;
    this.statusChar = null;
    this.connectionState = DeviceStates.DISCONNECTED;
    this.connectionStateCallback = null;
    this.deviceMovementCallback = () => {};
    device.addEventListener('gattserverdisconnected', () => {
      onGattServerDisconnected(this);
      sendToAnalytics('disconnect', this.device.name, this.device.id);
      sendAnalytics('disconnect', this.device.name, this.device.id);
    });
  }

  /**
   * Set new connection state and notify the callback
   * @param {string} newState - new state from DeviceStates.*
   */
  setConnectionState(newState) {
    this.connectionState = newState;
    this.connectionStateCallback?.();
  }

  /**
   * Connect to the device and discover the service and characteristic
   * @param {Function} extension - An optional function to be called in the middle of the connection process.
   */
  async connect(extension = null) {
    const startTime = performance.now();

    this.setConnectionState(DeviceStates.CONNECTING);
    this.server = await this.device.gatt.connect();
    this.sensorService = await this.server.getPrimaryService(this.serviceUuid);
    this.motorChar = await this.sensorService.getCharacteristic(this.charUuid);
    if (this.statusUuid) {
      this.statusChar = await this.sensorService.getCharacteristic(this.statusUuid);
    }
    if (typeof extension === 'function') {
      await extension();
    }
    this.setConnectionState(DeviceStates.CONNECTED);

    // Report to google analytics
    const milliseconds = Math.round(performance.now() - startTime);
    shouldSendAnalytics() &&
      sendToAnalytics('device_connect', 'device', this.device.name, milliseconds);
    sendAnalytics('connect', this.device.name, this.device.id);
  }

  /**
   * Disconnect from the device
   */
  async disconnect(isWifiDevice) {
    const checkIsNotWindowsOS = () => {
      const platform = window.navigator?.userAgentData?.platform;
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      return windowsPlatforms.indexOf(platform) === -1;
    };

    this.setConnectionState(DeviceStates.DISCONNECTING);
    if (this.device.gatt.connected) {
      await this.device.gatt.disconnect();
    }
    this.setConnectionState(DeviceStates.DISCONNECTED);

    // ignore this for Windows since it gives reconnection device error
    if (checkIsNotWindowsOS() && !isWifiDevice) {
      this.device.forget?.();
    }
  }

  /**
   * Abstract method to vibrate the device, should be implemented in child classes
   * @param {int} percent - vibration intesity, 0..100
   */
  async write(percent, speed = 0, isBlowJob = false) {
    throw new Error('Not implemented');
  }

  /**
   * Pause the device's vibration (if needed for the device), should be implemented in child classes of devices that need it
   * @param {int} percent - vibration intesity, 0..100
   */
  async writePaused() {}

  /**
   * Abstract method to do ambient movement/vibration
   * @param {int} percent - vibration/movement intesity, 0..100
   */
  async setAmbientMovement(percent) {
    throw new Error('Not implemented');
  }

  /**
   * Abstract method to set maximum intensity/movement speed
   * @param {int} percent - vibration/movement maximum intesity, 0..100
   */
  async setMaxIntensity(percent) {
    throw new Error('Not implemented');
  }

  setDeviceMovementCallback(callback) {
    this.deviceMovementCallback = callback;
  }
}
