import React, { createContext, useState, useEffect } from 'react';
import jstz from 'jstz';
import ct from 'countries-and-timezones';
import { isEuMember } from 'is-eu-member';

import { createHook } from 'utils/utils';

const LOCAL_STORAGE_KEY = 'CURRENCY_CODE';

const getCodeCurrencyLocalStorage = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || null;
};

const UserSettingsContext = createContext();
const useUserSettings = () => createHook('useUserSettings', UserSettingsContext);

const UserSettingsContextProvider = ({ children }) => {
  let [codeCurrency, setCodeCurrency] = useState(getCodeCurrencyLocalStorage());

  useEffect(() => {
    if (!codeCurrency) {
      try {
        const timezoneName = jstz.determine().name();
        const countryCode = ct.getTimezone(timezoneName).countries[0];
        const isEu = isEuMember(countryCode);
        setCodeCurrency(isEu ? 'EUR' : 'USD');
      } catch (e) {
        setCodeCurrency('USD');
      }
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, codeCurrency);
    }
  }, [codeCurrency]);

  return (
    <UserSettingsContext.Provider
      value={{
        codeCurrency,
        setCodeCurrency,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsContextProvider, useUserSettings };
