import { BaseDeviceWrapper } from './BaseDeviceWrapper';
import image from 'images/devices/hotoctopuss-promotion.png';

const serviceUuid: string = '00001900-0000-1000-8000-00805f9b34fb';
const statusCharacteristicUuid: number = 0x1901;
const commandCharacteristicUuid: number = 0x1902;

const names: string[] = ['Pulse Interactive'];
const MAX_DEVICE_INTENSITY: number = 99;
const AMBIENT_MOVEMENT_KICK_IN_TIMEOUT: number = 2000; // ms
const MAX_VIBRATION_DURATION: number = 500; // ms

/**
 * Represents a Pulse Interactive device wrapper over the Web Bluetooth device object.
 */
export default class PulseInteractive extends BaseDeviceWrapper {
  private ambientIntensity: number;
  private maxIntensity: number;
  private stopVibrationTimeout: NodeJS.Timeout | null;
  private isVibratingAmbiently: boolean;
  private ambientMovementTimestamp: number;

  /**
   * Constructs a PulseInteractive instance.
   * @param device The Web Bluetooth device object.
   */
  constructor(device: BluetoothDevice) {
    super(device, serviceUuid, commandCharacteristicUuid, image, statusCharacteristicUuid);
    this.ambientIntensity = 0;
    this.maxIntensity = MAX_DEVICE_INTENSITY;
    this.stopVibrationTimeout = null;
    this.isVibratingAmbiently = false;
    this.ambientMovementTimestamp = performance.now() + AMBIENT_MOVEMENT_KICK_IN_TIMEOUT;
  }

  /**
   * Gets the device names supported by this wrapper.
   */
  static get deviceNames(): string[] {
    return names;
  }

  /**
   * Gets the services needed to request access before connecting to the device.
   */
  static get services(): string[] {
    return [serviceUuid];
  }

  /**
   * Gets the company name.
   */
  get companyName(): string {
    return 'Kiiroo';
  }

  /**
   * Connects to the device and initializes it with 0 percent intensity.
   */
  async connect(): Promise<void> {
    await super.connect();
    await this.motorChar.writeValue(new Uint8Array([0x00]));
  }

  /**
   * Handles stopping the vibration of the device.
   */
  async handleStopVibration(): Promise<void> {
    await this.motorWrite(0);
  }

  /**
   * Handles starting the vibration of the device.
   * @param speed The speed at which to start the vibration.
   */
  async handleStartVibration(speed: number): Promise<void> {
    await this.motorWrite(Math.floor((this.maxIntensity * speed) / 1000));
    if (!this.stopVibrationTimeout) {
      this.stopVibrationTimeout = setTimeout(async () => {
        await this.motorWrite(0);
      }, MAX_VIBRATION_DURATION);
    }
  }

  /**
   * Writes a value to the motor characteristic.
   * @param percent The percentage intensity to write.
   */
  async motorWrite(percent: number): Promise<void> {
    this.ambientMovementTimestamp = performance.now() + AMBIENT_MOVEMENT_KICK_IN_TIMEOUT;
    const array: Uint8Array = new Uint8Array([0x01, percent]);
    await this.motorChar.writeValue(array);
  }

  /**
   * Writes a value to the device, optionally starting vibration.
   * @param percent The percentage intensity to write.
   * @param speed The speed at which to start the vibration, defaulting to 100.
   */
  async write(percent: number, speed: number = 100): Promise<void> {
    if (percent === 100) {
      await this.handleStartVibration(speed);
    } else {
      await this.handleStopVibration();
    }
  }

  /**
   * Pauses the device's activity.
   */
  async writePaused(): Promise<void> {
    await this.motorWrite(0);
  }

  async onTimer(): Promise<void> {
    if (this.ambientIntensity == 0) {
      return;
    }
    if (performance.now() < this.ambientMovementTimestamp) {
      return;
    }
    this.ambientMovementTimestamp = performance.now() + MAX_VIBRATION_DURATION;
    if (this.isVibratingAmbiently) {
      await this.motorWrite(0);
    } else {
      await this.motorWrite(Math.floor(Math.min(this.ambientIntensity, 99) / 10));
    }
    this.isVibratingAmbiently = !this.isVibratingAmbiently;
  }

  /**
   * Sets the ambient movement intensity.
   * @param percent The percentage intensity to set.
   */
  async setAmbientMovement(percent: number): Promise<void> {
    this.ambientIntensity = percent;
    if (percent == 0) {
      await this.motorWrite(0);
    }
  }

  /**
   * Sets the maximum intensity.
   * @param percent The percentage intensity to set as the maximum.
   */
  async setMaxIntensity(percent: number): Promise<void> {
    this.maxIntensity = Math.min(MAX_DEVICE_INTENSITY, percent);
  }

  /**
   * Retrieves the battery level from the device.
   * @returns The battery level as a number.
   */
  async getBattery(): Promise<number | undefined> {
    const data: DataView = await this.statusChar.readValue();
    return data?.getUint8(5);
  }
}
