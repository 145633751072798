import {
  StrapiResult,
  downloadImageAsBase64,
  getImageUrl,
  getMulti,
} from '@feelrobotics/strapi-sdk';

const PROMO_TYPE = 'promo-banner';

async function getPromotionalBannerItems(): Promise<StrapiResult> {
  const searchString = 'fm-promotional-banner-items';

  const res = await getMulti(searchString, PROMO_TYPE);

  res.response = await Promise.all(
    (res.response as object[]).map(async (item) => {
      return {
        url: item['attributes'].url,
        image: await downloadImageAsBase64(getImageUrl(item['attributes'], 'image')),
        mobile_image: await downloadImageAsBase64(getImageUrl(item['attributes'], 'image_mobile')),
      };
    }),
  );

  return res;
}

export { getPromotionalBannerItems };
