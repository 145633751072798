import {
  StrapiResult,
  downloadImageAsBase64,
  getImageUrl,
  getMulti,
} from '@feelrobotics/strapi-sdk';

const SLIDER_TYPE = 'slider';

async function getAuthDevicesData(): Promise<StrapiResult> {
  const searchString = 'fm-landing-device-slider-items';

  const res = await getMulti(searchString, SLIDER_TYPE);
  res.response = await Promise.all(
    (res.response as Object[]).map(async (item) => {
      return {
        companyLogo: await downloadImageAsBase64(getImageUrl(item['attributes'], 'logo')),
        companyDevices: await downloadImageAsBase64(getImageUrl(item['attributes'], 'image')),
      };
    }),
  );
  return res;
}

export { getAuthDevicesData };
