import React, { createContext, useState, useMemo } from 'react';
import { ToastOptions, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { sendToAnalytics } from 'lib/googleAnalytics';
import { sendAnalytics } from '@feelrobotics/ftap-connector';
import { shouldSendAnalytics, createHook } from 'utils/utils';
import { discoverDevice, createDevice } from '../lib/devices/connect';
import { getDeviceDisplayName } from 'lib/devices/wrappers/DeviceUtils';

import * as ConnectionState from '../lib/devices/wrappers/DeviceStates';

import DeviceStateNotification from 'components/DeviceStateNotification';

import {
  ROUTE_PATHS,
  CONNECTED_NOTIFICATIONS_OPTIONS,
  DISCONNECTED_NOTIFICATIONS_OPTIONS,
} from 'data/constants';
import { names as powerblowNames } from 'lib/devices/wrappers/PowerBlow';
import { BaseDeviceWrapper } from 'lib/devices/wrappers/BaseDeviceWrapper';

export type Device = {
  error?: string;
  company?: string;
  id?: number;
  image?: string;
  name?: string;
  connectionState?: string;
  supportWifi?: boolean;
  wrapper?: BaseDeviceWrapper;
  usedConnectionMethod?: 'wifi' | 'bluetooth';
};

export const LOCAL_STORAGE_KEY = 'devices_list';
export const ALL_DEVICES_DEVICE_ID = 'all';

const TEST_DEVICE_DURATION_SEC = 0.5;
const TEST_DEVICE_INTENSITY_PERCENT = 100;

export interface ConnectedDevicesContextInterface {
  clearDevices: () => void;
  connectedDevices: Device[];
  currentDevice: Device;
  deleteDevice: (index: number) => void;
  devices: Device[];
  disconnect: (index: number) => void;
  discoverDevices: () => void;
  getConnectedDevices: () => Device[];
  getConnectionState: (device: Device) => string;
  isNewDeviceConnected: boolean;
  lastConnectedDevice: string;
  reconnectDevice: (index: number) => void;
  selectedDeviceIndex: number;
  setIsNewDeviceConnected: (isNewDeviceConnected: boolean) => void;
  setSelectedDeviceIndex: (index: number) => void;
  setShowWifiConnectModal: (state: boolean) => void;
  showWifiConnectModal: boolean;
  testDevice: (index: number) => void;
  tryToConnectToWiFiDevice: (device: Device) => void;
}

const ConnectedDevicesContext = createContext<ConnectedDevicesContextInterface | null>(null);
export const useConnectedDevices = () => createHook('useConnectedDevices', ConnectedDevicesContext);

const getLocalStorageDevices = () => {
  const localStorageDevicesStr = localStorage.getItem(LOCAL_STORAGE_KEY) || '[]';
  try {
    return JSON.parse(localStorageDevicesStr);
  } catch (e) {
    return [];
  }
};

const getConnectionState = (device: Device): string => {
  return device?.wrapper?.connectionState || ConnectionState.DISCONNECTED;
};

type Props = {
  children: React.ReactNode;
};

export const ConnectedDevicesContextProvider = (props: Props) => {
  const navigate = useNavigate();

  const [devices, setDevices] = useState<Device[]>(getLocalStorageDevices());
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState<number>(0);
  const currentDevice = devices[selectedDeviceIndex];

  // Is there a new device connection
  const [isNewDeviceConnected, setIsNewDeviceConnected] = useState<boolean>(false);
  const [lastConnectedDevice, setLastConnectedDevice] = useState<string>(null);
  const [showWifiConnectModal, setShowWifiConnectModal] = useState<boolean>(false);

  const connectedDevices = useMemo<Device[]>(
    () =>
      devices.filter((device: Device) => getConnectionState(device) === ConnectionState.CONNECTED),
    [devices],
  );

  const clearError = (device: Device) => {
    device.error = null;
  };

  const deleteDevice = async (index: number) => {
    await disconnect(index);
    setDevices((oldDevices: Device[]) => {
      const newDevices = [...oldDevices];
      newDevices.splice(index, 1);
      return newDevices;
    });
  };

  const disconnect = async (index: number) => {
    const device = devices[index];
    device.connectionState = ConnectionState.DISCONNECTED;
    clearError(device);
    if (getConnectionState(device) !== ConnectionState.CONNECTED) {
      // Not connected
      return;
    }

    await device.wrapper?.disconnect();
    toast.dismiss();
    // Delay the notification by 500ms.
    // This is a workaround for connect/disconnect notifications instantly disappearing.
    setTimeout(() => {
      toast(
        <DeviceStateNotification device={device} state={ConnectionState.DISCONNECTED} />,
        // @ts-expect-error: TS2345
        DISCONNECTED_NOTIFICATIONS_OPTIONS,
      );
    }, 500);
    setDevices([...devices]);
  };

  const makeDeviceFromWrapper = (base: Device, deviceWrapper: BaseDeviceWrapper) => {
    return {
      ...base,
      id: deviceWrapper.device.id,
      name: deviceWrapper.device.name,
      // @ts-expect-error: TS2339
      company: deviceWrapper?.companyName,
      image: deviceWrapper.image,
      wrapper: deviceWrapper,
      connectionState: ConnectionState.CONNECTED,
      supportWifi: deviceWrapper?.supportWifi ?? false,
    };
  };

  const tryToConnectToDevice = async (
    deviceWrapper: BaseDeviceWrapper,
    isConnectionByWifi: boolean = false,
  ) => {
    try {
      // @ts-expect-error: TS2345
      await deviceWrapper?.connect(isConnectionByWifi);
      // Connection successful
      const newDevice = await addNewDevice(deviceWrapper);

      setIsNewDeviceConnected(true);
      newDevice.usedConnectionMethod = isConnectionByWifi ? 'wifi' : 'bluetooth';
      setLastConnectedDevice(newDevice.name);
      toast.dismiss();
      // Delay the notification by 500ms.
      // This is a workaround for connect/disconnect notifications instantly disappearing.
      setTimeout(() => {
        toast(
          <DeviceStateNotification device={newDevice} state={ConnectionState.CONNECTED} />,
          // @ts-expect-error: TS2345
          CONNECTED_NOTIFICATIONS_OPTIONS,
        );
      }, 500);
      // Auto-navigate to the Settings page when specific devices are connected
      if (powerblowNames.includes(newDevice.name)) {
        navigate(`${ROUTE_PATHS.DASHBOARD}${ROUTE_PATHS.SETTINGS}`);
      }
    } catch (error) {
      console.error(error);
      // Display error
      const deviceError = error.toString();
      const deviceName = getDeviceDisplayName(deviceWrapper.device.name);
      toast.error(`Something happened with your ${deviceName}, please try again`);

      sendToAnalytics('device_connect_fail', deviceName, deviceError);
      shouldSendAnalytics() && sendAnalytics('device_connect_fail', deviceName, deviceError);
    }
  };

  const tryToConnectToWiFiDevice = async (device: Device) => {
    const wifiDevice = {
      ...device,
      addEventListener: function (eventType, eventHandler) {
        console.log('addEventListener', eventType, eventHandler);
      },
    };
    const deviceWrapper = createDevice(wifiDevice, () => {});
    // Now we need to connect to the device that has been just discovered
    await tryToConnectToDevice(deviceWrapper, true);
  };

  const addNewDevice = async (deviceWrapper: BaseDeviceWrapper) => {
    const index = devices.findIndex((dev: Device) => dev.name === deviceWrapper.device.name);

    let newDevice = null;
    if (index >= 0) {
      // Device is already in the list
      const newDevices = [...devices];
      newDevices[index] = makeDeviceFromWrapper(newDevices[index], deviceWrapper);
      clearError(newDevices[index]);
      setSelectedDeviceIndex(index);
      setDevices(newDevices);
      newDevice = newDevices[index];
    } else {
      const emptyDevice: Device = {};
      newDevice = makeDeviceFromWrapper(emptyDevice, deviceWrapper);
      setDevices([...devices, newDevice]);
      setSelectedDeviceIndex(devices.length);
    }
    return newDevice;
  };

  const discoverDevices = async () => {
    const deviceWrapper = await discoverDevice(() => {
      // Refresh devices on every device state change
      setDevices((oldDevices) => [...oldDevices]);
    });

    if (!deviceWrapper) {
      // No device connected
      return;
    }

    if (deviceWrapper?.unsupported) {
      toast.error(`Device is not supported`);
      deviceWrapper.disconnect();
      return;
    }

    // Now we need to connect to the device that has been just discovered
    tryToConnectToDevice(deviceWrapper);
  };

  const reconnectDevice = async (index: number) => {
    const device = devices[index];
    clearError(device);
    if (!device?.wrapper?.server) {
      if (device.usedConnectionMethod === 'wifi') {
        setShowWifiConnectModal(true);
      } else {
        await discoverDevices();
      }
      return;
    }

    tryToConnectToDevice(device?.wrapper);
    setDevices([...devices]);
  };

  /**
   * Move the device as a test
   * @param {number} index - device index in devices
   */
  const testDevice = async (index: number) => {
    const device = devices[index];
    clearError(device);
    if (getConnectionState(device) !== ConnectionState.CONNECTED) {
      return;
    }

    // @ts-expect-error: TS2339
    if (device.wrapper?.testDevice) {
      // @ts-expect-error: TS2339
      await device.wrapper?.testDevice();
      return;
    }

    const moveDevice = async (percent) => {
      const dev = devices.find((d) => d.id === device.id);
      await dev.wrapper?.write(percent);
    };

    moveDevice(TEST_DEVICE_INTENSITY_PERCENT);
    setTimeout(() => {
      moveDevice(0);
    }, TEST_DEVICE_DURATION_SEC * 1000);
  };

  const clearDevices = () => {
    setDevices([]);
  };

  const getConnectedDevices = () => connectedDevices;

  return (
    <ConnectedDevicesContext.Provider
      value={{
        clearDevices,
        connectedDevices,
        currentDevice,
        deleteDevice,
        devices,
        disconnect,
        discoverDevices,
        getConnectedDevices,
        getConnectionState,
        isNewDeviceConnected,
        lastConnectedDevice,
        reconnectDevice,
        selectedDeviceIndex,
        setIsNewDeviceConnected,
        setSelectedDeviceIndex,
        setShowWifiConnectModal,
        showWifiConnectModal,
        testDevice,
        tryToConnectToWiFiDevice,
      }}
    >
      {props.children}
    </ConnectedDevicesContext.Provider>
  );
};
