import React from 'react';
import { translations } from 'i18n';
import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

// Device intensity percent value which corresponds
// to the minimum value on the slider
const MIN_INTENSITY = 35;

export const Intensity = () => {
  const { intensity, setIntensity } = useChromeExtensionPopup();
  const sliderValue = ((intensity - MIN_INTENSITY) / (100 - MIN_INTENSITY)) * 100;
  return (
    <>
      <label>
        <span className={styles.settingsLabel}>{translations('ExtensionPopup.PowerControl')}:</span>
        <span className={styles.settingsLabelDescription}>
          {translations('ExtensionPopup.PowerControlDescription')}
        </span>
        <input
          className={styles.settingsRange}
          type="range"
          list="intensity"
          min={MIN_INTENSITY}
          max="100"
          step={(100 - MIN_INTENSITY) / 2}
          value={intensity}
          onChange={(evt) => {
            setIntensity(evt.target.value);
          }}
          style={{
            background: `linear-gradient(to right, #A9C6FE 0%, #A9C6FE ${sliderValue}%, #4d445b ${sliderValue}%, #4c445a 100%)`,
          }}
        />
      </label>
      <datalist id="intensity" className={styles.settingsOptions}>
        <option value="1" label="Low"></option>
        <option value="2" label="Medium"></option>
        <option value="3" label="High"></option>
      </datalist>
    </>
  );
};
