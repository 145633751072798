import React from 'react';
import { translations } from 'i18n';

import CheckBox from 'components/common/CheckBox';
import { useChromeExtensionPopup } from 'ChromeExtensionPopup/hooks/ChromeExtensionPopupHook';
import styles from '../PopupContainer/style.module.scss';

export const AutopilotSwitch = () => {
  const { autopilot, setAutopilot } = useChromeExtensionPopup();

  return (
    <>
      <div
        className={styles.autopilotCheckboxRow}
        onClick={() => {
          setAutopilot(!autopilot);
        }}
      >
        <CheckBox checked={autopilot} />
        <span>{translations('ExtensionPopup.Autopilot')}</span>
      </div>
      <div>
        <span className={styles.settingsLabelDescription}>
          {translations('ExtensionPopup.AutopilotDescription')}
        </span>
      </div>
    </>
  );
};
