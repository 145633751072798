import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

import { sendToAnalyticsCustomEventObj } from 'lib/googleAnalytics';
import {
  AuthResponse,
  RegistrationTokenData,
  ResponseRegistrationToken,
  TokenPayload,
} from './models/response/AuthResponse';

const baseURL: string = process.env.REACT_APP_OAUTH_SERVER_API_URL ?? '';
const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refreshToken';
const REGISTRATION_TOKEN_LOCAL_STORAGE_KEY = 'registrationToken';
export const CC_WSS_URL_LOCAL_STORAGE_KEY = 'ccWsServerUrl';

class OauthApiArgs {
  path: string = '';
  params: string = '';
  options = {};
  headers: Headers;
  data: unknown;
}

const oauthApi = async (
  args: OauthApiArgs,
): Promise<AuthResponse | ResponseRegistrationToken | null> => {
  // If the OAuth server URL is not filled in, we ignore the token receiving functionality.
  if (!baseURL) {
    return;
  }

  const rawResponse = await fetch(
    baseURL + args.path + (args.params ? '?' + new URLSearchParams(args.params) : ''),
    {
      method: 'POST',
      headers: args.headers,
      body: args.data && JSON.stringify(args.data),
      ...args.options,
    },
  );

  if (!rawResponse.ok) {
    if (rawResponse.status !== 401 && rawResponse.status !== 404) {
      toast.error(rawResponse.status + ' ' + rawResponse.statusText);
    }
    sendToAnalyticsCustomEventObj('oauth_api_error', {
      status: rawResponse.status,
      message: rawResponse.statusText,
    });
    throw new Error(rawResponse.statusText);
  } else if (rawResponse.status === 204) {
    // No Content or not JSON type
    return;
  } else {
    // Check if the response has content
    const contentType = rawResponse.headers.get('content-type');
    if (contentType) {
      return await rawResponse.json();
    }
  }
};

const fetchAccessToken = async (): Promise<[string | null, string | null]> => {
  const headers = new Headers();

  try {
    // Attempt to get the current user session token
    const cognitoIdToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    headers.set('Authorization', `Bearer ${cognitoIdToken}`);
  } catch (error) {
    console.error('Error retrieving user session: ', error);
    return [null, null];
  }

  const args = new OauthApiArgs();
  args.path = '/api/token/access';
  args.headers = headers;
  const response = await oauthApi(args);
  if (response && 'access_token' in response) {
    return [response.access_token, response.refresh_token];
  }
  return [null, null];
};

const getTokenPayload = <T>(token: string): T | null => {
  if (!token) {
    return;
  }
  return jwtDecode<T>(token);
};

const isTokenValid = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const decodedToken = getTokenPayload<TokenPayload>(token);
  if (decodedToken && decodedToken.exp) {
    const halfwayExpiration = new Date(
      decodedToken.exp * 1000 - (decodedToken.exp * 1000 - decodedToken.iat * 1000) / 2,
    );
    const currentTime = new Date();

    if (currentTime > halfwayExpiration) {
      return false;
    }
  }
  return true;
};

const getRegistrationTokenData = (token: string): RegistrationTokenData => {
  const payload = getTokenPayload<TokenPayload<RegistrationTokenData>>(token);
  return payload.data;
};

const getWebSocketServerUrl = (token: string): string => {
  return getRegistrationTokenData(token).wss_cc_server_url;
};

export const getAccessToken = async (): Promise<string | null> => {
  let accessToken: string = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  let refreshToken: string;
  if (!isTokenValid(accessToken)) {
    [accessToken, refreshToken] = await fetchAccessToken();
    if (!accessToken) {
      localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
      return;
    }
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);
  }
  return accessToken;
};

const fetchRegistrationToken = async (): Promise<string | null> => {
  const headers = new Headers();

  const jwt_token = await getAccessToken();
  if (!jwt_token) {
    return;
  }

  headers.set('Authorization', `Bearer ${jwt_token}`);

  const args = new OauthApiArgs();
  args.path = '/api/token/registration';
  args.headers = headers;

  const response = await oauthApi(args);
  if (response && 'registration_token' in response) {
    return response.registration_token;
  }
};

export const getRegistrationToken = async (): Promise<[string | null, string | null]> => {
  let registrationToken: string = localStorage.getItem(REGISTRATION_TOKEN_LOCAL_STORAGE_KEY);
  if (!isTokenValid(registrationToken)) {
    registrationToken = await fetchRegistrationToken();
    if (!registrationToken) {
      localStorage.removeItem(REGISTRATION_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(CC_WSS_URL_LOCAL_STORAGE_KEY);
      return;
    }
    localStorage.setItem(REGISTRATION_TOKEN_LOCAL_STORAGE_KEY, registrationToken);
    localStorage.setItem(CC_WSS_URL_LOCAL_STORAGE_KEY, getWebSocketServerUrl(registrationToken));
  }
  return [registrationToken, getWebSocketServerUrl(registrationToken)];
};
