/**
 * @class AsyncQueue
 * A class that contains a queue of asynchronous functions.
 * You can add functions to the queue and they will be executed one by one.
 * If you add a function to the queue while it's already executing,
 * it will be executed after the current function is finished.
 */
export class AsyncQueue {
  queue: Array<() => Promise<void>>;
  isExecuting: boolean;

  constructor() {
    this.queue = [];
    this.isExecuting = false;
  }

  async execute(func) {
    this.queue.push(func);
    if (this.isExecuting) {
      return;
    }
    this.isExecuting = true;
    while (this.queue.length > 0) {
      const nextFunc = this.queue.shift();
      await nextFunc();
    }
    this.isExecuting = false;
  }
}


/**
 * @class AsyncQueueIter
 * Another version of the class above, but working on an iteration type.
 * Applicable when waiting for confirmation from the device via the BT channel.
 */
export class AsyncQueueIter {
  queue: Array<() => Promise<void>>;
  isExecuting: boolean;

  constructor() {
    this.queue = [];
    this.isExecuting = false;
  }

  async add(func) {
    this.queue.push(func);
    this.next();
  }

  async next() {
    if (this.isExecuting) {
      return;
    }
    this.isExecuting = true;
    if (this.queue.length > 0) {
      const nextFunc = this.queue.shift();
      await nextFunc();
    }
    this.isExecuting = false;
  }
}
