import React, { createContext, useState, useEffect, useRef } from 'react';
import { toast, Id } from 'react-toastify';
import differenceBy from 'lodash/differenceBy';

import { createHook } from 'utils/utils';
import { useConnectedDevices } from 'hooks/ConnectedDevicesHook';
import { BATTERY_THRESHOLD_WARNING } from 'data/constants';
import DeviceStateNotification from 'components/DeviceStateNotification';
import { BATTERY_NOTIFICATIONS_OPTIONS, APP_TITLE } from 'data/constants';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import { translations } from 'i18n';
import { getDeviceDisplayName } from 'lib/devices/wrappers/DeviceUtils';

type Props = {
  children: React.ReactNode;
};

type BatteryDevice = {
  deviceId: string;
  battery?: number;
};

type AlmostDiedDevice = {
  id?: string;
  toastId?: Id;
};

export interface DeviceBatteryContextInterface {
  batteryPerDevice: Array<BatteryDevice> | null;
}

const DeviceBatteryContext = createContext<DeviceBatteryContextInterface | null>(null);
const useDeviceBattery = () => createHook('useDeviceBattery', DeviceBatteryContext);

const DeviceBatteryContextProvider = (props: Props) => {
  const almostDiedDevices = useRef<AlmostDiedDevice[]>([{}]);
  const [batteryPerDevice, setBatteryPerDevice] = useState<Array<BatteryDevice> | null>(null);
  const pageIsvisible = useRef(true);

  const { connectedDevices } = useConnectedDevices();

  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        pageIsvisible.current = false;
      } else {
        pageIsvisible.current = true;
        document.title = APP_TITLE;
        const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
        link.href = '/favicon.ico';
      }
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);

  const BATTERY_UPDATE_TIMEOUT = 60 * 1000; // every minute
  // get battery status
  useEffect(() => {
    const devicesWithBatteryData = connectedDevices.filter(
      (device: any) => device.wrapper?.getBattery,
    );
    let interval: ReturnType<typeof setInterval>;
    if (devicesWithBatteryData.length) {
      // request permissions until user makes his choice (maximum 3 times)
      Notification.requestPermission();

      const requestBatteryData = () => {
        let batterylevels: Array<BatteryDevice> = [];
        devicesWithBatteryData.forEach(async (device: any) => {
          const battery = await device.wrapper?.getBattery?.();

          batterylevels = [...batterylevels, { battery, deviceId: device.id }];
          setBatteryPerDevice(batterylevels);

          // show warning only once for each device
          if (almostDiedDevices.current.some(({ id }) => id === device.id)) {
            return;
          }

          if (battery > BATTERY_THRESHOLD_WARNING) {
            return;
          }

          toast.dismiss();
          const toastId = toast(
            <DeviceStateNotification device={device} state={ConnectionState.LOW_BATTERY} />,
            // @ts-expect-error
            BATTERY_NOTIFICATIONS_OPTIONS,
          );
          almostDiedDevices.current.push({ id: device.id, toastId });

          if (!pageIsvisible.current) {
            const deviceName = getDeviceDisplayName(device.name);
            document.title = `Battery of your ${deviceName} is low`;
            const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
            link.href = '/battery-red.png';

            // create a new system notification
            const notification = new Notification(
              translations('Notifications.OopsYourBatteryIsLow', { deviceName }),
              {
                body: translations('Notifications.ToAvoidDisappointmentPutYourDeviceOnCharge'),
                icon: '/favicon.ico',
              },
            );

            // close the notification after 10 seconds
            setTimeout(() => {
              notification.close();
            }, 10 * 1000);
          }
        });
      };

      requestBatteryData();
      interval = setInterval(() => {
        requestBatteryData();
      }, BATTERY_UPDATE_TIMEOUT);
    } else {
      setBatteryPerDevice(null);
    }
    return () => clearInterval(interval);
  }, [connectedDevices.length]);

  useEffect(() => {
    // find disconnected device and dismiss notification for it
    const differences = differenceBy(almostDiedDevices.current, connectedDevices, 'id');
    differences.forEach(({ toastId }) => {
      if (toastId) {
        toast.dismiss(toastId);
        const found = almostDiedDevices.current.findIndex(({ toastId: tId }) => toastId == tId);
        almostDiedDevices.current.splice(found, 1);
      }
    });
  }, [connectedDevices.length]);

  return (
    <DeviceBatteryContext.Provider
      value={{
        batteryPerDevice,
      }}
    >
      {props.children}
    </DeviceBatteryContext.Provider>
  );
};

export { DeviceBatteryContextProvider, useDeviceBattery };
