import React from 'react';
import { translations } from 'i18n';
import styles from './style.module.scss';

export const NotLoggedIn = () => {
  return (
    <div className={styles.container}>
      <div className={styles.backdrop} />
      <p>{translations('ExtensionPopup.NotLoggedIn')}</p>
    </div>
  );
};
