import React from 'react';

import styles from './style.module.scss';
import lowBattery from 'images/device-status/battery-low-icon.png';
import connected from 'images/device-status/connected-icon.png';
import disconnected from 'images/device-status/disconnected-icon.png';
import { translations } from 'i18n';
import * as ConnectionState from 'lib/devices/wrappers/DeviceStates';
import powerblow from 'images/devices/powerblow-promotion.png';
import { getDeviceDisplayName } from 'lib/devices/wrappers/DeviceUtils';

const DeviceStateNotification = ({ device, state, isDefaultMode = null }) => {
  let image, title, description;
  switch (state) {
    case ConnectionState.LOW_BATTERY:
      image = <img src={lowBattery} alt="batteryLow" className={styles.icon} draggable="false" />;
      title = translations('Notifications.OopsYourBatteryIsLow', {
        deviceName: getDeviceDisplayName(device.name),
      });
      description = translations('Notifications.ToAvoidDisappointmentPutYourDeviceOnCharge');
      break;
    case ConnectionState.CONNECTED:
      image = <img src={connected} alt="connected" className={styles.icon} draggable="false" />;
      title = translations('Notifications.DeviceIsConnected', {
        deviceName: getDeviceDisplayName(device.name),
      });
      description = '';
      break;

    case ConnectionState.DISCONNECTED:
    case ConnectionState.CONNECTING:
      const message = translations('Notifications.DeviceIsDisconnected', {
        deviceName: getDeviceDisplayName(device.name),
      });
      image = (
        <img src={disconnected} alt="disconnected" className={styles.icon} draggable="false" />
      );
      title = message.startsWith('[missing')
        ? translations('Notifications.DeviceIsDisconnectedDefault')
        : message;
      description =
        state === ConnectionState.CONNECTING
          ? translations('Notifications.TryingToConnect')
          : translations('Notifications.ReconnectManually');
      break;
    case ConnectionState.POWERBLOW_SETTINGS:
      image = (
        <img src={powerblow} alt="powerblow" className={styles.powerblow} draggable="false" />
      );
      title = getDeviceDisplayName(device.name);
      description = translations(
        isDefaultMode
          ? 'SettingsPage.PowerBlowNotificationDefault'
          : 'SettingsPage.PowerBlowNotificationCustom',
      );
      break;
    default:
      return null;
  }

  return (
    <div className={styles.container}>
      {image}
      <div className={styles.infoContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default DeviceStateNotification;
