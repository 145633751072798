import React, { createContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { createHook, decrypt, queryParamsToObject } from 'utils/utils';
import { AFFILIATES_REF_KEY, USER_ID_KEY } from 'data/constants';
import billingApi from '../api/billing-api';

const AffiliateContext = createContext();
const useAffiliate = () => createHook('useAffiliate', AffiliateContext);

const AffiliateContextProvider = ({ children }) => {
  const [referralCode, setReferralCode] = useState('');
  const [userId, setUserId] = useState('');
  const [searchParams] = useSearchParams();

  const saveAffiliateCode = (affiliateCode) => {
    const currentDate = Date.now();
    localStorage.setItem(AFFILIATES_REF_KEY, JSON.stringify(currentDate + affiliateCode));
  };

  const getEncryptedPayload = () => {
    const payload = searchParams.get('payload')?.replace(/^\?/, '');
    if (!payload) {
      return null;
    }
    return decodeURIComponent(payload);
  };

  /**
   * Retrieves and processes an encrypted payload from the URL's query parameters.
   * It decrypts the payload and converts it into an object representation of these parameters.
   *
   * @returns {Object|null} An object representing the decrypted query parameters if the payload is found and successfully decrypted, null otherwise.
   */
  const getDecryptedParamsObject = () => {
    const encryptedPayload = getEncryptedPayload();
    if (!encryptedPayload) {
      return null;
    }
    const decryptedPayload = decrypt(encryptedPayload);
    const params = new URLSearchParams(decryptedPayload);
    return queryParamsToObject(params);
  };

  /**
   * Updates the component states for email, password, and referral code based on the provided result object.
   *
   * @param {Object} resultObject - The object containing member details including email, password, and optionally meta information.
   * @param {Function} setAutocompleteEmail - State setter function for setting the email.
   * @param {Function} setAutocompletePassword - State setter function for setting the password.
   * @param {Function} setReferralCode - State setter function for setting the referral code.
   */
  const setComponentStates = (
    resultObject,
    setAutocompleteEmail,
    setAutocompletePassword,
    setReferralCode,
  ) => {
    setAutocompleteEmail(resultObject.member.email);
    setAutocompletePassword(resultObject.member.password);
    setReferralCode(resultObject.member.meta?.lander);
  };

  const captureEncryptedParameters = (
    setAutocompleteEmail,
    setAutocompletePassword,
    setReferralCode,
  ) => {
    try {
      const resultObject = getDecryptedParamsObject();
      if (resultObject && resultObject.member) {
        setComponentStates(
          resultObject,
          setAutocompleteEmail,
          setAutocompletePassword,
          setReferralCode,
        );
      }
    } catch (error) {
      console.error('Failed to retrieve encrypted URL parameters:', error);
    }
  };

  const createAffiliateEvent = (eventType, isUserConfirmed, userIdToSend, referralCodeToSend) => {
    return {
      id: uuidv4(),
      event_type: eventType,
      content: {
        signup: {
          date: Math.floor(new Date().getTime() / 1000),
          confirmed: isUserConfirmed,
          referral_code: referralCodeToSend,
        },
        customer: {
          id: userIdToSend,
        },
      },
    };
  };

  const sendAffiliateEvent = async (
    eventType,
    isUserConfirmed,
    userIdToSend,
    referralCodeToSend,
  ) => {
    if (!referralCodeToSend || !userIdToSend) {
      return;
    }
    const event = createAffiliateEvent(
      eventType,
      isUserConfirmed,
      userIdToSend,
      referralCodeToSend,
    );
    try {
      billingApi('/affiliate', false, {}, { method: 'POST' }, event);
    } catch (error) {
      // Handle the error
      console.error('Fetching data failed', error);
    }
  };

  const sendSignUpEvent = (receivedUserId, receivedReferralCode) => {
    setUserId(receivedUserId);
    setReferralCode(receivedReferralCode);
    return sendAffiliateEvent('sign_up_initiated', false, receivedUserId, receivedReferralCode);
  };

  const sendConfirmSignUpEvent = () => {
    const savedUserId = sessionStorage.getItem(USER_ID_KEY);
    const savedReferralCode = localStorage.getItem(AFFILIATES_REF_KEY);
    return sendAffiliateEvent('sign_up_confirmed', true, savedUserId, savedReferralCode);
  };

  useEffect(() => {
    referralCode && saveAffiliateCode(referralCode);
    userId && sessionStorage.setItem(USER_ID_KEY, userId);
  }, [referralCode, userId]);

  return (
    <AffiliateContext.Provider
      value={{
        sendSignUpEvent,
        sendConfirmSignUpEvent,
        saveAffiliateCode,
        captureEncryptedParameters,
        referralCode,
      }}
    >
      {children}
    </AffiliateContext.Provider>
  );
};

export { AffiliateContextProvider, useAffiliate };
