/**
 * This hook is used to visualize the device movement and ML signals.
 * It is only used if the URL contains the string "#test".
 * It exposes an onDraw function that accepts a canvas context and draws the visualization on it.
 * It also exposes an onDeviceMove function that accepts a device movement
 * command and adds it to the visualization.
 */
import React, { createContext, useRef } from 'react';

import { createHook } from 'utils/utils';
import { isTestUrl } from 'utils/isTesting';

interface DeviceVisualizationContextInterface {
  // Called when a device has actually moved. For example, Keon
  // can report its position via Bluetooth notification. When
  // this happens, this function is called.
  onDeviceMove: (movement: DeviceMovementCommand) => void;
  // Called when the visualization should be drawn on the canvas
  onDraw: (ctx: CanvasRenderingContext2D) => void;
  // Called when a ML signal is received from the server
  onMlSignal: (signal: MLSignal) => void;
  // Called when a device command is sent to the devices
  onDeviceCommandSentToDevices: (positionValue: number, isMadeByFuturePrediction: boolean) => void;
}

type DeviceMovementCommand = {
  // Position of the device 0..100
  position: number;
};

type Props = {
  children: React.ReactNode;
};

type MLSignal = {
  timestampSent: number; // Timestamp in milliseconds, when the signal was sent
  timestampReceived: number; // Timestamp in milliseconds, when the signal was received
  value: number; // Value between 0 and 1
  period: number; // Future prediction period in frames
  future: Array<number>; // Array of predicted future values
};

type DeviceMovementEvent = {
  timestamp: number; // Timestamp in milliseconds
  command: DeviceMovementCommand;
  isMadeByFuturePrediction?: boolean;
};

// How many milliseconds of history to show
const VISIBLE_MILLISECONDS = 5000;

// How many milliseconds between each frame
const FRAME_DURATION_MSEC = 1000 / 30;

// How many pixels to show in the future
const FUTURE_WIDTH_PIXELS = 100;

const DeviceVisualizationContext = createContext<DeviceVisualizationContextInterface | null>(null);

// This is a dummy implementation of the hook that is used when
// the URL does not contain the string "#test".
const dummyDeviceVisualization = {
  onDeviceMove: () => {},
  onDraw: () => {},
  onMlSignal: () => {},
  onDeviceCommandSentToDevices: () => {},
};

export const useDeviceVisualization = () => {
  if (isTestUrl()) {
    return createHook('useDeviceVisualization', DeviceVisualizationContext);
  } else {
    return dummyDeviceVisualization;
  }
};

export const DeviceVisualizationContextProvider = (props: Props) => {
  const mlSignals = useRef<MLSignal[]>([]);
  const deviceEvents = useRef<DeviceMovementEvent[]>([]);
  const deviceCommandsSentToDevices = useRef<DeviceMovementEvent[]>([]);

  const onDeviceCommandSentToDevices = (
    positionValue: number,
    isMadeByFuturePrediction: boolean,
  ) => {
    const old = deviceCommandsSentToDevices.current;
    const newEvent: DeviceMovementEvent = {
      timestamp: Date.now(),
      command: {
        position: positionValue,
      },
      isMadeByFuturePrediction: isMadeByFuturePrediction,
    };
    const newDeviceEvents = [...old, newEvent];
    const cutoff = Date.now() - VISIBLE_MILLISECONDS;
    const filteredDevicePositions = newDeviceEvents.filter((s) => s.timestamp > cutoff);
    deviceCommandsSentToDevices.current = filteredDevicePositions;
  };

  const onDeviceMove = (command: DeviceMovementCommand) => {
    const old = deviceEvents.current;
    const newEvent: DeviceMovementEvent = {
      timestamp: Date.now(),
      command,
    };
    const newDeviceEvents = [...old, newEvent];
    const cutoff = Date.now() - VISIBLE_MILLISECONDS;
    const filteredDevicePositions = newDeviceEvents.filter((s) => s.timestamp > cutoff);
    deviceEvents.current = filteredDevicePositions;
  };

  const onMlSignal = (signal: MLSignal) => {
    const old = mlSignals.current;
    const newMlSignals = [...old, signal];
    const cutoff = Date.now() - VISIBLE_MILLISECONDS;
    const filteredMlSignals = newMlSignals.filter((s) => s.timestampReceived > cutoff);
    mlSignals.current = filteredMlSignals;
  };

  const onDraw = (ctx: CanvasRenderingContext2D) => {
    if (!ctx) {
      return;
    }
    const width = ctx.canvas.clientWidth;
    const height = ctx.canvas.clientHeight;
    const now = Date.now();

    /**
     * Convert timestamp to x-coordinate on the canvas
     * @param timestamp
     * @returns x-coordinate
     */
    const timestampToX = (timestamp: number) => {
      return width - FUTURE_WIDTH_PIXELS - ((now - timestamp) / VISIBLE_MILLISECONDS) * width;
    };

    /**
     * Convert device signal value to Y coordinate in 4th quarter of the canvas from the bottom
     * @param value device signal value (0..100)
     * @returns Y-coordinate of the signal value
     */
    const valueToYDevice = (value: number) => {
      return height / 4 - (value * height) / 400;
    };

    /**
     * Convert ML signal value to Y coordinate in 3rd quarter of the canvas from the bottom
     * @param value ML signal value (0..1)
     * @returns Y-coordinate of the signal value
     */
    const valueToYIdeal = (value: number) => {
      return (2 * height) / 4 - (value * height) / 400;
    };

    /**
     * Convert ML signal value to Y coordinate in 2nd quarter of the canvas from the bottom
     * @param value ML signal value (0..1)
     * @returns Y-coordinate of the signal value
     */
    const valueToYSignalReceived = (value: number) => {
      return (height * 3) / 4 - (value * height) / 4;
    };

    /**
     * Convert ML signal value to Y coordinate in the lowest quarter of the canvas
     * @param value ML signal value (0..1)
     * @returns Y-coordinate of the signal value
     */
    const valueToYSignalSent = (value: number) => {
      return height - (value * height) / 4;
    };

    const drawBackground = () => {
      // Draw background
      ctx.fillStyle = 'black';
      ctx.strokeStyle = 'white';
      ctx.fillRect(0, 0, width, height);
    };

    const drawNowLine = () => {
      // Draw vertical line at x position of width - FUTURE_WIDTH_PIXELS
      ctx.strokeStyle = 'gray';
      ctx.beginPath();
      ctx.moveTo(width - FUTURE_WIDTH_PIXELS, 0);
      ctx.lineTo(width - FUTURE_WIDTH_PIXELS, height);
      ctx.stroke();
    };

    const drawSignalsAsTheyReceived = () => {
      // Draw moments when signals were received
      ctx.strokeStyle = 'white';
      ctx.beginPath();
      mlSignals.current.forEach((signal, idx) => {
        const { period, value, timestampReceived } = signal;
        const x = timestampToX(timestampReceived);
        const y = valueToYSignalReceived(value);
        // Draw a line to x,y
        idx ? ctx.lineTo(x, y) : ctx.moveTo(x, y);
        // Draw a circle at x,y
        ctx.arc(x, y, 2, 0, 2 * Math.PI);
        if (period) {
          ctx.strokeText(period.toString(), x, y);
        }
      });
      ctx.stroke();
    };

    // Draw the signal how it's supposed to be if
    // there was 0 network latency
    const drawIdealPositionOfCommands = () => {
      ctx.beginPath();
      ctx.strokeStyle = '#F9F';
      mlSignals.current.forEach((signal, idx) => {
        const { value, timestampSent } = signal;
        const x = timestampToX(timestampSent);
        const y = valueToYIdeal(value * 100);
        // Draw a line to x,y
        idx ? ctx.lineTo(x, y) : ctx.moveTo(x, y);
        // Draw a circle at x,y
        ctx.arc(x, y, 2, 0, 2 * Math.PI);
      });
      ctx.stroke();
    };

    const drawMessagesToServerAndBack = () => {
      // Draw the dots at 0 at the moments when signals were received
      ctx.beginPath();
      mlSignals.current.forEach((signal, idx) => {
        const x = timestampToX(signal.timestampReceived);
        const y = valueToYSignalReceived(0);
        ctx.moveTo(x, y);
        ctx.arc(x, y, 2, 0, 2 * Math.PI);
      });
      ctx.stroke();

      // Draw the dots at the moments when signals were sent and lines to the moment they were received
      ctx.strokeStyle = 'yellow';
      mlSignals.current.forEach((signal) => {
        ctx.beginPath();
        const x1 = timestampToX(signal.timestampSent);
        const y1 = valueToYSignalSent(0);
        const x2 = timestampToX(signal.timestampReceived);
        const y2 = valueToYSignalReceived(0);
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
      });
    };

    const drawDeviceActualMovement = () => {
      // Draw device movement commands at the moment they were sent to device
      ctx.strokeStyle = 'red';
      deviceEvents.current.forEach((movementEvent, idx) => {
        const { command } = movementEvent;
        const x = timestampToX(movementEvent.timestamp);
        const y = valueToYDevice(command.position);
        ctx.beginPath();
        // draw a circle at x,y
        ctx.arc(x, y, 2, 0, 2 * Math.PI);
        ctx.stroke();
        if (idx) {
          // Draw a line from the previous device pos to this pos
          const { command: prevCommand, timestamp: prevTimestamp } = deviceEvents.current[idx - 1];
          const x0 = timestampToX(prevTimestamp);
          const y0 = valueToYDevice(prevCommand.position);
          ctx.beginPath();
          ctx.moveTo(x0, y0);
          ctx.lineTo(x, y);
          ctx.stroke();
        }
      });
    };

    const drawPerfectCommandForDevice = () => {
      if (!deviceCommandsSentToDevices.current.length) {
        return;
      }

      const lastCommand =
        deviceCommandsSentToDevices.current[deviceCommandsSentToDevices.current.length - 1];
      const x = timestampToX(lastCommand.timestamp);
      const deviceValue = lastCommand.command.position;
      const y = valueToYDevice(deviceValue * 100);
      ctx.beginPath();
      ctx.strokeStyle = 'white';
      // Draw a circle at x,y
      ctx.arc(x, y, 8, 0, 2 * Math.PI);
      ctx.stroke();
    };

    const drawDeviceCommandsSentToDevices = () => {
      // Draw device movement commands at the moment they were sent to device
      //console.log('deviceCommandsSentToDevices', deviceCommandsSentToDevices);
      ctx.strokeStyle = 'orange';
      deviceCommandsSentToDevices.current.forEach((deviceCommand, idx) => {
        const { command, isMadeByFuturePrediction } = deviceCommand;
        ctx.fillStyle = isMadeByFuturePrediction ? 'red' : 'white';
        const x = timestampToX(deviceCommand.timestamp);
        const y = valueToYDevice(command.position * 100);
        ctx.beginPath();
        // draw a circle at x,y
        ctx.arc(x, y, 4, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        if (idx) {
          // Draw a line from the previous device pos to this pos
          const { command: prevCommand, timestamp: prevTimestamp } =
            deviceCommandsSentToDevices.current[idx - 1];
          const x0 = timestampToX(prevTimestamp);
          const y0 = valueToYDevice(prevCommand.position * 100);
          ctx.beginPath();
          ctx.moveTo(x0, y0);
          ctx.lineTo(x, y);
          ctx.stroke();
        }
      });
    };

    const drawFuture = () => {
      // Draw device movement commands at the moment they were sent to device
      if (!mlSignals.current.length) {
        return;
      }
      ctx.strokeStyle = 'blue';
      ctx.fillStyle = 'blue';
      const mlSignal = mlSignals.current[mlSignals.current.length - 1];
      const { timestampSent, future } = mlSignal;
      let frame = 0;
      let offsetMsec = 0;
      let x = timestampToX(timestampSent);
      // const y0 = valueToYDevice(value * 100);
      ctx.beginPath();
      while (x < width) {
        offsetMsec = frame * FRAME_DURATION_MSEC;
        x = timestampToX(timestampSent + offsetMsec);
        const y = valueToYIdeal(future[frame] * 100);
        ctx.lineTo(x, y);
        frame++;
      }
      ctx.stroke();
    };

    drawBackground();
    drawNowLine();
    drawSignalsAsTheyReceived();
    drawIdealPositionOfCommands();
    drawMessagesToServerAndBack();
    drawDeviceActualMovement();
    drawPerfectCommandForDevice();
    drawDeviceCommandsSentToDevices();
    drawFuture();
  };

  return (
    <DeviceVisualizationContext.Provider
      value={{
        onDeviceMove,
        onDraw,
        onMlSignal,
        onDeviceCommandSentToDevices,
      }}
    >
      {props.children}
    </DeviceVisualizationContext.Provider>
  );
};
