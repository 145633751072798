import React from 'react';

import styles from './style.module.scss';

interface CloseIconProps {
  closeToast?: () => void; // This is the function to close the toast
}

const CloseIcon: React.FC<CloseIconProps> = ({ closeToast }) => (
  <div className={styles.container}>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.586123 0.899158C0.273704 1.21158 0.273704 1.71811 0.586123 2.03053L3.83829 5.28269L0.868815 8.25216C0.556396 8.56458 0.556395 9.07111 0.868814 9.38353C1.18123 9.69595 1.68777 9.69595 2.00019 9.38353L4.96966 6.41406L7.94003 9.38444C8.25245 9.69686 8.75898 9.69686 9.0714 9.38444C9.38382 9.07202 9.38382 8.56549 9.0714 8.25307L6.10103 5.28269L9.3541 2.02962C9.66652 1.7172 9.66652 1.21067 9.3541 0.898252C9.04168 0.585833 8.53515 0.585833 8.22273 0.898252L4.96966 4.15132L1.71749 0.899158C1.40507 0.586739 0.898542 0.586739 0.586123 0.899158Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CloseIcon;
