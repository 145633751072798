import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PortalApp from './PortalApp';
import ExtensionApp from './ExtensionApp';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={'*'} element={<PortalApp />} />
        <Route path={'/extension/*'} element={<ExtensionApp />} />
      </Routes>
    </Router>
  );
};

export default App;
