import { DeviceState, PositionState } from './DeviceState';

const FPS = 30;
const FRAME_DURATION = 1000 / FPS;

const POSITION_THRESHOLD = 0.1;

/**
 * This class is responsible for interpreting future predictions
 * and updating device state accordingly
 */
export class FutureInterpreter {
  private future: number[];
  private futureBeginTimestamp: number;

  constructor() {
    this.future = [];
    this.futureBeginTimestamp = 0;
  }

  /**
   * This function is called 30 times per second to update device state
   * @param deviceState - device state to update
   */
  updateDeviceStateOnFrame(deviceState: DeviceState) {
    if (!this.future.length) {
      return deviceState;
    }

    const now = Date.now();
    const timePassed = now - this.futureBeginTimestamp;

    const framesPassed = Math.floor(timePassed / FRAME_DURATION);
    if (framesPassed >= this.future.length - 1) {
      // future is over
      return deviceState;
    }

    deviceState.updatedWithFuturePrediction = true;

    const futurePosition = this.future[framesPassed];
    const nextFuturePosition = this.future[framesPassed + 1];
    if (futurePosition < nextFuturePosition || futurePosition > 1 - POSITION_THRESHOLD) {
      // We should go up
      if (deviceState.positionState === PositionState.Up) {
        // Already up
        return deviceState;
      }
      deviceState.positionState = PositionState.MoveUp;
    } else if (futurePosition > nextFuturePosition || futurePosition < POSITION_THRESHOLD) {
      // We should go down
      if (deviceState.positionState === PositionState.Down) {
        // Already down
        return deviceState;
      }
      deviceState.positionState = PositionState.MoveDown;
    }
    return deviceState;
  }
  /**
   * This function is called when we receive a new prediction from ML server
   * @param future - array of future predictions
   * @param timestamp - timestamp of the moment when the frame has been captured and sent to ML server
   */
  onMlCommand(future: Array<number>, timestamp: number) {
    this.future = future || [];
    this.futureBeginTimestamp = timestamp;
  }
}
